import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { AfeSettingsModalComponent } from '../auto-edit/afe-settings-modal/afe-settings-modal.component'
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service'
import { ToastrService } from 'ngx-toastr'
import { Router, ActivatedRoute } from '@angular/router'
import { FileUploadService } from 'src/app/_services/file-upload.service'
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auto-edit-uploaded-file',
  templateUrl: './auto-edit-uploaded-file.component.html',
  styleUrls: ['./auto-edit-uploaded-file.component.scss']
})
export class AutoEditUploadedFileComponent implements OnInit {
  userId: any;
  creditsDetails: any;

  constructor (
    private _dialog: MatDialog,
    public networkCalls: DashboardService,
    private toastr: ToastrService,
    private router: Router,
    public _fileUploadService: FileUploadService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    this.activatedRoute.queryParams.subscribe(params => {
      //Updating the file and it's related settings here
      if (!(this._fileUploadService.currentFileInfo.file || this._fileUploadService.currentFileInfo.cloudFileDetails) && params.fileId) {
        this._fileUploadService.currentFileInfo.fileId = params.fileId;
        this.getAutoEditFileById(params.fileId);
        this._fileUploadService.currentFileInfo.advanceSettings['writing_advisor_as_comments'] = JSON.parse(params.writing_advisor_as_comments);
        this._fileUploadService.currentFileInfo.advanceSettings['statistics_in_document'] = JSON.parse(params.statistics_in_document);
        this._fileUploadService.currentFileInfo.advanceSettings['revisions_as_tracks'] = JSON.parse(params.revisions_as_tracks);
        this._fileUploadService.currentFileInfo.advanceSettings['language'] = params.language;
        this._fileUploadService.currentFileInfo.advanceSettings['enhancement'] = JSON.parse(params.enhancement);
        this._fileUploadService.currentFileInfo.advanceSettings['grammar'] = JSON.parse(params.grammar);
        this._fileUploadService.currentFileInfo.advanceSettings['spellings'] = JSON.parse(params.spellings);
        this._fileUploadService.currentFileInfo.advanceSettings['writing_advisor'] = JSON.parse(params.writing_advisor);
        this._fileUploadService.currentFileInfo.advanceSettings['style_guide'] = params.style_guide;
        this._fileUploadService.currentFileInfo.advanceSettings['power_mode'] = params.power_mode ? true : false;
        this._fileUploadService.currentFileInfo.advanceSettings['langCode'] = 'en';
        this._fileUploadService.currentFileInfo.isCloudFile = params.isCloudFile;
        this._fileUploadService.currentFileInfo.fileType = params.fileType;
      }
      this._fileUploadService.currentFileInfo.advanceSettings['langCode'] = 'en';
    });

    this.getCreditDetails()
    setTimeout(() => {
      if (this._fileUploadService.currentFileInfo && this._fileUploadService.currentFileInfo && this._fileUploadService.currentFileInfo.apiResponse && this._fileUploadService.currentFileInfo.apiResponse.status !== 'OVER_LIMIT') {

        let dialogRef = this._dialog.open(AfeSettingsModalComponent, {
          data: { config: this._fileUploadService.currentFileInfo.advanceSettings },
          hasBackdrop: true,
          panelClass: 'fileSettingPopup',
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result && result.config) this._fileUploadService.currentFileInfo.advanceSettings = result.config;
          console.log("result.config ", result.config)
          console.log("this._fileUploadService.currentFileInfo.advanceSettings", this._fileUploadService.currentFileInfo.advanceSettings)
        });

      }
    }, 10)
  }

  getAutoEditFileById(fileId) {
    this._fileUploadService
    .getAutoEditFileById(fileId)
    .subscribe(
      res => {
        if (res && res.data) {
          this._fileUploadService.currentFileInfo.apiResponse = res.data;
          this._fileUploadService.currentFileInfo.file = {
            name: this._fileUploadService.currentFileInfo.apiResponse.filename,
          };
          this._fileUploadService.currentFileInfo.progress = 100;
        }
      },
      error => {
        this._fileUploadService.resetFileDetails();
        setTimeout(() => {
          this.router.navigate(['/dashboard/auto-edit'])
        }, 200)
        this.sharedService.errorHandller(error)
      }
    )
  }

  getCreditDetails() {
    this.networkCalls.getStoreUserCreditDetails().subscribe(resp => {
      if (Object.keys(resp).length == 0)  {
        this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
          if (response["status"]) {
            this.creditsDetails = response.data;
            this.networkCalls.storeUserCreditDetails(this.creditsDetails);
          }

        }, error => {
          this.sharedService.errorHandller(error);
        });
      }
      else{
        this.creditsDetails = resp;
      }
      this._fileUploadService.currentUserCredits = this.creditsDetails && this.creditsDetails.credits >= 0 ? this.creditsDetails.credits + this.creditsDetails.purchased_credits : 0;
      let redirct_url = environment.payment_fe;
      let data = {
        "user_id": this.userId,
        "is_premium": this.creditsDetails && this.creditsDetails.user_type == 'PREMIUM' ? true : false,
        "trinka_callback_url": window.location.href
      };
      let userData = btoa(encodeURIComponent(JSON.stringify(data)));
      this._fileUploadService.creditRedirectUrl = redirct_url + '/user/credit/' + userData, "_self";
    })
    }

}
