import { NgModule } from "@angular/core";
import { MatButtonModule, MatCheckboxModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatProgressBarModule, MatSelectModule, MatSidenavModule, MatToolbarModule } from "@angular/material";
import { PasswordStrengthComponent } from "src/app/components/sign-insign-out/password-strength/password-strength.component";
import { DndDirective } from "src/app/_directives/dnd.directive";
import { FilterByFileNamePipe } from "src/app/_pipes/filter-by-file-name.pipe";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";


@NgModule({
  imports: [
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
  ],
  declarations: [
      PasswordStrengthComponent,
      FilterByFileNamePipe,
      DndDirective,
  ],
  exports: [
    PasswordStrengthComponent,
    FilterByFileNamePipe,
    DndDirective
  ]
})

export class SharedModule { }