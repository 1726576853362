<div class="iThenticationPopup">
    <div class="iThenticatioHeader">
        <h1>Sample iThenticate Plagiarism Report</h1>
        <button class="cancelbuttons" mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>
    <div class="iThenticatioBody">
        <div class="downloadFileSection">
            <img src="../../../../../assets/images/PDF_icon.png" alt="">
            <p>Downloadable report in PDF format</p>
            <button (click)="downloadiTehnticateSampleReport()">Download Sample Report</button>
        </div>
        <div class="iThenticateText">
            <div class="whatDoYouGet">
                <h3>What do you get?</h3>
                <p>The iThenticate Plagiarism Report includes Internet, open access, and paid publication sources.</p>
                <p>It is the most comprehensive report that is trusted by academic publishers.</p>
                 <p>You will get a PDF report with a list of all sources and highlights for all text sections that were found to be similar with the sources.</p>
            </div>
            <div class="whatDoYouGet whatIsNotIncluded" style="margin-bottom: 0px;">
                <h3>What is not included</h3>
                <p style="margin-bottom: 0px;">Nothing!</p>
                <p>The iThenticate Plagiarism Report includes everything that you will need for a robust plagiarism check.</p>
            </div>
        </div>
    </div>
</div>