<div class="loader-screen">

    <div id="loadingWrapper">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#fff;display:block;" width="64px" height="64px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="28" stroke="#d9d9d9" stroke-width="8" fill="none"></circle>
        <circle cx="50" cy="50" r="28" stroke="#7e22ce" stroke-width="8" stroke-linecap="round" fill="none">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.3888888888888888s" values="0 50 50;180 50 50;720 50 50" keyTimes="0;0.5;1"></animateTransform>
          <animate attributeName="stroke-dasharray" repeatCount="indefinite" dur="1.3888888888888888s" values="17.59291886010284 158.33626974092556;87.96459430051421 87.96459430051421;17.59291886010284 158.33626974092556" keyTimes="0;0.5;1"></animate>
        </circle>
        </svg>
      <!-- <img src="../../../assets/images/loader.gif" class="loader-img"> -->
        <!-- <div class="profile-main-loader">
          <div class="loader">
            <svg class="circular-loader" viewBox="25 25 50 50" >
              <circle class="loader-path" cx="50" cy="50" r="20" fill="#fff" stroke="#D9D9D9" stroke-width="4" />
            </svg>
          </div>
        </div>         -->
    </div>

    <p>{{loaderText}}</p>
</div>