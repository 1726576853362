<div class="upgrade_plag_report_modal">
    <div class="upgradeButtonClosed"  (click)="close()">
        <img src="../../../../../assets/images/closeIcons.png" class=""/>
      </div>
      <svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M78.75 81.375C78.75 100.222 63.4725 115.5 44.625 115.5C25.7775 115.5 10.5 100.222 10.5 81.375C10.5 62.5275 25.7775 47.25 44.625 47.25C45.5175 47.25 46.4624 47.3025 47.3549 47.355C63.9974 48.6675 77.3326 62.0025 78.6451 78.645C78.6976 79.5375 78.75 80.4825 78.75 81.375Z" fill="#C084FC"/>
        <path opacity="0.4" d="M115.5 44.625C115.5 63.4725 100.222 78.75 81.375 78.75C80.4825 78.75 79.5376 78.6975 78.6451 78.645C77.3326 62.0025 63.9974 48.6675 47.3549 47.355C47.3024 46.4625 47.25 45.5175 47.25 44.625C47.25 25.7775 62.5275 10.5 81.375 10.5C100.222 10.5 115.5 25.7775 115.5 44.625Z" fill="#C084FC"/>
        </svg>

    <p>You don't have enough credits to generate this report. Buy at least {{required_credits}} more credits to proceed. </p>
    <button class="buyCreditsButtons" (click)="addCredits()">
      Buy Credits to Proceed
      <!-- <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333984 6.00033C0.333984 5.54009 0.70708 5.16699 1.16732 5.16699H12.834C13.2942 5.16699 13.6673 5.54009 13.6673 6.00033C13.6673 6.46056 13.2942 6.83366 12.834 6.83366H1.16732C0.70708 6.83366 0.333984 6.46056 0.333984 6.00033Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4232 5.41107C13.7487 5.73651 13.7487 6.26414 13.4232 6.58958L8.42324 11.5896C8.0978 11.915 7.57017 11.915 7.24473 11.5896C6.91929 11.2641 6.91929 10.7365 7.24473 10.4111L12.2447 5.41107C12.5702 5.08563 13.0978 5.08563 13.4232 5.41107Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.24473 0.41107C7.57017 0.0856329 8.0978 0.0856329 8.42324 0.41107L13.4232 5.41107C13.7487 5.73651 13.7487 6.26414 13.4232 6.58958C13.0978 6.91502 12.5702 6.91502 12.2447 6.58958L7.24473 1.58958C6.91929 1.26414 6.91929 0.736507 7.24473 0.41107Z" fill="white"/>
      </svg> -->
    </button>
   
  </div>