<div class="content_wrap_section">
    <div class="wrap_header_sectons">
        <div class="top_header_overlay"></div>
        <h2>Data Security</h2>
    </div>

    <div class="page_underConstruction">
        <div class="container">
            <div class="row">
                <div class="col-md-5">
                    <div class="page_contents">
                        <h3>Web page under construction</h3>
                        <a href="/">Back to Home</a>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="page_iamges">
                        <img src="../../../assets/images/UnderConstruction.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
