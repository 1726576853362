<div class="plag_check_onboading_popup">
    <div class="sliderPageOverlay">
        <svg width="180" height="557" viewBox="0 0 180 557" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5" filter="url(#filter0_f_578_32172)">
                <circle cx="362" cy="195" r="162" fill="#CF7CE2" />
            </g>
            <defs>
                <filter id="filter0_f_578_32172" x="0" y="-167" width="724" height="724" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_578_32172" />
                </filter>
            </defs>
        </svg>
    </div>

    <div class="slides_body">
        <div class="slide">
            <div class="slide1_img">
                <img src="../../../../../assets/images/plagCheckSliderOneImg.png" alt="">
            </div>
            <div class="slide1_body">
                <img src="../../../../../assets/images/trinka-logo.png">
                <p class="slide1_tag"> Plagiarism Checker</p>
                <p class="slide1_title">Your Plagarism Checker has a fresh look!</p>
                <p class="slide1_content">The powerful and reliable plagiarism checker has got a new design. This will help youuse the tool easily. You can now also share your report with your colleagues.</p>
            </div>

        </div>
    </div>

    <div class="popup_footer">
        <div class="next">
            <button class="next_btn" (click)="close()">
                <a [routerLink]="['/dashboard/plagiarism-checker']">Go to Plagiarism Checker!</a>
            </button>
        </div>
    </div>

</div>