// dialog-data.service.ts
import { EventEmitter, Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
    
//Created the service to maintain the data from parent as well dialog component(delete confirmation modal)    
export class DialogDataService {
  private dialogData: any
  dataUpdated: EventEmitter<any> = new EventEmitter()

  /**
   * Used to set data from parent to dialog(delete confirmation modal) component data 
   * @param data 
   */  
  setDialogData (data: any) {
    this.dialogData = data
    this.dataUpdated.emit(this.dialogData)
  }

  /**
   * 
   * @returns current/ latest data of delete confirmation modal
   */  
  getDialogData (): any {
    return this.dialogData
  }
}
