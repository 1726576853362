import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { ParaphraserService } from 'src/app/_services/paraphraser.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-paraphraser',
  templateUrl: './paraphraser.component.html',
  styleUrls: ['./paraphraser.component.scss']
})
export class ParaphraserComponent implements OnInit {
  @ViewChild('paraphraserSection', { static: false }) private _paraphraserSection!: ElementRef;
  @ViewChild('popover', { static: false }) private _popover!: ElementRef;
  @ViewChild('alternativesPopover', { static: false }) private _alternativesPopover!: ElementRef;
  @Input('right-panel-state') set rightPanelState(value: boolean) {
    this._rightPanelState = value;
    if(!this._rightPanelState) {
      this._resetParaphraserState();
    }

    if(!this.isParaphraserIntroVideoPaused) {
      this._paraphraserIntroVideoPause();
    }
  }
  get rightPanelState(): boolean {
    return this._rightPanelState;
  }

  private _rightPanelState: boolean = false;
  private _snackBarDuration: number = 3000;
  private _sendSentencesToParaphraserRequest: any;
  private _userSelectionSentencesChange: any;

  userId: string;
  paraphrasedSentences: any = [];
  paraphraserAlternativesOfCurrentSelection: any = null;
  paraphraseTemperature: number = 1.5;
  errorDuringParaphrase: any = {
    status: false,
    error: null
  };
  isSelectionLimitReached: boolean = false;
  isDocumentSplitInProgress: boolean = false;
  loader: boolean = false;
  toggleDiffOptionModel: boolean = true;
  isParaphrasedSentencesUpatedBackToEditor: boolean = false;
  data: any;
  isParaphraserIntroVideoPaused: boolean = true;

  constructor(private changeDetector: ChangeDetectorRef, private _networkCalls: OnlineEditorService, private _paraphraserService: ParaphraserService, public _sharedService: SharedService, private _amplitudeService: AmplitudeService, private _snackBar: MatSnackBar) {
    this._userSelectionSentencesChange = this._paraphraserService.userSelectionSentencesChange.subscribe((data: any) => {
      this.data = data;
      if(!data) {
        if(this._sendSentencesToParaphraserRequest) {
          this.loader = false;
          this._sendSentencesToParaphraserRequest.unsubscribe();
          this.changeDetector.detectChanges();
        }
        return;
      }

      this.paraphrasedSentences = [];
      this.errorDuringParaphrase = {
        status: false,
        error: null
      };
      this.isSelectionLimitReached = false;
      this.isDocumentSplitInProgress = false;
      this.isParaphrasedSentencesUpatedBackToEditor = false;
      this.closeAlternativesPopover();

      if(!data.userSelection || data.userSelection.toString() === '') {
        if(this._sendSentencesToParaphraserRequest) {
          this.loader = false;
          this._sendSentencesToParaphraserRequest.unsubscribe();
          this.changeDetector.detectChanges();
        }
        return;
      }
      data.userSelection.empty();

      if(!data.list.length) {
        this.isDocumentSplitInProgress = true;
        return;
      }

      if(data.list.length > 15) {
        this.isSelectionLimitReached = true;
        return;
      }

      let mapping = {
        '1': 'low',
        '1.5': 'medium',
        '2': 'high'
      };
      this._amplitudeService.logAmplitude('ED_paraphrase_process', {
        'from': 'Right panel',
        'degree_of_change_setting': mapping[this.paraphraseTemperature],
        'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
      });
      this._sendSentencesToParaphraser(data.list);
    });
  }

  ngOnInit() {
    const localMeta = this._sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
  }

  ngOnDestroy() {
    this._userSelectionSentencesChange.unsubscribe();
  }

  updateParaphraseTemperature(event) {
    this.isParaphrasedSentencesUpatedBackToEditor = false;
    this.paraphraseTemperature = event.value;
    this.closeAlternativesPopover();
    let mapping = {
      '1': 'low',
      '1.5': 'medium',
      '2': 'high'
    };

    this._amplitudeService.logAmplitude('ED_paraphraser_degree_of_change', {
      'degree_of_change_setting': mapping[event.value],
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });

    if(!this.paraphrasedSentences.length) return;
    this._sendSentencesToParaphraser(this.paraphrasedSentences);
  }

  showParaphraseAlternatives(index: string, sentence: any) {
    this.closeAlternativesPopover();
    sentence.selected = true;
    this.paraphraserAlternativesOfCurrentSelection = sentence;
    const currentSelectedElement = document.getElementById(`paraphrase-sentence-${index}`);
    const currentSelectedElementRect = currentSelectedElement.getBoundingClientRect();

    const scrollTop = this._paraphraserSection.nativeElement.scrollTop
    const multiLineRect: any = this._getMultilineSpanBoundingRect(currentSelectedElement);
    const parentRect = this._paraphraserSection.nativeElement.getBoundingClientRect();
    const top = currentSelectedElementRect.top - parentRect.top + scrollTop;
    const bottom = parentRect.bottom - currentSelectedElementRect.bottom;
    const left = currentSelectedElementRect.left - parentRect.left;
    const right = parentRect.right - currentSelectedElementRect.right;

    this._popover.nativeElement.style.display = `block`;
    this._popover.nativeElement.style.top = `${(top - 26)}px`;
    this._popover.nativeElement.style.left = `${multiLineRect.left + 24}px`;

    if(this.paraphraserAlternativesOfCurrentSelection.response.output_sentence.length && this.paraphraserAlternativesOfCurrentSelection.response.output_sentence.length > 1) {
      this._alternativesPopover.nativeElement.style.top = `${top + currentSelectedElementRect.height + 25}px`;
      this._alternativesPopover.nativeElement.style.left = `${left}px`;
      this._alternativesPopover.nativeElement.style.display = `block`;
    }

    this._amplitudeService.logAmplitude('ED_show_paraphrased_alternative', { 
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }

  updateCurrentSelection(currentSelectionIndex: any, currentSelection: any) {
    this.isParaphrasedSentencesUpatedBackToEditor = false;
    this.paraphraserAlternativesOfCurrentSelection.currentSelectionIndex = currentSelectionIndex;
    this.paraphraserAlternativesOfCurrentSelection.currentSelection = currentSelection;
    this.closeAlternativesPopover();

    this._amplitudeService.logAmplitude('ED_choose__paraphrased_alternative', { 
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }

  onToggleDiffOption() {
    this.closeAlternativesPopover();

    this._amplitudeService.logAmplitude('ED_paraphrase_revisions', {
      'toggle': this.toggleDiffOptionModel ? 'on' : 'off',
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }

  updateParaphrasedSentencesBackToEditor() {
    this.paraphrasedSentences.forEach((sentence) => {
      sentence.node.setText(sentence.currentSelection.suggestion);
    });
    this.isParaphrasedSentencesUpatedBackToEditor = true;
    this._paraphraserService.paraphraseContentAddedInEditorEvent();

    let mapping = {
      '1': 'low',
      '1.5': 'medium',
      '2': 'high'
    };

    this._amplitudeService.logAmplitude('ED_replace_paraphrased_text', {
      'degree_of_change_setting': mapping[this.paraphraseTemperature],
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0'
    });
  }

  closeAlternativesPopover() {
    this.paraphraserAlternativesOfCurrentSelection = null;
    if(this._alternativesPopover) {
      this._popover.nativeElement.style.display = `none`;
      this._alternativesPopover.nativeElement.style.display = `none`;
    }
    this.paraphrasedSentences.forEach(sentence => sentence.selected = false);
  }

  popoverOptionSelected($event: any, option: string, originalSentence: string = '', paraphrasedSentence: string = '', origin: string) {
    $event.stopPropagation();
    let requestBody = {};
    switch (option) {
      case 'like': {
        requestBody = {
          originalSentence,
          paraphrasedSentence,
          feedback: 'like'
        };
        if(origin === 'feedback_popover') {
          this._popover.nativeElement.style.display = `none`;
        }
        this._paraphraseFeedback(requestBody);
        break;
      }

      case 'dislike': {
        requestBody = {
          originalSentence,
          paraphrasedSentence,
          feedback: 'dislike'
        };
        if(origin === 'feedback_popover') {
          this._popover.nativeElement.style.display = `none`;
        }
        this._paraphraseFeedback(requestBody);
        break;
      }

      default:
      break;
    }
    // this.closeAlternativesPopover();
  }

  upgradePlanPopup() {
    this._amplitudeService.logAmplitude('Upgrade_nudge_clicked', {
      'from': 'paraphraser',
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
      // 'Current Plan': this.sharedService.uppercaseFirstLetter(this.use).toLowerCase(),
    });

    let data = {
      "user_id" : this.userId
    };
    let userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url  = environment.payment_fe;
    window.open(`${redirct_url}/user/${userData}`,"_blank")
  }

  private _paraphraseFeedback(requestBody: any) {
    this._networkCalls.paraphraseFeedbackRequest(requestBody)
    .subscribe({
      complete: () => { },
      next: (response: any) => {
        this._snackBar.open('Thank you for your feedback!', '', {
          duration: this._snackBarDuration
        });

        let mapping = {
          'like': 'ED_Thumbs_up_Paraphraser',
          'dislike': 'ED_Thumbs_down_Paraphraser'
        };

        this._amplitudeService.logAmplitude(mapping[requestBody.feedback], {
          'action': 'true',
          'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0'
        });
      },
      error: (error) => { }
    });
  }

  private _getMultilineSpanBoundingRect(element: HTMLElement) {
    if(!element.textContent) return;

    // TODO: adjustment is the wrapper padding need re-organize markup structure
    const adjustment = 0;
    const lines = element.textContent.split('\n');
    const lineHeight = parseFloat(getComputedStyle(element).lineHeight);

    let top = Number.MAX_VALUE;
    let bottom = Number.MIN_VALUE;

    for (let i = 0; i < lines.length; i++) {
      const lineTop = element.offsetTop + (i * lineHeight);
      const lineBottom = lineTop + lineHeight;

      if (lineTop < top) {
        top = lineTop;
      }

      if (lineBottom > bottom) {
        bottom = lineBottom;
      }
    }

    let left = element.offsetLeft;
    let right = element.offsetWidth;

    top -= adjustment;
    left -= adjustment;
    right -= adjustment;
    bottom -= adjustment;

    return { top, right, bottom, left };
  }

  private _resetParaphraserState() {
    this.paraphrasedSentences = [];
    this.errorDuringParaphrase = {
      status: false,
      error: null
    };
    this.paraphraseTemperature = 1.5;
    this.loader = false;
    this.toggleDiffOptionModel = true;
    this.isParaphrasedSentencesUpatedBackToEditor = false;
    this.closeAlternativesPopover();
  }

  private _sendSentencesToParaphraser(list: any) {
    this.loader = true;
    // correct video state to paused
    this.isParaphraserIntroVideoPaused = true;
    this.errorDuringParaphrase.status = false;
    let data = list.map(sentence => sentence.requestBody);
    data.forEach(sentence => {
      sentence.temperature = this.paraphraseTemperature;
    });

    this._sendSentencesToParaphraserRequest = this._networkCalls.sendSentencesToParaphraserRequest(data)
    .subscribe((result: any) => {
      list.forEach(sentence => {
        if(!result || !result.length) {
          this.paraphrasedSentences = [];
          this.loader = false;
          return;
        }

        let paraphrasedSentence = result.find(responseSentence => responseSentence.file_id === sentence.id)
        sentence['response'] = paraphrasedSentence;
        if(paraphrasedSentence && paraphrasedSentence.output_sentence.length) {
          sentence['currentSelectionIndex'] = 0;
          sentence['currentSelection'] = paraphrasedSentence.output_sentence[0];
          sentence['selected'] = false;
        }
      });

      this.paraphrasedSentences = list;
      this.loader = false;
    }, (error: any) => {
      this.errorDuringParaphrase.status = true;
      this.errorDuringParaphrase.error = error;
      if(error.error.renewalDate) {
        let renewalDate = error.error.renewalDate;
        let tempDate = new Date();
        tempDate.setDate(renewalDate.date);
        tempDate.setMonth(renewalDate.month);
        tempDate.setFullYear(renewalDate.year);
        this.errorDuringParaphrase.error.renewalDateDisplay = tempDate;
      }
      this.loader = false;

      if(error.status === 409) {
        this._amplitudeService.logAmplitude('ED_Paraphraser_Limit_reached', { 
          'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
        });
      }
    });
  }

  private _paraphraserIntroVideoPlay() {
    let paraphraserIntroVideo: HTMLVideoElement = document.getElementById("paraphraserIntroVideo") as HTMLVideoElement;
    if(!paraphraserIntroVideo) return;
    paraphraserIntroVideo.play();
    this.isParaphraserIntroVideoPaused = false;
  }

  private _paraphraserIntroVideoPause() {
    let paraphraserIntroVideo: HTMLVideoElement = document.getElementById("paraphraserIntroVideo") as HTMLVideoElement;
    if(!paraphraserIntroVideo) return;
    paraphraserIntroVideo.pause();
    this.isParaphraserIntroVideoPaused = true;
  }

  toggleParaphraserIntroVideoPlayPause() {
    if (this.isParaphraserIntroVideoPaused) {
      this._paraphraserIntroVideoPlay();
    } else {
      this._paraphraserIntroVideoPause();
    }
  }
}
