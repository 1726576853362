import { Component, Input, Output, EventEmitter, ApplicationRef, OnInit } from '@angular/core'
import { FileUploadService } from 'src/app/_services/file-upload.service'
import { AfeSettingsModalComponent } from '../afe-settings-modal/afe-settings-modal.component'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { SharedService } from 'src/app/_services/shared.service';
import { DeleteConfirmationModalComponent } from '../delete-confirmation-modal/delete-confirmation-modal.component'
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service'
import { HttpResponse } from '@angular/common/http';
import { AmplitudeService } from 'src/app/_services/amplitude.service'
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-loader',
  templateUrl: './file-loader.component.html',
  styleUrls: ['./file-loader.component.scss']
})
export class FileLoaderComponent {
  userId: any;
  showProofreadInProgressLoader: boolean = false

  @Output() public startFileProofread = new EventEmitter()
  @Output() public deleteUploadedFile = new EventEmitter()
  @Input() showProgressState: boolean = true
  @Input() currentFileInfo: any = {};
  @Input() currentUserCredits: number = 0;
  @Output() public stopUpload = new EventEmitter()

  constructor (
    public _fileUploadService: FileUploadService,
    private _dialog: MatDialog,
    private router: Router,
    public sharedService: SharedService,
    private networkCall: DashboardService,
    public amplitudeService: AmplitudeService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
  }

  onStartProofread() {
    if(!this._fileUploadService.currentFileInfo.advanceSettings.enhancement && !this._fileUploadService.currentFileInfo.advanceSettings.grammar && !this._fileUploadService.currentFileInfo.advanceSettings.spellings &&
      !this._fileUploadService.currentFileInfo.advanceSettings.writing_advisor && this._fileUploadService.currentFileInfo.advanceSettings.style_guide == "NONE") {
        this.toastr.error('Please select any one category or a style guide',"Error");
        return;
    } else {
      this.showProofreadInProgressLoader = true
      let fileObject = new FormData()
      fileObject.append(
        'writing_advisor_as_comments',
        this._fileUploadService.currentFileInfo.advanceSettings
          .writing_advisor_as_comments
      )
      fileObject.append(
        'statistics_in_document',
        this._fileUploadService.currentFileInfo.advanceSettings
          .statistics_in_document
      )
      fileObject.append(
        'revisions_as_tracks',
        this._fileUploadService.currentFileInfo.advanceSettings
          .revisions_as_tracks
      )
      fileObject.append(
        'language',
        this._fileUploadService.currentFileInfo.advanceSettings.language
      )
      fileObject.append(
        'enhancement',
        this._fileUploadService.currentFileInfo.advanceSettings.enhancement
      )
      fileObject.append(
        'grammar',
        this._fileUploadService.currentFileInfo.advanceSettings.grammar
      )
      fileObject.append(
        'spellings',
        this._fileUploadService.currentFileInfo.advanceSettings.spellings
      )
      fileObject.append(
        'writing_advisor',
        this._fileUploadService.currentFileInfo.advanceSettings
          .writing_advisor
      )
      fileObject.append(
        'style_guide',
        this._fileUploadService.currentFileInfo.advanceSettings.style_guide
      )
      if(this._fileUploadService.currentFileInfo.advanceSettings.power_mode == true){
        fileObject.append("pipeline", "advanced");
      } else {
        fileObject.append("pipeline", "basic");
      }
      fileObject.append("instruction", "");
      fileObject.append("power_mode", this._fileUploadService.currentFileInfo.advanceSettings.power_mode === undefined ? false : this._fileUploadService.currentFileInfo.advanceSettings.power_mode);
      fileObject.append("action", "PROFREAD");
      this.networkCall.setDashboardLocalFileDetails(null);
      this.networkCall.setDashboardCloudFileDetails(null);
      if (!this._fileUploadService.currentFileInfo.isCloudFile) {
        fileObject.append('document_id', this.currentFileInfo.apiResponse.id)
        this._fileUploadService.uploadOrProcessLocalFile(fileObject).subscribe(
          res => {
            if (res instanceof HttpResponse && res.body && res.body.data) {
              // this.toastr.success('File submitted successfully. It will appear in the Edited Files section.');
              this.resetFileAndRedirectToInitialPage(true);
              this.logProofreadEvent('success');
            }
          },
          error => {
            this.sharedService.errorHandller(error);
            this.resetFileAndRedirectToInitialPage();
            this.logProofreadEvent('success');
          }
        )
      } else {
        fileObject.append('document_id', this.currentFileInfo.apiResponse.id)
        this._fileUploadService.uploadOrProcessCloudFile(fileObject).subscribe(
          res => {
            if (res instanceof HttpResponse && res.body && res.body.data) {
              // this.toastr.success('File submitted successfully. It will appear in the Edited Files section.');
              this.resetFileAndRedirectToInitialPage(true);
              this.logProofreadEvent('success');
            }
          },
          error => {
            this.sharedService.errorHandller(error);
            this.resetFileAndRedirectToInitialPage();
            this.logProofreadEvent('success');
          }
        )
      }
    }
  }

  deleteFile() {
    this._dialog.open(DeleteConfirmationModalComponent, {
      disableClose: true,
      panelClass: 'deleteModalSection'
    });
  }

  openAdvanceSettingsModal(clickedVia: string = 'language') {
    this.amplitudeService.logAmplitude('AFE_change_settings_upload_modal', {
      'type': clickedVia
    });
    // console.log('File is: ', this._fileUploadService.currentFileInfo);
    const dialogRef = this._dialog.open(AfeSettingsModalComponent, {
      data: { config: this._fileUploadService.currentFileInfo.advanceSettings },
      hasBackdrop: true,
      panelClass: 'fileSettingPopup',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.config) this._fileUploadService.currentFileInfo.advanceSettings = result.config;
    })

  }

  resetFileAndRedirectToInitialPage(addParamToUrl: boolean = false) {

    // this.spinner.show();
    setTimeout(() => {
      this._fileUploadService.resetFileDetails();
      if (addParamToUrl) this.router.navigate(['/dashboard/auto-edit'], {
        queryParams: {
          "isNewFileAdded": true
        }
      });
      else this.router.navigate(['/dashboard/auto-edit']);
      // this.spinner.hide();
    }, 400);

  }

  stopUploadProgress() {
    this.resetFileAndRedirectToInitialPage();
    setTimeout(() => {
      this.stopUpload.emit();
    }, 200);
  }

  onUploadAgainAction() {
    this.networkCall.setDashboardLocalFileDetails(null);
    this.networkCall.setDashboardCloudFileDetails(null);
    this.amplitudeService.logAmplitude('AFE_upload_again', {
      'file_name': this.currentFileInfo.file.name
    });
    this.resetFileAndRedirectToInitialPage();
  }

  onBuyCredits() {
    this.amplitudeService.logAmplitude('auto_edit_add_credits',{
      'credit_balance_free': this._fileUploadService.userCreditDetails.credits,
      'credit_balance_purchased': this._fileUploadService.userCreditDetails.purchased_credits,
      'Subscription Plan': this.sharedService.uppercaseFirstLetter(this._fileUploadService.userCreditDetails.user_type),
      'from': 'insufficient credit modal'
    });

    let redirct_url = environment.payment_fe;
    let data = {
      "user_id": this.userId,
      "is_premium":  this._fileUploadService.userCreditDetails && this._fileUploadService.userCreditDetails.user_type == 'PREMIUM' ? true : false,
      "trinka_callback_url": window.location.href
    };
    let userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this._fileUploadService.creditRedirectUrl = redirct_url + '/user/credit/' + userData, "_self";

    if(this._fileUploadService.creditRedirectUrl) window.open(this._fileUploadService.creditRedirectUrl, '_self');
  }

  changeFileAction() {
    this._fileUploadService.changeFileClicked = true;
    this._fileUploadService
      .deleteFileByAutoEditId(
        this._fileUploadService.currentFileInfo.apiResponse.id
      )
      .subscribe(
        res => {
          this.networkCall.setDashboardLocalFileDetails(null);
          this.networkCall.setDashboardCloudFileDetails(null);

        },
        error => {
          this.sharedService.errorHandller(error)
        }
    )

    this.resetFileAndRedirectToInitialPage();
  }

  logProofreadEvent(status: string = 'success') {
    this.amplitudeService.logAmplitude('AFE_start_proofreading', {
      'file_name': this.currentFileInfo.apiResponse.filename,
      'status': status,
      'word count': this.currentFileInfo.apiResponse.word_count,
      'file_type': this.currentFileInfo.fileType,
      'credit_balance_free': this._fileUploadService.userCreditDetails.credits,
      'credit_balance_purchased': this._fileUploadService.userCreditDetails.purchased_credits,
      'editing mode': this.currentFileInfo.advanceSettings['Editing modes'],
      'language': this.currentFileInfo.advanceSettings.language
    });
    console.log("this.currentFileInfo ", this.currentFileInfo)
  }

  getNumberWithCommas(number: number) {
    return new Intl.NumberFormat().format(number);
  }

}
