<div class="sample_report_popup" *ngIf="!isIthenticate">
    <div class="heading">
        <div class="title">Sample General plagiarism report</div>
        <div class="close_btn" (click)="close()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.9429 2.94265C19.4636 2.42195 19.4636 1.57773 18.9429 1.05703C18.4222 0.536329 17.578 0.536329 17.0573 1.05703L10.0001 8.11422L2.94289 1.05703C2.42219 0.536329 1.57797 0.536329 1.05727 1.05703C0.536573 1.57773 0.536573 2.42195 1.05727 2.94265L8.11446 9.99984L1.05727 17.057C0.536573 17.5777 0.536573 18.4219 1.05727 18.9426C1.57797 19.4633 2.42219 19.4633 2.94289 18.9426L10.0001 11.8855L17.0573 18.9426C17.578 19.4633 18.4222 19.4633 18.9429 18.9426C19.4636 18.4219 19.4636 17.5777 18.9429 17.057L11.8857 9.99984L18.9429 2.94265Z"
                    fill="#4D4D4D" />
            </svg>
        </div>
    </div>

    <div class="sample_report_popup_body">
        <div class="report_img">
            <img src="../../../../assets/images/turnitin_desc.svg">
             <p>1. Detailed description</p>
        </div>

        <div class="report_img">
            <img src="../../../../assets/images/turnitin_content.svg">
            <p>2. Shows plagiarised content</p>
        </div>

        <div class="report_img">
            <img src="../../../../assets/images/turnitin_report.svg">
            <p>3. Shows the source of plagiarism</p>
        </div>

    </div>

    <div class="sample_report_popup_content">
        <div class="report_included">
            <h4 class="report_included_title">What is included?</h4>
            <p> 
                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2559 0.250425C14.5813 0.575862 14.5813 1.1035 14.2559 1.42894L5.92257 9.76227C5.59713 10.0877 5.06949 10.0877 4.74406 9.76227L0.577391 5.5956C0.251954 5.27017 0.251954 4.74253 0.577391 4.41709C0.902828 4.09166 1.43047 4.09166 1.7559 4.41709L5.33331 7.9945L13.0774 0.250425C13.4028 -0.0750116 13.9305 -0.0750116 14.2559 0.250425Z" fill="#591D89"/>
                </svg>
                Checks across all internet & open access sources.
            </p>
            <p>
                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2559 0.250425C14.5813 0.575862 14.5813 1.1035 14.2559 1.42894L5.92257 9.76227C5.59713 10.0877 5.06949 10.0877 4.74406 9.76227L0.577391 5.5956C0.251954 5.27017 0.251954 4.74253 0.577391 4.41709C0.902828 4.09166 1.43047 4.09166 1.7559 4.41709L5.33331 7.9945L13.0774 0.250425C13.4028 -0.0750116 13.9305 -0.0750116 14.2559 0.250425Z" fill="#591D89"/>
                </svg>
                The report provides a PDF that lists all sources & highlights 
                for all text sections that were found plagiarised.
            </p>
        </div>

        <div class="report_included">
            <h4 class="report_included_title">What is not included?</h4>
            <p>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5892 1.58909C11.9147 1.26366 11.9147 0.736018 11.5892 0.410582C11.2638 0.0851447 10.7361 0.0851447 10.4107 0.410582L5.99996 4.82133L1.58921 0.410582C1.26378 0.0851447 0.736141 0.0851447 0.410704 0.410582C0.0852667 0.736018 0.0852667 1.26366 0.410704 1.58909L4.82145 5.99984L0.410704 10.4106C0.0852667 10.736 0.0852667 11.2637 0.410704 11.5891C0.736141 11.9145 1.26378 11.9145 1.58921 11.5891L5.99996 7.17835L10.4107 11.5891C10.7361 11.9145 11.2638 11.9145 11.5892 11.5891C11.9147 11.2637 11.9147 10.736 11.5892 10.4106L7.17847 5.99984L11.5892 1.58909Z" fill="#591D89"/>
                </svg>
                Paid publication sources are not included.
            </p>
            <p>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5892 1.58909C11.9147 1.26366 11.9147 0.736018 11.5892 0.410582C11.2638 0.0851447 10.7361 0.0851447 10.4107 0.410582L5.99996 4.82133L1.58921 0.410582C1.26378 0.0851447 0.736141 0.0851447 0.410704 0.410582C0.0852667 0.736018 0.0852667 1.26366 0.410704 1.58909L4.82145 5.99984L0.410704 10.4106C0.0852667 10.736 0.0852667 11.2637 0.410704 11.5891C0.736141 11.9145 1.26378 11.9145 1.58921 11.5891L5.99996 7.17835L10.4107 11.5891C10.7361 11.9145 11.2638 11.9145 11.5892 11.5891C11.9147 11.2637 11.9147 10.736 11.5892 10.4106L7.17847 5.99984L11.5892 1.58909Z" fill="#591D89"/>
                </svg>
                The report provides a PDF that lists all sources & highlights 
                for all text sections that were found plagiarised.
            </p>
        </div>
    </div>

    <div class="sample_report_popup_footer">
        <a [routerLink]="['plagiarism-checker/report/simcheck/sample']" (click)="close()">View Sample report</a>
    </div>
</div>


<div class="sample_report_popup" *ngIf="isIthenticate">
    <div class="heading">
        <div class="title">Sample Academic plagiarism report </div>
        <div class="close_btn" (click)="close()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.9429 2.94265C19.4636 2.42195 19.4636 1.57773 18.9429 1.05703C18.4222 0.536329 17.578 0.536329 17.0573 1.05703L10.0001 8.11422L2.94289 1.05703C2.42219 0.536329 1.57797 0.536329 1.05727 1.05703C0.536573 1.57773 0.536573 2.42195 1.05727 2.94265L8.11446 9.99984L1.05727 17.057C0.536573 17.5777 0.536573 18.4219 1.05727 18.9426C1.57797 19.4633 2.42219 19.4633 2.94289 18.9426L10.0001 11.8855L17.0573 18.9426C17.578 19.4633 18.4222 19.4633 18.9429 18.9426C19.4636 18.4219 19.4636 17.5777 18.9429 17.057L11.8857 9.99984L18.9429 2.94265Z"
                    fill="#4D4D4D" />
            </svg>
        </div>
    </div>

    <div class="sample_report_popup_body">
        <div class="report_img">
            <img src="../../../../assets/images/ithenticate_desc.svg">
             <p>1. Detailed description</p>
        </div>

        <div class="report_img">
            <img src="../../../../assets/images/ithenticate_content.svg">
            <p>2. Shows plagiarised content</p>
        </div>

        <div class="report_img">
            <img src="../../../../assets/images/ithenticate_report.svg">
            <p>3. Shows the source of plagiarism</p>
        </div>

    </div>

    <div class="sample_report_popup_content">
        <div class="report_included">
            <h4 class="report_included_title">What is included?</h4>
            <p> 
                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2559 0.250425C14.5813 0.575862 14.5813 1.1035 14.2559 1.42894L5.92257 9.76227C5.59713 10.0877 5.06949 10.0877 4.74406 9.76227L0.577391 5.5956C0.251954 5.27017 0.251954 4.74253 0.577391 4.41709C0.902828 4.09166 1.43047 4.09166 1.7559 4.41709L5.33331 7.9945L13.0774 0.250425C13.4028 -0.0750116 13.9305 -0.0750116 14.2559 0.250425Z" fill="#591D89"/>
                </svg>
                Checks across all internet & open access sources.
            </p>
            <p> 
                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2559 0.250425C14.5813 0.575862 14.5813 1.1035 14.2559 1.42894L5.92257 9.76227C5.59713 10.0877 5.06949 10.0877 4.74406 9.76227L0.577391 5.5956C0.251954 5.27017 0.251954 4.74253 0.577391 4.41709C0.902828 4.09166 1.43047 4.09166 1.7559 4.41709L5.33331 7.9945L13.0774 0.250425C13.4028 -0.0750116 13.9305 -0.0750116 14.2559 0.250425Z" fill="#591D89"/>
                </svg>
                The most comprehensive report trusted by academic publishers.
            </p>
            <p>
                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2559 0.250425C14.5813 0.575862 14.5813 1.1035 14.2559 1.42894L5.92257 9.76227C5.59713 10.0877 5.06949 10.0877 4.74406 9.76227L0.577391 5.5956C0.251954 5.27017 0.251954 4.74253 0.577391 4.41709C0.902828 4.09166 1.43047 4.09166 1.7559 4.41709L5.33331 7.9945L13.0774 0.250425C13.4028 -0.0750116 13.9305 -0.0750116 14.2559 0.250425Z" fill="#591D89"/>
                </svg>
                The report provides a PDF that lists all sources & highlights 
                for all text sections that were found plagiarised.
            </p>
        </div>

        <div class="report_included">
            <h4 class="report_included_title">What is not included?</h4>
            <p>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5892 1.58909C11.9147 1.26366 11.9147 0.736018 11.5892 0.410582C11.2638 0.0851447 10.7361 0.0851447 10.4107 0.410582L5.99996 4.82133L1.58921 0.410582C1.26378 0.0851447 0.736141 0.0851447 0.410704 0.410582C0.0852667 0.736018 0.0852667 1.26366 0.410704 1.58909L4.82145 5.99984L0.410704 10.4106C0.0852667 10.736 0.0852667 11.2637 0.410704 11.5891C0.736141 11.9145 1.26378 11.9145 1.58921 11.5891L5.99996 7.17835L10.4107 11.5891C10.7361 11.9145 11.2638 11.9145 11.5892 11.5891C11.9147 11.2637 11.9147 10.736 11.5892 10.4106L7.17847 5.99984L11.5892 1.58909Z" fill="#591D89"/>
                </svg>
                Includes everything ! The report includes everything that you will 
                need for a robust plagiarism check.
            </p>
        </div>
    </div>

    <div class="sample_report_popup_footer">
        <a [routerLink]="['plagiarism-checker/report/ithenticate/sample']" (click)="close()">View Sample report</a>
    </div>
</div>