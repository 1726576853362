import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EditorComponent } from './editor.component';
import { MainComponent } from 'src/app/components/editor/main/main.component';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
import { MainComponentNew } from 'src/app/components/editor/main-new/main.component';
import { CKEditorScriptLoader } from './ckeditor-script-loader';

const routes: Routes = [
  {
    path: '', component: EditorComponent, children: [
      // { path: '', redirectTo: 'main/:id', pathMatch: 'full' },
      { path: 'main', component: MainComponent },
      { path: 'main/:id', component: MainComponent,canActivate: [AuthGuardService] },
      { path: 'main/:id/transaction/:transactionPayload', component: MainComponent,canActivate: [AuthGuardService] },
      { path: 'main-v1', component: MainComponentNew },
      { path: 'main-v1/:id', component: MainComponentNew,canActivate: [AuthGuardService] },
      { path: 'main-v1/:id/transaction/:transactionPayload', component: MainComponentNew,canActivate: [AuthGuardService] }
    ],
    canActivate: [AuthGuardService, CKEditorScriptLoader]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditorRoutingModule { }
