import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
 
 @Component({
   selector: 'app-plag-check-report-share',
   templateUrl: './plag-check-report-share.component.html',
   styleUrls: ['./plag-check-report-share.component.scss']
 })
 export class PlagCheckReportShareComponent implements OnInit {
 
   shareForm: FormGroup;
   emailPattern: any;
   selectedProfile: any = {};
   reportName: string;
   downloadUrl: string;
   plagType : any;
   isEmailValid: boolean = true;
   sharedReportEmailId: any = "";
   error_message: any = "";
 
   constructor(
     private dialogRef: MatDialogRef<PlagCheckReportShareComponent>,
     private networkCalls: DashboardService,
     private sharedService: SharedService,
     private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
     private toastr: ToastrService,
     public amplitudeService: AmplitudeService,
     @Inject(MAT_DIALOG_DATA) data,
     private spinner: NgxSpinnerService,
   ) { 
        this.reportName = data.fileName;
        this.downloadUrl = data.downloadUrl;
        this.plagType = data.plagType;
   }  
 
   ngOnInit() {
     this.emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
     this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(profileData => {
        this.selectedProfile = profileData;
     });
   }


 
   shareReport() {
    this.amplitudeService.logAmplitude('Plag_Report_Share_CTA', {
      'Report Type' : this.plagType
    });
     if (this.isEmailValid) {
       let payload = new FormData();
       payload.append("email", this.sharedReportEmailId);
       payload.append("report_name", this.reportName);
       payload.append("username", this.selectedProfile['firstname'] + " " +this.selectedProfile['lastname']);
       payload.append("download_url", this.downloadUrl);
       payload.append("sender_email", this.selectedProfile['email_id']);
       this.spinner.show();
       this.networkCalls.sharePlagCheckReport(payload).subscribe(resp => {
        this.spinner.hide();
        this.dialogRef.close();
        this.amplitudeService.logAmplitude('Plag_Report_Shared', {
          'Report Type' : this.plagType
        });
        this.toastr.success("Report shared via email");
       }, error => {
         this.spinner.hide();
         this.sharedService.errorHandller(error);
       });
 
     }
   }
 
   close() {
     this.dialogRef.close();
   }

   validateEmail(){
    let splitted_emailid = this.sharedReportEmailId.split(",");
    for(var i =0 ; i < splitted_emailid.length; i++ ){
      let email_id = splitted_emailid[i].toLowerCase().trim();
      var validated = this.emailPattern.test(email_id);
      if(validated){
        // emailId_list.push(email_id);
        this.error_message = "";
        this.isEmailValid = true;
      }
      else{
        this.isEmailValid = false;
        this.error_message = "Please check the email address(es).";
        break;
      }
    }
   }
 }