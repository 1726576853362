import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-document-legal-info-modal',
  templateUrl: './document-legal-info-modal.component.html',
  styleUrls: ['./document-legal-info-modal.component.scss']
})
export class DocumentLegalInfoModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DocumentLegalInfoModalComponent>) { }

  ngOnInit() { }

  closeModal(data: any) {
    this.dialogRef.close(data);
  }
}
