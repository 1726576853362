import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plagiarism-report-comparison',
  templateUrl: './plagiarism-report-comparison.component.html',
  styleUrls: ['./plagiarism-report-comparison.component.scss']
})
export class PlagiarismReportComparisonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
