<div class="SamplePlagiarismScore">
    <div class="SamplePlagiarismScoreHeader">
        <h1>Sample Score</h1>
        <button class="cancelbuttons" mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>
    <div class="SamplePlagiarismScoreBody">
        <div class="ScoreBodyImg">
            <img src="../../../../../assets/images/report_list_item.png" alt="">
        </div>
        <div class="ScoreBodyContent">
            <div class="ScoreBodyContentLeft">
                <h3>What do you get?</h3>
                <ul>
                    <li>Plagiarism score including all Internet and paid publications</li>
                    <li>An overview of all sources of similarity</li>
                    <li>Option to generate a comprehensive report using credits </li>
                </ul>
            </div>
            <div class="ScoreBodyContentRight">
                <h3>Recommended Next Steps</h3>
                <ol>
                    <li>Generate the Trinka Plagiarism or iThenticate report to identify all sources.</li>
                    <li>Cite sources or rephrase text to avoid similarity.</li>
                    <li>Recheck plagiarism score.</li>
                </ol>
            </div>
        </div>
    </div>

</div>