import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-plag-check-basic-word-limit-modal',
  templateUrl: './plag-check-basic-word-limit-modal.component.html',
  styleUrls: ['./plag-check-basic-word-limit-modal.component.scss']
})
export class PlagCheckBasicWordLimitModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PlagCheckBasicWordLimitModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onClickAction(value: string) {
    this.dialogRef.close({data :value});
  }

  onCloseAction() {
    this.dialogRef.close({});
  }

}
