<div class="activatedTrinkaLabs">
    <button class="closedModalCTA" (click)="closeModal(null)">
        <img src="../../../../assets/images/close-black.svg" alt="">
      </button>
    <div class="SVGwrapper"> 
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
    </div>
    <h3>Congratulations!</h3>
    <h4>Trinka Labs (Panini-1) is activated on your Cloud Editor.</h4>
    <!-- <p>Enjoy early access to our most advanced grammar error correction model built to make your writing clear, fluent, and impactful.</p> -->
    <span>Note: Panini-1 is activated only on the <strong>Trinka Cloud Editor for the English language.</strong></span>
    <button mat-dialog-close class="language-alert-footer-cancel-btn">Start Writing</button>
</div>