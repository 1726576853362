import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-seconds-loader-modal',
  templateUrl: './seconds-loader-modal.component.html',
  styleUrls: ['./seconds-loader-modal.component.scss']
})
export class SecondsLoaderModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SecondsLoaderModalComponent>,
    private amplitudeService: AmplitudeService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
