import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AutoEditComponent } from '../auto-edit/auto-edit.component';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit {

  fileData : any;
  dataSource = [];
  objectKeys = Object.keys;

  constructor(public sharedService: SharedService,
    private dialogRef: MatDialogRef<AutoEditComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.fileData = data.fileData;
      // this.dataSource = data.fileData['error_report']['categories'];
      this.generateScoreJson(data.fileData['error_report']['categories']);
}

  ngOnInit() {
  }
  

  generateScoreJson(dataSource){
    let keys = Object.keys(dataSource);
    for (let index = 0; index < keys.length; index++) {
      this.dataSource[index] = {};
      this.dataSource[index]['key'] = keys[index]
      this.dataSource[index]['value'] = dataSource[keys[index]]
    }
  }

}
