import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-referral-reward-modal',
  templateUrl: './referral-reward-modal.component.html',
  styleUrls: ['./referral-reward-modal.component.scss']
})
export class ReferralRewardModalComponent implements OnInit {

  userId: any;
  referralReward: number = 0;
  subscriptionUpgradeUrl: string = "";
  userType: string = "";
  showPremiumPlusRedeemMsg : boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) data,
  public sharedService: SharedService,
  public dialogRef: MatDialogRef<ReferralRewardModalComponent>,
  public amplitudeService: AmplitudeService) 
  { 
    this.userId = data.userId;
    this.referralReward = data.referralReward || 0;
    this.subscriptionUpgradeUrl = data.subscriptionUpgradeUrl;
    this.userType = data.userType;
  }

  ngOnInit() {
  }

  referNow(){
    this.amplitudeService.logAmplitude('Refer_trinka',{
      'User ID' : this.userId,
      'Subscription Type': this.sharedService.uppercaseFirstLetter(this.userType),
      "from": "Referral Modal"
    });
    this.dialogRef.close();
    this.sharedService.openShareDialog();
  }

  redeemNow(){
    this.amplitudeService.logAmplitude('Redeem_now',{
      'User ID' : this.userId,
      'Subscription Type': this.sharedService.uppercaseFirstLetter(this.userType)
    });
    if(this.userType == "PREMIUM_PLUS"){
      this.showPremiumPlusRedeemMsg = true;
      return;
    }
    this.dialogRef.close();
    window.open(this.subscriptionUpgradeUrl, "_blank");
  }
}
