<div class="report_body">
    <div class="report_header">
        <div class="logo">
            <div class="report_back_btn" (click)="close_report()">
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.49012e-08 7C1.49012e-08 6.44772 0.447715 6 1 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H1C0.447715 8 1.49012e-08 7.55228 1.49012e-08 7Z" fill="#414E62"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289Z" fill="#414E62"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70711 0.292893C8.09763 0.683417 8.09763 1.31658 7.70711 1.70711L1.70711 7.70711C1.31658 8.09763 0.683417 8.09763 0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893Z" fill="#414E62"/>
                </svg>
                <span>Back to document</span>
            </div>
        </div>
        <div class="share_btn">
            <a class="download" *ngIf="!isSamplePlagCheckReport && !isAdvancePlagCheckReport" (click)="generate_advance_plag_check()">Run Advanced Plagiarism</a>
        </div>
    </div>
    <div class="report_viewer">
        <iframe width="100%" height="100%" frameBorder="0" [src]="plagCheckReportUrl"></iframe>
    </div>
</div>
