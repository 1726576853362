<div class="pricing_outer_section">
    <div class="pricing_top_heading">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h2>Powerful writing at attractive value </h2>
                    <p>Trinka is free for individuals. Enterprises benefit from powerful writing assistance at the best
                        value. </p>
                </div>
            </div>
        </div>
    </div>

    <div class="pricing_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="pricing_cards active">
                        <div class="priciing_title">
                            <h3>Basic</h3>
                            <p>For researchers, technical writers, and academics</p>
                        </div>
                        <div class="divider_line"></div>
                        <div class="pricing_name">
                            <h4>FREE</h4>
                        </div>
                        <div class="divider_line"></div>
                        <div class="list_of_pricing_item">
                            <ul>
                                <li>All premium features <sup>*</sup></li>
                                <li>Free up to 30,000 words/month</li>
                                <li>Available on Microsoft Word</li>
                                <li>Available on Web</li>
                                <li>Robust data security</li>
                            </ul>
                        </div>
                        <div class="pricing_buttons">
                            <a href="/dashboard">Get Started</a>
                            <h5><sup>*</sup> Limited time offer</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="pricing_cards active">
                        <div class="priciing_title">
                            <h3>Enterprise</h3>
                            <p>Best for teams & businesses</p>
                        </div>
                        <div class="divider_line"></div>
                        <div class="pricing_name">
                            <h4>Best Value</h4>
                        </div>
                        <div class="divider_line"></div>
                        <div class="list_of_pricing_item">
                            <ul>
                                <li>All premium features</li>
                                <li>Unlimited access</li>
                                <li>Available on Microsoft Word</li>
                                <li>Available on Web</li>
                                <li>Robust data security</li>
                            </ul>
                        </div>
                        <div class="pricing_buttons">
                            <a href="mailto:sales@trinka.ai">Contact Us</a>
                            <h5 style="visibility: hidden;"><sup>*</sup> Limited time offer</h5>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="pricing_cards active">
                        <div class="priciing_title">
                            <h3>API</h3>
                            <p>Get Trinka within your product</p>
                        </div>
                        <div class="divider_line"></div>
                        <div class="pricing_name">
                            <h4>Best value</h4>
                        </div>
                        <div class="divider_line"></div>
                        <div class="list_of_pricing_item">
                            <ul>
                                <li>All premium features</li>
                                <li>High-volume access</li>
                                <li>API customization</li>
                                <li>Scalable, reliable service</li>
                                <li>Robust data security</li>
                            </ul>
                        </div>
                        <div class="pricing_buttons">
                            <a href="mailto:sales@trinka.ai">Contact Us</a>
                            <h5 style="visibility: hidden;"><sup>*</sup> Limited time offer</h5>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</div>