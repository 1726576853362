import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserExtensionRoutingModule } from './browser-extension-routing.module';
import { BrowserExtensionComponent } from './browser-extension.component';
import { MainComponent } from '../../components/browser-extension/main/main.component';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/_services/interceptors/token.interceptor';
import { MaterialModuleList } from 'src/app/material-modules';
import { CKEditorModule } from 'ngx-ckeditor';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConnectionService } from 'src/app/_socket/connection.service';
import { FilterByCatergoryExtensionPipe } from 'src/app/_pipes/filter-by-catergory-extension.pipe';
import { UninstallComponent } from 'src/app/components/browser-extension/uninstall/uninstall.component';
import { InstallComponent } from 'src/app/components/browser-extension/install/install.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from 'src/app/_guards/auth.service';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
@NgModule({
  declarations: [
    MainComponent,
    InstallComponent,
    UninstallComponent,
    BrowserExtensionComponent,
    FilterByCatergoryExtensionPipe
    ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserExtensionRoutingModule,
    CKEditorModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModuleList,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    ConnectionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ], schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ], entryComponents: [
  ],
  exports:[]
})
export class BrowserExtensionModule { }
