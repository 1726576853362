import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AutoEditComponent } from '../auto-edit/auto-edit.component';
import { environment } from 'src/environments/environment';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credit-details',
  templateUrl: './credit-details.component.html',
  styleUrls: ['./credit-details.component.scss']
})
export class CreditDetailsComponent implements OnInit {

  creditsDetails: any;
  localMeta: any;
  userData: any;
  constructor(public sharedService: SharedService,
    private dialogRef: MatDialogRef<AutoEditComponent>,
    public amplitudeService: AmplitudeService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.creditsDetails = data.creditData;
    }
    

  ngOnInit() {
    this.localMeta = this.sharedService.getLocalStorageMeta();
    let data = {
      "user_id" : btoa(this.localMeta.sub),
      "is_premium" : this.creditsDetails.user_type == 'PREMIUM' ? true : false,
      "trinka_callback_url" : window.location.href
    };

    data = this.sharedService.generateAddCreditsPayload(data.user_id, this.creditsDetails.user_type,window.location.href);
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
  }

  addCredit(){
    let redirct_url  = environment.payment_fe;
    window.open(redirct_url+'/user/credit/'+this.userData, "_self");
    this.amplitudeService.logAmplitude('Add_Credits_CTA',{
      'credit_balance_free':this.creditsDetails.credits,
      'credit_balance_purchased':this.creditsDetails.purchased_credits,
      'Subscription Plan': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'User ID': btoa(this.localMeta.sub),
      'from': this.router.url.includes('/auto-edit') ? 'Proofread' : this.router.url.includes('mydrive') ? 'Mydrive' : this.router.url.includes('mydictionary') ? 'Mydictionary' : 'Profile'
    });
  }

  upgradeToPremium(){
    this.amplitudeService.logAmplitude('auto_edit_premium',{
    });
    let redirct_url  =
    environment.payment_fe;
    window.open(redirct_url+'/user/'+this.userData,"_blank") 
  }

  viewBenefits()
  {
    this.amplitudeService.logAmplitude('auto_edit_premium_benefits',{
    });
    window.open("https://www.trinka.ai/pricing","_blank") 
  }

}
