<ngx-spinner></ngx-spinner>
<website-header></website-header>
<router-outlet (activate)="onActivate($event)"></router-outlet>


<footer>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="footer-top">
                    <div class="row">

                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="powerd-by">
                                        <h3>Powered By:</h3>
                                        <p>
                                            <a href="https://www.enago.com/" target="_blank">
                                                <img src="../../../assets/images/enago-logo.png" class="enago logo" />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="bottom_footer_menus">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="footer_menu_one">
                                                    <h3>Company</h3>
                                                    <ul class="ftr-menu">
                                                        <li><a [routerLink]="['/home/aboutUs']" routerLinkActive="active">About us</a></li>
                                                        <li><a [routerLink]="['/home/contactUs']" routerLinkActive="active">Contact us</a></li>
                                                        <li><a [routerLink]="['/home/workWithUs']" routerLinkActive="active">Work with us</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="row">
                                <div class="footer_social_media">
                                    <div class="col-md-12">
                                        <div class="call-phone">
                                            <div class="email">
                                                <img src="../../../assets/images/email.png" alt="Email">
                                                <a href="mailto:support@trinka.ai">support@trinka.ai</a>
                                            </div>
                                        </div>
                                        <div class="contact">
                                            <form class="form-inline">
                                                <div class="form-group mx-sm-3 mb-2 ml-0">
                                                    <input type="email" class="form-control" id="email-add1"
                                                        placeholder="Your email here for Trinka news" [(ngModel)]="email" name="email">
                                                </div>
                                                <button type="submit" class="btn btn-primary mb-2" (click)="subscribeNow()">Subscribe</button>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="footer-bottom">
                    <div class="row">
                        <div class="col-md-6">
                            <p>© Crimson AI, 2019. Made in India.</p>
                        </div>
                        <div class="col-md-6">
                            <ul class="ftr-menu1">
                                <li><a  [routerLink]="['/home/privacypolicy']" routerLinkActive="active">Privacy Policy</a></li>
                                <li><a  [routerLink]="['/home/termsOfServices']" routerLinkActive="active">Terms of Services</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<script>


    $('#myCarousel').carousel({
        interval: false
    });
    $("#myCarousel").on("touchstart", function (event) {

        var yClick = event.originalEvent.touches[0].pageY;
        $(this).one("touchmove", function (event) {

            var yMove = event.originalEvent.touches[0].pageY;
            if (Math.floor(yClick - yMove) > 1) {
                $(".carousel").carousel('next');
            }
            else if (Math.floor(yClick - yMove) < -1) {
                $(".carousel").carousel('prev');
            }
        });
        $(".carousel").on("touchend", function () {
            $(this).off("touchmove");
        });
    });

</script>