<!-- <div class="notificaitonOverlay" *ngIf="notificationPermisson == 'default'  && step<=2">
  <svg width="394" height="277" viewBox="0 0 394 277" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M145.54 272.057C50.0585 64.1172 346.22 -50.0691 384.342 29.6295C434.246 133.962 108.482 194.582 30.7602 32.0954M30.7602 32.0954L89.5835 45.2114M30.7602 32.0954L4.84698 77.4933"
      stroke="white" stroke-width="8" stroke-linecap="round" />
  </svg>
  <h1>We will notify you when your report is ready</h1>
</div> -->
<!-- <div class="pageLoader">
  <svg class="spinner">
    <circle>
      <animateTransform attributeName="transform" type="rotate" values="-90;810" keyTimes="0;1"
        dur="2s" repeatCount="indefinite"></animateTransform>
      <animate attributeName="stroke-dashoffset" values="0%;0%;-157.080%" calcMode="spline"
        keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s"
        repeatCount="indefinite"></animate>
      <animate attributeName="stroke-dasharray" values="0% 314.159%;157.080% 157.080%;0% 314.159%"
        calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s"
        repeatCount="indefinite"></animate>
    </circle>
  </svg>
  <span>Opening Plagiarism Checker...</span>
</div> -->
<header class="header-wrapper">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-8">
        <div class="logo">
          <img src="../../../assets/images/Trinka_Logo.svg" (click)="navigationToDashboard()" [routerLink]="['/dashboard']" alt="logo" />
          <span class="tags-box" *ngIf="creditsDetails?.user_type === 'BASIC'">Basic</span>
          <span class="tags-box-premium" *ngIf="creditsDetails?.user_type === 'PREMIUM'">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.187 4.35161L9.41105 1.67033C9.34045 1.56408 9.22115 1.5 9.09353 1.5H2.90684C2.77913 1.5 2.65992 1.56409 2.58931 1.67033L0.813334 4.35161C0.718569 4.49408 0.731218 4.68211 0.844103 4.81027L5.71367 10.3701C5.78559 10.4528 5.88998 10.5 5.99995 10.5C6.10992 10.5 6.2143 10.4528 6.2866 10.3698L11.1562 4.8099C11.2687 4.6821 11.2813 4.49408 11.187 4.35161ZM3.84922 4.9405L5.02944 8.43581L1.96799 4.9405H3.84922ZM6.87805 2.25922L7.38839 4.18168H4.61062L5.12097 2.25922H6.87805ZM7.34695 4.9405L5.99977 8.93135L4.65259 4.9405H7.34695ZM8.15037 4.9405H10.0317L6.97023 8.43581L8.15037 4.9405ZM10.1619 4.18127H8.17623L7.66588 2.25881H8.88875L10.1619 4.18127ZM3.11118 2.2592H4.33405L3.8237 4.18165H1.83753L3.11118 2.2592Z" fill="#92400E"/>
            </svg>  
            Premium
          </span>
          <span class="tags-box-premium-plus" *ngIf="creditsDetails?.user_type === 'PREMIUM_PLUS'">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.935 6.0381L10.0987 3.43811C10.0257 3.33509 9.90237 3.27295 9.77042 3.27295H3.37364C3.24159 3.27295 3.11833 3.33509 3.04533 3.43811L1.20904 6.0381C1.11106 6.17625 1.12414 6.35857 1.24085 6.48285L6.27578 11.8741C6.35015 11.9543 6.45808 12.0001 6.57179 12.0001C6.68549 12.0001 6.79342 11.9543 6.86817 11.8738L11.9031 6.48249C12.0194 6.35857 12.0325 6.17625 11.935 6.0381ZM4.34802 6.60914L5.56831 9.99846L2.4029 6.60914H4.34802ZM7.4797 4.00915L8.00738 5.87331H5.13527L5.66295 4.00915H7.4797ZM7.96453 6.60914L6.5716 10.479L5.17867 6.60914H7.96453ZM8.79523 6.60914H10.7404L7.57501 9.99846L8.79523 6.60914ZM10.8751 5.87292H8.82197L8.29429 4.00875H9.55868L10.8751 5.87292ZM3.58492 4.00913H4.84931L4.32164 5.87329H2.26802L3.58492 4.00913Z" fill="#92400E"/>
              <path d="M2.85775 0L3.32074 1.19438L4.57194 1.63633L3.32074 2.07829L2.85775 3.27267L2.39476 2.07829L1.14355 1.63633L2.39476 1.19438L2.85775 0Z" fill="#92400E"/>
              <path d="M1.1428 2.72705L1.45146 3.5233L2.28559 3.81794L1.45146 4.11258L1.1428 4.90883L0.834138 4.11258L0 3.81794L0.834138 3.5233L1.1428 2.72705Z" fill="#92400E"/>
            </svg>
            Premium Plus
          </span>
        </div>
        <div class="plagCheckHeading">
          <h1>Plagiarism Checker</h1>
          <span (click)="credits()" class="credits-details-txt" *ngIf="creditsDetails && (creditsDetails.credits >= 0 || creditsDetails.purchased_credits >= 0)">Credits: {{ creditsDetails.credits + creditsDetails.purchased_credits }}</span>
          <p  *ngIf="creditsDetails.user_type === 'PREMIUM_PLUS'" class="onHoverTextStandalone">Page<b *ngIf="creditsDetails.plagiarism_free_page_count_scan > 1">s</b> available: 
            {{ creditsDetails.plagiarism_free_page_count_scan }}
            <i>{{ creditsDetails.plagiarism_free_page_count_scan }} Page<b *ngIf="creditsDetails.plagiarism_free_page_count_scan > 1">s</b> available for Standared Plagiarim Check</i>
          </p>
          <span class="referralReferralHeaderText" *ngIf="country!='IN'" (click)="showReferralRewardModal()">Rewards: <i>${{referralReward}}</i></span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="media media-credits">
          <div class="media-body">
            <button [ngClass]="paymentDetails?.user_type == 'PREMIUM'? 'plag-check-upgrade-btn editorUpgradeButtonPremiumPlus' : paymentDetails?.user_type == 'BASIC' ? 'plag-check-upgrade-btn': '' " *ngIf="paymentDetails?.user_type !== 'PREMIUM_PLUS' && paymentDetails?.user_type !== 'PREMIUM_TRIAL' && paymentDetails?.user_type !== 'INSTITUTIONAL_ACCESS'" (click)="redirectToUpgradeToPremiumPage(true)">
                    <svg *ngIf="paymentDetails?.user_type == 'BASIC'" width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.187 4.35161L9.41105 1.67033C9.34045 1.56408 9.22115 1.5 9.09353 1.5H2.90684C2.77913 1.5 2.65992 1.56409 2.58931 1.67033L0.813334 4.35161C0.718569 4.49408 0.731218 4.68211 0.844103 4.81027L5.71367 10.3701C5.78559 10.4528 5.88998 10.5 5.99995 10.5C6.10992 10.5 6.2143 10.4528 6.2866 10.3698L11.1562 4.8099C11.2687 4.6821 11.2813 4.49408 11.187 4.35161ZM3.84922 4.9405L5.02944 8.43581L1.96799 4.9405H3.84922ZM6.87805 2.25922L7.38839 4.18168H4.61062L5.12097 2.25922H6.87805ZM7.34695 4.9405L5.99977 8.93135L4.65259 4.9405H7.34695ZM8.15037 4.9405H10.0317L6.97023 8.43581L8.15037 4.9405ZM10.1619 4.18127H8.17623L7.66588 2.25881H8.88875L10.1619 4.18127ZM3.11118 2.2592H4.33405L3.8237 4.18165H1.83753L3.11118 2.2592Z" fill="#92400E"/>
                    </svg>
                    <svg *ngIf="paymentDetails?.user_type == 'PREMIUM'" width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.935 6.0381L10.0987 3.43811C10.0257 3.33509 9.90237 3.27295 9.77042 3.27295H3.37364C3.24159 3.27295 3.11833 3.33509 3.04533 3.43811L1.20904 6.0381C1.11106 6.17625 1.12414 6.35857 1.24085 6.48285L6.27578 11.8741C6.35015 11.9543 6.45808 12.0001 6.57179 12.0001C6.68549 12.0001 6.79342 11.9543 6.86817 11.8738L11.9031 6.48249C12.0194 6.35857 12.0325 6.17625 11.935 6.0381ZM4.34802 6.60914L5.56831 9.99846L2.4029 6.60914H4.34802ZM7.4797 4.00915L8.00738 5.87331H5.13527L5.66295 4.00915H7.4797ZM7.96453 6.60914L6.5716 10.479L5.17867 6.60914H7.96453ZM8.79523 6.60914H10.7404L7.57501 9.99846L8.79523 6.60914ZM10.8751 5.87292H8.82197L8.29429 4.00875H9.55868L10.8751 5.87292ZM3.58492 4.00913H4.84931L4.32164 5.87329H2.26802L3.58492 4.00913Z" fill="#92400E"/>
                        <path d="M2.85775 0L3.32074 1.19438L4.57194 1.63633L3.32074 2.07829L2.85775 3.27267L2.39476 2.07829L1.14355 1.63633L2.39476 1.19438L2.85775 0Z" fill="#92400E"/>
                        <path d="M1.1428 2.72705L1.45146 3.5233L2.28559 3.81794L1.45146 4.11258L1.1428 4.90883L0.834138 4.11258L0 3.81794L0.834138 3.5233L1.1428 2.72705Z" fill="#92400E"/>
                    </svg>

                    <span *ngIf="paymentDetails?.user_type == 'BASIC'">Upgrade</span>
                    <span *ngIf="paymentDetails?.user_type == 'PREMIUM'">Upgrade to Premium Plus</span>
            </button>
            <button class="plag-check-upgrade-btn institutional_btn" *ngIf="paymentDetails?.user_type&&paymentDetails?.user_type === 'INSTITUTIONAL_ACCESS'" >
              <img src="../../../assets/images/institutional.svg" alt="Browser Icon"/>
              <span>Institutional Access</span>
            </button>
            <!-- <p>
              <img src="../../../assets/images/reference-quality/Credit-icons.svg" alt="vector" />
              Credits: <strong>
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits != 0"
                  (click)="credits()">{{ creditsDetails.credits + creditsDetails.purchased_credits ||
                  0 }}</span>
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0"
                  (click)="credits()">Nil</span>
                <span *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0
                        " class="autoEditBuyOption" (click)="addCredits()">Buy</span>

              </strong>
            </p> -->
          </div>
          <div class="media-right">
            <button mat-icon-button [matMenuTriggerFor]="menu1" class="p-0">
              <span class="name-initial">{{profileName}}</span>
            </button>
            <mat-menu #menu1="matMenu" class="profile-menu personalize_top_menus">
              <a mat-menu-item [routerLink]="['/dashboard/profile']">
                <img class="img-fluid" src="../../../../assets/images/Icon_ManageProfile.svg" alt="logo favicon">
                <span> Account Settings</span>
              </a>
              <button mat-menu-item (click)="signOut()"><img class="img-fluid"
                  src="../../../../assets/images/Icon_Logout.svg" alt="logo favicon">
                Logout</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div *ngIf="!fileSelected && creditsDetails.user_type !== 'INSTITUTIONAL_ACCESS'" style="visibility:hidden; opacity:0" id="dropzone" (dragleave)="onDragEnd($event)"
  (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
  <span class="topLeftConner_topSide"></span>
  <span class="topLeftConner_leftSide"></span>
  <span class="topLeftConner_topSide" style="left: auto; right: 70px;"></span>
  <span class="topLeftConner_leftSide" style="left: auto; right: 70px;"></span>
  <div id="textnode">Drop file anywhere</div>
  <span class="bottomleftConner_bottomLeftSide"></span>
  <span class="bottomRightConner_bottomRightside"></span>
  <span class="bottomleftConner_bottomLeftSide" style="left: auto; right: 70px;"></span>
  <span class="bottomRightConner_bottomRightside" style="left: auto; right: 70px;"></span>
</div>


<section class="section-md plagCheckHomePage">
  <div class="upgradeToPremiumBar" *ngIf="(creditsDetails.user_type !== 'PREMIUM_PLUS' && creditsDetails.user_type !== 'INSTITUTIONAL_ACCESS') && !fileSelected">
    <p>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0537 8.0507L13.7165 4.58399C13.6235 4.44662 13.4665 4.36377 13.2986 4.36377H5.1568C4.98873 4.36377 4.83184 4.44663 4.73893 4.58399L2.40171 8.0507C2.277 8.23491 2.29364 8.47801 2.4422 8.64372L8.85063 15.8322C8.94528 15.9391 9.08266 16.0001 9.22739 16.0001C9.37211 16.0001 9.50948 15.9391 9.60462 15.8317L16.013 8.64323C16.1611 8.478 16.1778 8.23491 16.0537 8.0507ZM6.39698 8.8121L7.95017 13.3313L3.92125 8.8121H6.39698ZM10.383 5.34539L11.0546 7.83099H7.399L8.07062 5.34539H10.383ZM11.0001 8.8121L9.22715 13.972L7.45423 8.8121H11.0001ZM12.0574 8.8121H14.5332L10.5043 13.3313L12.0574 8.8121ZM14.7046 7.83046H12.0914L11.4198 5.34486H13.0291L14.7046 7.83046ZM5.42572 5.34536H7.03503L6.3634 7.83096H3.74957L5.42572 5.34536Z" fill="#7A28A0"/>
        <path d="M4.50018 0L5.08947 1.59253L6.682 2.18182L5.08947 2.77111L4.50018 4.36364L3.91089 2.77111L2.31836 2.18182L3.91089 1.59253L4.50018 0Z" fill="#7A28A0"/>
        <path d="M2.31831 3.63623L2.71117 4.69792L3.77286 5.09078L2.71117 5.48364L2.31831 6.54532L1.92546 5.48364L0.86377 5.09078L1.92546 4.69792L2.31831 3.63623Z" fill="#7A28A0"/>
      </svg>
      <span (click)="redirectToUpgradeToPremiumPage()">Upgrade </span> to Premium Plus & get Unlimited Standard Plagiarism Checks
    </p>
  </div>
  <div class="container">
    <div class="row" *ngIf="!fileSelected">

      <div class="col-md-6">
        <div class="plagCheckMainHeadingSection">
          <div class="plagCheckArrowSeciton">
            <svg width="86" height="40" viewBox="0 0 86 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.00001 2.0069C23.2366 16.2202 50.6819 25.4756 76.6036 24.8313" stroke="#7A28A0" stroke-width="4" stroke-linecap="round"/>
              <path d="M65.3617 12.7703C70.5327 20.0124 77.1564 24.8165 83.3515 24.6625" stroke="#7A28A0" stroke-width="4" stroke-linecap="round"/>
              <path d="M65.9747 37.4338C70.7795 29.9438 77.1564 24.8165 83.3515 24.6625" stroke="#7A28A0" stroke-width="4" stroke-linecap="round"/>
              </svg>
          </div>
          <h1>Check Plagiarism <span> with highest accuracy</span></h1>
          <!-- <h1>Check Plagiarism<br /> <span>with highest accuracy</span></h1> -->
          <div class="plagCheckViewReportSection">
              <p><span>Standard Plagiarism Check</span> : Checks plagiarism against internet sources.</p>
              <h4>Powered by 
                <img src="../../../assets/images/turnitinImg.png" alt="" style="width: 80px;">
                <a (click)="view_report(3)">View sample</a>
              </h4>
          </div>
          <div class="plagCheckViewReportSection">
            <p><span>Advanced Plagiarism Check</span> : Checks plagiarism against internet and paid publications.</p>
            <!-- <a (click)="openSampleReportModal(true)">View sample</a> -->
            <h4 class="ithenticatePoweredBy">Powered by 
              <img src="../../../assets/images/ithentication_img.svg" alt="" style="width: 80px;">
              <a (click)="view_report(2)">View sample</a>
            </h4>
          </div>
          <!-- <div class="GetOneAdvancePlagCheck">
            <p>Get free <b>Advanced Plagiarism Score</b> every month</p>
          </div> -->
        </div>
      </div>

      <div class="col-md-6">
        <div class="dragAreaOverlay">
          <svg width="547" height="604" viewBox="0 0 547 604" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_1433_8410)">
              <circle cx="334" cy="270" r="84" fill="#FADFFB" />
            </g>
            <defs>
              <filter id="filter0_f_1433_8410" x="0" y="-64" width="668" height="668" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_1433_8410" />
              </filter>
            </defs>
          </svg>
        </div>
        <div class="drag-area">
          <div class="dragAreaBorderd" *ngIf="creditsDetails.user_type !== 'INSTITUTIONAL_ACCESS'">
            <div class="icon" (click)="referenceQuality.click()">
              <svg width="38" height="31" viewBox="0 0 38 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.887 4.0488C19.8774 3.86524 18.8364 3.86243 17.8255 4.04058C16.8146 4.21872 15.8601 4.57314 15.0158 5.07762C13.3096 6.09699 12.1759 7.65057 11.7928 9.35737L10.1666 8.99234V10.659C8.50578 10.659 6.92707 11.2908 5.77428 12.3934C4.62401 13.4937 3.99236 14.9697 3.99236 16.4923C3.99236 18.0149 4.62401 19.491 5.77428 20.5912C6.92707 21.6939 8.50578 22.3256 10.1666 22.3256C11.0871 22.3256 11.8333 23.0718 11.8333 23.9923C11.8333 24.9128 11.0871 25.659 10.1666 25.659C7.66835 25.659 5.25833 24.7104 3.47021 23C1.67956 21.2872 0.659027 18.948 0.659027 16.4923C0.659027 14.0367 1.67956 11.6974 3.47021 9.98463C4.95429 8.56508 6.86675 7.67028 8.90251 7.40703C9.6993 5.26321 11.2581 3.43973 13.3061 2.21613C14.5081 1.49797 15.8474 1.00447 17.247 0.75783C18.6465 0.511197 20.0853 0.515055 21.4833 0.769234C22.8813 1.02342 24.2176 1.52418 25.4151 2.24898C26.6128 2.9739 27.6519 3.91115 28.4658 5.01454C29.2801 6.11847 29.8519 7.36595 30.1394 8.68735C30.2812 9.33895 30.352 10.0002 30.3518 10.6613C32.2738 10.7087 34.1071 11.4929 35.4699 12.8557C36.8764 14.2622 37.6666 16.1699 37.6666 18.159C37.6666 20.1481 36.8764 22.0558 35.4699 23.4623C34.0634 24.8688 32.1557 25.659 30.1666 25.659H28.4999C27.5795 25.659 26.8333 24.9128 26.8333 23.9923C26.8333 23.0718 27.5795 22.3256 28.4999 22.3256H30.1666C31.2717 22.3256 32.3315 21.8867 33.1129 21.1053C33.8943 20.3239 34.3333 19.2641 34.3333 18.159C34.3333 17.0539 33.8943 15.9941 33.1129 15.2127C32.3315 14.4313 31.2717 13.9923 30.1666 13.9923H28.4999C27.9938 13.9923 27.5151 13.7623 27.1988 13.3672C26.8825 12.972 26.7629 12.4545 26.8737 11.9606C27.0638 11.1139 27.0667 10.2435 26.8823 9.39599C26.6978 8.548 26.3275 7.731 25.7833 6.99324C25.2387 6.25494 24.5296 5.60935 23.6891 5.10065C22.8484 4.59183 21.8965 4.23236 20.887 4.0488Z"
                  fill="#4D4D4D" />
                <path
                  d="M19.138 12.46C19.3346 12.5413 19.5187 12.6619 19.6785 12.8217L24.6785 17.8217C25.3294 18.4725 25.3294 19.5278 24.6785 20.1787C24.0277 20.8295 22.9724 20.8295 22.3215 20.1787L20.1667 18.0239V29.0002C20.1667 29.9206 19.4205 30.6668 18.5 30.6668C17.5795 30.6668 16.8333 29.9206 16.8333 29.0002V18.0239L14.6785 20.1787C14.0276 20.8295 12.9724 20.8295 12.3215 20.1787C11.6706 19.5278 11.6706 18.4725 12.3215 17.8217L17.3205 12.8226C17.3241 12.819 17.3288 12.8144 17.3324 12.8108C17.8232 12.3288 18.5383 12.2119 19.138 12.46Z"
                  fill="#4D4D4D" />
                <path
                  d="M19.138 12.46C18.9415 12.3785 18.726 12.3335 18.5 12.3335C18.0453 12.3335 17.6331 12.5156 17.3324 12.8108"
                  fill="#4D4D4D" />
              </svg>
            </div>
            <p class="dragDropUpload" (click)="referenceQuality.click()">Drag & drop a file to get started</p>
            <p class="docTypeSection">doc/docx/pdf file type (Max 100 MB)</p>
            <span class="dividedLineSection">or</span>
            <ul>
              <li (click)="launchTrinkaDrivePicker()">
                <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M22.1086 9.65507C22.1086 9.46787 22.387 10.1975 18.7486 1.44707C18.6981 1.32582 18.6128 1.2223 18.5034 1.14965C18.394 1.07701 18.2655 1.03852 18.1342 1.03907H5.52938C5.40604 1.03844 5.28498 1.07225 5.17982 1.1367C5.07466 1.20115 4.98959 1.29368 4.93418 1.40387C0.614178 10.0439 0.911778 9.41507 0.892578 9.56387C0.892578 9.62627 0.892578 9.17027 0.892578 16.2839C0.892578 16.3709 0.909712 16.457 0.943 16.5374C0.976289 16.6177 1.02508 16.6908 1.08659 16.7523C1.1481 16.8138 1.22112 16.8626 1.30149 16.8959C1.38185 16.9291 1.46799 16.9463 1.55498 16.9463H21.4462C21.6202 16.9463 21.7873 16.8778 21.9112 16.7557C22.0352 16.6335 22.1061 16.4675 22.1086 16.2935C22.1086 16.1831 22.1086 9.70307 22.1086 9.65507ZM22.1086 9.60707H0.911778H22.1086Z"
                    fill="#FDF1FD" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>Trinka My Drive</p>
              </li>
              <li (click)="referenceQuality.click()">
                <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.5 0.00732422C1.39543 0.00732422 0.5 0.902755 0.5 2.00732V12.0073C0.5 13.1119 1.39543 14.0073 2.5 14.0073H6.5V15.9923H5.5C4.94772 15.9923 4.5 16.44 4.5 16.9923C4.5 17.5446 4.94772 17.9923 5.5 17.9923H7.3264C7.38278 18.0022 7.44079 18.0073 7.5 18.0073C7.55921 18.0073 7.61722 18.0022 7.6736 17.9923H13.3264C13.3828 18.0022 13.4408 18.0073 13.5 18.0073C13.5592 18.0073 13.6172 18.0022 13.6736 17.9923H15.5C16.0523 17.9923 16.5 17.5446 16.5 16.9923C16.5 16.44 16.0523 15.9923 15.5 15.9923H14.5V14.0073H18.5C19.6046 14.0073 20.5 13.1119 20.5 12.0073V2.00732C20.5 0.902755 19.6046 0.00732422 18.5 0.00732422H2.5ZM18.5 12.0073V2.00732H2.5V12.0073H18.5ZM12.5 14.0073V15.9923H8.5V14.0073H12.5Z"
                    fill="#4D4D4D" />
                </svg>
                <p>My Device</p>
              </li>
              <li (click)="launchOneDrivePicker()">
                <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1379_8421)">
                    <path
                      d="M9.99992 10.7328L5.04599 5.83248C6.07499 1.68218 10.1619 -0.819345 14.1744 0.245091C15.9813 0.724398 17.5506 1.88292 18.5762 3.49455C18.4996 3.49261 9.99992 10.7328 9.99992 10.7328Z"
                      fill="#0364B8" />
                    <path
                      d="M15.3483 4.4167L15.3485 4.41758C16.2951 3.81115 17.3868 3.49062 18.5003 3.49219C18.526 3.49219 18.5513 3.49384 18.577 3.49413C21.8889 3.53661 24.5405 6.34814 24.4995 9.77389C24.4847 11.0165 24.1093 12.2259 23.4221 13.2452L16.0003 12.2838L10.3103 7.53806L15.3483 4.4167Z"
                      fill="#0078D4" />
                    <path
                      d="M5.04705 5.83302C5.15657 5.825 5.26632 5.82076 5.37611 5.82031C6.04067 5.81965 6.6983 5.96005 7.3083 6.23282L7.30848 6.23234L10.3105 7.53901L7.00098 11.7676L1.08442 13.2552C-0.192453 10.8035 0.694048 7.74536 3.06442 6.42463C3.67692 6.08339 4.35294 5.8817 5.04705 5.83311V5.83302Z"
                      fill="#1490DF" />
                    <path
                      d="M23.4219 13.2463C22.8698 14.0674 22.1338 14.738 21.2767 15.201C20.4196 15.6639 19.4669 15.9055 18.5 15.9049H5.37556C4.4956 15.9052 3.63198 15.659 2.87637 15.1925C2.12076 14.726 1.50135 14.0567 1.08388 13.2554L10.3101 7.53906L23.4219 13.2463Z"
                      fill="#28A8EA" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1379_8421">
                      <rect width="24" height="16" fill="white" transform="matrix(-1 0 0 1 24.5 0)" />
                    </clipPath>
                  </defs>
                </svg>
                <p>OneDrive</p>
              </li>
              <li (click)="launchDropBoxPicker()">
                <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.4804 7.24196C17.8413 8.35437 19.1928 9.46073 20.555 10.5744C20.5052 10.6113 20.4655 10.6427 20.4211 10.6712C18.5405 11.9234 16.6611 13.1775 14.7817 14.4327C14.6969 14.4896 14.643 14.5047 14.5535 14.4291C13.2494 13.3112 11.9418 12.1976 10.6341 11.084C10.6033 11.0579 10.5725 11.0319 10.5281 10.9956C9.14519 12.1746 7.77533 13.3427 6.40013 14.5132C4.43599 13.2035 2.47895 11.8986 0.5 10.5792C1.86927 9.45831 3.2184 8.35437 4.57937 7.24196C3.2184 6.12834 1.86809 5.02319 0.505925 3.90956C0.559843 3.86962 0.600133 3.83754 0.642793 3.80909C2.51747 2.55869 4.39333 1.30768 6.268 0.0572726C6.34443 0.00643329 6.40013 -0.0359328 6.49671 0.0463785C7.81265 1.17513 9.13274 2.29844 10.4522 3.42235C10.4724 3.43809 10.4943 3.45322 10.5376 3.48409C8.54261 4.74297 6.57077 5.98671 4.58115 7.24196C6.56899 8.496 8.53965 9.73853 10.5287 10.9926C12.5183 9.73853 14.4896 8.496 16.4774 7.24196C14.486 5.9855 12.5159 4.74297 10.5352 3.49256C10.5856 3.44535 10.6229 3.40964 10.662 3.37575C11.9489 2.27907 13.237 1.18361 14.5221 0.0832975C14.6187 0.000380973 14.6833 -0.0171707 14.8006 0.060904C16.6729 1.31494 18.5488 2.56535 20.4235 3.81515C20.4614 3.84117 20.5011 3.86962 20.5556 3.90835C19.1976 5.01956 17.8473 6.1235 16.4804 7.24196ZM10.5429 11.7509C9.15941 12.9233 7.78244 14.0889 6.40309 15.2601C5.81592 14.8691 5.23646 14.4835 4.6327 14.0805C4.6327 14.5259 4.62914 14.935 4.63685 15.3442C4.63744 15.3853 4.69432 15.4398 4.73698 15.4658C6.63832 16.6339 8.54084 17.7984 10.4422 18.9659C10.5127 19.0076 10.5654 19.0137 10.6395 18.9695C12.5456 17.7984 14.4534 16.6315 16.3559 15.458C16.4045 15.4283 16.446 15.3454 16.4484 15.2861C16.4608 15.0724 16.4537 14.8588 16.4537 14.6457C16.4537 14.4672 16.4537 14.2899 16.4537 14.0786C15.8482 14.4829 15.2711 14.8679 14.6851 15.2582C13.301 14.0865 11.9258 12.9227 10.5429 11.7509Z"
                    fill="#017EE6" />
                </svg>
                <p>DropBox</p>
              </li>
              <li (click)="launchGoogleDrivePicker()">
                <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1379_8435)">
                    <path
                      d="M2.01203 14.5562L2.89406 16.0038C3.07734 16.3086 3.34078 16.5481 3.65008 16.7223C4.53602 15.6537 5.15258 14.8337 5.50008 14.2623C5.85266 13.6825 6.28602 12.7754 6.80016 11.5412C5.41453 11.3679 4.36453 11.2812 3.65016 11.2812C2.96445 11.2812 1.91445 11.3679 0.5 11.5412C0.5 11.8786 0.591641 12.216 0.774922 12.5208L2.01203 14.5562Z"
                      fill="#0066DA" />
                    <path
                      d="M17.3495 16.7223C17.6589 16.5481 17.9223 16.3086 18.1055 16.0039L18.4721 15.4053L20.2248 12.5208C20.4047 12.2226 20.4994 11.8849 20.4996 11.5412C19.077 11.3679 18.0289 11.2812 17.3553 11.2812C16.6314 11.2812 15.5833 11.3679 14.2109 11.5412C14.719 12.7821 15.1466 13.6892 15.4939 14.2623C15.8441 14.8406 16.4627 15.6605 17.3495 16.7223Z"
                      fill="#EA4335" />
                    <path
                      d="M10.4997 5.4423C11.5247 4.26604 12.2311 3.35895 12.6188 2.72119C12.9311 2.20762 13.2748 1.38762 13.6498 0.261236C13.3405 0.0870786 12.9854 0 12.6188 0H8.38055C8.01398 0 7.65898 0.0979913 7.34961 0.261236C7.82664 1.55309 8.23148 2.4725 8.56398 3.01939C8.93148 3.62382 9.57672 4.43143 10.4997 5.4423Z"
                      fill="#00832D" />
                    <path
                      d="M14.2002 11.5391H6.80039L3.65039 16.7201C3.95961 16.8943 4.31469 16.9814 4.68125 16.9814H16.3194C16.6859 16.9814 17.0411 16.8834 17.3503 16.7201L14.2003 11.5391H14.2002Z"
                      fill="#2684FC" />
                    <path
                      d="M10.5001 5.44662L7.35008 0.265625C7.0407 0.439782 6.77727 0.679193 6.59398 0.984005L0.774922 10.5624C0.59501 10.8606 0.500238 11.1983 0.5 11.542H6.80016L10.5002 5.44662H10.5001Z"
                      fill="#00AC47" />
                    <path
                      d="M17.3156 5.77325L14.406 0.984005C14.2228 0.679193 13.9593 0.439782 13.65 0.265625L10.5 5.44669L14.1998 11.5421H20.4886C20.4886 11.2046 20.397 10.8673 20.2137 10.5625L17.3156 5.77325Z"
                      fill="#FFBA00" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1379_8435">
                      <rect width="20" height="17" fill="white" transform="translate(0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Google Drive</p>
              </li>
            </ul>
            <input type="file" #referenceQuality name="myfile" (change)="onFileChange($event.target.files)" />
          </div>
          <div class="institutionalPlanStandalone" *ngIf="creditsDetails.user_type === 'INSTITUTIONAL_ACCESS'">
            <svg width="50" height="50" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.0009 29.3627C22.5763 29.3627 23.8534 28.0856 23.8534 26.5102C23.8534 24.9348 22.5763 23.6577 21.0009 23.6577C19.4255 23.6577 18.1484 24.9348 18.1484 26.5102C18.1484 28.0856 19.4255 29.3627 21.0009 29.3627Z" fill="#7A28A0"/>
              <path d="M27.975 17.16C21.825 17.16 14.025 17.16 14.025 17.16C13.905 17.16 13.8 17.16 13.68 17.16V15.42C13.68 11.025 14.925 8.1 21 8.1C27.495 8.1 28.32 11.265 28.32 14.025C28.32 14.61 28.665 35.9693 29.25 35.9693C29.835 35.9693 30.42 14.61 30.42 14.025C30.42 8.7 27.255 6 21 6C12.555 6 11.58 11.37 11.58 15.42V17.295C7.38 17.82 6 19.95 6 25.185V27.975C6 34.125 7.875 36 14.025 36H27.975C34.125 36 36 34.125 36 27.975V25.185C36 19.035 34.125 17.16 27.975 17.16ZM21 31.11C18.495 31.11 16.47 29.07 16.47 26.58C16.47 24.075 18.51 22.05 21 22.05C23.49 22.05 25.53 24.09 25.53 26.58C25.53 29.085 23.505 31.11 21 31.11Z" fill="#7A28A0"/>
            </svg>
            <p>You currently don't have access to the Plagiarism Check feature under your institutional plan. To use this feature, we recommend creating a new Trinka account with your personal email.</p>
            <a href="{{signUpPageUrl}}" target="_blank">Create New Account</a>
          </div>
        </div>
        <div class="yourSecureSection">
          <!-- <div class="getFreeAdvancedPlagiaris">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.1314 6.5814C16.3316 6.40767 16.2246 6.07871 15.9605 6.05594L10.8548 5.61567C10.7442 5.60613 10.6479 5.53623 10.6045 5.434L8.60969 0.734564C8.50622 0.490784 8.16067 0.490879 8.05733 0.734715L6.06226 5.44193C6.01884 5.54438 5.92228 5.61438 5.81141 5.62379L0.70849 6.05672C0.444062 6.07915 0.336839 6.40863 0.537418 6.58239L4.41314 9.93993C4.49731 10.0128 4.53416 10.1264 4.50886 10.2348L3.34486 15.2244C3.28457 15.4829 3.56484 15.6866 3.79203 15.5494L8.17831 12.9021C8.27365 12.8445 8.39302 12.8445 8.48835 12.9021L12.8758 15.5501C13.1028 15.6872 13.3829 15.4839 13.323 15.2255L12.1658 10.2344C12.1407 10.1262 12.1775 10.0129 12.2614 9.94011L16.1314 6.5814ZM8.48845 11.344C8.39307 11.2863 8.27357 11.2862 8.17815 11.3439L5.80905 12.7741C5.58181 12.9113 5.30147 12.7075 5.36186 12.4491L5.99215 9.75142C6.01748 9.64302 5.9807 9.5295 5.8966 9.45655L3.80185 7.63942C3.60167 7.46577 3.70849 7.13683 3.9725 7.11393L6.73648 6.87413C6.84731 6.86451 6.94372 6.79435 6.98696 6.69185L8.05739 4.15432C8.16047 3.90994 8.50669 3.90973 8.61007 4.15398L9.68793 6.7005C9.73124 6.80283 9.82757 6.87284 9.93827 6.88244L12.7025 7.12226C12.9665 7.14516 13.0733 7.4741 12.8731 7.64775L10.7784 9.46488C10.6943 9.53783 10.6575 9.65135 10.6828 9.75976L11.313 12.4566C11.3734 12.7152 11.0928 12.919 10.8656 12.7816L8.48845 11.344Z" fill="#7A28A0" fill-opacity="0.7"/>
            </svg>
            Get free <span>Advanced Plagiarism</span> Score every month
          </div> -->
          <div class="yourDataIsSecure">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.3335 10.6668C7.3335 9.93045 7.93045 9.3335 8.66683 9.3335C9.40321 9.3335 10.0002 9.93045 10.0002 10.6668C10.0002 11.4032 9.40321 12.0002 8.66683 12.0002C7.93045 12.0002 7.3335 11.4032 7.3335 10.6668Z" fill="#7A28A0" fill-opacity="0.7"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.3335 6.66182V4.66683C5.3335 3.78277 5.68469 2.93493 6.30981 2.30981C6.93493 1.68469 7.78277 1.3335 8.66683 1.3335C9.55088 1.3335 10.3987 1.68469 11.0239 2.30981C11.649 2.93493 12.0002 3.78277 12.0002 4.66683V6.66182C13.1047 6.66182 14.0002 7.55725 14.0002 8.66182V12.6618C14.0002 13.7664 13.1047 14.6618 12.0002 14.6618H5.3335C4.22893 14.6618 3.3335 13.7664 3.3335 12.6618V8.66182C3.3335 7.55725 4.22893 6.66182 5.3335 6.66182ZM7.25262 3.25262C7.62769 2.87754 8.1364 2.66683 8.66683 2.66683C9.19726 2.66683 9.70597 2.87754 10.081 3.25262C10.4561 3.62769 10.6668 4.1364 10.6668 4.66683V6.66182H6.66683V4.66683C6.66683 4.1364 6.87754 3.62769 7.25262 3.25262ZM5.91807 7.99516C5.94497 7.99846 5.97237 8.00016 6.00016 8.00016C6.02796 8.00016 6.05536 7.99846 6.08226 7.99516H11.2514C11.2783 7.99846 11.3057 8.00016 11.3335 8.00016C11.3613 8.00016 11.3887 7.99846 11.4156 7.99516H12.0002C12.3684 7.99516 12.6668 8.29363 12.6668 8.66182V12.6618C12.6668 13.03 12.3684 13.3285 12.0002 13.3285H5.3335C4.96531 13.3285 4.66683 13.03 4.66683 12.6618V8.66182C4.66683 8.29363 4.96531 7.99516 5.3335 7.99516H5.91807Z" fill="#7A28A0" fill-opacity="0.7"/>
            </svg>
            <span>Your data is secure</span>
          </div>
        </div>
        <!-- After upload section-->
      </div>
    </div>
  </div>

  <div class="container-fluid option-page" *ngIf="fileSelected">
    <!-- TODO: Commented the back btn & filename with text -->
    <!-- <div class="check-reference">
      <p class="backButton" (click)="cancelFileSelection()">
        <img class="button-cancel" alt="" src="../../../assets/images/arrow.png"> Back
      </p>
      <div class="uploadedFileName">
        <p>Choose a plagiarism check to continue</p>
      </div>
    </div>
    <div class="textCenter" style="text-align: center;">
      {{fileName}}
    </div>  -->
    <div class="file-upload-loader-wrapper">
      <div class="container">
        <div class="fileUploadLoader" *ngIf="fileUploadProgress === 100">
          <div class="SVGwrapper">
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          <span>File uploaded successfully!</span>
        </div>

        <div class="fileUploadProcessing" *ngIf="fileUploadProgress < 100">
          <svg class="spinner">
            <circle style="cx: 50%;cy: 50%;r: 50%;">
              <animateTransform attributeName="transform" type="rotate" values="-90;810" keyTimes="0;1" dur="2s" repeatCount="indefinite"></animateTransform>
              <animate attributeName="stroke-dashoffset" values="0%;0%;-157.080%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
              <animate attributeName="stroke-dasharray" values="0% 314.159%;157.080% 157.080%;0% 314.159%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
            </circle>
          </svg>
          <p>Uploading file</p>
        </div>

        <div class="fileUploadSection">
          <div class="file-icon-outer">
            <img src="../../../assets/images/AEF_file_icon.svg" alt="">
          </div>
          <div [ngClass]="[show_delay ? '' : 'name-full-height', 'fileNameSection']">
            <div class="fileNameWithWordCount">
              <h5>{{ fileName }}</h5>
              <span *ngIf="uploadedFileWordCount">{{uploadedFileWordCount}} words</span>
              <span class="page-count-txt" *ngIf="uploadedFileWordCount && creditsDetails.user_type === 'PREMIUM_PLUS'">
                {{ getPageCount(uploadedFileWordCount)}} <span *ngIf="getPageCount(uploadedFileWordCount) > 1">Pages</span><span *ngIf="getPageCount(uploadedFileWordCount) === 1">Page</span> required for Standard Plagiarism Check
                <div class="pages-info-circle-icon">
                  <div class="pages-info-popup">
                    <p class="pages-info-popup-heading">For Standard Plagiarism Check</p>
                    <p class="pages-info-popup-fixed-msg">1 Page will be used per 250 words</p>
                    <div class="pages-info-popup-credit-div">
                      <p class="pages-info-popup-credit-details" *ngIf="creditsDetails?.plagiarism_free_page_count_scan >= getPageCount(uploadedFileWordCount)">Your file has approximately <span class="pf-info-popup-word-count-value">{{uploadedFileWordCount}} words so maximum {{getPageCount(uploadedFileWordCount)}} page<span *ngIf="getPageCount(uploadedFileWordCount) > 1">s</span> will be used.</span></p>
                      <p class="pages-info-popup-credit-details low-credit" *ngIf="creditsDetails?.plagiarism_free_page_count_scan < getPageCount(uploadedFileWordCount)">You need {{getPageCount(uploadedFileWordCount)}} Pages. Since you are low on Pages, {{getStandardPlagCheckWordCount(uploadedFileWordCount)}} will be used.</p>
                    </div>
                  </div>
                </div>
              </span>
              <div class="upload-progress-bar-txt-outer" *ngIf="show_delay">
                <div class="upload-progress-bar-inner">
                  <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="70" aria-valuemin="0"
                    aria-valuemax="100">
                    <div class="progress-bar" [ngStyle]="{ width: fileUploadProgress + '%' }"></div>
                  </div>
                </div>
              </div>
            </div>
            <div (click)="openDeleteConfirmationModal()" *ngIf="!show_delay" class="fileDeleteIcon">
              <span class="file-delete-icon"></span>
            </div>
          </div>
        </div>

        <h5 class="plagReportsOptionsHeading" *ngIf="fileUploadProgress === 100">Choose a plagiarism check to continue</h5>
      </div>
    </div>
    <app-plagcheck-options *ngIf="!show_delay && fileSelected && uploadedFileWordCount <= PLAG_CHECK_FILE_UPLOAD_WROD_COUNT_LIMIT" [fileName]="fileName" [files_submitted]="files_submitted"
      [cloudDriveFileDetails]="cloudDriveFileDetails" [isFileFromEditor]="isFileFromEditor" (reportStatus)="showReports($event)" (wordLimitReachedAction)="wordLimitReachedAction($event)"></app-plagcheck-options>
      <div class="container file-limit-exceed-container" *ngIf="!show_delay && fileSelected && uploadedFileWordCount > PLAG_CHECK_FILE_UPLOAD_WROD_COUNT_LIMIT">
        <div class="file-exceeded-outer">
          <span class="pf-change-file-btn-icon"></span>
          <p class="file-size-exceeded-msg">
            Your file has more than <b> {{PLAG_CHECK_FILE_UPLOAD_WROD_COUNT_LIMIT | number}} words</b>, please split the file and upload again.
          </p>
          <button class="change-file-btn" (click)="cancelFileSelection()">
            Upload again
          </button>
        </div>
      </div>
    <!-- <ng-container *ngIf="show_delay && fileSelected">
      <div class="wordCountLoader">
        <div class='checkingloader'>
          <div class='loader--dot'></div>
          <div class='loader--dot'></div>
          <div class='loader--dot'></div>
        </div>
      </div>

    </ng-container> -->

  </div>

  <div class="container search" style="margin-top: 72px;">
    <div class="row" *ngIf="!fileSelected && plagiarismFilesList.length != 0">
      <div class="col-sm-12">
        <div class="file_searchBox">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.33366 0.666992C3.65176 0.666992 0.666992 3.65176 0.666992 7.33366C0.666992 11.0156 3.65176 14.0003 7.33366 14.0003C8.87425 14.0003 10.2928 13.4778 11.4217 12.6002L15.9111 17.0896C16.2365 17.415 16.7641 17.415 17.0896 17.0896C17.415 16.7641 17.415 16.2365 17.0896 15.9111L12.6002 11.4217C13.4778 10.2928 14.0003 8.87425 14.0003 7.33366C14.0003 3.65176 11.0156 0.666992 7.33366 0.666992ZM2.33366 7.33366C2.33366 4.57224 4.57224 2.33366 7.33366 2.33366C10.0951 2.33366 12.3337 4.57224 12.3337 7.33366C12.3337 10.0951 10.0951 12.3337 7.33366 12.3337C4.57224 12.3337 2.33366 10.0951 2.33366 7.33366Z"
              fill="#B3B3B3" />
          </svg>
          <input placeholder="Search for a plagiarism report" (change)="searchReport()" [(ngModel)]="filterModel">
        </div>
      </div>
    </div>
  </div>

  <div class="container list" *ngIf="!fileSelected">
    <div class="row">
      <div class="col-sm-12">
        <div class="plagCheckAccordionSection">
          <ng-container *ngIf="plagiarismFilesList.length != 0">
            <ng-container *ngFor="let file of allPlagiarismFilesList   | filterByFileName: filterModel ; let i = index">
              <ng-container *ngIf="file.file_status == 'SUCCESS'">
                <div style="padding:35px;display:initial;" [id]="'main_card'+file.id"></div>
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel [id]="'card'+file.id" class="accordionOuterSection" [expanded]="file.open">
                    <mat-expansion-panel-header class="accordionHeaderSection" (click)="OpenRow(file.id)">
                      <mat-panel-title>
                        <div class="plagiarismReportOuterSection">
                          <div class="row">
                            <div class="reportTitleSection">
                              <div class="fileInformationSection">

                                <ul class="fileDescriptionSection">
                                  <li class="plagiarismScoreSection">
                                    <svg *ngIf="file.plagiarism_check_type == 1" viewBox="-10 0 55 36"
                                      [ngClass]="getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type) > 0 ? 'circular-chart red' : 'circular-chart green'">
                                      <path class="circle-bg" d="M18 2.0845
                                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                                a 15.9155 15.9155 0 0 1 0 -31.831" />
                                      <path class="circle"
                                        [attr.stroke-dasharray]="getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type)+',100'"
                                        d="M18 2.0845
                                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                                a 15.9155 15.9155 0 0 1 0 -31.831" />

                                      <text x="10" y="18" class="percentage"
                                        style="font-size: 7px; font-weight: 600;"
                                        [ngClass]="getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type) > 0 ? 'graph_red' :'graph_green'">

                                        <tspan x="8" dy="0" *ngIf="getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type) != 0">&gt;</tspan>
                                        <tspan x="12" dy="0">
                                          {{getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type)}}%
                                        </tspan>
                                        <tspan x="8" dy="7" style="font-size: 4px; font-weight: 600;">Similarity</tspan>

                                      </text>
                                    </svg>
                                    <svg *ngIf="file.plagiarism_check_type == 2" viewBox="-10 0 55 36"
                                      [ngClass]="getLowestPercent(strToJson(file.final_report).overall_match_percentage,file.plagiarism_check_type) > 0 ? 'circular-chart red' : 'circular-chart green'">
                                      <path class="circle-bg" d="M18 2.0845
                                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                                a 15.9155 15.9155 0 0 1 0 -31.831" />
                                      <path class="circle"
                                        [attr.stroke-dasharray]="getLowestPercent(strToJson(file.final_report).overall_match_percentage,file.plagiarism_check_type)+',100'"
                                        d="M18 2.0845
                                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                                a 15.9155 15.9155 0 0 1 0 -31.831" />

                                      <text x="10" y="18" class="percentage"
                                        style="font-size: 7px; font-weight: 600;"
                                        [ngClass]="getLowestPercent(strToJson(file.final_report).overall_match_percentage,file.plagiarism_check_type) > 0 ? 'graph_red' : 'graph_green'">
                                        <tspan x="14" dy="0">
                                          {{getLowestPercent(strToJson(file.final_report).overall_match_percentage,file.plagiarism_check_type)}}%
                                        </tspan>
                                        <tspan x="8" dy="7" style="font-size: 4px; font-weight: 600;">Similarity</tspan>
                                      </text>

                                    </svg>
                                    <svg *ngIf="file.plagiarism_check_type ==3" viewBox="-10 0 55 36"
                                      [ngClass]="getLowestPercent(strToJson(file.turnitine_final_report).overall_match_percentage,file.plagiarism_check_type) > 0 ? 'circular-chart red':  'circular-chart green'">
                                      <path class="circle-bg" d="M18 2.0845
                                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                                a 15.9155 15.9155 0 0 1 0 -31.831" />
                                      <path class="circle"
                                        [attr.stroke-dasharray]="getLowestPercent(strToJson(file.turnitine_final_report).overall_match_percentage,file.plagiarism_check_type)+',100'"
                                        d="M18 2.0845
                                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                                a 15.9155 15.9155 0 0 1 0 -31.831" />

                                      <text x="12" y="18" class="percentage" style="font-size: 7px; font-weight: 600;"
                                        [ngClass]="getLowestPercent(strToJson(file.turnitine_final_report).overall_match_percentage,file.plagiarism_check_type) > 0 ? 'graph_red': 'graph_green'">
                                        {{getLowestPercent(strToJson(file.turnitine_final_report).overall_match_percentage,file.plagiarism_check_type)}}%
                                      </text>
                                      <text x="8" y="25" style="font-size: 4px; font-weight: 600;" [ngClass]="getLowestPercent(strToJson(file.turnitine_final_report).overall_match_percentage,file.plagiarism_check_type) > 0 ?
                                           'graph_red': 'graph_green'">
                                        Similarity
                                      </text>
                                    </svg>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="PlagCheckHeaderContent">
                          <div class="reportTitleSection">
                            <h2 title="{{file.file_name}}">{{file.file_name}}</h2>
                          </div>
                          <div class="reportGeneratedSection">
                            <div
                              *ngIf="file.plagiarism_check_type == 1 && getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type) > 0"
                              class="header_warning">
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M17.134 12.2665L11.8007 2.6665C11.084 1.37484 10.0924 0.666504 9.00071 0.666504C7.90905 0.666504 6.91738 1.37484 6.20071 2.6665L0.86738 12.2665C0.19238 13.4915 0.11738 14.6665 0.659047 15.5915C1.20071 16.5165 2.26738 17.0248 3.66738 17.0248H14.334C15.734 17.0248 16.8007 16.5165 17.3424 15.5915C17.884 14.6665 17.809 13.4832 17.134 12.2665ZM8.37571 6.49984C8.37571 6.15817 8.65905 5.87484 9.00071 5.87484C9.34238 5.87484 9.62571 6.15817 9.62571 6.49984V10.6665C9.62571 11.0082 9.34238 11.2915 9.00071 11.2915C8.65905 11.2915 8.37571 11.0082 8.37571 10.6665V6.49984ZM9.59238 13.7582C9.55071 13.7915 9.50905 13.8248 9.46738 13.8582C9.41738 13.8915 9.36738 13.9165 9.31738 13.9332C9.26738 13.9582 9.21738 13.9748 9.15905 13.9832C9.10905 13.9915 9.05071 13.9998 9.00071 13.9998C8.95071 13.9998 8.89238 13.9915 8.83405 13.9832C8.78405 13.9748 8.73405 13.9582 8.68405 13.9332C8.63405 13.9165 8.58405 13.8915 8.53405 13.8582C8.49238 13.8248 8.45071 13.7915 8.40905 13.7582C8.25905 13.5998 8.16738 13.3832 8.16738 13.1665C8.16738 12.9498 8.25905 12.7332 8.40905 12.5748C8.45071 12.5415 8.49238 12.5082 8.53405 12.4748C8.58405 12.4415 8.63405 12.4165 8.68405 12.3998C8.73405 12.3748 8.78405 12.3582 8.83405 12.3498C8.94238 12.3248 9.05905 12.3248 9.15905 12.3498C9.21738 12.3582 9.26738 12.3748 9.31738 12.3998C9.36738 12.4165 9.41738 12.4415 9.46738 12.4748C9.50905 12.5082 9.55071 12.5415 9.59238 12.5748C9.74238 12.7332 9.83405 12.9498 9.83405 13.1665C9.83405 13.3832 9.74238 13.5998 9.59238 13.7582Z"
                                  fill="#EF4444" />
                              </svg>
                              <h3>Plagiarism issue detected</h3>
                            </div>
                            <div
                              *ngIf="file.plagiarism_check_type == 1 && getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type) == 0"
                              class="header_warning" style="background: #e6fde3;">
                              <img src="../../../../assets/images/CorrectEmailID.svg" alt="">
                              <h3 style="color:#466235;padding-left: 8px;">No plagiarism issue detected</h3>
                            </div>
                            <div
                              *ngIf="file.plagiarism_check_type == 2 && getLowestPercent(strToJson(file.final_report).overall_match_percentage,file.plagiarism_check_type) > 0"
                              class="header_warning">
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M17.134 12.2665L11.8007 2.6665C11.084 1.37484 10.0924 0.666504 9.00071 0.666504C7.90905 0.666504 6.91738 1.37484 6.20071 2.6665L0.86738 12.2665C0.19238 13.4915 0.11738 14.6665 0.659047 15.5915C1.20071 16.5165 2.26738 17.0248 3.66738 17.0248H14.334C15.734 17.0248 16.8007 16.5165 17.3424 15.5915C17.884 14.6665 17.809 13.4832 17.134 12.2665ZM8.37571 6.49984C8.37571 6.15817 8.65905 5.87484 9.00071 5.87484C9.34238 5.87484 9.62571 6.15817 9.62571 6.49984V10.6665C9.62571 11.0082 9.34238 11.2915 9.00071 11.2915C8.65905 11.2915 8.37571 11.0082 8.37571 10.6665V6.49984ZM9.59238 13.7582C9.55071 13.7915 9.50905 13.8248 9.46738 13.8582C9.41738 13.8915 9.36738 13.9165 9.31738 13.9332C9.26738 13.9582 9.21738 13.9748 9.15905 13.9832C9.10905 13.9915 9.05071 13.9998 9.00071 13.9998C8.95071 13.9998 8.89238 13.9915 8.83405 13.9832C8.78405 13.9748 8.73405 13.9582 8.68405 13.9332C8.63405 13.9165 8.58405 13.8915 8.53405 13.8582C8.49238 13.8248 8.45071 13.7915 8.40905 13.7582C8.25905 13.5998 8.16738 13.3832 8.16738 13.1665C8.16738 12.9498 8.25905 12.7332 8.40905 12.5748C8.45071 12.5415 8.49238 12.5082 8.53405 12.4748C8.58405 12.4415 8.63405 12.4165 8.68405 12.3998C8.73405 12.3748 8.78405 12.3582 8.83405 12.3498C8.94238 12.3248 9.05905 12.3248 9.15905 12.3498C9.21738 12.3582 9.26738 12.3748 9.31738 12.3998C9.36738 12.4165 9.41738 12.4415 9.46738 12.4748C9.50905 12.5082 9.55071 12.5415 9.59238 12.5748C9.74238 12.7332 9.83405 12.9498 9.83405 13.1665C9.83405 13.3832 9.74238 13.5998 9.59238 13.7582Z"
                                  fill="#EF4444" />
                              </svg>
                              <h3>Plagiarism issue detected</h3>
                            </div>
                            <div
                              *ngIf="file.plagiarism_check_type == 2 && getLowestPercent(strToJson(file.final_report).overall_match_percentage,file.plagiarism_check_type) == 0"
                              class="header_warning" style="background: #e6fde3;">
                              <img src="../../../../assets/images/CorrectEmailID.svg" alt="">
                              <h3 style="color:#466235;padding-left: 8px;">No plagiarism issue detected</h3>
                            </div>
                            <div
                              *ngIf="file.plagiarism_check_type == 3 &&  getLowestPercent(strToJson(file.turnitine_final_report).overall_match_percentage,file.plagiarism_check_type) > 0"
                              class="header_warning">
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M17.134 12.2665L11.8007 2.6665C11.084 1.37484 10.0924 0.666504 9.00071 0.666504C7.90905 0.666504 6.91738 1.37484 6.20071 2.6665L0.86738 12.2665C0.19238 13.4915 0.11738 14.6665 0.659047 15.5915C1.20071 16.5165 2.26738 17.0248 3.66738 17.0248H14.334C15.734 17.0248 16.8007 16.5165 17.3424 15.5915C17.884 14.6665 17.809 13.4832 17.134 12.2665ZM8.37571 6.49984C8.37571 6.15817 8.65905 5.87484 9.00071 5.87484C9.34238 5.87484 9.62571 6.15817 9.62571 6.49984V10.6665C9.62571 11.0082 9.34238 11.2915 9.00071 11.2915C8.65905 11.2915 8.37571 11.0082 8.37571 10.6665V6.49984ZM9.59238 13.7582C9.55071 13.7915 9.50905 13.8248 9.46738 13.8582C9.41738 13.8915 9.36738 13.9165 9.31738 13.9332C9.26738 13.9582 9.21738 13.9748 9.15905 13.9832C9.10905 13.9915 9.05071 13.9998 9.00071 13.9998C8.95071 13.9998 8.89238 13.9915 8.83405 13.9832C8.78405 13.9748 8.73405 13.9582 8.68405 13.9332C8.63405 13.9165 8.58405 13.8915 8.53405 13.8582C8.49238 13.8248 8.45071 13.7915 8.40905 13.7582C8.25905 13.5998 8.16738 13.3832 8.16738 13.1665C8.16738 12.9498 8.25905 12.7332 8.40905 12.5748C8.45071 12.5415 8.49238 12.5082 8.53405 12.4748C8.58405 12.4415 8.63405 12.4165 8.68405 12.3998C8.73405 12.3748 8.78405 12.3582 8.83405 12.3498C8.94238 12.3248 9.05905 12.3248 9.15905 12.3498C9.21738 12.3582 9.26738 12.3748 9.31738 12.3998C9.36738 12.4165 9.41738 12.4415 9.46738 12.4748C9.50905 12.5082 9.55071 12.5415 9.59238 12.5748C9.74238 12.7332 9.83405 12.9498 9.83405 13.1665C9.83405 13.3832 9.74238 13.5998 9.59238 13.7582Z"
                                  fill="#EF4444" />
                              </svg>
                              <h3>Plagiarism issue detected</h3>
                            </div>
                            <div
                              *ngIf="file.plagiarism_check_type == 3 &&  getLowestPercent(strToJson(file.turnitine_final_report).overall_match_percentage,file.plagiarism_check_type) == 0"
                              class="header_warning" style="background: #e6fde3;">
                              <img src="../../../../assets/images/CorrectEmailID.svg" alt="">
                              <h3 style="color:#466235;padding-left: 8px;">No plagiarism issue detected</h3>
                            </div>



                            <div class="reportDateAndTime">
                              <span *ngIf="file.plagiarism_check_type == 2">
                                Uploaded on {{file.uploaded_at | date:"dd/MMM/y h:mm a"}}</span>
                              <span *ngIf="file.plagiarism_check_type == 1">
                                Uploaded on {{file.free_score_uploaded_at | date:"dd/MMM/y h:mm a"}}</span>
                              <span *ngIf="file.plagiarism_check_type == 3">
                                Uploaded on {{file.turnitine_uploaded_at || file.uploaded_at | date:"dd/MMM/y hh:mm a"}}</span>
                            </div>
                            <div class="reportDateAndTime">
                              <span *ngIf="file.plagiarism_check_type == 2 && file.source != 'sample_card'">
                                Advanced Plagiarism Check</span>
                              <span *ngIf="file.plagiarism_check_type == 3 && file.source != 'sample_card'">
                                Standard Plagiarism Check</span>
                              <span *ngIf="file.plagiarism_check_type == 1 && file.source != 'sample_card'">
                                Score Only</span>
                              <span *ngIf="file.source == 'sample_card'">
                                Sample Report</span>
                            </div>
                          </div>

                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>


                    <div class="accordionBodySection plagiarismReportOuterSection">
                      <span
                        *ngIf="file.plagiarism_check_type == 3 ?  parseJson(file.turnitine_final_report) : file.plagiarism_check_type == 1 ? parseJson(file.free_score_final_report) : parseJson(file.final_report) "></span>
                      <div class="row" style="width: 100%;">
                        <div class="row" [id]="'scores'+i+file.file_id"
                          style="padding: 15px;border-radius: 10px;border-color: transparent;width: 100%">
                          <div class="col-6">
                            <div class="reprotTotalSection"
                              *ngIf="getLowestPercent(report_data.overall_match_percentage,file.plagiarism_check_type) > 0">
                              <div class="totalReportNumber">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="24" cy="24" r="24" fill="#FEE2E2" />
                                  <path
                                    d="M33.7605 27.92L27.3605 16.4C26.5005 14.85 25.3105 14 24.0005 14C22.6905 14 21.5005 14.85 20.6405 16.4L14.2405 27.92C13.4305 29.39 13.3405 30.8 13.9905 31.91C14.6405 33.02 15.9205 33.63 17.6005 33.63H30.4005C32.0805 33.63 33.3605 33.02 34.0105 31.91C34.6605 30.8 34.5705 29.38 33.7605 27.92ZM23.2505 21C23.2505 20.59 23.5905 20.25 24.0005 20.25C24.4105 20.25 24.7505 20.59 24.7505 21V26C24.7505 26.41 24.4105 26.75 24.0005 26.75C23.5905 26.75 23.2505 26.41 23.2505 26V21ZM24.7105 29.71C24.6605 29.75 24.6105 29.79 24.5605 29.83C24.5005 29.87 24.4405 29.9 24.3805 29.92C24.3205 29.95 24.2605 29.97 24.1905 29.98C24.1305 29.99 24.0605 30 24.0005 30C23.9405 30 23.8705 29.99 23.8005 29.98C23.7405 29.97 23.6805 29.95 23.6205 29.92C23.5605 29.9 23.5005 29.87 23.4405 29.83C23.3905 29.79 23.3405 29.75 23.2905 29.71C23.1105 29.52 23.0005 29.26 23.0005 29C23.0005 28.74 23.1105 28.48 23.2905 28.29C23.3405 28.25 23.3905 28.21 23.4405 28.17C23.5005 28.13 23.5605 28.1 23.6205 28.08C23.6805 28.05 23.7405 28.03 23.8005 28.02C23.9305 27.99 24.0705 27.99 24.1905 28.02C24.2605 28.03 24.3205 28.05 24.3805 28.08C24.4405 28.1 24.5005 28.13 24.5605 28.17C24.6105 28.21 24.6605 28.25 24.7105 28.29C24.8905 28.48 25.0005 28.74 25.0005 29C25.0005 29.26 24.8905 29.52 24.7105 29.71Z"
                                    fill="#DC2626" />
                                </svg>
                                <span>
                                  <span *ngIf="file.plagiarism_check_type == 1 && getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type) != 0">More than </span>
                                  <span
                                    style="font-weight: 600;">{{getLowestPercent(report_data.overall_match_percentage,file.plagiarism_check_type)}}</span>%
                                  similarity detected</span>
                              </div>
                              <div class="diviedReportSection">
                                <div class="leftPanelSideReport">

                                  <p *ngIf="file.plagiarism_check_type == 1">>
                                    <span
                                      class="score_match">{{getLowestPercent(report_data.internet_match_percentage,file.plagiarism_check_type)}}%</span>
                                  </p>
                                  <p *ngIf="file.plagiarism_check_type != 1">
                                    <span
                                      class="score_match">{{getLowestPercent(report_data.internet_match_percentage,file.plagiarism_check_type)}}%</span>
                                  </p>

                                  <span>Internet match</span>
                                </div>
                                <div class="leftPanelSideReport">

                                  <p *ngIf="file.plagiarism_check_type == 1">
                                    <span
                                      class="score_match" *ngIf="creditsDetails.user_type !== 'BASIC'"> > {{getLowestPercent(report_data.publication_match_percentage,file.plagiarism_check_type)}}%</span>
                                      <span class="lock-icon" *ngIf="creditsDetails.user_type === 'BASIC'"></span>

                                  </p>
                                  <p *ngIf="file.plagiarism_check_type == 2 ">
                                    <span
                                      class="score_match">{{getLowestPercent(report_data.internet_match_percentage,file.plagiarism_check_type)}}%</span>
                                  </p>
                                  <div class="tooltipWrapper" style="margin-bottom: 8px;"
                                    *ngIf="file.plagiarism_check_type == 3">
                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M6 14C6 12.8954 6.89543 12 8 12C9.10457 12 10 12.8954 10 14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14Z"
                                        fill="#DC2626" />
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M3 7.99249V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0C9.32608 0 10.5979 0.526784 11.5355 1.46447C12.4732 2.40215 13 3.67392 13 5V7.99249C14.6569 7.99249 16 9.33564 16 10.9925V16.9925C16 18.6493 14.6569 19.9925 13 19.9925H3C1.34315 19.9925 0 18.6493 0 16.9925V10.9925C0 9.33564 1.34315 7.99249 3 7.99249ZM5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7.99249H5V5C5 4.20435 5.31607 3.44129 5.87868 2.87868ZM3.87685 9.99249C3.91721 9.99745 3.95831 10 4 10C4.04169 10 4.08279 9.99745 4.12315 9.99249H11.8769C11.9172 9.99745 11.9583 10 12 10C12.0417 10 12.0828 9.99745 12.1231 9.99249H13C13.5523 9.99249 14 10.4402 14 10.9925V16.9925C14 17.5448 13.5523 17.9925 13 17.9925H3C2.44772 17.9925 2 17.5448 2 16.9925V10.9925C2 10.4402 2.44772 9.99249 3 9.99249H3.87685Z"
                                        fill="#DC2626" />
                                    </svg>
                                    <div class="Locktooltip" style="left:-190px;">Generate Advance Plagiarism report to
                                      unlock this</div>
                                  </div>

                                  <span>Paid publication match</span>
                                  <div class="availableTextTag" *ngIf="(creditsDetails.user_type === 'BASIC' && (file.plagiarism_check_type == 1 || file.source == 'sample_card')) || (creditsDetails.user_type !== 'BASIC' && file.source == 'sample_card') || (file.plagiarism_check_type == 3)">
                                    {{ file.source == 'sample_card' ? 'Available only with' : 'Available on'}} <img src="../../../assets/images/ithentication_img.svg" alt="" style="width: 69.71px; height: 8.39px;">   
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                            <div class="reprotTotalSection"
                              *ngIf="getLowestPercent(report_data.overall_match_percentage,file.plagiarism_check_type) == 0">
                              <div class="totalReportNumber score_border" style="border: 1px solid #7db75b">
                                <img style="margin-right: 8px;" width="24" height="24" src="../../../../assets/images/CorrectEmailID.svg" alt="">
                               
                                <span style="color:#466235">
                                  <span *ngIf="file.plagiarism_check_type == 1 && getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type) != 0">More than </span>
                                  <span
                                    style="font-weight: 600;color:#466235">{{getLowestPercent(report_data.overall_match_percentage,file.plagiarism_check_type)}}</span>%
                                  similarity detected</span>
                              </div>
                              <div class="diviedReportSection divider_before_score">
                                <div class="leftPanelSideReport" style="border: 1px solid #7db75b">

                                  <p *ngIf="file.plagiarism_check_type == 1 && getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type) != 0">>
                                    <span 
                                      class="score_match" style="color:#466235">{{getLowestPercent(report_data.internet_match_percentage,file.plagiarism_check_type)}}%</span>
                                  </p>
                                  <p *ngIf="file.plagiarism_check_type != 1" style="color:#466235">
                                    <span 
                                      class="score_match" style="color:#466235 !important">{{getLowestPercent(report_data.internet_match_percentage,file.plagiarism_check_type)}}%</span>
                                  </p>

                                  <span>Internet match</span>
                                </div>
                                <div class="leftPanelSideReport" style="border: 1px solid #7db75b">

                                  <p *ngIf="file.plagiarism_check_type == 1 && getLowestPercent(strToJson(file.free_score_final_report).overall_match_percentage,file.plagiarism_check_type) != 0">
                                    <span
                                      class="score_match" *ngIf="creditsDetails.user_type !== 'BASIC'" style="color:#466235 !important"> > {{getLowestPercent(report_data.publication_match_percentage,file.plagiarism_check_type)}}%</span>
                                      <span class="lock-icon" *ngIf="creditsDetails.user_type === 'BASIC'"></span>
                                  </p>
                                  <p *ngIf="file.plagiarism_check_type == 2 " style="color:#466235">
                                    <span 
                                      class="score_match" style="color:#466235 !important">{{getLowestPercent(report_data.internet_match_percentage,file.plagiarism_check_type)}}%</span>
                                  </p>
                                  <div class="tooltipWrapper" style="margin-bottom: 8px;"
                                    *ngIf="file.plagiarism_check_type == 3">
                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M6 14C6 12.8954 6.89543 12 8 12C9.10457 12 10 12.8954 10 14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14Z"
                                        fill="#466235" />
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M3 7.99249V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0C9.32608 0 10.5979 0.526784 11.5355 1.46447C12.4732 2.40215 13 3.67392 13 5V7.99249C14.6569 7.99249 16 9.33564 16 10.9925V16.9925C16 18.6493 14.6569 19.9925 13 19.9925H3C1.34315 19.9925 0 18.6493 0 16.9925V10.9925C0 9.33564 1.34315 7.99249 3 7.99249ZM5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7.99249H5V5C5 4.20435 5.31607 3.44129 5.87868 2.87868ZM3.87685 9.99249C3.91721 9.99745 3.95831 10 4 10C4.04169 10 4.08279 9.99745 4.12315 9.99249H11.8769C11.9172 9.99745 11.9583 10 12 10C12.0417 10 12.0828 9.99745 12.1231 9.99249H13C13.5523 9.99249 14 10.4402 14 10.9925V16.9925C14 17.5448 13.5523 17.9925 13 17.9925H3C2.44772 17.9925 2 17.5448 2 16.9925V10.9925C2 10.4402 2.44772 9.99249 3 9.99249H3.87685Z"
                                        fill="#466235" />
                                    </svg>
                                    <div class="Locktooltip" style="left: -105px;bottom: 30px;width: 230px;">Generate Advance Plagiarism report to
                                      unlock this</div>
                                  </div>

                                  <span>Paid publication match</span>
                                  <div class="availableTextTag" *ngIf="(creditsDetails.user_type === 'BASIC' && (file.plagiarism_check_type == 1 || file.source == 'sample_card')) || (creditsDetails.user_type !== 'BASIC' && file.source == 'sample_card') || (file.plagiarism_check_type == 3)">
                                    {{ file.source == 'sample_card' ? 'Available only with' : 'Available on'}} <img src="../../../assets/images/ithentication_img.svg" alt="" style="width: 69.71px; height: 8.39px;">   
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>

                          <div class="col-6">
                            <div class="scoreMathSection">
                              <h3><span *ngIf="report_data.top_source_largest_matched_word_count != 0">{{report_data.top_source_largest_matched_word_count}} words <span style="color: #1D1D1D;font-weight: 500;">match</span></span>
                                
                                <span *ngIf="report_data.top_source_largest_matched_word_count == 0" style="color: #1D1D1D;font-weight: 500;">No matches found</span>
                                <span *ngIf="report_data.top_source_largest_matched_word_count != 0" style="color: #1D1D1D;font-weight: 500;">
                                  with top
                                <span style="color: black;font-weight: 500;"
                                  *ngIf="report_data.top_matches.length >= 3">3</span>
                                <span style="color: black;font-weight: 500;"
                                  *ngIf="report_data.top_matches.length < 3">{{report_data.top_matches.length}}</span>
                                resources
                                </span>
                              </h3>
                              <div class="scoreMatchTable">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>MATCH %</th>
                                      <th>TOP SOURCES</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let matches of report_data.top_matches | slice:0:3">
                                      <td class="tooltipWrapper" *ngIf="file.plagiarism_check_type == 1">
                                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M6 14C6 12.8954 6.89543 12 8 12C9.10457 12 10 12.8954 10 14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14Z"
                                            fill="#DC2626" />
                                          <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M3 7.99249V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0C9.32608 0 10.5979 0.526784 11.5355 1.46447C12.4732 2.40215 13 3.67392 13 5V7.99249C14.6569 7.99249 16 9.33564 16 10.9925V16.9925C16 18.6493 14.6569 19.9925 13 19.9925H3C1.34315 19.9925 0 18.6493 0 16.9925V10.9925C0 9.33564 1.34315 7.99249 3 7.99249ZM5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7.99249H5V5C5 4.20435 5.31607 3.44129 5.87868 2.87868ZM3.87685 9.99249C3.91721 9.99745 3.95831 10 4 10C4.04169 10 4.08279 9.99745 4.12315 9.99249H11.8769C11.9172 9.99745 11.9583 10 12 10C12.0417 10 12.0828 9.99745 12.1231 9.99249H13C13.5523 9.99249 14 10.4402 14 10.9925V16.9925C14 17.5448 13.5523 17.9925 13 17.9925H3C2.44772 17.9925 2 17.5448 2 16.9925V10.9925C2 10.4402 2.44772 9.99249 3 9.99249H3.87685Z"
                                            fill="#DC2626" />
                                        </svg>
                                        <div class="Locktooltip">Generate Plagiarism report to unlock this</div>
                                      </td>
                                      <td *ngIf="file.plagiarism_check_type != 1">{{matches.percentage.toFixed(1)}}</td>
                                      <td><span>{{matches.name}}</span></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <app-plag-report-plans style="width: 100%; margin-top: 50px;"
                          [plag_type_number]="file.plagiarism_check_type"
                          [ithenticateUrl]="file.ithenticate_report_pdf_url"
                          [turnitinUrl]="file.turnitine_report_pdf_url" [score]="getFinalReportScore(file)"
                          [filename]="file.file_name" [plansCTAToShow]="file" [file_id]="file.file_id"
                          [card_id]="file.id" [plagiarism_free_score]="remainig_free_score"
                          [total_free_score]="total_free_score" [source]="file.source"
                          [free_score_reset_date]="free_score_reset_date" 
                          [user_decoded_id]="user_decoded_id"
                          [file]="file"
                          [used_page_count]="file.used_page_count"
                          [word_count]="file.word_count"
                          [isPremiumPlusUser]="creditsDetails.user_type === 'PREMIUM_PLUS'"
                          [plagiarism_free_page_count_scan]="creditsDetails?.plagiarism_free_page_count_scan"
                          [stdPlagCheckUsedCredits]="file.credits"
                          [userType]="creditsDetails.user_type"
                          (onPlanClick)="viewResult($event,file,i)"
                          (GotoCard)="goToRelatedCard($event)"></app-plag-report-plans>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>


              </ng-container>

              <ng-container *ngIf="file.file_status == 'IN_PROGRESS'">
                <div class="accordionOuterSection in_progressSection plagCheckReportAmiation">
                  <div class="accordionHeaderSection">
                    <div class="reportTitleSection">
                      <h2 title="{{file.file_name}}">{{file.file_name}}</h2>
                    </div>
                  </div>
                  <div class="inprogress_file">
                    <div class="checkingloader">
                      <div class='loader--text'></div>
                      <div class='loader--dot'></div>
                      <div class='loader--dot'></div>
                      <div class='loader--dot'></div>
                    </div>
                  </div>
                  <div class="scrolling-words-box">
                    <ul id="animationBackroundSectionTwo">
                      <li>Generating Plagiarism Report</li>
                      <li>This can take upto 5 minutes</li>
                      <li>Our web crawlers are analysing your content</li>
                      <li>Checking for plagiarism</li>
                      <li>We will notify you once done</li>
                    </ul>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="file.file_status == 'FAILED'">
                <div class="accordionOuterSection in_progressSection">
                  <div class="accordionHeaderSection">
                    <div class="reportTitleSection">
                      <h2 title="{{file.file_name}}">{{file.file_name}}</h2>
                      <div class="file_delete"
                        *ngIf="!(file.file_status !== 'FAILED' && file.file_status !== 'SUCCESS') || (file.file_status === 'ON_HOLD')">
                        <button (click)="deleteFile('Undo',file)">
                          <svg width="17" height="18" viewBox="0 0 17 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M0 4.50505C0 4.00818 0.402793 3.60539 0.899663 3.60539H15.2943C15.7911 3.60539 16.1939 4.00818 16.1939 4.50505C16.1939 5.00192 15.7911 5.40472 15.2943 5.40472H0.899663C0.402793 5.40472 0 5.00192 0 4.50505Z"
                              fill="#414E62" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M6.29764 7.19057C6.79451 7.19057 7.19731 7.59336 7.19731 8.09023V13.4882C7.19731 13.9851 6.79451 14.3879 6.29764 14.3879C5.80077 14.3879 5.39798 13.9851 5.39798 13.4882V8.09023C5.39798 7.59336 5.80077 7.19057 6.29764 7.19057Z"
                              fill="#414E62" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M9.8963 7.19057C10.3932 7.19057 10.796 7.59336 10.796 8.09023V13.4882C10.796 13.9851 10.3932 14.3879 9.8963 14.3879C9.39943 14.3879 8.99663 13.9851 8.99663 13.4882V8.09023C8.99663 7.59336 9.39943 7.19057 9.8963 7.19057Z"
                              fill="#414E62" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M1.72461 3.6085C2.21977 3.56723 2.65462 3.93518 2.69588 4.43034L3.59555 15.2263C3.59762 15.2511 3.59865 15.2761 3.59865 15.301C3.59865 15.5396 3.69344 15.7684 3.86216 15.9372C4.03088 16.1059 4.25971 16.2007 4.49832 16.2007H11.6956C11.9342 16.2007 12.1631 16.1059 12.3318 15.9372C12.5005 15.7684 12.5953 15.5396 12.5953 15.301C12.5953 15.2761 12.5963 15.2511 12.5984 15.2263L13.4981 4.43034C13.5393 3.93518 13.9742 3.56723 14.4693 3.6085C14.9645 3.64976 15.3324 4.08461 15.2912 4.57976L14.3943 15.3422C14.3836 16.0431 14.1005 16.713 13.6041 17.2095C13.0979 17.7156 12.4114 18 11.6956 18H4.49832C3.7825 18 3.096 17.7156 2.58984 17.2095C2.0934 16.713 1.81033 16.0431 1.79964 15.3422L0.902771 4.57976C0.861508 4.08461 1.22946 3.64976 1.72461 3.6085Z"
                              fill="#414E62" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.02533 0.527011C5.36277 0.189571 5.82043 0 6.29764 0H9.8963C10.3735 0 10.8312 0.189571 11.1686 0.527011C11.5061 0.864449 11.6956 1.32212 11.6956 1.79933V4.49832C11.6956 4.99519 11.2928 5.39798 10.796 5.39798C10.2991 5.39798 9.8963 4.99519 9.8963 4.49832V1.79933H6.29764V4.49832C6.29764 4.99519 5.89485 5.39798 5.39798 5.39798C4.90111 5.39798 4.49832 4.99519 4.49832 4.49832V1.79933C4.49832 1.32212 4.68789 0.864449 5.02533 0.527011Z"
                              fill="#414E62" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div class="reportGeneratedSection">
                      <span *ngIf="file.plagiarism_check_type == 2">
                        Uploaded on {{file.uploaded_at | date:'medium'}}</span>
                      <span *ngIf="file.plagiarism_check_type == 1 ">
                        Uploaded on {{file.free_score_final_report | date:'medium'}}</span>
                      <span *ngIf="file.plagiarism_check_type == 3">
                        Uploaded on {{file.uploaded_at |date:'medium'}}</span>
                    </div>
                    <div class="failedOopSection">
                      <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.999 7.99988C10.999 7.44759 10.5513 6.99988 9.99902 6.99988C9.44674 6.99988 8.99902 7.44759 8.99902 7.99988V9.99988C8.99902 10.5522 9.44674 10.9999 9.99902 10.9999C10.5513 10.9999 10.999 10.5522 10.999 9.99988V7.99988Z"
                          fill="#FF5C5C" />
                        <path
                          d="M10.999 13.9999C10.999 13.4476 10.5513 12.9999 9.99902 12.9999C9.44674 12.9999 8.99902 13.4476 8.99902 13.9999V14.0099C8.99902 14.5622 9.44674 15.0099 9.99902 15.0099C10.5513 15.0099 10.999 14.5622 10.999 14.0099V13.9999Z"
                          fill="#FF5C5C" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.99859 0.964844C9.4627 0.964844 8.93659 1.10839 8.47496 1.38055C8.01551 1.65143 7.63662 2.03988 7.37727 2.50584L0.380346 14.7505C0.358289 14.7891 0.338832 14.8291 0.322102 14.8703C0.142014 15.3136 0.0706013 15.7936 0.113827 16.2702C0.157052 16.7468 0.313661 17.2062 0.570579 17.6099C0.827497 18.0136 1.17727 18.35 1.59069 18.591C2.00412 18.832 2.4692 18.9706 2.9471 18.9953C2.97641 18.9968 3.00549 18.997 3.03429 18.996C3.04567 18.9964 3.05711 18.9966 3.06859 18.9966H16.9986C17.0158 18.9966 17.0329 18.9962 17.0501 18.9953C17.528 18.9706 17.9931 18.832 18.4065 18.591C18.8199 18.35 19.1697 18.0136 19.4266 17.6099C19.6835 17.2062 19.8401 16.7468 19.8834 16.2702C19.9266 15.7936 19.8552 15.3136 19.6751 14.8703C19.6583 14.8291 19.6389 14.7891 19.6168 14.7505L12.6199 2.50584C12.3606 2.03988 11.9817 1.65143 11.5222 1.38055C11.0606 1.10839 10.5345 0.964844 9.99859 0.964844ZM3.06859 16.9966C3.05775 16.9966 3.04696 16.9968 3.0362 16.9971C2.88178 16.987 2.73174 16.9412 2.59794 16.8632C2.46013 16.7828 2.34354 16.6707 2.2579 16.5361C2.17226 16.4016 2.12006 16.2484 2.10565 16.0896C2.09291 15.9491 2.11004 15.8078 2.15567 15.6748L9.11691 3.49278L9.12359 3.48072C9.21007 3.32442 9.33683 3.19414 9.49071 3.10341C9.64459 3.01269 9.81996 2.96484 9.99859 2.96484C10.1772 2.96484 10.3526 3.01269 10.5065 3.10341C10.6603 3.19414 10.787 3.32447 10.8735 3.48077L17.8415 15.6748C17.8871 15.8078 17.9043 15.9491 17.8915 16.0896C17.8771 16.2484 17.8249 16.4016 17.7393 16.5361C17.6536 16.6707 17.537 16.7828 17.3992 16.8632C17.2676 16.9399 17.1202 16.9855 16.9684 16.9966H3.06859Z"
                          fill="#FF5C5C" />
                      </svg>
                      <span>Oops! The file processing failed and your credits have been
                        refunded</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>


          </ng-container>

          <!-- <ng-container *ngIf="allPlagiarismFilesList.length == 0">
            <ul class="no_file_search_section" style="margin-bottom: 20px;">
              <li>
                <p>Your plagiarism checker files will appear here.</p>
              </li>
            </ul>
          </ng-container> -->

          <ng-container
            *ngIf="(allPlagiarismFilesList | filterByFileName: filterModel).length<=0 && allPlagiarismFilesList.length>0">
            <ul class="no_file_search_section" style="margin-bottom: 20px;">
              <li>
                <p>Sorry, we couldn't find any file named "{{filterModel}}". Please try a different
                  search.
                </p>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="loadMoreReportButton" style="display:none;" *ngIf="!fileSelected && plagiarismFilesList.length > 4">
    <button [ngClass]="loadmorebtn ? 'blur_loadmore' : ''" (click)="loadMore(currentPage+1)">Load More</button>
  </div>

</section>