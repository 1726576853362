import { Component, OnInit, ViewChild, TemplateRef, ApplicationRef } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectProfileModalComponent } from 'src/app/components/select-profile-modal/select-profile-modal.component';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VerifiedUserService } from 'src/app/_services/networkcalls/verified-user.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { PaymentDetails } from 'src/app/_interfaces/dashboard/payment-details';
import { UpgradeToPremiumComponent } from 'src/app/components/editor/upgrade-to-premium/upgrade-to-premium.component';
import { environment } from 'src/environments/environment';
import { MyDriveService } from 'src/app/_services/networkcalls/my-drive.service';
import { GoogleDrivePickerService } from 'src/app/_services/google-drive-picker.service';
import { DropboxChooseOptions, Dropbox } from 'src/app/_interfaces/dashboard/dropbox-choose-options';
import { DashboardFileUploadSelectionModalComponent } from 'src/app/components/dashboard/dashboard-file-upload-selection-modal/dashboard-file-upload-selection-modal.component';
import { PluginsService } from 'src/app/_services/plugins.service';
import { MatDialogConfig } from '@angular/material';
import { CreditDetailsComponent } from '../../../app/components/dashboard/credit-details/credit-details.component';
import { PaymentService } from 'src/app/_services/networkcalls/payment.service';
import { ReferralRewardModalComponent } from 'src/app/components/dashboard/referral-reward-modal/referral-reward-modal.component';

// import { $ } from 'protractor';
declare var $: any;
declare function wisepop():any;
declare const OneDrive: any;
declare var Dropbox: Dropbox;

@Component({
  selector: 'app-dashbaord',
  templateUrl: './dashbaord.component.html',
  styleUrls: ['./dashbaord.component.scss']
})
export class DashbaordComponent implements OnInit {
  win = window['dataLayer'] || {};
  userId: string;
  userDetails: any;
  @ViewChild('profileModal', { static: true }) profileModal: TemplateRef<any>;
  selectedProfile: any = {};
  profileList = [];
  paymentDetails : PaymentDetails;
  userData: any;
  isNewUser:boolean = false;
  fileName: string;
  file: File;
  fileType: any;
  creditsDetails: any=[];
  fileSectionLoader: boolean = false;
  cloudDriveFileDetails = {"fileId":"", "fileName": "", "fileUrl":"", "source": "", "oauthToken": "", "userId" :""};
  uploadSource =[
    {
     "GOOGLE_DRIVE" : false,
     "TRINKA_DRIVE" : false,
     "ONE_DRIVE"    : false,
     "DROP_BOX"     : false,
     "LOCAL_SYSTEM" : false
     }
  ];
  isChromeExtensionInstalled: boolean = false;
  isFirefoxExtensionInstalled: boolean = false;
  isEdgeExtensionInstalled: boolean = false;
  showExtensionAddMsg: boolean = true;
  currentBrowserName: string = '';
  browserIcon: string = 'googleChormeIconNew';
  currentSelectedLeftMenu: string = 'myDrive';
  imgSrc: string = '../../../../assets/images/profileIconNew.svg';
  sendEmailBanner: boolean = true;
  isDomainPopUpEnabled : boolean = false;
  referralReward: number = 0;
  country:string = "";
  userSubscriptionDetails: any;
  trialDayRemaing: number = 0;

  constructor(
    public sharedService: SharedService,
    private networkCalls: DashboardService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private verifiedUserService: VerifiedUserService,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    public amplitudeService: AmplitudeService,
    private myDriveService: MyDriveService,
    private googleDrivePickerService: GoogleDrivePickerService,
    private appRef: ApplicationRef,
    private dashboardService : DashboardService,
    private pluginsService: PluginsService,
    public networkCallsPayments: PaymentService
  ) { }


  ngOnInit() {
    wisepop();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.getUserPaymentSubscription();
    this.featurePermissionRequest()
    this.activeRoute.queryParams.subscribe(params => {
      this.getSelectedProfileData(false);
      if(params["newUser"]){
        window['usetifulTags'] = {newUser: "Yes"}
        this.isNewUser = true;
      }
    });
    this.country = localStorage.getItem("country-trinka");
    this.amplitudeService.setUserId(this.userId);
    // setTimeout(()=>{
    //   this.openChangeProfile();
    // },1000)
    // this.modalService.open(this.profileModal, { size: 'md', backdrop: 'static' });
    //this.getUserProfileData();
    this.getAutoEditCredit();
    this.getCreditsDetails();
    // route.data includes both `data` and `resolve`
    // $(window).resize(function () {
    //   if ($(window).width() < 992) {
    //     // alert("this is resize window");
    //     // $("#dashboard_menu_toggle").click(function (){
    //     //   $("#side_bar_sections").toggleClass("side_bar_collapse");
    //     // });
    //     $("#side_bar_sections").addClass("side_bar_collapse");
    //   }
    //   else {
    //     $("#side_bar_sections").removeClass("side_bar_collapse");
    //   }
    // });

    // $(document).ready(function () {
    //   if ($(window).width() < 992) {
    //     // alert("this is onload functoin");
    //     $("#side_bar_sections").addClass("side_bar_collapse");
    //   }
    //   else {
    //     $("#side_bar_sections").removeClass("side_bar_collapse");
    //   }
    // });

    // $("#dashboard_menu_toggle").click(function () {
    //   $("#side_bar_sections").toggleClass("side_bar_collapse");
    // });

    if (navigator.userAgent.indexOf("WOW64") != -1 ||
        navigator.userAgent.indexOf("Win64") != -1 ){
        document.getElementById("windowsDeviceDetector").innerHTML = '<a href="https://s3.amazonaws.com/trinka-word-plugin/64bit/Trinka.Installer.msi" target="_blank"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2044_76462)"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 18H18V10.8H10V18ZM2 18H9.2V10.8H2V18ZM10 10H18V2H10V10ZM2 10H9.2V2H2V10Z" fill="white"/></g><defs><clipPath id="clip0_2044_76462"><rect width="16" height="16" fill="white" transform="translate(2 2)"/></clipPath></defs></svg><i>Windows Add-in</i></a>';
      } else {
        document.getElementById("windowsDeviceDetector").innerHTML = '<a href="https://s3.amazonaws.com/trinka-word-plugin/32bit/Trinka.Installer.msi" target="_blank"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2044_76462)"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 18H18V10.8H10V18ZM2 18H9.2V10.8H2V18ZM10 10H18V2H10V10ZM2 10H9.2V2H2V10Z" fill="white"/></g><defs><clipPath id="clip0_2044_76462"><rect width="16" height="16" fill="white" transform="translate(2 2)"/></clipPath></defs></svg><i>Windows Add-in</i></a>';
    }
    const browserName = this.sharedService.detectCurrentBrowser();
    this.browserIcon = browserName === 'Chrome' ? 'googleChormeIconNew' : browserName;
    this.checkForExtensionInstalled(browserName);
    this.currentSelectedLeftMenu = this.router.url.includes('/auto-edit') ? 'AFE' : this.currentSelectedLeftMenu;

    this.router.events.subscribe((val: any) => {
      this.currentSelectedLeftMenu = val && val.url && val.url.includes('/auto-edit') ? 'AFE' : 'myDrive';
    })
    this.loadSelectedProfileData();
    this.fetchReferralRewards();
  }

  openShareModal() {
    this.sharedService.openShareDialog();
  }

  // getUserProfileData() {
  //   this.networkCalls.getUserDetails(this.userId).subscribe(data => {
  //     this.userDetails = data.data
  //   }, error => {
  //     this.sharedService.errorHandller(error);
  //   })
  // }

  createNewFileCall() {
    // this.spinner.show();
    this.fileSectionLoader = true;
    this.networkCalls.createNewFile(this.userId,{}).subscribe(result => {

      this.amplitudeService.logAmplitude('file_new',{
        'userID' : this.userId,
        'fileID': result['data']['file_id'],
        'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
      });
      // this.router.navigate(['/editor/main', result['data']['file_id']])
      //Enabling new editor for BASIC & PREMIUM_PLUS user for now
      let redirectRoute = this.creditsDetails['user_type'] === 'BASIC' || this.creditsDetails['user_type'] === 'PREMIUM_PLUS' ? "/editor/main-v1/" : "/editor/main/";
      document.location.href = document.location.origin + redirectRoute + result['data']['file_id'];
      this.spinner.hide();
      this.fileSectionLoader = false;
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }
  // openFileUploadModal(){
  //   this.sharedService.openFileUploadDialog()
  // }

  openGoogleDrivePicker(){

    this.resetCloudFileDetails();
    this.uploadSource["GOOGLE_DRIVE"] = true;
    this.cloudDriveFileDetails.fileId = "";

    this.googleDrivePickerService.open((data) => {
      if (data.action === 'picked') {
        let file_details = data.docs[0];
        this.fileName = file_details.name;
        if(!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx") || this.fileName.endsWith(".txt"))){
          this.fileName +=".docx"
        }
        this.cloudDriveFileDetails.fileId = file_details.id;
        this.cloudDriveFileDetails.fileName = this.fileName;
        this.cloudDriveFileDetails.source = "GOOGLE_DRIVE";
        this.cloudDriveFileDetails.oauthToken = this.googleDrivePickerService.oauthAccessToken;
        this.appRef.tick();
        this.amplitudeService.logAmplitude('DR_Upload_via_Googledrive',{ });
        this.selectFileUploadOption();
      }
    });
  }


  launchOneDrivePicker(){
    this.resetCloudFileDetails();
    this.uploadSource["ONE_DRIVE"] = true;
    this.cloudDriveFileDetails.fileId = "";
    var that =this;
    var odOptions = {
      clientId: environment.one_drive_client_id,
      action: "download",
      multiSelect: false,
      openInNewWindow: true,
      advanced: {
        queryParameters: "select=id,name,size,file,folder,@microsoft.graph.downloadUrl",
        filter: [".docx",".doc", ".txt"],
        redirectUri: environment.cloud_redirect_url+"dashboard/auto-edit",
        },
      success: function(files) {
        for (const file of files.value) {
            that.fileName = file.name;
            that.cloudDriveFileDetails.fileId = file.id;
            that.cloudDriveFileDetails.fileName = file.name;
            that.cloudDriveFileDetails.fileUrl = file['@microsoft.graph.downloadUrl'];
            that.cloudDriveFileDetails.source = "ONE_DRIVE";
            that.appRef.tick();
            that.amplitudeService.logAmplitude('DR_Upload_via_OneDrive',{ });
            that.selectFileUploadOption();
        }
       },
      cancel: function() {
       },
      error: function(e) {
       }
    }
    OneDrive.open(odOptions);
  }

  launchDropBoxPicker(){
    this.resetCloudFileDetails();
    this.uploadSource["DROP_BOX"] = true;
    this.cloudDriveFileDetails.fileId = "";
    let that =this;
    var options: DropboxChooseOptions = {
      success: function (files) {
           for (const file of files) {
                  that.fileName = file.name;
                  that.cloudDriveFileDetails.fileName = file.name;
                  that.cloudDriveFileDetails.fileUrl = file.link;
                  that.cloudDriveFileDetails.source = "DROP_BOX";
              }
        that.amplitudeService.logAmplitude('DR_Upload_via_DropBox',{ });
        that.selectFileUploadOption();
      },
      cancel: function () {
      },
      linkType: "direct",
      multiselect: false,
      extensions: ['.doc', '.docx', '.txt'],
  };

  Dropbox.choose(options);
  }


  resetCloudFileDetails(){
    let that= this;
    this.fileName = null;

    Object.keys(this.uploadSource).forEach(function(key){
      that.uploadSource[key] = false;
    });

    Object.keys(this.cloudDriveFileDetails).forEach(function(key){
      that.cloudDriveFileDetails[key] = "";
    });

  }


  selectFileUploadOption(){
    let upload_source = null;
    var that = this;
    Object.keys(this.uploadSource).forEach(function(key){
      if(that.uploadSource[key])
      {
        upload_source = key;
      }
    });
    const dialogRef = this.dialog.open(DashboardFileUploadSelectionModalComponent,{
      data: {"file_name": this.fileName, "upload_source": upload_source}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
      if(result.data=="AutoEdit"){
        if(this.uploadSource["LOCAL_SYSTEM"]){
          this.networkCalls.setDashboardLocalFileDetails(this.file);
        }
        else{
          this.networkCalls.setDashboardCloudFileDetails(this.cloudDriveFileDetails);
        }
        this.router.navigate(['/dashboard/auto-edit']);
      }
      else{
          this.uploadForManualCloudFileProcessing();
      }
    }
    });
  }

  uploadForManualCloudFileProcessing(){
    // this.spinner.show();
    this.fileSectionLoader = true;
    var formData = new FormData();

    if(this.uploadSource["LOCAL_SYSTEM"]){
      formData.append('file', this.file);
    }else{
      formData.append('cloudFileDetails', JSON.stringify(this.cloudDriveFileDetails));
    }

    this.networkCalls.uploadFile(this.userId, formData).subscribe(result => {

            this.amplitudeService.logAmplitude('file_upload',{
              'userID' : this.userId,
              'format' : this.fileType,
              'fileID': result['data']['file_id'],
              'editor': 'V0'
              });
        // this.spinner.hide();
        this.fileSectionLoader = false;
        //Enabling new editor for BASIC & PREMIUM_PLUS user for now
        let redirectRoute = this.creditsDetails['user_type'] === 'BASIC' || this.creditsDetails['user_type'] === 'PREMIUM_PLUS' ? "/editor/main-v1/" : "/editor/main/";
        document.location.href = document.location.origin + redirectRoute + result['data']['file_id'];
    }, error => {
      // this.spinner.hide();
      this.fileSectionLoader = false;
      this.toastr.error("Failed to process uploaded file","Error");
      this.sharedService.errorHandller(error);
    });

  }

  uploadFile(elemId) {
    this.resetCloudFileDetails();
    this.uploadSource["LOCAL_SYSTEM"]= true;
    // var elem = document.getElementById(elemId);
    // if(elem && document.createEvent) {
    //    var evt = docume
    //    evt.initEvent("click", true, false);
    //    elem.dispatchEvent(evt);
    // }

    var input = document.createElement('input');
    input.type = 'file';

    input.onchange = e => {
      this.file = e.target['files'][0];
      let fileSize = this.file.size;
      fileSize = (fileSize / 1024) / 1024;
      this.fileType = this.file.name.split('.').pop().toLowerCase().toString();
      if (['doc', 'docx', 'txt'].indexOf(this.fileType) != -1) {

        if (fileSize <= 25) {
          this.amplitudeService.logAmplitude('DR_Upload_via_Localdevice',{ });
          this.fileName = this.file.name;
          this.selectFileUploadOption();
        } else {
          this.toastr.error("File size is greater than 25MB","Error");
        }
      } else {
        this.toastr.error('Please upload file with doc, docx,txt format.',"Error");
      }

    }

    input.click();
  }

  signOut() {
    this.sharedService.SignOut();
  }

  openChangeProfile() {
    if (Object.keys(this.selectedProfile).length === 0 && this.selectedProfile.constructor === Object) {
      // this.spinner.show();
      this.fileSectionLoader = true;
      this.networkCalls.getUserMultipleProfile(this.userId).subscribe(result => {
        if (result["status"]) {
          this.profileList = result['data'];
          // this.spinner.hide();
          this.fileSectionLoader = false;
          this.dialog.open(SelectProfileModalComponent, { data: { profileList: this.profileList, selectedProfile: this.selectedProfile } }).afterClosed().subscribe(() => {
            this.getSelectedProfileData();
          })
        } else {

        }
      }, error => {
        this.sharedService.errorHandller(error);
      })
    }
  }

  getSelectedProfileData(newUser?) {
    this.verifiedUserService.getSelectedProfileData(newUser);
    this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(profileData=>{
      this.selectedProfile = profileData;
      if(this.selectedProfile.is_social_login){
        this.amplitudeService.logAmplitude('Google_Login_User',{
          'User ID': this.userId
        });
      }
    });
  }

  featurePermissionRequest() {

    this.dashboardService.getStoreUserFeatureDetails().subscribe(resp => {
      if(Object.keys(resp).length == 0){
        this.networkCalls.featurePermission().subscribe(res => {
          this.sharedService.featurePermission = res;
          this.dashboardService.storeUserFeatureDetails(res);
          this.checkPermission();
        }, error => {
          this.sharedService.errorHandller(error);
        })
      }
      else{
        this.sharedService.featurePermission = resp;
        this.checkPermission();
      }
    });


  }

  checkPermission(){
    this.activeRoute.url.subscribe(() => {

      if(this.activeRoute.snapshot.firstChild.routeConfig.path == "auto-edit"){
        if(!this.sharedService.featurePermission.data.auto_edit){
          this.router.navigate(['/404']);
        }
      }
      if(this.activeRoute.snapshot.firstChild.routeConfig.path == "plagiarism-check"){
        if(!this.sharedService.featurePermission.data.plagiarism_check){
          this.router.navigate(['/404']);
        }
      }
      if(this.activeRoute.snapshot.firstChild.routeConfig.path == "citation-check"){
        if(!this.sharedService.featurePermission.data.ref_check){
          this.router.navigate(['/404']);
        }
      }
      if(this.activeRoute.snapshot.firstChild.routeConfig.path == "journal-finder-tool"){
        if(!this.sharedService.featurePermission.data.journal_finder){
          this.router.navigate(['/404']);
        }
      }
     });
  }

  getAutoEditCredit() {
    if (this.sharedService.featurePermission.data.auto_edit === true) {
      this.networkCalls.getAutoEditCredits().subscribe(response => {
        this.sharedService.autoEditFileCredit = response.data['credit_limit'];
      })
    }
    else {
    }
  }

  verifyEmail(){
    // this.verifiedUserService.verifyEmail(this.selectedProfile);
  }

  getUserPaymentSubscription(){
    this.networkCalls.getStoreUserPlanDetails().subscribe(resp=> {
      if(Object.keys(resp).length == 0){
          this.networkCalls.getUserPaymentSubscription(this.userId).subscribe(response => {
            this.paymentDetails = response['data'];
            this.networkCalls.storeUserPlanDetails(this.paymentDetails);
            this.amplitudeService.setUserProperties('subscription_type',(this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase()));
            if(this.isNewUser){
              this.amplitudeService.logAmplitude('Product_tour_popupview',{
                'User ID': this.userId,
                'Subscription Type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type)
              });
            }
            if(this.paymentDetails.user_type=="PREMIUM_TRIAL"){
              this.getSubsciptionDetails();
            }
          });
        }
        else{
          this.paymentDetails = resp;
          if(this.paymentDetails.user_type=="PREMIUM_TRIAL"){
            this.getSubsciptionDetails();
          }
        }

    });
  }

  logMyDriveAmplitude(){
    this.amplitudeService.logAmplitude('Mydrive_pageview',{
      'User ID': this.userId,
      'Subscription Type':this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type)
    });
  }

  logAddtoBroswerCTA(selectedOption: string = '') {
    this.amplitudeService.logAmplitude(selectedOption, {
      'User ID': this.userId,
      'Subscription Type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'Browser Name': this.currentBrowserName
    });
  }

  logLeftPanelOption(selectedOption: string = '') {
    this.amplitudeService.logAmplitude(selectedOption, {
      'User ID': this.userId,
      'Subscription Type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'From': "Left Panel"
    });
  }

  upgradePlanPopup(){
    let data = {
      "user_id" : this.userId,
      "plan_name": this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      "from":"Trinka"
    };

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url  = environment.payment_fe;
    window.open(redirct_url + '/user/' + this.userData, "_blank");

    this.amplitudeService.logAmplitude('Upgrade_nudge_clicked', {
      'from': this.router.url.includes('/auto-edit') ? 'Proofread' : this.router.url.includes('mydrive') ? 'Mydrive' : this.router.url.includes('mydictionary') ? 'Mydictionary' : 'Account settings',
      'Current Plan': this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    })
  }

  getFiles(){
    this.myDriveService.getFilesByUser(this.userId).subscribe(response => {
    });
  }

  logRefAmplitude(){
    this.amplitudeService.logAmplitude('RFQ_MyDrive_CTA',{
      'Credit Balance Free':this.creditsDetails.credits,
      'Credit Balance Purchased':this.creditsDetails.purchased_credits
    });
  }

  logJournalAmplitude()
  {
    this.amplitudeService.logAmplitude('JFR_Mydrive_CTA',{
      'Credit Balance Free':this.creditsDetails.credits,
      'Credit Balance Purchased':this.creditsDetails.purchased_credits
    });
  }

  getCreditsDetails() {

    this.networkCalls.getStoreUserCreditDetails().subscribe(resp => {
      if(Object.keys(resp).length == 0)
      {
        this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(
          (response) => {
            if (response["status"]) {
              this.creditsDetails = response.data;
              this.networkCalls.storeUserCreditDetails(this.creditsDetails);
            }
          },
          (error) => {
            this.sharedService.errorHandller(error);
          }
        );
      }
      else{
        this.creditsDetails = resp;
      }
    });
  }

  checkForExtensionInstalled(browserName: string) {
    this.currentBrowserName = browserName;
    // TODO: To be added after discussion for extension installed logic
    switch (browserName) {
      case 'Chrome':
        this.pluginsService.isChromeExtensionInstalled().then((response: any) => {
          this.showExtensionAddMsg = response;
        }).catch((reject: any) => {
          this.showExtensionAddMsg = reject;
        });
        break;
      case 'Firefox':
        this.showExtensionAddMsg = true;
        // const tokenPayload = {
        //   type:"connectionPing",
        //   target:"bg",
        // }
        // this.pluginsService.sendMessageToBrowserExtension(tokenPayload);
        // this.pluginsService.isFirefoxExtensionInstalled().then((response: any) => {
        //   console.log('response is: ', response);
        //   this.showExtensionAddMsg = response;
        // }).catch((reject: any) => {
        //   console.log('reject is: ', reject);
        //   this.showExtensionAddMsg = reject;
        // });
        break;
      case 'Edge':
        this.showExtensionAddMsg = false;
        break;
      default:
        console.log('Cannot detect browser');
        this.showExtensionAddMsg = false;
        break;
    }

  }

  getBrowserExtensionUrl(browserName: string) {
    let extensionUrl = '';
    switch (browserName) {
      case 'Chrome':
        extensionUrl = 'https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp';
        break;
      case 'Firefox':
        extensionUrl = 'https://addons.mozilla.org/en-US/firefox/addon/trinka-ai-firefox-browser/';
        break;
      case 'Edge':
        extensionUrl = 'https://microsoftedge.microsoft.com/addons/detail/trinka-ai-for-edge/kjhkcgjfbpppkbhhaocagkmfkkiibeap';
        break
      default:
        extensionUrl = 'https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp';
        break;
    }

    return extensionUrl;
  }


  showCreditModal(){
    if(this.creditsDetails != null){
      this.amplitudeService.logAmplitude('Credits_clicked',{
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits,
        'Subscription Plan': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'User ID': this.userId,
        'from': this.router.url.includes('/auto-edit') ? 'Proofread' : this.router.url.includes('mydrive') ? 'Mydrive' : this.router.url.includes('mydictionary') ? 'Mydictionary' : 'Profile'
      });
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData : this.creditsDetails,
      };
      this.dialog.open(CreditDetailsComponent, dialogConfig)
    }
  }

  resendMail(){
    this.spinner.show();
    this.networkCalls.sendResendEmailid(this.creditsDetails['email_id'], this.selectedProfile['firstname']).subscribe(result=>{
      this.spinner.hide();
      this.toastr.success("Email sent successfully.");
    },error=>{
      this.spinner.hide();
    });
  }


  addCredits() {
    this.amplitudeService.logAmplitude('Add_Credits_CTA',{
      'credit_balance_free': this.creditsDetails.credits,
      'credit_balance_purchased': this.creditsDetails.purchased_credits,
      'Subscription Plan': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'from': this.router.url.includes('/auto-edit') ? 'Proofread' : this.router.url.includes('mydrive') ? 'Mydrive' : this.router.url.includes('mydictionary') ? 'Mydictionary' : 'Profile'
    });
    let redirct_url  = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url+'/user/credit/'+this.userData,"_self");
  }

  uppercaseFirstLetter(phrase):string{
    if(!phrase) return '';
    else return phrase
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }

  sendEmailClose() {
    this.isDomainPopUpEnabled = false;
  }

  loadSelectedProfileData(){

    this.networkCalls.getSelectedProfileData(this.userId).subscribe(result => {
      this.isDomainPopUpEnabled = result['data']['profile_data'].domainPopUp;
    });

  }

  addBodyClass() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('responsiveMenu');
  }

  fetchReferralRewards(){
    this.networkCalls.getReferralRewards(this.userId).subscribe(response => {
        this.referralReward = response["referral_reward"] || 0;
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  showReferralRewardModal(){

    this.amplitudeService.logAmplitude("Referal_reward_clicked", {
      'User ID': this.userId,
      'Subscription Type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'Reward Available': this.referralReward
    });

    let data = {
      "user_id" : this.userId,
      "plan_name": this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      "from":"Trinka"
    };

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let subscriptionUpgradeUrl  = environment.payment_fe + '/user/' + this.userData;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      userId : this.userId,
      referralReward : this.referralReward,
      subscriptionUpgradeUrl: subscriptionUpgradeUrl,
      userType: this.creditsDetails.user_type
    };
    this.dialog.open(ReferralRewardModalComponent, dialogConfig);
  }

  getSubsciptionDetails() {
    this.networkCallsPayments.getPaymentSubscriptionDetails(this.userId).subscribe(response => {
      this.userSubscriptionDetails = response.data;
      let epochDate = new Date(this.userSubscriptionDetails['next_renewal_date']);
      let currentDate = new Date().getTime();
      let differenceInTime = epochDate.getTime() - currentDate;
      this.trialDayRemaing = Math.floor(differenceInTime / (1000 * 3600 * 24));
    }, error => {
       this.sharedService.errorHandller(error);
    });
  }


}
