<div class="word_plugin_body">
    <div class="word_plugin_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="ms_word_section_img">
                        <img src="../../../assets/images/ms-word-img.png" alt="">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="ms_word_section_text">
                        <h2>Write easily anywhere with Trinka Cloud</h2>
                        <p>Get access to Trinka right on your favourite browser. Write better anytime, anywhere. Trinka saves your files on the cloud too!</p>
                        <div class="buttons_optinos">
                            <button class="plansButtons" [routerLink]="['/dashboard']">   Get Started
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 384.928 384.928" style="enable-background:new 0 0 384.928 384.928;"
                                    xml:space="preserve">
                                    <g>
                                        <path id="Arrow_Download"
                                            d="M321.339,245.334c-4.74-4.692-12.439-4.704-17.179,0l-99.551,98.564V12.03
                                                c0-6.641-5.438-12.03-12.151-12.03s-12.151,5.39-12.151,12.03v331.868l-99.551-98.552c-4.74-4.704-12.439-4.704-17.179,0
                                                s-4.74,12.319,0,17.011l120.291,119.088c4.692,4.644,12.499,4.644,17.191,0l120.291-119.088
                                                C326.091,257.653,326.091,250.038,321.339,245.334C316.599,240.642,326.091,250.038,321.339,245.334z" />
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="ms_process_sections">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="process_line first_chalid_section">
                            <div class="process_inner_section">
                                <img src="../../../assets/images/Login.png" alt="">
                            </div>
                            <div class="process_heading_title">
                                <h3>Register / Login</h3>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-4">
                        <div class="process_line">
                            <div class="process_inner_section">
                                <img src="../../../assets/images/Import.png" alt="">
                            </div>
                            <div class="process_heading_title">
                                <h3>Type or import files</h3>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-4">
                        <div class="process_line last_chalid_scetion">
                            <div class="process_inner_section">
                                <img src="../../../assets/images/ImproveYourWriting.png" alt="">
                            </div>
                            <div class="process_heading_title">
                                <h3>Improve your writing</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <div class="better_writing_sections">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h2>Easy writing on the go </h2>
                    <p>Trinka Cloud lets you write anywhere you want. With real-time corrections, Trinka is always with you. The Trinka web editor is feature rich and saves your work automatically. Even better, you can import and export your documents too! Effective writing has never been so convenient!</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="writing_bullet_points">
                        <p>Write better anywhere</p>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="writing_bullet_points">
                        <p>Stores files on the cloud</p>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="writing_bullet_points">
                        <p>Import & export Word files </p>
                    </div>
                </div>

            </div>
        </div>
    </div>

    



    <div class="online_avaiable_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="avaiable_text">
                        <h2>Trinka also works with Word!</h2>
                        <p>The Trinka plug-in easily integrates with your Microsoft Word application so you get powerful corrections right where you like writing the most. </p>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="avaiable_get_started">
                        <div class="icons" style="margin: 0 10px 0 0;">
                            <img src="../../../assets/images/download_trinka_icon.png" alt="">
                        </div>
                        <div class="get_started_text">
                            <h3>Coming soon !</h3>
                            <p>FREE Microsoft Word plug-in</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>