import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class SubjectiveBehaviorSharedService {
  inclusiveFilterChange: Subject<any> = new Subject<any>();

  styleGuideSelect = new BehaviorSubject<any>('');
  getStyleGuide = this.styleGuideSelect.asObservable();

  instuctionSelection = new BehaviorSubject<any>('');
  getInstruction = this.instuctionSelection.asObservable();



  public docTypeDataSource = new BehaviorSubject<any>('');
  getDocTypeObject = this.docTypeDataSource.asObservable();

  public setForUpdateFolderList = new BehaviorSubject<any>('');
  syncFolderListBs = this.setForUpdateFolderList.asObservable();

  cardIsExpanded =  new Subject<any>();
  getIsExpandedCard = this.cardIsExpanded.asObservable();

  subjectAreaList = new BehaviorSubject<any>('');
  getSubjectAreaList = this.subjectAreaList.asObservable();

  public previous_url = new BehaviorSubject<any>('');
  getPreviousUrl = this.previous_url.asObservable();

  public moduleCheck = new BehaviorSubject<any>('');
  getModuleCheck = this.moduleCheck.asObservable();

  public allCheckReportData = new BehaviorSubject<any>('');
  getA1AllCheckReportData = this.allCheckReportData.asObservable();

  moduleChange = new BehaviorSubject<any>('');
  getModuleChange = this.moduleChange.asObservable();

  disableRightPanelMenuOptions = new BehaviorSubject<any>(false);
  getDisableRightPanelMenuOptionsFlag = this.disableRightPanelMenuOptions.asObservable();



  public readonly = new BehaviorSubject<any>(false);
  getFileReadOnly = this.readonly.asObservable();


  public profileData = new BehaviorSubject<any>('');
  getSelectedProfileData = this.profileData.asObservable();

  public wordLimitExeed = new BehaviorSubject<any>('');
  getWordLimitExeed = this.wordLimitExeed.asObservable();

  trialModeEnable = new BehaviorSubject<any>('');
  getTrialMode = this.trialModeEnable.asObservable();

  powerModePremiumStatus = new BehaviorSubject<any>('');
  getPowerModePremiumStatus = this.powerModePremiumStatus.asObservable();

  basicUserPowerModeTrialData = new BehaviorSubject<any>('');
  getBasicUserPowerModeTrialData = this.basicUserPowerModeTrialData.asObservable();

  purgeFileStatus = new BehaviorSubject<any>('');
  getPurgeFileStatus = this.purgeFileStatus.asObservable();

  amplitudeData = new BehaviorSubject<any>('');
  getAmplitudeData = this.amplitudeData.asObservable();

  editorFileId = new BehaviorSubject<any>('');
  editorFileName = new BehaviorSubject<any>('');
  editorLanguage = new BehaviorSubject<any>('');
  editorEnglishPrefernce = new BehaviorSubject<any>('');
  constructor(public amplitudeService: AmplitudeService) { }

  setFileReaduOnly(value){
    this.readonly.next(value);
  }

  changeStyleGuide(styleGuide: string, flag: boolean) {
    this.styleGuideSelect.next([styleGuide,flag]);
  }

  changeInstuctionModule(mode, instruction, module) {
    this.instuctionSelection.next({mode, instruction, module});
  }

  setDocumentTypeData(docTypeObject, flag: boolean){
    this.docTypeDataSource.next(docTypeObject);

  }

  setCardIsExpanded(flag){
    this.cardIsExpanded.next(flag);
  }

  setSubjectAreaList(array){
    this.subjectAreaList.next(array);
  }

  setPreviousUrl(url){
    this.previous_url.next(url);
  }

  setModuleCheck(module){
    this.moduleCheck.next(module);
  }

  setA1AllCheckReportData(allCheckReport){
    this.allCheckReportData.next(allCheckReport);
  }

  setSelectedProfileData(selectedProfileData){
    this.profileData.next(selectedProfileData);
  }

  setWordLimitExeed(limitExceed){
    this.wordLimitExeed.next(limitExceed);
  }

  setTrialMode(trialMode){
    this.trialModeEnable.next(trialMode);
  }

  setPowerModePremiumStatus(powerMode){
    this.powerModePremiumStatus.next(powerMode);
  }

  setBasicUserPowerModeTrialData(powerModeData){
    this.basicUserPowerModeTrialData.next(powerModeData);
  }

  setForUpdateFolderListTrigger(){
    this.setForUpdateFolderList.next('');
  }

  setpurgeFileStatus(status){
    this.purgeFileStatus.next(status);
  }

  public getEditorFileId(){
    return this.editorFileId.asObservable();
  }

  public setEditorFileId(data){
    this.editorFileId.next(data);
  }

  public getEditorFileName(){
    return this.editorFileName.asObservable();
  }

  public setEditorFileName(data){
    this.editorFileName.next(data);
  }
  setAmplitudeData(data){
    this.amplitudeData.next(data);
  }

  public setEditorLanguage(data) {
    this.editorLanguage.next(data);
  }

  public getEditorLanguage() {
    return this.editorLanguage.asObservable();
  }
  public setEditorEnglishPrefernce(data) {
    this.editorEnglishPrefernce.next(data);
  }

  public getEditorEnglishPrefernce() {
    return this.editorEnglishPrefernce.asObservable();
  }
}
