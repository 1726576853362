import { Component } from '@angular/core';
import { ActivationEnd, Router, RouterEvent, RouterModule } from '@angular/router';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { TawkService } from './_services/tawk.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TrinkaFrontEndNew';

  constructor(private _router: Router, private _tawkService: TawkService) {
    let previousRoute = '';
    this._router.events.pipe(
      filter((e) => e instanceof ActivationEnd),
      distinctUntilChanged((prev: any, curr: any) => this._router.url === previousRoute),
      tap(() => previousRoute = this._router.url),
    ).subscribe((event: RouterEvent) => {
      this._tawkService.waitTillTawkIsLoaded().then(() => {
        if(['/dashboard/mydrive/files'].includes(this._router.routerState.snapshot.url)) {
          this._tawkService.showWidget();
        } else {
          this._tawkService.hideWidget();
        }
      });
    });
  }

  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }
}
