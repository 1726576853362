<ngx-spinner></ngx-spinner>
<div class="report_body">
    <div class="report_header">
        <!-- <div class="report_back_btn"  [routerLink]="['/plagiarism-checker']">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.49012e-08 7C1.49012e-08 6.44772 0.447715 6 1 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H1C0.447715 8 1.49012e-08 7.55228 1.49012e-08 7Z" fill="#414E62"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289Z" fill="#414E62"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70711 0.292893C8.09763 0.683417 8.09763 1.31658 7.70711 1.70711L1.70711 7.70711C1.31658 8.09763 0.683417 8.09763 0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893Z" fill="#414E62"/>
            </svg>
        </div> -->
        <div class="logo">
            <img src="../../../assets/images/Trinka_Logo.svg" [routerLink]="['/dashboard']" alt="logo" />
        </div>

        <div class="share_btn">
            <a class="download" (click)="close_report()">
                <img  alt="close_btn" src="../../../assets/images/closeIcons.png">
            </a>
            <!-- <a class="share" (click)="shareReport()" *ngIf="isShareEnable">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 3.5C9.5 1.84315 10.8431 0.5 12.5 0.5C14.1569 0.5 15.5 1.84315 15.5 3.5C15.5 5.15685 14.1569 6.5 12.5 6.5C11.5906 6.5 10.7758 6.0954 10.2256 5.45641L6.44119 7.40597C6.47976 7.59798 6.5 7.79663 6.5 8C6.5 8.20921 6.47859 8.41341 6.43783 8.61054L10.2148 10.5562C10.7651 9.90994 11.5847 9.5 12.5 9.5C14.1569 9.5 15.5 10.8431 15.5 12.5C15.5 14.1569 14.1569 15.5 12.5 15.5C10.8431 15.5 9.5 14.1569 9.5 12.5C9.5 12.2965 9.52026 12.0978 9.55887 11.9057L5.77456 9.95619C5.22439 10.5953 4.40946 11 3.5 11C1.84315 11 0.5 9.65685 0.5 8C0.5 6.34315 1.84315 5 3.5 5C4.41545 5 5.23512 5.41003 5.78539 6.05647L9.56223 4.11082C9.52143 3.91361 9.5 3.70931 9.5 3.5ZM12.5 2C11.6716 2 11 2.67157 11 3.5C11 4.32843 11.6716 5 12.5 5C13.3284 5 14 4.32843 14 3.5C14 2.67157 13.3284 2 12.5 2ZM11.1116 11.9312C11.0397 12.1066 11 12.2987 11 12.5C11 13.3284 11.6716 14 12.5 14C13.3284 14 14 13.3284 14 12.5C14 11.6716 13.3284 11 12.5 11C11.9328 11 11.4392 11.3148 11.1842 11.7791C11.1727 11.8124 11.1586 11.8453 11.142 11.8776C11.1325 11.896 11.1224 11.9139 11.1116 11.9312ZM4.85857 7.37759C4.84247 7.34632 4.82877 7.31445 4.81742 7.28218C4.56295 6.81614 4.06839 6.5 3.5 6.5C2.67157 6.5 2 7.17157 2 8C2 8.82843 2.67157 9.5 3.5 9.5C4.32843 9.5 5 8.82843 5 8C5 7.79757 4.9599 7.60451 4.88722 7.42832C4.8771 7.41192 4.86755 7.39501 4.85857 7.37759Z" fill="#7A28A0"/>
                    </svg>
            Share</a> -->
            <!-- <a class="download" (click)="download()" *ngIf="isDownloadable"> -->
                <!-- <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.40098 1.2901C8.40098 0.853739 8.04724 0.5 7.61088 0.5C7.17452 0.5 6.82078 0.853739 6.82078 1.2901V8.85195L4.21908 6.25024C3.91052 5.94169 3.41026 5.94169 3.10171 6.25024C2.79316 6.55879 2.79316 7.05905 3.10171 7.36761L6.9767 11.2426C7.12072 11.4361 7.35117 11.5614 7.61088 11.5614C7.8706 11.5614 8.10105 11.4361 8.24506 11.2426L12.1201 7.36761C12.4286 7.05905 12.4286 6.55879 12.1201 6.25024C11.8115 5.94169 11.3112 5.94169 11.0027 6.25024L8.40098 8.85195V1.2901Z" fill="#7A28A0"/>
                    <path d="M1.2901 10.7594C1.72646 10.7594 2.0802 11.1132 2.0802 11.5495V13.1297C2.0802 13.3393 2.16344 13.5402 2.31161 13.6884C2.45978 13.8366 2.66075 13.9198 2.87029 13.9198H12.3515C12.561 13.9198 12.762 13.8366 12.9102 13.6884C13.0583 13.5402 13.1416 13.3393 13.1416 13.1297V11.5495C13.1416 11.1132 13.4953 10.7594 13.9317 10.7594C14.368 10.7594 14.7218 11.1132 14.7218 11.5495V13.1297C14.7218 13.7583 14.472 14.3612 14.0275 14.8058C13.583 15.2503 12.9801 15.5 12.3515 15.5H2.87029C2.24165 15.5 1.63876 15.2503 1.19424 14.8058C0.749727 14.3612 0.5 13.7583 0.5 13.1297V11.5495C0.5 11.1132 0.853739 10.7594 1.2901 10.7594Z" fill="#7A28A0"/>
                    </svg> -->
                <!-- Download PDF</a> -->
        </div>
            
    </div>
    <div class="pdf-container">
        <!-- <pdf-viewer
          [src]="downloadUrl"
          [rotation]="0"
          [original-size]="false"
          [show-all]="true"
          [fit-to-page]="false"
          [zoom]="0.5"
          [zoom-scale]="'page-width'"
          [stick-to-page]="false"
          [render-text]="true"
          [external-link-target]="'blank'"
          [autoresize]="true"
          [show-borders]="false"
          style="width: 60%; height: 600px;"
          (on-progress)="onProgress($event)"
          (page-rendered)="pageRendered($event)"
        ></pdf-viewer> -->
        <iframe width="100%" height="100%" frameBorder="0" [src]="reportUrl"></iframe>
      </div>
</div>