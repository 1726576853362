<!-- <div class="quillEditorSection" style="height: 100%; white-space:break-spaces; width: calc(100% - 395px);">
  <div #quillEditor style="padding: 10px 0% 100px;border-left: none;"></div>
  <div class="quillEditorOuterWrapper" #quillEditor></div>
  <div id="counter">Word Count: 0</div>

</div> -->
<app-ckeditor-component (onEditorChange)="debounceSaveFile(1500)" [editorContent]="editorContent"></app-ckeditor-component>

<app-editor-plag-check-report-viewer class="container-fluid  report_viwer editor_section" *ngIf="viewerEnabled" (viewerEnabled)="close_report($event)" (generate_advance_report)="generate_advance_report($event)" [plagCheckReportUrl]="plagCheckReportUrl" [isSamplePlagCheckReport]="isSamplePlagCheckReport" [isAdvancePlagCheckReport]="isAdvancePlagCheckReport"></app-editor-plag-check-report-viewer>

<ng-container class="testing" style="height: 500px !important; background-color: red;">
  <div>
    <ng-container [ngSwitch]="enabledModule">
      <ng-container *ngSwitchDefault>
      </ng-container>
      <ng-container *ngSwitchCase="'PC'">
        <div class="PremiumPlusUserStandardPC" style="    position: absolute;
        top: 43px;right: 85px;left: auto;background: #EEE;width: 390px;">
          <div class="PremiumPlusUserStandardPCHeader">
            <h2>Plagiarism Checker</h2>
            <div class="pageAvailableSection">
              <p>Pages available: <span>{{avaialbleScan}}</span></p>
              <div class="availablePagePopup">
                <span>For Standard Plagiarism</span>
                <h3>1 Page will be used per 250 words</h3>
                <div class="availablePagePopupFooter">
                  <h4><i>Your file has approximately</i> {{plagFileWordCount}} words so maximum {{calulatePageCount()}}
                    Page<b style="font-weight: 600;" *ngIf="calulatePageCount() > 1">s</b> will be used.</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="PremiumPlusUserStandardPCBody">

            <!-- Recheck Plagiarism Check -->
            <div class="RecheckPlagiarismCheck" *ngIf="creditsDetails['user_type']=='PREMIUM_PLUS' && plagCheckFileList.length > 0 && plagCheckFileList[0].file_status!='IN_PROGRESS'">
             <div class="RecheckPlagCheckButton">
               <p>Checks against open access journals and internet articles</p>
                 <button *ngIf="!isScanCalculated && noOfScanRequired==0" [disabled]="!noTextFound()" (click)="calculatenoOfScanRequired()">{{ avaialbleScan > calulatePageCount() ? 'Recheck Standard Plagiarism' : 'Check Plagiarism using credits' }}</button>
                 <button class="CalculatingNumberScans" *ngIf="isScanCalculated && noOfScanRequired==0">
                   <svg class="spinner">
                     <circle style="cx: 50%;cy: 50%; r: 50%;">
                         <animateTransform attributeName="transform" type="rotate" values="-90;810" keyTimes="0;1" dur="2s" repeatCount="indefinite"></animateTransform>
                         <animate attributeName="stroke-dashoffset" values="0%;0%;-157.080%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
                         <animate attributeName="stroke-dasharray" values="0% 314.159%;157.080% 157.080%;0% 314.159%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
                     </circle>
                   </svg>
                   Calculating number of Page
                 </button>
                 <!-- <button *ngIf="isScanCalculated && noOfScanRequired > 0" [disabled]="isGeneratingPlagCheckReport" (click)="process_plagiarism_check(3)">Check Plagiarism for {{noOfScanRequired}} page</button> -->

                 <div class="pageInforWithProceed" *ngIf="isScanCalculated && noOfScanRequired > 0">
                   <ul>
                     <li>1 Page = 250 words</li>
                     <li><span>{{noOfScanRequired}} Pages</span> will be used</li>
                   </ul>
                   <button [disabled]="isGeneratingPlagCheckReport" (click)="process_plagiarism_check(3)">Proceed</button>
                 </div>

               </div>
             </div>

             <div class="notEnoughtPageSection" *ngIf="creditsDetails['user_type']=='PREMIUM_PLUS' && avaialbleScan<calulatePageCount()  && plagCheckFileList.length > 0 && plagCheckFileList[0].file_status !== 'IN_PROGRESS'">
               <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M10.0001 1.66669C5.40841 1.66669 1.66675 5.40835 1.66675 10C1.66675 14.5917 5.40841 18.3334 10.0001 18.3334C14.5917 18.3334 18.3334 14.5917 18.3334 10C18.3334 5.40835 14.5917 1.66669 10.0001 1.66669ZM9.37508 6.66669C9.37508 6.32502 9.65842 6.04169 10.0001 6.04169C10.3417 6.04169 10.6251 6.32502 10.6251 6.66669V10.8334C10.6251 11.175 10.3417 11.4584 10.0001 11.4584C9.65842 11.4584 9.37508 11.175 9.37508 10.8334V6.66669ZM10.7667 13.65C10.7251 13.7584 10.6667 13.8417 10.5917 13.925C10.5084 14 10.4167 14.0584 10.3167 14.1C10.2167 14.1417 10.1084 14.1667 10.0001 14.1667C9.89175 14.1667 9.78341 14.1417 9.68342 14.1C9.58342 14.0584 9.49175 14 9.40841 13.925C9.33342 13.8417 9.27508 13.7584 9.23342 13.65C9.19175 13.55 9.16675 13.4417 9.16675 13.3334C9.16675 13.225 9.19175 13.1167 9.23342 13.0167C9.27508 12.9167 9.33342 12.825 9.40841 12.7417C9.49175 12.6667 9.58342 12.6084 9.68342 12.5667C9.88342 12.4834 10.1167 12.4834 10.3167 12.5667C10.4167 12.6084 10.5084 12.6667 10.5917 12.7417C10.6667 12.825 10.7251 12.9167 10.7667 13.0167C10.8084 13.1167 10.8334 13.225 10.8334 13.3334C10.8334 13.4417 10.8084 13.55 10.7667 13.65Z" fill="#DC2626"/>
               </svg>
               <p>Not enough Pages are available to run this report. You can instead use <strong>credits</strong> to run the report.</p>
             </div>
            <!-- Get unlimited access to standard Plag check with New desgin  -->
           <div class="getUnlimitedAccesSection" *ngIf="creditsDetails['user_type']!='PREMIUM_PLUS'">
             <div class="getUnlimitedBox">
               <h2>Get unlimited access to Standard Plagiarism Check with Trinka Premium Plus</h2>
               <div class="getUnlimitedBoxContainer">
                 <h4>
                   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <g clip-path="url(#clip0_7731_14449)">
                     <path d="M19.8861 10.0695L16.6724 5.3028C16.5446 5.11393 16.3287 5 16.0978 5H4.90285C4.67176 5 4.45604 5.11393 4.32828 5.3028L1.1146 10.0695C0.943125 10.3228 0.966014 10.6571 1.17028 10.8849L9.98188 20.7691C10.112 20.916 10.3009 21 10.4999 21C10.6989 21 10.8878 20.916 11.0186 20.7685L19.8302 10.8843C20.0338 10.6571 20.0567 10.3228 19.8861 10.0695ZM6.60811 11.1165L8.74374 17.3303L3.20397 11.1165H6.60811ZM12.0888 6.34973L13.0123 9.76742H7.98588L8.90936 6.34973H12.0888ZM12.9373 11.1165L10.4996 18.2113L8.06183 11.1165H12.9373ZM14.3912 11.1165H17.7954L12.2557 17.3303L14.3912 11.1165ZM18.0311 9.7667H14.4379L13.5145 6.349H15.7273L18.0311 9.7667ZM5.27262 6.34969H7.48542L6.56194 9.76738H2.96791L5.27262 6.34969Z" fill="#FDE68A"/>
                     <path d="M5 0L5.67523 1.82477L7.5 2.5L5.67523 3.17523L5 5L4.32477 3.17523L2.5 2.5L4.32477 1.82477L5 0Z" fill="#FDE68A"/>
                     <path d="M2.16667 4L2.61682 5.21652L3.83333 5.66667L2.61682 6.11682L2.16667 7.33333L1.71652 6.11682L0.5 5.66667L1.71652 5.21652L2.16667 4Z" fill="#FDE68A"/>
                     </g>
                     <defs>
                     <clipPath id="clip0_7731_14449">
                     <rect width="20" height="20" fill="white"/>
                     </clipPath>
                     </defs>
                   </svg>
                   Premium Plus includes:
                 </h4>
                 <ul>
                   <li>
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_7731_14428)">
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8051 4.20014C14.0654 4.46049 14.0654 4.8826 13.8051 5.14295L7.1384 11.8096C6.87805 12.07 6.45594 12.07 6.19559 11.8096L2.86225 8.47629C2.6019 8.21594 2.6019 7.79383 2.86225 7.53348C3.1226 7.27313 3.54471 7.27313 3.80506 7.53348L6.66699 10.3954L12.8623 4.20014C13.1226 3.9398 13.5447 3.9398 13.8051 4.20014Z" fill="white"/>
                       </g>
                       <defs>
                       <clipPath id="clip0_7731_14428">
                       <rect width="16" height="16" fill="white"/>
                       </clipPath>
                       </defs>
                     </svg>
                     Unlimited access to Standard Plagiarism</li>
                   <!-- <li>
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_7731_14428)">
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8051 4.20014C14.0654 4.46049 14.0654 4.8826 13.8051 5.14295L7.1384 11.8096C6.87805 12.07 6.45594 12.07 6.19559 11.8096L2.86225 8.47629C2.6019 8.21594 2.6019 7.79383 2.86225 7.53348C3.1226 7.27313 3.54471 7.27313 3.80506 7.53348L6.66699 10.3954L12.8623 4.20014C13.1226 3.9398 13.5447 3.9398 13.8051 4.20014Z" fill="white"/>
                       </g>
                       <defs>
                       <clipPath id="clip0_7731_14428">
                       <rect width="16" height="16" fill="white"/>
                       </clipPath>
                       </defs>
                     </svg>
                     Unlimited access to AI content detector</li> -->
                   <li>
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_7731_14428)">
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8051 4.20014C14.0654 4.46049 14.0654 4.8826 13.8051 5.14295L7.1384 11.8096C6.87805 12.07 6.45594 12.07 6.19559 11.8096L2.86225 8.47629C2.6019 8.21594 2.6019 7.79383 2.86225 7.53348C3.1226 7.27313 3.54471 7.27313 3.80506 7.53348L6.66699 10.3954L12.8623 4.20014C13.1226 3.9398 13.5447 3.9398 13.8051 4.20014Z" fill="white"/>
                       </g>
                       <defs>
                       <clipPath id="clip0_7731_14428">
                       <rect width="16" height="16" fill="white"/>
                       </clipPath>
                       </defs>
                     </svg>
                     Unlimited access to Grammar Checks and Paraphraser</li>
                   <li>
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_7731_14428)">
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8051 4.20014C14.0654 4.46049 14.0654 4.8826 13.8051 5.14295L7.1384 11.8096C6.87805 12.07 6.45594 12.07 6.19559 11.8096L2.86225 8.47629C2.6019 8.21594 2.6019 7.79383 2.86225 7.53348C3.1226 7.27313 3.54471 7.27313 3.80506 7.53348L6.66699 10.3954L12.8623 4.20014C13.1226 3.9398 13.5447 3.9398 13.8051 4.20014Z" fill="white"/>
                       </g>
                       <defs>
                       <clipPath id="clip0_7731_14428">
                       <rect width="16" height="16" fill="white"/>
                       </clipPath>
                       </defs>
                     </svg>
                     Unlimited access to Word and Browser add-ins</li>
                   <li>
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_7731_14428)">
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8051 4.20014C14.0654 4.46049 14.0654 4.8826 13.8051 5.14295L7.1384 11.8096C6.87805 12.07 6.45594 12.07 6.19559 11.8096L2.86225 8.47629C2.6019 8.21594 2.6019 7.79383 2.86225 7.53348C3.1226 7.27313 3.54471 7.27313 3.80506 7.53348L6.66699 10.3954L12.8623 4.20014C13.1226 3.9398 13.5447 3.9398 13.8051 4.20014Z" fill="white"/>
                       </g>
                       <defs>
                       <clipPath id="clip0_7731_14428">
                       <rect width="16" height="16" fill="white"/>
                       </clipPath>
                       </defs>
                     </svg>
                     10 Free credits per month</li>
                   <li>
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_7731_14428)">
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8051 4.20014C14.0654 4.46049 14.0654 4.8826 13.8051 5.14295L7.1384 11.8096C6.87805 12.07 6.45594 12.07 6.19559 11.8096L2.86225 8.47629C2.6019 8.21594 2.6019 7.79383 2.86225 7.53348C3.1226 7.27313 3.54471 7.27313 3.80506 7.53348L6.66699 10.3954L12.8623 4.20014C13.1226 3.9398 13.5447 3.9398 13.8051 4.20014Z" fill="white"/>
                       </g>
                       <defs>
                       <clipPath id="clip0_7731_14428">
                       <rect width="16" height="16" fill="white"/>
                       </clipPath>
                       </defs>
                     </svg>
                     Discounted rates on new credit purchase</li>
                 </ul>
                 <button class="upgradeToPPButton" (click)="upgradeToPremium()">
                   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <g clip-path="url(#clip0_7731_14413)">
                     <path d="M19.8861 10.0695L16.6724 5.3028C16.5446 5.11393 16.3287 5 16.0978 5H4.90285C4.67176 5 4.45604 5.11393 4.32828 5.3028L1.1146 10.0695C0.943125 10.3228 0.966014 10.6571 1.17028 10.8849L9.98188 20.7691C10.112 20.916 10.3009 21 10.4999 21C10.6989 21 10.8878 20.916 11.0186 20.7685L19.8302 10.8843C20.0338 10.6571 20.0567 10.3228 19.8861 10.0695ZM6.60811 11.1165L8.74374 17.3303L3.20397 11.1165H6.60811ZM12.0888 6.34973L13.0123 9.76742H7.98588L8.90936 6.34973H12.0888ZM12.9373 11.1165L10.4996 18.2113L8.06183 11.1165H12.9373ZM14.3912 11.1165H17.7954L12.2557 17.3303L14.3912 11.1165ZM18.0311 9.7667H14.4379L13.5145 6.349H15.7273L18.0311 9.7667ZM5.27262 6.34969H7.48542L6.56194 9.76738H2.96791L5.27262 6.34969Z" fill="#92400E"/>
                     <path d="M5 0L5.67523 1.82477L7.5 2.5L5.67523 3.17523L5 5L4.32477 3.17523L2.5 2.5L4.32477 1.82477L5 0Z" fill="#92400E"/>
                     <path d="M2.16667 4L2.61682 5.21652L3.83333 5.66667L2.61682 6.11682L2.16667 7.33333L1.71652 6.11682L0.5 5.66667L1.71652 5.21652L2.16667 4Z" fill="#92400E"/>
                     </g>
                     <defs>
                     <clipPath id="clip0_7731_14413">
                     <rect width="20" height="20" fill="white"/>
                     </clipPath>
                     </defs>
                   </svg>
                   Upgrade to Premium Plus
                 </button>
               </div>
             </div>
             <h5>OR</h5>
             <button class="checkPlagWithCredits" (click)="generateReportOnCreditBased()">Check Plagiarism with credits</button>
           </div>
           <!-- Premium plus User Standard Plagiarism Check -->
           <div class="PPUPC_FirstScreen" *ngIf="plagCheckFileList.length == 0 && creditsDetails['user_type']=='PREMIUM_PLUS'">
             <div class="PPUPC_introScreen">
               <h2 *ngIf="!isScanCalculated && noOfScanRequired == 0">Standard Plagiarism Check</h2>
               <h2 *ngIf="isScanCalculated">Check Plagiarism on this file</h2>
               <ul>
                 <li>
                   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M9.9974 18.9583C5.05573 18.9583 1.03906 14.9417 1.03906 10C1.03906 5.05833 5.05573 1.04166 9.9974 1.04166C14.9391 1.04166 18.9557 5.05833 18.9557 10C18.9557 10.3417 18.6724 10.625 18.3307 10.625C17.9891 10.625 17.7057 10.3417 17.7057 10C17.7057 5.75 14.2474 2.29166 9.9974 2.29166C5.7474 2.29166 2.28906 5.75 2.28906 10C2.28906 14.25 5.7474 17.7083 9.9974 17.7083C10.3391 17.7083 10.6224 17.9917 10.6224 18.3333C10.6224 18.675 10.3391 18.9583 9.9974 18.9583Z" fill="#7A28A0"/>
                     <path d="M7.50313 18.125H6.6698C6.32813 18.125 6.0448 17.8417 6.0448 17.5C6.0448 17.1583 6.31146 16.8833 6.65313 16.875C5.34479 12.4083 5.34479 7.59167 6.65313 3.125C6.31979 3.11667 6.0448 2.84167 6.0448 2.5C6.0448 2.15833 6.32813 1.875 6.6698 1.875H7.50313C7.70313 1.875 7.8948 1.975 8.01146 2.13333C8.12813 2.3 8.16147 2.50833 8.0948 2.69999C6.52813 7.40833 6.52813 12.6 8.0948 17.3083C8.16147 17.5 8.12813 17.7083 8.01146 17.875C7.8948 18.0417 7.70313 18.125 7.50313 18.125Z" fill="#7A28A0"/>
                     <path d="M13.7154 10.625C13.3737 10.625 13.0904 10.3417 13.0904 10C13.0904 7.51669 12.6904 5.05835 11.9071 2.70002C11.7987 2.37502 11.9737 2.01667 12.2987 1.90834C12.6237 1.80001 12.9821 1.97503 13.0904 2.30003C13.9154 4.78336 14.3404 7.37502 14.3404 10C14.3404 10.3417 14.0571 10.625 13.7154 10.625Z" fill="#7A28A0"/>
                     <path d="M10 14.3417C7.66667 14.3417 5.35833 14.0083 3.125 13.35C3.11667 13.6833 2.84167 13.9583 2.5 13.9583C2.15833 13.9583 1.875 13.675 1.875 13.3333V12.5C1.875 12.3 1.975 12.1083 2.13333 11.9917C2.3 11.875 2.50834 11.8416 2.7 11.9083C5.05834 12.6916 7.51667 13.0917 10 13.0917C10.3417 13.0917 10.625 13.375 10.625 13.7167C10.625 14.0583 10.3417 14.3417 10 14.3417Z" fill="#7A28A0"/>
                     <path d="M17.4993 8.12501C17.4326 8.12501 17.3659 8.11669 17.2993 8.09169C12.5909 6.52503 7.39925 6.52503 2.69092 8.09169C2.36592 8.20003 2.00759 8.025 1.89925 7.7C1.79092 7.375 1.96592 7.01666 2.29092 6.90832C7.25759 5.24999 12.7243 5.24999 17.6826 6.90832C18.0076 7.01666 18.1826 7.375 18.0743 7.7C18.0076 7.95833 17.7576 8.12501 17.4993 8.12501Z" fill="#7A28A0"/>
                     <path d="M15.1667 18.4584C13.35 18.4584 11.875 16.9833 11.875 15.1667C11.875 13.35 13.35 11.875 15.1667 11.875C16.9833 11.875 18.4583 13.35 18.4583 15.1667C18.4583 16.9833 16.9833 18.4584 15.1667 18.4584ZM15.1667 13.125C14.0417 13.125 13.125 14.0417 13.125 15.1667C13.125 16.2917 14.0417 17.2084 15.1667 17.2084C16.2917 17.2084 17.2083 16.2917 17.2083 15.1667C17.2083 14.0417 16.2917 13.125 15.1667 13.125Z" fill="#7A28A0"/>
                     <path d="M18.3312 18.9583C18.1729 18.9583 18.0146 18.9 17.8896 18.775L17.0562 17.9417C16.8146 17.7 16.8146 17.3 17.0562 17.0583C17.2979 16.8166 17.6979 16.8166 17.9396 17.0583L18.7729 17.8916C19.0146 18.1333 19.0146 18.5333 18.7729 18.775C18.6479 18.9 18.4896 18.9583 18.3312 18.9583Z" fill="#7A28A0"/>
                   </svg>
                   <span>Covers open-access journals and internet articles</span>
                 </li>
                
                 <li>
                   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M11.0475 3L4.20585 10.2417C3.94752 10.5167 3.69752 11.0583 3.64752 11.4333L3.33918 14.1333C3.23085 15.1083 3.93085 15.775 4.89752 15.6083L7.58085 15.15C7.95585 15.0833 8.48085 14.8083 8.73918 14.525L15.5808 7.28333C16.7642 6.03333 17.2975 4.60833 15.4558 2.86667C13.6225 1.14167 12.2308 1.75 11.0475 3Z" stroke="#7A28A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                     <path d="M9.90625 4.20833C10.2646 6.50833 12.1312 8.26667 14.4479 8.5" stroke="#7A28A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                     <path d="M2.5 18.3333H17.5" stroke="#7A28A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                   </svg>
                   <span>Provides better coverage with regular content updates</span>
                 </li>
                 <li>
                   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M7.5 9.16667V14.1667L9.16667 12.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                     <path d="M7.5026 14.1667L5.83594 12.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                     <path d="M18.3307 8.33333V12.5C18.3307 16.6667 16.6641 18.3333 12.4974 18.3333H7.4974C3.33073 18.3333 1.66406 16.6667 1.66406 12.5V7.5C1.66406 3.33333 3.33073 1.66667 7.4974 1.66667H11.6641" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                     <path d="M18.3307 8.33333H14.9974C12.4974 8.33333 11.6641 7.5 11.6641 5V1.66667L18.3307 8.33333Z" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                   </svg>
                   <span>Get Interactive reports and downloadable PDF</span>
                 </li>
               </ul>

               <div class="notEnoughtPageSection" *ngIf="avaialbleScan<calulatePageCount()">
                 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M10.0001 1.66669C5.40841 1.66669 1.66675 5.40835 1.66675 10C1.66675 14.5917 5.40841 18.3334 10.0001 18.3334C14.5917 18.3334 18.3334 14.5917 18.3334 10C18.3334 5.40835 14.5917 1.66669 10.0001 1.66669ZM9.37508 6.66669C9.37508 6.32502 9.65842 6.04169 10.0001 6.04169C10.3417 6.04169 10.6251 6.32502 10.6251 6.66669V10.8334C10.6251 11.175 10.3417 11.4584 10.0001 11.4584C9.65842 11.4584 9.37508 11.175 9.37508 10.8334V6.66669ZM10.7667 13.65C10.7251 13.7584 10.6667 13.8417 10.5917 13.925C10.5084 14 10.4167 14.0584 10.3167 14.1C10.2167 14.1417 10.1084 14.1667 10.0001 14.1667C9.89175 14.1667 9.78341 14.1417 9.68342 14.1C9.58342 14.0584 9.49175 14 9.40841 13.925C9.33342 13.8417 9.27508 13.7584 9.23342 13.65C9.19175 13.55 9.16675 13.4417 9.16675 13.3334C9.16675 13.225 9.19175 13.1167 9.23342 13.0167C9.27508 12.9167 9.33342 12.825 9.40841 12.7417C9.49175 12.6667 9.58342 12.6084 9.68342 12.5667C9.88342 12.4834 10.1167 12.4834 10.3167 12.5667C10.4167 12.6084 10.5084 12.6667 10.5917 12.7417C10.6667 12.825 10.7251 12.9167 10.7667 13.0167C10.8084 13.1167 10.8334 13.225 10.8334 13.3334C10.8334 13.4417 10.8084 13.55 10.7667 13.65Z" fill="#DC2626"/>
                 </svg>
                 <p>Not enough Pages are available to run this report. You can instead use <strong>credits</strong> to run the report.</p>
               </div>

               <div class="PPUSPC_viewReport" *ngIf="!isScanCalculated">
                 <a (click)="viewSampleReport(3)">View Sample Report</a>
               </div>
               <div class="PPUSPC_RunPlagCheck" *ngIf="!isScanCalculated && noOfScanRequired==0">
                 <button style="width: 290px;" (click)="calculatenoOfScanRequired()" [disabled]="plagFileWordCount === 0 || noTextFound()" *ngIf="avaialbleScan<calulatePageCount()">Check Plagiarism using credits</button>
                 <button (click)="calculatenoOfScanRequired()" [disabled]="!noTextFound()" *ngIf="avaialbleScan>=calulatePageCount()">Check Plagiarism</button>
               </div>

               <div class="PPUSPC_RunPlagCheck" *ngIf="isScanCalculated && noOfScanRequired==0" style="height: 120px;
               display: flex;align-items: center;margin-bottom: -20px; padding-top: 20px;">
                 <button class="calculatingNumberOfPage" style="width: 300px;opacity: 0.7;">
                   <svg class="spinner">
                     <circle style="cx: 50%;cy: 50%; r: 50%;">
                         <animateTransform attributeName="transform" type="rotate" values="-90;810" keyTimes="0;1" dur="2s" repeatCount="indefinite"></animateTransform>
                         <animate attributeName="stroke-dashoffset" values="0%;0%;-157.080%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
                         <animate attributeName="stroke-dasharray" values="0% 314.159%;157.080% 157.080%;0% 314.159%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
                     </circle>
                     </svg>
                     Calculating number of pages
                 </button>
               </div>

               <div class="PPUSPC_viewReport" *ngIf="isScanCalculated && noOfScanRequired > 0">
                 <p><span>{{noOfScanRequired}} Page<b style="font-weight: normal;" *ngIf="noOfScanRequired > 1">s</b></span> will be used</p>
                 <div class="pf-info-circle-icon">
                   <div class="pf-info-popup">
                     <p class="pf-info-popup-heading">For Standard Plagiarism</p>
                     <p class="pf-info-popup-fixed-msg">1 Page will be used per 250 words</p>
                     <div class="pf-info-popup-credit-div">
                       <p class="pf-info-popup-credit-details"><span>Your file has approximately</span> {{plagFileWordCount}} words so maximum {{calulatePageCount()}} Page<b style="font-weight: 600;" *ngIf="calulatePageCount() > 1">s</b> will be used.</p>
                     </div>
                   </div>
                 </div>
               </div>

               <div class="PPUSPC_RunPlagCheck" *ngIf="isScanCalculated && plagFileWordCount !== 0 && noOfScanRequired > 0">
                 <button [disabled]="isGeneratingPlagCheckReport" (click)="process_plagiarism_check(3)">Proceed</button>
               </div>

               <div class="onePageWrodCount">
                 <!-- <p>1 Page = 250 words</p> -->
                 <p><span>Note:</span> Plagiarism will run on the current file. Kindly ensure that all the necessary corrections are done before you proceed.</p>
               </div>
             </div>
           </div>

            <!-- Premium plus User generate report -->
           <div class="PPUPC_FourScreen" *ngIf="plagCheckFileList.length > 0">
             <div class="PPUPC_introScreen" *ngIf="plagCheckFileList[0].file_status=='IN_PROGRESS'">
               <div class="spinnerOuterSection">
                 <svg class="spinner">
                   <circle style="cx: 50%;cy: 50%; r: 50%;">
                     <animateTransform attributeName="transform" type="rotate" values="-90;810" keyTimes="0;1" dur="2s" repeatCount="indefinite"></animateTransform>
                     <animate attributeName="stroke-dashoffset" values="0%;0%;-157.080%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
                     <animate attributeName="stroke-dasharray" values="0% 314.159%;157.080% 157.080%;0% 314.159%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
                   </circle>
                 </svg>
               </div>
               <p style="font-weight: 600;" *ngIf="plagCheckFileList[0].plagiarism_check_type==3">Generating Standard Plagiarism Report ...</p>
               <p style="font-weight: 600;" *ngIf="plagCheckFileList[0].plagiarism_check_type==2">Generating Advanced Plagiarism Report ...</p>
             </div>
           </div>

            <!-- Premium plus User  report check -->
           <div class="PPUPC_FiveScreen" *ngIf="plagCheckFileList.length > 0">
             <div class="PPUPC_introScreen" *ngIf="plagCheckFileList[0].file_status.file_status=='SUCCESS'">
               <div class="processingCompleted">
                 <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
               </div>
               <p>Check is complete...</p>
             </div>
           </div>


           <ng-container *ngIf="creditsDetails['user_type']=='PREMIUM_PLUS' && plagCheckFileList.length > 0">
             <div class="PPUPC_SixScreen" *ngFor="let file of plagCheckFileList| slice: 0:1; let i = index">
               <h5 *ngIf="checkLastGeneratedReport()">Last Generated Report</h5>
               <div class="reportHistorySection">
                 <div class="PPUPC_introScreen" *ngIf="file.file_status=='SUCCESS' ">
                   <div class="viewReportHeader">
                     <div class="viewReportHeaderLeftSide">
                       <h3 *ngIf="file.plagiarism_check_type==3">Standard Plagiarism Report</h3>
                       <h3 *ngIf="file.plagiarism_check_type==2">Advanced Plagiarism Report</h3>
                       <span>{{file.uploaded_at | date:'d/MMM/y, h:mm a'}}</span>

                       <span style="padding-left: 6px;margin-left: 8px;border-left: solid 2px #DDE2E9;">
                         {{file.used_page_count}} Page<span *ngIf="file.used_page_count > 0">s</span> used
                       </span>
                     </div>
                     <div class="viewReportHeaderRightSide">
                       <a (click)="view_plag_check_report(file)">View Report</a>
                     </div>
                   </div>

                   <div class="SeriousPlagiarism">
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M14.5067 10.6135L10.24 2.9335C9.66665 1.90016 8.87332 1.3335 7.99998 1.3335C7.12665 1.3335 6.33332 1.90016 5.75998 2.9335L1.49332 10.6135C0.953318 11.5935 0.893318 12.5335 1.32665 13.2735C1.75999 14.0135 2.61332 14.4202 3.73332 14.4202H12.2667C13.3867 14.4202 14.24 14.0135 14.6733 13.2735C15.1067 12.5335 15.0467 11.5868 14.5067 10.6135ZM7.49998 6.00016C7.49998 5.72683 7.72665 5.50016 7.99998 5.50016C8.27332 5.50016 8.49998 5.72683 8.49998 6.00016V9.3335C8.49998 9.60683 8.27332 9.8335 7.99998 9.8335C7.72665 9.8335 7.49998 9.60683 7.49998 9.3335V6.00016ZM8.47332 11.8068C8.43998 11.8335 8.40665 11.8602 8.37332 11.8868C8.33332 11.9135 8.29332 11.9335 8.25332 11.9468C8.21332 11.9668 8.17332 11.9802 8.12665 11.9868C8.08665 11.9935 8.03998 12.0002 7.99998 12.0002C7.95998 12.0002 7.91332 11.9935 7.86665 11.9868C7.82665 11.9802 7.78665 11.9668 7.74665 11.9468C7.70665 11.9335 7.66665 11.9135 7.62665 11.8868C7.59332 11.8602 7.55998 11.8335 7.52665 11.8068C7.40665 11.6802 7.33332 11.5068 7.33332 11.3335C7.33332 11.1602 7.40665 10.9868 7.52665 10.8602C7.55998 10.8335 7.59332 10.8068 7.62665 10.7802C7.66665 10.7535 7.70665 10.7335 7.74665 10.7202C7.78665 10.7002 7.82665 10.6868 7.86665 10.6802C7.95332 10.6602 8.04665 10.6602 8.12665 10.6802C8.17332 10.6868 8.21332 10.7002 8.25332 10.7202C8.29332 10.7335 8.33332 10.7535 8.37332 10.7802C8.40665 10.8068 8.43998 10.8335 8.47332 10.8602C8.59332 10.9868 8.66665 11.1602 8.66665 11.3335C8.66665 11.5068 8.59332 11.6802 8.47332 11.8068Z" fill="#EF4444"/>
                     </svg>
                     <p *ngIf="file.final_report.overall_match_percentage > 0">Plagiarism Issues detected</p>
                     <p *ngIf="file.final_report.overall_match_percentage == 0">No plagiarism issue detected</p>
                   </div>

                   <div class="viewReportBodySection">
                     <div class="viewReportBodyLeftSection">
                       <div class="wordMatching">
                         <svg viewBox="0 0 36 36" [ngClass]="file.final_report.overall_match_percentage > 0 ? 'circular-chart red': 'circular-chart green'">
                           <path class="circle-bg"
                             d="M18 2.0845
                               a 15.9155 15.9155 0 0 1 0 31.831
                               a 15.9155 15.9155 0 0 1 0 -31.831"
                           />
                           <path class="circle"
                           [attr.stroke-dasharray]="file.final_report.overall_match_percentage+',100'"
                             d="M18 2.0845
                               a 15.9155 15.9155 0 0 1 0 31.831
                               a 15.9155 15.9155 0 0 1 0 -31.831"
                           />
                           <text [ngClass]="file.final_report.overall_match_percentage > 0 ? 'graph_red': 'graph_green'" x="11" y="19.35" class="percentage">{{file.final_report.overall_match_percentage}}%</text>
                           <text x="9" y="25" class="SimilarityText">Similarity</text>
                         </svg>
                       </div>
                     </div>
                     <div class="viewReportBodyRightSection">
                       <ul>
                         <li>
                           <p>{{file.final_report.internet_match_percentage}}%</p>
                           <span>Internet match</span>
                         </li>
                         <li *ngIf="file.plagiarism_check_type==3">
                           <p>{{file.final_report.top_source_largest_matched_word_count}}</p>
                           <span>Words match</span>
                         </li>

                         <li *ngIf="file.plagiarism_check_type==2">
                           <p>{{file.final_report.publication_match_percentage}} %</p>
                           <span>Paid sources match</span>
                         </li>
                       </ul>
                     </div>
                   </div>

                   <div class="topVeiwSourcesSection" >
                     <h4>Top {{file.final_report.top_matches.length >3 ? 3 : file.final_report.top_matches.length}} sources</h4>
                     <div class="sourceTable">
                       <table class="table">
                         <tr>
                           <th>Match%</th>
                           <th>Sources</th>
                         </tr>
                         <tr *ngFor="let matches of file.final_report.top_matches | slice: 0:3">
                           <td>{{matches.percentage | number : '1.2-2'}}%</td>
                           <td>
                             <p>{{matches.name}}</p>
                           </td>
                         </tr>
                       </table>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </ng-container>

           <!-- Premium plus User report history -->
           <ng-container *ngIf="creditsDetails['user_type']=='PREMIUM_PLUS' && plagCheckFileList.length > 1">
             <h5 class="reportHistoryHeading" *ngIf="checkReportHistory()" (click)="reportHistoryTab = !reportHistoryTab" [ngClass]="reportHistoryTab? 'isReportHistoryTabOpen' : '' ">
               Report History
               <svg class="report_history_show" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L7 2.41421L1.70711 7.70711C1.31658 8.09763 0.683417 8.09763 0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893Z" fill="#414E62"/>
               </svg>
             </h5>
             <div class="PPUPC_SixScreen" *ngFor="let file of plagCheckFileList | slice: 1; let i = index"
             [ngClass]="reportHistoryTab? 'isReportHistoryTabOpen' : ''  ">

               <div class="reportHistorySection"  *ngIf="reportHistoryTab">
                 <div class="PPUPC_introScreen" *ngIf="file.file_status=='SUCCESS' ">
                   <div class="viewReportHeader">
                     <div class="viewReportHeaderLeftSide">
                       <h3 *ngIf="file.plagiarism_check_type==3">Standard Plagiarism Report</h3>
                       <h3 *ngIf="file.plagiarism_check_type==2">Advanced Plagiarism Report</h3>
                       <span>{{file.uploaded_at | date:'d/MMM/y, h:mm a'}}</span>

                       <span style="padding-left: 6px;margin-left: 8px;border-left: solid 2px #DDE2E9;">
                         {{file.used_page_count}} Page<span *ngIf="file.used_page_count > 1">s</span> used
                       </span>
                     </div>
                     <div class="viewReportHeaderRightSide">
                       <a (click)="view_plag_check_report(file)">View Report</a>
                     </div>
                   </div>

                   <div class="SeriousPlagiarism">
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M14.5067 10.6135L10.24 2.9335C9.66665 1.90016 8.87332 1.3335 7.99998 1.3335C7.12665 1.3335 6.33332 1.90016 5.75998 2.9335L1.49332 10.6135C0.953318 11.5935 0.893318 12.5335 1.32665 13.2735C1.75999 14.0135 2.61332 14.4202 3.73332 14.4202H12.2667C13.3867 14.4202 14.24 14.0135 14.6733 13.2735C15.1067 12.5335 15.0467 11.5868 14.5067 10.6135ZM7.49998 6.00016C7.49998 5.72683 7.72665 5.50016 7.99998 5.50016C8.27332 5.50016 8.49998 5.72683 8.49998 6.00016V9.3335C8.49998 9.60683 8.27332 9.8335 7.99998 9.8335C7.72665 9.8335 7.49998 9.60683 7.49998 9.3335V6.00016ZM8.47332 11.8068C8.43998 11.8335 8.40665 11.8602 8.37332 11.8868C8.33332 11.9135 8.29332 11.9335 8.25332 11.9468C8.21332 11.9668 8.17332 11.9802 8.12665 11.9868C8.08665 11.9935 8.03998 12.0002 7.99998 12.0002C7.95998 12.0002 7.91332 11.9935 7.86665 11.9868C7.82665 11.9802 7.78665 11.9668 7.74665 11.9468C7.70665 11.9335 7.66665 11.9135 7.62665 11.8868C7.59332 11.8602 7.55998 11.8335 7.52665 11.8068C7.40665 11.6802 7.33332 11.5068 7.33332 11.3335C7.33332 11.1602 7.40665 10.9868 7.52665 10.8602C7.55998 10.8335 7.59332 10.8068 7.62665 10.7802C7.66665 10.7535 7.70665 10.7335 7.74665 10.7202C7.78665 10.7002 7.82665 10.6868 7.86665 10.6802C7.95332 10.6602 8.04665 10.6602 8.12665 10.6802C8.17332 10.6868 8.21332 10.7002 8.25332 10.7202C8.29332 10.7335 8.33332 10.7535 8.37332 10.7802C8.40665 10.8068 8.43998 10.8335 8.47332 10.8602C8.59332 10.9868 8.66665 11.1602 8.66665 11.3335C8.66665 11.5068 8.59332 11.6802 8.47332 11.8068Z" fill="#EF4444"/>
                     </svg>
                     <p *ngIf="file.final_report.overall_match_percentage > 0">Plagiarism Issues detected</p>
                     <p *ngIf="file.final_report.overall_match_percentage == 0">No plagiarism issue detected</p>
                   </div>

                   <div class="viewReportBodySection">
                     <div class="viewReportBodyLeftSection">
                       <div class="wordMatching">
                         <svg viewBox="0 0 36 36" [ngClass]="file.final_report.overall_match_percentage > 0 ? 'circular-chart red': 'circular-chart green'">
                           <path class="circle-bg"
                             d="M18 2.0845
                               a 15.9155 15.9155 0 0 1 0 31.831
                               a 15.9155 15.9155 0 0 1 0 -31.831"
                           />
                           <path class="circle"
                           [attr.stroke-dasharray]="file.final_report.overall_match_percentage+',100'"
                             d="M18 2.0845
                               a 15.9155 15.9155 0 0 1 0 31.831
                               a 15.9155 15.9155 0 0 1 0 -31.831"
                           />
                           <text [ngClass]="file.final_report.overall_match_percentage > 0 ? 'graph_red': 'graph_green'" x="11" y="19.35" class="percentage">{{file.final_report.overall_match_percentage}}%</text>
                           <text x="9" y="25" class="SimilarityText">Similarity</text>
                         </svg>
                       </div>
                     </div>
                     <div class="viewReportBodyRightSection">
                       <ul>
                         <li>
                           <p>{{file.final_report.internet_match_percentage}}%</p>
                           <span>Internet match</span>
                         </li>
                         <li *ngIf="file.plagiarism_check_type==3">
                           <p>{{file.final_report.top_source_largest_matched_word_count}}</p>
                           <span>Words match</span>
                         </li>

                         <li *ngIf="file.plagiarism_check_type==2">
                           <p>{{file.final_report.publication_match_percentage}} %</p>
                           <span>Paid sources match</span>
                         </li>
                       </ul>
                     </div>
                   </div>

                   <div class="topVeiwSourcesSection" >
                     <h4>Top {{file.final_report.top_matches.length >3 ? 3 : file.final_report.top_matches.length}} sources</h4>
                     <div class="sourceTable">
                       <table class="table">
                         <tr>
                           <th>Match%</th>
                           <th>Sources</th>
                         </tr>
                         <tr *ngFor="let matches of file.final_report.top_matches | slice: 0:3">
                           <td>{{matches.percentage | number : '1.2-2'}}%</td>
                           <td>
                             <p>{{matches.name}}</p>
                           </td>
                         </tr>
                       </table>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </ng-container>

         </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>