import {
  Directive,
  Output,
  Input,
  EventEmitter,
  HostBinding,
  HostListener,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Directive({
  selector: "[appDnd]",
})
export class DndDirective {
  @HostBinding('class.fileover') get fileover() { return this.fileOver; }
  @Output() fileDropped = new EventEmitter<any>();
  // @Output() isAllowedType = new EventEmitter<any>();
  allowedFileExtensions: string[] = ["txt", "pdf", "docx","md", "doc", 'tex'];

  fileOver: boolean = false;
  constructor(private toastr: ToastrService){ }

  // Dragover listener
  @HostListener("window:dragover", ["$event"]) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener("window:drop", ["$event"]) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;

    if (files.length > 0) {
      // console.log(files[0]["type"]);
      let fileName: string = files[0]["name"];
      let extension = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
      let isAllowed = false;
      if(this.allowedFileExtensions.includes(extension)){
        isAllowed = true;
      }
      if(isAllowed){
        this.fileDropped.emit(files);
      }
      else{
        this.toastr.error("This File Type is Not Allowed", "Error");
      }
      // this.isAllowedType.emit(isAllowed);
    }
  }
  
}
