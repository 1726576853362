import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-plagiarism-help-to-choose-report-modal',
  templateUrl: './plagiarism-help-to-choose-report-modal.component.html',
  styleUrls: ['./plagiarism-help-to-choose-report-modal.component.scss']
})
export class PlagiarismHelpToChooseReportModalComponent implements OnInit {

  credits:number;
  free_score : number;
  selectedPlagCheckType: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data,
  private matDialogRef : MatDialogRef<PlagiarismHelpToChooseReportModalComponent>) {
    this.credits = data.credits;
    this.free_score = data.free_score;
    this.selectedPlagCheckType = data.selectedPlagCheckType;
   }

  ngOnInit() {
    this.matDialogRef.backdropClick().subscribe(v=>{
      this.matDialogRef.close(this.selectedPlagCheckType);
  });
  }

  setplagcheckType(plag_type){
    this.selectedPlagCheckType= plag_type;
  }

  close(){
    this.matDialogRef.close(this.selectedPlagCheckType);
  }
}
