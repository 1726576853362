<mat-expansion-panel class="alertCardWrap"
[ngClass]="(item.suggestions[0].error_category == 'Syntax & Vocabulary-Syntax' )? 'advisorCardSection' : ''
|| (item.suggestions[0].error_category == 'Enhancement')? 'advisorCardSection enhancementAlertCard' : ''
|| (item.suggestions[0].error_category == 'Writing Advisor' )? 'advisorCardSection' : ''
|| (item.suggestions[0].error_category == 'Syntax & Vocabulary-Others' )? 'advisorCardSection' : ''
|| (item.suggestions[0].error_category == 'Complexity' )? 'ComplexityAlertCard' : ''
|| (item.suggestions[0].error_category == 'Syntax & Vocabulary-Fragment' )? 'syntexVocabularyFragment' : ''
|| (item.suggestions[0].error_category == 'Grammar-Pronouns' )? 'syntexVocabularyFragment' : ''
|| (item.suggestions[0].error_category == 'Grammar-Verbs (except SVA)' )? 'syntexVocabularyFragment' : ''
|| (item.suggestions[0].error_category == 'Grammar-Word form' )? 'syntexVocabularyFragment' : ''
|| (item.suggestions[0].error_category == 'Grammar-Grammar casing' )? 'syntexVocabularyFragment' : ''
|| (item.suggestions[0].error_category == 'Grammar-Singular/Plural noun' )? 'GrammarSingularPluralNoun' : '' "
 (opened)="openCard(item,'open')" (closed)="closeCard(item,'close')" [expanded]="(item.isExpanded)">

  <mat-expansion-panel-header>
    <mat-panel-title>
      <!-- *ngIf="!item.isExpanded" -->
      <div class="isExpandedHeader">
        <div class="headerSuggestionSection" *ngFor="let suggestion of item.suggestions; let k = index">
          <!-- Non CTA  -->
          <div class="wrongTextContent nonCTAalerts" *ngIf="(suggestion.suggestion == '' && !suggestion.cta_present)">
            <!-- class="strong_text" -->
            <strong class="NON_CTA_WritingAdvisorHeader" *ngIf="k == 0">{{item.text}}</strong>
            <p class="nonCTAdescriptionText">{{item.suggestions[0].comment}}</p>
          </div>
          <!-- Non CTA end -->
          <div  class="wrongTextContent wrongRightContentSection" *ngIf="(suggestion.suggestion == '' && suggestion.cta_present)">
            <!-- class="strong_text" -->
            <strong [style.visibility]="k==0? 'visible': 'hidden'">
              <span *ngIf="suggestion.suggestion == '' && suggestion.cta_present" >{{item.text}}</span>
            </strong>
            <div class="arrowWithTextContent">
              <span class="arrow">
                <img src="assets/images/arrow.png" alt="">
              </span>
              <!-- class="error-link" -->
              <label [ngClass]="item.alert_class"
                (click)="acceptCard(item, 'accept',suggestion, k,false);$event.cancelBubble=true;">
                <span style="text-decoration: line-through;">{{item.text}}</span>
              </label>
            </div>
          </div>
          <!-- Has CTA -->
          <div *ngIf="suggestion.suggestion != '' && suggestion.cta_present">
            <span [ngClass]="item.alert_class"></span>
            <div class="wrongTextContent" [style.visibility]="k==0? 'visible': 'hidden'" [ngClass]="(item.text.length >= 14)? 'noneSectionContent' : 'blockSectionContent' ">
              <span class="headerWrongTextContent" [style.min-width]="(item.text.length >= 14 && k==0)? '150px' : 'auto' " *ngIf="suggestion.suggestion != '' && suggestion.cta_present" >{{item.text}}</span>
              <span class="headerWrongTextContent" [style.min-width]="(item.text.length >= 14 && k==0)? '150px' : 'auto' " *ngIf="suggestion.suggestion == '' && suggestion.cta_present" >{{item.text}}</span>
            </div>

            <div class="arrowWithTextContent" [style.min-width]="(item.text.length >= 15 && k==0)? '155px' : '172px' ">
              <span class="arrow">
                <img src="assets/images/arrow.png" alt="">
              </span>
              <label [ngClass]="item.alert_class"
                (click)="acceptCard(item, 'accept',suggestion, k,false);$event.cancelBubble=true;" >{{suggestion.suggestion}}
              </label>
            </div>
          </div>
          <!-- Has CTA end -->
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <!-- class="error-main cards_body" -->
  <div class="cardBodySection" [ngClass]="(suggestion.type == 2 && sharedService.featurePermission.data.add_to_dictionary)? 'spellingButtonCard' : '' "
   *ngFor="let suggestion of item.suggestions; let k = index">
    <!-- <div class="margin_bottoms"> -->
      <!-- class="error-comment" -->
      <div class="bodyTextContentSection" *ngIf="k == item.suggestions.length-1">
        <!-- class="ErrorCommentText" -->
        <span class="">{{suggestion.comment}}</span>
        <!-- class="dictionary_sections noncta_buttons" -->
        <div *ngIf="!suggestion.cta_present">
          <button class="thisWasHelpfulbutton" [ngClass]="item.alert_class" (click)="acceptNonCta(item,'accept_noncta',suggestion, k, j)"
            *ngIf="k == 0">Okay, this was helpful.
          </button>
        </div>
        <!-- class="dictionary_sections" -->
        <div class="dictionaryAcceptAllButton">
            <div
            *ngIf="suggestion.type == 2 && sharedService.featurePermission.data.add_to_dictionary">
            <button [ngClass]="item.alert_class" class="AddMyDictionary"
              (click)="muteCard(item,'mute',suggestion, k, j)" *ngIf="k == 0">Add to My Dictionary
            </button>
            <!-- class="error-link add_dictionary_button" -->
          </div>
          <!-- class="acceptForAllSection" -->
          <div >
            <!-- class="acceptAll_alert_button" -->
            <button class="acceptForAllButton"
              title="This action will apply this change to all instances with the same context."
              *ngIf="hasSimilarCardToAccept(item, suggestion)" (click)="acceptCard(item,'accept',suggestion,k,true)">
              Accept for all
            </button>
            <!-- <span class="alert_info_span" *ngIf="hasSimilarCardToAccept(item, suggestion)" alt="This action will apply this change to all instances with the same context.">
              <img src="../../../../assets/images/Icon_Info.svg" alt="">
            </span> -->
          </div>
        </div>

        <!-- <div class="cardFooterSection">
          <span *ngIf="item.suggestions[0].type == 1">GRAMMAR: {{suggestion.error_category}}</span>
          <span *ngIf="item.suggestions[0].type == 2">SPELLING: {{suggestion.error_category}}</span>
          <span *ngIf="item.suggestions[0].type == 3">WRITING ADVISOR: {{suggestion.error_category}}</span>
          <span *ngIf="item.suggestions[0].type == 4">ENHANCEMENT: {{suggestion.error_category}}</span>
          <span *ngIf="item.suggestions[0].type == 5">{{suggestion.error_category}}: {{styleGuideDispay[styleGuide]| uppercase}} STYLE</span>
        </div> -->

        <div class="cardFooterSection">
          <span *ngIf="item.suggestions[0].type == 1">GRAMMAR</span>
          <span *ngIf="item.suggestions[0].type == 2">SPELLING</span>
          <span *ngIf="item.suggestions[0].type == 3">WRITING ADVISOR</span>
          <span *ngIf="item.suggestions[0].type == 4">ENHANCEMENT</span>
          <!-- <span *ngIf="item.suggestions[0].type == 5">{{suggestion.error_category}}: {{styleGuideDispay[styleGuide] | uppercase}} STYLE</span> -->
          <span *ngIf="item.suggestions[0].type == 5">STYLE GUIDE: {{styleGuideDispay[styleGuide] | uppercase}}</span>
          <span *ngIf="item.suggestions[0].type == 10" style="display: flex;">
            <span style="width: 160px;">INCLUSIVE LANGUAGE: </span>
           <!--
            Legal writing sub category color code
            [ngStyle]="{'color': getColor(suggestion.error_category)}"
            -->
            <span class="iLGender-codedWords" *ngIf="suggestion.error_category">{{ suggestion.error_category | uppercase }}</span>
          </span>
          <span *ngIf="item.suggestions[0].type == 11">LEGAL WRITING</span>
        </div>

      </div>
    <!-- </div> -->
    <!-- class="Optional_buttons" -->
    <div class="bodyTextOptionButtons" *ngIf="k == item.suggestions.length-1">
      <mat-panel-description>
        <span (click)="ignoreCard(item, 'ignore', j,false)" title="Delete" style="display: none;">
          <img src="../../../../assets/images/delete_icon.svg" alt="">
        </span>
      </mat-panel-description>
      <span class="thumbsDown" (click)="rejectSuggestions(item,'reject',j)" title="Reject">
        <!-- <img src="../../../../assets/images/dislike_icon.svg" alt=""> -->
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.3 8.26669C1.32248 8.73481 1.48877 9.18461 1.77619 9.55479C1.60192 9.93961 1.55414 10.3698 1.6397 10.7835C1.72526 11.1971 1.93976 11.5731 2.25238 11.8572C2.84286 12.4 3.85714 12.6429 5.25714 12.5715C5.87696 12.5432 6.49336 12.4636 7.1 12.3334V12.3334C7.29048 12.3 7.49048 12.2596 7.69286 12.2143C7.70714 12.4524 7.66667 13.05 7.21667 14.3905C6.69286 15.9834 6.72619 17.2048 7.31428 18.0215C7.53903 18.3119 7.82791 18.5464 8.15835 18.7067C8.48878 18.867 8.85181 18.9486 9.21905 18.9453C9.35503 18.9455 9.48941 18.916 9.61279 18.8588C9.73617 18.8016 9.84557 18.7182 9.93333 18.6143C10.3452 18.1381 10.2952 17.2405 10.2476 16.831C10.7667 15.5096 12.1429 12.2596 13.3333 11.3572C13.3548 11.3417 13.3748 11.3241 13.3929 11.3048C13.6971 10.9817 13.945 10.6099 14.1262 10.2048C14.3538 10.3295 14.6095 10.3943 14.869 10.3929H17.1429C17.5556 10.3923 17.9513 10.228 18.2432 9.93611C18.5351 9.64423 18.6994 9.24853 18.7 8.83574V2.7524C18.7003 2.5476 18.6603 2.34475 18.5822 2.15542C18.5041 1.96609 18.3895 1.79399 18.2449 1.64896C18.1003 1.50392 17.9286 1.38878 17.7395 1.31012C17.5504 1.23146 17.3477 1.19081 17.1429 1.1905H14.8619C14.5395 1.18981 14.2249 1.28969 13.9619 1.47621L13.0952 1.37621C11.4366 1.17319 9.76615 1.08171 8.09524 1.1024C7.09681 0.99801 6.0887 1.03327 5.1 1.20717C3.95476 1.44526 3.17381 1.93574 2.77381 2.65478C2.53481 3.09025 2.44531 3.59212 2.51905 4.08336C2.23578 4.31976 2.01133 4.61875 1.86341 4.95675C1.71548 5.29474 1.64813 5.66249 1.66667 6.03098C1.68237 6.28728 1.7353 6.53993 1.82381 6.78098C1.477 7.19755 1.29114 7.72472 1.3 8.26669V8.26669Z"
            stroke="#7A7A7A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14 1.5V10.2405" stroke="#7A7A7A" stroke-width="1.3" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </span>

      <span class="ignore_allButton" *ngIf="hasSimilarCardToIgnore(item)" style="margin-top: 20px;"
        (click)="ignoreCard(item,'ignore',j,true)" title="Ignore for all similar alerts">
        <!-- <img src="../../../../assets/images/IgnoreAll_Icons.svg" alt=""> -->
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.37834 9.52529V20.3867C7.37834 21.0285 7.60548 21.6312 8.00148 22.0641C8.39577 22.4979 8.94519 22.7441 9.52034 22.745H17.562C18.1372 22.7441 18.6866 22.4979 19.0809 22.0641C19.4778 21.6312 19.704 21.0285 19.704 20.3867V9.52529C20.4918 9.3084 21.0026 8.51817 20.8972 7.67994C20.7918 6.84082 20.1026 6.21415 19.2875 6.21415H17.1112V5.66303C17.1138 5.19992 16.9372 4.75458 16.6209 4.42746C16.3046 4.10035 15.8752 3.91813 15.4286 3.92168H11.6538C11.2072 3.91813 10.7778 4.10035 10.4615 4.42746C10.1452 4.75458 9.96862 5.19992 9.97119 5.66303V6.21415H7.79491C6.97977 6.21415 6.29063 6.84171 6.1852 7.67994C6.07977 8.51906 6.58977 9.3084 7.37834 9.52529ZM17.2526 6.21415H9.82976H17.2526Z"
            stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M4.12285 6.85879V17.7202C4.12285 18.362 4.34999 18.9647 4.74599 19.3976C5.14027 19.8314 5.6897 20.0776 6.26484 20.0785H14.3065C14.8817 20.0776 15.4311 19.8314 15.8254 19.3976C16.2223 18.9647 16.4485 18.362 16.4485 17.7202V6.85879C17.2363 6.6419 17.7471 5.85167 17.6417 5.01344C17.5363 4.17432 16.8471 3.54765 16.032 3.54765H13.8557V2.99653C13.8583 2.53341 13.6817 2.08808 13.3654 1.76096C13.0491 1.43385 12.6197 1.25162 12.1731 1.25518H8.39827C7.9517 1.25162 7.52227 1.43385 7.20598 1.76096C6.8897 2.08808 6.71313 2.53341 6.7157 2.99653V3.54765H4.53942C3.72427 3.54765 3.03513 4.17521 2.9297 5.01344C2.82428 5.85256 3.33428 6.6419 4.12285 6.85879ZM13.9971 3.54765H6.57427H13.9971Z"
            fill="white" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M8.01855 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12.5528 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
    </div>
  </div>

</mat-expansion-panel>
