import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import * as Sentry from "@sentry/browser" 

// var dsnSentry = "https://cabf033dddceac39f6d48783b72d71ab@o4505839832596480.ingest.sentry.io/4505924174217216"
// if (environment.production) {
//   enableProdMode();
//   dsnSentry = "https://34398a8ee96b5df285eba5d49fdbd2a8@o4505839832596480.ingest.sentry.io/4505923579740160"
// }

// Sentry.init({
//   dsn: dsnSentry,
//   environment: environment.production? "Production": "Development",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/cloud\.trinka.ai\.io\/api/],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
