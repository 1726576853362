import { Component, OnInit } from '@angular/core';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { FigureCheck } from 'src/app/_interfaces/author-one/figure-check';
import { TableCheck } from 'src/app/_interfaces/author-one/table-check'
import { MatDialogConfig, MatDialog } from '@angular/material';
import { FigureTableDialogComponent } from '../figure-table-check/figure-table-dialog/figure-table-dialog.component'
import { Caption } from 'src/app/_interfaces/author-one/technical-check';


@Component({
  selector: 'app-figure-table-check',
  templateUrl: './figure-table-check.component.html',
  styleUrls: ['./figure-table-check.component.scss']
})
export class FigureTableCheckComponent implements OnInit {

  // figuresAndTables: FigureTableCheck;
  figuresCheck: FigureCheck;
  allCitationMissingFigure: boolean = false
  figuresNumberWithoutCaption = []
  figuresNumberWithoutCitation = []
  figuresWithCaptionCount = 0
  allFigures = [];

  tableCheck: TableCheck;
  allCitationMissingTable: boolean = false
  tablesNumberWithoutCaption = []
  tablesNumberWithoutCitation = []
  tablesWithCaptionCount = 0
  allTables = [];

  constructor(public dialog: MatDialog, private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService) {
    this.getReportData();
  }

  ngOnInit() {
  }

  getReportData() {
    this.subjectiveBehaviorSharedService.getA1AllCheckReportData.subscribe(resp => {
      // this.figuresAndTables = resp.figures_tables;
      this.figuresCheck = resp['technical_compliance']['figures']
      this.figuresCheck.status = resp['technical_compliance']['status']

      this.tableCheck = resp['technical_compliance']['tables']
      this.tableCheck.status = resp['technical_compliance']['status']

      if (this.figuresCheck.is_present) {
        if (typeof (this.figuresCheck.unordered_citations) != "undefined") {
          this.allCitationMissingFigure = false
        } else {
          this.allCitationMissingFigure = true
        }
      }

      if (this.tableCheck.is_present) {

        if (typeof (this.tableCheck.unordered_citations) != "undefined") {
          this.allCitationMissingTable = false
        } else {
          this.allCitationMissingTable = true
        }
      }
      this.figuresWithCaptionCount = this.processDataForCaptionAndCitation(this.figuresCheck.individual_figure, this.allFigures, this.figuresNumberWithoutCaption, this.figuresNumberWithoutCitation);
      this.tablesWithCaptionCount = this.processDataForCaptionAndCitation(this.tableCheck.individual_table, this.allTables, this.tablesNumberWithoutCaption, this.tablesNumberWithoutCitation);
    });
  }

  private processDataForCaptionAndCitation(individualElements, allElements, elementNumberWithoutCaption, elementNumberWithoutCitation) {
    if (!individualElements) {
      return 0;
    }
    let allElementsInRequest: Map<number, Caption> = new Map();
    let elementsWithCaptionCount = 0;

    let maxCaption = 0;
    for (let index = 0; index < individualElements.length; index++) {
      const element = individualElements[index];
      if (element.caption && element.caption.caption_number) {
        const number = element.caption.caption_number
        allElementsInRequest.set(number, element.caption)
        maxCaption = Math.max(maxCaption, number)
      }
    }

    for (let index = 1; index <= maxCaption; index++) {
      if (allElementsInRequest.has(index)) {
        const element: Caption = allElementsInRequest.get(index);
        if (element.is_present) {
          elementsWithCaptionCount++
        } else {
          elementNumberWithoutCaption.push(element.caption_number);
        }
        if (element.citation_list.length == 0) {
          elementNumberWithoutCitation.push(element.caption_number);
        }
        allElements.push(element)
      } else {
        elementNumberWithoutCaption.push(index);
        elementNumberWithoutCitation.push(index);
        allElements.push({
          "caption_number": index,
          "citation_list": [],
          "is_premium": true,
          "is_present": false
        })
      }
    }
    return elementsWithCaptionCount;
  }

  viewdata(section) {
    var individual = {}
    if (section == 'Table') {
      individual = { 'section': section, 'individual': this.allTables }
    } else {
      individual = { 'section': section, 'individual': this.allFigures }
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      individualData: individual,
    };
    this.dialog.open(FigureTableDialogComponent, dialogConfig);
  }

}
