import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-internet-connectivity',
  templateUrl: './editor-internet-connectivity.component.html',
  styleUrls: ['./editor-internet-connectivity.component.scss']
})
export class EditorInternetConnectivityComponent implements OnInit {

  //@Input('mainToEditorConnectivity') IS_CONNECTED: boolean;

  constructor() { 
  }

  ngOnInit() {
  }

}
