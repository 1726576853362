import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-plag-check-sample-report-popup',
  templateUrl: './plag-check-sample-report-popup.component.html',
  styleUrls: ['./plag-check-sample-report-popup.component.scss']
})
export class PlagCheckSampleReportPopupComponent implements OnInit {

  isIthenticate: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialog: MatDialogRef<PlagCheckSampleReportPopupComponent>) {
      this.isIthenticate = data.isIthenticate;
   }

  ngOnInit() {
  }

  close(){
    this.dialog.close();
  }

}
