import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-editor-alerts',
  templateUrl: './editor-alerts.component.html',
  styleUrls: ['./editor-alerts.component.scss']
})
export class EditorAlertsComponent implements OnInit {

  card_item : any;
  suggestion_type : any;
  @Input('lang') langCode : any;
  @Input('index') j : any;
  @Input('alertListFromMain') item: any;
  @Input('allAlertList') allAlertList: any;
  @Output() outputAlertsFromChild= new EventEmitter<{alert: any, flag: any, parentIndex?: any, suggestion?: any, indexK?: any}>();
  styleGuide: string = 'NONE';
  styleGuideDispay = {
    "NONE": "NONE",
    "AMA": "AMA (11th ed)",
    "APA": "APA (7th ed)",
    "ACS": "ACS (2nd ed)",
    "AGU": "AGU (2017 ed)",
    "IEEE": "IEEE",
    "Redbook (3rd ed)-Legal": "USA-The Redbook (3rd ed.)",
    "SCI stereotypical language": "India-SCI Gender Handbook"
  }

  constructor(public sharedService: SharedService,
              public subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
              public amplitudeService: AmplitudeService) {
              this.subjectiveBehaviorSharedService.cardIsExpanded.subscribe(resp=>{
              })

              this.subjectiveBehaviorSharedService.getStyleGuide.subscribe(styleguide => {
                this.styleGuide = styleguide[0]
              })
  }

  ngOnInit() { }

  getColor(category: string) {
    let filter = Object.values(this.sharedService.inclusiveLanguageFilterOptions).find(filter => filter.error_category === category);
    return filter ? filter.color : '#0E7490';
  }

  openCard(alert,flag){
    this.outputAlertsFromChild.emit({alert,flag});
  }

  closeCard(alert,flag){
    this.outputAlertsFromChild.emit({alert,flag});
  }

  ignoreCard(alert, flag, parrentIndex, ignoreFlag) {
    var parentIndex =parrentIndex;
    var indexK = ignoreFlag;
    this.outputAlertsFromChild.emit({alert,flag, parentIndex,indexK});

    this.amplitudeService.logAmplitude('grammar_ignore',{
      'type':indexK?'all':'single',
      'category': alert.suggestions[0].error_category,
      'fileID': this.sharedService.currentFileId,
      'error_text': alert.text,
      'suggestion': alert.suggestions[0].suggestion,
      'lang_code': this.langCode,
      'strictness': alert.strictness,
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }

  rejectSuggestions(alert,flag, parrentIndex) {
    var parentIndex =0;
    this.outputAlertsFromChild.emit({alert,flag,parentIndex });
  }

  muteCard(alert, flag, suggestion, indexK,j){
    var parentIndex =j;
    this.outputAlertsFromChild.emit({alert,flag,parentIndex,suggestion , indexK});
  }

  acceptCard(alert, flag, suggestion, indexK, parrentIndex) {
      var parentIndex =parrentIndex;
      this.outputAlertsFromChild.emit({alert, flag, parentIndex, suggestion, indexK});
  }

  acceptNonCta(alert, flag, suggestion, indexK, parrentIndex) {
    var parentIndex =parrentIndex;
    this.outputAlertsFromChild.emit({alert, flag, parentIndex, suggestion, indexK});
  }

  hasSimilarCardToAccept(alertToCheck, suggestion) {
    return suggestion.type == 2 && this.getSimilarAcceptCard(alertToCheck, suggestion).length > 1;
  }

  private getSimilarAcceptCard(alertToCheck, suggestion) {
    return this.allAlertList
      .filter(alert => alert['text'] === alertToCheck['text'])
      .map(alert => {
        let matchSuggestion = alert['suggestions']
          .filter(sugg => sugg['type'] === suggestion['type'])
          .filter(sugg => sugg['tag'].indexOf('us_uk_spelling') >= 0)
          .filter(sugg => sugg['suggestion'] === suggestion['suggestion']);
        return [alert, (matchSuggestion.length > 0) ? matchSuggestion[0] : null];
      })
      .filter(alert => alert[1] !== null);
  }

  hasSimilarCardToIgnore(alertToCheck) {
    return this.getSimilarIgnoreCard(alertToCheck).length > 1;
  }

  private getSimilarIgnoreCard(alertToCheck) {
    let type = alertToCheck['suggestions'][0]['type'];
    if (type !== 1) {
      return this.allAlertList
        .filter(alert => alert['text'] === alertToCheck['text'])
        .filter(alert => alert['suggestions'][0]['type'] === type)
    }
    return [alertToCheck];
  }
}
