
<div class="refer_trinka_container">
        <div class="top_heading_secton" style="display: none;">
                <button type="button" class="close refer_closed_button" (click)="close()">×</button>
                <h1>Help your friends improve their writing!</h1>
                <div class="gift_text_content">
                        <div class="amazon_vouchers">
                                <img src="../../../../assets/images/amazon_icon.png" alt="">
                                <p>You get <span>Amazon vouchers</span> worth <span>$10</span> for every five successful Trinka
                                        referrals.*</p>
                        </div>
                        <div class="apple_airpods">
                                <img src="../../../../assets/images/apple_airpod_icon.png" alt="">
                                <p>Every month, one lucky winner with maximum referrals wins <span>Apple
                                                Airpods.</span>*</p>
                        </div>
                </div>
        </div>
        <div class="top_heading_second_section">
                <button type="button" class="close refer_closed_button" (click)="close()">×</button>
                <!-- *ngIf="country!='IN'" -->
                <h1>Refer Trinka to Get Exciting Rewards <span *ngIf="country=='IN'">Coming Soon</span></h1>
                <p>Give your friends a 10% discount coupon, and get back rewards worth 10% of their purchase. There's no limit to how many friends you can refer!</p>

                <!-- <h1 *ngIf="country=='IN'">Trinka Referral Program is Coming Soon..</h1> -->
                <!-- <p *ngIf="country=='IN'">Earn rewards on every successful referral!</p> -->
        </div>
        <div class="share_social_media_sections" [ngClass]="(country=='IN')? 'referralForIN' : '' ">
                <div class="referralOverlay"></div>
                <div class="Share_your_unique_section">
                        <h2>Share Your Unique Referral Coupon:</h2>
                        <div class="unique_link_section">
                                <p>
                                        <i *ngIf="country!='IN'">{{refer_code}}</i>
                                        <i *ngIf="country=='IN'">REFERRALCODE</i>
                                        <span #btnCopyToClipBoard (click)="copyToClipBoard()" *ngIf="btnLinkCopy">Copy Coupon</span>
                                        <span class="linkCopied_section" style="color: #10bc20; text-decoration: none;" *ngIf="linkCopied">
                                                <img src="../../../../assets/images/copiedLink_icon.png" alt="">
                                                Copied
                                        </span>
                                </p>
                                <!-- <span class="link-loader" *ngIf="loading_link">
                                        <div class="link_loader"></div>
                                </span> -->
                        </div>
                </div>
                <div class="social_media_icons_section">
                        <div #add2Any class="a2a_kit a2a_kit_size_32 a2a_default_style" (click)="shareClicked($event)"
                        data-a2a-url="https://cloud.trinka.ai/signup" data-a2a-title="Referral Program">
                                <a class="a2a_button_facebook"></a>
                                <a class="a2a_button_x"></a>
                                <a class="a2a_button_whatsapp"></a>
                                <a class="a2a_button_linkedin"></a>
                                <!-- <a class="a2a_button_wechat"></a>
                                <a class="a2a_button_google_gmail"></a>
                                <a class="a2a_button_blogger"></a>
                                <a class="a2a_button_skype"></a> -->
                                <!-- <a class="add_moreButtons a2a_dd" href="https://www.addtoany.com/share"></a> -->
                        </div>
                </div>
                <div class="invite_via_mail_section" *ngIf="!sendEmailSuccessfully">
                        <h2>Or Invite via Email: </h2> <img src="../../../../assets/images/refer_Secured_img.png"
                                alt="" title="Your data is secured and never shared with any third parties.">
                        <div class="multiple_share_section">
                                <textarea type='text' [(ngModel)]='invited_emailid'
                                        placeholder='Use comma(,) to add more than one email id'
                                        class='user_id_password'></textarea>
                                <span #errorMessage class="textarea_error_msg">{{error_message}}</span>
                                <div class="submit_multiple_emails">
                                        <button (click)='sendReferEmail()'>Send</button>
                                        <p (click)='openSampleMail()' style="cursor: pointer;"><a>View Sample Email</a></p>
                                </div>
                        </div>
                </div>
                <div class="sendEmail_section" *ngIf="sendEmailSuccessfully">
                        <h2>The email(s) are sent successfully.</h2>
                        <p style="margin: 0;">Continue to refer Trinka among your friends and win exciting rewards!</p>
                        <p><span (click)='backToEmail()'>Click here</span> to refer more friends.</p>
                </div>
        </div>
        <div class="applyTerms_section" [ngClass]="(country=='IN')? 'referralForIN' : '' ">
                <p><a href="https://www.trinka.ai/termsofservices/#referProgram" target="_blank">Referral Policy</a> | <a href="https://www.trinka.ai/faqs" target="_blank">FAQ</a></p>
        </div>
</div>