import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { VerifiedUserService } from 'src/app/_services/networkcalls/verified-user.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { PlagCheckReportShareComponent } from '../plag-check-report-share/plag-check-report-share.component';

@Component({
  selector: 'app-plag-report-viewer',
  templateUrl: './plag-report-viewer.component.html',
  styleUrls: ['./plag-report-viewer.component.scss']
})
export class PlagReportViewerComponent implements OnInit {

  downloadUrl: any;
  reportUrl: any;
  fileName: any;
  isShareEnable: boolean = false;
  isDownloadable: boolean = false;
  plagType : any;
  constructor(private dialog: MatDialog,
    private route: ActivatedRoute,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    private verifiedService: VerifiedUserService,
    private spinner: NgxSpinnerService,
    public amplitudeService: AmplitudeService,
    public sanitizer:DomSanitizer
  ) {
    this.loadProfileDetails();
    this.route.params.subscribe((params) => {
      this.fileName = params["fileName"];
      this.plagType = params['plagType'] == 2 ? 'advance check' : 'Standard check'
      if (this.fileName == "simcheck") {
        this.downloadUrl = "../../../../../assets/Trinka_Plagiarism_Checker_Sample_Trinka.pdf";
        this.fileName = "Trinka_Plagiarism_Checker_Sample_Trinka.pdf"
        this.isDownloadable = true;
      }
      else if (this.fileName == "ithenticate") {
        this.downloadUrl = "../../../../../assets/Trinka_Plagiarism_Checker_Sample_ithenticate.pdf";
        this.fileName = "Trinka_Plagiarism_Checker_Sample_ithenticate.pdf"
        this.isDownloadable = true;
      } else {

        try {
          this.fileName = decodeURI(atob(this.fileName));
          //this.downloadUrl = atob(params["downloadUrl"]);
          this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(decodeURIComponent(atob(params["reportUrl"])));  
          this.isShareEnable = true;
          this.isDownloadable = false;
        } catch (error) {
          this.isShareEnable = false;
          this.isDownloadable = false;
        }
      }
    })
  }

  ngOnInit() {
  }

  loadProfileDetails()
  {
    this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(profileData=>{
      if(Object.keys(profileData).length == 0)
      {
        this.verifiedService.getSelectedProfileData();
      }
    });
  }

  shareReport() {
    this.dialog.open(PlagCheckReportShareComponent, { data: { "downloadUrl": this.downloadUrl, "fileName": this.fileName,"plagType":this.plagType } });
    
  }

  download() {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = this.downloadUrl;
    a.download = this.fileName;
    a.click();
    document.body.removeChild(a);
    this.amplitudeService.logAmplitude('Plag_view_report', {
        'Report Type': this.plagType
    });
  }

  onProgress(event){
    this.spinner.show();
  }

  pageRendered(event){
    this.spinner.hide();
  }

  close_report(){
    window.self.close(); 
  }
}