import { ApplicationRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-loader-section-with-text',
  templateUrl: './dashboard-loader-section-with-text.component.html',
  styleUrls: ['./dashboard-loader-section-with-text.component.scss']
})
  
export class DashboardLoaderSectionWithTextComponent implements OnInit {
  loaderText: string;
  previousRoute: string;

  constructor(public dialogRef: MatDialogRef<DashboardLoaderSectionWithTextComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appRef: ApplicationRef,
    private router: Router
  ) { 
      this.loaderText = data["showAutocompleteText"] ? 'Uploading file for Proofreading...' : 'Opening file on Trinka Cloud Editor...';
      this.dialogRef.disableClose = true;
      this.appRef.tick();
    router.events.subscribe((val) => {
        if (this.previousRoute === this.router.url.toString()) {
          this.previousRoute = this.router.url.toString();
        } else {
          this.close();
        }
      })
    }

  ngOnInit() {
  }

  close(){
      this.dialogRef.close();
      this.appRef.tick();
  }

}
