<div class="feedback-modal-outer" [ngClass]="isSubmitted? 'instructionFeedbackThankYou' : '' ">
    <div class="feedback-modal-header" *ngIf="!isSubmitted">
      <h1>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z" stroke="#7A28A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 8H17" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 13H13" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Share your experience with us!
      </h1>
      <button (click)="closeModal(null)">
        <img src="../../../../assets/images/close-black.svg" alt="">
      </button>
    </div>
    <div class="feedback-modal-body" *ngIf="!isSubmitted">
      <div class="feedback-question">
        <p class="feedback-question-txt">Q1. Did you like Panini-1 Model?</p>
        <div class="feedback-question-option-1">
            <span class="feedback-icon feedback-like-icon" *ngIf="!isLiked" (click)="isLiked = true"></span>
            <span class="feedback-icon feedback-like-icon-hover" *ngIf="isLiked" (click)="isLiked = true"></span>
            <span class="feedback-icon feedback-dislike-icon" *ngIf="isLiked" (click)="isLiked = false"></span>
            <span class="feedback-icon feedback-dislike-icon-hover" *ngIf="!isLiked" (click)="isLiked = false"></span>
        </div>
      </div>
      <div class="feedback-question">
        <p class="feedback-question-txt">Q2. In your opinion, which model performed better in the following parameters?</p>
        <table>
            <thead>
                <tr>
                    <th>Parameters</th>
                    <th>Panini-1</th>
                    <th>Live Model</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="dot-underline">
                        <span>Accuracy</span>
                        <span class="feedback-tooltip">Model gave precise corrections for grammar, spelling, and vocabulary</span>
                    </td>
                    <td class="round-radio-btn">
                        <label class="round-radio-btn-outer">
                            <input type="checkbox" (click)="toggleSelectedOption('accuracyOption', 'panini')" [(ngModel)]="accuracyOption.panini">
                            <span class="round-radio-btn-inner"></span>
                        </label>
                    </td>
                    <td class="round-radio-btn">
                        <label class="round-radio-btn-outer">
                            <input type="checkbox" (click)="toggleSelectedOption('accuracyOption', 'live')" [(ngModel)]="accuracyOption.live">
                            <span class="round-radio-btn-inner"></span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="dot-underline">
                        <span>Quality</span>
                        <span style="width: 190px;" class="feedback-tooltip">Model enhanced my writing and made it more fluent</span>
                    </td>
                    <td class="round-radio-btn">
                        <label class="round-radio-btn-outer">
                            <input type="checkbox" (click)="toggleSelectedOption('qualityOption', 'panini')" [(ngModel)]="qualityOption.panini">
                            <span class="round-radio-btn-inner"></span>
                        </label>
                    </td>
                    <td class="round-radio-btn">
                        <label class="round-radio-btn-outer">
                            <input type="checkbox" (click)="toggleSelectedOption('qualityOption', 'live')" [(ngModel)]="qualityOption.live">
                            <span class="round-radio-btn-inner"></span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="dot-underline">
                        <span>Speed</span>
                        <span style="width: 160px;" class="feedback-tooltip">Model gave suggestions instantly</span>
                    </td>
                    <td class="round-radio-btn">
                        <label class="round-radio-btn-outer">
                            <input type="checkbox" (click)="toggleSelectedOption('speedOption', 'panini')" [(ngModel)]="speedOption.panini">
                            <span class="round-radio-btn-inner"></span>
                        </label>
                    </td>
                    <td class="round-radio-btn">
                        <label class="round-radio-btn-outer">
                            <input type="checkbox" (click)="toggleSelectedOption('speedOption', 'live')" [(ngModel)]="speedOption.live">
                            <span class="round-radio-btn-inner"></span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
      <div class="feedback-question">
        <p class="feedback-question-txt">Q3. Any suggestions?</p>
        <textarea [(ngModel)]="textFeedback" placeholder="Do you have any suggestions for Panini-1..."></textarea>
      </div>
    </div>
    <div class="feedback-modal-footer" *ngIf="!isSubmitted">
        <div class="footerCTASection">
          <button (click)="submitFeedback()">Submit Feedback</button>
        </div>
    </div>
    <div class="feedbackThankYou" *ngIf="isSubmitted">
        <div class="SVGwrapper"> 
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
        </div>
        <h2 class="animate__animated animate__fadeInUp animate__delay-1s">Thank you for sharing your feedback!</h2>
        <button class="contactusCloseButton animate__animated animate__fadeInUp animate__delay-1s" mat-button mat-dialog-close>Close</button>
      </div>
  </div>
  