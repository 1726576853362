import { Injectable } from '@angular/core';
import { Router, CanActivate, NavigationCancel } from '@angular/router';
import { AuthService } from './auth.service';
import { SubjectiveBehaviorSharedService } from '../_services/subjective-behavior-shared.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public auth: AuthService,
    public router: Router,
    public sharedService : SubjectiveBehaviorSharedService) {
      router.events.subscribe(e => {
        if (e instanceof NavigationCancel) {
          this.sharedService.setPreviousUrl(e.url)
        }
      });
      }

  canActivate(): boolean {
    var loc = window.location.href
    if (!this.auth.isAuthenticated() && !loc.includes('feedback')) {
      this.router.navigate(['/signin']);
      return false;
    }
    return true;
  }
}