import { Component, OnInit } from '@angular/core';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { AuthorShipCheck } from 'src/app/_interfaces/author-one/author-ship-check';

@Component({
  selector: 'app-authorship-check',
  templateUrl: './authorship-check.component.html',
  styleUrls: ['./authorship-check.component.scss']
})
export class AuthorshipCheckComponent implements OnInit {
  
  panelOpenState = false;
  authorShipList : any;
  authorShipCheck : AuthorShipCheck;
  summary = {};
  constructor(private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService) {
    this.getReportData();
     }

  ngOnInit() {
  }

  getReportData(){
    this.subjectiveBehaviorSharedService.getA1AllCheckReportData.subscribe(reportData=>{
      this.authorShipCheck = reportData['technical_compliance']['authorship'];
      this.summary = reportData['technical_compliance']['summary'];
      this.createAuthorShipArray(this.authorShipCheck);
    });
  }

  createAuthorShipArray(authorShipCheck){
    this.authorShipList =[
      {
        'checkNo':'Check 1',
        'checkName' : 'Author Details',
        'checkList': authorShipCheck.author_names.authors_list, 
        'count':authorShipCheck.author_names.count,
        'text':authorShipCheck.author_names.is_present? 'author(s) found': 'No author(s) found.',
        'textExpand':'Please add the author name(s).',
        'flag':authorShipCheck.author_names.is_present, 
        'tooltip':'Shows the detected author list'
      },
      {
        'checkNo':'Check 2',
        'checkName' : 'Affiliations',
        'checkList': authorShipCheck.affiliators.affiliators_list,
        'count':authorShipCheck.affiliators.count,
        'text':authorShipCheck.affiliators.is_present? 'affiliation(s) identified': 'No affiliation(s) found.',
        'textExpand':'Provide the name of the university, institute or organization below the author name(s).',
        'flag': authorShipCheck.affiliators.is_present, 
        'tooltip':'Shows the detected affiliation list'
      },
      {
        'checkNo':'Check 3',
        'checkName' : 'Correspondence',
        'checkList': authorShipCheck.correspondence.correspondence,
        'count':authorShipCheck.correspondence.correspondence != undefined? authorShipCheck.correspondence.correspondence.length: 0,
        'text':authorShipCheck.correspondence.is_present?'correspondence email(s) identified': 'No correspondence details found.',
        'textExpand':'Please add the email address(es) of the corresponding author(s).',
        'flag':authorShipCheck.correspondence.is_present, 
        'tooltip': 'Shows the detected email address(es)'
      },
      {
        'checkNo':'Check 4',
        'checkName' : 'Author Contribution',
        'checkList': authorShipCheck.author_contribution.matches,
        'count':authorShipCheck.author_contribution.matches.length,
        'text':authorShipCheck.author_contribution.is_present?'author contribution statement(s) found':'No author contribution statement(s) found.',
        'textExpand':'Please provide the contribution of each author to the study  according to the target journal guidelines.',
        'flag':authorShipCheck.author_contribution.is_present,
        'tooltip':'Shows the detected author contribution statements'
        },
    ];
  }

 
}
