import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(public http: HttpClient) { }


  getPaymentSubscriptionDetails(userid):Observable<any>{
    return this.http.get(environment.payment_api+"/api/v1/payment/"+userid)
  }

  getPaymentUrl(userid){
    let pcs= "http://0.0.0.0:9000";
    return this.http.get(pcs+"/trinka/api/v1/payment/generateurl/"+userid)
  }

  getUserTransactionDetails(userId):Observable<any>{
    return this.http.get(environment.payment_api+"/api/v1/all/payment/"+userId)
  }

  cancelSubscription(userId):Observable<any>{
    return this.http.post(environment.payment_api+"/api/v1/subscription/cancel/"+userId,{})
  }

  createSetupIntent(payload): Observable<any> {
    return this.http.post(environment.payment_api + "/api/v1/payment/subscription/setupintent/create", payload);
  }

  changePaymentMethodOnActiveSubscription(payload): Observable<any> {
    return this.http.patch(environment.payment_api + "/api/v1/payment/subscription/paymentmethod/update", payload);
  }

  getSavedCardDetails(user_id: string): Observable<any> {
    return this.http.get(environment.payment_api + "/api/v1/payment/" + user_id + "/cards");
  }

  deleteSavedCard(user_id: string, card_id: string): Observable<any> {
    return this.http.delete(environment.payment_api + "/api/v1/payment/" + user_id + "/card/" + card_id);
  }

  getSubscriptionPaymentMethod(subscription_id: string): Observable<any> {
    return this.http.get(environment.payment_api + "/api/v1/payment/subscription/" + subscription_id + "/paymentmethod");
  }

  getCustomerPortalLink(userid: string, isPremiumUpgrade: boolean = false): Observable<any> {
    return this.http.get(environment.payment_api + "/api/v1/payment/customer/portal/" + userid + "/" + isPremiumUpgrade + "/" + !isDevMode());
  }
}
