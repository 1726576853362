import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { MyDriveService } from 'src/app/_services/networkcalls/my-drive.service';
import { VerifiedUserService } from 'src/app/_services/networkcalls/verified-user.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';

@Component({
  selector: 'app-show-my-drive-files-modal',
  templateUrl: './show-my-drive-files-modal.component.html',
  styleUrls: ['./show-my-drive-files-modal.component.scss']
})
export class ShowMyDriveFilesModalComponent implements OnInit {

  userId:any;
  folderList:any;
  fileList:any;
  dataSource:any;
  folderStatus:boolean = false;
  filesStatus:boolean = false;
  currentfolderName: any;
  folderId:any;
  isArchiveMode = false;
  is_purge : boolean = false;
  archiveFilesCount: number = 0;
  searchFileWithName: string = "";
  searchChangeObservable: Subject<any> = new Subject();
  selectedFileName: string = "";
  selectedFileId:any;
  selectedFileDetails:any;
  selectedFileCount:any;

  constructor(public dialogRef: MatDialogRef<ShowMyDriveFilesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private myDriveNetwordCalls: MyDriveService,
    private networkCalls: DashboardService,
    private subjectiveBehaviourService: SubjectiveBehaviorSharedService,
    private verifiedService: VerifiedUserService,) { }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.getAllFolderList();
    this.verifiedService.getSelectedProfileData();
    this.searchChangeObservable.pipe(debounceTime(1500)).subscribe(() => this.getFilesBySearchCall(this.searchFileWithName));
    this.dialogRef.backdropClick().subscribe(() => {
      // this.close(); 
      });
  }

  getAllFolderList(){
    this.folderId = "";
    this.selectedFileName = "";
    this.selectedFileId = "";
    this.searchFileWithName = "";
    this.spinner.show();
    this.myDriveNetwordCalls.getFoldersByUser(this.userId).subscribe(res => {
      this.folderList = res.data;
      this.archiveFilesCount = res.archive_files_count;
      this.folderStatus = true;
      this.filesStatus = false;
    }, error => {
      this.spinner.hide();
      this.sharedService.errorHandller(error);
    });

    this.networkCalls.getFilesByUser(this.userId).subscribe(files => {
      this.spinner.hide();
      this.fileList = files.data;
    }, error => {
      this.spinner.hide();
      this.sharedService.errorHandller(error);
    });
  }

  getAllFilebyFolderId(row){
    this.selectedFileName = "";
    this.selectedFileId = "";
    this.searchFileWithName = "";
    this.currentfolderName = row.folder_name;
    this.folderId = row.folder_id;
    this.selectedFileCount = row.file_count;
    this.spinner.show();
    this.myDriveNetwordCalls.getFilesByFolderId(this.userId, row.folder_id).subscribe(res => {
      this.spinner.hide();
      this.dataSource = res.data;
      this.filesStatus = true;
      this.folderStatus = false;
    }, error => {
      this.spinner.hide();
      this.sharedService.errorHandller(error);
    });
  }

  getArchiveFilesByUserCall(showSpinner = false) {
    this.currentfolderName = "Archived files";
    this.selectedFileCount = this.archiveFilesCount;
    if (showSpinner)
      this.spinner.show();
    this.myDriveNetwordCalls.getArchiveFilesRequest(this.userId).subscribe(files => {
      this.spinner.hide();
      this.dataSource = files.data;
        this.filesStatus = true;
        this.folderStatus = false;
    }, error => {
      this.spinner.hide();
      this.sharedService.errorHandller(error);
    })
  }
  
  searchFiles() {
    if(this.searchFileWithName.trim() == ""){
      this.filesStatus = false;
      this.folderStatus = true;
    }
    this.searchChangeObservable.next();
  }

  getFilesBySearchCall(searchString: string, showSpinner = false) {
    if(this.searchFileWithName.trim()!=""){
      var fd = new FormData();
      fd.append("search_string", searchString);
      fd.append("archieve_status", '0');
      this.myDriveNetwordCalls.getFilesBySearchCall(this.userId, fd).subscribe(files => {
        this.dataSource = files.data;
        this.filesStatus = true;
        this.folderStatus = false;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.sharedService.errorHandller(error);
      });
    }
    else{
      this.getAllFolderList();
    }
  }

  setSelectedFileDetails(file){
    this.selectedFileDetails = file;
    this.selectedFileName = file.file_name;
    this.selectedFileId = file.file_id;
  }
  
  uploadFile(){
    this.dialogRef.close(this.selectedFileDetails);
  }
  
}
