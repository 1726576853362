import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-spinner',
  templateUrl: './editor-spinner.component.html',
  styleUrls: ['./editor-spinner.component.scss']
})
export class EditorSpinnerComponent implements OnInit {

  //@Input('mainToEditorSpinner') IS_CONNECTED: boolean;

  constructor() { 
  }

  ngOnInit() {
  }
}
