<ngx-spinner></ngx-spinner>
<div class="pageLoader" *ngIf="citationCheckPageLoader">
  <svg class="spinner">
    <circle style="cx: 50%;cy: 50%; r: 50%;">
      <animateTransform attributeName="transform" type="rotate" values="-90;810" keyTimes="0;1"
        dur="2s" repeatCount="indefinite"></animateTransform>
      <animate attributeName="stroke-dashoffset" values="0%;0%;-157.080%" calcMode="spline"
        keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s"
        repeatCount="indefinite"></animate>
      <animate attributeName="stroke-dasharray" values="0% 314.159%;157.080% 157.080%;0% 314.159%"
        calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s"
        repeatCount="indefinite"></animate>
    </circle>
  </svg>
  <span>Opening Citation Checker...</span>
</div>

<header class="header-wrapper">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-7">
        <div class="logo">
          <img src="../../../assets/images/Trinka_Logo.svg" [routerLink]="['/dashboard']" alt="logo" />
          <span class="tags-box" *ngIf="creditsDetails?.user_type === 'BASIC'">Basic</span>
          <span class="tags-box-premium" *ngIf="creditsDetails?.user_type === 'PREMIUM'">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.187 4.35161L9.41105 1.67033C9.34045 1.56408 9.22115 1.5 9.09353 1.5H2.90684C2.77913 1.5 2.65992 1.56409 2.58931 1.67033L0.813334 4.35161C0.718569 4.49408 0.731218 4.68211 0.844103 4.81027L5.71367 10.3701C5.78559 10.4528 5.88998 10.5 5.99995 10.5C6.10992 10.5 6.2143 10.4528 6.2866 10.3698L11.1562 4.8099C11.2687 4.6821 11.2813 4.49408 11.187 4.35161ZM3.84922 4.9405L5.02944 8.43581L1.96799 4.9405H3.84922ZM6.87805 2.25922L7.38839 4.18168H4.61062L5.12097 2.25922H6.87805ZM7.34695 4.9405L5.99977 8.93135L4.65259 4.9405H7.34695ZM8.15037 4.9405H10.0317L6.97023 8.43581L8.15037 4.9405ZM10.1619 4.18127H8.17623L7.66588 2.25881H8.88875L10.1619 4.18127ZM3.11118 2.2592H4.33405L3.8237 4.18165H1.83753L3.11118 2.2592Z" fill="#92400E"/>
            </svg>  
            Premium
          </span>
          <span class="tags-box-premium-plus" *ngIf="creditsDetails?.user_type === 'PREMIUM_PLUS'">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.935 6.0381L10.0987 3.43811C10.0257 3.33509 9.90237 3.27295 9.77042 3.27295H3.37364C3.24159 3.27295 3.11833 3.33509 3.04533 3.43811L1.20904 6.0381C1.11106 6.17625 1.12414 6.35857 1.24085 6.48285L6.27578 11.8741C6.35015 11.9543 6.45808 12.0001 6.57179 12.0001C6.68549 12.0001 6.79342 11.9543 6.86817 11.8738L11.9031 6.48249C12.0194 6.35857 12.0325 6.17625 11.935 6.0381ZM4.34802 6.60914L5.56831 9.99846L2.4029 6.60914H4.34802ZM7.4797 4.00915L8.00738 5.87331H5.13527L5.66295 4.00915H7.4797ZM7.96453 6.60914L6.5716 10.479L5.17867 6.60914H7.96453ZM8.79523 6.60914H10.7404L7.57501 9.99846L8.79523 6.60914ZM10.8751 5.87292H8.82197L8.29429 4.00875H9.55868L10.8751 5.87292ZM3.58492 4.00913H4.84931L4.32164 5.87329H2.26802L3.58492 4.00913Z" fill="#92400E"/>
              <path d="M2.85775 0L3.32074 1.19438L4.57194 1.63633L3.32074 2.07829L2.85775 3.27267L2.39476 2.07829L1.14355 1.63633L2.39476 1.19438L2.85775 0Z" fill="#92400E"/>
              <path d="M1.1428 2.72705L1.45146 3.5233L2.28559 3.81794L1.45146 4.11258L1.1428 4.90883L0.834138 4.11258L0 3.81794L0.834138 3.5233L1.1428 2.72705Z" fill="#92400E"/>
            </svg>
            Premium Plus
          </span>
          <!-- <span class="tags-box" *ngIf="!paymentDetails?.is_premium">Free</span>
          <span class="tags-box-premium" *ngIf="paymentDetails?.is_premium">Premium</span> -->
          <div class="plagCheckHeading">
            <h1>Citation Checker</h1>
            <span (click)="credits()"  *ngIf="creditsDetails.credits + creditsDetails.purchased_credits != 0">
              Credits: {{ creditsDetails.credits + creditsDetails.purchased_credits || 0 }}
            </span>
            <span (click)="credits()" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0">Nil</span>
            <span *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0
                " class="autoEditBuyOption" (click)="addCredits()">Buy</span>
            <span class="referralReferralHeaderText" *ngIf="country!='IN'" (click)="showReferralRewardModal()">Rewards: <i>${{referralReward}}</i></span>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div class="media media-credits">
          <div class="media-body">
            <button [ngClass]="paymentDetails?.user_type == 'PREMIUM'? 'plag-check-upgrade-btn editorUpgradeButtonPremiumPlus' : paymentDetails?.user_type == 'BASIC' ? 'plag-check-upgrade-btn': '' " *ngIf="paymentDetails?.user_type !== 'PREMIUM_PLUS' && paymentDetails?.user_type !== 'PREMIUM_TRIAL' && paymentDetails?.user_type !== 'INSTITUTIONAL_ACCESS'" (click)="redirectToUpgradeToPremiumPage(true)" 
                >
                    <svg *ngIf="paymentDetails?.user_type == 'BASIC'" width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.187 4.35161L9.41105 1.67033C9.34045 1.56408 9.22115 1.5 9.09353 1.5H2.90684C2.77913 1.5 2.65992 1.56409 2.58931 1.67033L0.813334 4.35161C0.718569 4.49408 0.731218 4.68211 0.844103 4.81027L5.71367 10.3701C5.78559 10.4528 5.88998 10.5 5.99995 10.5C6.10992 10.5 6.2143 10.4528 6.2866 10.3698L11.1562 4.8099C11.2687 4.6821 11.2813 4.49408 11.187 4.35161ZM3.84922 4.9405L5.02944 8.43581L1.96799 4.9405H3.84922ZM6.87805 2.25922L7.38839 4.18168H4.61062L5.12097 2.25922H6.87805ZM7.34695 4.9405L5.99977 8.93135L4.65259 4.9405H7.34695ZM8.15037 4.9405H10.0317L6.97023 8.43581L8.15037 4.9405ZM10.1619 4.18127H8.17623L7.66588 2.25881H8.88875L10.1619 4.18127ZM3.11118 2.2592H4.33405L3.8237 4.18165H1.83753L3.11118 2.2592Z" fill="#92400E"/>
                    </svg>
                    <svg *ngIf="paymentDetails?.user_type == 'PREMIUM'" width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.935 6.0381L10.0987 3.43811C10.0257 3.33509 9.90237 3.27295 9.77042 3.27295H3.37364C3.24159 3.27295 3.11833 3.33509 3.04533 3.43811L1.20904 6.0381C1.11106 6.17625 1.12414 6.35857 1.24085 6.48285L6.27578 11.8741C6.35015 11.9543 6.45808 12.0001 6.57179 12.0001C6.68549 12.0001 6.79342 11.9543 6.86817 11.8738L11.9031 6.48249C12.0194 6.35857 12.0325 6.17625 11.935 6.0381ZM4.34802 6.60914L5.56831 9.99846L2.4029 6.60914H4.34802ZM7.4797 4.00915L8.00738 5.87331H5.13527L5.66295 4.00915H7.4797ZM7.96453 6.60914L6.5716 10.479L5.17867 6.60914H7.96453ZM8.79523 6.60914H10.7404L7.57501 9.99846L8.79523 6.60914ZM10.8751 5.87292H8.82197L8.29429 4.00875H9.55868L10.8751 5.87292ZM3.58492 4.00913H4.84931L4.32164 5.87329H2.26802L3.58492 4.00913Z" fill="#92400E"/>
                        <path d="M2.85775 0L3.32074 1.19438L4.57194 1.63633L3.32074 2.07829L2.85775 3.27267L2.39476 2.07829L1.14355 1.63633L2.39476 1.19438L2.85775 0Z" fill="#92400E"/>
                        <path d="M1.1428 2.72705L1.45146 3.5233L2.28559 3.81794L1.45146 4.11258L1.1428 4.90883L0.834138 4.11258L0 3.81794L0.834138 3.5233L1.1428 2.72705Z" fill="#92400E"/>
                    </svg>

                    <span *ngIf="paymentDetails?.user_type == 'BASIC'">Upgrade</span>
                    <span *ngIf="paymentDetails?.user_type == 'PREMIUM'">Upgrade to Premium Plus</span>
            </button>
            <button class="plag-check-upgrade-btn institutional_btn" *ngIf="paymentDetails?.user_type&&paymentDetails?.user_type === 'INSTITUTIONAL_ACCESS'" >
              <img src="../../../assets/images/institutional.svg" alt="Browser Icon"/>
              <span>Institutional Access</span>
            </button>
            <!-- <p>
              <img src="../../../assets/images/reference-quality/Vector.svg" alt="vector" />
              Credits: <strong>
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits != 0"
                  (click)="credits()">{{ creditsDetails.credits + creditsDetails.purchased_credits || 0 }}</span>
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0"
                  (click)="credits()">Nil</span>
                <span *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0
                " class="autoEditBuyOption" (click)="addCredits()">Buy</span>

              </strong>
            </p> -->
          </div>
          <div class="media-right">
            <button mat-icon-button [matMenuTriggerFor]="menu1" class="p-0">
              <span class="name-initial">{{profileName}}</span>
            </button>
            <mat-menu #menu1="matMenu" class="profile-menu personalize_top_menus">
              <a mat-menu-item [routerLink]="['/dashboard/profile']">
                  <img class="img-fluid" src="../../../../assets/images/Icon_ManageProfile.svg"
                      alt="logo favicon">
                  <span> Account Settings</span>
              </a>
              <button mat-menu-item (click)="signOut()"><img class="img-fluid"
                      src="../../../../assets/images/Icon_Logout.svg" alt="logo favicon">
                  Logout</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="section-md section-first">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title">
          <!-- <h3 class="x-title">Citation Checker</h3> -->
          <p>Identify weak citations in 3 simple steps</p>
          <div class="rc-line">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row match-box">
      <div class="col-lg-4 col-md-4 col-12 dflex">
        <div class="c-card steps-card">
          <h3>Submit file</h3>
          <p>Submit the doc/docx file with all citations (incomplete citations lists yield inaccurate scores)</p>
          <span class="number">01</span>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12 dflex">
        <div class="c-card steps-card">
          <h3>Get free score & report</h3>
          <p>Get the quality score for free. Only buy the report when you need it at 1 credit for 30 references.</p>
          <span class="number">02</span>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12 dflex">
        <div class="c-card steps-card">
          <h3>Improve citations</h3>
          <p>Identify citations with issues across visibility, age & more. Replace with stonger citations to improve.</p>
          <span class="number">03</span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-md bg-grey">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="x-title">Submit file</h3>
      </div>
      <div class="col-12 mb-20 mt-20">
        <div class="drag-area" *ngIf="!fileSelected" (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
          <div class="icon" (click)="referenceQuality.click()">
            <img src="../../../assets/images/reference-quality/upload.svg" alt="Upload" />
          </div>
          <h3 (click)="referenceQuality.click()">Drag and drop to upload file</h3>
          <span>or</span>
          <h3 style="cursor: none;">select file from</h3>
          <ul>
            <li (click)="launchTrinkaDrivePicker()">
              <svg _ngcontent-nqb-c7="" fill="none" height="24" viewBox="0 0 26 20" width="30"
                xmlns="http://www.w3.org/2000/svg">
                <path _ngcontent-nqb-c7=""
                  d="M21.6349 15.109C21.6349 15.3108 21.5751 15.5081 21.4629 15.676C21.3508 15.8438 21.1914 15.9746 21.0049 16.0518C20.8184 16.1291 20.6132 16.1493 20.4152 16.1099C20.2173 16.0705 20.0354 15.9733 19.8927 15.8306C19.7499 15.6879 19.6527 15.506 19.6134 15.3081C19.574 15.1101 19.5942 14.9049 19.6714 14.7184C19.7487 14.5319 19.8795 14.3725 20.0473 14.2604C20.2152 14.1482 20.4125 14.0884 20.6143 14.0884C20.885 14.0884 21.1446 14.1959 21.336 14.3873C21.5274 14.5787 21.6349 14.8383 21.6349 15.109V15.109Z"
                  fill="#773795"></path>
                <path _ngcontent-nqb-c7=""
                  d="M24.9945 10.7444C24.9945 10.5326 25.3093 11.3578 21.1944 1.46144C21.1374 1.32431 21.0409 1.20723 20.9172 1.12507C20.7934 1.04291 20.6481 0.999383 20.4996 1.00001H6.24404C6.10455 0.999291 5.96763 1.03753 5.8487 1.11042C5.72977 1.18332 5.63356 1.28796 5.57089 1.41258C0.685141 11.1841 1.02171 10.4729 1 10.6412C1 10.7118 1 10.1961 1 18.2413C1 18.3397 1.01938 18.4371 1.05703 18.528C1.09467 18.6188 1.14986 18.7014 1.21942 18.771C1.28899 18.8406 1.37157 18.8957 1.46246 18.9334C1.55335 18.971 1.65077 18.9904 1.74915 18.9904H24.2453C24.4421 18.9904 24.631 18.913 24.7712 18.7748C24.9114 18.6367 24.9916 18.4489 24.9945 18.2521C24.9945 18.1273 24.9945 10.7986 24.9945 10.7444V10.7444ZM24.9945 10.6901H1.02171H24.9945Z"
                  stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
              </svg>
              <p>Trinka My Drive</p>
            </li>
            <li (click)="referenceQuality.click()">
              <svg _ngcontent-slg-c9="" fill="none" height="26" viewBox="0 0 24 16" width="32"
                xmlns="http://www.w3.org/2000/svg">
                <path _ngcontent-slg-c9=""
                  d="M4.25 0C3.65326 0 3.08097 0.237053 2.65901 0.65901C2.23705 1.08097 2 1.65326 2 2.25V11.25C2 11.8467 2.23705 12.419 2.65901 12.841C3.08097 13.2629 3.65326 13.5 4.25 13.5H19.75C20.3467 13.5 20.919 13.2629 21.341 12.841C21.7629 12.419 22 11.8467 22 11.25V2.25C22 1.65326 21.7629 1.08097 21.341 0.65901C20.919 0.237053 20.3467 0 19.75 0H4.25ZM3.5 2.25C3.5 2.05109 3.57902 1.86032 3.71967 1.71967C3.86032 1.57902 4.05109 1.5 4.25 1.5H19.75C19.9489 1.5 20.1397 1.57902 20.2803 1.71967C20.421 1.86032 20.5 2.05109 20.5 2.25V11.25C20.5 11.4489 20.421 11.6397 20.2803 11.7803C20.1397 11.921 19.9489 12 19.75 12H4.25C4.05109 12 3.86032 11.921 3.71967 11.7803C3.57902 11.6397 3.5 11.4489 3.5 11.25V2.25ZM0.75 14.5C0.551088 14.5 0.360322 14.579 0.21967 14.7197C0.0790175 14.8603 0 15.0511 0 15.25C0 15.4489 0.0790175 15.6397 0.21967 15.7803C0.360322 15.921 0.551088 16 0.75 16H23.25C23.4489 16 23.6397 15.921 23.7803 15.7803C23.921 15.6397 24 15.4489 24 15.25C24 15.0511 23.921 14.8603 23.7803 14.7197C23.6397 14.579 23.4489 14.5 23.25 14.5H0.75Z"
                  fill="#4D4D4D"></path>
                <path _ngcontent-slg-c9="" d="M10 6H14" stroke="#773795" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2"></path>
              </svg>
              <p>My Device</p>
            </li>
            <li (click)="launchOneDrivePicker()">
              <svg _ngcontent-hvg-c7="" fill="none" height="22" viewBox="0 0 24 16" width="30"
                xmlns="http://www.w3.org/2000/svg">
                <g _ngcontent-hvg-c7="" clip-path="url(#clip0_1393_8873)">
                  <path _ngcontent-hvg-c7=""
                    d="M14.4991 10.3764L19.453 5.63883C18.424 1.62633 14.3371 -0.792141 10.3246 0.236954C8.51775 0.700347 6.9484 1.8204 5.92285 3.37852C5.99945 3.37664 14.4991 10.3764 14.4991 10.3764V10.3764Z"
                    fill="#0364B8"></path>
                  <path _ngcontent-hvg-c7=""
                    d="M9.15169 4.27053L9.1515 4.27137C8.20489 3.68508 7.11319 3.37519 5.99972 3.37671C5.97403 3.37671 5.94872 3.37831 5.92303 3.37859C2.61113 3.41965 -0.0405003 6.13784 0.000468414 9.44985C0.0153465 10.6512 0.390719 11.8204 1.07794 12.8059L8.49966 11.8764L14.1897 7.28825L9.15169 4.27053V4.27053Z"
                    fill="#0078D4"></path>
                  <path _ngcontent-hvg-c7=""
                    d="M19.4529 5.63899C19.3434 5.63124 19.2337 5.62714 19.1239 5.62671C18.4593 5.62607 17.8017 5.7618 17.1917 6.02552L17.1915 6.02506L14.1895 7.28834L17.499 11.3765L23.4156 12.8147C24.6924 10.4444 23.8059 7.48784 21.4356 6.21096C20.8231 5.88105 20.1471 5.68606 19.4529 5.63909V5.63899Z"
                    fill="#1490DF"></path>
                  <path _ngcontent-hvg-c7=""
                    d="M1.07812 12.8058C1.63023 13.5996 2.36625 14.248 3.22333 14.6956C4.08041 15.1432 5.03308 15.3767 6 15.3762H19.1244C20.0044 15.3764 20.868 15.1384 21.6236 14.6874C22.3792 14.2364 22.9987 13.5893 23.4161 12.8146L14.1899 7.28809L1.07812 12.8058V12.8058Z"
                    fill="#28A8EA"></path>
                </g>
                <defs _ngcontent-hvg-c7="">
                  <clipPath _ngcontent-hvg-c7="" id="clip0_1393_8873">
                    <rect _ngcontent-hvg-c7="" fill="white" height="15.4688" width="24"></rect>
                  </clipPath>
                </defs>
              </svg>
              <p>OneDrive</p>
            </li>
            <li (click)="launchDropBoxPicker()">
              <svg _ngcontent-lre-c7="" fill="none" height="27" viewBox="0 0 24 21" width="31"
                xmlns="http://www.w3.org/2000/svg">
                <g _ngcontent-lre-c7="" clip-path="url(#clip0_1393_8871)">
                  <path _ngcontent-lre-c7=""
                    d="M5.99953 0L0 3.82228L5.99953 7.64466L12 3.82228L5.99953 0ZM18 0L12 3.82266L18 7.64531L24.0001 3.82266L18 0ZM0 11.4676L5.99953 15.29L12 11.4676L5.99953 7.64531L0 11.4676ZM18 7.64531L12 11.468L18 15.2905L24 11.468L18 7.64531ZM6 16.5723L12.0005 20.3947L18 16.5723L12.0005 12.75L6 16.5723Z"
                    fill="#0061FF"></path>
                </g>
                <defs _ngcontent-lre-c7="">
                  <clipPath _ngcontent-lre-c7="" id="clip0_1393_8871">
                    <rect _ngcontent-lre-c7="" fill="white" height="20.4375" width="24"></rect>
                  </clipPath>
                </defs>
              </svg>
              <p>DropBox</p>
            </li>
            <li (click)="launchGoogleDrivePicker()">
              <svg _ngcontent-mho-c7="" fill="none" height="26" viewBox="0 0 24 22" width="30"
                xmlns="http://www.w3.org/2000/svg">
                <path _ngcontent-mho-c7=""
                  d="M1.81444 18.3783L2.87287 20.2064C3.09281 20.5914 3.40894 20.8938 3.78009 21.1137C4.84322 19.7643 5.58309 18.7287 6.00009 18.0071C6.42319 17.2749 6.94322 16.1293 7.56019 14.5707C5.89744 14.3518 4.63744 14.2424 3.78019 14.2424C2.95734 14.2424 1.69734 14.3518 0 14.5707C0 14.9968 0.109969 15.4229 0.329906 15.8079L1.81444 18.3783V18.3783Z"
                  fill="#0066DA"></path>
                <path _ngcontent-mho-c7=""
                  d="M20.2204 21.1137C20.5917 20.8938 20.9078 20.5914 21.1276 20.2065L21.5675 19.4505L23.6707 15.8079C23.8866 15.4313 24.0003 15.0048 24.0005 14.5707C22.2933 14.3518 21.0357 14.2424 20.2274 14.2424C19.3587 14.2424 18.1009 14.3518 16.4541 14.5707C17.0638 16.1379 17.5769 17.2834 17.9937 18.0071C18.4139 18.7374 19.1562 19.7728 20.2204 21.1137V21.1137Z"
                  fill="#EA4335"></path>
                <path _ngcontent-mho-c7=""
                  d="M11.9998 6.87291C13.2298 5.38744 14.0775 4.24191 14.5428 3.4365C14.9175 2.78794 15.3299 1.75238 15.7799 0.329906C15.4088 0.109969 14.9827 0 14.5428 0H9.45685C9.01698 0 8.59098 0.12375 8.21973 0.329906C8.79216 1.96134 9.27798 3.12244 9.67698 3.81309C10.118 4.57641 10.8923 5.59631 11.9998 6.87291Z"
                  fill="#00832D"></path>
                <path _ngcontent-mho-c7=""
                  d="M16.4401 14.5706H7.56028L3.78027 21.1136C4.15134 21.3335 4.57743 21.4435 5.01731 21.4435H18.9831C19.4229 21.4435 19.8491 21.3197 20.2202 21.1135L16.4402 14.5706H16.4401Z"
                  fill="#2684FC"></path>
                <path _ngcontent-mho-c7=""
                  d="M12.0001 6.87298L8.22009 0.330078C7.84884 0.550016 7.53272 0.852359 7.31278 1.2373L0.329906 13.3336C0.114012 13.7102 0.000285232 14.1366 0 14.5707H7.56019L12.0002 6.87298H12.0001Z"
                  fill="#00AC47"></path>
                <path _ngcontent-mho-c7=""
                  d="M20.1788 7.28524L16.6872 1.23705C16.4674 0.852115 16.1512 0.549772 15.78 0.329834L12 6.87284L16.4398 14.5706H23.9863C23.9863 14.1444 23.8763 13.7184 23.6564 13.3334L20.1788 7.28524Z"
                  fill="#FFBA00"></path>
              </svg>
              <p>Google Drive</p>
            </li>
          </ul>
          <input type="file" #referenceQuality name="myfile" (change)="onFileChange($event.target.files)" />
        </div>
        <!-- After upload section-->
        <div class="check-reference" *ngIf="fileSelected">
          <div class="icon">
            <img src="../../../assets/images/file_icons.png" alt="Upload" />
          </div>
          <h3>{{fileName}} </h3>
          <p>Ensure that all your citations are present in the file for an accurate assessment</p>
          <div class="btn-reference">
            <div class="row">
              <div class="col-6">
                <button type="button" (click)="cancelFileSelection()" class="button-cancel" >Cancel</button>
              </div>
              <div class="col-6">
                <button type="button" (click)="startAutoRefQuality()" class="button">Check Citations</button>
              </div>
            </div>
            
          </div>
          <!-- loader-->
          <div class="drag-loader" *ngIf="fileSelected && fileUploaded">
            <svg class="spinner" viewBox="0 0 50 50">
              <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
            <p class="uploading">Uploading file...</p>
          </div>
          <!-- loader ends -->
        </div><!-- After upload section ends-->
      </div>
    </div>
  </div>
</section>

<section class="section-md">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-8">
        <h3 class="x-title">Your reports</h3>
      </div>
      <div class="col-12 col-sm-4">
        <div class="search-input form-group">
          <input type="text" class="form-control" [(ngModel)]="filterModel" placeholder="Search" />
          <span class="search-icon"><i class="fa fa-search"></i></span>
        </div>
      </div>
      <div class="col-12">
        <div class="files-header" *ngIf="refQualityFileList.length != 0">
          <div class="row no-margin">
            <div class="col-5">
              <span>File Name</span>
            </div>
            <div class="col-2 text-center">
              <span>Citations</span>
              <button class="info"
              alt="This shows the number of citations that likely need to be replaced out of the total number of citations.">
              <img src="../../../../assets/images/Icon_Info.svg">
              </button>
            </div>
            <div class="col-2 text-center">
              <span>Score</span>
                <button class="info"
                alt="Overall quality score for your citation list. The higher the score, the better the quality.">
                <img src="../../../../assets/images/Icon_Info.svg">
                </button>
            </div>
            <div class="col-3 text-center">
              <span>Action</span>
            </div>
          </div>
        </div>

        <ng-container >
          <div class="shine-loader" class="{{loaderStatus ? '' : 'shine_loader_hide'}}"> 
            <div class="full-shine">
              <span class="shine lines"></span>
            </div>
            <div class="full-shine">
              <span class="shine lines"></span>
            </div>
            <div class="full-shine">
              <span class="shine lines"></span>
            </div>
            <div class="full-shine">
              <span class="shine lines"></span>
            </div>
          </div>
          <ul class="upload-wrapper" *ngFor=" let file of refQualityFileList | filterByFileName: filterModel" >
            <li class="upload-files" [ngClass]="file.status === 'SUCCESS' && !file.credit_deducted && file.no_of_references>0 ? 'buy-credits' : file.status === 'FAILED' ? 'report-failed' : '' ">
              <div class="row no-margin w-100 align-items-center">
                <div class="col-5">
                  <div class="media">
                    <div class="media-left">
                      <img src="../../../assets/images/file_icons.png" alt="file" />
                    </div>
                    <div class="media-body">
                      <h3>{{ file.filename }}</h3>
                      <p *ngIf="file.status === 'SUCCESS'">Results generated on: {{ file.created_on | date: "medium" }} <span *ngIf="file.status === 'SUCCESS' && file.credit_deducted">| {{ file.credit_req }} credits used</span></p>
                      <p *ngIf="file.status != 'SUCCESS'">Uploaded on: {{ file.created_on | date: "medium" }} </p>
                    </div>
                  </div>
                </div>
                <div class="col-2 text-center" *ngIf="file.status === 'SUCCESS' && file.no_of_references >0 ">
                  <span class="count d-block"> {{file.bait.count}} of {{file.no_of_references}}<br/> at risk </span>
                </div>
                <div class="col-2 text-center" *ngIf="file.status === 'FAILED'">
                  <span class="count d-block"> - </span>
                </div>
                <div class="col-2 text-center" *ngIf="file.status === 'SUCCESS' && file.no_of_references >0">
                    <div class="qualityScoreSection mx-auto">
                      <svg viewBox="0 0 36 36" class="circular-chart" [ngClass]="file.quality_summary.good.percentage>80 ? 'green': 'red'">
                        <path class="circle-bg"
                          d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path class="circle"
                        [attr.stroke-dasharray] = "file.quality_summary.good.percentage  +',100'"
                          d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                          <text x="10" y="22" class="percentage" style="font-size: 11px; font-weight: 600;" *ngIf="file.quality_summary.good.percentage<=9">{{file.quality_summary.good.percentage| number:'.0-0'}}%</text>
                          <text x="9" y="22" class="percentage" style="font-size: 10px;font-weight: 600;" *ngIf="file.quality_summary.good.percentage<=99 && file.quality_summary.good.percentage>9">{{file.quality_summary.good.percentage| number:'.0-0'}}%</text>
                          <text x="8" y="21" class="percentage" style="font-size: 8px;font-weight: 600;" *ngIf="file.quality_summary.good.percentage>=100">{{file.quality_summary.good.percentage| number:'.0-0'}}%</text>
                      </svg>
                    </div>
                </div>
                <div class="col-2 text-center" *ngIf="file.status === 'FAILED'">
                  <div class="qualityScoreSection mx-auto">
                    <svg viewBox="0 0 36 36" class="circular-chart red">
                      <path class="circle-bg"
                        d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                      <path class="circle"
                      stroke-dasharray = "0,100"
                        d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                        <text x="12" y="21" class="percentage" style="font-size: 8px;font-weight: 600;" *ngIf="file.status === 'FAILED'">NA</text>
                    </svg>
                  </div>
              </div>
                <div *ngIf="file.status === 'SUCCESS' && file.credit_deducted && file.no_of_references >0" class="col-3 text-center">
                  <button class="button" (click)="setCurrentFileDetails(file, false)" (click)="viewReport(file.id)" [routerLink]="['/citation-check-report']" [queryParams]="{document_id: file.id}"  target="_blank">View Report</button>
                  <!-- <small>{{ file.credit_req }} credits used</small> -->
                </div>

                <div *ngIf="file.status === 'SUCCESS' && !file.credit_deducted && file.no_of_references >0" class="col-3 text-center">
                  <button class="button-filled" data-toggle="modal" (click)="setCurrentFileDetails(file, true)" [attr.data-target]="file.credit_req <= totalCredit? '#checkReferences' : '#insuffecientModal' ">Buy Report for<br/>{{file.credit_req}} Credits</button>
                </div>

                <!-- IN PROGRESS LOADER-->
                <div class="col-7"   *ngIf="(file.status !== 'FAILED' && file.status !== 'SUCCESS' && file.status !== 'OVER_LIMIT' && file.status !== 'WAITING_IN_QUEUE' && file.status !== 'ON_HOLD')" >
                  <div class="inprogress_file">
                    <div class="checkingloader">
                          <div class='loader--text'></div>
                          <div class='loader--dot'></div>
                          <div class='loader--dot'></div>
                          <div class='loader--dot'></div>
                      </div>
                      <p>In progress</p>
                  </div>
                </div>

                <div class="col-3 offset-4"  *ngIf="file.status === 'WAITING_IN_QUEUE'">
                    <p class="in-queued">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                                stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8 4V8L10 10" stroke="#4D4D4D" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                        Queued
                    </p>
                </div>

                <div class="col-3 text-center" *ngIf="file.status === 'FAILED'">
                  <button class="button" (click)="reUploadFile(file)">
                      Report generation failed.<br> Click to Retry
                  </button>
                </div>

                <div class="col-7" *ngIf="file.status === 'SUCCESS' && file.no_of_references ==0">
                  <span class="no-reference-found">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.0611 14.5816L9.10189 0.806122C8.98327 0.601403 8.77855 0.5 8.57192 0.5C8.36528 0.5 8.15865 0.601403 8.04194 0.806122L0.0827553 14.5816C-0.152576 14.9911 0.142066 15.5 0.61273 15.5H16.5311C17.0018 15.5 17.2964 14.9911 17.0611 14.5816ZM7.95967 6.16327C7.95967 6.07908 8.02855 6.0102 8.11273 6.0102H9.0311C9.11528 6.0102 9.18416 6.07908 9.18416 6.16327V9.68367C9.18416 9.76786 9.11528 9.83673 9.0311 9.83673H8.11273C8.02855 9.83673 7.95967 9.76786 7.95967 9.68367V6.16327ZM8.57192 12.898C8.3316 12.8931 8.10277 12.7941 7.93455 12.6224C7.76633 12.4508 7.6721 12.22 7.6721 11.9796C7.6721 11.7392 7.76633 11.5084 7.93455 11.3367C8.10277 11.165 8.3316 11.0661 8.57192 11.0612C8.81224 11.0661 9.04106 11.165 9.20928 11.3367C9.37751 11.5084 9.47173 11.7392 9.47173 11.9796C9.47173 12.22 9.37751 12.4508 9.20928 12.6224C9.04106 12.7941 8.81224 12.8931 8.57192 12.898Z" fill="#D42D27"/>
                    </svg>
                    No citations were found for this file. Please recheck.</span>
                </div>

              </div>
            </li>
          </ul>
        </ng-container>

        <ng-container *ngIf="refQualityFileList.length === 0">
          <ul class="no_file_search_section" style="border:none">
            <li>
              <p style="color: grey;"><i>No reports</i></p>
            </li>
          </ul>
        </ng-container>

        <ng-container *ngIf="(refQualityFileList | filterByFileName: filterModel).length <= 0 && refQualityFileList.length > 0">
          <ul class="no_file_search_section">
            <li>
              <p>
                Sorry, we couldn't find any file named "{{ filterModel }}". Please try a different search.
              </p>
            </li>
          </ul>
        </ng-container>

      </div>
    </div>
  </div>
</section>

<!-- Check References Modal -->
<div class="modal" id="checkReferences">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <div class="cs-modal-content">
          <div class="icon">
            <img src="../../../../assets/images/reference-quality/check.svg" width="90" height="90" alt="check" />
          </div>
          <h3>Do you wish to buy report?</h3>
          <p>File Name: {{selectedFileName}}</p>
          <a (click)="buyReport()" class="md-btn" data-dismiss="modal">Yes, use {{selectedCreditReq}} credits</a>
          <div class="mt-3 text-center">
            <a href="javascript:void(0)" (click)="cancelBuyReport()" class="cancel-link" data-dismiss="modal">Cancel</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Insuffecient credits Modal -->
<div class="modal" id="insuffecientModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <div class="cs-modal-content">
          <div class="icon">
            <span>
              <img src="../../../../assets/images/reference-quality/cancel.svg" width="40" height="40" alt="cancel" />
            </span>
          </div>
          <h3>Insufficient Credits</h3>
          <p>You do not have enough credits to buy this report. Please add credits to view this report.</p>
          <a (click)="addCredits()" class="md-btn" data-dismiss="modal">Add Credits</a>
          <div class="mt-3 text-center">
            <a href="javascript:void(0)" (click)="logCancelInsuffeicent()" class="cancel-link" data-dismiss="modal">Cancel</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>