<div class="trinkaUpdateModal">
    <div class="trinkaUpdateHeader">
        <h2>Important updates on the Trinka Cloud Editor</h2>
        <!-- (click)="closeModal(null)" -->
        <button style="display: none;" (click)="closeModal(true)">
            <img src="../../../../assets/images/close-black.svg" alt="">
        </button>
    </div>
    <div class="trinkaUpdateBodySection">
        <div class="svgRightSide">
            <svg fill="none" height="557" viewBox="0 0 180 557" width="180" xmlns="http://www.w3.org/2000/svg"
                data-gtm-vis-recent-on-screen13498092_497="7065" data-gtm-vis-first-on-screen13498092_497="7065"
                data-gtm-vis-total-visible-time13498092_497="100" data-gtm-vis-has-fired13498092_497="1">
                <g filter="url(#filter0_f_578_32172)" opacity="0.5" data-gtm-vis-first-on-screen13498092_497="18009"
                    data-gtm-vis-total-visible-time13498092_497="100" data-gtm-vis-has-fired13498092_497="1">
                    <circle cx="362" cy="195" fill="#CF7CE2" r="162" data-gtm-vis-first-on-screen13498092_497="18010"
                        data-gtm-vis-total-visible-time13498092_497="100" data-gtm-vis-has-fired13498092_497="1">
                    </circle>
                </g>
                <defs data-gtm-vis-polling-id13498092_497="3477">
                    <filter color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse" height="724"
                        id="filter0_f_578_32172" width="724" x="0" y="-167" data-gtm-vis-polling-id13498092_497="3478">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"
                            data-gtm-vis-polling-id13498092_497="3479"></feFlood>
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape"
                            data-gtm-vis-polling-id13498092_497="3480"></feBlend>
                        <feGaussianBlur result="effect1_foregroundBlur_578_32172" stdDeviation="100"
                            data-gtm-vis-polling-id13498092_497="3481"></feGaussianBlur>
                    </filter>
                </defs>
            </svg>
        </div>
        <p><strong>We are excited to launch the latest and most advanced version of the Trinka Cloud Editor!</strong> With this new version, you can write, copy and paste text, accept or reject suggestions, and apply formatting with greater ease.</p>
        <div class="" *ngIf="!data.isNewEditor" style=" margin-top: 24px;">
            <h3>How to Access It?</h3>
            <p style="margin-top: 10px; margin-bottom: 24px;">To access the new version (Version 2) of the Cloud Editor, simply upload or create a new file on Trinka. Starting from 28/Jun/24, any newly uploaded or created files will automatically open in the latest version of the editor. Existing files, i.e., those created before 28/Jun/24, will continue to open in the previous version of the editor (Version 1).</p>
            <h3>What Will Be the Impact?</h3>
            <p style="margin-top: 10px;">There is no impact on you. Both versions, V1 and V2, of the editor will be available on Trinka. However, for more formatting options, better performance, and an enhanced writing experience, we encourage you to use Version 2 of the Cloud Editor. You can easily do this by downloading your current files and re-uploading them to the cloud editor.</p>
        </div>
        <h3 style="margin-top: 24px;">What's New and Improved?</h3>
        <!-- <ul>
            <li>Intuitive experience with grammar suggestions.</li>
            <li>Enhanced formatting options.</li>
            <li>Retained formatting on upload/download.</li>
            <li>Reliable copy and paste output.</li>
            <li>Improved equations handling/display.</li>
            <li>No large file glitches</li>
        </ul> -->
        <ul>
            <li><span>Improved Document Formatting:</span> The new Cloud Editor offers more formatting options to achieve your desired presentation. Additionally, we have fixed inconsistent line spacing and text formatting issues, and we have made necessary improvements so that your document's formatting is preserved while uploading and downloading files.</li>
            <li><span>More Reliable Copy and Paste:</span> Responding to user feedback, we have further improved copy and paste functionality, making the process error-free while preserving almost the entire formatting.</li>
            <li><span>Better Math Equations Handling:</span> We have improved the display of mathematical equations for clearer, more accurate representation. This benefits technical and academic writing, ensuring effective equation handling.</li>
            <li><span>Improved Performance:</span> We have optimized the performance for handling large files, enabling you to work with documents up to 15,000 words smoothly. Please note that for files with a word count greater than 15,000 words, we encourage you to use the Proofread File feature.</li>
            <!-- <li><span>Better Math Equations Handling:</span> Work with mathematical equations more effectively.</li>
            <li><span>Improved Performance:</span> Handle large files smoothly without glitches.</li> -->
        </ul>
        <!-- <h3 style="margin-top: 24px;">Impact:</h3> -->
        <p style="margin-top: 24px;">We hope these updates enhance your writing experience. Keep sharing your feedback to help us serve you better!</p>
    </div>
    <div class="trinkaUpdateFooterSection">
        <button (click)="closeModal(true)">Okay</button>
    </div>
</div>