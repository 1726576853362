import { ApplicationRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-dashboard-file-upload-selection-modal',
  templateUrl: './dashboard-file-upload-selection-modal.component.html',
  styleUrls: ['./dashboard-file-upload-selection-modal.component.scss']
})
export class DashboardFileUploadSelectionModalComponent implements OnInit {

  fileName: string;
  disableAutoeditBtn:boolean = true;
  upload_source: string;
  constructor(public dialogRef: MatDialogRef<DashboardFileUploadSelectionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appRef: ApplicationRef,
    public amplitudeService: AmplitudeService,
    ) { 
      this.fileName = data["file_name"];
      this.upload_source = data["upload_source"];

      if(!this.fileName.endsWith(".txt")){
        this.disableAutoeditBtn = false;
      }
      this.dialogRef.disableClose = true;
      this.appRef.tick();
    }

  ngOnInit() {
  }

  close(){
      this.dialogRef.close();
      this.appRef.tick();
  }

  selectUpload(val){
      if(val === "AutoEdit"){
        this.amplitudeService.logAmplitude('FileUpload_AutoEdit',{
          "Upload_Via": this.upload_source
        });
      }
      else{
        this.amplitudeService.logAmplitude('FileUpload_ManualEdit',{
          "Upload_Via": this.upload_source
        });
      }
      this.dialogRef.close({data :val});
      this.appRef.tick();    
  }
}
