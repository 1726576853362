import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit {
  grammar: boolean = false;
  spelling: boolean = false;
  enhancement: boolean = false;
  advisor: boolean = false;
  master_checked: boolean = true;
  master_indeterminate: boolean = false;
  
  constructor(public sharedService: SharedService,) { 
    // this.sharedService.filterCheckList = [
    //   {
    //     name: "Grammar",
    //     disabled: false,
    //     checked: false,
    //     labelPosition: "after",
    //     errorCount: 0
    //   }, {
    //     name: "Spelling",
    //     disabled: false,
    //     checked: false,
    //     labelPosition: "after",
    //     errorCount: 0
    //   }, {
    //     name: "Enhancements",
    //     disabled: false,
    //     checked: false,
    //     labelPosition: "after",
    //     errorCount: 0
    //   }, {
    //     name: "Writting Advisor",
    //     disabled: false,
    //     checked: false,
    //     labelPosition: "after",
    //     errorCount: 0
    //   }
    // ]

  }

  ngOnInit() {
  }


  master_change() {
    for (let value of Object.values(this.sharedService.filterCheckList)) {
      value.checked = this.master_checked;
    }
  }
 
  list_change(){
    let checked_count = 0;
    //Get total checked items
    for (let value of Object.values(this.sharedService.filterCheckList)) {
      if(value.checked)
      checked_count++;
    }
 
    if(checked_count>0 && checked_count<this.sharedService.filterCheckList.length){
      // If some checkboxes are checked but not all; then set Indeterminate state of the master to true.
      this.master_indeterminate = true;
    }else if(checked_count == this.sharedService.filterCheckList.length){
      //If checked count is equal to total items; then check the master checkbox and also set Indeterminate state to false.
      this.master_indeterminate = false;
      this.master_checked = true;
    }else{
      //If none of the checkboxes in the list is checked then uncheck master also set Indeterminate to false.
      this.master_indeterminate = false;
      this.master_checked = false;
    }
  }
}
