import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditorRoutingModule } from './editor-routing.module';
import { EditorComponent } from './editor.component';
import { ManageProfileComponent } from 'src/app/components/dashboard/manage-profile/manage-profile.component';
import { MydriveComponent } from 'src/app/components/dashboard/mydrive/mydrive.component';
import { MainComponent } from 'src/app/components/editor/main/main.component';
import { MainComponentNew } from 'src/app/components/editor/main-new/main.component';
import { AuthService } from 'src/app/_guards/auth.service';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { NgxSpinnerModule } from 'ngx-spinner';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConnectionService } from 'src/app/_socket/connection.service';

import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModuleList } from 'src/app/material-modules';
import { AuthInterceptor } from 'src/app/_services/interceptors/token.interceptor'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FilterByCategoryPipe } from 'src/app/_pipes/filter-by-category.pipe';
import { CCFilterByCategoryPipe } from 'src/app/_pipes/ccfilter-by-category.pipe';
import { FilterModalComponent } from 'src/app/components/editor/main/filter-modal/filter-modal.component';
import { FilterModalComponent as FilterModalComponentNew } from 'src/app/components/editor/main-new/filter-modal/filter-modal.component';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { DocumentTypeComponent } from 'src/app/components/document-type/document-type.component';
import { CustomDatePipe } from 'src/app/_pipes/custom-date.pipe';
import { EditorLanguageSelectionComponent } from 'src/app/components/editor/editor-language-selection/editor-language-selection.component';
import { EditorInternetConnectivityComponent } from 'src/app/components/editor/editor-internet-connectivity/editor-internet-connectivity.component';
import { EditorAlertsComponent } from 'src/app/components/editor/editoralerts/editor-alerts.component';
import { EditorSpinnerComponent } from 'src/app/components/editor/editor-spinner/editor-spinner.component';
import { CkEditorComponent } from 'src/app/components/editor/ck-editor/ck-editor.component';
import { JournalScopeMatchComponent } from 'src/app/components/author-one/journal-scope-match/journal-scope-match.component';
import { TechnicalComplianceComponent } from 'src/app/components/author-one/technical-compliance/technical-compliance.component';
import { EthicalComplianceComponent } from 'src/app/components/author-one/ethical-compliance/ethical-compliance.component';
import { AuthorshipCheckComponent } from 'src/app/components/author-one/authorship-check/authorship-check.component';
import { ReferenceCheckComponent } from 'src/app/components/author-one/reference-check/reference-check.component';
import { ClinicalTrialCheckComponent } from 'src/app/components/author-one/clinical-trial-check/clinical-trial-check.component';
import { FigureTableCheckComponent } from 'src/app/components/author-one/figure-table-check/figure-table-check.component';
import { KeywordsSummaryComponent } from 'src/app/components/author-one/keywords-summary/keywords-summary.component';
import { ReferenceCheckDialogComponent } from 'src/app/components/author-one/reference-check/reference-check-dialog/reference-check-dialog.component';
import { FigureTableDialogComponent } from 'src/app/components/author-one/figure-table-check/figure-table-dialog/figure-table-dialog.component';
import { ClipboardModule } from 'ngx-clipboard';
import { TechnicalComplianceDialogComponent } from 'src/app/components/author-one/technical-compliance/technical-comliance-dialog/technical-comliance-dialog.component';
import { DictionaryModalComponent } from 'src/app/components/editor/main/dictionary-modal/dictionary-modal.component';
import { DictionaryModalComponent  as DictionaryModalComponentNew} from 'src/app/components/editor/main-new/dictionary-modal/dictionary-modal.component';
import { UpgradeToPremiumComponent } from 'src/app/components/editor/upgrade-to-premium/upgrade-to-premium.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PowerModeComponent } from 'src/app/components/editor/power-mode/power-mode.component';
import { FreeTrialModelComponent } from 'src/app/components/editor/power-mode/free-trial-model/free-trial-model.component';
import { CountdownModule } from '@ciri/ngx-countdown';
import { EditorPlagiarismModalComponent } from 'src/app/components/editor/editor-plagiarism-modal/editor-plagiarism-modal.component';
import { SaveNewFileDetailsModalComponent } from 'src/app/components/editor/main/save-new-file-details-modal/save-new-file-details-modal.component';
import { SaveNewFileDetailsModalComponent as SaveNewFileDetailsModalComponentNew } from 'src/app/components/editor/main-new/save-new-file-details-modal/save-new-file-details-modal.component';
import { EditorReferenceQualityCheckModalComponent } from 'src/app/components/editor/editor-reference-quality-check-modal/editor-reference-quality-check-modal.component';
import { CkeditorComponent } from 'src/app/components/editor/main-new/ckeditor-component/ckeditor-component.component';

import { CustomNgxCkeditorComponent } from 'src/app/_directives/custom-ngx-ckeditor.component';
import { EditorJournalFinderModalComponent } from 'src/app/components/editor/editor-journal-finder-modal/editor-journal-finder-modal.component';
import { FeedbackModalComponent } from 'src/app/components/feedback-modal/feedback-modal.component';
import { ParaphraserComponent } from 'src/app/components/editor/main/paraphraser/paraphraser.component';
import { EditorPlagCheckReportViewerComponent } from 'src/app/components/editor/editor-plag-check-report-viewer/editor-plag-check-report-viewer.component';
import { EditorAdvancePlagCheckCreditModalComponent } from 'src/app/components/editor/editor-advance-plag-check-credit-modal/editor-advance-plag-check-credit-modal.component';
import { DocumentAndStyleModalComponent } from 'src/app/components/editor/document-and-style-modal/document-and-style-modal.component';
import { DocumentLegalInfoModalComponent } from 'src/app/components/editor/document-legal-info-modal/document-legal-info-modal.component';
import { EditorAlertSortPipe } from 'src/app/_pipes/editor-alert-sort.pipe';
import { FilterByInclusiveCategoryPipe } from 'src/app/_pipes/filter-by-inclusive-category.pipe';
import { ConfirmAccessTrinkaComponent } from '../shared/components/confirm-access-trinka/confirm-access-trinka.component';
import { StartTrinkaLabsModalComponent } from '../shared/components/start-trinka-labs-modal/start-trinka-labs-modal.component';
import { SecondsLoaderModalComponent } from '../shared/components/seconds-loader-modal/seconds-loader-modal.component';
import { TrinkaUpdatesComponent } from '../shared/components/trinka-updates/trinka-updates.component';
import { PowerModeEditingComponent } from '../shared/components/power-mode-editing/power-mode-editing.component';
import { CKEditorScriptLoader } from './ckeditor-script-loader';


@NgModule({
  declarations: [
    EditorComponent,
    MainComponent,
    MainComponentNew,
    FilterByCategoryPipe,
    EditorAlertSortPipe,
    FilterByInclusiveCategoryPipe,
    CCFilterByCategoryPipe,
    FilterModalComponent,
    FilterModalComponentNew,
    CustomDatePipe,
    DocumentTypeComponent,
    EditorAlertsComponent,
    EditorLanguageSelectionComponent,
    EditorInternetConnectivityComponent,
    CkEditorComponent,
    EditorSpinnerComponent,
    JournalScopeMatchComponent,
    TechnicalComplianceComponent,
    EthicalComplianceComponent,
    AuthorshipCheckComponent,
    ReferenceCheckComponent,
    ClinicalTrialCheckComponent,
    FigureTableCheckComponent,
    KeywordsSummaryComponent,
    ReferenceCheckDialogComponent,
    FigureTableDialogComponent,
    TechnicalComplianceDialogComponent,
    DictionaryModalComponent,
    DictionaryModalComponentNew,
    UpgradeToPremiumComponent,
    PowerModeComponent,
    FreeTrialModelComponent,
    EditorPlagiarismModalComponent,
    EditorJournalFinderModalComponent,
    SaveNewFileDetailsModalComponent,
    SaveNewFileDetailsModalComponentNew,
    EditorReferenceQualityCheckModalComponent,
    CustomNgxCkeditorComponent,
    FeedbackModalComponent,
    ParaphraserComponent,
    EditorPlagCheckReportViewerComponent,
    EditorAdvancePlagCheckCreditModalComponent,
    DocumentAndStyleModalComponent,
    DocumentLegalInfoModalComponent,
    ConfirmAccessTrinkaComponent,
    StartTrinkaLabsModalComponent,
    SecondsLoaderModalComponent,
    TrinkaUpdatesComponent,
    CkeditorComponent,
    PowerModeEditingComponent
  ],
  imports: [
    CommonModule,
    EditorRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModuleList,
    NgbModule,
    ReactiveFormsModule,
    ClipboardModule,
    ScrollingModule,
    CountdownModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    CKEditorScriptLoader,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
    },
    JwtHelperService,
    ConnectionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ], schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ], entryComponents: [
    //LanguagePreferencesComponent,
    FeedbackModalComponent,
    FilterModalComponent,
    FilterModalComponentNew,
    DocumentTypeComponent,
    ReferenceCheckDialogComponent,
    FigureTableDialogComponent,
    TechnicalComplianceDialogComponent,
    DictionaryModalComponent,
    DictionaryModalComponentNew,
    UpgradeToPremiumComponent,
    FreeTrialModelComponent,
    EditorPlagiarismModalComponent,
    SaveNewFileDetailsModalComponent,
    SaveNewFileDetailsModalComponentNew,
    EditorReferenceQualityCheckModalComponent,
    EditorJournalFinderModalComponent,
    EditorPlagCheckReportViewerComponent,
    EditorAdvancePlagCheckCreditModalComponent,
    DocumentAndStyleModalComponent,
    DocumentLegalInfoModalComponent,
    ConfirmAccessTrinkaComponent,
    StartTrinkaLabsModalComponent,
    SecondsLoaderModalComponent,
    TrinkaUpdatesComponent,
    PowerModeEditingComponent
  ],
  exports:[CustomDatePipe, FilterByCategoryPipe,CCFilterByCategoryPipe, EditorAlertSortPipe, FilterByInclusiveCategoryPipe]
})
export class EditorModule { }
