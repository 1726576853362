import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'regionFilter',
  pure: false
})
export class RegionPipe implements PipeTransform {

  transform(items: any, filter: any): any {
    let currentfilter = filter["Geography"].data;

    if (!items) return [];
    
    if (currentfilter.length == 0) return items;

    return items.filter(result => {return currentfilter.includes(result.geography)});
  }

}
