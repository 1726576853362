<div class="advancedPlagCheckPopup">
    <button class="poppClosedButton" (click)="close_modal()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4139_106245)">
            <path d="M18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5858L6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L13.4142 12L18.7071 6.70711Z" fill="#414E62"/>
            </g>
            <defs>
            <clipPath id="clip0_4139_106245">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    </button>
    <div class="advancedPlagCheckBodySection" *ngIf="!isCreditBased">
        <div class="popupLogSections">
            <img src="../../../../assets/images/Trinka_Logo.svg" alt="">
        </div>
    
        <h2>Check Plagiarism</h2>
        <div class="upgradToPremiumSection">
            <p>Our Premium plus users get unlimited* access to plagiarism check</p>
            <!-- <p>Our Premium plus users get unlimited* access to plagiarism check with AI content detection</p> -->
            <button (click)="upgradePlanPopup()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.8731 12.076L20.2004 6.87599C20.0543 6.66994 19.8076 6.54565 19.5437 6.54565H6.74946C6.48536 6.54565 6.23882 6.66994 6.09281 6.87599L2.42004 12.076C2.22406 12.3524 2.25022 12.717 2.48367 12.9656L12.5541 23.7483C12.7028 23.9086 12.9187 24.0002 13.1461 24.0002C13.3735 24.0002 13.5894 23.9086 13.7389 23.7476L23.8093 12.9648C24.042 12.717 24.0681 12.3524 23.8731 12.076ZM8.69833 13.2181L11.1391 19.9969L4.80789 13.2181H8.69833ZM14.962 8.01809L16.0174 11.7465H10.2729L11.3283 8.01809H14.962ZM15.9317 13.2181L13.1457 20.958L10.3597 13.2181H15.9317ZM17.5932 13.2181H21.4838L15.1527 19.9969L17.5932 13.2181ZM21.7532 11.7457H17.6467L16.5913 8.01729H19.1202L21.7532 11.7457ZM7.17206 8.01804H9.70097L8.64556 11.7464H4.5381L7.17206 8.01804Z" fill="#92400E"/>
                    <path d="M5.71763 -0.000244141L6.64366 2.38855L9.14621 3.27248L6.64366 4.15642L5.71763 6.54521L4.79161 4.15642L2.28906 3.27248L4.79161 2.38855L5.71763 -0.000244141Z" fill="#92400E"/>
                    <path d="M2.28571 5.45386L2.90306 7.04639L4.57143 7.63568L2.90306 8.22496L2.28571 9.81749L1.66836 8.22496L0 7.63568L1.66836 7.04639L2.28571 5.45386Z" fill="#92400E"/>
                </svg>
                Upgrade to Premium Plus
            </button>
        </div>
        <div class="PlagcheckPopupfooter">
            <span>or</span>
            <a class="CreateRenameButton" (click)="submit()">I want to check plagiarism with credits</a>
        </div>
    </div>

    <div class="plagCheckBuyCredits"  *ngIf="isCreditBased">
        <div class="plagCheckBuyCreditsLeft">
            <img src="../../../../assets/images/BuyCreditPlagCheck.svg" alt="">
        </div>
        <div class="plagCheckBuyCreditsRight">
            <div class="popupLogSections">
                <img src="../../../../assets/images/Trinka_Logo.svg" alt="">
            </div>
            <h2>Advanced Plagiarism check</h2>
            <p>The Advanced check report will include - <img src="../../../../assets/images/SearchPlagCheck.svg" alt=""> </p>
            <ul>
                <li>1. Report powered by <img src="../../../../assets/images/ithentication_img.svg" alt=""> </li>
                <li>2. Checks against the <span>paid sources.</span> </li>
                <li>3. Largest and most updated database of paid and open source articles</li>
            </ul>
            <div *ngIf="totalCredits<18">
                <h4>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0001 1.66675C5.40841 1.66675 1.66675 5.40841 1.66675 10.0001C1.66675 14.5917 5.40841 18.3334 10.0001 18.3334C14.5917 18.3334 18.3334 14.5917 18.3334 10.0001C18.3334 5.40841 14.5917 1.66675 10.0001 1.66675ZM9.37508 6.66675C9.37508 6.32508 9.65842 6.04175 10.0001 6.04175C10.3417 6.04175 10.6251 6.32508 10.6251 6.66675V10.8334C10.6251 11.1751 10.3417 11.4584 10.0001 11.4584C9.65842 11.4584 9.37508 11.1751 9.37508 10.8334V6.66675ZM10.7667 13.6501C10.7251 13.7584 10.6667 13.8417 10.5917 13.9251C10.5084 14.0001 10.4167 14.0584 10.3167 14.1001C10.2167 14.1417 10.1084 14.1667 10.0001 14.1667C9.89175 14.1667 9.78341 14.1417 9.68342 14.1001C9.58342 14.0584 9.49175 14.0001 9.40841 13.9251C9.33342 13.8417 9.27508 13.7584 9.23342 13.6501C9.19175 13.5501 9.16675 13.4417 9.16675 13.3334C9.16675 13.2251 9.19175 13.1167 9.23342 13.0167C9.27508 12.9167 9.33342 12.8251 9.40841 12.7417C9.49175 12.6667 9.58342 12.6084 9.68342 12.5667C9.88342 12.4834 10.1167 12.4834 10.3167 12.5667C10.4167 12.6084 10.5084 12.6667 10.5917 12.7417C10.6667 12.8251 10.7251 12.9167 10.7667 13.0167C10.8084 13.1167 10.8334 13.2251 10.8334 13.3334C10.8334 13.4417 10.8084 13.5501 10.7667 13.6501Z" fill="#DC2626"/>
                    </svg>
                    This report requires 18 credits, you only need {{18-totalCredits}} more to proceed. Buy credits below!
                </h4>
                <a (click)="addCredits()">Buy Credits</a>
            </div>
            <div *ngIf="totalCredits>=18">
                <a (click)="submit()">Generate report for 18 credits</a>
            </div>
            
        </div>
    </div>
</div>