import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'app-afe-info-modal',
  templateUrl: './afe-info-modal.component.html',
  styleUrls: ['./afe-info-modal.component.scss']
})
export class AfeInfoModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AfeInfoModalComponent>,) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
