import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class JournalFinderService {
  constructor(public http: HttpClient) {
    
  }
  

  fetchJournalData(payload: any) {
    const headers = new HttpHeaders();
    headers.set('Cache-Control','private');
    return this.http.post(environment.journal_finder_service + "/api/v1/fetch-journal-article",payload,{headers: headers});
  }

  fetchArticleData(payload: any) {
    const headers = new HttpHeaders();
    headers.set('Cache-Control','private');
    return this.http.post(environment.journal_finder_service + "/api/v1/fetch-articles",payload,{headers: headers});
  }

  fetchArticleAbstract(doc_id: any) {
    return this.http.get("https://services.raxter.io/literature/record/" + doc_id);
  }

  fetchSimilarArticle(payload){
    return this.http.post(environment.journal_finder_service + "/api/v1/fetch-articles", payload);
  }

  renewExpiredReport(payload){
    return this.http.post(environment.journal_finder_service + "/api/v1/renew-report", payload);
  }

  fetchProcessedAbstractList(user_id){
    return this.http.get(environment.journal_finder_service + "/api/v1/fetch-reports/"+user_id);
  }

  getAbstractReport(user_id, file_id){
    return this.http.get(environment.journal_finder_service + "/api/v1/fetch-reports/"+user_id+"/id/"+file_id);
  }
}