<mat-list class="row headerBoxShadow ManageProfilemobileHeader">
    <div class="col-md-12 top_new_header">
        <div class="page_top_header">
            <h1 class="mat-display-1 mb-0">My Dictionary</h1>
        </div>
    </div>
</mat-list>

<div class="dictinary_sections">
    <div class="top_text_sections">
        <p>When you add terms to your dictionary, Trinka ignores these as spelling errors.</p>
        <p>Your dictionary is only yours. No one apart from you can see it.</p>
    </div>
    <div class="search_filter_sections">
        <div class="searchbox_sections">
            <form action="">
                <input type="text" class="form-control" onfocus="placeholder = ''"
                    onblur="placeholder = 'Add new word(s) to your personal dictionary'"
                    placeholder="Add new words to your personal dictionary" name="addWord" [(ngModel)]="dicTextVal">
                <button (click)="onClickAdd()" [disabled]="dicTextVal ==''"> <i class="fa fa-plus"  aria-hidden="true"></i> Add</button>
            </form>
            <p>Use <span>a comma</span> ( , ) to add more than one word</p>
        </div>
    </div>


    <div class="search_filter_sections mydictionary_filter_section">
        <div class="filter_sections dropdown">
            <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="button"> <i
                    class="fa fa-filter" aria-hidden="true"></i> Filter</button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <p class="searchHeading">Search</p>
                <div class="searchBox_findbox">
                    <img src="../../../../assets/images/search_icon.svg" alt="">
                    <input type="text" class="Searchbox" placeholder="Type here..." [(ngModel)]="searchByWord"
                        class="form-control">
                </div>
                <p class="searchHeading">Sort By</p>
                <div class="filter_options">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="sortByDate">
                        <mat-radio-button [value]="true">Alphabetical Order</mat-radio-button>
                        <mat-radio-button [value]="false">Recently Added</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
    <div class="list_of_word_sections">
        <ul>
            <li *ngFor="let word of dicWordList | filterByWord: searchByWord| sortByDate: sortByDate"
                title="{{ word.word }}">
                {{word.word}} &nbsp;
                <span class="fa-editButtons" title="Edit" (click)="editWord(word)">
                    <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1"
                        style="enable-background:new 0 0 348.882 348.882;" version="1.1" viewBox="0 0 348.882 348.882"
                        x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
                        <g>
                            <path d="M333.988,11.758l-0.42-0.383C325.538,4.04,315.129,0,304.258,0c-12.187,0-23.888,5.159-32.104,14.153L116.803,184.231
                        c-1.416,1.55-2.49,3.379-3.154,5.37l-18.267,54.762c-2.112,6.331-1.052,13.333,2.835,18.729c3.918,5.438,10.23,8.685,16.886,8.685
                        c0,0,0.001,0,0.001,0c2.879,0,5.693-0.592,8.362-1.76l52.89-23.138c1.923-0.841,3.648-2.076,5.063-3.626L336.771,73.176
                        C352.937,55.479,351.69,27.929,333.988,11.758z M130.381,234.247l10.719-32.134l0.904-0.99l20.316,18.556l-0.904,0.99
                        L130.381,234.247z M314.621,52.943L182.553,197.53l-20.316-18.556L294.305,34.386c2.583-2.828,6.118-4.386,9.954-4.386
                        c3.365,0,6.588,1.252,9.082,3.53l0.419,0.383C319.244,38.922,319.63,47.459,314.621,52.943z"
                                style="fill: #7a28a0"></path>
                            <path d="M303.85,138.388c-8.284,0-15,6.716-15,15v127.347c0,21.034-17.113,38.147-38.147,38.147H68.904
                      c-21.035,0-38.147-17.113-38.147-38.147V100.413c0-21.034,17.113-38.147,38.147-38.147h131.587c8.284,0,15-6.716,15-15
                      s-6.716-15-15-15H68.904c-37.577,0-68.147,30.571-68.147,68.147v180.321c0,37.576,30.571,68.147,68.147,68.147h181.798
                      c37.576,0,68.147-30.571,68.147-68.147V153.388C318.85,145.104,312.134,138.388,303.85,138.388z"
                                style="fill: #4d4d4d"></path>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                    </svg>
                </span>
                <span class="fa-trashButton" title="Delete" (click)="deleteWord(word.word)"><img
                        src="../../../../assets/images/Icon_Delete.svg" alt=""></span>
            </li>
        </ul>
    </div>
