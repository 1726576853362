<mat-list class="row headerBoxShadow ManageProfilemobileHeader">
    <div class="col-md-12 top_new_header">
        <div class="page_top_header">
            <h1 class="mat-display-1 mb-0">Citation Checker</h1>
            <div *ngIf="creditsDetails" class="AutoEditCreditDetails">
                <img src="../../../../assets/images/Credits_icon.svg" alt="" />
                Credits:
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits != 0"
                    (click)="credits()">{{ creditsDetails.credits + creditsDetails.purchased_credits }}</span>
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0"
                    (click)="credits()">Nil</span>
                <span *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0"
                class="autoEditBuyOption" (click)="addCredits()">Buy</span>
            </div>
        </div>
    </div>
</mat-list>

<section class="reference-wrapper">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12">
                
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="left-img">
                    <img src="../../../../../assets/images/Reference-Quality-Check-copy.png" class="img-fluid" alt="image" />
                    
                </div>
            </div>
            <div class="col-lg-6">
                <!-- <div class="home-badge">
                    <div class="icon"><img src="../../../assets/images/early-access.svg" class="premium-upgrade"></div>
                    <p class="premium-user">for Premium users</p>
                </div> -->
                <!-- <div class="p-user">Early access for premium users</div> -->
                <h1 class="reference-title">Strengthen your research argumentation </h1>
                
                <ul class="icon-list">
                    <li>Get a quality score and a detailed report from a comprehensive assessment of all your citations for over 5 quality metrics for credibility and visibility.</li>
                    <li>See the quality score for free and only pay for the full report when needed.</li>
                </ul>
                <div class="button-gp">
                    <a class="linker-btn" (click)="openSampleReport()">See Sample Report</a>
                    <a class="upload-file-btn button" (click)="startReferenceQuality()">Check for Free</a>
                    <!-- <a class="upload-file-btn button" *ngIf="creditsDetails?.user_type!='PREMIUM'" (click)="upgradePlanPopup()">Upgrade to Premium</a> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h1 class="reference-title mb-10">How does Trinka citation checker help you?</h1>
            </div>
        </div>
        <div class="row match-box">
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <span class="badge high-risk">High Risk</span>
                    <h3>Eliminate retracted citations</h3>
                    <p>Identify studies that have been recalled after publication and avoid peer review critique.</p>
                </div>
            </div>
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <span class="badge high-risk">High Risk</span>
                    <h3>Identify non-reviewed publications</h3>
                    <p>Improve your citation credibility by eliminating non-peer-reviewed works.</p>
                </div>
            </div>
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <span class="badge medium-risk">Medium Risk</span>
                    <h3>Avoid low visibility citations</h3>
                    <p>Identify citations that have not been frequently cited by other authors to avoid weak argumentation.</p>
                </div>
            </div>
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <span class="badge low-risk">Low Risk</span>
                    <h3>Avoid biases towards one or more journals</h3>
                    <p>Identify unintentional bias toward a journal and avoid peer review critique by diversifying your citation sources.</p>
                </div>
            </div>
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <span class="badge medium-risk">Medium Risk</span>
                    <h3>Avoid citations published long ago</h3>
                    <p>Easily identify papers published long ago to ensure high relevance and validity of your citations.</p>
                </div>
            </div>
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <h3>Good-to-use citations</h3>
                    <p>Highlights citations with no potential issues that can weaken your argumentation.</p>
                </div>
            </div>

        </div>

        <div class="row mt-50">
            <div class="col-12">
                <h1 class="reference-title mb-10">Why choose Trinka citation checker?</h1>
            </div>
        </div>

        <div class="row match-box">
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <h3>Free score overview</h3>
                    <p>Generate citation analysis score for free and only pay for the report when needed. </p>
                </div>
            </div>
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <h3>Crossref validation</h3>
                    <p>All your citations are validated against Crossref's extensive publication repository trusted by publishers and organizations worldwide.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="video">
                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/XBuFdCM3xtk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</section>