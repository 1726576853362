import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-plagiasrim-ithenticate-sample-report-modal',
  templateUrl: './plagiasrim-ithenticate-sample-report-modal.component.html',
  styleUrls: ['./plagiasrim-ithenticate-sample-report-modal.component.scss']
})
export class PlagiasrimIthenticateSampleReportModalComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  downloadiTehnticateSampleReport(){
    this.spinner.show();
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../../assets/Trinka_Plagiarism_Checker_Sample_ithenticate.pdf');
    link.setAttribute('download', 'Trinka Plagiarism Checker Sample ithenticate.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.spinner.hide();
  }

}
