import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customPaymentTransactionDate'
})
export class CustomPaymentTransactionDatePipe extends
DatePipe implements PipeTransform {

  transform(value: any, timeZone?: any): any {
    value = this.convertDateToUTC(value);
    var date_time = super.transform(value, 'EEE, dd MMM yyyy '
                    + this.getTwoDigit(new Date(value).getUTCHours()) + ":"
                    + this.getTwoDigit(new Date(value).getUTCMinutes()));
    return date_time;
  }

  convertDateToUTC(date: string) {
    return new Date(date).toUTCString();
  }

  getTwoDigit(value: number) {
    return value < 10 ? "0"+value : value;
  }

}
