<div class="outer_div">
    <div class="square_shape">
        <img src="../../../assets/images/home_banner.jpg" alt="">
        <div class="in_side_images">
        </div>
    </div>
</div>
<div class="academic-writing">
    <div class="container">
        <div class="row">
            <div class="col-xl-7">
                <h1 class="main-title ">Grammar and writing done right</h1>
                <p>Trinka is the best grammar and language correction AI tool for academic and technical writing.</p>
                <ul class="highted-points">
                    <li>3000+ grammar checks</li>
                    <li>Tone & style enhancements</li>
                    <li>Advanced writing tips</li>
                    <li>Works on all subjects</li>
                </ul>
            </div>


            <div class="col-xl-5">

                <div class="academic_writing_section main_slider_homepage">
                    <div class="container">
                        <div class="academic_slider_section">
                            <div id="main_slider" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <h4>Grammar</h4>
                                        <p>The animals in the temperature-controlled chamber
                                            <span>was</span>
                                            <span class="themeColor">were</span>
                                            allowed access to food and water ad libitum.</p>
                                    </div>
                                    <div class="carousel-item">
                                        <h4>Word choice</h4>
                                        <p>The questionnaire was <span>made</span> <span
                                                class="themeColor">designed</span> to analyze the reasons that
                                            influenced respondents’ understanding of the textures.</p>
                                    </div>
                                    <div class="carousel-item">
                                        <h4>Writing style</h4>
                                        <p> <span>To the best of our knowledge, there </span> <span
                                                class="themeColor">There</span> is no publicly available
                                            dataset that satisfies our needs .</p>
                                    </div>
                                    <div class="carousel-item">
                                        <h4>Formal tone</h4>
                                        <p>We <span>didn’t</span> <span class="themeColor">did not</span> study these
                                            effects as the
                                            data was insufficient.
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h4>Contextual spelling</h4>
                                        <p><span>Resent</span> <span class="themeColor">Recent</span> studies explore
                                            the use of
                                            applied linguistics with
                                            special focus on sociolinguistics.</p>
                                    </div>


                                    <a class="carousel-control-prev" href="#main_slider" data-slide="prev">
                                        <span>
                                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 240.823 240.823"
                                                style="enable-background:new 0 0 240.823 240.823;" xml:space="preserve">
                                                <g>
                                                    <path id="Chevron_Right" d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179
                                                        l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816
                                                        C52.942,116.507,52.942,124.327,57.633,129.007z" />
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                    <a class="carousel-control-next" href="#main_slider" data-slide="next">
                                        <span>
                                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 240.823 240.823"
                                                style="enable-background:new 0 0 240.823 240.823;" xml:space="preserve">
                                                <g>
                                                    <path id="Chevron_Right" d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179
                                                        l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816
                                                        C52.942,116.507,52.942,124.327,57.633,129.007z" />
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="banner-btns">
                    <div class="banner-btns-inner">
                        <a href="/home/ms-word-app">
                            <div class="icons">
                                <img src="../../../assets/images/download_trinka_icon.png" alt="Download Trinka">
                            </div>
                            <div class="banner-txt">
                                <span class="purple-text">Coming soon !</span>
                                <p>FREE Microsoft Word plug-in</p>
                            </div>
                        </a>
                    </div>
                    <div class="banner-btns-inner">
                        <a href="/dashboard">
                            <div class="icons">
                                <img src="../../../assets/images/Get_stated_online_icons.png" alt="Online start">
                            </div>
                            <div class="banner-txt">
                                <span class="purple-text">Start writing better now</span>
                                <p>Log into Trinka Cloud</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="academic_writing_section academic_background_shap">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="academic_writing_text">
                    <h2>Made for academic & technical writing</h2>
                    <p>Trinka finds difficult errors unique to academic writing that other tools don’t. From complex
                        grammar errors to scientific tone and style, Trinka checks it all!</p>
                </div>
            </div>
        </div>

        <div class="academic_slider_section">
            <div id="subject-verb-d" class="carousel slide" data-ride="carousel">
                <div class="bullet_points_sections">
                    <h3>Finds tricky errors that others don’t</h3>
                    <p>Trinka is specially made for academic & technical writing; it corrects 3000+ complex grammar
                        errors. Error-free writing is now stress-free!</p>
                    <ul class="carousel-indicators">
                        <li data-target="#subject-verb-d" data-slide-to="0" class="active"> <span></span> Subject-verb,
                            pronoun-antecedent disagreements</li>
                        <li data-target="#subject-verb-d" data-slide-to="1"><span></span>Syntax, word choice
                        </li>
                        <li data-target="#subject-verb-d" data-slide-to="2"><span></span>Pronoun, article usage
                        </li>
                        <li data-target="#subject-verb-d" data-slide-to="3"><span></span>Technical spellings</li>
                    </ul>
                </div>

                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <h4>Subject-verb disagreement</h4>
                        <p>Five different experts <span>was</span> <span class="themeColor">were</span> asked to
                            evaluate the individual's performance and
                            interobserver agreement was assessed.</p>
                    </div>

                    <div class="carousel-item">
                        <h4>Syntax</h4>
                        <p>Rarely <span class="themeColor">do</span> we use aneurysm clips to obliterate the vessels.
                        </p>
                    </div>

                    <div class="carousel-item">
                        <h4>Pronoun, article usage</h4>
                        <p>Every <span class="themeColor">one</span> of these studies claimed the importance of the
                            spatial externalities for economic growth.</p>
                    </div>

                    <div class="carousel-item">
                        <h4>Technical spelling</h4>
                        <p>It was full of <span>turgid</span> <span class="themeColor">turbid</span> water and on
                            analysis, the contamination levels were found to be very high.</p>
                    </div>
                    <a class="carousel-control-prev" href="#subject-verb-d" data-slide="prev">
                        <span>
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240.823 240.823"
                                style="enable-background:new 0 0 240.823 240.823;" xml:space="preserve">
                                <g>
                                    <path id="Chevron_Right" d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179
                                        l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816
                                        C52.942,116.507,52.942,124.327,57.633,129.007z" />
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                            </svg>
                        </span>
                    </a>
                    <a class="carousel-control-next" href="#subject-verb-d" data-slide="next">
                        <span>
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240.823 240.823"
                                style="enable-background:new 0 0 240.823 240.823;" xml:space="preserve">
                                <g>
                                    <path id="Chevron_Right" d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179
                                        l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816
                                        C52.942,116.507,52.942,124.327,57.633,129.007z" />
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                            </svg>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="or-spacer">
                <div class="mask"></div>
            </div>
        </div>
    </div>
</div>



<div class="academic_writing_section beyond_grammer_spelling_sections footer_shap_background">
    <div class="container">

        <div class="academic_slider_section">
            <div id="spelling_grammers" class="carousel slide" data-ride="carousel">
                <div class="bullet_points_sections">
                    <h3>Improves writing beyond grammar and spelling</h3>
                    <p>Trinka goes beyond grammar and enhances your writing for vocabulary, tone, syntax, and much more.
                        Make your point confidently, with Trinka.</p>
                    <ul class="carousel-indicators">
                        <li data-target="#spelling_grammers" data-slide-to="0" class="active">
                            <a><img src="../../../assets/images/style_guides_icon.png" alt=""></a>
                            Style guides (APA | AMA)
                        </li>
                        <li data-target="#spelling_grammers" data-slide-to="1">
                            <a><img src="../../../assets/images/scientific_tone_icon.png" /></a>
                            Scientific tone
                        </li>
                        <li data-target="#spelling_grammers" data-slide-to="2">
                            <a><img src="../../../assets/images/technical_word_choice_icon.png" /></a>
                            Technical word choice
                        </li>
                        <li data-target="#spelling_grammers" data-slide-to="3">
                            <a><img src="../../../assets/images/word_count_reduction_icon.png" /></a>
                            Conciseness
                        </li>
                    </ul>
                </div>

                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <h4>Style guide preferences</h4>
                        <p>The <span>males</span> <span class="themeColor">men</span> scored higher than the
                            <span>females</span> <span class="themeColor">women.</span></p>
                    </div>

                    <div class="carousel-item">
                        <h4>Formal tone</h4>
                        <p>They <span>reached a decision</span> <span class="themeColor">decided</span> to ban 5
                            countries from the games next year.</p>
                    </div>

                    <div class="carousel-item">
                        <h4>Technical word choice</h4>
                        <p>They may have an <span>affect</span> <span class="themeColor">effect</span> on the tendon.
                        </p>
                    </div>

                    <div class="carousel-item">
                        <h4>Conciseness</h4>
                        <p>Studies <span>were carried out to evaluate</span> <span class="themeColor"> evaluated</span>
                            the effect of lower temperatures on energy
                            consumption.</p>
                    </div>
                    <a class="carousel-control-prev" href="#spelling_grammers" data-slide="prev">
                        <span class="">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240.823 240.823"
                                style="enable-background:new 0 0 240.823 240.823;" xml:space="preserve">
                                <g>
                                    <path id="Chevron_Right" d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179
                                        l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816
                                        C52.942,116.507,52.942,124.327,57.633,129.007z" />
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                            </svg>
                        </span>
                    </a>
                    <a class="carousel-control-next" href="#spelling_grammers" data-slide="next">
                        <span class="">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240.823 240.823"
                                style="enable-background:new 0 0 240.823 240.823;" xml:space="preserve">
                                <g>
                                    <path id="Chevron_Right" d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179
                                        l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816
                                        C52.942,116.507,52.942,124.327,57.633,129.007z" />
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                            </svg>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="or-spacer">
                <div class="mask"></div>
            </div>
        </div>
    </div>
</div>


<div class="academic_writing_section beyond_grammer_spelling_sections suggests_corrections_slider">
    <div class="container">

        <div class="academic_slider_section">
            <div id="suggests_corrections" class="carousel slide" data-ride="carousel">
                <div class="bullet_points_sections">
                    <h3>Suggests corrections relevant to your subject</h3>
                    <p>Trinka has learned from the best-written papers in every subject to give you the best
                        suggestions. With Trinka, your writing will always be precise.</p>
                    <ul class="carousel-indicators">
                        <li data-target="#suggests_corrections" data-slide-to="0" class="active">
                            <a><img src="../../../assets/images/medicine.png" class="img-fluid" /></a> Medicine
                        </li>
                        <li data-target="#suggests_corrections" data-slide-to="1">
                            <a><img src="../../../assets/images/biology.png" class="img-fluid" /></a> Biology
                        </li>
                        <li data-target="#suggests_corrections" data-slide-to="2">
                            <a><img src="../../../assets/images/physics-icons.png" class="img-fluid" /></a> Physics
                        </li>
                        <li data-target="#suggests_corrections" data-slide-to="3">
                            <a><img src="../../../assets/images/social-science.png" class="img-fluid" /></a> Social
                            sciences
                        </li>

                        <li data-target="#suggests_corrections" data-slide-to="4">
                            <a><img src="../../../assets/images/engineering.png" class="img-fluid" /></a> Engineering
                        </li>

                        <li data-target="#suggests_corrections" data-slide-to="5">
                            <a><img src="../../../assets/images/chemistry.png" class="img-fluid" /></a> Chemistry
                        </li>
                        <span></span>

                        <li data-target="#suggests_corrections" data-slide-to="6">
                            <a><img src="../../../assets/images/geology.png" class="img-fluid" /></a> Geology
                        </li>

                        <li data-target="#suggests_corrections" data-slide-to="7">
                            <a><img src="../../../assets/images/computer-science.png" class="img-fluid" /></a> Computer
                            science
                        </li>

                        <li data-target="#suggests_corrections" data-slide-to="8">
                            <a><img src="../../../assets/images/business-and-economics.png"
                                    class="img-fluid" /></a>Economics
                        </li>
                    </ul>
                </div>

                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <h4>Medicine</h4>
                        <p>In general, the ADT group had a more <span>advance</span> <span
                                class="themeColor">advanced</span> disease compared with the non-ADT
                            group.</p>
                    </div>

                    <div class="carousel-item">
                        <h4>Biology</h4>
                        <p>The membranes were then incubated with human cleaved caspase-3 antibodies at 4°C
                            <span>for</span> overnight.</p>
                    </div>

                    <div class="carousel-item">
                        <h4>Physics</h4>
                        <p>Scientists rely on exactly <span>soluble</span> <span class="themeColor">solvable</span>
                            problems to describe the strange effects which have quantum mechanical origin.</p>
                    </div>

                    <div class="carousel-item">
                        <h4>Social sciences</h4>
                        <p>The individual displayed <span>a persistent spirit</span> <span
                                class="themeColor">persistence.</span></p>
                    </div>

                    <div class="carousel-item">
                        <h4>Engineering</h4>
                        <p>The technique has <span class="themeColor">been</span> widely used for the fabrication of SiC
                            <span>fiber</span> <span class="themeColor">fibers</span> with high mechanical properties.
                        </p>
                    </div>

                    <div class="carousel-item">
                        <h4>Chemistry</h4>
                        <p>A current is sent through the material, consequently <span
                                class="themeColor">polarizing</span> the electrons. <span>are polarized.</span></p>
                    </div>

                    <div class="carousel-item">
                        <h4>Geology</h4>
                        <p>
                            There was <span class="themeColor">a</span> large tectonic movement at the end of the
                            Mesozoic <span>period</span> <span class="themeColor">era</span>.
                        </p>
                    </div>

                    <div class="carousel-item">
                        <h4>Computer science</h4>
                        <p>In <span class="themeColor">the</span> testing phase, <span>it's</span> <span
                                class="themeColor">its</span> label is determined using the <span>triaining</span> <span
                                class="themeColor">training</span> instances
                            mapped to a specific neuron.</p>
                    </div>

                    <div class="carousel-item">
                        <h4>Economics</h4>
                        <p>The role expected <span>to</span> <span class="themeColor">of</span> IASB has changed when it
                            became a member of the Financial Stability Board in 2009.</p>
                    </div>
                    
                    <a class="carousel-control-prev" href="#suggests_corrections" data-slide="prev">
                        <span class="">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240.823 240.823"
                                style="enable-background:new 0 0 240.823 240.823;" xml:space="preserve">
                                <g>
                                    <path id="Chevron_Right" d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179
                                        l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816
                                        C52.942,116.507,52.942,124.327,57.633,129.007z" />
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                            </svg>
                        </span>
                    </a>
                    <a class="carousel-control-next" href="#suggests_corrections" data-slide="next">
                        <span class="">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240.823 240.823"
                                style="enable-background:new 0 0 240.823 240.823;" xml:space="preserve">
                                <g>
                                    <path id="Chevron_Right" d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179
                                        l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816
                                        C52.942,116.507,52.942,124.327,57.633,129.007z" />
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                            </svg>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="purple-bg1 footer_shap_background">
    <div class="purple_background_overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="box">
                    <h3>Trinka offers the best enhancements</h3>
                    <div class="box_text_content">
                        <p>Trinka is purpose-built for academic and technical writing and draws on the expertise of
                            highly
                            experienced editors and linguists. The result is simple – the finest language enhancements.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="box">
                    <h3>Trinka is great for teams and enterprises</h3>
                    <div class="box_text_content">
                        <p>Get your teams to write better with Trinka and get attractive pricing and other benefits.
                        </p>
                        <a [routerLink]="['/home/enterprise']">Learn more
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 31.49 31.49"
                                style="enable-background:new 0 0 31.49 31.49;" xml:space="preserve">
                                <path
                                    d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
                                   C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
                                   c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z" />
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                            </svg>
                        </a>
                    </div>
                    <div class="contact-sales">
                        <form class="form-inline" id="contact-sales">
                            <div class="form-group">
                                <input type="email" class="form-control" id="email-add"
                                    placeholder="Enter your email address" [(ngModel)]="email" name="email">
                            </div>
                            <button type="submit" class="btn btn-primary mb-2" (click)="contactToSales()">Contact
                                Sales</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center testi">
                <h2 class="sub-head">Testimonials</h2>
                <p>Check what users are saying about Trinka</p>
            </div>
        </div>
    </div>
</div>
<div class="testimonial text-center">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="testimonial-wrap">
                    <div class="testimonal-icon">
                        <img src="../../../assets/images/testimonial.png" alt="testimonial">
                    </div>
                    <div class="testimonial-slider">
                        <div id="testi" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <p class="start-text">First, I love Trinka. Congratulations on
                                        developing something that extensively aids editing, especially critical grammar
                                        conventions and word count reductions.</p>
                                    <p class="author_designation">Academic Editor, USA</p>
                                </div>
                                <div class="carousel-item">
                                    <p class="start-text">The grammar checking tool exceeds Grammarly!
                                        I re-checked some documents that I had checked earlier with Grammarly.
                                        I was pleasantly surprised to see such a product from India!</p>
                                    <p class="author_designation">Radiologist, India</p>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#testi" data-slide="prev">
                            <span class="carousel-control-prev-icon"></span>
                        </a>
                        <a class="carousel-control-next" href="#testi" data-slide="next">
                            <span class="carousel-control-next-icon"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>