import { Component, OnInit, AfterViewInit, AfterViewChecked, Inject, AfterContentChecked, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/_guards/auth.service';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { Router } from '@angular/router';
import { SignInSignOutComponent } from '../sign-in-sign-out/sign-in-sign-out.component';
import { SharedService } from 'src/app/_services/shared.service';
import * as $ from 'jquery';
import { WebsiteService } from 'src/app/_services/networkcalls/website.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetMetaService } from 'src/app/_services/get-meta.service';
declare var $: any;

@Component({
  selector: 'website-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked {
  dialogRef: any;
  isSignIn: boolean = false;
  isSticky: boolean = false;
  userId: string;
  userDetails: any;

  constructor(
    public dialog: MatDialog,
    public auth: AuthService,
    private router: Router,
    private sharedService: SharedService,
    public networkCalls: WebsiteService,
    private spinner: NgxSpinnerService,
    public metaString : GetMetaService,
    @Inject(LOCAL_STORAGE) private storage: StorageService, ) { }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    if (localMeta.sub) {
      this.userId = btoa(localMeta.sub)
      this.getUserProfileData()
    }
    window.onscroll = function () { myFunction() };
    var header = document.getElementById("main_header");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }

    $("#main_sub_menus").hover(
      function () {
        // $(".dropdown").addClass("open");
        $("#Home_sub_menus").addClass("show");
      }, function () {
        // $(".dropdown").removeClass("open");
        $("#Home_sub_menus").removeClass("show");
      }
    );

    $(window).resize(function () {
      if ($(window).width() < 1199) {
        $('.nav-item').click(function () {
          $('#mobile_menu_toggle').click();
        });
        $(".nav-item.dropdown").unbind("click");
        $(".nav-item.dropdown > .dropdown-menu > .nav-link").click(function () {
          $('#mobile_menu_toggle').click();
        });
      }
    });

    $(document).ready(function () {
      if ($(window).width() < 1199) {
        $('.nav-item').click(function () {
          $('#mobile_menu_toggle').click();
        });
        $(".nav-item.dropdown").unbind("click");
        $(".nav-item.dropdown > .dropdown-menu > .nav-link").click(function () {
          $('#mobile_menu_toggle').click();
        });
      }
    });

    // Body overlay Jquery. Click any where menu will be closed
    $("#mobile_menu_toggle").click(function () {
      $("#body_overlay_section").toggleClass("body_overlay");
    });

    $("#body_overlay_section").click(function () {
      $("#mobile_menu_toggle").click();
      $("#body_overlay_section").hasClass("body_overlay").removeClass("body_overlay");
    });

  }
  ngAfterViewInit() {
    this.isSignIn = this.auth.isAuthenticated();
  }

  ngAfterViewChecked() {
  }

  ngAfterContentChecked() {
  }



  openDialog() {
    this.sharedService.signInpopUp = this.dialog.open(SignInSignOutComponent);
    this.sharedService.signInpopUp.afterClosed().subscribe(result => {
    });
  }

  signOut() {
    this.storage.set(this.metaString.getMeta(), {});
    this.isSignIn = false
    this.router.navigate(['/signin'])
  }

  getUserProfileData() {
    this.networkCalls.getUserDetails(this.userId).subscribe(data => {
      this.userDetails = data.data
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

}
