import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {

  constructor(private http: HttpClient) { }

  postSubscribe(email: string): Observable<any> {
    return this.http.post<any>(environment.server_address+"/support/api/v1/subscription", {	"email_id": email})
  }
  postContactUs(email, query): Observable<any> {
    return this.http.post<any>(environment.server_address+"/support/api/v1/subscription", {	"email_id": email,	"query":query })
  }
  postContactSales(email: string): Observable<any> {
    return this.http.post<any>(environment.server_address+"/support/api/v1/enquiry", {	"email_id": email,	"query": "sales"})
  }

  getUserDetails(user_id: any){
    return this.http.get<any>(environment.auth_api+"/api/v1/user/"+user_id)
  }

  upgradeUGRUser(token: any) {
    return this.http.post<any>(environment.auth_api+"/api/v1/user/domain/premium", {"token": token})
  }
}
