import {
  Component,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core'

// declare const ClassicEditor: any;
declare const CKEDITOR: any
@Component({
  selector: 'app-ckeditor-component',
  templateUrl: './ckeditor-component.component.html',
  styleUrls: ['./ckeditor-component.component.scss']
})
export class CkeditorComponent implements AfterViewInit, OnChanges {
  private editor: any
  @Input() editorContent: string = ''
  @Output() onEditorChange = new EventEmitter<any>()

  constructor () { }

  async ngAfterViewInit (): Promise<void> {
    if(!CKEDITOR.ClassicEditor) return;

    const editor1 = await CKEDITOR.ClassicEditor.create(
      document.getElementById('editor'),
      {
        // https://ckeditor.com/docs/ckeditor5/latest/getting-started/setup/toolbar/toolbar.html#extended-toolbar-configuration-format
        toolbar: {
          items: [
            'heading',
            '|',
            'fontFamily',
            '|',
            'fontSize',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            '|',
            'subscript',
            'superscript',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            'alignment',
            '|',
            'fontColor',
            'fontBackgroundColor',
            // 'highlight',
            '|',
            'insertTable',
            'link',
            'uploadImage',
            '|',
            'removeFormat',
            '|',
            //TODO: To be discussed and see if we need these options or not
            // 'code',
            // 'todoList',
            // 'undo',
            // 'redo',
            // 'blockQuote'
          ],
          shouldNotGroupWhenFull: false
        },
        language: 'en',
        ui: {
            labels: {
                heading: 'Heading',
                bold: 'Bold',
                italic: 'Italic',
                link: 'Insert Link',
                fontFamily: 'Font Famliy',
                fontSize: 'Font Size',
                underline: 'Underline',
                strikethrough: 'Strikethrough',
                subscript: 'Subscript',
                superscript: 'Superscript',
                bulletedList: 'Bullet List',
                numberedList: 'Number List',
                outdent: 'Outdent',
                indent: 'Indent',
                alignment: 'Alignment',
                fontColor: 'Font Color',
                fontBackgroundColor: 'Font Background Color',
                insertTable: 'Insert Table',
                uploadImage: 'Link',
                removeFormat: 'Remove Format'
            }
        },
        placeholder: 'Enter some text here',
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3'
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4'
            },
            {
              model: 'heading5',
              view: 'h5',
              title: 'Heading 5',
              class: 'ck-heading_heading5'
            },
            {
              model: 'heading6',
              view: 'h6',
              title: 'Heading 6',
              class: 'ck-heading_heading6'
            }
          ]
        },
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif'
          ],
          supportAllValues: true
        },
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22],
          supportAllValues: true
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        },
        // Be careful with enabling previews
        // https://ckeditor.com/docs/ckeditor5/latest/features/html-embed.html#content-previews
        htmlEmbed: {
          showPreviews: false
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/link.html#custom-link-attributes-decorators
        link: {
          decorators: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html#configuration
        mention: {
          feeds: [
            {
              marker: '@',
              feed: [
                '@apple',
                '@bears',
                '@brownie',
                '@cake',
                '@cake',
                '@candy',
                '@canes',
                '@chocolate',
                '@cookie',
                '@cotton',
                '@cream',
                '@cupcake',
                '@danish',
                '@donut',
                '@dragée',
                '@fruitcake',
                '@gingerbread',
                '@gummi',
                '@ice',
                '@jelly-o',
                '@liquorice',
                '@macaroon',
                '@marzipan',
                '@oat',
                '@pie',
                '@plum',
                '@pudding',
                '@sesame',
                '@snaps',
                '@soufflé',
                '@sugar',
                '@sweet',
                '@topping',
                '@wafer'
              ],
              minimumCharacters: 1
            }
          ]
        },
        // The "superbuild" contains more premium features that require additional configuration, disable them below.
        // Do not turn them on unless you read the documentation and know how to configure them and setup the editor.
        removePlugins: [
          // These two are commercial, but you can try them out without registering to a trial.
          'ExportPdf',
          'ExportWord',
          'AIAssistant',
          'CKBox',
          'CKFinder',
          'EasyImage',
          
          // This sample uses the Base64UploadAdapter to handle image uploads as it requires no configuration.
          // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/base64-upload-adapter.html
          // Storing images as Base64 is usually a very bad idea.
          // Replace it on production website with other solutions:
          // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/image-upload.html
          // 'Base64UploadAdapter',
          'MultiLevelList',
          'RealTimeCollaborativeComments',
          'RealTimeCollaborativeTrackChanges',
          'RealTimeCollaborativeRevisionHistory',
          'PresenceList',
          'Comments',
          'TrackChanges',
          'TrackChangesData',
          'RevisionHistory',
          'Pagination',
          'WProofreader',
          // Careful, with the Mathtype plugin CKEditor will not load when loading this sample
          // from a local file system (file://) - load this site via HTTP server if you enable MathType.
          'MathType',
          // The following features are part of the Productivity Pack and require additional license.
          'SlashCommand',
          'Template',
          'DocumentOutline',
          'FormatPainter',
          'TableOfContents',
          'PasteFromOfficeEnhanced',
          'CaseChange',
          'Highlight'
        ]
      }
    )
      .then((newEditor: any) => {
        this.editor = newEditor;
        this.editor.ui.view.editable.element.setAttribute('spellcheck', false);
        if (this.editor) this.editor.setData(this.editorContent)
        let observer = new MutationObserver(function(mutations) {
          mutations.forEach(function(mutationRecord) {
              if ((mutationRecord.target as HTMLElement).className.includes("ck-blurred") || (mutationRecord.target as HTMLElement).className.includes("ck-focused")) {
                  if(newEditor)newEditor.ui.view.editable.element.setAttribute('spellcheck', 'false');
              }
          })
        });
        if (this.editor) {
          observer.observe(this.editor.ui.view.editable.element, {
            attributes: true,
            attributeFilter: ['style', 'class']
          });
        }
      })
      .catch(error => {
        console.log('Error while creating is: ', error)
      })
      this.editor.model.document.on('change:data', (evt:any, data: any) => {
          const changes = this.editor.model.document.differ.getChanges();

          if (changes[0].attributeKey === "highlight" && changes[0].attributeNewValue) return;
          else this.onEditorChange.emit(data);
      })

    //TODO: Backup of ClassicEditor which needs to deleted once deleted
    // await CKEDITOR.ClassicEditor
    // .create(document.querySelector('#editor'))
    // .then((newEditor: any) => {
    //   this.editor = newEditor;
    //   console.log('Editor is: ', this.editor);
    //   this.editor.setData(this.editorContent);

    //   // Function to highlight the selected text
    //   const highlightSelection = () => {
    //     this.editor.model.change(writer => {
    //       const selection = this.editor.model.document.selection;

    //       if (selection && !selection.isCollapsed) {
    //         const ranges = selection.getRanges();

    //         for (const range of ranges) {
    //           writer.setAttribute('highlight', 'true', range);
    //         }
    //       }
    //     });
    //   };

    //   // Detect selection changes and highlight immediately
    //   this.editor.editing.view.document.on('selectionChange', () => {
    //     // highlightSelection();
    //   });

    //   // Handle view rendering
    //   // this.editor.conversion.for('downcast').attributeToElement({
    //   //   model: 'highlight',
    //   //   view: (attributeValue, { writer }) => {
    //   //     if (attributeValue) {
    //   //       return writer.createAttributeElement('span', {
    //   //       class: 'highlight-class'
    //   //       }, {
    //   //       priority: 5
    //   //       });
    //   //     }
    //   //   }
    //   // });
    // }).catch(error => {
    //   console.log('Error while creating is: ', error);
    // });
    console.log('Edito1 is: ', editor1)
  }

  ngOnChanges (changes: SimpleChanges) {
    console.log('Changes are: ', changes)
    this.updateEditorContent(changes.editorContent.currentValue)
  }

  updateEditorContent (currentEditorContent: string) {
    if (this.editor) this.editor.setData(currentEditorContent)
  }

}
