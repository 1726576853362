import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AutoEditComponent } from '../auto-edit/auto-edit.component';

@Component({
  selector: 'app-error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.scss']
})
export class ErrorReportComponent implements OnInit {

  dataSource = [];
  errorReport: any;
  fileData: any;

  styleGuideDisplay = {
    "NONE": "None",
    "AMA": "AMA (11th ed)",
    "APA": "APA (7th ed)",
    "ACS": "ACS (2nd ed)",
    "AGU": "AGU (2017 ed)",
    "IEEE": "IEEE"
  }

  constructor(public sharedService: SharedService,
    private dialogRef: MatDialogRef<AutoEditComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.errorReport = data.fileData['error_report'];
    this.fileData = data.fileData;

    this.generateErrorJson(this.errorReport);
    if (this.fileData.writing_advisor_as_comments) {
      // this.z_suggestions(this.fileData,this.errorReport);
    }
  }

  ngOnInit() {
  }

  generateErrorJson(errorData) {
    if (this.fileData.writing_advisor_as_comments) {
      this.dataSource = [
       { key: 'Abbreviations', value: this.safe(errorData.abbreviations), isVisible: true },
       { key: 'Adjectives/Adverbs', value: this.safe(errorData.adverb_and_adjective), isVisible: true },
       { key: 'Articles', value: this.safe(errorData.article), isVisible: true },
       { key: 'Capitalization & Spacing', value: this.safe(errorData.capitalization_and_spacing), isVisible: true },
       { key: 'Conjunctions', value: this.safe(errorData.conjunction), isVisible: true },
       { key: 'Difficult-to-read Sentence', value: this.safe(errorData.complexity), isVisible: true },
       { key: 'Enhancement', value: this.safe(errorData.enhancement), isVisible: true },
       { key: 'Fragment', value: this.safe(errorData.fragment), isVisible: true },
       { key: 'Idioms', value: this.safe(errorData.idioms), isVisible: true },
       { key: 'Number Style', value: this.safe(errorData.number_style), isVisible: true },
       { key: 'Other', value: this.safe(errorData.other), isVisible: true },
       { key: 'Plain Language', value: this.safe(errorData.plain_language), isVisible: true },
       { key: 'Prepositions', value: this.safe(errorData.preposition), isVisible: true },
       { key: 'Pronouns & Determiners', value: this.safe(errorData.pronoun_and_determiner), isVisible: true },
       { key: 'Punctuation', value: this.safe(errorData.punctuation), isVisible: true },
       { key: 'Run-on Sentence', value: this.safe(errorData.run_on), isVisible: true },
       { key: 'Redundancy & Conciseness', value: this.safe(errorData.redundancy_conciseness), isVisible: true },
       { key: 'Sensitive Language', value: this.safe(errorData.sensitive_language), isVisible: true },
       { key: 'Singular-Plural nouns', value: this.safe(errorData.noun), isVisible: true },
       { key: 'Spelling & Typos', value: this.safe(errorData.spelling), isVisible: true },
       { key: 'Style', value: this.safe(errorData.style), isVisible: true },        
       { key: 'Style Guide - '+ this.styleGuideDisplay[this.fileData.style_guide], value: this.safe(errorData.style_guide), isVisible: true }, 
       { key: 'Subject-verb agreement', value: this.safe(errorData.sva), isVisible: true },
       { key: 'Symbols/Notations ', value: this.safe(errorData.symbols_notations), isVisible: true },        
       { key: 'Syntax', value: this.safe(errorData.syntax), isVisible: true },
       { key: 'Tense', value: this.safe(errorData.tense), isVisible: true },
       { key: 'Verbs', value: this.safe(errorData.verb), isVisible: true },
       { key: 'Word form', value: this.safe(errorData.word_form), isVisible: true },       
       { key: 'Word order', value: this.safe(errorData.word_order), isVisible: true },       
       { key: 'Word/Phrase choice', value: this.safe(errorData.word_choice), isVisible: true },       
       { key: 'Writing Advisor', value: this.safe(errorData.writing_advisor), isVisible: true },
      ]
    } else {
      this.dataSource = [
        { key: 'Abbreviations', value: this.safe(errorData.abbreviations), isVisible: true },
        { key: 'Adjectives/Adverbs', value: this.safe(errorData.adverb_and_adjective), isVisible: true },
        { key: 'Articles', value: this.safe(errorData.article), isVisible: true },
        { key: 'Capitalization & Spacing', value: this.safe(errorData.capitalization_and_spacing), isVisible: true },
        { key: 'Conjunctions', value: this.safe(errorData.conjunction), isVisible: true },
        { key: 'Difficult-to-read Sentence', value: this.safe(errorData.complexity), isVisible: true },
        { key: 'Enhancement', value: this.safe(errorData.enhancement), isVisible: true },
        { key: 'Fragment', value: this.safe(errorData.fragment), isVisible: true },
        { key: 'Idioms', value: this.safe(errorData.idioms), isVisible: true },
        { key: 'Number Style', value: this.safe(errorData.number_style), isVisible: true },
        { key: 'Other', value: this.safe(errorData.other), isVisible: true },
        { key: 'Plain Language', value: this.safe(errorData.plain_language), isVisible: true },
        { key: 'Prepositions', value: this.safe(errorData.preposition), isVisible: true },
        { key: 'Pronouns & Determiners', value: this.safe(errorData.pronoun_and_determiner), isVisible: true },
        { key: 'Punctuation', value: this.safe(errorData.punctuation), isVisible: true },
        { key: 'Run-on Sentence', value: this.safe(errorData.run_on), isVisible: true },
        { key: 'Redundancy & Conciseness', value: this.safe(errorData.redundancy_conciseness), isVisible: true },
        { key: 'Sensitive Language', value: this.safe(errorData.sensitive_language), isVisible: true },
        { key: 'Singular-Plural nouns', value: this.safe(errorData.noun), isVisible: true },
        { key: 'Spelling & Typos', value: this.safe(errorData.spelling), isVisible: true },
        { key: 'Style', value: this.safe(errorData.style), isVisible: true },
        { key: 'Style Guide - '+ this.styleGuideDisplay[this.fileData.style_guide], value: this.safe(errorData.style_guide), isVisible: true },
        { key: 'Subject-verb agreement', value: this.safe(errorData.sva), isVisible: true },
        { key: 'Symbols/Notations ', value: this.safe(errorData.symbols_notations), isVisible: true },
        { key: 'Syntax', value: this.safe(errorData.syntax), isVisible: true },
        { key: 'Tense', value: this.safe(errorData.tense), isVisible: true },
        { key: 'Verbs', value: this.safe(errorData.verb), isVisible: true },
        { key: 'Word form', value: this.safe(errorData.word_form), isVisible: true },
        { key: 'Word order', value: this.safe(errorData.word_order), isVisible: true },
        { key: 'Word/Phrase choice', value: this.safe(errorData.word_choice), isVisible: true },
        
        
      ]
    }
  }

  // generateErrorJson(errorData) {
  //   if (this.fileData.writing_advisor_as_comments) {

  //     this.dataSource = [
  //       { key: 'Adverb & Adjective', value: errorData.adverb_and_adjective, isVisible: true },
  //       { key: 'Register & Tone', value: errorData.register_and_tone, isVisible: true },
  //       { key: 'Articles', value: errorData.article, isVisible: true },
  //       { key: 'Spelling', value: errorData.spelling, isVisible: true },
  //       { key: 'Capitalization & Spacing ', value: errorData.capitalization_and_spacing, isVisible: true },
  //       { key: 'Subject Verb Agreement', value: errorData.sva, isVisible: true },
  //       { key: 'Enhancement', value: errorData.enhancement, isVisible: true },
  //       { key: 'Verb', value: errorData.verb, isVisible: true },
  //       { key: 'Fragment', value: errorData.fragment, isVisible: true },
  //       { key: 'Word Choice', value: errorData.word_choice, isVisible: true },
  //       { key: 'Noun', value: errorData.noun, isVisible: true },
  //       { key: 'Word Order', value: errorData.word_order, isVisible: true },
  //       { key: 'Prepositions', value: errorData.preposition, isVisible: true },
  //       { key: 'Writing Advisor', value: errorData.writing_advisor, isVisible: true },
  //       { key: 'Pronoun & Determiner', value: errorData.pronoun_and_determiner, isVisible: true },
  //       { key: 'Other', value: errorData.other, isVisible: true },
  //       { key: 'Punctuation', value: errorData.punctuation, isVisible: true },
  //     ]
  //   } else {

  //     this.dataSource = [
  //       { key: 'Adverb & Adjective', value: errorData.adverb_and_adjective, isVisible: true },
  //       { key: 'Punctuation', value: errorData.punctuation, isVisible: true },
  //       { key: 'Articles', value: errorData.article, isVisible: true },
  //       { key: 'Register & Tone', value: errorData.register_and_tone, isVisible: true },
  //       { key: 'Capitalization & Spacing ', value: errorData.capitalization_and_spacing, isVisible: true },
  //       { key: 'Spelling', value: errorData.spelling, isVisible: true },
  //       { key: 'Enhancement', value: errorData.enhancement, isVisible: true },
  //       { key: 'Subject Verb Agreement', value: errorData.sva, isVisible: true },
  //       { key: 'Fragment', value: errorData.fragment, isVisible: true },
  //       { key: 'Verb', value: errorData.verb, isVisible: true },
  //       { key: 'Noun', value: errorData.noun, isVisible: true },
  //       { key: 'Word Choice', value: errorData.word_choice, isVisible: true },
  //       { key: 'Prepositions', value: errorData.preposition, isVisible: true },
  //       { key: 'Word Order', value: errorData.word_order, isVisible: true },
  //       { key: 'Pronoun & Determiner', value: errorData.pronoun_and_determiner, isVisible: true },
  //       { key: 'Other', value: errorData.other, isVisible: true },
  //     ]
  //   }

  // }

  private safe(value) {
    return value ? value : 0;
  }
}
