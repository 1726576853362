<div class="content_wrap_section">
    <div class="wrap_header_sectons contact_us_background">
        <div class="top_header_overlay"></div>
        <h2>Contact Us</h2>
    </div>

    <div class="contactus_contents">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="office_address">
                        <div class="office_contents">
                            <h4>Office Address</h4>
                            <p>Office No 1001, 10th Floor, Techniplex – II
                                Off SV Road, Goregaon - West
                                Mumbai - 400062, India</p>
                        </div>

                        <div class="office_contents">
                            <h4>Contact Info</h4>
                            <p>Phone no.+91-22-61935000</p>
                            <p>Email: <a href="mailto:support@trinka.ai">support@trinka.ai</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="contact_forms">
                        <form [formGroup]="contactForm" (ngSubmit)="processForm()">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input type="text" formControlName="contactFormName" class="form-control" placeholder="Your name *">
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input type="text"  formControlName="contactFormEmail" class="form-control" placeholder="Email *">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <input type="text" formControlName="contactFormSubjects"  class="form-control" placeholder="Subject *">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <textarea name="containt" formControlName="contactFormMessage" class="form-control" placeholder="Your Message. . ."></textarea>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <div class="form-group">
                                    <button type="submit" [disabled]="disabledSubmitButton">Send Message</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
