<div class="container h-100 language-pre language-code-pre mat-typography-config p-4" fxLayout.xs="column" fxLayoutAlign="left"
        fxLayoutGap.xs="0" fxLayout="row">
        <div class="lang-code-modal-header">
                <h4>Set Language Preference</h4>
        </div>
        <div class="multiselect-button lang-form-group"
        *ngFor="let option of languageOptions"
        [class.selected]="selectedLanguageCode.includes(option.code)">
                <label class="lang-form-label">
                        <input type="checkbox" (click)="languagePrefe(option.code)" class="checkbox" [checked]="option.isSelected" [(ngModel)]="option.isSelected">
                        <span>{{option.value}} <i *ngIf="option.code !== 'en'" class="languageBetaTag">Beta</i></span>
                </label>
                <div class="us-uk-eglish-option" *ngIf="option.value === 'English (EN)' && selectedLanguageCode.includes('en')">
                        <mat-radio-group class="english-language-options-outer" aria-label="Select an option" [(ngModel)]="userDetails.language">
                                <mat-radio-button class="english-language-code-options-inner" value="US">US English<br><b>eg. realize, not realise</b></mat-radio-button><br>
                                <mat-radio-button class="english-language-code-options-inner" value="UK"> UK English<br><b>eg. realise, not realize</b></mat-radio-button>
                        </mat-radio-group>
                </div>
        </div>
        <div class="modal-footer-btn-outer">
                <button [disabled]="selectedLanguageCode.length === 0" class="modal-footer-btn" (click)="setLangCode()">Save</button>
        </div>
        <!-- <mat-radio-group class="language-code-options-outer" aria-label="Select an option" [(ngModel)]="userDetails.lang_code">
                <mat-radio-button class="language-code-options-inner" *ngFor="let language of languageOptions;" [value]="language.code" (click)="languagePrefe(language.code)">{{language.value}}</mat-radio-button>
        </mat-radio-group> -->

</div>