<div class="share_popup">
    <div class="share_heading">
        <div class="title">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.8 3.6C10.8 1.61177 12.4118 0 14.4 0C16.3882 0 18 1.61177 18 3.6C18 5.58822 16.3882 7.2 14.4 7.2C13.3088 7.2 12.3309 6.71449 11.6708 5.94769L7.12943 8.28716C7.17572 8.51758 7.2 8.75595 7.2 9C7.2 9.25105 7.1743 9.49609 7.1254 9.73265L11.6578 12.0675C12.3181 11.2919 13.3016 10.8 14.4 10.8C16.3882 10.8 18 12.4118 18 14.4C18 16.3882 16.3882 18 14.4 18C12.4118 18 10.8 16.3882 10.8 14.4C10.8 14.1558 10.8243 13.9173 10.8706 13.6868L6.32947 11.3474C5.66927 12.1144 4.69135 12.6 3.6 12.6C1.61177 12.6 0 10.9882 0 9C0 7.01178 1.61177 5.4 3.6 5.4C4.69854 5.4 5.68215 5.89204 6.34247 6.66776L10.8747 4.33299C10.8257 4.09633 10.8 3.85117 10.8 3.6ZM14.4 1.8C13.4059 1.8 12.6 2.60589 12.6 3.6C12.6 4.59411 13.4059 5.4 14.4 5.4C15.3941 5.4 16.2 4.59411 16.2 3.6C16.2 2.60589 15.3941 1.8 14.4 1.8ZM12.7339 13.7174C12.6476 13.9279 12.6 14.1584 12.6 14.4C12.6 15.3941 13.4059 16.2 14.4 16.2C15.3941 16.2 16.2 15.3941 16.2 14.4C16.2 13.4059 15.3941 12.6 14.4 12.6C13.7194 12.6 13.127 12.9777 12.8211 13.535C12.8072 13.5749 12.7904 13.6144 12.7704 13.6531C12.759 13.6752 12.7469 13.6967 12.7339 13.7174ZM5.23029 8.2531C5.21096 8.21558 5.19452 8.17734 5.1809 8.13862C4.87554 7.57937 4.28207 7.2 3.6 7.2C2.60589 7.2 1.8 8.00589 1.8 9C1.8 9.99411 2.60589 10.8 3.6 10.8C4.59411 10.8 5.4 9.99411 5.4 9C5.4 8.75709 5.35188 8.52541 5.26466 8.31399C5.25253 8.2943 5.24105 8.27401 5.23029 8.2531Z"
                    fill="#4D4D4D" />
            </svg>Share
        </div>
        <div class="close" (click)="close()">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z"
                    fill="#4D4D4D" />
            </svg>
        </div>
    </div>
    <div class="share_popup_body">
        <p>Enter email id to share. Multiple email ids separated by comma.</p>
        <div class="share_form">
            <input [(ngModel)]="sharedReportEmailId" (ngModelChange)="validateEmail()" [ngClass]="isEmailValid ? '' : 'outerLineError'" type="email"
                placeholder="john.doe@gmail.com" />
            <button (click)="shareReport()" [disabled]="sharedReportEmailId.length == 0">Share</button>
        </div>
        <div>
            <span #errorMessage class="textarea_error_msg">{{error_message}}</span>
        </div>
    </div>
</div>