import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-plag-check-existing-user-onboarding-modal',
  templateUrl: './plag-check-existing-user-onboarding-modal.component.html',
  styleUrls: ['./plag-check-existing-user-onboarding-modal.component.scss']
})
export class PlagCheckExistingUserOnboardingModalComponent implements OnInit {

  constructor(private matDialogRef : MatDialogRef<PlagCheckExistingUserOnboardingModalComponent>) { }

  ngOnInit() {
  }

  close(){
    this.matDialogRef.close();
  }
}
