import { Component, OnInit, HostListener, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { FileObject } from 'src/app/_interfaces/editor/file';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { ConnectionService } from 'src/app/_socket/connection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { ConnectionService as ConnectionStatusService } from 'ng-connection-service';
import { CKEditorComponent } from 'ngx-ckeditor';
import { debounceTime } from 'rxjs/operators';
import { FilterModalComponent } from '../../editor/main/filter-modal/filter-modal.component';
import { SharedBrowserExtensionService } from 'src/app/_services/shared-browser-extension.service';
import { TranslationObject } from 'src/app/_interfaces/editor/transationObject';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  // Constants
  styleGuide: string = 'NONE';
  EVENT_LOOP_DELAY: number = 2000;
  COMMENT_ID_SESSION_COUNTER: number = Math.round(Math.random() * 10000);
  LAST_KNOWN_TEXT: string = "last_known_text";
  SENTENCE_TAG: string = "sentence";
  ALERT_TAG: string = "gcalert";
  VALID_TEXT: RegExp = /[a-zA-Z0-9]+/g;
  TERMINAL: RegExp = /[.!?]/g;
  TEXT_NODE: number = 3;
  COMMENT_NODE: number = 8;
  ELEMENT_NODE: number = 1;
  POSITION_PRECEDING: number = 4;
  CHUNK_SIZE: number = 100;
  HEALTH_CHECK_TIMEOUT: number = 60000;
  CHUNK_RETRY_TIMEOUT: number = 10000;
  REQUEST_RETRY_TIMEOUT: number = 60000;
  CHECK_TYPE: string = "check";
  SPLIT_TYPE: string = "split";
  SUPER_SCRIPT: string = "sup";
  SUB_SCRIPT: string = "sub";
  ALERT_REGEX: RegExp = /<\/?gcalert[^><]*>/g;
  SENTENCE_REGEX: RegExp = /<\/?sentence[^><]*>/g;
  COMPETITOR_TAGS = ["grammarly-popups", "grammarly-card", "grammarly-extension", "grammarly-btn"];
  COMPETITOR_BLOCK_SUPPORTED_BROWSER = new Set(["chrome", "firefox", "safari"]);
  INVALID_TAGS_WITHIN_ALERT = ['img', 'sup', 'sub'];
  CONNECTION_STATUS: string = 'ONLINE';
  IS_CONNECTED: boolean = true;
  SPECIAL_SPACES = /[\u200B-\u200D\uFEFF]/g;

  COMMENT_HOLDER: string = "<div comment-holder=\"true\"><span start=\"true\" comment=\"_cmnt1\"><span></span></span><span end=\"true\" comment=\"_cmnt1\"></span></div>";
  @ViewChild(CKEditorComponent, { static: true }) ckEditor: CKEditorComponent;
  // Constants end
  lastSavedTime: Date = new Date();
  saving: boolean = false;
  readOnly: boolean = false;
  isInitialised: boolean = false;
  toggleFlag: boolean = false;
  fileHolder = new FileObject();
  innerHeight: number;
  editorValue: any = "";
  alertList: Array<any> = [];
  requestToElementMap: Map<string, any> = new Map();
  contentChangeObservable: Subject<any> = new Subject();
  healthCheckObservable: Subject<any> = new Subject();
  chunkObservable: Subject<any> = new Subject();
  documentId: string;
  paramObs: any;
  userId: string;
  preBody: string;
  postBody: string;
  trinkaOnHold = false;
  summaryDetails = {
    'total_error': 0,
  };
  enableSending = false;
  selectedCat = false;
  grammar: boolean = false;
  spelling: boolean = false;
  enhancement: boolean = false;
  advisor: boolean = false;
  master_checked: boolean = true;
  master_indeterminate: boolean = false;

  // Config declaration
  config = {
    startupFocus: true,
    allowedContent: true,
    disableNativeSpellChecker: true,

    toolbar: [
      { name: 'styles', items: ['Format', 'Font', 'FontSize'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
      { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', '-', 'NumberedList', 'BulletedList'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'insert', items: ['Table', 'SpecialChar', 'PageBreak'] },
    ],
    contentsCss: ["div[id^='_cmnt'] {display: none;},body{overflow-y: hidden}", "table{width: 100%!important;max-width:100%}", "pre{white-space: pre-wrap;}", "gcalert{border-bottom-style: solid;}", ".grammar{border-bottom-color: #763293;}", ".spelling{border-bottom-color: #fd9a00;}", ".suggestion{border-bottom-color: #ff5c5d;}", ".grammar_active{background-color: rgba(118,50,147,0.4);}",
      ".spelling_active{background-color: rgba(253,154,0,0.4);}", ".suggestion_active{background-color: rgba(255,92,93,0.4);}", ".sticky-top{top: 0;bottom: 0;z-index: 1;position: fixed;height: 30px;background: white;width: 100%;}", ".tempTagRemove{border-bottom-style: none !important;background-color: white !important;}"],
    removePlugins: 'iframe,contextmenu,liststyle,tabletools,tableselection,magicline,link',
    stylesSet: "/src/styles.css",
    extraPlugins: 'autogrow,confighelper',
    language: 'en',
    autoGrow_onStartup: true,
    autoGrow_minHeight: window.innerHeight - 100,
    autoGrow_maxHeight: 150000,
  }
  styleGuideDispay = {
    "NONE": "NONE",
    "AMA": "AMA (11th ed)",
    "APA": "APA (7th ed)",
    "ACS": "ACS (2nd ed)",
    "AGU": "AGU (2017 ed)"
  }
  document_type: any = 1;
  languageTranslation: TranslationObject;
  selectedLanguageCode: string[] = ['en'];

  constructor(
    private networkCalls: OnlineEditorService,
    private socketService: ConnectionService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public sharedService: SharedBrowserExtensionService,
    private changeDetector: ChangeDetectorRef,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private connectionStatusService: ConnectionStatusService,
    private subjectiveBehaviourService: SubjectiveBehaviorSharedService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.featurePermissionRequest();
    this.connectionStatusService.monitor().subscribe(isConnected => {
      this.IS_CONNECTED = isConnected;
      if (this.IS_CONNECTED) {
        this.CONNECTION_STATUS = "ONLINE"
      } else {
        this.CONNECTION_STATUS = "OFLINE"
      }
    })
  }

  ngAfterViewInit() {
    this.getSelectedStyleGuide();
    setTimeout(() => {
      //this.getSelectedDocumentType();
    }, 10000);
    this.onResize(event);
  }

  getSelectedStyleGuide() {
    this.subjectiveBehaviourService.getStyleGuide.subscribe(styleguide => {
      this.styleGuide = styleguide[0]
      if (styleguide[0] == "NONE") {
        this.sharedService.filterCheckList[4].display = false;
        this.sharedService.filterCheckList[4].checked = false;
      } else {
        this.sharedService.filterCheckList[4].display = true;
        this.sharedService.filterCheckList[4].checked = true;
        this.sharedService.filterCheckList[4].name = this.styleGuideDispay[styleguide[0]] + " Style";
      }
      if (styleguide[0] && styleguide[1]) {
        this.sentDataCall(() => window.location.reload(), true);
      }
    })
  }

  /**paper_type = 1;
  added_subject_area = '';
  getSelectedDocumentType() {
    this.subjectiveBehaviorSharedService.getDocTypeObject.subscribe(document_type => {
      this.docTypeObject['selected_document_type'] = document_type['selected_document_type']
      this.docTypeObject['selected_paper_type'] = document_type['selected_paper_type']
      this.docTypeObject['user_added_subject_area'] = document_type['user_added_subject_area']
      this.docTypeObject['selected_subject_area'] = document_type['selected_subject_area']
      this.docTypeObject['subject_area_list'] = document_type['subject_area_list'] ? document_type['subject_area_list'] : []
    });
  }*/

  ngAfterViewChecked() {
    this.onResize(event);
    try {
      if (document.getElementById('cke_1_bottom') && document.getElementById('cke_editor-1')) {
        document.getElementById('cke_editor-1').style.border = 'none';
        document.getElementById('cke_1_bottom').style.display = 'none';
      }
      // document.getElementById('cke_1_top').style.background = 'transparent';
      // document.getElementById('cke_1_top').style.border = 'none';

    } catch (ngAfterViewInit) {

    }

  }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    this.onResize(event)
    this.route.params.subscribe(params => {
      this.documentId = params['id'];
      //this.documentId = "690b31d2-da58-41fa-96e0-b58d2cda685d"       //hardcode
      this.sharedService.setCurrentFileAndUserId(this.userId, this.documentId);
    });
    try {
      setTimeout(() => {
        document.getElementById('cke_editor-1').style.border = 'none';
        document.getElementById('cke_1_bottom').style.display = 'none';
        // document.getElementById('cke_1_top').style.background = 'transparent';
        // document.getElementById('cke_1_top').style.border = 'none';
      }, 3000);
    } catch (DOMException) {

    }
    this.onWindowScroll();

  }



  featurePermissionRequest() {
    this.networkCalls.featurePermission().subscribe(res => {
      this.sharedService.featurePermission = res;
      this.activeRoute.url.subscribe(() => {
          if(!this.sharedService.featurePermission.data.extension_route){
            this.router.navigate(['/404']);
          }
       });
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  pageConfigDetails = {};
  getPageConfig() {
    this.spinner.show();
    this.networkCalls.pageConfigRequest(this.userId).subscribe(result => {
      this.spinner.hide();
      if (result['status']) {
        this.pageConfigDetails = result['data'];
        this.getLanguageTranslationData(this.pageConfigDetails['localeCulture']);
        this.getSelectedProfileData();
      } else {
      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  getLanguageTranslationData(culture) {
    this.networkCalls.getLocalCultureData(culture).subscribe(res => {
      this.languageTranslation = res;
    }, error => {
    })
  }


  selectedProfile = {};
  getSelectedProfileData() {
    this.networkCalls.getSelectedProfileData(this.userId).subscribe(result => {

      if (result["status"]) {
        this.selectedProfile = result['data']['profile_data'];
        //this.enagoProofReadingfForm.get('subjectArea').setValue(result['data']['profile_data']['othersubjectarea']); //ONHOLD
      } else {

      }
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  private getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  private addOrUpdateStatsComment() {
    let body = this.ckEditor.instance.document.getBody();
    let statsComment = body.findOne('#_cmnt1');
    if (!statsComment) {
      statsComment = this.createComment("1");
    }
    statsComment = statsComment.findOne('p>span');

    let statsCommentHtml = 'There are no unresolved Trinka alerts for your file.';
    let length = this.alertList.length;
    if (length > 0) {
      statsCommentHtml = (length === 1) ? 'is ' + length + ' unresolved Trinka alert' : 'are ' + length + ' unresolved Trinka alerts';
      statsCommentHtml = 'There ' + statsCommentHtml + ' for your file. Please resolve them by opening the document at <a href="https://cloud.trinka.ai/editor/main/' + this.documentId + '">cloud.trinka.ai.</a>';
    }
    statsComment.setHtml(statsCommentHtml);
    this.onChange(null);
  }

  private createComment(id: string) {
    let document = this.ckEditor.instance.document;

    let anchorSpan = document.createElement('span');
    anchorSpan.setText('[a1]');

    let anchor = document.createElement("a");
    anchor.setAttribute('href', "#_cmntref" + id);
    anchor.append(anchorSpan);

    let commentSpan = document.createElement('span');
    commentSpan.setText('');

    let commentParagraph = document.createElement('p');
    commentParagraph.append(anchor);
    commentParagraph.append(commentSpan);

    let commentDiv = document.createElement("div");
    commentDiv.setAttribute("id", '_cmnt' + id);
    commentDiv.setAttribute("style", `-aw-comment-author:'Trinka'; -aw-comment-datetime:'${(new Date()).toISOString()}'; -aw-comment-initial:'a'`);
    commentDiv.append(commentParagraph);

    document.getBody().append(commentDiv);

    return commentDiv;
  }

  private clearTags() {
    this.COMPETITOR_TAGS.forEach((tag) => {
      if (document.querySelector(tag)) {
        document.querySelector(tag).remove()
      }
    })
  }

  private getSentenceID(node) {
    try {
      const potential_parent = node.getParent()
      if (potential_parent.getName() === "sentence") {
        return potential_parent.getUniqueId()
      }
      else {
        return this.getSentenceID(potential_parent)
      }
    } catch{
      return null
    }
  }

  onlyNonCTACardsLeft(alertList): boolean {
    let onlynonctas = true
    alertList.forEach((alert, j) => {
      if (alert.suggestions[0].cta_present) {
        onlynonctas = false
      }
    })
    return onlynonctas
  }

  removeComments() {

  }

  acceptAllCards() {
    if (this.alertList.length > 50) {
      this.spinner.show()
    }
    let processed_sentences = []
    let alertToRemove = []
    let onlynonctas = this.onlyNonCTACardsLeft(this.alertList)

    this.alertList.forEach((alert, j) => {
      if (onlynonctas) {
        const cval = this.COMMENT_ID_SESSION_COUNTER++
        const commidattr = "#_cmntref" + cval
        const commnameattr = "_cmnt" + cval
        // Use this to create new ekeditor html elements
        const ckdocument = this.ckEditor.instance.document.getBody().getDocument()


        const outerspan = ckdocument.createElement("span")
        outerspan.setAttribute("start", "true")
        outerspan.setAttribute("comment", commnameattr)

        const innerSpan = ckdocument.createElement("span")
        innerSpan.setText(alert.text)
        outerspan.append(innerSpan)

        const adjspan = ckdocument.createElement("span")
        adjspan.setAttribute("end", "true")
        adjspan.setAttribute("comment", commidattr)
        adjspan.insertAfter(alert.node)
        alert.node.insertBeforeMe(outerspan)


        const commDiv = ckdocument.createElement("div")
        commDiv.setAttribute("id", commnameattr)
        var dd = new Date()
        commDiv.setAttribute("style", `-aw-comment-author:'${atob(this.userId)}'; -aw-comment-datetime:'${dd.toISOString()}'; -aw-comment-initial:'a'`)

        const divp = ckdocument.createElement("p")
        const anchorp = ckdocument.createElement("a")
        anchorp.setAttribute("href", commidattr)

        const anchspan = ckdocument.createElement("span")
        anchspan.setText("[a1]")

        const adjanchspan = ckdocument.createElement("span")
        adjanchspan.setText(alert.suggestions[0].comment)
        anchorp.append(anchspan)
        divp.append(anchorp)
        divp.append(adjanchspan)
        commDiv.append(divp)
        alert.node.remove()
        alertToRemove.push(alert)

        // this.ckEditor.instance.insertElement(commDiv)
        const ckbody = this.ckEditor.instance.document.getBody()
        // ckbody.insertAfter(commDiv,alert.node.getParent())
        ckbody.append(commDiv)

      } else {
        const sentenceId = this.getSentenceID(alert.node)
        let i = 0;
        let ctaindexfound = true
        alert.suggestions.forEach((suggestion, ind) => {
          if (!ctaindexfound && suggestion.cta_present) {
            ctaindexfound = true;
            i = ind
          }
        })
        if (sentenceId != null && alert.suggestions[i].cta_present && !processed_sentences.includes(sentenceId)) {
          const suggestion = alert.suggestions[i]
          processed_sentences.push(sentenceId)
          var correction = suggestion['suggestion'];
          this.setTextAndRetainFormat(alert['node'], correction);
          this.socketService.accept(alert, suggestion, '', '', '');
          alertToRemove.push(alert)
        }
      }

      // processed_sentences.push(sentenceId)
      //     alertToRemove.push(alert)
      // else{
      // }
    })

    // change only the below three lines if you don't want the changes to reflect(debug only)
    alertToRemove.forEach((alert) => {
      this.removeCard(alert);
    })
    if (this.alertList.length > 0) { this.alertList[0]['isExpanded'] = true; }
    this.changeDetector.detectChanges();
    this.spinner.hide()
  }

  private handleExtension(browserName: string, target: string) {
    /*
      currently browser is only required, in future target name will help specify the arrays to clear the tags array inside clearTags method
      Note - Grammarly doesn't have an extension in opera browser
    */
    if (this.COMPETITOR_BLOCK_SUPPORTED_BROWSER.has(browserName)) {
      this.clearTags()
    }
  }

  public onFocus(event) {
    setTimeout(() => {
      const browserName = this.getBrowserName()
      this.handleExtension(browserName, "grammarly")
    }, 1000);
  }


  public onEditorReady() {
    if (!this.isInitialised) {

      this.contentChangeObservable
        .pipe(debounceTime(this.EVENT_LOOP_DELAY))
        .subscribe(() => this.saveTextFile());

      this.healthCheckObservable
        .pipe(debounceTime(this.HEALTH_CHECK_TIMEOUT))
        .subscribe(() => this.connnectionHelthCheck());

      this.chunkObservable
        .pipe(debounceTime(this.CHUNK_RETRY_TIMEOUT))
        .subscribe(() => this.scheduleRetry());

      if (this.documentId) {
        this.getFileByIdCall();
      }
      this.isInitialised = true;
    }
  }

  private connectToSocket() {
    //this.socketService.connectToSocket(this.documentId);

    this.socketService.connectToSocket(this.documentId);

    this.socketService.socket.addEventListener('open', () => {
      this.saveTextFile();
    });
    this.socketService.socket.addEventListener('close', () => {
    });
    this.socketService.socket.addEventListener('message', (event) => {
      var response = JSON.parse(this.socketService.decrypt_message(event.data));
      if (!this.ckEditor.instance) {
        return;
      }
      if (response['type'] === 'ping') {
        this.socketService.ping();
        return;
      }
      switch (response['conn_status']) {
        case 1:
          this.trinkaOnHold = false;
          this.readOnly = false;

          if (response['response']) { this.processMessage(response); }
          if (this.requestToElementMap.size == 0) {
            this.scheduleRetry();
          }
          break;
        case 3:
          this.toastr.warning(response['message']);
          // this.sharedService.SignOut();
          this.readOnly = true;
          this.requestToElementMap.clear();
          this.enableSending = false;
          this.saveTextFile();
          break;
        case 4:
          this.toastr.warning(response['message']);
          setTimeout(() => {
            this.sharedService.SignOut();
          }, 2000)
          break;
        default:
          // this.toastr.warning(response['message']);
          this.trinkaOnHold = true;
          this.enableSending = false;
          this.readOnly = false;
          break;
      }
    });
  }

  enableSendingRequest() {
    this.networkCalls.enableEditingCall(this.documentId).subscribe(result => {
      window.location.reload();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }
  private connnectionHelthCheck() {
    if (this.requestToElementMap.size > 0) {
      this.requestToElementMap.clear();
      this.socketService.close();
      if (this.IS_CONNECTED) {
        this.connectToSocket();
      }
    }
  }



  processMessage(response) {
    switch (response['type']) {
      case this.CHECK_TYPE: {
        this.processAlerts(response['response'], response['sentence']);
        this.calculateSummary();
        break;
      }
      case this.SPLIT_TYPE: {
        this.createSentences(response['response'], response['text']);
        break;
      }
    }
  }

  private scheduleRetry() {
    this.enableSending = true;
    this.saveTextFile();
  }


  itemList = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
  private calculateSummary(flag = false, alert = null) {
    // if (this.alertList.length > 0) {
    this.summaryDetails['total_error'] = this.alertList.length;
    if (!flag) {
      this.itemList = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
      this.alertList.map(value => {
        this.itemList[value.suggestions[0].type] += 1;
      })
    } else {
      this.sharedService.filterCheckList[alert.suggestions[0].type == 0 ? alert.suggestions[0].type : alert.suggestions[0].type - 1].errorCount = this.itemList[alert.suggestions[0].type] - 1;
    }
    if (this.alertList.length) {
      this.sharedService.filterCheckList[0].errorCount = this.itemList[1] // Grammar
      this.sharedService.filterCheckList[1].errorCount = this.itemList[2] //Spelling
      this.sharedService.filterCheckList[2].errorCount = this.itemList[3] //Writting advisor
      this.sharedService.filterCheckList[3].errorCount = this.itemList[4] // Enhancements
      this.sharedService.filterCheckList[4].errorCount = this.itemList[5] // Style Guide
    } else {
      this.sharedService.filterCheckList[0].errorCount = 0;
      this.sharedService.filterCheckList[1].errorCount = 0;
      this.sharedService.filterCheckList[2].errorCount = 0;
      this.sharedService.filterCheckList[3].errorCount = 0;
      this.sharedService.filterCheckList[4].errorCount = 0;
    }
    // }
  }

  displayVisibleErrorCount() {
    let totalCount = 0;
    if (this.sharedService.filterCheckList[0].checked) { totalCount += this.sharedService.filterCheckList[0].errorCount; }
    if (this.sharedService.filterCheckList[1].checked) { totalCount += this.sharedService.filterCheckList[1].errorCount; }
    if (this.sharedService.filterCheckList[2].checked) { totalCount += this.sharedService.filterCheckList[2].errorCount; }
    if (this.sharedService.filterCheckList[3].checked) { totalCount += this.sharedService.filterCheckList[3].errorCount; }
    if (this.sharedService.filterCheckList[4].checked) { totalCount += this.sharedService.filterCheckList[4].errorCount; }

    return totalCount;
  }

  displayNoError() {
    return this.isInitialised
      && !this.readOnly
      && !this.trinkaOnHold
      && (this.alertList.length === 0)
      && (this.requestToElementMap.size === 0)
      && (this.ckEditor.instance.document.getBody().getElementsByTag(this.SENTENCE_TAG).count() > 0);
  }

  selectedCategories() {
    var count = 0;
    if (this.sharedService.filterCheckList[0].checked) { count += 1; }
    if (this.sharedService.filterCheckList[1].checked) { count += 1; }
    if (this.sharedService.filterCheckList[2].checked) { count += 1; }
    if (this.sharedService.filterCheckList[3].checked) { count += 1; }
    if (this.sharedService.filterCheckList[4].checked) { count += 1; }
    return count;
  }


  filterCategories() {
    var countList = new Set();
    if (this.sharedService.filterCheckList[0].checked) {
      countList.add(1);
    } else {
      if (countList.has(1)) {
        countList.delete(1);
      }
    }
    if (this.sharedService.filterCheckList[1].checked) {
      countList.add(2);
    } else {
      if (countList.has(2)) {
        countList.delete(2);
      }
    }
    if (this.sharedService.filterCheckList[2].checked) {
      countList.add(3);
    } else {
      if (countList.has(3)) {
        countList.delete(3);
      }
    }
    if (this.sharedService.filterCheckList[3].checked) {
      countList.add(4);
    } else {
      if (countList.has(4)) {
        countList.delete(4);
      }
    }
    if (this.sharedService.filterCheckList[4].checked) {
      countList.add(5);
    } else {
      if (countList.has(5)) {
        countList.delete(5);
      }
    }
    return countList;
  }

  public onChange(event) {
    this.contentChangeObservable.next(event);
  }


  public onKeyUp(event) {
    try {

    } catch (error) {

    }
  }

  @HostListener('window:load', ['$event'])
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHeight = window.innerHeight;
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    try {
      var toolbar = document.getElementById('cke_1_top')
      if (document.documentElement.scrollTop > 50) {
        toolbar.style.top = '0';
        toolbar.style.bottom = '0';
        toolbar.style['z-index'] = '1';
        toolbar.style.position = 'fixed';
        toolbar.style.height = '44px';
        toolbar.style.background = 'white';
        toolbar.style.width = 'calc(100% - 33.33%)';
        toolbar.style.left = '44px';
        toolbar.style.borderBottom = 'none';
        toolbar.style.boxShadow = '0px 0px 9px rgba(0, 0, 0, 0.21)';
        document.getElementsByClassName('filter')[0]['classList']['add']('errorPanelToolbar');
        document.getElementsByClassName('errorPanel')[0]['classList']['add']('errorWindowScroll');
        document.getElementsByClassName('filter_toggle_button')[0]['classList']['add']('mobile_alerts');
      } else {
        document.getElementsByClassName('filter')[0]['classList']['remove']('errorPanelToolbar');
        document.getElementsByClassName('errorPanel')[0]['classList']['remove']('errorWindowScroll');
        document.getElementsByClassName('filter_toggle_button')[0]['classList']['remove']('mobile_alerts');
        toolbar.style.removeProperty('top');
        toolbar.style.removeProperty('bottom');
        toolbar.style.removeProperty('position');
        // toolbar.style.removeProperty('background');
        toolbar.style.removeProperty('width');
        toolbar.style.removeProperty('z-index');
        toolbar.style.height = 'auto';
        toolbar.style.borderBottom = 'none';
        toolbar.style.boxShadow = 'none';
      }
    } catch (error) {
    }
  }

  openFilterModal() {
    var filterModal = this.dialog.open(FilterModalComponent);
    filterModal.afterClosed().subscribe(result => {
    });
  }

  docTypeObject = {
    selected_document_type: null,
    selected_paper_type: null,
    user_added_subject_area: null,
    selected_subject_area: null,
    subject_area_list: null,
  };
  getFileByIdCall() {
    this.spinner.show();
    this.networkCalls.getFileById(this.userId, this.documentId).subscribe(result => {
      var content = result.data.content.split(/<\/?body[^>]*>/g);
      this.fileHolder = result;
      this.preBody = content[0]
      var editiorContent = "" + content[1];
      editiorContent = editiorContent.replace(this.ALERT_REGEX, "").replace(this.SPECIAL_SPACES, "").replace(this.COMMENT_HOLDER, "");
      this.editorValue = editiorContent;
      this.postBody = content[2];
      this.changeDetector.detectChanges();
      this.subjectiveBehaviourService.changeStyleGuide(this.fileHolder.data.style_guide, false);

      this.docTypeObject = {
        selected_document_type: this.fileHolder.data.document_type,
        selected_paper_type: this.fileHolder.data.paper_type,
        user_added_subject_area: this.fileHolder.data.added_subject_area,
        selected_subject_area: this.fileHolder.data.selected_subject_area,
        subject_area_list: JSON.parse(JSON.stringify(this.fileHolder.data.subject_area_list))
      }
      this.docTypeObject['subject_area_list'] = this.docTypeObject['subject_area_list'].sort((a, b) => b.user_added_flag - a.user_added_flag).sort((a, b) => b.isSelected - a.isSelected)
      this.subjectiveBehaviourService.setDocumentTypeData(this.docTypeObject, false);
      this.spinner.hide();
      if (this.IS_CONNECTED) {
        this.connectToSocket();
      }
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  muteCard(alert, suggestion, index, parrentIndex) {
    // var correction = suggestion['suggestion'];
    // this.setTextAndRetainFormat(alert['node'], correction);
    this.socketService.mute(alert, suggestion, this.fileHolder.data.language);
    this.removeCard(alert);
    this.removeSimillarCards(alert);
    if (this.alertList[parrentIndex + 1]) { this.alertList[parrentIndex]['isExpanded'] = true; }
  }

  acceptCard(alert, suggestion, index, parrentIndex) {
    var correction = suggestion['suggestion'];
    this.setTextAndRetainFormat(alert['node'], correction);
    this.socketService.accept(alert, suggestion, '', '', '');
    this.removeCard(alert);
    if (this.alertList[parrentIndex + 1]) { this.alertList[parrentIndex]['isExpanded'] = true; }
  }

  ignoreCard(alert, parrentIndex) {
    alert.suggestions.forEach((suggestion) => {
      this.socketService.ignore(alert, suggestion, '', '', '');
    })
    this.removeCard(alert);
    if (this.alertList[parrentIndex + 1]) { this.alertList[parrentIndex]['isExpanded'] = true; }
  }

  rejectSuggestions(alert, parrentIndex) {
    alert.suggestions.forEach((suggestion) => {
      this.socketService.reject(alert, suggestion, '', '', '');
    })
    this.removeCard(alert);
    if (this.alertList[parrentIndex + 1]) { this.alertList[parrentIndex]['isExpanded'] = true; }
  }

  removeSimillarCards(alert) {
    var removeItemList = []
    this.alertList.forEach((item, index) => {
      if (item.suggestions[0].type == 2) {
        if (item.text.toLowerCase() == alert.text.toLowerCase()) {
          removeItemList.push(item);
        }
      }
    });
    removeItemList.forEach(element => {
      this.removeCard(element);
    });
  }


  private removeCard(alert) {
    alert['node'].remove(true);
    this.saveTextFile();
    this.calculateSummary(true, alert);
    this.addOrUpdateStatsComment();
  }

  showAlertTextOnEditor(original_scroll, original_element) {
    var editor_height = original_element.clientHeight
    var half_window = editor_height / 2;
    // var half_window = window.innerHeight / 2;
    // var half_window = 200;
    if ((original_scroll - original_element.scrollTop) != 0) {
      // scrolled down
      original_element.scrollTop = original_element.scrollTop - (half_window)
    }
  }
  ScrollErrorPanel(original_scroll, index) {
    var scroll_offset = window.innerHeight * 0.3;
    let card_scroll_val = (index * (48 + 10)) - scroll_offset;
    var errorPanel = document.getElementsByClassName('errorPanel');
    setTimeout(() => {
      errorPanel[0].scrollTop = card_scroll_val;
    }, 500);
  }

  openCard(alert) {
    var index = this.alertList.filter((element, array, index) => {
      return this.filterCategories().has(element.suggestions[0].type);
    }).findIndex(itm => itm == alert);
    var original_element = document.getElementsByClassName("outer_scroll_sections")
    var original_scroll = original_element[0].scrollTop
    alert['node'].scrollIntoView(true)
    this.showAlertTextOnEditor(original_scroll, original_element[0])
    this.ScrollErrorPanel(original_scroll, index)
    alert['node'].addClass(alert['alert_class'] + '_active');
    alert['isExpanded'] = true;
  }


  closeCard(alert) {
    alert['node'].removeClass(alert['alert_class'] + '_active');
    alert['isExpanded'] = false;
  }


  // Private Function
  private getAlertClass(alert) {
    if (alert.suggestions[0].type == 1) {
      return "grammar";
    } else if (alert.suggestions[0].type == 2) {
      return "spelling"
    } else {
      return "suggestion"
    }
  }

  private saveTextFile() {
    if (this.readOnly) {
      var body = this.ckEditor.instance.document.getBody();
      this.removeSentences(body);
      this.scanAlerts();
      this.calculateSummary();
    } else {
      this.processDocumentForBreak();
      this.scanDocument();
      this.scanAlerts();
      this.calculateSummary();
      this.sentDataCall();
    }
  }

  private scanAlerts() {
    for (var i = this.alertList.length - 1; i >= 0; i--) {
      if (!this.alertList[i]['node'].isVisible()) {
        this.alertList.splice(i, 1);
      }
    }
    this.addOrUpdateStatsComment();
  }
  savingSection = false;
  interval = null;

  private sentDataCall(callback?, forceUpdate: boolean = false) {
    var content = this.ckEditor.instance.document.getBody().getHtml();
    content = content.replace(this.ALERT_REGEX, "").replace(this.SENTENCE_REGEX, "").replace(this.SPECIAL_SPACES, "");
    content = this.preBody + "<body>" + this.COMMENT_HOLDER + content + "</body>" + this.postBody;
    // Document type payload
    this.fileHolder.data.document_type = this.docTypeObject['selected_document_type'];
    this.fileHolder.data.paper_type = this.docTypeObject['selected_paper_type'];
    this.fileHolder.data.selected_subject_area = this.docTypeObject['selected_subject_area'];
    this.fileHolder.data.added_subject_area = this.docTypeObject['user_added_subject_area'];
    this.fileHolder.data.subject_area_list = this.docTypeObject['subject_area_list'].sort((a, b) => b.user_added_flag - a.user_added_flag).sort((a, b) => b.isSelected - a.isSelected);
    if (forceUpdate || this.fileHolder.data.content !== content) {
      this.savingSection = true;
      this.fileHolder.data.content = content;
      this.saving = true;
      if (this.interval != null) {
        clearInterval(this.interval);
      }

      //this.fileHolder.data['style_guide'] = this.styleGuide ? this.styleGuide : "NONE"
      this.networkCalls.putUpdateFile(this.userId, this.fileHolder.data).subscribe(result => {
        this.saving = false;
        this.savingSection = true;
        this.lastSavedTime = new Date();
        if (callback) { callback(); }

        this.interval = setInterval(() => {
          this.savingSection = false;
        }, 3000);
      }, error => {
        this.saving = false;
        this.sharedService.errorHandller(error);
      });
    }
  }

  // public getLastSaved() {
  //   var time = new Date().getTime() - this.lastSavedTime.getTime();
  //   time = Math.floor(time / 1000);

  //   if (time < 60) { return time + ' seconds'; }

  //   time = Math.floor(time / 60)
  //   if (time < 60) { return time + ' minutes'; }

  //   time = Math.floor(time / 60);
  //   if (time < 24) { return time + ' hours' }

  //   time = Math.floor(time / 24);
  //   return time + ' days';
  // }

  private addAlert(alert) {
    var minIndex = 0;
    var maxIndex = this.alertList.length;
    while (maxIndex - minIndex > 1) {
      var index = Math.floor((maxIndex + minIndex) / 2);
      if (this.alertList[index]['node'].getPosition(alert['node']) === this.POSITION_PRECEDING) {
        minIndex = index;
      } else {
        maxIndex = index;
      }
    }
    if (minIndex === 0 && this.alertList.length > 0 && this.alertList[minIndex]['node'].getPosition(alert['node']) !== this.POSITION_PRECEDING) {
      maxIndex = 0;
    }
    alert['isVisible'] = true;
    this.alertList.splice(maxIndex, 0, alert);
  }

  private canProcessRequest(element, type: string) {
    var key = type + "_" + element.getText().trim();
    if (this.requestToElementMap.has(key)) {
      var request = this.requestToElementMap.get(key);
      if (request['next_retry'] >= Date.now()) {
        return false;
      }
    }
    return true;
  }

  private createRequest(element, type: string) {
    var key = type + "_" + element.getText().trim();
    var request = {
      'element': element,
      'next_retry': (Date.now()) + this.REQUEST_RETRY_TIMEOUT
    }
    this.requestToElementMap.set(key, request);
  }

  private getAndDeleteRequest(text: string, type: string) {
    var key = type + "_" + text.trim();
    if (this.requestToElementMap.has(key)) {
      var request = this.requestToElementMap.get(key);
      this.requestToElementMap.delete(key);
      return request['element'];
    }
    return null;
  }

  private processDocumentForBreak() {
    var body = this.ckEditor.instance.document.getBody();
    if (body.getText().trim() == '') {
      return;
    }
    var breaks = body.getElementsByTag('br');
    while (breaks.count() > 0) {
      this.fixDomBr(breaks.getItem(0));
      var breaks = body.getElementsByTag('br');
    }
  }

  private fixDomBr(breaker) {
    while (!breaker.getParent().isBlockBoundary()) {
      this.breakParent(breaker);
    }
    this.breakParent(breaker);
    this.insertSpace(breaker.getPrevious());
    this.insertSpace(breaker.getNext());
    breaker.remove();
  }

  private breakParent(breaker) {
    var parent = breaker.getParent();
    if ((breaker.getParent().getChildCount() - 1) != breaker.getIndex() && breaker.getIndex() != 0) {
      breaker.breakParent(parent);
    }
    if (breaker.getIndex() == 0) {
      breaker.insertBefore(parent);
    } else {
      breaker.insertAfter(parent);
    }
  }

  private insertSpace(element) {
    if (element == null) {
      return;
    }
    let text = element.getText();
    text = text.replace(this.SPECIAL_SPACES, '');
    if (text !== "") {
      return;
    }
    while (element.getChildCount() > 0) {
      let child = element.getChild(0);
      if (child.type != this.ELEMENT_NODE) {
        break;
      }
      element = child;
    }
    element.setText("\u00A0");
  }
  // Private Function end




  // Grammar Checking Logic
  private setTextAndRetainFormat(node, text) {
    while (node.getChildCount() > 0 && node.getChild(0).type == this.ELEMENT_NODE) {
      for (let index = 1; index < node.getChildCount();) {
        node.getChild(index).remove();
      }
      node = node.getChild(0);
    }
    node.setText(text);
  }

  private scanDocument() {
    var body = this.ckEditor.instance.document.getBody();
    var sections = this.hasAllSections(body) ? this.getSections(body) : [body];
    sections.forEach(node =>
      this.processSection(node)
    );
  }

  private getSections(element) {
    var sections = [];
    for (let index = 0; index < element.getChildCount(); index++) {
      var child = element.getChild(index);
      if (!this.isCommentSection(child)) {
        if (this.hasAllSections(child)) {
          sections = sections.concat(this.getSections(child));
        } else {
          sections.push(child);
        }
      }
    }
    return sections;
  }

  private isCommentSection(element) {
    let id = element.getId();
    return (id != null && id.startsWith("_cmnt"));
  }

  private hasAllSections(element) {
    var children = element.getChildren();
    for (let index = 0; index < children.count(); index++) {
      var child = children.getItem(index);
      if (child.type != this.ELEMENT_NODE || !child.isBlockBoundary()) {
        return false;
      }
    }
    return true;
  }

  private processSection(section) {
    if (section.getText().trim() == "") {
      // do nothing
    } else if (this.isProcessingRequired(section)) {
      if (this.isSingleSentence(section)) {
        var sentences = section.getElementsByTag(this.SENTENCE_TAG);
        var modifiedSentences = this.getModifiedSentences(sentences);
        this.rememberText(section);
        modifiedSentences.forEach(sentence => this.processSentence(sentence));
      } else {
        this.removeSentences(section);
        this.splitSection(section);
      }
    } else {
      this.scanSection(section);
    }
  }

  private getModifiedSentences(sentences) {
    var modifiedSentences = [];
    for (let index = 0; index < sentences.count(); index++) {
      const element = sentences.getItem(index);
      if (this.isProcessingRequired(element)) {
        modifiedSentences.push(element);
      }
    }
    return modifiedSentences;
  }

  private isSingleSentence(sentence) {
    var lastKnownText = sentence.getCustomData(this.LAST_KNOWN_TEXT);
    if (lastKnownText) {
      var lastKnownTerminals = (lastKnownText.match(this.TERMINAL) || []).length
      var currentTerminals = (sentence.getText().match(this.TERMINAL) || []).length
      return lastKnownTerminals === currentTerminals;
    } else {
      return false;
    }
  }

  private removeSentences(section) {
    this.removeTags(section, this.ALERT_TAG);
    this.removeTags(section, this.SENTENCE_TAG);
  }

  private splitSection(section) {
    var text = section.getText();
    if (text.match(this.TERMINAL)) {
      if (this.enableSending) {
        if (this.requestToElementMap.size >= this.CHUNK_SIZE) {
          this.enableSending = false;
          this.chunkObservable.next();
        }
        if (this.canProcessRequest(section, this.SPLIT_TYPE)) {
          this.createRequest(section, this.SPLIT_TYPE);
          // this.socketService.split(text);
          this.socketService.split(text, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.docTypeObject.selected_document_type, this.styleGuide);
          this.healthCheckObservable.next();
        }
      }
    } else {
      var indexes = [{ 'begin': 0, 'end': text.length }];
      this.createTags(section, indexes, this.SENTENCE_TAG);
      this.rememberText(section);
      this.scanSection(section);
    }
  }



  private createSentences(sentences, text: string) {
    var section = this.getAndDeleteRequest(text, this.SPLIT_TYPE);
    if (section && section.isVisible() && text === section.getText()) {
      this.createTags(section, sentences, this.SENTENCE_TAG);
      this.rememberText(section);
      this.scanSection(section);
    }
  }

  private scanSection(section) {
    var sentences = section.getElementsByTag(this.SENTENCE_TAG);
    for (let index = 0; index < sentences.count(); index++) {
      var node = sentences.getItem(index);
      if (this.isProcessingRequired(node)) {
        this.processSentence(node);
      }
    }
  }

  private processSentence(sentence) {
    this.removeTags(sentence, this.ALERT_TAG);
    if (sentence.isVisible() && this.enableSending) {
      if (this.requestToElementMap.size >= this.CHUNK_SIZE) {
        this.enableSending = false;
        this.chunkObservable.next();
      }
      if (this.canProcessRequest(sentence, this.CHECK_TYPE)) {
        this.createRequest(sentence, this.CHECK_TYPE);
        this.socketService.check(sentence.getText(), this.fileHolder.data.language, "NONE", false, false, false,"", "", 1);
	// this.sharedService.featurePermission.data.sentence_fragmentation
        this.healthCheckObservable.next();
      }
    }
  }

  onLanguageTypeSelect() {
    if(environment.trialMode){
      return
    }
    else{
      this.sentDataCall(() => window.location.reload(), true);
    }
  }

  private processAlerts(alerts, text: string) {
    var sentence = this.getAndDeleteRequest(text, this.CHECK_TYPE);

    if (sentence && sentence.isVisible() && text === sentence.getText()) {
      this.rememberText(sentence);
      if (alerts.length > 0) {
        var indexes: Array<any> = [];
        for (const alert of alerts) {
          indexes.push({
            'begin': alert['begin'],
            'end': alert['end'] + 1
          });
        }
        this.createTags(sentence, indexes, this.ALERT_TAG, (index, node) => this.createAlert(alerts[index], node, text));
      }
    }
  }

  private createAlert(alert, node, sentence: string) {
    if (this.isValidAlert(node)) {
      alert['sentence'] = sentence
      alert['node'] = node;
      alert['alert_class'] = this.getAlertClass(alert);
      node.addClass(alert['alert_class']);
      node.on("click", (event) => {
        this.openCard(alert);
        this.changeDetector.detectChanges();
      });
      this.addAlert(alert);
      this.addOrUpdateStatsComment();
    } else {
      node.remove(true)
    }
  }

  private isValidAlert(node) {
    for (var i = 0; i < this.INVALID_TAGS_WITHIN_ALERT.length; i++) {
      var tags = node.getElementsByTag(this.INVALID_TAGS_WITHIN_ALERT[i])
      if (tags.count() > 0) {
        return false
      }
    }
    return true
  }

  private isProcessingRequired(element) {
    if (element.type == this.COMMENT_NODE) {
      return false;
    }
    var currentText = element.getText().trim();
    if (!currentText.match(this.VALID_TEXT)) {
      return false;
    } else {
      var lastKnownText = element.getCustomData(this.LAST_KNOWN_TEXT);
      return currentText != lastKnownText;
    }
  }

  private removeTags(element, tag: string) {
    var ranges = this.ckEditor.instance.getSelection().getRanges();
    var nodes = element.getElementsByTag(tag);
    while (nodes.count() > 0) {
      nodes.getItem(0).remove(true);
      nodes = element.getElementsByTag(tag);
    }
    this.ckEditor.instance.getSelection().selectRanges(ranges);
  }

  private createTags(element, indexes, tag: string, callback?: Function) {
    var ranges = this.ckEditor.instance.getSelection().getRanges();
    indexes = this.adjustIndexes(indexes);
    var pendingNodes: Array<any> = [];
    pendingNodes.push(element);

    var currentIndex = 0;
    var newNode = [];
    var pendingLength = indexes[currentIndex]['end'] - 0;

    while (pendingNodes.length != 0) {
      var currentNode = pendingNodes.pop();

      if (currentNode.type == this.TEXT_NODE) {

        var currentNodeLength = currentNode.getLength();
        if (pendingLength > currentNodeLength) {
          pendingLength = pendingLength - currentNodeLength;
          newNode.push(currentNode);
        } else {
          if (pendingLength < currentNodeLength) {
            var nextTextNode = currentNode.split(pendingLength);
            pendingNodes.push(nextTextNode);
          }

          newNode.push(currentNode);
          this.fixDomAndAppend(element, newNode, tag, indexes[currentIndex], callback);

          var currentIndex = currentIndex + 1;
          if (indexes.length == currentIndex) {
            break;
          }
          var pendingLength = indexes[currentIndex]['end'] - indexes[currentIndex - 1]['end'];
          newNode = [];
        }

      } else if (currentNode.getChildCount() > 0) {
        var nodes = currentNode.getChildren();
        for (let index = nodes.count() - 1; index >= 0; index--) {
          pendingNodes.push(nodes.getItem(index));
        }
      }
    }

    if (newNode.length > 0) {
      this.fixDomAndAppend(element, newNode, tag);
    }

    try {
      this.ckEditor.instance.getSelection().selectRanges(ranges);
    } catch (DOMException) {
      /*
        Below exception occurs on Chrome when entire document is processed. In such cases, Firefox removes the focus from the editor.
        Hence, As of now, reflecting the same behavior in Chrome.

        core.js:6014 ERROR DOMException: Failed to execute 'setStart' on 'Range': The offset 531 is larger than the node's length (32).
          at CKEDITOR.dom.selection.selectRanges (http://cdn.ckeditor.com/4.7.1/full/ckeditor.js:461:130)
          at MainComponent.createTags (http://localhost:4200/main.js:2010:47)
          at SafeSubscriber._next (http://localhost:4200/main.js:1898:26)

      */
    }

  }

  private adjustIndexes(indexes: Array<any>) {
    var adjustedIndexes: Array<any> = [];
    for (let index = 0; index < indexes.length; index++) {
      var previousEnd = (index == 0) ? 0 : indexes[index - 1]['end'];
      if (indexes[index]['begin'] > previousEnd) {
        adjustedIndexes.push({
          'begin': previousEnd,
          'end': indexes[index]['begin'],
          'create': false,
          'original': -1
        });
      }
      indexes[index]['create'] = true;
      indexes[index]['original'] = index;
      adjustedIndexes.push(indexes[index]);
    }
    return adjustedIndexes;
  }

  private fixDomAndAppend(root, childNodes: Array<any>, newTag: string, index?: any, callback?: Function) {
    var firstNode = childNodes[0];
    var lastNode = childNodes[childNodes.length - 1];
    this.fixDom(root, lastNode);
    if (index != null && index['create']) {
      var newNode = this.appendAll(root, firstNode, lastNode, newTag);
      if (index['original'] != -1 && callback) {
        callback(index['original'], newNode);
      }
    }
  }

  private fixDom(root, lastNode) {
    var breaker = root.getDocument().createElement("break");
    breaker.insertAfter(lastNode);
    while (!root.equals(breaker.getParent())) {
      var parent = breaker.getParent();
      if ((breaker.getParent().getChildCount() - 1) != breaker.getIndex()) {
        breaker.breakParent(parent);
      }
      breaker.insertAfter(parent);
    }
    breaker.remove();
  }

  private appendAll(root, firstNode, lastNode, newTag) {
    var newNode = root.getDocument().createElement(newTag);
    var ancestor = firstNode.getCommonAncestor(lastNode);

    if (ancestor.equals(root)) {
      firstNode = this.getAscendant(root, firstNode);
      lastNode = this.getAscendant(root, lastNode);

      newNode.insertAfter(lastNode);
      while (!firstNode.equals(newNode.getPrevious())) {
        newNode.append(newNode.getPrevious(), true);
      }
      newNode.append(firstNode, true);

    } else {

      newNode.insertAfter(ancestor);
      ancestor.appendTo(newNode);
    }
    return newNode;
  }

  private getAscendant(parentNode, containsNode) {
    var parents = containsNode.getParents();
    for (let index = 0; index < parents.length; index++) {
      if (parents[index].equals(parentNode)) {
        return parents[index + 1];
      }
    }
  }

  private rememberText(element) {
    element.setCustomData(this.LAST_KNOWN_TEXT, element.getText().trim());
  }

  master_change() {
    for (let value of Object.values(this.sharedService.filterCheckList)) {
      value.checked = this.master_checked;
    }
  }

  list_change() {
    let checked_count = 0;
    //Get total checked items
    for (let value of Object.values(this.sharedService.filterCheckList)) {
      if (value.checked)
        checked_count++;
    }

    if (checked_count > 0 && checked_count < this.sharedService.filterCheckList.length) {
      // If some checkboxes are checked but not all; then set Indeterminate state of the master to true.
      // this.master_indeterminate = true;
      this.master_checked = false;
    } else if (checked_count == this.sharedService.filterCheckList.length) {
      //If checked count is equal to total items; then check the master checkbox and also set Indeterminate state to false.
      // this.master_indeterminate = false;
      this.master_checked = true;
    } else {
      //If none of the checkboxes in the list is checked then uncheck master also set Indeterminate to false.
      // this.master_indeterminate = false;
      this.master_checked = false;
    }
  }
  @HostListener('window:beforunload')
  ngOnDestroy() {
    if(this.sharedService.featurePermission.data.extension_route){
      this.socketService.close();
    }
  }
}
