import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { SharedService } from 'src/app/_services/shared.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FileObject } from 'src/app/_interfaces/editor/file';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef } from '@angular/material';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.scss']
})
export class DocumentTypeComponent implements OnInit {
  win  = window['dataLayer'] || {}
  userId: any;
  documentForm: FormGroup;
  document_type: any = 'Select';
  selectedPaperType: any[] = [];

  paperType = [
    { sno: 1, text: 'OriginalArticle', description: 'Original Article', isSelected: false },
    { sno: 2, text: 'CaseReport', description: 'Case Report', isSelected: false },
    { sno: 3, text: 'ReviewPaper', description: 'Review Paper', isSelected: false },
    { sno: 4, text: 'Communication', description: 'Communication', isSelected: false },
    { sno: 5, text: 'Other', description: 'Other', isSelected: false }
  ];

  subjectType = [
  ];

  isAutoMode: boolean = true;
  result: any;
  resultPaperType: any;
  subjectArea: string;
  subjectAreaList = [];
  subjectAreaData: any;
  subAreaLoaded: boolean = false;

  constructor(public fb: FormBuilder,
    public sharedService: SharedService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<DocumentTypeComponent>,
    private spinner: NgxSpinnerService,
    private networkCalls: OnlineEditorService,
    public subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    private onlineEditorService: OnlineEditorService,
    public amplitudeService: AmplitudeService) {
    this.documentForm = this.fb.group({
      subjectArea: new FormControl('', [Validators.required])
    });
    this.getSubjectList();
  }

  ngOnInit() {
    this.getSelectedDocumentType();
    this.getSubjectAreas();
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => (term === '' ? this.subjectAreaListArray
        : this.subjectAreaListArray.filter(v => v.text.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 100).map(item => {
          if (item.levelNo == 3) {
            return item.text;
          }
        }))
    );


  onTypeSubjectArea() {
  }
  onSubjectAreaCapture(model) {
    // this.subjectArea = model.item;
  }


  switchGeneralWriting() {
    this.docTypeObject['selected_document_type'] = 3
    this.subjectiveBehaviorSharedService.setDocumentTypeData(this.docTypeObject, false);
    if(this.docTypeObject['selected_document_type'] != 1)
    {
      this.amplitudeService.logAmplitude('writing_type_set',{
        'fileID': this.sharedService.currentFileId,
        'type': this.docTypeObject['selected_document_type'] == 3 ? 'academic' : 'general',
        'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
      });
    }
    this.dialogRef.close();
  }

  fileId: any;
  getSubjectList() {
    this.subAreaLoaded = true;
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.fileId = this.sharedService.currentFileId;
    this.onlineEditorService.subjectAreaValidator(this.userId, this.fileId).subscribe(resp => {
      this.subAreaLoaded = false;
      this.subjectAreaList = resp.data.suggested_subject_area;
      // get selected object
      let selObjs = [];
      let filterLabels = [];
      this.subjectType.forEach((subobj) => {
        if (subobj.isSelected || subobj.user_added_flag) {
          selObjs.push(subobj)
          filterLabels.push(subobj.label)
        }
      });
      this.subjectType = []
      selObjs.forEach((selObj) => {
        this.subjectType.push(selObj)
      })
      var found = false;
      if (this.subjectAreaList.length > 0) {
        for (let i = 0; i < this.subjectAreaList.length; i++) {
          if (filterLabels.includes(this.subjectAreaList[i].label)) {
            continue
          }
          this.subjectType.push({ label: this.subjectAreaList[i].label, level_1: this.subjectAreaList[i].level_1, level_2: this.subjectAreaList[i].level_2, isSelected: false, user_added_flag: false })
        }
      }
      // var extraList = [];
      // // ======= both list concat logic ==============
      // // user added SA
      // var tempUserAdded = this.subjectType.filter(item => {
      //   return item.user_added_flag && !item.user_added_flag;
      // });
      // if (tempUserAdded.length > 0) {
      //   this.subjectType.push(tempUserAdded);
      // }
      // //User Selected
      // var tempUserSelected = this.subjectType.filter(item => {
      //   return item.user_added_flag;
      // });
      // // ======= both list concat logic close==============
      // var listHolder = false;
      // for (let i = 0; i < this.subjectAreaList.length; i++) {
      //   for (let j = 0; j < tempUserSelected.length; j++) {
      //     if (this.subjectAreaList[i].label == tempUserSelected[j].label) {
      //       listHolder = true;
      //       this.subjectAreaList[i] = tempUserSelected[j];
      //     } else {
      //       listHolder = false;
      //     }
      //   }
      // }
      // if (tempUserSelected.length > 0 && !listHolder) {
      //   this.subjectType.push(tempUserSelected[0]);
      // }



    }, error => {
      this.sharedService.errorHandller(error);
      this.subAreaLoaded = false;
    });
  }


  user_added_subject_area: any;
  selected_subject_area: any;
  selected_paper_type: any;
  getSelectedDocumentType() {

    this.subjectiveBehaviorSharedService.getDocTypeObject.subscribe(document_type => {
      for (var element of this.paperType) {
        if (document_type.selected_paper_type === element.sno) {
          element.isSelected = true;
          this.selected_paper_type = element.sno;
        }
      }
      this.user_added_subject_area = document_type.user_added_subject_area;
      this.selected_subject_area = document_type.selected_subject_area;
      this.subjectType = JSON.parse(JSON.stringify(document_type.subject_area_list));
      // if (this.selected_subject_area != null && this.selected_subject_area != '') {
      //   for (const subjet of this.subjectType) {
      //     if (this.selected_subject_area == subjet.label) {
      //       subjet.isSelected = true;
      //     }
      //   }
      // }
    });
  }

  changeSelectedSubjectType(parameter: string, query: string) {
    const index = this.selectedPaperType.indexOf(query);
    if (index >= 0) {
      this.selectedPaperType.splice(index, 1);
    } else {
      this.selectedPaperType.push(query);
    }
  }

  changeSubjectType(subjectTypeObject) {
    subjectTypeObject.isSelected = !subjectTypeObject.isSelected;
    this.isAutoMode = subjectTypeObject;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  /**@description: for subject area changes */
  toggleChip(chip) {
    this.subjectType.forEach(item => {
      if(item != chip)
        item.isSelected = false;
    })
    chip.isSelected = !chip.isSelected ;
    this.selected_subject_area = chip.label;
  }

  paperTypeSel: any;
  // selected_paper_type: any
  togglePaperChip(chip) {
    this.paperType.forEach(c => {
      c.isSelected = false;
    });
    chip.isSelected = true;
    this.selected_paper_type = chip.sno;
  }

  /**@function: On form submit called */
  docTypeObject = {};
  savingSection = false;
  showSpinner = false;
  interval = null;
  onFormSubmit(documentForm) {
    this.showSpinner = true;
    if (this.documentForm.value)
      this.submitedSubjectArea(this.documentForm.value)
    this.docTypeObject = {
      selected_document_type: 3,
      selected_paper_type: this.selected_paper_type,
      user_added_subject_area: documentForm.subjectArea ? documentForm.subjectArea.trim() : "",
      selected_subject_area: documentForm.subjectArea ? documentForm.subjectArea.trim() : this.selected_subject_area,
      subject_area_list: this.subjectType
    }

    var data = {
      'document_type': 3,
      'paper_type': this.selected_paper_type,
      'subject_area_list': this.subjectType,
      'is_document_type': true
    }
    this.networkCalls.putUpdateFileForDocumentType(this.userId, this.sharedService.currentFileId, data).subscribe(resp => {


      this.showSpinner = false;
      if (resp['status']) {
        this.toastr.success("Your selection has been saved.");
        this.docTypeObject['selected_document_type'] = resp.data['document_type'];
        this.docTypeObject['selected_paper_type'] = resp.data['paper_type'];
        this.docTypeObject['selected_subject_area'] = resp.data['subject_area_list'];
      }
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        'action': 'save',
        'paper_type': this.selected_paper_type,
        'subject': this.subjectType,
        'event_name': 'document_settings_type',
        'event': 'document_settings'
         });

      var paper_type = ["original","case","review","communication"];
      if(this.selected_paper_type <= 4)
      {
        this.amplitudeService.logAmplitude('paper_type_set',{
          'fileID': this.sharedService.currentFileId,
          'type': paper_type[this.selected_paper_type -1]
        });
      }
      else
      {
        this.amplitudeService.logAmplitude('paper_type_set',{
          'fileID': this.sharedService.currentFileId,
          'type': 'other'
        });
      }

      this.amplitudeService.logAmplitude('subject_area_set',{
        'fileID': this.sharedService.currentFileId,
        'subject':this.selected_subject_area
      });
    });

    // if (documentForm.subjectArea || this.isSubjectAreaIsSelected()) {
    // if(!documentForm.subjectArea){
    //   this.docTypeObject['user_added_subject_area'] = this.docTypeObject["selected_subject_area"]
    // }
    /**setTimeout(() => {
      this.toastr.success("Your selection has been saved.");
      this.showSpinner = false;
    }, 2000);*/
    this.subjectiveBehaviorSharedService.setDocumentTypeData(this.docTypeObject, false);
    if(this.docTypeObject['selected_document_type'] != 1)
    {
      this.amplitudeService.logAmplitude('writing_type_set',{
        'fileID': this.sharedService.currentFileId,
        'type': this.docTypeObject['selected_document_type'] == 3 ? 'academic' : 'general',
        'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
      });
    }
    this.documentForm.clearValidators();
    // this.dialogRef.close('success');
    // } else {
    //   alert("Please select or add subject area");
    // }

  }

  isSubjectAreaIsSelected() {
    var temp = this.subjectType.filter(item => {
      return item.isSelected == true;
    });
    return temp.length > 0;
  }

  filterSubjectArea(SAlist) {
    var temp = [];
    if (SAlist) {
      return SAlist.filter(sa => {
        if (sa.levelNo == 3) {
          if (!temp.includes(sa.text)) {
            temp.push(sa.text);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      })
    } else return false;
  }
  suggestedSAlist = false;
  subjectAreaListArray = [];
  getSubjectAreas() {
    this.suggestedSAlist = true;
    this.subjectiveBehaviorSharedService.getSubjectAreaList.subscribe(resp => {
      this.suggestedSAlist = false;
      this.subjectAreaListArray = this.filterSubjectArea(resp);
    }, error => {
      this.suggestedSAlist = false;
      this.sharedService.errorHandller(error);
    });
  }

  submitedSubjectArea(documentForm) {
    var tempList = this.subjectType.filter(item => {
      return item.user_added_flag;
    });
    if (documentForm['subjectArea'].trim() != "") {
      if (tempList.length > 0) {
        this.subjectType.forEach(c => {
          c.isSelected = false;
        });
        tempList[0]["label"] = documentForm['subjectArea'].trim();
        tempList[0]["isSelected"] = true;
      } else {
        this.subjectType.forEach(c => {
          c.isSelected = false;
        });
        this.subjectType.unshift({ label: documentForm['subjectArea'].trim(), isSelected: true, user_added_flag: true })
      }
      this.documentForm.controls['subjectArea'].setValue("");
    }
  }

}
