import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-start-trinka-labs-modal',
  templateUrl: './start-trinka-labs-modal.component.html',
  styleUrls: ['./start-trinka-labs-modal.component.scss']
})
export class StartTrinkaLabsModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<StartTrinkaLabsModalComponent>) { }

  ngOnInit() {
  }
  closeModal(param){
    this.dialogRef.close(param)
  }

}
