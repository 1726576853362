const noiselocation = 4;
const noiselength = 10;


export class EncryptionDecryption{
    constructor() { }
     encryptJSON = (json) => {
        return this.encryptText(JSON.stringify(json))
    }
    
     decryptJSON = (json) => {
        return JSON.parse(this.decryptText(json))
    }
    
     encryptText = (text) => {
        const phase1 = this.simpleEncode(text)
        const rtext = this.makenoise(phase1);
        const insertPos = noiselocation
        const phase2 = phase1.slice(0, insertPos) + rtext + phase1.slice(insertPos)
        const phase3 = this.simpleEncode(phase2)
        return phase3
    }
    
     decryptText = (text) => {
        const phase1 = this.simpleDecode(text)
        const phase2 = phase1.slice(0, noiselocation) + phase1.slice(noiselocation + noiselength)
        const phase3 = this.simpleDecode(phase2)
        return phase3
    }
    
    simpleEncode(text) {
        return btoa(encodeURIComponent(text))
    }
    
    simpleDecode(text) {
        return decodeURIComponent(atob(text))
    }
    
    makenoise(text) {
        let tempText;
        if (text.length >= 4) {
            tempText = btoa(text)
        } else {
            tempText = btoa("bVYyYVdGMGFXOXVjeVV6UVNVeU1FRkdKVEpESlRJd1lXNXVkV3gxY3lVeU1HWnBZbkp2YzNWekpUTkNKVEl3UVU1UFZrRWxNa01sTWpCdmJtVXRkMkY1SlRJd1lXNWhiSGx6YVhNbE1qQnZaaVV5TUhaaGNtbGhibU5sSlROQ0pUSXdRazBsTWtNbE1qQmliMjVsSlRJd2JXRnljbTkzSlROQ0pUSXdRMWhEVWkwMEpUSkRKVEl3UXkxWUxVTWxNakJqYUdWdGIydHBibVVsTWpCeVpXTmxjSFJ2Y2kwMEpUTkNKVEl3UTFoRFVpMDNKVEpESlRJd1F5MVlMVU1sTWpCamFHVnRiMnRwYm1VbE1qQnlaV05sY0hSdmNpMDNKVE5DSlRJd1JFaEpKVEpESlR")
        }
    
        return tempText.slice(0, noiselength)
    }
    
    makerandomtext(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return String(result);
    }

}
