import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signupaccess',
  templateUrl: './signupaccess.component.html',
  styleUrls: ['./signupaccess.component.scss']
})
export class SignupaccessComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SignupaccessComponent>,
    private router: Router
  ) { }

  ngOnInit() {
  }


  signUp(){
    this.closePopup();


  }

  closePopup(){
    this.dialogRef.close();
    this.router.navigate(['/signup']);
  }

}
