<div class="feature_outer_section">
    <div class="feature_cards_heading">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2>Advanced writing needs advanced checks. Meet Trinka.</h2>
                    <p>Academic writing needs to be clear, concise, formal, objective, and readable.</p>
                    <p>Trinka helps you improve your writing on all these fronts so your writing can be the best version
                        of itself.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="feature_cards">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="inner_card_contents">
                        <h3>Advanced grammar
                        </h3>
                        <p>Trinka corrects 3000+ complex grammar errors that other tools don’t. With Trinka, make your
                            writing clean and clear.</p>
                        <a data-toggle="modal" data-target="#Advanced_Grammar">See example</a>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="inner_card_contents odd_cards">
                        <h3>Sentence structure</h3>
                        <p>Trinka structures your sentences correctly considering your subject, making your writing easy
                            to understand.</p>
                        <a data-toggle="modal" data-target="#Sentence_Structure">See example</a>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="inner_card_contents">
                        <h3>Word choice</h3>
                        <p>Trinka fixes incorrect use of words that makes your writing unclear. Convey your intent
                            accurately and clearly with Trinka.</p>
                        <a data-toggle="modal" data-target="#Word_Choice">See example</a>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="inner_card_contents odd_cards">
                        <h3>Usage and style</h3>
                        <p>Trinka corrects phrasing and gives your writing a formal tone. Impress your readers with
                            bold, precise language.</p>
                        <a data-toggle="modal" data-target="#Usage_Style">See example</a>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="inner_card_contents">
                        <h3>US/UK style</h3>
                        <p>Choose between US or UK English and let Trinka help you make your writing fit for your
                            audience and journal style.</p>
                        <a data-toggle="modal" data-target="#US_UK_Style">See example</a>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="inner_card_contents odd_cards">
                        <h3>Advanced spelling</h3>
                        <p>Trinka’s contextual spelling check helps you pick the right word for your subject. No more
                            awkward spelling errors.</p>
                        <a data-toggle="modal" data-target="#Advanced_Spelling">See example</a>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="feature_cards_heading Enhancements_text_contents">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2>Enhancements that get your writing ready to publish</h2>
                    <p>Trinka enhances the tone, delivery, and phrasing of your writing according to academic
                        publication standards.
                        With Trinka, academic writing is now stress-free.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="feature_cards">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="inner_card_contents">
                        <h3>Style guide preferences <span>Only on Trinka</span> </h3>
                        <p>Trinka suggests phrasing preferred by style guides such as AMA and APA. Get your writing
                            ready for publication with Trinka.</p>
                        <a data-toggle="modal" data-target="#Style_guide_preferences">See example</a>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="inner_card_contents odd_cards">
                        <h3>Word count reduction <span>Only on Trinka</span></h3>
                        <p>Trinka makes redundant and wordy phrases concise for you. Now meet journal guidelines with
                            ease!</p>
                        <a data-toggle="modal" data-target="#Word_count_reduction">See example</a>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="inner_card_contents">
                        <h3>Unbiased language <span>Only on Trinka</span> </h3>
                        <p>Trinka corrects biased and insensitive language. Avoid criticism and make your point
                            effectively, with Trinka.</p>
                        <a data-toggle="modal" data-target="#Unbiased_language">See example</a>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="inner_card_contents odd_cards">
                        <h3>Vague language <span>Only on Trinka</span> </h3>
                        <p>Trinka helps you make your writing specific and direct. Effective communication is easy with
                            Trinka.</p>
                        <a data-toggle="modal" data-target="#Vague_language">See example</a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="inner_card_contents">
                        <h3>Technical phrasing</h3>
                        <p>Trinka suggests the right words to use for your subject area. Make your content relatable to
                            your readers with Trinka.</p>
                        <a data-toggle="modal" data-target="#Technical_phrasing">See example</a>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="inner_card_contents odd_cards">
                        <h3>Academic tone</h3>
                        <p>Trinka gives your writing a formal tone so you can impress your readers. Professional writing
                            is now easy!</p>
                        <a data-toggle="modal" data-target="#Academic_tone">See example</a>
                    </div>
                </div>
            </div>

        </div>
    </div>


    <div class="feature_cards_heading understand_results">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2>Easy to understand results that also help you learn</h2>
                    <p>Trinka is designed to make your academic writing experience smooth and easy. Trinka works with
                        you as you write to make sure you get help whenever you need.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="feature_cards">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="inner_card_contents">
                        <h3>Detailed explanations </h3>
                        <p>Trinka offers detailed explanations, suggestions, and writing tips. Every time you use
                            Trinka, your writing skills get better.</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="inner_card_contents odd_cards">
                        <h3>Accurate suggestions</h3>
                        <p>Trinka is made for formal writing. It improves your content while you learn academic writing
                            nuances too.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<div class="modal features_modals" id="Advanced_Grammar">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>Neither the healthy individuals nor the patient <span class="wrong_text">recognize</span> <span
                        class="right_text">recognizes</span> the identify the visual discrepancies.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal features_modals" id="Sentence_Structure">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>The students don't enjoy <span class="wrong_text">to go</span> <span class="right_text">going</span>
                    over the same rules again and again.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal features_modals" id="Word_Choice">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>One of the reasons is <span class="wrong_text">because</span> <span class="right_text">that</span>
                    the fabrication cost <span class="wrong_text">for</span> <span class="right_text">of</span> steel
                    members depends upon the material.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="modal features_modals" id="Usage_Style">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>However, his method is novel and is <span class="wrong_text">very much</span> <span
                        class="right_text">considerably</span> different from the classical method.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal features_modals" id="US_UK_Style">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>The upper limbs of the patient were <span class="wrong_text">paralysed</span> <span
                        class="right_text">paralyzed.</span></p>
            </div>
        </div>
    </div>
</div>


<div class="modal features_modals" id="Advanced_Spelling">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>The <span class="wrong_text">Bonferoni</span> <span class="right_text">Bonferroni</span> test was
                    used to check for statistical significance.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal features_modals" id="Style_guide_preferences">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>The man <span class="wrong_text">suffered from</span> <span class="right_text">had</span> chronic
                    kidney disease.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal features_modals" id="Word_count_reduction">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>Bcl6 deficiency in T cells resulted in <span class="wrong_text">the reduction of</span> <span
                        class="right_text">reduced</span> mRNA expression.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal features_modals" id="Unbiased_language">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>The <span class="wrong_text">retarded children</span> <span class="right_text">children with mental
                        retardation</span> were taken to a different facility.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal features_modals" id="Vague_language">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p><span class="wrong_text">Some studies</span> <span class="right_text">Studies</span> have shown that
                    children not participating in co-curricular activities may do not develop the necessary social
                    skills in adulthood.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal features_modals" id="Technical_phrasing">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>Anti-aquaporin 4 antibody testing may be useful in cases of pediatric <span class="wrong_text">transverse myelitis with long
                    spinal cord lesions</span> <span class="right_text">longitudinally extensive transverse myelitis</span>.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="modal features_modals" id="Academic_tone">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>Children actively engage in activities <span class="wrong_text">like</span> <span
                        class="right_text">such as</span> outdoor games, skits, and other team activities.</p>
            </div>
        </div>
    </div>
</div>