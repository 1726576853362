import { HttpRequest, HttpHandler, HttpInterceptor, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injector } from "@angular/core";
import { SharedService } from '../shared.service';
import { Injectable, Inject } from '@angular/core';
import { Observable, throwError, BehaviorSubject, Subject } from "rxjs";
import { JwtHelperService } from '@auth0/angular-jwt';
import { switchMap } from 'rxjs/operators';
import { SignInSignOutService } from '../networkcalls/sign-in-sign-out.service';
import { catchError, tap } from 'rxjs/operators';
import { LoginOutput } from 'src/app/_enums/login-output.enum';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from "src/environments/environment";
import { GetMetaService } from "../get-meta.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  IGNORE_URLS = ['/trinka/api/v1/auth', '/trinka/api/v1/register', '/api/v1/user/password/forgot', '/api/v1/token/refresh', '/api/v1/ext_chrome/', '/api/v1/ext_firefox/','/api/v1/user/verifylink/verify', 'trinka/api/v1/social/auth', '/trinka/api/v1/registration/update', '/api/v1/user/domain/premium']

  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private injector: Injector,
    public jwtHelper: JwtHelperService,
    public loginservice: SignInSignOutService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public sharedService: SharedService,
    public metaString : GetMetaService
  ) { }

  refreshToken(): Observable<LoginOutput> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      return this.sharedService.refreshToken().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError((error) => {
          this.refreshTokenInProgress = false;
          this.logout();
          return Observable.throw(error.statusText);
        }));
    }
  }

  logout() {
    this.sharedService.SignOut();
  }

  handleResponseError(error, request?, next?) {
    if (error.status === 401 || error.status === 403) {
      request = request.clone({
        withCredentials: true
      });
      return this.refreshToken().pipe(
        switchMap(() => {
          let token = this.storage.get(this.metaString.getMeta())['token'];
          request = this.addAuthorizationToken(request, token);
          return next.handle(request);
        }),
        catchError(e => {
          if (e.status !== 401 || e.status !== 403) {
            return this.handleResponseError(e);
          } else {
            this.logout();
          }
        }));
    }

    return throwError(error);
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let handleWithoutToken = false;
    request = request.clone({
      withCredentials: true
    });
    for (let i = 0; i < this.IGNORE_URLS.length; i++) {
      if (request.url.indexOf(this.IGNORE_URLS[i]) !== -1) {
        handleWithoutToken = true;
        break;
      }
    }

    if (!handleWithoutToken && request.url.indexOf('/api/v1/user/password/reset') !== -1) {
      if (!this.storage.get(this.metaString.getMeta()) || !this.storage.get(this.metaString.getMeta())['token']) {
        handleWithoutToken = true
      }
    }

    if (handleWithoutToken) {
      return next.handle(request);
    }

    let token = this.storage.get(this.metaString.getMeta())['token'];
    request = this.addAuthorizationToken(request, token);

    return next.handle(request).pipe(catchError(error => {
      return this.handleResponseError(error, request, next);
    }));

  }


  private addAuthorizationToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}

