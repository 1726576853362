<mat-list class="row headerBoxShadow ManageProfilemobileHeader">
  <div class="col-md-12 top_new_header">
    <div class="page_top_header">
      <h1 class="mat-display-1 mb-0">Account Settings</h1>
    </div>
  </div>
</mat-list>

<div class="manage-profile manageAccountBody">
  <div class="manage_profile_body" style="margin-top: 29px;">
    <div class="">
      <div class="row">
        <div class="col-xl-12">
          <div class="user_info_section">
            <div class="title_heading">
              <h2 class="profile-title">Profile</h2>
            </div>
            <div class="form-input" style="margin-bottom: 15px;">
              <mat-card-title class="user_text_info" style="display: block;">Name</mat-card-title>
              <mat-card-subtitle>
                <mat-label class="w-100">
                  <span class="manageProfileFirstname"
                    title="{{selectedProfile?.firstname}}">{{selectedProfile?.firstname}}</span>
                  <span class="manageProfileLastname"
                    title="{{selectedProfile?.lastname}}">{{selectedProfile?.lastname}}</span>
                </mat-label>
              </mat-card-subtitle>
            </div>
            <div class="form-input" style="margin-bottom: 15px;">
              <mat-card-content class="">
                <mat-label class="user_text_info">Email</mat-label><br>
                <span class="email">{{selectedProfile?.email_id}}</span>&nbsp;
                <!-- <span class="emailVerifiedSection" *ngIf="selectedProfile?.is_verified"> 
                  <img src="../../../../assets/images/verified_email.svg" alt=""> Verified
                </span>
                <span class="emailVerificationPendding" *ngIf="!selectedProfile?.is_verified"> <img
                    src="../../../../assets/images/verification_alert_icon.svg" alt=""> Verification pending</span> -->
              </mat-card-content>
            </div>

            <div class="form-input" style="margin-bottom: 15px;">
              <p *ngIf="selectedProfile?.is_social_login && selectedProfile?.provider =='Google_Trinka'"
                class="signInInform"><img src="../../../../assets/images/Icon_Info.svg" alt=""> You had signed up using
                your Google account. You may set a password below to sign in with your email without using Google.</p>
              <form [formGroup]="updatepassword" (ngSubmit)="changePassword()">
                <mat-card-content class="footer-part mb-4">
                  <mat-card-title class="user_text_info">Password</mat-card-title>
                  <mat-card-subtitle class="manage_profile_password">
                    <div class="manage_profile_textboxs">
                      <mat-label class="edit-pwd" *ngIf="!passUpdateMode">*********</mat-label>

                      <label for="passwordTextbox" class="passwordTextbox"
                        [ngClass]="!updatepassword.valid && updatepassword.dirty ? 'wrong_password' : '' ">
                        <input type="text" formControlName="password" type="password" *ngIf="passUpdateMode"
                          id="passwordTextbox" placeholder="&nbsp;" [type]="showPassword ? 'password' : 'text'">
                        <div class="passwordContainer manageAccountPassword">

                          <div class="passwordTextSection">
                            <div  style="color:red;font-size: 12px;"
                              *ngIf="!updatepassword.valid && updatepassword.dirty && !updatepassword.controls.password.errors?.cannotContainSpace">
                              <svg  fill="none" height="14" viewBox="0 0 14 14" width="14"
                                xmlns="http://www.w3.org/2000/svg">
                                <path 
                                  d="M7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0ZM7 3C6.87304 2.99989 6.74745 3.02626 6.63126 3.07743C6.51507 3.1286 6.41082 3.20344 6.32519 3.29717C6.23955 3.3909 6.1744 3.50146 6.13391 3.62179C6.09341 3.74211 6.07846 3.86957 6.09 3.996L6.455 8.002C6.46915 8.13668 6.53267 8.26136 6.63331 8.35197C6.73395 8.44259 6.86458 8.49274 7 8.49274C7.13542 8.49274 7.26605 8.44259 7.36669 8.35197C7.46733 8.26136 7.53085 8.13668 7.545 8.002L7.909 3.996C7.92053 3.86965 7.90561 3.74228 7.86517 3.62202C7.82474 3.50176 7.75968 3.39125 7.67415 3.29754C7.58862 3.20382 7.4845 3.12896 7.36843 3.07773C7.25235 3.0265 7.12688 3.00003 7 3ZM7 11C7.21217 11 7.41566 10.9157 7.56569 10.7657C7.71571 10.6157 7.8 10.4122 7.8 10.2C7.8 9.98783 7.71571 9.78434 7.56569 9.63431C7.41566 9.48429 7.21217 9.4 7 9.4C6.78783 9.4 6.58434 9.48429 6.43431 9.63431C6.28429 9.78434 6.2 9.98783 6.2 10.2C6.2 10.4122 6.28429 10.6157 6.43431 10.7657C6.58434 10.9157 6.78783 11 7 11Z"
                                  fill="#FF5C5C"></path>
                              </svg> Minimum 8 character required
                            </div>
                            <div style="color:red;font-size: 12px;"
                            *ngIf="updatepassword.controls.password.errors?.cannotContainSpace && updatepassword.dirty">
                            <svg _ngcontent-gva-c7="" fill="none" height="14" viewBox="0 0 14 14" width="14"
                              xmlns="http://www.w3.org/2000/svg">
                              <path _ngcontent-gva-c7=""
                                d="M7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0ZM7 3C6.87304 2.99989 6.74745 3.02626 6.63126 3.07743C6.51507 3.1286 6.41082 3.20344 6.32519 3.29717C6.23955 3.3909 6.1744 3.50146 6.13391 3.62179C6.09341 3.74211 6.07846 3.86957 6.09 3.996L6.455 8.002C6.46915 8.13668 6.53267 8.26136 6.63331 8.35197C6.73395 8.44259 6.86458 8.49274 7 8.49274C7.13542 8.49274 7.26605 8.44259 7.36669 8.35197C7.46733 8.26136 7.53085 8.13668 7.545 8.002L7.909 3.996C7.92053 3.86965 7.90561 3.74228 7.86517 3.62202C7.82474 3.50176 7.75968 3.39125 7.67415 3.29754C7.58862 3.20382 7.4845 3.12896 7.36843 3.07773C7.25235 3.0265 7.12688 3.00003 7 3ZM7 11C7.21217 11 7.41566 10.9157 7.56569 10.7657C7.71571 10.6157 7.8 10.4122 7.8 10.2C7.8 9.98783 7.71571 9.78434 7.56569 9.63431C7.41566 9.48429 7.21217 9.4 7 9.4C6.78783 9.4 6.58434 9.48429 6.43431 9.63431C6.28429 9.78434 6.2 9.98783 6.2 10.2C6.2 10.4122 6.28429 10.6157 6.43431 10.7657C6.58434 10.9157 6.78783 11 7 11Z"
                                fill="#FF5C5C"></path>
                            </svg>Password can not contain space
                          </div>
                          </div>
 
                        </div>
                        <mat-icon class="manageProfilePasswodhide" matSuffix (click)="showPassword = !showPassword">
                          <img class="showPasswordIcons" *ngIf="showPassword && passUpdateMode" title="Show password"
                            src="../../../../assets/images/PasswordHide_icon.svg" alt="Show password">
                          <img class="showPasswordIcons" *ngIf="!showPassword && passUpdateMode" title="Hide password"
                            src="../../../../assets/images/PasswordShow_icon.svg" alt="Hide password">
                        </mat-icon>
                        <span class="focus-bg"></span>
                      </label>


                      <span class="edit_update_password">
                        <span class="text-link pl-2 align-top" *ngIf="!passUpdateMode" class="edit_button"
                          title="Change Password" (click)="togglePasswordEditMode()">
                          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 348.882 348.882"
                            style="enable-background:new 0 0 348.882 348.882;" xml:space="preserve">
                            <g>
                              <path style="fill: #7a28a0"
                                d="M333.988,11.758l-0.42-0.383C325.538,4.04,315.129,0,304.258,0c-12.187,0-23.888,5.159-32.104,14.153L116.803,184.231
                            c-1.416,1.55-2.49,3.379-3.154,5.37l-18.267,54.762c-2.112,6.331-1.052,13.333,2.835,18.729c3.918,5.438,10.23,8.685,16.886,8.685
                            c0,0,0.001,0,0.001,0c2.879,0,5.693-0.592,8.362-1.76l52.89-23.138c1.923-0.841,3.648-2.076,5.063-3.626L336.771,73.176
                            C352.937,55.479,351.69,27.929,333.988,11.758z M130.381,234.247l10.719-32.134l0.904-0.99l20.316,18.556l-0.904,0.99
                            L130.381,234.247z M314.621,52.943L182.553,197.53l-20.316-18.556L294.305,34.386c2.583-2.828,6.118-4.386,9.954-4.386
                            c3.365,0,6.588,1.252,9.082,3.53l0.419,0.383C319.244,38.922,319.63,47.459,314.621,52.943z" />
                              <path style="fill: #4d4d4d"
                                d="M303.85,138.388c-8.284,0-15,6.716-15,15v127.347c0,21.034-17.113,38.147-38.147,38.147H68.904
                          c-21.035,0-38.147-17.113-38.147-38.147V100.413c0-21.034,17.113-38.147,38.147-38.147h131.587c8.284,0,15-6.716,15-15
                          s-6.716-15-15-15H68.904c-37.577,0-68.147,30.571-68.147,68.147v180.321c0,37.576,30.571,68.147,68.147,68.147h181.798
                          c37.576,0,68.147-30.571,68.147-68.147V153.388C318.85,145.104,312.134,138.388,303.85,138.388z" />
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>

                    <div class="update_password_buttons">
                      <button type="submit" class="text-link pl-2 align-middle update_password" *ngIf="passUpdateMode"
                        [disabled]="!updatepassword.valid">Update</button>
                      <span class="text-link align-middle cancel_password" *ngIf="passUpdateMode"
                        (click)="togglePasswordEditMode()">Cancel</span>
                    </div>
                  </mat-card-subtitle>
                </mat-card-content>
              </form>
              <button mat-button class="lang-preference switchAccount_button" (click)="openChangeProfile()"
                *ngIf="sharedService.featurePermission.data.enago_proofreading && profileList.length>1">
                Switch between your Enago accounts</button>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="userWithCardInfo">
            <div class="paymentLoaderSection" *ngIf="(!userSubscriptionDetails && !isUserSubscriptionDetailsLoaded)
              || (userSubscriptionDetails && !isUserSubscriptionDetailsLoaded)">
              <div class="shimmerBG title-line"></div>
              <div class="shimmerBG content-line"></div>
              <div class="shimmerBG content-line"></div>
            </div>
            <div *ngIf="userSubscriptionDetails && isUserSubscriptionDetailsLoaded" class="paymentSubscrptionSection">
              <div class="subscriptionHeader">
                <h2>Subscription Details</h2>
                <button [ngClass]="paymentDetails?.user_type&&paymentDetails?.user_type === 'PREMIUM_TRIAL' || paymentDetails?.user_type&&paymentDetails?.user_type === 'INSTITUTIONAL_ACCESS' ? '' : 'UpgradePremiumButton'" (click)="upgradeToPremium()" *ngIf="paymentDetails?.user_type !== 'PREMIUM_PLUS' && paymentDetails?.user_type !== 'PREMIUM_TRIAL' && paymentDetails?.user_type !== 'INSTITUTIONAL_ACCESS'">
                  <svg *ngIf="paymentDetails?.user_type == 'BASIC'" width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.187 4.35161L9.41105 1.67033C9.34045 1.56408 9.22115 1.5 9.09353 1.5H2.90684C2.77913 1.5 2.65992 1.56409 2.58931 1.67033L0.813334 4.35161C0.718569 4.49408 0.731218 4.68211 0.844103 4.81027L5.71367 10.3701C5.78559 10.4528 5.88998 10.5 5.99995 10.5C6.10992 10.5 6.2143 10.4528 6.2866 10.3698L11.1562 4.8099C11.2687 4.6821 11.2813 4.49408 11.187 4.35161ZM3.84922 4.9405L5.02944 8.43581L1.96799 4.9405H3.84922ZM6.87805 2.25922L7.38839 4.18168H4.61062L5.12097 2.25922H6.87805ZM7.34695 4.9405L5.99977 8.93135L4.65259 4.9405H7.34695ZM8.15037 4.9405H10.0317L6.97023 8.43581L8.15037 4.9405ZM10.1619 4.18127H8.17623L7.66588 2.25881H8.88875L10.1619 4.18127ZM3.11118 2.2592H4.33405L3.8237 4.18165H1.83753L3.11118 2.2592Z" fill="#92400E"/>
                  </svg>
                  <svg *ngIf="paymentDetails?.user_type == 'PREMIUM'" width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.935 6.0381L10.0987 3.43811C10.0257 3.33509 9.90237 3.27295 9.77042 3.27295H3.37364C3.24159 3.27295 3.11833 3.33509 3.04533 3.43811L1.20904 6.0381C1.11106 6.17625 1.12414 6.35857 1.24085 6.48285L6.27578 11.8741C6.35015 11.9543 6.45808 12.0001 6.57179 12.0001C6.68549 12.0001 6.79342 11.9543 6.86817 11.8738L11.9031 6.48249C12.0194 6.35857 12.0325 6.17625 11.935 6.0381ZM4.34802 6.60914L5.56831 9.99846L2.4029 6.60914H4.34802ZM7.4797 4.00915L8.00738 5.87331H5.13527L5.66295 4.00915H7.4797ZM7.96453 6.60914L6.5716 10.479L5.17867 6.60914H7.96453ZM8.79523 6.60914H10.7404L7.57501 9.99846L8.79523 6.60914ZM10.8751 5.87292H8.82197L8.29429 4.00875H9.55868L10.8751 5.87292ZM3.58492 4.00913H4.84931L4.32164 5.87329H2.26802L3.58492 4.00913Z" fill="#92400E"/>
                    <path d="M2.85775 0L3.32074 1.19438L4.57194 1.63633L3.32074 2.07829L2.85775 3.27267L2.39476 2.07829L1.14355 1.63633L2.39476 1.19438L2.85775 0Z" fill="#92400E"/>
                    <path d="M1.1428 2.72705L1.45146 3.5233L2.28559 3.81794L1.45146 4.11258L1.1428 4.90883L0.834138 4.11258L0 3.81794L0.834138 3.5233L1.1428 2.72705Z" fill="#92400E"/>
                  </svg>
                    Upgrade{{paymentDetails?.user_type == 'BASIC'?'':' to Premium Plus'}}
                </button>
              </div>
              <div class="paymentSubscrption_card">
                <div class="paymentDetails">
                  <div class="paymentContent subscriptionName" *ngIf="!userSubscriptionDetails.is_expired">
                    <p>{{userSubscriptionDetails?.plan_name}}</p>
                    <span>{{userSubscriptionDetails.interval}} subscription</span>
                  </div>
  
                  <div class="paymentContent" *ngIf="userSubscriptionDetails.is_expired">
                    <p>Trinka Basic</p>
                  </div>
                  <div class="paymentAccount" *ngIf="!userSubscriptionDetails.is_expired">
                    <p class="text-right">{{userSubscriptionDetails.currency_symbol}}{{userSubscriptionDetails?.amount|
                      number:'1.2-2'}}</p>
                    <span class="text-right" *ngIf="userSubscriptionDetails.interval== 'Annual'">per year</span>
                    <span class="text-right" *ngIf="userSubscriptionDetails.interval== 'Monthly'">per month</span>
                    <span class="text-right" *ngIf="userSubscriptionDetails.interval== 'Quarterly'">per quarter</span>
                  </div>
                  <div class="paymentAccount" *ngIf="userSubscriptionDetails.is_expired">
                    <p class="text-right">Free</p>
                    <span class="text-right">for lifetime</span>
                  </div>
                </div>
  
                <div class="nextPaymentDetails"
                  *ngIf="userSubscriptionDetails.auto_renew && !userSubscriptionDetails.is_expired">
                  <div class="SectionPaymentDue">
                    <p>Next payment on</p>
                    <span>{{userSubscriptionDetails?.next_renewal_date | date: 'MMM dd, yyyy'}}</span>
                  </div>
                  <div class="SectionPaymentDue" style="margin-bottom: 0;">
                    <p>Payment method </p>
                    <!-- <span *ngIf="userSubscriptionDetails.payment_mode == 'CARD'">Credit Card **** ****
                      {{userSubscriptionDetails.card_number}}</span> -->
                    <span *ngIf="userSubscriptionDetails.payment_mode == 'CARD' && userSubscriptionDetails.currency !== 'INR'">STRIPE</span>
                    <span *ngIf="userSubscriptionDetails.payment_mode == 'PAYPAL'">PayPal</span>
                    <span *ngIf="(userSubscriptionDetails.payment_mode == 'UPI' || userSubscriptionDetails.currency === 'INR')">RAZORPAY</span>
  
                  </div>
                </div>
  
  
                <div class="nextPaymentDetails"
                  *ngIf="!userSubscriptionDetails.auto_renew && !userSubscriptionDetails.is_expired">
                  <div class="SectionPaymentDue" style="margin-bottom: 0;">
                    <p
                      *ngIf="!userSubscriptionDetails.is_manual_payment && userSubscriptionDetails.payment_mode != 'WECHAT_PAY'">
                      Subscription auto-renewal canceled.</p>
                    <p *ngIf="userSubscriptionDetails.is_manual_payment"> </p>
                    <span></span>
                    <span>Valid till {{userSubscriptionDetails?.next_renewal_date | date: 'MMM dd, yyyy'}}</span>
                  </div>
                </div>
  
                <div class="nextPaymentDetails"
                  [ngClass]="(!userSubscriptionDetails.auto_renew && userSubscriptionDetails.is_expired)? 'premiumPlanExpired' : '' "
                  *ngIf="!userSubscriptionDetails.auto_renew && userSubscriptionDetails.is_expired && userSubscriptionDetails.plan_name !== 'Trinka Premium Trial'">
                  <div class="SectionPaymentDue" style="margin-bottom: 0;">
                    <p>Your premium plan expired on <span>{{userSubscriptionDetails?.next_renewal_date | date: 'MMM dd,yyyy'}}</span></p>
                  </div>
                </div>
                <div class="nextPaymentDetails"
                  [ngClass]="(!userSubscriptionDetails.auto_renew && userSubscriptionDetails.is_expired)? 'premiumPlanExpired' : '' "
                  *ngIf="!userSubscriptionDetails.auto_renew && userSubscriptionDetails.is_expired && userSubscriptionDetails.plan_name === 'Trinka Premium Trial'">
                  <div class="SectionPaymentDue" style="margin-bottom: 0;">
                    <p>Your premium trial plan expired on <span>{{userSubscriptionDetails?.next_renewal_date | date: 'MMM dd,yyyy'}}</span></p>
                  </div>
                </div>
              </div>
              <div class="paymentFooterSection">
                <div>
                  <span (click)="getTransactionHistory()">Transaction history</span>
                  <a class="savedCardButton" *ngIf="stripePortalLink" (click)="redirectToSubscriptionPage()">Manage Subscription and Invoices</a>
                  <!-- <a class="savedCardButton" (click)="showSavedCards()">My Saved Cards</a> -->
                </div>
                <span (click)="confirmCancel()" *ngIf="userSubscriptionDetails.auto_renew && userSubscriptionDetails.payment_mode != 'CARD'">Cancel subscription
                  renewal</span>
              </div>
            </div>
  
            <div *ngIf="!userSubscriptionDetails && isUserSubscriptionDetailsLoaded" class="paymentSubscrptionSection">
              <div class="subscriptionHeader">
                <h2>Subscription Details</h2>
                <button [ngClass]="paymentDetails?.user_type&&paymentDetails?.user_type === 'PREMIUM_TRIAL' || paymentDetails?.user_type&&paymentDetails?.user_type === 'INSTITUTIONAL_ACCESS' ? '' : 'UpgradePremiumButton'" (click)="upgradeToPremium()" *ngIf="paymentDetails?.user_type !== 'PREMIUM_TRIAL' && paymentDetails?.user_type !== 'INSTITUTIONAL_ACCESS'">
                  <svg *ngIf="paymentDetails?.user_type == 'BASIC'" width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.187 4.35161L9.41105 1.67033C9.34045 1.56408 9.22115 1.5 9.09353 1.5H2.90684C2.77913 1.5 2.65992 1.56409 2.58931 1.67033L0.813334 4.35161C0.718569 4.49408 0.731218 4.68211 0.844103 4.81027L5.71367 10.3701C5.78559 10.4528 5.88998 10.5 5.99995 10.5C6.10992 10.5 6.2143 10.4528 6.2866 10.3698L11.1562 4.8099C11.2687 4.6821 11.2813 4.49408 11.187 4.35161ZM3.84922 4.9405L5.02944 8.43581L1.96799 4.9405H3.84922ZM6.87805 2.25922L7.38839 4.18168H4.61062L5.12097 2.25922H6.87805ZM7.34695 4.9405L5.99977 8.93135L4.65259 4.9405H7.34695ZM8.15037 4.9405H10.0317L6.97023 8.43581L8.15037 4.9405ZM10.1619 4.18127H8.17623L7.66588 2.25881H8.88875L10.1619 4.18127ZM3.11118 2.2592H4.33405L3.8237 4.18165H1.83753L3.11118 2.2592Z" fill="#92400E"/>
                  </svg>
                  <svg *ngIf="paymentDetails?.user_type == 'PREMIUM'" width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.935 6.0381L10.0987 3.43811C10.0257 3.33509 9.90237 3.27295 9.77042 3.27295H3.37364C3.24159 3.27295 3.11833 3.33509 3.04533 3.43811L1.20904 6.0381C1.11106 6.17625 1.12414 6.35857 1.24085 6.48285L6.27578 11.8741C6.35015 11.9543 6.45808 12.0001 6.57179 12.0001C6.68549 12.0001 6.79342 11.9543 6.86817 11.8738L11.9031 6.48249C12.0194 6.35857 12.0325 6.17625 11.935 6.0381ZM4.34802 6.60914L5.56831 9.99846L2.4029 6.60914H4.34802ZM7.4797 4.00915L8.00738 5.87331H5.13527L5.66295 4.00915H7.4797ZM7.96453 6.60914L6.5716 10.479L5.17867 6.60914H7.96453ZM8.79523 6.60914H10.7404L7.57501 9.99846L8.79523 6.60914ZM10.8751 5.87292H8.82197L8.29429 4.00875H9.55868L10.8751 5.87292ZM3.58492 4.00913H4.84931L4.32164 5.87329H2.26802L3.58492 4.00913Z" fill="#92400E"/>
                    <path d="M2.85775 0L3.32074 1.19438L4.57194 1.63633L3.32074 2.07829L2.85775 3.27267L2.39476 2.07829L1.14355 1.63633L2.39476 1.19438L2.85775 0Z" fill="#92400E"/>
                    <path d="M1.1428 2.72705L1.45146 3.5233L2.28559 3.81794L1.45146 4.11258L1.1428 4.90883L0.834138 4.11258L0 3.81794L0.834138 3.5233L1.1428 2.72705Z" fill="#92400E"/>
                  </svg>
                  Upgrade{{paymentDetails?.user_type == 'BASIC'?'':' to Premium Plus'}}</button>
              </div>
              
              <div class="paymentSubscrption_card TrinkaBasicUser">
                <div class="paymentDetails">
                  <div class="paymentContent">
                    <p>Trinka Basic</p>
                  </div>
                  <div class="paymentAccount">
                    <p class="text-right">Free</p>
                    <span class="text-right">for lifetime</span>
                  </div>
                </div>
              </div>
              
  
              <div class="basicUser_TH">
                <div class="basicLeftButtons">
                  <div class="userTransactionHistory">
                    <span (click)="getTransactionHistoryBasicUser()">Transaction history</span>
                  </div>
                  <a class="savedCardButton" *ngIf="stripePortalLink" (click)="redirectToSubscriptionPage()">Manage Subscription and Invoices</a>

                  <!-- <a class="savedCardButton" (click)="showSavedCards()">My Saved Cards</a> -->
                </div>
              </div>
            </div>
  
            <!--<div class="paymentMethodDetails"
              *ngIf="paymentMethodDetails != undefined && userSubscriptionDetails != undefined && (userSubscriptionDetails.payment_mode=='CARD'||userSubscriptionDetails.payment_mode=='SAVED_CARD') &&
                           !userSubscriptionDetails.is_manual_payment && (userSubscriptionDetails.auto_renew || !userSubscriptionDetails.is_expired)">
              <h3>Payment Method</h3>
              <p>{{paymentMethodDetails.brand}} card ending with <span>{{paymentMethodDetails.last4}}</span></p>
              <p>{{paymentMethodDetails.name}}</p>
              <p>Expiry Date: <span>{{formatExpiryDate(paymentMethodDetails)}}</span></p>
              <a *ngIf="userSubscriptionDetails.auto_renew && !userSubscriptionDetails.is_expired"
                (click)="changePaymentMethod()">Change Payment Method</a>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-sm-12">
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="z-index: 999999999;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Sure to cancel your Premium Subscription ?</h5>
          <h6>You will only get 10,000 words of usage with Basic.</h6>
          <h6>Only 2 credits will be offered for Auto Edit.</h6>
          <h6>You will not have access to upcoming feature.</h6>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Yes, cancel now</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="EmailNotificationSettingsText">
        <div class="title_heading">
          <h2 class="profile-title">Email notification settings</h2>
        </div>
        <div *ngFor="let item of emailItems">
          <mat-checkbox [checked]="item.selected" [ngModel]="item.selected"
            (change)="notificationsChange(item.type,$event)">{{item.label}}</mat-checkbox><br>
        </div>
      </div>
      <!-- <mat-divider class="seprator"></mat-divider> -->
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <mat-divider class="seprator" style="margin: 20px 0 -10px 0 !important"></mat-divider>
      <div class="dashbaord_language_preference">
        <h2 class="mat-h2 pt-4 profile-title">Writing Settings</h2>
        <div class="other_options_buttons">
          <!-- <button mat-button class="lang-preference" (click)="openLanguagePrefGlobal()"><img
              src="../../../../assets/images/language-preference.png" alt="Language Preference">Language
            Preference</button> -->
          <button mat-button class="lang-preference" [routerLink]="['/dashboard/mydictionary']"
            *ngIf="sharedService.featurePermission.data.add_to_dictionary">
            <img src="../../../../assets/images/Dictionay_icon.png" alt="Language Preference">My Dictionary</button>
          <button mat-button class="lang-preference" (click)="openLanguageCodePreferrencedModal()"><img
              src="../../../../assets/images/language-preference.png" alt="Language Preference">Language
            Preference</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="dashbaord_language_preference">
        <mat-divider class="seprator" style="margin: 20px 0 40px 0 !important;"></mat-divider>
        <ul>
          <li>
            <a href="https://www.trinka.ai/privacypolicy/" target="_blank">Privacy Policy</a>
          </li>
          <li>
            <a href="https://www.trinka.ai/termsofservices/" target="_blank">Terms of Services</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="DeleteAccount">
        <mat-divider class="seprator" style="margin: 20px 0 40px 0 !important;"></mat-divider>
        <span (click)="DeleteAccountPopUp()">Delete Account</span>
      </div>
    </div>
  </div>


</div>

<div class="modal fade" id="CancelSubscription" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>