import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_guards/auth.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { GetMetaService } from 'src/app/_services/get-meta.service';

@Component({
  selector: 'app-user-verify',
  templateUrl: './user-verify.component.html',
  styleUrls: ['./user-verify.component.scss']
})
export class UserVerifyComponent implements OnInit {

  verificationKey:string
  verificationMessage:string
  userLoggedIn:boolean= false
  constructor(
    private route: ActivatedRoute,
    private router:Router,
    private networkCalls: DashboardService,
    public auth: AuthService,
    public metaString : GetMetaService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
  ) {
    if(this.auth.isAuthenticated()){
      this.userLoggedIn = true;
    }

    this.route.params.subscribe(params => {
      this.verificationKey = params['verify_key'];
      if(this.verificationKey.trim()==""){
        this.router.navigate(['/404'])
        return 
      }

      this.networkCalls.userVerifyMail(this.verificationKey).subscribe(result=>{
        if(result['status']){
          this.verificationMessage = result['message'];
        }else{
          this.router.navigate(['/404'])
        }
      },error=>{
        this.router.navigate(['/404'])
      })
    })
  }

  ngOnInit() {
    
  }


  signOut() {
    this.storage.set(this.metaString.getMeta(), {});
    this.router.navigate(['/signin'])
  }

}
