<div class="PowerModePopup">
  <button class="cancelbuttons" mat-button (click)="freeTrialClose()">
    <img src="../../../assets/images/closeIcons.png" alt="">
  </button>

  <div class="PMouterWrapSection">
    <div class="powerMode_img"  *ngIf="!exampleEnable">
      <img src="../../../../../assets/images/powerMode_icon.svg" alt="">
    </div>
    <div class="PMBodySection" *ngIf="!exampleEnable">
      <div class="PMHeadingSection">
        <h2>Power Mode <span>on Trinka Premium</span></h2>
        <span class="titleSubText">Premium includes uninterrupted access and many other benefits.</span>
      </div>
      <h5 class="usedFreeText" *ngIf="trialStatus == 'trial_end'">Your free trial is over. Upgrade to use Power Mode for all your writing</h5>
      <ul>
        <li>In-depth contextual checks and finer suggestion</li>
        <li>Up to 40% more alerts to improve your writing</li>
        <li>Uses our latest AI models to deliver our best experience</li>
      </ul>
      
    </div>

    <div class="PMBodySection" *ngIf="exampleEnable">
      <div class="PMHeaderSection">
        <button (click)="goBack()" class="GoBackButton">
          <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.25 10.2869H5.495L11.8475 2.81332C12.1445 2.46327 12.2875 2.01197 12.2448 1.55871C12.2021 1.10545 11.9774 0.687351 11.62 0.396397C11.2626 0.105443 10.8019 -0.0345367 10.3391 0.00725335C9.87639 0.0490434 9.44954 0.26918 9.1525 0.619234L0.4025 10.904C0.343631 10.9858 0.290989 11.0718 0.245 11.1611C0.245 11.2469 0.245 11.2983 0.1225 11.384C0.0431789 11.5805 0.00164696 11.7897 0 12.0011C0.00164696 12.2124 0.0431789 12.4216 0.1225 12.6182C0.1225 12.7039 0.1225 12.7553 0.245 12.841C0.290989 12.9304 0.343631 13.0163 0.4025 13.0981L9.1525 23.3829C9.31704 23.5764 9.52308 23.732 9.75598 23.8387C9.98888 23.9453 10.2429 24.0004 10.5 24C10.9089 24.0008 11.3052 23.8613 11.62 23.6057C11.7972 23.4618 11.9437 23.2851 12.051 23.0857C12.1584 22.8862 12.2246 22.668 12.2457 22.4435C12.2668 22.219 12.2425 21.9926 12.1742 21.7773C12.1059 21.562 11.9949 21.362 11.8475 21.1888L5.495 13.7152H26.25C26.7141 13.7152 27.1593 13.5346 27.4874 13.2131C27.8156 12.8917 28 12.4557 28 12.0011C28 11.5465 27.8156 11.1105 27.4874 10.789C27.1593 10.4675 26.7141 10.2869 26.25 10.2869Z" fill="#4D4D4D"/>
            </svg>
        </button>
        <span>Examples</span>
      </div>
      <div class="carousel slide" id="main-carousel" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#main-carousel" data-slide-to="0" class="active"></li>
          <li data-target="#main-carousel" data-slide-to="1"></li>
          <li data-target="#main-carousel" data-slide-to="2"></li>
          <li data-target="#main-carousel" data-slide-to="3"></li>
          <li data-target="#main-carousel" data-slide-to="4"></li>
        </ol><!-- /.carousel-indicators -->
        <div class="sliderHeading">
          <div class="row">
            <div class="col-md-5">
              <div class="sliderLeftSideHeading">
                <b>What you see now</b>
              </div>
            </div>
            <div class="col-md-7">
              <div class="sliderRightSideHeading">
                <div class="withPowerMode">
                  <span>With</span>
                  <p><img src="../../../../../assets/images/powerMode_icon.svg" alt="">Power Mode</p>
                </div>
                <div class="advancedGrammarCheckSection">
                  <img src="../../../../../assets/images/arrow.gif" alt="">
                  <p>Advanced<br />Improvements</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="sliderContent">
              <div class="row">
                <div class="col-md-5">
                  <div class="withOutCorrections">
                    <p><b>With the nature</b> <span>Given</span> that the results will be known once the tests are done, each individual that is diagnosed to be a genetic risk carrier <b>face</b> <span>faces</span> an ethical problem: They should warn their relatives about the <b>risking</b> <span>risk</span> of being the same genetic risk carrier.</p>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="withCorrectionsText">
                    <p><b>With the nature</b> <span>Given</span> that the results will be known once the tests <b>are done, each individual that is</b> <span>have been performed, the individuals diagnosed</span> <b>to be</b> <span>as</span> a genetic risk carrier face an ethical problem: They should warn their relatives about the <b>risking</b> <span>risk</span> of being the same genetic risk carrier.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="sliderContent">
              <div class="row">
                <div class="col-md-5">
                  <div class="withOutCorrections">
                    <p>Most people care about their relatives<b style="margin-left: 0;">,</b><span style="margin-left: 0;">;</span> if there is no serious consequence of telling them so, I assume most of them will tell their relatives to be careful.</p>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="withCorrectionsText">
                    <p>Most people care about their relatives , <b>if there is no serious consequence of telling them so ,and</b> <span>and</span> I assume most of them will <b>tell</b> <span>ask</span> their relatives to be careful <span>, if there is no serious consequence of telling them so.</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="sliderContent">
              <div class="row">
                <div class="col-md-5">
                  <div class="withOutCorrections">
                    <p>However, the wife <b>actually</b> had already known about the polygenetic disorder before the marriage and she did not tell her husband.</p>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="withCorrectionsText">
                    <p>However, the wife <b>actually had already known about</b> <span>had been actually aware of</span> the polygenetic disorder before the marriage<span style="margin-left: 0;">,</span> and she did not tell her husband.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <div class="sliderContent">
              <div class="row">
                <div class="col-md-5">
                  <div class="withOutCorrections">
                    <p><span>The involvement</span> <b>Involvement</b> of cardiac muscle is felt to be <b>very</b> uncommon in anti-HMGCR myopathy, and therefore early cardiac evaluation is not a high priority for this condition. </p>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="withCorrectionsText">
                    <p><b>Involvement</b> <span>The involvement</span> of cardiac muscle is <b>felt</b> <span>considered</span> <b>to be very</b> uncommon in anti-HMGCR myopathy, and therefore<span>, early</span> <b>early</b> cardiac evaluation is not a high priority for this condition.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
          <div class="carousel-item">
            <div class="sliderContent">
              <div class="row">
                <div class="col-md-5">
                  <div class="withOutCorrections">
                    <p>I think <b>a</b> <span>no</span> carrier of a known genetic risk should not be obligated to tell his or her relatives.</p>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="withCorrectionsText">
                    <p>I think <span>that</span> a carrier of <b>a</b> known genetic risk should <b>not</b> be obligated to <b>tell</b> <span>divulge this to</span> his or her relatives .</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="PMFooterButtons">
        <button (click)="trialEnable()" class="TryButtons" [disabled]="isDisabled" *ngIf="trialStatus == 'trial_start'">Try it for 60 minutes!</button>
        <button (click)="upgradeToPremium()" class="TryButtons" mat-dialog-close *ngIf="trialStatus == 'trial_end' || trialStatus == 'trial_auto_edit'">Upgrade to Premium</button>
      </div>
    </div>
  </div>

  <div class="PMFooterButtons"  *ngIf="!exampleEnable">
    <button (click)="trialEnable()" class="TryButtons" *ngIf="trialStatus == 'trial_start'">Try it for 60 minutes!</button>
    <button (click)="upgradeToPremium()" class="TryButtons" mat-dialog-close *ngIf="trialStatus == 'trial_end' || trialStatus == 'trial_auto_edit'">Upgrade to Premium</button>
    <button (click)="seeExample()" class="GoBackButton">See examples</button>
  </div>
</div>