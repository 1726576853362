import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetService } from 'src/app/_services/networkcalls/asset.service';
import { AssetType } from 'src/app/_enums/asset-type.enum';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-uninstall',
  templateUrl: './uninstall.component.html',
  styleUrls: ['./uninstall.component.scss']
})
export class UninstallComponent implements OnInit {
  platformName: boolean;
  websitemName: boolean;
  OthersComments: boolean;
  isDisabled : boolean;
  constructor(private route: ActivatedRoute,
    public networkCalls: AssetService,
    private toastr: ToastrService,
    ) { 
      this.createOrResetFeedback();
      this.isDisabled = true;
    }

  assetId: string;
  assetType : AssetType = AssetType.ext_chrome;

  feedbackDataObject: any ;


  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.assetId = atob(params['assetid']);
      
      if(params['assettype']){
        this.assetType = params['assettype'];
      }
      this.deleteAsset();
    });
  }

  createOrResetFeedback(){
    this.feedbackDataObject = {
      incorrect_suggestions: {
      "selected": false,
      "value" : "Corrections are incorrect / Doesn't suggest corrections",
      "text" : ""
    },
    favorite_platform: {
      "selected": false,
      "value" : "Doesn't work on my favorite platform",
      "text" : ""
    },
    favorite_websites: {
      "selected": false,
      "value" : "Doesn't work on my favorite websites",
      "text" : ""
    },
    no_signup: {
      "selected": false,
      "value" : "Don't want to sign-up / create an account",
      "text" : ""
    },
    others: {
      "selected": false,
      "value" : "Others",
      "text" : ""
    }
  };
  }  

  deleteAsset() {
    this.networkCalls.deleteAssetById(this.assetType, this.assetId).subscribe(() => {
    },
      error => {
      });
  }

  validateSelectedOptions()
  {
    if (this.feedbackDataObject.incorrect_suggestions.selected ||this.feedbackDataObject.favorite_platform.selected
      || this.feedbackDataObject.favorite_websites.selected ||this.feedbackDataObject.no_signup.selected ||this.feedbackDataObject.others.selected){
        this.isDisabled = false ;
        return;
       }
      this.isDisabled = true;
  }

  submitFeedback(){
    this.networkCalls.submitFeedback( this.assetType,this.assetId,this.feedbackDataObject).subscribe(() => {
      this.toastr.success("Thank you for your feedback.");
      this.createOrResetFeedback();
      this.isDisabled = true;
    },
      error => {
        this.toastr.error("Your feedback is not submitted.");
      });
  }
  

}
