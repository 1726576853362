import { AuthService } from 'src/app/_guards/auth.service';
import { SharedService } from 'src/app/_services/shared.service';
import { Component, OnInit, Inject } from '@angular/core';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { PluginsService } from 'src/app/_services/plugins.service';
import { AssetType } from 'src/app/_enums/asset-type.enum';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { GetMetaService } from 'src/app/_services/get-meta.service';

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss']
})
export class InstallComponent implements OnInit {

  constructor(    
    public auth: AuthService ,
    private sharedService: SharedService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private pluginService: PluginsService,
    public networkCalls: DashboardService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public metaString : GetMetaService
    ) { }
    isLoggedIn : boolean = false;
    assetType : AssetType = AssetType.ext_chrome;
    userId: string;
    selectedProfile: any;
    userDetails: any;

  ngOnInit() {

     this.route.queryParams.subscribe(params => {
            if(params['assettype']){
        this.assetType = params['assettype'];
      }
    });

    if(this.auth.isAuthenticated()){
      const {token,refresh_token,token_type} = this.storage.get(this.metaString.getMeta());
      const pluginPayload = {token,refresh_token,token_type}
      const localMeta = this.sharedService.getLocalStorageMeta();
      pluginPayload["email_id"] = btoa(localMeta.sub);
      this.pluginService.handleLogin(pluginPayload);
      this.isLoggedIn = true;

      this.userId = btoa(localMeta.sub)
      this.getUserProfileData()
      this.getSelectedProfileData();
    }

    if (navigator.userAgent.indexOf("WOW64") != -1 || 
        navigator.userAgent.indexOf("Win64") != -1 ){
        document.getElementById("successDeviceDetector").innerHTML = '<a href="https://s3.amazonaws.com/trinka-word-plugin/64bit/Trinka.Installer.msi" target="_blank"><img src="../../../../assets/images/download_trinka_icon.png" alt=""><div class="trinka_button_text"><p>Download Now</p><span>MS Word Add-in</span></div></a>';
      } else {
        document.getElementById("successDeviceDetector").innerHTML = '<a href="https://s3.amazonaws.com/trinka-word-plugin/32bit/Trinka.Installer.msi" target="_blank"><img src="../../../../assets/images/download_trinka_icon.png" alt=""><div class="trinka_button_text"><p>Download Now</p><span>MS Word Add-in</span></div></a>';
      }
  }

  getUserProfileData() {
    this.spinner.show();
    this.networkCalls.getUserDetails(this.userId).subscribe(data => {
      this.userDetails = data.data
      this.spinner.hide();
    }, error => {
     this.sharedService.errorHandller(error);
     this.spinner.hide();
    });
  }

  getSelectedProfileData(){   
    this.networkCalls.getSelectedProfileData(this.userId).subscribe(result=>{
      if(result["status"]){
        this.selectedProfile = result['data']['profile_data'];
      } 
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }


  signOut() {
    this.sharedService.SignOut();
  }
}
