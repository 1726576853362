<div class="renameFolderPopup">
    <div class="showFolderPopupHeader">
        <h1>How would you like to proceed?</h1>
        <button class="cancelbuttons"  mat-button  mat-close (click)="close()" >
            <img src="../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>
   
    <div class="optionWrapper">
        <div class="optionWrapperORSection">
            OR
        </div>
        <div class="optionTextHeader">
            <p>Option 1</p>
            <p>Option 2</p>
        </div>
        <div class="automatedFileSection recommended-card" (click)="selectUpload('AutoEdit')">
            <div class="automatedFileSectionHeader">
                <h3>
                    <span class="heading-icon auto-edit-option-icon"></span>
                    Proofread file in one go
                </h3>
                <img src="../../../../assets/images/ProofreadFileInOneGo.svg" alt="">
                <p>Note: Available for English Language only</p>
            </div>
            <!-- <h3 class="proofread-file-option-header">
                <span class="proofread-file-option">
                    <span class="heading-icon auto-edit-option-icon"></span>
                    File Proofread
                </span>
                <span class="recommended-txt">Recommended</span>
            </h3> -->
            <!-- <div class="automatedFileSectionDetails">
                <div class="single-detail">
                    <span class="single-detail-icon book-icon"></span>
                    <span class="single-detail-txt">Trinka proofreads the entire file in one go</span>
                </div>
                <div class="single-detail">
                    <span class="single-detail-icon received-icon"></span>
                    <span class="single-detail-txt">Receive a word file with tracked changes</span>
                </div>
                <div class="single-detail">
                    <span class="single-detail-icon thunder-icon"></span>
                    <span class="single-detail-txt">Time taken is 2-5 mins</span>
                </div>
            </div>
            <div class="automatedFileTextChanges">
                <p class="section-heading">File with tracked changes</p>
                <p class="sample-edited-text">
                    The yak <span class="discarded-txt">was</span>&nbsp;<span class="accepted-txt">were</span> coloured white to <span class="discarded-txt">distinguished them but</span>&nbsp;<span class="accepted-txt">distinguish them, but</span> it was hard to make out <span class="discarded-txt">teh</span>&nbsp;<span class="accepted-txt">the</span> difference. They were terrified and <span class="discarded-txt">could have possibly</span>&nbsp;<span class="accepted-txt">could have</span> undergone trauma.
                </p>
            </div> -->
            <!-- <button (click)="selectUpload('AutoEdit')" [disabled]="disableAutoeditBtn">Try Auto File Edit </button>
            <span>1 Credit is used for every 5000 words</span> -->
        </div>
        <div class="automatedFileSection proofreadOnCloudEditorBox" (click)="selectUpload('Manually')">
            <div class="automatedFileSectionHeader">
                <h3 style="margin-bottom: 26px;">
                    <span class="heading-icon cloud-editor-option-icon"></span>
                    Proofread on Cloud Editor
                </h3>
            </div>
            
            <div class="automatedFileSectionDetails">
                <div class="single-detail">
                    <span class="single-detail-icon glass-icon"></span>
                    <span class="single-detail-txt">Review suggestions as you write</span>
                </div>
                <div class="single-detail">
                    <span class="single-detail-icon tick-circle-icon"></span>
                    <span class="single-detail-txt">Accept/reject changes in real-time</span>
                </div>
                <div class="single-detail">
                    <span class="single-detail-icon import-icon"></span>
                    <span class="single-detail-txt">Download the edited file</span>
                </div>
            </div>
            <div class="automatedFileTextChanges">
                <img src="../../../../assets/images/ProofreadOnCloudEditor.svg" alt="">
                <!-- <p class="section-heading">Trinka Cloud Editor</p>
                <div class="sample-edited-text-outer">
                    <p class="sample-edited-text">
                        The yak <span class="wrong-word-text">was</span> coloured white to <span class="wrong-word-text">distinguished them but</span> it was hard to make out <span class="wrong-word-text">teh</span> difference. They were terrified and <span class="wrong-word-text">could have possibly</span> undergone trauma.
                    </p>
                    <div class="manual-corrections-outer">
                        <div class="manual-correction">
                            <span class="wrong-word">was</span>
                            <span class="correction-arrow">-></span>
                            <span class="corrected-word">were</span>
                        </div>
                        <div class="manual-correction">
                            <span class="wrong-word">teh</span>
                            <span class="correction-arrow">-></span>
                            <span class="corrected-word">the</span>
                        </div>
                        
                        <div class="manual-correction">
                            <span class="wrong-word">but</span>
                            <span class="correction-arrow">-></span>
                            <span class="corrected-word">, but</span>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- <button (click)="selectUpload('AutoEdit')" [disabled]="disableAutoeditBtn">Try Auto File Edit </button>
            <span>1 Credit is used for every 5000 words</span> -->
        </div>
        <!-- <div class="automatedFileSection">
            <h3>Manually edit file</h3>
            <p>Make changes on the go with real-time language suggestions </p>
            <button (click)="selectUpload('Manually')">Edit Manually</button>
            <span>Free Usage</span>
        </div> -->
    </div>
</div>