<div class="dictionary_module_popup">
  <button class="cancelbuttons" mat-button mat-dialog-close>
    <img src="../../../assets/images/closeIcons.png" alt="">
  </button>
  <div class="dictionary_loader" *ngIf="!dictApiCall">
      <div class='checkingloader'>
        <div class='loader--text'></div>
        <div class='loader--dot'></div>
        <div class='loader--dot'></div>
        <div class='loader--dot'></div>
      </div>
  </div>
  <div class="dictionary_popup_header">
    <h2>Words Present in My Dictionary</h2>
    <a (click)="redirectToDict()">Go to My Dictionary</a>  
  </div>
  <div class="dictionary_popup_body">
      <ul>
        <li *ngFor="let words of dicWordList;index as i">{{words.word}}</li>
      </ul>
  </div>
</div>
