<div class="publicationSubSection">
<mat-accordion>
    <ng-container *ngFor="let object of authorShipList; let i = index">
        <mat-expansion-panel class="authorship_section" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <div class="authorShip_header">
                    <div class="authorship_check_header">
                        <p><span>{{object.checkNo}}</span> {{object.checkName}}
                            <i class="authorship_alternet_text">
                                <b>{{object.tooltip}}</b>
                                <img src="../../../../assets/images/Icon_Info.svg" alt="">
                            </i>
                        </p>
                    </div>
                    <div class="authorship_found_section" *ngIf="object.flag == true">
                        <p>{{object.count}} {{object.text}}</p>
                    </div>
                    <div class="authorship_found_section authorship_not_found_section" *ngIf="object.flag == false">
                        <p>{{object.text}}</p>
                    </div>
                </div>
            </mat-expansion-panel-header>

            <div class="authorship_card_body" *ngIf="object.flag == true">
                <ul>
                    <li *ngFor="let checkList of object.checkList; let j = index">{{checkList}}</li>
                </ul>
            </div>
            <div class="authorship_card_body" *ngIf="object.flag == false">
               {{object.textExpand}}
            </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
</div>