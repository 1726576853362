<div class="paraphraser-wrapper">
  <div class="paraphraserHeader">
    <div class="selectOpeionParaphraser dropdown">
      <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Paraphraser
      </button>
      <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2228_76316)">
            <path d="M3.3628 5.61725V5.51725H3.2628H1.49712H1.39712V5.61725V6.79436C1.39712 6.96897 1.30399 7.13025 1.15285 7.21748C1.00171 7.30473 0.815429 7.30473 0.664277 7.21748C0.513143 7.13025 0.420007 6.96897 0.420007 6.79436V4.14585C0.420007 3.44562 0.793629 2.79862 1.39999 2.44851C2.00634 2.09841 2.75359 2.09841 3.35994 2.44851C3.9663 2.79862 4.33992 3.44562 4.33992 4.14585V6.79436C4.33992 6.96897 4.24678 7.13025 4.09565 7.21748C3.9445 7.30473 3.75822 7.30473 3.60707 7.21748C3.45594 7.13025 3.3628 6.96897 3.3628 6.79436V5.61725ZM3.26278 4.54011H3.36278V4.44011V4.14583C3.36278 3.88524 3.25921 3.63514 3.07496 3.45085C2.89069 3.26655 2.64058 3.16301 2.37996 3.16301H2.37993C2.11933 3.16301 1.86924 3.26659 1.68495 3.45084C1.50065 3.63511 1.39711 3.88522 1.39711 4.14583V4.44011V4.54011H1.49711H3.26278Z" fill="#99A2B2" stroke="white" stroke-width="0.2"/>
            <path d="M6.69443 2.18598V2.08598V0.908863C6.69443 0.734255 6.60129 0.57298 6.45016 0.485746C6.29901 0.398501 6.11273 0.398502 5.96158 0.485745C5.81045 0.572979 5.71731 0.734262 5.71731 0.908863V6.79445C5.71731 6.92401 5.76879 7.04834 5.86038 7.13993C5.95197 7.23152 6.07631 7.28301 6.20587 7.28301H7.97154C8.41336 7.28301 8.83694 7.10753 9.1494 6.79522C9.14941 6.79521 9.14942 6.7952 9.14943 6.79519M6.69443 2.18598L9.22013 2.60307C9.5512 2.93428 9.73722 3.3833 9.73722 3.85166V5.61733C9.73722 6.08569 9.5512 6.53472 9.22013 6.86592L9.14943 6.79519M6.69443 2.18598H6.79443H7.97154C8.41336 2.18598 8.83696 2.36146 9.1494 2.67377M6.69443 2.18598L9.1494 2.67377M9.14943 6.79519C9.46174 6.48274 9.63722 6.05915 9.63722 5.61733V3.85166C9.63722 3.40984 9.46174 2.98626 9.14943 2.6738C9.14942 2.67379 9.14941 2.67378 9.1494 2.67377M9.14943 6.79519L9.1494 2.67377M6.69443 6.20589V6.30589H6.79443H7.97154C8.15413 6.30589 8.32933 6.23335 8.45845 6.10424C8.58757 5.97512 8.6601 5.79991 8.6601 5.61733V3.85166C8.6601 3.66907 8.58757 3.49387 8.45845 3.36475C8.32933 3.23563 8.15413 3.1631 7.97154 3.1631H6.79443H6.69443V3.2631V6.20589Z" fill="#99A2B2" stroke="white" stroke-width="0.2"/>
            <path d="M12.3645 5.93181L12.3646 5.93185C12.6041 6.17129 12.929 6.30585 13.2677 6.30585H13.8562C14.0308 6.30585 14.1921 6.39899 14.2793 6.55012C14.3666 6.70127 14.3666 6.88754 14.2793 7.0387C14.1921 7.18983 14.0308 7.28297 13.8562 7.28297H13.2677C12.6698 7.28297 12.0964 7.04546 11.6737 6.62268L11.6737 6.62265C11.2509 6.2 11.0134 5.62662 11.0134 5.02873V4.44017C11.0134 3.84228 11.2509 3.26891 11.6737 2.84625L11.6737 2.84623C12.0964 2.42344 12.6698 2.18594 13.2677 2.18594H13.8562C14.0308 2.18594 14.1921 2.27908 14.2793 2.43021C14.3666 2.58136 14.3666 2.76763 14.2793 2.91879C14.1921 3.06992 14.0308 3.16305 13.8562 3.16305H13.2677C12.929 3.16305 12.6042 3.29761 12.3646 3.53705L12.3645 3.53709C12.1251 3.77666 11.9905 4.1015 11.9905 4.44017V5.02873C11.9905 5.36739 12.1251 5.69223 12.3645 5.93181Z" fill="#99A2B2" stroke="white" stroke-width="0.2"/>
            <path d="M15.3921 8.81027L15.3811 8.79918C15.2894 8.70671 15.1646 8.65469 15.0342 8.65469C14.9039 8.65469 14.779 8.70671 14.6873 8.79918L14.6871 8.79943L9.21952 14.273L9.14881 14.3438L9.07806 14.2731L7.84801 13.043L7.77723 12.9722L7.84807 12.9015L11.2616 9.49404L15.3921 8.81027ZM15.3921 8.81027C15.4782 8.90091 15.5265 9.02131 15.5265 9.1468C15.5265 9.27715 15.4744 9.40201 15.382 9.49371L15.3817 9.494L9.4961 15.3796L9.49581 15.3799C9.4041 15.4724 9.27924 15.5244 9.1489 15.5244C9.01855 15.5244 8.89369 15.4723 8.80199 15.3799L8.80183 15.3797L7.15978 13.7318L7.08894 13.6607L7.01811 13.7318L5.37606 15.3797L5.3759 15.3799C5.28419 15.4724 5.15933 15.5244 5.02899 15.5244C4.89864 15.5244 4.77378 15.4723 4.68208 15.3799L4.68179 15.3796L2.32759 13.0254C2.32758 13.0254 2.32757 13.0254 2.32755 13.0254C2.20354 12.9012 2.15512 12.7205 2.20051 12.5511L2.20052 12.5511C2.24592 12.3816 2.3782 12.2493 2.54771 12.2039L2.54772 12.2039C2.71706 12.1585 2.89778 12.2069 3.02191 12.3309C3.02194 12.3309 3.02196 12.331 3.02199 12.331L4.95817 14.2731L5.02892 14.3441L5.09974 14.2732L10.5672 8.79964L15.3921 8.81027ZM7.98949 12.9017L11.3324 9.56471C11.4816 9.41534 11.5399 9.19779 11.4853 8.99388L7.98949 12.9017ZM7.98949 12.9017L7.98943 12.9016L7.91872 12.9723L7.98936 13.0431L7.98943 13.043L9.07806 14.1316L9.07802 14.1317L9.14877 14.2024L9.21948 14.1316L9.21944 14.1316L7.98949 12.9017ZM11.3887 9.01977L11.3887 9.01976C11.3433 8.85024 11.211 8.71797 11.0415 8.67256L11.0415 8.67256C10.8722 8.62718 10.6914 8.67558 10.5673 8.79956L11.3887 9.01977ZM11.3887 9.01977C11.4341 9.1891 11.3857 9.36981 11.2617 9.49394L11.3887 9.01977Z" fill="#99A2B2" stroke="white" stroke-width="0.2"/>
            </g>
            <defs>
            <clipPath id="clip0_2228_76316">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
          </svg>
          Grammar</a>
        <a class="dropdown-item">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 3.5H9.33334C9.06001 3.5 8.83334 3.27333 8.83334 3C8.83334 2.72667 9.06001 2.5 9.33334 2.5H14C14.2733 2.5 14.5 2.72667 14.5 3C14.5 3.27333 14.2733 3.5 14 3.5Z" fill="#99A2B2"/>
            <path d="M14 6.83594H9.33334C9.06001 6.83594 8.83334 6.60927 8.83334 6.33594C8.83334 6.0626 9.06001 5.83594 9.33334 5.83594H14C14.2733 5.83594 14.5 6.0626 14.5 6.33594C14.5 6.60927 14.2733 6.83594 14 6.83594Z" fill="#99A2B2"/>
            <path d="M14 10.1641H2C1.72667 10.1641 1.5 9.9374 1.5 9.66406C1.5 9.39073 1.72667 9.16406 2 9.16406H14C14.2733 9.16406 14.5 9.39073 14.5 9.66406C14.5 9.9374 14.2733 10.1641 14 10.1641Z" fill="#99A2B2"/>
            <path d="M14 13.5H2C1.72667 13.5 1.5 13.2733 1.5 13C1.5 12.7267 1.72667 12.5 2 12.5H14C14.2733 12.5 14.5 12.7267 14.5 13C14.5 13.2733 14.2733 13.5 14 13.5Z" fill="#99A2B2"/>
            <path d="M5.28001 7.16406H3.38668C2.36001 7.16406 1.83334 6.64406 1.83334 5.61073V3.7174C1.83334 2.69073 2.35334 2.16406 3.38668 2.16406H5.28668C6.31334 2.16406 6.84001 2.68406 6.84001 3.7174V5.6174C6.83334 6.64406 6.31334 7.16406 5.28001 7.16406ZM3.38668 3.16406C2.91334 3.16406 2.83334 3.24406 2.83334 3.7174V5.6174C2.83334 6.09073 2.91334 6.17073 3.38668 6.17073H5.28668C5.76001 6.17073 5.84001 6.09073 5.84001 5.6174V3.7174C5.84001 3.24406 5.76001 3.16406 5.28668 3.16406H3.38668Z" fill="#99A2B2"/>
          </svg>
          Paraphraser</a>
        <a class="dropdown-item">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5067 3.5C12.9534 3.5 12.5067 3.05333 12.5067 2.5C12.5067 1.94667 12.9534 1.5 13.5067 1.5H13.5133C14.0667 1.5 14.5133 1.94667 14.5133 2.5C14.5133 3.05333 14.06 3.5 13.5067 3.5Z" fill="#99A2B2"/>
            <path d="M2.50668 14.5C1.95335 14.5 1.50668 14.0533 1.50668 13.5C1.50668 12.9467 1.95335 12.5 2.50668 12.5H2.51334C3.06667 12.5 3.51334 12.9467 3.51334 13.5C3.51334 14.0533 3.06002 14.5 2.50668 14.5Z" fill="#99A2B2"/>
            <path d="M4.02001 10.9359C3.85334 10.9359 3.68669 10.8493 3.59335 10.6959C3.10002 9.88927 2.83334 8.95594 2.83334 8.0026C2.83334 5.15594 5.15334 2.83594 8.00001 2.83594C8.94001 2.83594 9.86 3.08927 10.66 3.56927C10.8933 3.70927 10.9733 4.01594 10.8333 4.25594C10.6933 4.48927 10.3867 4.56927 10.1467 4.42927C9.50001 4.0426 8.76002 3.83594 8.00668 3.83594C5.70668 3.83594 3.84002 5.7026 3.84002 8.0026C3.84002 8.76927 4.05334 9.5226 4.45334 10.1759C4.60001 10.4093 4.52667 10.7159 4.28667 10.8626C4.20001 10.9159 4.10668 10.9359 4.02001 10.9359Z" fill="#99A2B2"/>
            <path d="M7.99998 13.1695C7.03998 13.1695 6.10666 12.9028 5.29999 12.4095C5.06666 12.2628 4.99333 11.9561 5.13333 11.7228C5.27999 11.4895 5.58666 11.4161 5.81999 11.5561C6.47332 11.9561 7.22665 12.1695 7.99998 12.1695C10.3 12.1695 12.1667 10.3028 12.1667 8.00279C12.1667 7.23612 11.9533 6.48279 11.5533 5.82946C11.4066 5.59613 11.48 5.28946 11.72 5.14279C11.9533 5.00279 12.26 5.06946 12.4066 5.30946C12.9 6.12279 13.1667 7.04945 13.1667 8.00945C13.1667 10.8495 10.8467 13.1695 7.99998 13.1695Z" fill="#99A2B2"/>
          </svg>
          Bias Check</a>
        <a class="dropdown-item">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.60668 11.4543C2.48001 11.4543 2.35335 11.4076 2.25335 11.3076C1.34001 10.3876 0.833344 9.17427 0.833344 7.88761C0.833344 5.21427 3.00001 3.04093 5.66668 3.04093L9.71335 3.05427L8.98667 2.36091C8.78667 2.16758 8.78001 1.85425 8.97335 1.65425C9.16668 1.45425 9.48001 1.44761 9.68001 1.64094L11.3067 3.20092C11.4533 3.34092 11.5 3.56092 11.4267 3.74759C11.3533 3.93426 11.1667 4.06095 10.96 4.06095L5.66001 4.0476C3.54668 4.0476 1.82668 5.77428 1.82668 7.89428C1.82668 8.91428 2.22667 9.88091 2.95334 10.6076C3.14667 10.8009 3.14667 11.1209 2.95334 11.3142C2.86001 11.4076 2.73335 11.4543 2.60668 11.4543Z" fill="#99A2B2"/>
            <path d="M6.6666 14.4974C6.53993 14.4974 6.41993 14.4507 6.31993 14.3574L4.69326 12.7974C4.5466 12.6574 4.49993 12.4374 4.57327 12.2507C4.6466 12.064 4.83327 11.9374 5.03993 11.9374L10.3399 11.9507C12.4533 11.9507 14.1733 10.224 14.1733 8.10402C14.1733 7.08403 13.7733 6.11739 13.0466 5.39073C12.8533 5.19739 12.8533 4.8774 13.0466 4.68406C13.2399 4.49073 13.5599 4.49073 13.7533 4.68406C14.6666 5.60406 15.1733 6.81736 15.1733 8.10402C15.1733 10.7774 13.0066 12.9507 10.3399 12.9507L6.29327 12.9374L7.01993 13.6307C7.21993 13.824 7.2266 14.1374 7.03327 14.3374C6.9266 14.444 6.79993 14.4974 6.6666 14.4974Z" fill="#99A2B2"/>
            <path d="M10 8.5H6C5.72667 8.5 5.5 8.27333 5.5 8C5.5 7.72667 5.72667 7.5 6 7.5H10C10.2733 7.5 10.5 7.72667 10.5 8C10.5 8.27333 10.2733 8.5 10 8.5Z" fill="#99A2B2"/>
          </svg>
          Consistency</a>
        <a class="dropdown-item">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.60001 15.1641H5.06668C2.14001 15.1641 0.833344 13.8574 0.833344 10.9307V8.3974C0.833344 5.47073 2.14001 4.16406 5.06668 4.16406H7.06668C7.34001 4.16406 7.56668 4.39073 7.56668 4.66406C7.56668 4.9374 7.34001 5.16406 7.06668 5.16406H5.06668C2.68001 5.16406 1.83334 6.01073 1.83334 8.3974V10.9307C1.83334 13.3174 2.68001 14.1641 5.06668 14.1641H7.60001C9.98668 14.1641 10.8333 13.3174 10.8333 10.9307V8.93073C10.8333 8.6574 11.06 8.43073 11.3333 8.43073C11.6067 8.43073 11.8333 8.6574 11.8333 8.93073V10.9307C11.8333 13.8574 10.5267 15.1641 7.60001 15.1641Z" fill="#99A2B2"/>
            <path d="M11.3333 9.42975H9.20001C7.32668 9.42975 6.56668 8.66974 6.56668 6.79641V4.66308C6.56668 4.46308 6.68668 4.27641 6.87335 4.20308C7.06001 4.12308 7.27335 4.16974 7.42001 4.30974L11.6867 8.57641C11.8267 8.71641 11.8733 8.93641 11.7933 9.12308C11.72 9.30975 11.5333 9.42975 11.3333 9.42975ZM7.56668 5.86975V6.79641C7.56668 8.12308 7.87335 8.42975 9.20001 8.42975H10.1267L7.56668 5.86975Z" fill="#99A2B2"/>
            <path d="M10.4 1.83594H7.73334C7.46 1.83594 7.23334 1.60927 7.23334 1.33594C7.23334 1.0626 7.46 0.835938 7.73334 0.835938H10.4C10.6733 0.835938 10.9 1.0626 10.9 1.33594C10.9 1.60927 10.6733 1.83594 10.4 1.83594Z" fill="#99A2B2"/>
            <path d="M4.66666 3.83594C4.39332 3.83594 4.16666 3.60927 4.16666 3.33594C4.16666 1.95594 5.28666 0.835938 6.66666 0.835938H8.41332C8.68666 0.835938 8.91332 1.0626 8.91332 1.33594C8.91332 1.60927 8.68666 1.83594 8.41332 1.83594H6.66666C5.83999 1.83594 5.16666 2.50927 5.16666 3.33594C5.16666 3.60927 4.93999 3.83594 4.66666 3.83594Z" fill="#99A2B2"/>
            <path d="M12.7933 11.8359C12.52 11.8359 12.2933 11.6093 12.2933 11.3359C12.2933 11.0626 12.52 10.8359 12.7933 10.8359C13.5533 10.8359 14.1667 10.2159 14.1667 9.4626V5.33594C14.1667 5.0626 14.3933 4.83594 14.6667 4.83594C14.94 4.83594 15.1667 5.0626 15.1667 5.33594V9.4626C15.1667 10.7693 14.1 11.8359 12.7933 11.8359Z" fill="#99A2B2"/>
            <path d="M14.6667 5.83495H12.6667C10.8933 5.83495 10.1667 5.10829 10.1667 3.33495V1.33495C10.1667 1.13495 10.2867 0.948287 10.4733 0.874953C10.66 0.794953 10.8733 0.84162 11.02 0.98162L15.02 4.98162C15.16 5.12162 15.2067 5.34162 15.1267 5.52829C15.0533 5.71495 14.8667 5.83495 14.6667 5.83495ZM11.1667 2.54162V3.33495C11.1667 4.55495 11.4467 4.83495 12.6667 4.83495H13.46L11.1667 2.54162Z" fill="#99A2B2"/>
          </svg>
          Enago Reports</a>
        <a class="dropdown-item">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33334 6.5026C1.06001 6.5026 0.833344 6.27594 0.833344 6.0026V4.33594C0.833344 2.4026 2.40668 0.835938 4.33334 0.835938H6.00001C6.27334 0.835938 6.50001 1.0626 6.50001 1.33594C6.50001 1.60927 6.27334 1.83594 6.00001 1.83594H4.33334C2.95334 1.83594 1.83334 2.95594 1.83334 4.33594V6.0026C1.83334 6.27594 1.60668 6.5026 1.33334 6.5026Z" fill="#99A2B2"/>
            <path d="M14.6667 6.5026C14.3933 6.5026 14.1667 6.27594 14.1667 6.0026V4.33594C14.1667 2.95594 13.0467 1.83594 11.6667 1.83594H10C9.72667 1.83594 9.5 1.60927 9.5 1.33594C9.5 1.0626 9.72667 0.835938 10 0.835938H11.6667C13.5933 0.835938 15.1667 2.4026 15.1667 4.33594V6.0026C15.1667 6.27594 14.94 6.5026 14.6667 6.5026Z" fill="#99A2B2"/>
            <path d="M11.6667 15.1641H10.6667C10.3933 15.1641 10.1667 14.9374 10.1667 14.6641C10.1667 14.3907 10.3933 14.1641 10.6667 14.1641H11.6667C13.0467 14.1641 14.1667 13.0441 14.1667 11.6641V10.6641C14.1667 10.3907 14.3933 10.1641 14.6667 10.1641C14.94 10.1641 15.1667 10.3907 15.1667 10.6641V11.6641C15.1667 13.5974 13.5933 15.1641 11.6667 15.1641Z" fill="#99A2B2"/>
            <path d="M6.00001 15.1667H4.33334C2.40668 15.1667 0.833344 13.6 0.833344 11.6667V10C0.833344 9.72667 1.06001 9.5 1.33334 9.5C1.60668 9.5 1.83334 9.72667 1.83334 10V11.6667C1.83334 13.0467 2.95334 14.1667 4.33334 14.1667H6.00001C6.27334 14.1667 6.50001 14.3933 6.50001 14.6667C6.50001 14.94 6.27334 15.1667 6.00001 15.1667Z" fill="#99A2B2"/>
            <path d="M9.33332 12.1693H6.66666C5.05332 12.1693 4.16666 11.2826 4.16666 9.66927V6.33594C4.16666 4.7226 5.05332 3.83594 6.66666 3.83594H9.33332C10.9467 3.83594 11.8333 4.7226 11.8333 6.33594V9.66927C11.8333 11.2826 10.9467 12.1693 9.33332 12.1693ZM6.66666 4.83594C5.61332 4.83594 5.16666 5.2826 5.16666 6.33594V9.66927C5.16666 10.7226 5.61332 11.1693 6.66666 11.1693H9.33332C10.3867 11.1693 10.8333 10.7226 10.8333 9.66927V6.33594C10.8333 5.2826 10.3867 4.83594 9.33332 4.83594H6.66666Z" fill="#99A2B2"/>
            <path d="M12.6667 8.5H3.33334C3.06001 8.5 2.83334 8.27333 2.83334 8C2.83334 7.72667 3.06001 7.5 3.33334 7.5H12.6667C12.94 7.5 13.1667 7.72667 13.1667 8C13.1667 8.27333 12.94 8.5 12.6667 8.5Z" fill="#99A2B2"/>
            </svg>
          Plagiarism Checker</a>
        <a class="dropdown-item">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.54 13.4498C10.3667 13.4498 10.1867 13.4431 9.99332 13.4231C9.64665 13.3964 9.25332 13.3298 8.84665 13.2298L7.72665 12.9631C4.65332 12.2364 3.64665 10.6098 4.36665 7.5431L5.01999 4.74976C5.16665 4.11643 5.33999 3.6031 5.55332 3.17643C6.69999 0.809763 8.89332 1.0231 10.4533 1.38976L11.5667 1.64976C13.1267 2.01643 14.1133 2.59643 14.6667 3.4831C15.2133 4.36976 15.3 5.50976 14.9333 7.06976L14.28 9.85643C13.7067 12.2964 12.5133 13.4498 10.54 13.4498ZM8.74665 2.1631C7.63332 2.1631 6.92665 2.6231 6.45332 3.60976C6.27999 3.96976 6.12665 4.41643 5.99332 4.97643L5.33999 7.76976C4.74665 10.2898 5.43332 11.3898 7.95332 11.9898L9.07332 12.2564C9.43332 12.3431 9.77332 12.3964 10.08 12.4231C11.8933 12.6031 12.7933 11.8098 13.3 9.62976L13.9533 6.8431C14.2533 5.55643 14.2133 4.65643 13.8133 4.00976C13.4133 3.3631 12.6267 2.9231 11.3333 2.6231L10.22 2.3631C9.66665 2.22976 9.17332 2.1631 8.74665 2.1631Z" fill="#99A2B2"/>
            <path d="M5.55335 14.837C3.84001 14.837 2.74668 13.8104 2.04668 11.6437L1.19335 9.01036C0.246681 6.07703 1.09335 4.4237 4.01335 3.47703L5.06668 3.13703C5.41335 3.03036 5.67335 2.95703 5.90668 2.91703C6.10001 2.87703 6.28668 2.95036 6.40001 3.1037C6.51335 3.25703 6.53335 3.45703 6.45335 3.63036C6.28001 3.9837 6.12668 4.43036 6.00001 4.99036L5.34668 7.7837C4.75335 10.3037 5.44001 11.4037 7.96001 12.0037L9.08001 12.2704C9.44001 12.357 9.78001 12.4104 10.0867 12.437C10.3 12.457 10.4733 12.6037 10.5333 12.8104C10.5867 13.017 10.5067 13.2304 10.3333 13.3504C9.89335 13.6504 9.34001 13.9037 8.64001 14.1304L7.58668 14.477C6.82001 14.717 6.15335 14.837 5.55335 14.837ZM5.18668 4.15036L4.32668 4.43036C1.94668 5.19703 1.38001 6.31703 2.14668 8.7037L3.00001 11.337C3.77335 13.717 4.89335 14.2904 7.27335 13.5237L8.32668 13.177C8.36668 13.1637 8.40001 13.1504 8.44001 13.137L7.73335 12.9704C4.66001 12.2437 3.65335 10.617 4.37335 7.55036L5.02668 4.75703C5.07335 4.5437 5.12668 4.33703 5.18668 4.15036Z" fill="#99A2B2"/>
            <path d="M11.66 7.01042C11.62 7.01042 11.58 7.00376 11.5333 6.99709L8.30001 6.17709C8.03334 6.11042 7.87334 5.83709 7.94001 5.57042C8.00667 5.30376 8.28001 5.14376 8.54667 5.21042L11.78 6.03042C12.0467 6.09709 12.2067 6.37042 12.14 6.63709C12.0867 6.85709 11.88 7.01042 11.66 7.01042Z" fill="#99A2B2"/>
            <path d="M9.70667 9.26188C9.66667 9.26188 9.62667 9.25522 9.58 9.24855L7.64 8.75522C7.37334 8.68855 7.21334 8.41522 7.28 8.14855C7.34667 7.88188 7.62 7.72188 7.88667 7.78855L9.82667 8.28188C10.0933 8.34855 10.2533 8.62188 10.1867 8.88855C10.1333 9.11522 9.93334 9.26188 9.70667 9.26188Z" fill="#99A2B2"/>
          </svg>
          Related Papers</a>
      </div> -->
    </div>

    <div class="degreeOfChanges">
      <label>
        Degree of changes
      </label>
      <div class="sliderText">
        <i class="lowText">Low</i>
        <!--  [disabled]="(!errorDuringParaphrase.status && !paraphrasedSentences.length && !loader) || errorDuringParaphrase.status" -->
        <mat-slider
        [title]="isParaphrasedSentencesUpatedBackToEditor ? 'Below text is already paraphrased. Select a text to paraphrase again.' : ''"
        [disabled]="isParaphrasedSentencesUpatedBackToEditor"
        [(ngModel)]="paraphraseTemperature"
        [ngModelOptions]={standalone:true}
        (change)="updateParaphraseTemperature($event)"
        min="1"
        max="2"
        step="0.5"
        [value]="paraphraseTemperature"></mat-slider>
        <i class="HighText">High</i>
      </div>
    </div>

    <div class="ShowRevisionsSection">
      <label>
        Show revisions
      </label>
      <div class="toggleDiffOption">
        <label class="switch">
          <!-- <input #toggleDiffOptionCheckbox id="toggleDiffOptionCheckbox" type="checkbox" [checked]="toggleDiffOptionModel" (change)="onToggleDiffOption(toggleDiffOptionCheckbox.checked)"> -->
          <!--  [disabled]="(!errorDuringParaphrase.status && !paraphrasedSentences.length && !loader) || errorDuringParaphrase.status" -->
          <input id="toggleDiffOptionCheckbox" type="checkbox" [(ngModel)]="toggleDiffOptionModel" [ngModelOptions]={standalone:true} [checked]="toggleDiffOptionModel" (change)="onToggleDiffOption()">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

  </div>


  <div #paraphraserSection class="paraphraserBodySection">
    <!-- Paraphraser initial taxt -->
    <div class="slectTextParaphraser" *ngIf="!errorDuringParaphrase.status && !paraphrasedSentences.length && !loader && !isSelectionLimitReached && !isDocumentSplitInProgress">
      <div class="mainSliderSection">
        <video class="vid-video" id="paraphraserIntroVideo" loop muted>
          <source src="./assets/video/ParaphraserVideo.mp4#t=0.1" type="video/mp4">
            Your browser does not support the video element. Kindly update it to latest version.
        </video>
        <img class="posterImages" src="./assets/video/video_poster_img.png" alt="" *ngIf="isParaphraserIntroVideoPaused">
        <button (click)="toggleParaphraserIntroVideoPlayPause()">
          <svg *ngIf="isParaphraserIntroVideoPaused" class="playIconButton" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9499 36.6667C29.1546 36.6667 36.6165 29.2048 36.6165 20C36.6165 10.7953 29.1546 3.33337 19.9499 3.33337C10.7451 3.33337 3.2832 10.7953 3.2832 20C3.2832 29.2048 10.7451 36.6667 19.9499 36.6667Z" stroke="#d8b5ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.5664 20.3834V17.6C14.5664 14.1334 17.0164 12.7167 20.0164 14.45L22.4331 15.85L24.8497 17.25C27.8497 18.9834 27.8497 21.8167 24.8497 23.55L22.4331 24.95L20.0164 26.35C17.0164 28.0834 14.5664 26.6667 14.5664 23.2V20.3834Z" fill="#d8b5ff" stroke="#d8b5ff" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <svg *ngIf="!isParaphraserIntroVideoPaused" class="pauseIconButton" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9499 36.6667C29.1546 36.6667 36.6165 29.2048 36.6165 20C36.6165 10.7953 29.1546 3.33337 19.9499 3.33337C10.7451 3.33337 3.2832 10.7953 3.2832 20C3.2832 29.2048 10.7451 36.6667 19.9499 36.6667Z" stroke="#d8b5ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.8663 24.2166V15.7833C17.8663 14.9833 17.533 14.6666 16.683 14.6666H14.5163C13.6663 14.6666 13.333 14.9833 13.333 15.7833V24.2166C13.333 25.0166 13.6663 25.3333 14.5163 25.3333H16.6663C17.533 25.3333 17.8663 25.0166 17.8663 24.2166Z" fill="#d8b5ff" stroke="#d8b5ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.6671 24.2166V15.7833C26.6671 14.9833 26.3337 14.6666 25.4837 14.6666H23.3337C22.4837 14.6666 22.1504 14.9833 22.1504 15.7833V24.2166C22.1504 25.0166 22.4837 25.3333 23.3337 25.3333H25.4837C26.3337 25.3333 26.6671 25.0166 26.6671 24.2166Z" fill="#d8b5ff" stroke="#d8b5ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        
      </div>
      <!-- <h2>Select the text from editor to paraphrase</h2>
      <div class="" style="display: flex; flex-wrap: wrap;">
        <p>
          <i>It was about this experiments that were carried</i><i> out on some village.</i>
          <b>The villages (Lulbushki) was located in a mountaineous region and well built yak roamed the pastures.</b>
        </p>
      </div> -->
    </div>
   
    <!-- Paraphrasing Loader section -->
    <div class="ParaphrasingLoader" *ngIf="loader">
      <h3>Paraphrasing</h3>
      <ul>
        <li class="loading-shimmer"></li>
        <li style="opacity: 0.7;" class="loading-shimmer"></li>
        <li style="opacity: 0.7;" class="loading-shimmer"></li>
        <li style="opacity: 0.5;" class="loading-shimmer"></li>
        <li style="opacity: 0.3;" class="loading-shimmer"></li>
        <li style="opacity: 0.2;" class="loading-shimmer"></li>
        <li style="opacity: 0.1;" class="loading-shimmer"></li>
      </ul>
    </div>

    <!-- Limit Reached out sectin -->
    <div class="splitDocumentProgress" *ngIf="!errorDuringParaphrase.status && !paraphrasedSentences.length && !loader && isSelectionLimitReached && !isDocumentSplitInProgress">
      <div class="limitReachedImg">
        <!-- <img src="../../../../../assets/icons/splitDocumentProgress.png" alt=""> -->
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.7605 15.92L15.3605 4.4C14.5005 2.85 13.3105 2 12.0005 2C10.6905 2 9.50047 2.85 8.64047 4.4L2.24047 15.92C1.43047 17.39 1.34047 18.8 1.99047 19.91C2.64047 21.02 3.92047 21.63 5.60047 21.63H18.4005C20.0805 21.63 21.3605 21.02 22.0105 19.91C22.6605 18.8 22.5705 17.38 21.7605 15.92ZM11.2505 9C11.2505 8.59 11.5905 8.25 12.0005 8.25C12.4105 8.25 12.7505 8.59 12.7505 9V14C12.7505 14.41 12.4105 14.75 12.0005 14.75C11.5905 14.75 11.2505 14.41 11.2505 14V9ZM12.7105 17.71C12.6605 17.75 12.6105 17.79 12.5605 17.83C12.5005 17.87 12.4405 17.9 12.3805 17.92C12.3205 17.95 12.2605 17.97 12.1905 17.98C12.1305 17.99 12.0605 18 12.0005 18C11.9405 18 11.8705 17.99 11.8005 17.98C11.7405 17.97 11.6805 17.95 11.6205 17.92C11.5605 17.9 11.5005 17.87 11.4405 17.83C11.3905 17.79 11.3405 17.75 11.2905 17.71C11.1105 17.52 11.0005 17.26 11.0005 17C11.0005 16.74 11.1105 16.48 11.2905 16.29C11.3405 16.25 11.3905 16.21 11.4405 16.17C11.5005 16.13 11.5605 16.1 11.6205 16.08C11.6805 16.05 11.7405 16.03 11.8005 16.02C11.9305 15.99 12.0705 15.99 12.1905 16.02C12.2605 16.03 12.3205 16.05 12.3805 16.08C12.4405 16.1 12.5005 16.13 12.5605 16.17C12.6105 16.21 12.6605 16.25 12.7105 16.29C12.8905 16.48 13.0005 16.74 13.0005 17C13.0005 17.26 12.8905 17.52 12.7105 17.71Z" fill="#FFBD4A"/>
          </svg>
        </span>
        <p style="letter-spacing: -0.1px;text-align: center;padding: 0;">You can select upto 15 sentences at a time to paraphrase. Please select the text again.</p>
      </div>
    </div>

    <!-- document split is in progress -->
    <div class="splitDocumentProgress" *ngIf="!errorDuringParaphrase.status && !paraphrasedSentences.length && !loader && !isSelectionLimitReached && isDocumentSplitInProgress">
      <div class="limitReachedImg">
        <!-- <img src="../../../../../assets/icons/splitDocumentProgress.png" alt=""> -->
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.7605 15.92L15.3605 4.4C14.5005 2.85 13.3105 2 12.0005 2C10.6905 2 9.50047 2.85 8.64047 4.4L2.24047 15.92C1.43047 17.39 1.34047 18.8 1.99047 19.91C2.64047 21.02 3.92047 21.63 5.60047 21.63H18.4005C20.0805 21.63 21.3605 21.02 22.0105 19.91C22.6605 18.8 22.5705 17.38 21.7605 15.92ZM11.2505 9C11.2505 8.59 11.5905 8.25 12.0005 8.25C12.4105 8.25 12.7505 8.59 12.7505 9V14C12.7505 14.41 12.4105 14.75 12.0005 14.75C11.5905 14.75 11.2505 14.41 11.2505 14V9ZM12.7105 17.71C12.6605 17.75 12.6105 17.79 12.5605 17.83C12.5005 17.87 12.4405 17.9 12.3805 17.92C12.3205 17.95 12.2605 17.97 12.1905 17.98C12.1305 17.99 12.0605 18 12.0005 18C11.9405 18 11.8705 17.99 11.8005 17.98C11.7405 17.97 11.6805 17.95 11.6205 17.92C11.5605 17.9 11.5005 17.87 11.4405 17.83C11.3905 17.79 11.3405 17.75 11.2905 17.71C11.1105 17.52 11.0005 17.26 11.0005 17C11.0005 16.74 11.1105 16.48 11.2905 16.29C11.3405 16.25 11.3905 16.21 11.4405 16.17C11.5005 16.13 11.5605 16.1 11.6205 16.08C11.6805 16.05 11.7405 16.03 11.8005 16.02C11.9305 15.99 12.0705 15.99 12.1905 16.02C12.2605 16.03 12.3205 16.05 12.3805 16.08C12.4405 16.1 12.5005 16.13 12.5605 16.17C12.6105 16.21 12.6605 16.25 12.7105 16.29C12.8905 16.48 13.0005 16.74 13.0005 17C13.0005 17.26 12.8905 17.52 12.7105 17.71Z" fill="#FFBD4A"/>
          </svg>
        </span>
        <p>An unexpected error occurred. Please select the text again to paraphrase.</p>
      </div>
    </div>


    <!-- An error occurred, please try again -->
    <div class="errorOccurred" *ngIf="errorDuringParaphrase.status && !loader">
      <div class="errorOccurredBodySection" *ngIf="errorDuringParaphrase.error.status === 409; else serverError">
        <div class="reachedMonthlyText">
          <h3>You have reached monthly limit of 5000 words. Limit resets on {{errorDuringParaphrase.error.renewalDateDisplay | date:'dd/MMM/yyyy'}}</h3>
        </div>
        <div class="optionTobeAccess">
          <h4>Upgrade to get full access</h4>
          <ul>
            <li>
              <span class="upgradeToPremium"></span>
              Unlimited access to paraphraser
            </li>
            <li>
              <span class="upgradeToPremium"></span>
              Unlimited access to cloud editor
            </li>
            <li>
              <span class="upgradeToPremium"></span>
              Unlimited access to Word and Browser add-ins
            </li>
            <li>
              <span class="upgradeToPremium"></span>
              10 Free credits per month
            </li>
            <li>
              <span class="upgradeToPremium"></span>
              Discounted rates on new credit purchase
            </li>
          </ul>
          <button (click)="upgradePlanPopup()">
            <span class="UpgradeParaphraser"></span>
            Upgrade to use Paraphraser
          </button>
        </div>
      </div>

      <ng-template #serverError>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.0133 21.2241L20.48 5.86406C19.3333 3.7974 17.7466 2.66406 16 2.66406C14.2533 2.66406 12.6666 3.7974 11.52 5.86406L2.98664 21.2241C1.90664 23.1841 1.78664 25.0641 2.6533 26.5441C3.51997 28.0241 5.22664 28.8374 7.46664 28.8374H24.5333C26.7733 28.8374 28.48 28.0241 29.3466 26.5441C30.2133 25.0641 30.0933 23.1707 29.0133 21.2241ZM15 11.9974C15 11.4507 15.4533 10.9974 16 10.9974C16.5466 10.9974 17 11.4507 17 11.9974V18.6641C17 19.2107 16.5466 19.6641 16 19.6641C15.4533 19.6641 15 19.2107 15 18.6641V11.9974ZM16.9466 23.6107C16.88 23.6641 16.8133 23.7174 16.7466 23.7707C16.6666 23.8241 16.5866 23.8641 16.5066 23.8907C16.4266 23.9307 16.3466 23.9574 16.2533 23.9707C16.1733 23.9841 16.08 23.9974 16 23.9974C15.92 23.9974 15.8266 23.9841 15.7333 23.9707C15.6533 23.9574 15.5733 23.9307 15.4933 23.8907C15.4133 23.8641 15.3333 23.8241 15.2533 23.7707C15.1866 23.7174 15.12 23.6641 15.0533 23.6107C14.8133 23.3574 14.6666 23.0107 14.6666 22.6641C14.6666 22.3174 14.8133 21.9707 15.0533 21.7174C15.12 21.6641 15.1866 21.6107 15.2533 21.5574C15.3333 21.5041 15.4133 21.4641 15.4933 21.4374C15.5733 21.3974 15.6533 21.3707 15.7333 21.3574C15.9066 21.3174 16.0933 21.3174 16.2533 21.3574C16.3466 21.3707 16.4266 21.3974 16.5066 21.4374C16.5866 21.4641 16.6666 21.5041 16.7466 21.5574C16.8133 21.6107 16.88 21.6641 16.9466 21.7174C17.1866 21.9707 17.3333 22.3174 17.3333 22.6641C17.3333 23.0107 17.1866 23.3574 16.9466 23.6107Z" fill="#EF4444"/>
        </svg>
        <p>An error occurred, please try again.</p>
      </ng-template>
    </div>

    <div *ngIf="!errorDuringParaphrase.status && !loader && paraphrasedSentences.length">
      <div class="paraphrasingTextSection">
        <h4>Paraphrased text</h4>
        <span class="paraphrase-sentences" [id]="'paraphrase-sentence-' + i" *ngFor="let sentence of paraphrasedSentences; let i = index;" (click)="showParaphraseAlternatives(i, sentence)" [style.background-color]="sentence.selected ? '#E3EFF4' : ''">
          <span [innerHTML]="sentence.currentSelection.suggestion_diff" *ngIf="toggleDiffOptionModel; else nonDiff"></span>
          <ng-template #nonDiff>
            <span>{{ sentence.currentSelection.suggestion }}</span>
          </ng-template>
        </span>
      </div>


      <div class="insertButton" *ngIf="paraphrasedSentences.length">
        <button (click)="updateParaphrasedSentencesBackToEditor()" *ngIf="!isParaphrasedSentencesUpatedBackToEditor; else textInsertedToEditor" [disabled]="data.fromFloatingOption">Replace</button>
        <ng-template #textInsertedToEditor>
          <button class="insertedButton" title="Above text is already paraphrased. Select a text to paraphrase again.">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 18.3307C15.0833 18.3307 18.8333 14.5807 18.8333 9.9974C18.8333 5.41406 15.0833 1.66406 10.5 1.66406C5.91663 1.66406 2.16663 5.41406 2.16663 9.9974C2.16663 14.5807 5.91663 18.3307 10.5 18.3307Z" stroke="#9531C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.95837 9.99896L9.31671 12.3573L14.0417 7.64062" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Replaced
          </button>
        </ng-template>
      </div>
    </div>

    <div #popover id="popover">
      <div class="popoverContent">
        <span class="likeDislikeSection" (click)="popoverOptionSelected($event, 'like', paraphraserAlternativesOfCurrentSelection.response.original_sentence, paraphraserAlternativesOfCurrentSelection.currentSelection.suggestion, 'feedback_popover')">
          <span class="pp-icon-like"></span>
          <span class="pp-icon-like pp-icon-like-hover"></span>
        </span>
        <span class="likeDislikeSection" (click)="popoverOptionSelected($event, 'dislike', paraphraserAlternativesOfCurrentSelection.response.original_sentence, paraphraserAlternativesOfCurrentSelection.currentSelection.suggestion, 'feedback_popover')">
          <span class="pp-icon-dislike"></span>
          <span class="pp-icon-dislike pp-icon-dislike-hover"></span>
        </span>
      </div>
    </div>

    <div #alternativesPopover id="alternativesPopover">
      <div *ngIf="paraphraserAlternativesOfCurrentSelection">
        <div class="popoverWrapper">
          <div class="popoverHeader">
            <h3>Alternatives</h3>
            <span class="pp-icon-times" style="cursor: pointer;" (click)="closeAlternativesPopover();"></span>
          </div>
          <ul>
            <ng-container *ngFor="let paraphraseSentences of paraphraserAlternativesOfCurrentSelection.response.output_sentence; let i = index; let last = last;">
              <li class="alternativeSentences" *ngIf="paraphraserAlternativesOfCurrentSelection.currentSelectionIndex != i" (click)="updateCurrentSelection(i, paraphraseSentences)">
                <span [innerHTML]="paraphraseSentences.suggestion_diff" *ngIf="toggleDiffOptionModel; else nonDiff"></span>
                <ng-template #nonDiff>
                  <span>{{ paraphraseSentences.suggestion }}</span>
                </ng-template>
                <span class="likeAndDislikeButtons">
                  <span class="likeDislikeSection" (click)="popoverOptionSelected($event, 'like', paraphraserAlternativesOfCurrentSelection.response.original_sentence, paraphraseSentences.suggestion, 'alternative_popover')">
                    <span class="pp-icon-like"></span>
                    <span class="pp-icon-like pp-icon-like-hover"></span>
                  </span>
                  <span class="likeDislikeSection" (click)="popoverOptionSelected($event, 'dislike', paraphraserAlternativesOfCurrentSelection.response.original_sentence, paraphraseSentences.suggestion, 'alternative_popover')">
                    <span class="pp-icon-dislike"></span>
                    <span class="pp-icon-dislike pp-icon-dislike-hover"></span>
                  </span>
                </span>
              </li>
              <!-- <div *ngIf="paraphraserAlternativesOfCurrentSelection.currentSelectionIndex != i && !last" style="height: 1px; background: #DDE2E9;"></div> -->
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

  </div>

  <div class="paraphraserFooter">
    <p *ngIf="errorDuringParaphrase.status && errorDuringParaphrase.error.status === 409;">
      5000/5000 monthly words limit reached
    </p>
    <div class="paraphraserStyleGuid" *ngIf="!errorDuringParaphrase.status && paraphrasedSentences.length && !loader">
      <p style="color: #DC2626; text-decoration: line-through;">Deleted text</p>
      <p class="paraphraserRightText">Added text</p>
    </div>
  </div>
</div>
