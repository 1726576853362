import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";

function containAtLeastEightChars(password:string): boolean {
   return password.length >= 8;
}
   
   
function containAtLeastOneLowerCaseLetter(password:string): boolean {
   return RegExp(/^(?=.*?[a-z])/).test(password);
}
   
   
function containAtLeastOneUpperCaseLetter(password:string): boolean {
   return  RegExp(/^(?=.*?[A-Z])/).test(password);
}
   
   
function containAtLeastOneDigit(password:string): boolean {
   return RegExp(/^(?=.*?[0-9])/).test(password);
}
   
   
function containAtLeastOneSpecialChar(password:string): boolean {
   return RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/).test(password);
}
   
   
// export function calculatePasswordStrength(password:string) {
//   if(password == null){
//      return {"value":0, 
//      "at_least_eight_chars":false
//     ,"at_least_one_digit_char":false
//     ,"at_least_one_upper_case_char":false
//     ,"at_least_one_lower_case_char":false
//     ,"at_least_one_special_char":false}
//   }
//   const requirements: Array<boolean> = [];
//   const unit = 100 / 5;
//   let at_least_eight_chars = containAtLeastEightChars(password);
//   let at_least_one_digit_char = containAtLeastOneDigit(password);
//   let at_least_one_upper_case_char = containAtLeastOneUpperCaseLetter(password);
//   let at_least_one_lower_case_char = containAtLeastOneLowerCaseLetter(password);
//   let at_least_one_special_char= containAtLeastOneSpecialChar(password);


//   requirements.push(
//       at_least_eight_chars
//      ,at_least_one_digit_char
//      ,at_least_one_upper_case_char
//      ,at_least_one_lower_case_char
//      ,at_least_one_special_char)       
//   let strength = requirements.filter(v => v).length * unit;
//   return {"value":strength, 
//           "at_least_eight_chars":at_least_eight_chars
//          ,"at_least_one_digit_char":at_least_one_digit_char
//          ,"at_least_one_upper_case_char":at_least_one_upper_case_char
//          ,"at_least_one_lower_case_char":at_least_one_lower_case_char
//          ,"at_least_one_special_char":at_least_one_special_char}
// }


export class noWhiteSpaceValidator {
   static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
      if(control.value){
         if((control.value as string).indexOf(' ') >= 0){
            return {cannotContainSpace: true}
        }
        return null;
      } 
       
   }



   static mustMatch(controlName: string, matchingControlName: string) {
      return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
  
        
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
        } else {
          matchingControl.setErrors(null);
        }
        return null;
      };
    }
}
