<div class="renameFolderPopup newFileEditorPopup">
    <h4>File Settings</h4>
    <!-- <h6>Share few details for personalized suggestions</h6> -->
    <form [formGroup]="form">
        <mat-dialog-content>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="file_name">File Name</label>
                        <input type="text" formControlName="file_name" class="form-control" placeholder="Enter File Name"  style="width: 100%">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group" style="margin: 0;">
                        <label for="document_type">Document Type </label>
                        <div class="docTypeOptions">
                            <div class="form-group" [ngClass]="selectedDocumentType == 2 ? 'btn_selected' : ''" style="margin-right: 12px;">
                                <input type="button" formControlName="general_doc" id="general_doc" class="form-control"
                                value="General" (click)="setDocumentType($event)">
                            </div>
                            <div class="form-group" [ngClass]="selectedDocumentType == 3 ? 'btn_selected' : ''"  style="position: relative; margin-right: 12px;">
                                <input type="button" formControlName="academic_doc" id="academic_doc" class="form-control"
                                value="Academic" (click)="setDocumentType($event)">
                            </div>
                            <div class="form-group" [ngClass]="selectedDocumentType == 4 ? 'btn_selected' : ''" style="position: relative;">
                                <span class="legalNewTag">New</span>
                                <input type="button" formControlName="legal_doc" id="legal_doc" class="form-control"
                                value="Legal" (click)="setDocumentType($event)">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group" style="margin-top: 24px;">
                        <label for="style_guide">Style Guide</label>
                        <mat-select *ngIf="selectedDocumentType !== 4" class="form-control" formControlName="style_guide" [ngClass]="disable_options? 'styledGudeDisabled': '' " style="width: 100%"
                            (selectionChange)="selectStyleGuide($event)" [disabled]="disable_options">
                            <mat-option *ngFor="let x of styleGuideDispay" [value]="x">{{x.name}}</mat-option>
                        </mat-select>

                        <mat-select *ngIf="selectedDocumentType == 4" class="form-control" formControlName="style_guide" [ngClass]="disable_options? 'styledGudeDisabled': '' "  style="width: 100%"
                            (selectionChange)="selectStyleGuide($event)" [disabled]="disable_options">
                            <mat-option *ngFor="let x of legalStyleGuideList" [value]="x">{{x.name}}</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group" [ngClass]="selectedDocumentType === 4? 'selectLegalLanguage' : '' " style="margin: 0;align-items: inherit;padding-bottom: 32px;border-bottom: none;">
                        <label style="position: relative;top: 8px;" for="languange">Document Language</label>
                        <div class="langPreferenceOptions" *ngIf="selectedDocumentType !== 4">
                            <div class="lang-form-group" *ngFor="let languageCode of languageCodeOptions" [ngClass]="selectedLanguageCode.includes(languageCode.code) ? 'btn_selected' : ''">

                                <label class="lang-form-label" for="{{languageCode.value}}" [ngClass]="selectedLanguageCode.length === 1 && languageCode.isSelected ? 'disabled-option' : ''">
                                    <input type="checkbox" [disabled]="selectedLanguageCode.length === 1 && languageCode.isSelected" (click)="setLanguageOptions(languageCode.code)" class="checkbox"
                                    [checked]="languageCode.isSelected"
                                    id="{{languageCode.value}}">
                                    <span>{{languageCode.value}} <i *ngIf="languageCode.code !== 'en'" class="languageBetaTag">Beta</i></span>
                                </label>
                            </div>
                        </div>
                        <div class="langPreferenceOptions legalLanguageOption" style="width: 100%; gap: 12px; margin: 0;" *ngIf="selectedDocumentType === 4">
                            <div class="form-group" [ngClass]="selectedLanguage === 'US' ? 'btn_selected' : ''" style="flex: calc(50% - 6px); margin: 0; padding: 0; border: 0px;">
                                <input type="button" formControlName="us_lang" id="us_lang" class="form-control"
                                value="American English" (click)="setLanguagePreferenceOption('US')">
                            </div>
                            <div class="form-group" [ngClass]="selectedLanguage === 'UK' ? 'btn_selected' : ''" style="flex: calc(50% - 6px); margin: 0; padding: 0; border: 0px;">
                                <input type="button" formControlName="uk_lang" id="uk_lang" class="form-control"
                                value="British English" (click)="setLanguagePreferenceOption('UK')">
                            </div>
                        </div>
                    </div>
                </div>

                <!--<div class="manageSpacingBetweenCTA" [ngClass]="selectedDocumentType === 4? 'selectLegalLanguage' : '' "></div> -->

                <div class="col-md-6" style="display: none;">
                    <div class="form-group">
                        <label for="paper_type">Paper Type</label>
                        <mat-select class="form-control" formControlName="paper_type" [ngClass]="disable_options? 'styledGudeDisabled': '' "
                            (selectionChange)="selectPaperType($event)" [disabled]="disable_options">
                            <mat-option *ngFor="let x of paper_type" [value]="x">{{x.name}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>

        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="folderRenameFooter">
                <button class="cancelRenameButton" (click)="submit(0)">Skip for Now</button>
                <button class="CreateRenameButton" (click)="submit(1)" [disabled]="loader">
                    Save & Proceed
                    <i class="fa fa-spinner fa-spin" *ngIf="loader"></i>
                </button>
            </div>
        </mat-dialog-actions>
    </form>
</div>
