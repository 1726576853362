<div class="upgrade_plag_report_modal">
  <div>
    <img src="../../../../../assets/images/closeIcons.png" class="closeBtn" (click)="close()" />
  </div>

  <div *ngIf="requestedPlagiarismType==2 && totalCredits>=requiredCredits" class="modal_img">
    <img src="../../../../../assets/images/Plagiarism_confirm_advance.svg" width="100px" height="150px" class="report_img1" />
  
  </div>
  <div *ngIf="requestedPlagiarismType==3 && (pages_used <= pages_available || totalCredits>=requiredCredits)" class="modal_img">
    <img src="../../../../../assets/images/Plagiarism_confirm.svg" width="100px" height="150px" class="report_img1" />
  </div>
  <div *ngIf="requestedPlagiarismType==2 && requiredCredits>totalCredits" class="modal_img">
    <svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M78.75 81.375C78.75 100.222 63.4725 115.5 44.625 115.5C25.7775 115.5 10.5 100.222 10.5 81.375C10.5 62.5275 25.7775 47.25 44.625 47.25C45.5175 47.25 46.4624 47.3025 47.3549 47.355C63.9974 48.6675 77.3326 62.0025 78.6451 78.645C78.6976 79.5375 78.75 80.4825 78.75 81.375Z" fill="#C084FC"/>
      <path opacity="0.4" d="M115.5 44.625C115.5 63.4725 100.222 78.75 81.375 78.75C80.4825 78.75 79.5376 78.6975 78.6451 78.645C77.3326 62.0025 63.9974 48.6675 47.3549 47.355C47.3024 46.4625 47.25 45.5175 47.25 44.625C47.25 25.7775 62.5275 10.5 81.375 10.5C100.222 10.5 115.5 25.7775 115.5 44.625Z" fill="#C084FC"/>
      </svg>
  </div>
  <div *ngIf="requestedPlagiarismType==3 && pages_used > pages_available && requiredCredits>totalCredits" class="modal_img">
    <svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M78.75 81.375C78.75 100.222 63.4725 115.5 44.625 115.5C25.7775 115.5 10.5 100.222 10.5 81.375C10.5 62.5275 25.7775 47.25 44.625 47.25C45.5175 47.25 46.4624 47.3025 47.3549 47.355C63.9974 48.6675 77.3326 62.0025 78.6451 78.645C78.6976 79.5375 78.75 80.4825 78.75 81.375Z" fill="#C084FC"/>
      <path opacity="0.4" d="M115.5 44.625C115.5 63.4725 100.222 78.75 81.375 78.75C80.4825 78.75 79.5376 78.6975 78.6451 78.645C77.3326 62.0025 63.9974 48.6675 47.3549 47.355C47.3024 46.4625 47.25 45.5175 47.25 44.625C47.25 25.7775 62.5275 10.5 81.375 10.5C100.222 10.5 115.5 25.7775 115.5 44.625Z" fill="#C084FC"/>
      </svg>
  </div>
  <div class="upgrade_plag_report_modal_body">

    <div *ngIf="requestedPlagiarismType==2 && totalCredits>=requiredCredits">
      <p class="modal_title">Confirm Advanced Plagiarism Report generation</p>
    </div>
    <p class="credit_buy" style="font-weight: 400;font-size: 18px;" 
    *ngIf="requestedPlagiarismType==2 && totalCredits>=requiredCredits">
    {{requiredCredits}} credits will be used to generate the report.
  </p>


    <div *ngIf="requestedPlagiarismType==3 && (pages_used <= pages_available || totalCredits>=requiredCredits)">
      <p class="modal_title" *ngIf="requestedPlagiarismType==3">Confirm Standard Plagiarism Report generation</p>
    </div>
    <p class="credit_buy" style="font-weight: 400;font-size: 18px;" *ngIf="requestedPlagiarismType==3 && pages_used <= pages_available && creditsDetails.user_type === 'PREMIUM_PLUS'">{{pages_used}} pages will be used to generate the report.</p>
    <p class="credit_buy" style="font-weight: 400;font-size: 18px;" *ngIf="requestedPlagiarismType==3 && pages_used > pages_available && totalCredits>=requiredCredits">{{requiredCredits}} credits will be used to generate the report.</p>
    <p class="credit_buy" style="font-weight: 400;font-size: 18px;" *ngIf="requestedPlagiarismType==3 && pages_used > pages_available && requiredCredits>totalCredits">You don't have enough credits to generate this report. Buy at least {{requiredCredits - totalCredits}} more credits to proceed.</p>
    <p class="credit_buy" *ngIf="requestedPlagiarismType !==3 && requiredCredits>totalCredits">You don't have enough credits to generate this report. Buy at least {{requiredCredits - totalCredits}} more credits to proceed. </p>
  </div>

  <div class="modal_footer">
    <a class="buy_report_btn" *ngIf="requestedPlagiarismType==2 && totalCredits>=requiredCredits"
      (click)="generatePlagiarismReport()"> Generate Report</a>
    <a class="buy_report_btn" *ngIf="requestedPlagiarismType==2 && requiredCredits>totalCredits" (click)="addCredits()">
      Buy Credits to Proceed</a>

    <a class="buy_report_btn" *ngIf="requestedPlagiarismType==3 && (pages_used <= pages_available || totalCredits>=requiredCredits)"
      (click)="generatePlagiarismReport()"> Generate Report</a>
    <a class="buy_report_btn" *ngIf="requestedPlagiarismType==3 && pages_used > pages_available && requiredCredits>totalCredits" (click)="addCredits()">
      Buy Credits to Proceed</a>
  </div>

</div>