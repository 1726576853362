import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-editor-journal-finder-modal',
  templateUrl: './editor-journal-finder-modal.component.html',
  styleUrls: ['./editor-journal-finder-modal.component.scss']
})
export class EditorJournalFinderModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef : MatDialogRef<EditorJournalFinderModalComponent>,
    public amplitudeService: AmplitudeService,
  ) { }

  ngOnInit() {
  }

  submit(val){
    this.dialogRef.close(val);
  }
}
