import { Component, OnInit, AfterViewChecked, Inject, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
// import { Login } from 'src/app/_enums/login.enum';
// import { SignInSignOutService } from 'src/app/_services/networkcalls/sign-in-sign-out.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
// import { AuthService } from '../../_guards/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { Login } from 'src/app/_enums/login.enum';
import { SignInSignOutService } from 'src/app/_services/networkcalls/sign-in-sign-out.service';
import { AuthService } from 'src/app/_guards/auth.service';
import { PasswordValidation } from './password-validator';
import { HeaderComponent } from '../header/header.component';
import { SharedService } from 'src/app/_services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { PluginsService } from 'src/app/_services/plugins.service';
import { GetMetaService } from 'src/app/_services/get-meta.service';

@Component({
  selector: 'app-sign-in-sign-out',
  templateUrl: './sign-in-sign-out.component.html',
  styleUrls: ['./sign-in-sign-out.component.scss']
})
export class SignInSignOutComponent implements OnInit, AfterViewChecked {
  win = window['dataLayer']   || {};
  loginCredentials: Login;
  signinform: FormGroup;
  signupform: FormGroup;
  ForgetPwdform: FormGroup;
  plug: boolean;
  hide: boolean = true;
  passHide: boolean = true;
  enableSignInPanel: number = 0;
  tempVar: any;

  constructor(
    public fb: FormBuilder,
    public loginservice: SignInSignOutService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public auth: AuthService,
    public spinner: NgxSpinnerService,
    private router: Router,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private pluginService: PluginsService,
    public metaString : GetMetaService
  ) {
    this.signinform = fb.group({
      username: new FormControl('', [Validators.required
        , Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
      ]),
      password: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });
    this.ForgetPwdform = fb.group({
      email_id: new FormControl('', [Validators.required
        , Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
      ]),
    });


  }
  // convenience getter for easy access to form fields


  ngOnInit() {
    this.signupform = this.fb.group({
      first_name: new FormControl('', [Validators.required
        // , Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
      ]),
      last_name: new FormControl('', [Validators.required
        // , Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
      ]),
      email_id: new FormControl('', [Validators.required
        , Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
      ]),
      password: new FormControl('', [Validators.required, Validators.minLength(5)]),
      // confirmPassword: new FormControl('', Validators.required),
    }
      // , {
      //   validator: PasswordValidation.MatchPassword
      // }
    );
  }

  ngAfterViewChecked() {
  }

  login(): void {
    this.spinner.show();
    this.signinform.value.password = btoa(this.signinform.value.password)
    this.loginservice.postLoginToServer(this.signinform.value).subscribe(result => {

      const {token,refresh_token,token_type} = result
      const pluginPayload = {token,refresh_token,token_type}
      pluginPayload["email_id"] = btoa(this.signinform.value.username);
      this.pluginService.handleLogin(pluginPayload)

      this.storage.set(this.metaString.getMeta(), result);
      this.sharedService.isSignIn = this.auth.isAuthenticated()
      this.sharedService.signInpopUp.close();
      this.router.navigate(['/dashboard']);
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  enablePanel(flag: number) {
    this.sharedService.enableSignInPanel = flag;
  }

  signup() {
    this.spinner.show();
    this.signupform.value.password = btoa(this.signupform.value.password)
    console.debug(this.signupform.value)
    this.loginservice.postSignUpToServer(this.signupform.value).subscribe(result => { 
     
      const {token,refresh_token,token_type} = result
      const pluginPayload = {token,refresh_token,token_type}
      pluginPayload["email_id"] = btoa(this.signupform.value.email_id);
      this.pluginService.handleLogin(pluginPayload)
      // this.sharedService.enableSignInPanel = 0
      this.signupform.reset()
      this.toastr.success(result.message);
      // this.spinner.hide();
      this.storage.set(this.metaString.getMeta(), result);
      this.sharedService.isSignIn = this.auth.isAuthenticated()
      this.sharedService.signInpopUp.close();
      this.router.navigate(['/dashboard']);
     
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    });

  }

  enableSignInPanelCall(value) {
    if (this.sharedService.enableSignInPanel == value) {
      return true;
    } else {
      return false;
    }
  }

  forgetPassword() {
    this.spinner.show();
    this.loginservice.postForgotPasswordServer(this.ForgetPwdform.value).subscribe(result => {
      if (result.status) {
        this.sharedService.signInpopUp.close();
        this.toastr.success(result.message)
        this.router.navigate(['/dashboard']);
      }
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }
}
