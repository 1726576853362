import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'targetjournalFilter',
    pure: false
  })
  export class TargetJournalFilterPipe implements PipeTransform {
    transform(items: any, filter: any): any {
      let currentfilter = filter["Target Journal"].data;
      
      if (!items) return [];
      
      if (currentfilter.length == 0) return items;

      return items.filter(result => {return currentfilter.includes(result.journal_title)});
    }
  }