import { Component, OnInit, Input, Output,EventEmitter} from '@angular/core';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/_services/shared.service';


@Component({
  selector: 'app-editor-language-selection',
  templateUrl: './editor-language-selection.component.html',
  styleUrls: ['./editor-language-selection.component.scss']
})
export class EditorLanguageSelectionComponent implements OnInit {


   /**getting value from parent to child*/
   @Input('childToMaster') masterName: string;
   
   /**getting value of language codes as well */
   @Input('langCode') langCode: string;

   /**parent component to child component emit values*/
   @Output() childToParent = new EventEmitter<String>();
   trialMode = false
  

  constructor(public amplitudeService: AmplitudeService,
    public sharedService: SharedService) { 

    this.trialMode = environment.trialMode;
  }

  ngOnInit() {
  }

  onLanguageTypeSelect() {  
    this.childToParent.emit( this.masterName);
    this.amplitudeService.logAmplitude('en_locale_set',{
      'fileID': this.sharedService.currentFileId,
      'locale': this.masterName,
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }
}
