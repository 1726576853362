<ngx-spinner></ngx-spinner>
<div class="plagCheckPlansTable">
    <table class="table">
        <thead>
            <tr>
                <th></th>
                <th>Score Only
                    <div class="headerPoweredBy">
                        <span>Powered by</span>
                        <img *ngIf="creditsDetails?.user_type === 'BASIC'" src="../../../../assets/images/Turnitin_logo.svg" alt="" style="width: 44px;">
                        <img *ngIf="creditsDetails?.user_type !== 'BASIC'" src="../../../../assets/images/ithentication_img.svg" alt="" style="width: 70px;">
                        <!-- <img src="../../../../assets/images/ithentication_img.svg" alt="" style="width: 70px;"> -->
                    </div>
                </th>
                <th>
                    <div class="popularTextBox">
                        <h2>Popular</h2>
                    </div>
                    Standard Check
                    <div class="headerPoweredBy">
                        <span>Powered by</span>
                        <img src="../../../../assets/images/Turnitin_logo.svg" alt="" style="width: 44px;">
                    </div>
                </th>
                <th>
                    <div class="RecommendedText">
                        <h2>Highest Quality</h2>
                    </div>
                    Advanced Check
                    <div class="headerPoweredBy">
                        <span>Powered by</span>
                        <img src="../../../../assets/images/ithentication_img.svg" alt="" style="width: 70px;">
                    </div>
                </th>
            </tr>
        </thead>
        
        <tbody>

            <tr *ngFor="let plan of optionList">
                <td [innerHTML]="plan.name"></td>
                <td *ngIf="plan.score">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7071 6.30071C21.0976 6.69123 21.0976 7.3244 20.7071 7.71492L10.7071 17.7149C10.3166 18.1054 9.68342 18.1054 9.29289 17.7149L4.29289 12.7149C3.90237 12.3244 3.90237 11.6912 4.29289 11.3007C4.68342 10.9102 5.31658 10.9102 5.70711 11.3007L10 15.5936L19.2929 6.30071C19.6834 5.91018 20.3166 5.91018 20.7071 6.30071Z" fill="#1E2938"/>
                    </svg>
                </td>
                <td *ngIf="!plan.score">
                    <svg width="9" height="3" viewBox="0 0 9 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 0.639914V2.29545H0V0.639914H9Z" fill="#99A2B2"/>
                    </svg>
                </td>

                <td *ngIf="plan.standard">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7071 6.30071C21.0976 6.69123 21.0976 7.3244 20.7071 7.71492L10.7071 17.7149C10.3166 18.1054 9.68342 18.1054 9.29289 17.7149L4.29289 12.7149C3.90237 12.3244 3.90237 11.6912 4.29289 11.3007C4.68342 10.9102 5.31658 10.9102 5.70711 11.3007L10 15.5936L19.2929 6.30071C19.6834 5.91018 20.3166 5.91018 20.7071 6.30071Z" fill="#1E2938"/>
                    </svg>
                </td>
                <td *ngIf="!plan.standard">
                    <svg width="9" height="3" viewBox="0 0 9 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 0.639914V2.29545H0V0.639914H9Z" fill="#99A2B2"/>
                    </svg>
                </td>

                <td *ngIf="plan.advance">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7071 6.30071C21.0976 6.69123 21.0976 7.3244 20.7071 7.71492L10.7071 17.7149C10.3166 18.1054 9.68342 18.1054 9.29289 17.7149L4.29289 12.7149C3.90237 12.3244 3.90237 11.6912 4.29289 11.3007C4.68342 10.9102 5.31658 10.9102 5.70711 11.3007L10 15.5936L19.2929 6.30071C19.6834 5.91018 20.3166 5.91018 20.7071 6.30071Z" fill="#1E2938"/>
                    </svg>
                </td>
                <td *ngIf="!plan.advance">
                    <svg width="9" height="3" viewBox="0 0 9 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 0.639914V2.29545H0V0.639914H9Z" fill="#99A2B2"/>
                    </svg>
                </td>
            </tr>
            <!-- <tr>
                <td>Powered by</td>
                <td><img src="../../../../assets/images/ithentication_img.svg" alt=""></td>
                <td><img src="../../../../assets/images/Turnitin_logo.svg" alt=""></td>
                <td><img src="../../../../assets/images/ithentication_img.svg" alt=""></td>
            </tr> -->
            <tr>
                <td>Credits required</td>
                <td class="freeCheckedPerMonth">{{total_free_score}} free
                     <span *ngIf="total_free_score == 1" >check</span>
                     <span *ngIf="total_free_score == 2" >checks</span> 
                     per month
                     <br>
                     <span class="basic-word-count-limit" *ngIf="total_free_score == 1">
                        (Upto 500 words)
                     </span>
                </td>
                <td class="freeCheckedPerMonth underline-text">
                    {{ creditsDetails?.user_type !== 'PREMIUM_PLUS' ? getStandardPlagCheckCreditCount(cloudDriveFileDetails.word_count) : creditsDetails && creditsDetails?.plagiarism_free_page_count_scan >= getPageCount(cloudDriveFileDetails.word_count) ?  getPageCount(cloudDriveFileDetails.word_count) + getPageOrPagesTxt(cloudDriveFileDetails.word_count) : getStandardPlagCheckCreditCount(cloudDriveFileDetails.word_count) }}
                    <div class="pages-info-popup" *ngIf="creditsDetails?.plagiarism_free_page_count_scan >= getPageCount(cloudDriveFileDetails.word_count)">
                        <p class="pages-info-popup-heading">For Standard Plagiarism Check</p>
                        <p class="pages-info-popup-fixed-msg">1 Page will be used per 250 words</p>
                        <div class="pages-info-popup-credit-div">
                          <p class="pages-info-popup-credit-details" *ngIf="creditsDetails?.plagiarism_free_page_count_scan >= getPageCount(cloudDriveFileDetails.word_count)">Your file has approximately <span class="pf-info-popup-word-count-value">{{cloudDriveFileDetails.word_count | number}} words so maximum {{getPageCount(cloudDriveFileDetails.word_count)}} page<span *ngIf="getPageCount(cloudDriveFileDetails.word_count) > 1">s</span> will be used.</span></p>
                          <p class="pages-info-popup-credit-details low-credit" *ngIf="creditsDetails?.plagiarism_free_page_count_scan < getPageCount(cloudDriveFileDetails.word_count)">You need {{getPageCount(cloudDriveFileDetails.word_count)}} Pages. Since you are low on Pages, {{getStandardPlagCheckCreditCount(cloudDriveFileDetails.word_count)}} will be used.</p>
                        </div>
                    </div>

                    <div class="pages-info-popup" *ngIf="creditsDetails?.plagiarism_free_page_count_scan < getPageCount(cloudDriveFileDetails.word_count)">
                        <p class="pages-info-popup-heading">For Standard Plagiarism Check</p>
                        <p class="pages-info-popup-fixed-msg">12 Credits are used per 25,000 words</p>
                        <div class="pages-info-popup-credit-div">
                            <p class="pages-info-popup-credit-details">Your file has <span class="pf-info-popup-word-count-value">{{cloudDriveFileDetails.word_count | number }} words, therefore {{getStandardPlagCheckCreditCount(cloudDriveFileDetails.word_count)}} will be used.</span></p>
                        </div>
                    </div>
                </td>
                <td class="freeCheckedPerMonth underline-text">{{getAdvanceCreditCount(cloudDriveFileDetails.word_count)}}
                    <div class="pages-info-popup">
                        <p class="pages-info-popup-heading">For Advanced Plagiarism Check</p>
                        <p class="pages-info-popup-fixed-msg">18 Credits are used per 25,000 words</p>
                        <div class="pages-info-popup-credit-div">
                            <p class="pages-info-popup-credit-details">Your file has <span class="pf-info-popup-word-count-value">{{cloudDriveFileDetails.word_count | number }} words, therefore {{getAdvanceCreditCount(cloudDriveFileDetails.word_count)}} will be used.</span></p>
                        </div>
                    </div>

                </td>
            </tr>
            <tr>
                <td></td>
                <td>
                    <div *ngIf="!plagiarism_free_score" class="plagCheckOptionButton" (click)="generateReport(1)">
                        <span class="upload-file-btn button">Generate</span>
                    </div>
                    <div *ngIf="plagiarism_free_score" style="padding-top: 10px">
                        <span class="">Free check exhausted. Resets on {{free_score_reset_date | date:'d MMM'}}</span>
                    </div>
                    <div *ngIf="remainig_free_score != 0" style="padding-top: 10px;">
                        <i class="">{{remainig_free_score}} free 
                            <span *ngIf="remainig_free_score == 1" >check</span>
                            <span *ngIf="remainig_free_score == 2" >checks</span> left. Resets on {{free_score_reset_date |  date:'d MMM'}}
                        </i>
                    </div>
                    <div class="premium-plus-user-scans-text" *ngIf="creditsDetails?.user_type === 'PREMIUM_PLUS'" style="padding-top: 10px;opacity: 0;">
                        <i *ngIf="creditsDetails?.plagiarism_free_page_count_scan >= getPageCount(cloudDriveFileDetails.word_count)">
                            Included in your plan
                        </i>
                        <i *ngIf="creditsDetails?.plagiarism_free_page_count_scan < getPageCount(cloudDriveFileDetails.word_count)">
                            Insufficient pages, use credits
                        </i>
                    </div>
                </td>
                <td>
                    <div class="plagCheckOptionButton" (click)="generateReport(3)">
                        <span class="upload-file-btn button">Generate</span>
                    </div>
                    <div class="premium-plus-user-scans-text" *ngIf="creditsDetails?.user_type === 'PREMIUM_PLUS'" style="padding-top: 10px;">
                        <i *ngIf="creditsDetails?.plagiarism_free_page_count_scan >= getPageCount(cloudDriveFileDetails.word_count)">
                            Included in your plan
                        </i>
                        <i *ngIf="creditsDetails?.plagiarism_free_page_count_scan < getPageCount(cloudDriveFileDetails.word_count)">
                            Insufficient pages, use credits
                        </i>
                    </div>
                    <div *ngIf="remainig_free_score != 0" style="padding-top: 10px;opacity: 0;">
                        <i class="">{{remainig_free_score}} free 
                            <span *ngIf="remainig_free_score == 1" >check</span>
                            <span *ngIf="remainig_free_score == 2" >checks</span> left. Resets on {{free_score_reset_date |  date:'d MMM'}}
                        </i>
                    </div>
                </td>
                <td>
                    <div class="plagCheckOptionButton" (click)="generateReport(2)">
                        <span class="upload-file-btn button">Generate</span>
                    </div>
                    <div *ngIf="remainig_free_score != 0" style="padding-top: 10px;opacity: 0;">
                        <i class="">{{remainig_free_score}} free 
                            <span *ngIf="remainig_free_score == 1" >check</span>
                            <span *ngIf="remainig_free_score == 2" >checks</span> left. Resets on {{free_score_reset_date |  date:'d MMM'}}
                        </i>
                    </div>
                     <div class="premium-plus-user-scans-text" *ngIf="creditsDetails?.user_type === 'PREMIUM_PLUS'" style="padding-top: 10px;opacity: 0;">
                        <i *ngIf="creditsDetails?.plagiarism_free_page_count_scan >= getPageCount(cloudDriveFileDetails.word_count)">
                            Included in your plan
                        </i>
                        <i *ngIf="creditsDetails?.plagiarism_free_page_count_scan < getPageCount(cloudDriveFileDetails.word_count)">
                            Insufficient pages, use credits
                        </i>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>