<div class="powerModeHoverMode" >
    <div class="powerModeHoverModeHeader">
      <h2>Know More About Editing Modes</h2>
      <button (click)="closeModal(true)">
        <img src="../../../../../assets/images/close-black.svg" alt="">
    </button>
    </div>
    <div class="knowMoreAboutPowerMode">
      <div class="powerModeHoverModeBody">
        <h3>
          <svg width="9.62" height="15.63" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
          </svg>  
          Power Mode
        </h3>
        <p><strong>About:</strong> Power Mode corrects your language as well as enhances it with advanced AI suggestions. It also paraphrases the text lightly in order to bring clarity and fluency to your writing.</p>
        <p><strong>When to use:</strong> Use Power Mode when you need both corrections and enhancements for your writing, especially if you aim to make your writing clearer, more fluent, and impactful.</p>
        <h3 style="margin-top: 24px;">
          <svg width="14" height="14.4" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Lite Mode
        </h3>
        <p><strong>About:</strong> Lite Mode focuses on correcting basic grammar, spelling, and punctuation mistakes. It helps keep your writing accurate.</p>
        <p><strong>When to use:</strong> Use Lite Mode when basic grammar corrections are sufficient for your needs or you just want to proofread the content</p>
      </div>
      <div class="powerModeHoverSliderSectoin">
        <h4>
          <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="11" height="1.375" rx="0.6875" fill="#444444"/>
            <rect y="3.66675" width="11" height="1.375" rx="0.6875" fill="#444444"/>
            <rect y="7.33325" width="11" height="1.375" rx="0.6875" fill="#444444"/>
            <rect y="11" width="5.5" height="1.375" rx="0.6875" fill="#444444"/>
            <rect x="7.3335" y="11" width="1.375" height="1.375" rx="0.6875" fill="#444444"/>
          </svg>
          View Examples
        </h4>
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#carouselExampleControls" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleControls" data-slide-to="1"></li>
            <li data-target="#carouselExampleControls" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <!-- <h4>View Examples</h4> -->
              <!-- <h5>“In recent years, air pollutions more and more serious with the development of technology and industry.”</h5> -->
              <div class="powerModeShowReivision">
                <label for="">Show Revisions</label>
                <input type="checkbox" checked (click)="showRevivationActive_Slider_two()">
              </div>
              <div class="powerModeSlides">
                <div class="powerModeCard" [ngClass]="{'showRevivisionTwoPowerMode': showRevivsionTwo}">
                  <h2>
                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
                    </svg>
                    Power Mode
                  </h2>
                  <p style="margin-bottom: 15px;">
                    In recent years, air
                     <span class="powerModeNormalWord">pollutions</span>
                     <span class="powerModeEditedWord" style="margin-right: 3px;">pollution</span>
                     <span class="powerModeNormalWord">are</span>
                     <span class="powerModeEditedWord" style="margin-right: 3px;">has become</span>
                     <span class="powerModeNormalWord">more and more</span>
                     <span class="powerModeEditedWord">increasingly</span>
                      serious
                      <span class="powerModeNormalWord">with</span>
                     <span class="powerModeEditedWord" style="margin-right: 3px;">because of</span>
                     <span class="powerModeNormalWord">the development</span>
                     <span class="powerModeEditedWord">advances in</span>
                      technology and industry.
                  </p>
                  <p class="powerModeNameOfSentence">Corrections and enhancements</p>
                </div>
                <div class="liteModeCard" [ngClass]="{'showRevivisionTwoLiteMode': showRevivsionTwo}">
                  <h2>
                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Lite Mode
                  </h2>
                  <p style="margin-bottom: 53px;">
                    In recent years, air
                     <span class="powerModeNormalWord">pollutions</span>
                     <span class="powerModeEditedWord">pollution</span>
                     <span class="powerModeNormalWord">are</span>
                     <span class="powerModeEditedWord" style="margin-right: 0px;">has become</span> more and more serious with the development of technology and industry.
                  </p>
                  <p class="powerModeNameOfSentence">Only corrections</p>
                </div>
              </div>
              <div class="powerModeEditingMode">
                <p> * Editing Modes are available only for English language.</p>
              </div>
            </div>
            <div class="carousel-item">
              <!-- <h5>“Since 2006, corporate profits broke records, while unemployment rate in America has gone up significantly.”</h5> -->
              <div class="powerModeShowReivision">
                <label for="">Show Revisions</label>
                <input type="checkbox" checked (click)="showRevivationActive_Slider_one()">
              </div>
              <div class="powerModeSlides">
                <div class="powerModeCard" [ngClass]="{'showRevivisionOnePowerMode': showRevivsionOne}">
                  <h2>
                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
                    </svg>
                    Power Mode
                  </h2>
                  <p style="margin-bottom: 50px;">Since 2006, corporate profits
                    <span class="powerModeNormalWord">broke</span>
                    <span class="powerModeEditedWord">have broken</span> records,
                    <span class="powerModeNormalWord">while</span>
                    <span class="powerModeEditedWord">and</span>  unemployment
                    <span class="powerModeNormalWord" style="margin-right: 0;">rate</span> in
                    <span class="powerModeNormalWord">America</span>
                    <span class="powerModeEditedWord">the US</span> has
                    <span class="powerModeNormalWord">gone up</span>
                    <span class="powerModeEditedWord">increased</span> significantly.
                  </p>
                  <p class="powerModeNameOfSentence">Corrections and enhancements</p>
                </div>
                <div class="liteModeCard" [ngClass]="{'showRevivisionOneLiteMode': showRevivsionOne}">
                  <h2>
                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Lite Mode
                  </h2>
                  <p style="margin-bottom: 49px;">Since 2006, corporate profits
                    <span class="powerModeNormalWord">broke</span>
                    <span class="powerModeEditedWord" style="margin-right: 0;">have broken</span> records, while
                    <span class="powerModeEditedWord" style="margin-right: 0;">the</span> unemployment rate in America has
                    <span class="powerModeNormalWord">gone up</span>
                    <span class="powerModeEditedWord" style="margin-right: 0;">increased</span> significantly.
                  </p>
                  <p class="powerModeNameOfSentence">Only corrections</p>
                </div>
              </div>
              <div class="powerModeEditingMode">
                <p> * Editing Modes are available only for English language.</p>
              </div>
            </div>
            <div class="carousel-item">
                <!-- <h4>View Examples</h4> -->
                <!-- <h5>“I wants to publish my paper in IEEE.”</h5> -->
                <div class="powerModeShowReivision">
                  <label for="">Show Revisions</label>
                  <input type="checkbox" checked (click)="showRevivationActive_Slider_three()">
                </div>
                <div class="powerModeSlides">
                  <div class="powerModeCard" [ngClass]="{'showRevivisionThreePowerMode': showRevivsionThree}">
                    <h2>
                      <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
                      </svg>
                      Power Mode
                    </h2>
                    <p style="margin-bottom: 52px;">
                      Subadult and juvenile <span class="powerModeEditedWord" style="margin-right: 3px;">elephants</span> <span class="powerModeNormalWord">were found to consume</span> <span class="powerModeEditedWord">consumed</span> more plant species <span class="powerModeNormalWord">then</span> <span class="powerModeEditedWord">than</span> adult elephants.
                    </p>
                    <p class="powerModeNameOfSentence">Corrections and enhancements</p>
                  </div>
                  <div class="liteModeCard" [ngClass]="{'showRevivisionThreeLiteMode': showRevivsionThree}">
                    <h2>
                      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Lite Mode
                    </h2>
                    <p style="margin-bottom: 70px;">
                      Subadult and juvenile <span class="powerModeEditedWord" style="margin-right: 0;">elephants</span> were found to consume more plant species  <span class="powerModeNormalWord">then</span> <span style="margin-right: 0;" class="powerModeEditedWord">than</span> adult elephants.
                    </p>
                    <p class="powerModeNameOfSentence">Only corrections</p>
                  </div>
                </div>
                <div class="powerModeEditingMode">
                  <p> * Editing Modes are available only for English language.</p>
                </div>
              </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>

      </div>
    </div>
  </div>