<div class="container popup reference_check_popup">
  <div class="row">
    <div class="col-sm-12">
      <div class="popup_heading">
        <h2>References</h2>
        <button class="cancelbuttons" mat-button mat-dialog-close>
          <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
      </div>
      <div class="reference_check_popup_body">
        <ol start="1">
          <li *ngFor="let individualObject of individualData; let i= index">
            {{individualObject.content}}<span class="Oldreference_checkes" *ngIf="individualObject.obsolete">Old reference</span>
          </li>
        </ol>
      </div>

    </div>
  </div>
</div>
