import { Component, OnInit } from '@angular/core';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { ClinicalTrial } from 'src/app/_interfaces/author-one/clinical-trial-check';

@Component({
  selector: 'app-clinical-trial-check',
  templateUrl: './clinical-trial-check.component.html',
  styleUrls: ['./clinical-trial-check.component.scss']
})
export class ClinicalTrialCheckComponent implements OnInit {

  clinicalCheck : ClinicalTrial

  constructor(private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService) {
    this.getReportData();
     }

  ngOnInit() {
  }

  getReportData(){
    this.subjectiveBehaviorSharedService.getA1AllCheckReportData.subscribe(resp=>{
      this.clinicalCheck = resp['technical_compliance']['clinical_trial_info']
      if(typeof (this.clinicalCheck.required_to_show) == "undefined"){
        this.clinicalCheck.required_to_show = false
      }
    });
  }

  redirectOnUrl(url){
    window.open(url, '_blank');
  }


}
