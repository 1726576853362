<div class="PlagiarismCompareWrapper">
    <button class="popupClosedButton" mat-button mat-dialog-close>
        <img src="../../../../assets/images/closeIcons.png" alt="">
    </button>
    <table class="table">
        <tr>
            <th class="tableStickyHeader"></th>
            <th class="tableStickyHeader">
                <h3>DrillBit</h3>
                <span class="freeText">Free</span>
                <p>Select</p>
                <a href="" target="_blank">Sample Report</a>
            </th>
            <th class="tableStickyHeader">
                <h3>Similarity</h3>
                <span><i>9</i> Credits</span>
                <p class="Selected">Selected</p>
                <a href="" target="_blank">Sample Report</a>
            </th>            
            <th class="tableStickyHeader">
                <h3>iThenticate</h3>
                <span><i>12</i> Credits</span>
                <p>Select</p>
                <a href="" target="_blank">Sample Report</a>
            </th>
        </tr>
        <tbody>
            <tr>
                <td colspan="4"><h2>Databases</h2></td>
            </tr>
            <tr>
                <td><h4>Web Pages</h4></td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
            </tr>
            <tr>
                <td><h4>Open Access</h4></td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
            </tr>
            <tr style="border-bottom: 1px solid #F5EEEE;">
                <td style="padding-bottom: 6px;"><h4>Paywalled</h4></td>
                <td style="padding-bottom: 6px;">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td style="padding-bottom: 6px;">
                <svg width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0.833313H10V2.49998H0V0.833313Z" fill="#9B8383"/>
                </svg>
                </td>
                <td style="padding-bottom: 6px;">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
            </tr>
            <tr>
                <td colspan="4" ><h2>Report Format</h2></td>
            </tr>
            <tr>
                <td><h4>PDF Download</h4></td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
            </tr>
            <tr style="border-bottom: 1px solid #F5EEEE;">
                <td style="padding-bottom: 6px;"><h4>Interactive Viewer</h4></td>
                <td style="padding-bottom: 6px;">
                <svg width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0.833313H10V2.49998H0V0.833313Z" fill="#9B8383"/>
                </svg>
                </td>
                <td style="padding-bottom: 6px;">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td style="padding-bottom: 6px;">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
            </tr>
            <tr>
                <td colspan="4"><h2>Match Controls</h2></td>
            </tr>
            <tr>
                <td><h4>Ignore Sources</h4></td>
                <td>
<svg width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0.833313H10V2.49998H0V0.833313Z" fill="#9B8383"/>
                </svg>

                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
            </tr>
            <tr>
                <td><h4>Exclude Sections</h4></td>
                <td>
<svg width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0.833313H10V2.49998H0V0.833313Z" fill="#9B8383"/>
                </svg>

                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
            </tr>
            <tr style="border-bottom: 1px solid #F5EEEE;">
                <td style="padding-bottom: 6px;"><h4>Exclude Matches on Length</h4></td>
                <td style="padding-bottom: 6px;">
                <svg width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0.833313H10V2.49998H0V0.833313Z" fill="#9B8383"/>
                </svg>
                </td>
                <td style="padding-bottom: 6px;">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td style="padding-bottom: 6px;">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
            </tr>
            <tr>
                <td><h2>Report Filters</h2></td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
                <td>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.49992 9.50002L1.99992 6.00002L0.833252 7.16669L5.49992 11.8334L15.4999 1.83335L14.3333 0.666687L5.49992 9.50002Z" fill="#2FC50A"/>
                    </svg>
                </td>
            </tr>
            <tr>
                <td><h2>Powered By</h2></td>
                <td class="poweredByText">DrillBit</td>
                <td class="poweredByText">Similarity</td>
                <td class="poweredByText">iThenticate</td>
            </tr>
        </tbody>
    </table>
</div>