<div class="renameFolderPopup quality-modal">
        <h1>Advance Plagiarism check</h1>
        <mat-dialog-content>
            <div>The Advanced check report will include -</div>
            <div>Report powered by </div>
            <div>Checks against the paid sources.</div>
            <div>Largest and most updated database of paid and open source articles</div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="folderRenameFooter">
                <button class="CreateRenameButton" (click)="submit()">Generate report for 18 credits</button>
            </div>
        </mat-dialog-actions>
</div>