import { Injectable, Inject } from '@angular/core';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';
import { SharedService } from '../_services/shared.service';
import { AmplitudeService } from 'src/app/_services/amplitude.service';


@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  PING = "JTdCJTIydHlwZSUyMiUzQSUyMCUyMnBpbmclMjIlN0Q=";
  socket;
  token: string;
  documentTypeList = ["Select", "General", "Academic", "Legal"];
  instructionModule: {instruction: string,module: string} = {instruction: "",module: ""}
  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public sharedService: SharedService,
    public amplitudeService: AmplitudeService) {
  }

  connectToSocket(documentId, is_premium=false) {
    const urlProvider = () => {
      this.token = this.sharedService.getAccessToken();
      var url = `${environment.for_new_socket}/ws/v1/trinka/${this.token}*${documentId}`
      // return environment.for_socket + '/ws/v1/trinka/' + this.token + "*" + documentId
      return url;
    };
    this.socket = new ReconnectingWebSocket(urlProvider(), [], {
      connectionTimeout: 10000
    })
  }

  public close() {
    this.socket.close();
  }

  public ping() {
    this.socket.send(this.PING);
  }

  public check(text: string, language: string, style_guide = "NONE", fragment: boolean, complexity: boolean, run_on: boolean, request_id, langCode: string = "en", document_type: any, instructionModule = {instruction: "", module: ""}) {
    let payload = this.encrypt_message(JSON.stringify({
      "type": "check",
      "text": text,
      "language": language,
      "request_id":request_id,
      "style_guide": style_guide,
      "source": "CLOUD",
      "inclusive_lang": true,
      "langCode": langCode,
      "doc_type": document_type,
      "model": instructionModule.module,
      "instruction": instructionModule.instruction
    }))
    this.socket.send(payload);
  }

  public split(text: string, langCode: string = "en", document_type: any, style_guide: string = "NONE") {
    let payload = this.encrypt_message(JSON.stringify({
      "type": "split",
      "text": text,
      "request_id": "",
      "source": "CLOUD",
      "langCode": langCode,
      "doc_type": document_type,
      "style_guide": style_guide
    }))
    this.socket.send(payload);
  }

  public mute(alert, suggestion, language, langCode = 'en', selectedLanguages = '', instructionModule = {instruction: "", module: ""}) {
    let payload = { ...suggestion }
    payload.type = "mute"
    payload.correction = suggestion.suggestion
    payload.text = alert.text
    payload.sentence = alert.sentence
    payload.language = language
    payload.langCode = langCode
    payload = this.encrypt_message(JSON.stringify(payload))
    this.socket.send(payload);
    this.amplitudeService.logAmplitude('add_to_dictionary',{
      'fileID': this.sharedService.currentFileId,
      'word': alert.text,
      'language': selectedLanguages,
      'model': instructionModule.module,
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }

  public acceptnoncta(alert, suggestion, language, langCode = 'en', selectedLanguages = '') {
    let payload = { ...suggestion }
    payload.type = "accept_noncta"
    payload.correction = suggestion.suggestion
    payload.text = alert.text
    payload.sentence = alert.sentence
    payload.language = language
    payload.langCode = langCode
    payload = this.encrypt_message(JSON.stringify(payload))
    this.socket.send(payload);

    this.amplitudeService.logAmplitude('grammar_accept',{
      'fileID': this.sharedService.currentFileId,
      'category': alert.suggestions[0].error_category,
      'language': selectedLanguages,
      'lang_code': langCode,
      'strictness': alert.strictness,
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }

  public accept(alert, suggestion, langCode = 'en', selectedLanguages = '', document_type: any, instructionModule= {instruction: "", module: ""}) {
    if(suggestion["spell_status"] == 'conf'){
      suggestion["type"] = 2;
    }
    let payload = { ...suggestion }
    payload.type = "accept"
    payload.correction = suggestion.suggestion
    payload.text = alert.text
    payload.sentence = alert.sentence
    payload.langCode = langCode
    payload = this.encrypt_message(JSON.stringify(payload))
    this.socket.send(payload);

    this.amplitudeService.logAmplitude('grammar_accept',{
      'fileID': this.sharedService.currentFileId,
      'category': alert.suggestions[0].error_category,
      'language': selectedLanguages,
      'lang_code': langCode,
      'strictness': alert.strictness,
      "doc_type": this.documentTypeList[document_type - 1],
      'model': instructionModule.module,
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }

  public ignore(alert, suggestion,  langCode = 'en', selectedLanguages = '', document_type: any) {
    let payload = { ...suggestion }
    payload.error_type = payload.type
    payload.type = "ignore"
    payload.correction = suggestion.suggestion
    payload.text = alert.text
    payload.sentence = alert.sentence
    payload.langCode = langCode
    payload = this.encrypt_message(JSON.stringify(payload))
    this.socket.send(payload);
  }

  public reject(alert, suggestion, langCode = 'en', selectedLanguages = '', document_type: any, instructionModule = {instruction: "", module: ""}) {
    if(suggestion["spell_status"] == 'conf'){
      suggestion["type"] = 2;
    }
    let payload = { ...suggestion }
    payload.error_type = payload.type
    payload.type = "reject"
    payload.correction = suggestion.suggestion
    payload.text = alert.text
    payload.sentence = alert.sentence
    payload.langCode = langCode
    payload = this.encrypt_message(JSON.stringify(payload))
    this.socket.send(payload);

    this.amplitudeService.logAmplitude('grammar_reject',{
      'fileID': this.sharedService.currentFileId,
      'category': alert.suggestions[0].error_category,
      'error_text': alert.text,
      'suggestion': alert.suggestions[0].suggestion,
      'language': selectedLanguages,
      'lang_code': langCode,
      'strictness': alert.strictness,
      "doc_type": this.documentTypeList[document_type - 1],
      'model': instructionModule.module
    });
  }

  public decrypt_message(message) {
    return decodeURIComponent(atob(message))
  }
  public encrypt_message(message) {
    return btoa(encodeURIComponent(message))
  }

  public ignoreCC(alert) {
    let payload = { ...alert.occurrence }
    payload.type = "ignore"
    payload.variation = alert.variation
    // payload.text = alert.text
    // payload.error_type = payload.type
    //payload.sentence = alert.sentence
    payload = this.encrypt_message(JSON.stringify(payload))
    this.socket.send(payload);
  }
  public rejectCC(alert) {
    let payload = { ...alert.occurrence }
    payload.error_type = payload.type
    payload.type = "reject"
    // payload.text = alert.text
    // payload.correction = alert.occurrence
    // payload.sentence = alert.sentence
    payload = this.encrypt_message(JSON.stringify(payload))
    this.socket.send(payload);
    this.amplitudeService.logAmplitude('consistency_reject',{
      'fileID': this.sharedService.currentFileId,
      'category': alert.suggestions[0].error_category
    });
  }

  public cache(response: any) {
    response['type']="cache";
    let payload = this.encrypt_message(JSON.stringify(response))
    this.socket.send(payload);
  }

  public dict_action(id,timestamp) {
    var response ={};
    response['id'] = id;
    response['timestamp'] = timestamp;
    response['type']="dict_action";
    let payload = this.encrypt_message(JSON.stringify(response))
    this.socket.send(payload);
  }

  public sendCacheToSocket(text: string, language: string, style_guide = "NONE", fragment: boolean, complexity: boolean, run_on: boolean, request_id) {
    let payload = this.encrypt_message(JSON.stringify({
      "type": "cacheData",
      "text": text,
      "language": language,
      "request_id": request_id,
      "style_guide": style_guide,
      "source": "CLOUD"
    }))
    this.socket.send(payload);
  }
  socketURLForSDK(documentId) {
    this.token = this.sharedService.getAccessToken();
    let url = `${environment.for_new_socket}/ws/v1/trinka/${this.token}*${documentId}`
    return url;
  }
}
