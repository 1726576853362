import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByWord'
})
export class FilterByWordPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return it.word.toLowerCase().includes(searchText);
    });
  }
}
