<div class="editor_outer_section">
    <div class="online-editor-right">
        <div class="editor-here">
            <div class="outer_login_wrap_section">
                <div class="login_left_side">
                    <div class="left_login_overlay"></div>
                    <div class="login_content">
                        <div class="top_logo">
                            <a href="https://trinka.ai" target="_blank">
                                <img src="/assets/images/trinka-logo-login.png" alt="">
                            </a>
                        </div>
                        <div class="login_text_contents">
                            <h2>Welcome to Trinka !!</h2>
                            <p style="max-width: 450px;">As you are starting your journey with Trinka, we welcome you to a splendid writing experience ahead.</p>
                        </div>
                        <div class="login_footer">
                            <!-- <ul>
                                <li><a href="https://www.trinka.ai/privacypolicy" target="_blank">Privacy Policy</a></li>
                                <li><a href="https://www.trinka.ai/termsofservices" target="_blank">Terms of Services</a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
                <div class="login_right_side browserInstall_page">
                    <div class="login_body_section">
                        <div class="login_changeLogs installPage_bellIcon">
                            
                        </div>
                        <div class="email-success-text">
                            <div class="loading-state-outer" *ngIf="loadingStateComplete && !showErrorState">
                                <p class="loading-state">
                                    <span class="loader">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="48px" height="48px"
                                            viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                            <circle cx="50" cy="50" r="28" stroke="#d9d9d9" stroke-width="8"
                                                fill="none"></circle>
                                            <circle cx="50" cy="50" r="28" stroke="#7e22ce" stroke-width="8"
                                                stroke-linecap="round" fill="none">
                                                <animateTransform attributeName="transform" type="rotate"
                                                    repeatCount="indefinite" dur="1.3888888888888888s"
                                                    values="0 50 50;180 50 50;720 50 50" keyTimes="0;0.5;1">
                                                </animateTransform>
                                                <animate attributeName="stroke-dasharray" repeatCount="indefinite"
                                                    dur="1.3888888888888888s"
                                                    values="17.59291886010284 158.33626974092556;87.96459430051421 87.96459430051421;17.59291886010284 158.33626974092556"
                                                    keyTimes="0;0.5;1"></animate>
                                            </circle>
                                        </svg>
                                    </span>
                                </p>
                                <p>Please wait....activation to Trinka-Institutional Access is currently in process.....</p>
                                <p>Do not press the back button or close this page</p>
                            </div>
                            <div class="success-state" *ngIf="!loadingStateComplete && !showErrorState">
                                <p class="congratulation-txt" *ngIf="!isExistingUser">
                                    <span class="congratulations-txt-icon"></span>
                                </p>
                                <p style="width: 600px;" *ngIf="!isExistingUser">Your account linked to {{emailId}} has been successfully upgraded to Trinka-Institutional Access!</p>
                                <p style="width: 600px;" *ngIf="isExistingUser">Your account has already been upgraded to Trinka-Institutional Access</p>
                                <button class="continue-btn" (click)="redirectToSignInPage()">Continue</button>
                            </div>
                            <div class="success-state" *ngIf="showErrorState && !loadingStateComplete">
                                <p class="congratulation-txt">
                                    <span class="error-icon"></span>
                                    An error occurred, please try again</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>