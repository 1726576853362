<div class="plag-check-word-limit-cross-modal">
    <div class="plag-check-word-limit-cross-modal-header">
        <div class="plag-check-word-limit-cross-modal-header-text">
            <span class="alert-icon"></span>
            <span>You have exceeded the word limit!</span>
            <button class="cancelbuttons"  mat-button  mat-close (click)="onCloseAction()">
                <img src="../../../assets/images/closeIcons.png" alt="">
            </button>
        </div>
    </div>

    <div class="plag-check-word-limit-cross-modal-head-text">
        <h1>How would you like to proceed?</h1>
    </div>   
    <div class="plag-check-word-limit-cross-options">
        <div class="plag-check-word-limit-cross-option">
            <h4>Option 1</h4>
            <div class="plag-check-word-limit-cross-option-card">
                <span class="split-icon"></span>
                <h4 class="card-option-heading">Split & Upload Again</h4>
                <span class="card-option-text">
                    Your document has more than <b>500 words</b>. Please split the document into smaller files & upload again.
                </span>
                <button class="card-option-button" (click)="onClickAction('uploadAgainAction')">Upload again</button>
            </div>
        </div>
        <div class="plag-check-word-limit-cross-option-separator">
            OR
        </div>
        <div class="plag-check-word-limit-cross-option">
            <h4>Option 2</h4>
            <div class="plag-check-word-limit-cross-option-card upgrade-option">
                <div class="recommendedTag">Recommended</div>
                <span class="diamond-icon"></span>
                <h4 class="card-option-heading">Upgrade to Premium Plus</h4>
                <span class="card-option-text">
                    Enjoy <b>unlimited</b> access to Plagiarism Check with <b>Trinka Premium Plus</b>.
                </span>
                <button class="card-option-button" (click)="onClickAction('redirectToBillingPage')">    
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.3681 12.0762L20.6955 6.87622C20.5495 6.67018 20.3028 6.5459 20.0389 6.5459H7.24532C6.98123 6.5459 6.73471 6.67018 6.5887 6.87622L2.91613 12.0762C2.72016 12.3525 2.74632 12.7171 2.97975 12.9657L13.0496 23.7483C13.1983 23.9085 13.4142 24.0001 13.6416 24.0001C13.869 24.0001 14.0849 23.9085 14.2344 23.7475L24.3042 12.965C24.5369 12.7171 24.5631 12.3525 24.3681 12.0762ZM9.19408 13.2183L11.6347 19.9969L5.30385 13.2183H9.19408ZM15.4575 8.0183L16.5128 11.7466H10.7686L11.8239 8.0183H15.4575ZM16.4271 13.2183L13.6413 20.958L10.8554 13.2183H16.4271ZM18.0885 13.2183H21.9789L15.6481 19.9969L18.0885 13.2183ZM22.2483 11.7458H18.142L17.0866 8.01751H19.6154L22.2483 11.7458ZM7.66789 8.01826H10.1967L9.14132 11.7466H5.03408L7.66789 8.01826Z" fill="#92400E"/>
                    <path d="M6.21355 0.000244141L7.13952 2.38899L9.64194 3.27291L7.13952 4.15683L6.21355 6.54558L5.28757 4.15683L2.78516 3.27291L5.28757 2.38899L6.21355 0.000244141Z" fill="#92400E"/>
                    <path d="M2.78559 5.4541L3.40291 7.0466L5.07119 7.63588L3.40291 8.22516L2.78559 9.81766L2.16828 8.22516L0.5 7.63588L2.16828 7.0466L2.78559 5.4541Z" fill="#92400E"/>
                </svg>    
                    Upgrade now
                </button>
            </div>
        </div>
    </div>
</div>