import { AfterViewInit, ApplicationRef, Component, NgZone, OnChanges, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatDialog, MatDialogConfig, MatSlideToggleChange } from '@angular/material';
import { SharedService } from 'src/app/_services/shared.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { ErrorReportComponent } from '../error-report/error-report.component';
import { AutoEdit } from 'src/app/_enums/auto-edit.enum';
import { ScoreComponent } from '../score/score.component';
import { VerifiedUserService } from 'src/app/_services/networkcalls/verified-user.service';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { PaymentDetails } from 'src/app/_interfaces/dashboard/payment-details';
import { CreditDetailsComponent } from '../credit-details/credit-details.component';
import { environment } from 'src/environments/environment';
import { AdvanceSettingsComponent } from '../auto-edit/advance-settings/advance-settings.component';
import { FreeTrialModelComponent } from '../../editor/power-mode/free-trial-model/free-trial-model.component';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { PurgePlanComponent } from '../purge-plan/purge-plan.component';
import { GoogleDrivePickerService } from 'src/app/_services/google-drive-picker.service';
import { ShowMyDriveFilesModalComponent } from '../show-my-drive-files-modal/show-my-drive-files-modal.component';
import { DropboxChooseOptions,Dropbox } from 'src/app/_interfaces/dashboard/dropbox-choose-options';
import { HttpResponse, HttpHeaderResponse } from '@angular/common/http';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import { AfeInfoModalComponent } from './afe-info-modal/afe-info-modal.component'
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { DeleteConfirmationModalComponent } from './delete-confirmation-modal/delete-confirmation-modal.component';
import { MessageModalComponent } from '../message-modal/message-modal.component';

declare function wisepop():any;
declare const OneDrive: any;
declare var Dropbox: Dropbox;
declare function setUserPropartiesWisePop(setUserPropartiesWisePop): any;
@Component({
  selector: 'app-auto-edit',
  templateUrl: './auto-edit.component.html',
  styleUrls: ['./auto-edit.component.scss']
})
export class AutoEditComponent implements OnInit {
  win = window['dataLayer'] || {}
  files_submitted = [];
  message = '';
  element: HTMLElement;
  @ViewChild('autoEditFile', { static: true }) autoEditFileControl: any;
  autoEditFilesList: any = [];
  userId: any;
  powerModeSelection: any = true;
  fileDataObject: any = {
    writing_advisor_as_comments: true,
    statistics_in_document: true,
    revisions_as_tracks: true,
    revisions_as_comments: false,
    language: "US",
    enhancement: true,
    grammar: true,
    spellings: true,
    writing_advisor: true,
    style_guide: "NONE",
    power_mode: this.powerModeSelection
  };
  filterModel = "";
  totalErrorCount: any;
  fileId: string;
  selectedFiles: FileList;
  fileName: string;
  revisionTracksComments: boolean
  paymentDetails : PaymentDetails;
  creditsDetails: any;
  dashboardCloudDetails : any;
  cloudDriveFileDetails = {"fileId":"", "fileName": "", "fileUrl":"", "source": "", "oauthToken": "", "userId" :""};
  uploadSource =[
    {
     "GOOGLE_DRIVE" : false,
     "TRINKA_DRIVE" : false,
     "ONE_DRIVE"    : false,
     "DROP_BOX"     : false,
     "LOCAL_SYSTEM" : false
     }
  ];
  delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  showMoreOptionsPanel = false;
  defaultLanguage: string = 'US';
  uploadRequest: any;
  errorOccured: boolean = false;
  hideLoaderComponent: boolean = false;
  highlightFirstFile: boolean = false;
  sendEmailBanner: boolean = true;

  constructor(
    public dialog: MatDialog,
    public sharedService: SharedService,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public networkCalls: DashboardService,
    private verifiedService: VerifiedUserService,
    public amplitudeService: AmplitudeService,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    private googleDrivePickerService: GoogleDrivePickerService,
    private appRef: ApplicationRef,
    public _fileUploadService: FileUploadService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private _zone: NgZone
  ) {
  }

  autoEditFile: any;
  userData: any;
  langCode: string = 'en'
  ngOnInit() {
    wisepop();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    let data = {
      "user_id" : this.userId
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.subjectiveBehaviorSharedService.setEditorFileId(null);
    this.subjectiveBehaviorSharedService.setEditorFileName(null);
    this._fileUploadService.resetFileDetails();
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.isNewFileAdded) {

        let collapsibleMenu = document.getElementById('ProcessedFileList');
        let arrowElement = document.querySelector('.edit_file_heading h3.card-title');

        setTimeout(() => {
          if (!collapsibleMenu.classList.contains('show')) {
            collapsibleMenu.classList.add('show');
            arrowElement.classList.remove('collapsed')
          }
        }, 1000);

        this.highlightFirstFile = true;

        setTimeout(() => {
          this.route.navigate([], {
            queryParams: {
              'isNewFileAdded': null,
            },
            queryParamsHandling: 'merge'
          });
          this.highlightFirstFile = false;
        }, 7000);
      }
    });
    this.networkCalls.getDashboardCloudFileDetails().subscribe(cloudDetails => {
      if(cloudDetails && Object.keys(cloudDetails).length !== 0 && this.route.url === '/dashboard/auto-edit'){
      this.uploadSource["LOCAL_SYSTEM"] = false;
      this.dashboardCloudDetails = cloudDetails;
      this.cloudDriveFileDetails.fileId = this.dashboardCloudDetails.fileId;
      this.cloudDriveFileDetails.fileName = this.dashboardCloudDetails.fileName;
      this.cloudDriveFileDetails.fileUrl = this.dashboardCloudDetails.fileUrl;
      this.cloudDriveFileDetails.oauthToken = this.dashboardCloudDetails.oauthToken;
      this.cloudDriveFileDetails.source = this.dashboardCloudDetails.source;
      this.cloudDriveFileDetails.userId = this.dashboardCloudDetails.userId;
      this.uploadSource[this.cloudDriveFileDetails.source] = true;
      this._fileUploadService.currentFileInfo.cloudFileDetails = cloudDetails;
      this._fileUploadService.currentFileInfo.advanceSettings.power_mode = this.powerModeSelection;
      this._fileUploadService.currentFileInfo.advanceSettings.language = this.defaultLanguage;
      this._fileUploadService.currentFileInfo.advanceSettings['langCode'] = this.langCode;
      this.fileName = cloudDetails.fileName;
      this._fileUploadService.currentFileInfo.fileType = this.getFileType(this.fileName);

      setTimeout(() => {
        this.uploadCloudFile();
      }, 50);
      }
    });

    this.networkCalls.getDashboardLocalFileDetails().subscribe(localFileDetails => {
      if(localFileDetails && this.route.url === '/dashboard/auto-edit'){
        this.fileName = localFileDetails.name;
        this.autoEditFile = localFileDetails;
        this.isFileSelected = true;
        this.uploadSource["LOCAL_SYSTEM"] = true;
        this._fileUploadService.currentFileInfo.file = localFileDetails;
        this._fileUploadService.currentFileInfo.advanceSettings.power_mode = this.powerModeSelection;
        this._fileUploadService.currentFileInfo.advanceSettings.language = this.defaultLanguage;
        this._fileUploadService.currentFileInfo.fileType = this.getFileType(this.fileName);
        setTimeout(() => {
          this.uploadAutoEditFile(false);
        }, 50);
      }
    });
    this.loadCreditDetails();
    this.getAutoEditFileList();
    this.getUserProfileData();
    this.revisionTracksComments = this.sharedService.featurePermission.data.revision_tracks_comments;

    this.getUserPaymentSubscription();
    this.handleClickEvent();
    setTimeout(() => {
      this.amplitudeService.logAmplitude('auto_edit_page_view',{
      'userID':this.userId,
      "credit_balance_free": this.creditsDetails.credits,
      "credit_balance_purchased": this.creditsDetails.purchased_credits
      })
    }, 2000);

    if (this._fileUploadService.changeFileClicked && document.getElementById('auto-edit')) {
      document.getElementById('auto-edit').click();
      this._fileUploadService.changeFileClicked = false;
    }
  }

  loadCreditDetails(){
    this.networkCalls.getStoreUserCreditDetails().subscribe(resp => {
      if(Object.keys(resp).length == 0)
      {
        this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
          if (response["status"]) {
            this.creditsDetails = response.data;
            this.networkCalls.storeUserCreditDetails(this.creditsDetails);
          }

        }, error => {
          this.sharedService.errorHandller(error);
        });
      }
      else{
        this.creditsDetails = resp;
      }
      this._fileUploadService.currentUserCredits = this.creditsDetails && this.creditsDetails.credits >= 0 ? this.creditsDetails.credits + this.creditsDetails.purchased_credits : 0;
      let redirct_url = environment.payment_fe;
      let data = {
        "user_id": this.userId,
        "is_premium": this.creditsDetails && this.creditsDetails.user_type == 'PREMIUM' ? true : false,
        "trinka_callback_url": window.location.href
      };
      this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
      this._fileUploadService.creditRedirectUrl = redirct_url + '/user/credit/' + this.userData, "_self";
      this._fileUploadService.userCreditDetails = this.creditsDetails;
    });
  }


  loadProfileDetails()
  {
    this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(profileData=>{
      if(Object.keys(profileData).length == 0)
      {
        this.verifiedService.getSelectedProfileData();
      }
    });
  }


  arrayChunk(list: any[], chunkSize: number) {
    var R = [];
    for (var i = 0; i < list.length; i += chunkSize)
      R.push(list.slice(i, i + chunkSize));
    return R;
  }

  startAutoEdit(){
    if(!this.fileDataObject.enhancement && !this.fileDataObject.grammar && !this.fileDataObject.spellings &&
      !this.fileDataObject.writing_advisor && this.fileDataObject.style_guide == "NONE")
      this.toastr.error('Please select any one category or a style guide',"Error");
    else
    {
      if(this.uploadSource["GOOGLE_DRIVE"] || this.uploadSource["TRINKA_DRIVE"] || this.uploadSource["ONE_DRIVE"] || this.uploadSource["DROP_BOX"] || this.uploadSource["LOCAL_SYSTEM"]){
          if(this.uploadSource["LOCAL_SYSTEM"]){
            this.uploadAutoEditFile();
          }
          else{
            this.uploadCloudFile();
          }
      }else{
        if(this.fileName){
          if(this.fileName.endsWith(".txt")){
            this.toastr.error('Please upload file with doc, docx format.',"Error");
            return
          }
        }
        this.toastr.error('Please upload file with doc, docx format.',"Error");
      }
    }
  }

  async uploadAutoEditFile(resetCloudFileDetails = true) {
    if(resetCloudFileDetails) this.resetCloudFileDetails();
    this.uploadSource["LOCAL_SYSTEM"] = true;
      if (this.autoEditFile) {
        try {

            // this.amplitudeService.logAmplitude('Upload_via_local_Device',{
            //   'credit_balance_free': this.creditsDetails.credits,
            //   'credit_balance_purchased': this.creditsDetails.purchased_credits
            // });
            let index = 0;
            this.cloudDriveFileDetails.source ="LOCAL_SYSTEM";
            let fileObject = new FormData();

            fileObject.append('language', this._fileUploadService.currentFileInfo.advanceSettings.language);
            fileObject.append("file", this._fileUploadService.currentFileInfo.file);
            fileObject.append("action", "UPLOAD");

            this.uploadRequest = this._fileUploadService.uploadOrProcessLocalFile(fileObject).subscribe(
              async (res: any) => {
                this.errorOccured = this.errorOccured || (res instanceof HttpHeaderResponse && res.status !== 200);

                if (!this.errorOccured) {
                  // Simulating the upload progress
                  for (let temp = 1; temp <= 6; temp++) {
                    if (this._fileUploadService.currentFileInfo.isUploadRequestCancelled) {
                      this._fileUploadService.currentFileInfo.progress = 0;
                      this._fileUploadService.resetFileDetails();
                      break;
                    } else {
                      if (!this._fileUploadService.currentFileInfo.apiResponse) {
                        if (this._fileUploadService.currentFileInfo.progress + 4 > 95) {
                          this._fileUploadService.currentFileInfo.progress = 95
                        } else {
                            this._fileUploadService.currentFileInfo.progress += 4;
                        }
                        await this.delay(200);
                      }
                    }
                  }

                  // Check if the response is a valid HttpResponse
                  if (res instanceof HttpResponse) {
                    const data = res.body && res.body.data;

                    if (data && data.status === 'OVER_LIMIT') {
                      this._fileUploadService.currentFileInfo.progress = 100;
                      this._fileUploadService.currentFileInfo.apiResponse = data;
                      this._redirectToNextStage();
                    } else if (data && data.word_count === 0) {
                      setTimeout(() => {
                        this.openMessageModal("You have uploaded a file with no text. Please upload another file with text.", "No text found!");
                        this._fileUploadService.resetFileDetails();
                      }, 1000);
                    } else {
                      // Handle successful file upload
                      this._fileUploadService.currentFileInfo.apiResponse = data;
                      this._fileUploadService.currentFileInfo.progress = 100;

                      // Log the upload event and move to the next stage
                      this.amplitudeService.logAmplitude('FileUpload_AutoEdit', {
                        'uploaded_via': 'local device',
                        'status': 'success',
                        'word_count': data && data.word_count,
                        "credit_balance_free": this.creditsDetails.credits,
                        "credit_balance_purchased": this.creditsDetails.purchased_credits,
                        "fileType": this._fileUploadService.currentFileInfo.fileType
                      });

                      this._redirectToNextStage({
                        queryParams: {
                          "fileId": data.id,
                          "writing_advisor_as_comments": this._fileUploadService.currentFileInfo.advanceSettings.writing_advisor_as_comments,
                          "statistics_in_document": this._fileUploadService.currentFileInfo.advanceSettings.statistics_in_document,
                          "revisions_as_tracks": this._fileUploadService.currentFileInfo.advanceSettings.revisions_as_tracks,
                          "language": this._fileUploadService.currentFileInfo.advanceSettings.language,
                          "enhancement": this._fileUploadService.currentFileInfo.advanceSettings.enhancement,
                          "grammar": this._fileUploadService.currentFileInfo.advanceSettings.grammar,
                          "spellings": this._fileUploadService.currentFileInfo.advanceSettings.spellings,
                          "writing_advisor": this._fileUploadService.currentFileInfo.advanceSettings.writing_advisor,
                          "style_guide": this._fileUploadService.currentFileInfo.advanceSettings.style_guide,
                          "power_mode": this._fileUploadService.currentFileInfo.advanceSettings.power_mode,
                          "isCloudFile": false,
                          "fileType": this._fileUploadService.currentFileInfo.fileType,
                          "langCode": this.langCode
                        }
                      });

                      if (res['status']) {
                        this.autoEditFileControl.nativeElement.value = null;
                        this.autoEditFile = null;
                        this.fileName = null;
                      }
                    }
                  }
                } else {
                  // Reset file details and log the failed upload
                  this._fileUploadService.resetFileDetails();
                  this.amplitudeService.logAmplitude('FileUpload_AutoEdit', {
                    'uploaded_via': 'local device',
                    'status': 'failed',
                    'word count': 0,
                    "credit_balance_free": this.creditsDetails.credits,
                    "credit_balance_purchased": this.creditsDetails.purchased_credits,
                    "fileType": this._fileUploadService.currentFileInfo.fileType
                  });
                }
              },
              (error: any) => {
                // Handle errors during file upload
                this.toastr.error(error.error.message, "Error");
                this._fileUploadService.resetFileDetails();
                this.sharedService.errorHandller(error);
                this.errorOccured = false;
              }
            )

        }
        catch (error) {
          this.isFileSelected = false;
        }
      } else {
        this.toastr.error('Please upload file with doc, docx format.',"Error");
      }
  }


  reUploadFile(file){
    if(file.status == 'ON_HOLD'){
      this.networkCalls.reUploadOnholdFile(file.id).subscribe(res => {
        this.getAutoEditFileList();
        this.getCreditsDetails();
      }, error => {
        this.sharedService.errorHandller(error);
      })
    }
  }

  isFileSelected = null;
  async onFileChange(event) {
    // this.resetCloudFileDetails();
    this.files_submitted = []
    this.selectedFiles = event.files ? event.files : event;
    if (this.selectedFiles.length > 1) {
      this.toastr.error('Please upload a single file only', "Error");
      return;
      //   this.fileName = this.selectedFiles.length + " files selected";
    }
    try {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        let file = this.selectedFiles[i];
        this.files_submitted.push(file)

        this.validateFileType(file);
        if (!this.validFile) {
          this.toastr.error('File type not supported. Please use a doc/docx/LaTeX format to proceed',"Error");
          this.autoEditFile = null;
          this.uploadSource["LOCAL_SYSTEM"] = false;
          break
        } else {
          this.fileName = this.selectedFiles[0].name;
          this.autoEditFile = file;
          this.isFileSelected = true;
          this.uploadSource["LOCAL_SYSTEM"] = true;
          this._fileUploadService.currentFileInfo.file = file;
          this._fileUploadService.currentFileInfo.advanceSettings.power_mode = this.powerModeSelection;
          this._fileUploadService.currentFileInfo.advanceSettings.language = this.defaultLanguage;
          this._fileUploadService.currentFileInfo.fileType = this.getFileType(this.fileName);
          this.uploadAutoEditFile();
        }
      }
    } catch (error) {
      this.isFileSelected = false;
      this.uploadSource["LOCAL_SYSTEM"] = false;
    }
  }

  logLocalFileClickedEvent() {
    this.amplitudeService.logAmplitude('AFE_Upload_via_LocalDevice',{
      'credit_balance_free': this.creditsDetails.credits,
      'credit_balance_purchased': this.creditsDetails.purchased_credits
    });
  }

  validFile = false;
  validateFileType(file) {
    const fileType = file.name.split('.').pop().toLowerCase().toString();

    if (fileType === 'doc' || fileType === 'docx' || fileType === 'tex') {
      this.validFile = true;
    } else {
      this.validFile = false;
    }
  }

  creditLimit: any;
  errorArr = [];

  getAutoEditFileList(flag: boolean = true) {
    if (flag) {
      // this.spinner.show();
    }
    this.networkCalls.getAutoEditFileList().subscribe(res => {
      if (flag) {
        // this.spinner.hide();
      }
      if (res["status"]) {
        this.sharedService.autoEditFileCredit = res.data.credit_limit;
        this.autoEditFilesList = res.data["documents"];
        length = res.data["documents"].length;
        if(length > 0){
          this.autoEditFilesList.splice(length , 0, this.sample_file_details);
        }else{
          this.autoEditFilesList.push(this.sample_file_details);
        }
        this.spinner.hide();
        for (let i = 0; i < this.autoEditFilesList.length; i++) {
          if (this.autoEditFilesList[i].status === AutoEdit.IN_PROGRESS || this.autoEditFilesList[i].status === AutoEdit.WAITING_IN_QUEUE) {
            setTimeout(() => {
              this.getAutoEditFileList(false);
            }, 60000);
            return ;
          }

          /**if (this.autoEditFilesList[i].error_report) {
            this.autoEditFilesList[i]['errorCount'] = Object.values(this.autoEditFilesList[i].error_report).reduce((a: number, b: number) => a + b, 0);
            this.totalErrorCount = this.autoEditFilesList[i]['errorCount'];
          } else {
            this.autoEditFilesList[i]['errorCount'] = 0;
          }*/
        }
      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
    this.getCreditsDetails()
  }

  getCreditsDetails(){

    // this.creditsDetails = {credits: 1, user_id: "abcdef", email_id: "tushar.advilkar@crimsoni.com", user_type: "PREMIUM", purchased_credits: 0}
      this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
        if (response["status"]) {
          this.creditsDetails = response.data;
          this.networkCalls.storeUserCreditDetails(this.creditsDetails);
        }

      }, error => {
        this.sharedService.errorHandller(error);
      })
  }

  downloadFile(file) {
    this.sharedService.setCurrentFileAndUserId(this.userId, file.id);
    this.sharedService.downloadFileAutoEdit(file.id,file, this.creditsDetails, false);
  }

  deleteFileNewFlow(file) {
    const dialogRef = this.dialog.open(DeleteConfirmationModalComponent, {
      disableClose: true,
      panelClass: 'deleteModalSection',
      data: {file: file}
    });
    dialogRef.componentInstance.removeFileFromList.subscribe((res: any) => {
      if (res) this.getAutoEditFileList();
    });
  }

  deleteFile(action: string, file) {
    let getProfileSubscription = this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(profileData=>{
      //if user on purge plan
      if(profileData.is_purge){
        //check if plan not expired
        let date = new Date();
        let currentDate = date.toString()

        if(Date.parse(currentDate) < Date.parse(profileData.purge_expire_on)){
          if(file.created_on > Date.parse(profileData.purge_start_time)){
            this.dialog.open(PurgePlanComponent, {
              data: {
                file : file.id,
                source: 'auto_edit' },
              width : '700px',
            });
            this.subjectiveBehaviorSharedService.getPurgeFileStatus.subscribe(res=>{
              if(res['message']){
                this.getAutoEditFileList();
              }
            });
          }
          else if (file.created_on < Date.parse(profileData.purge_start_time)) {
            this.deleteFileApi(action, file);
            setTimeout(() => {
              getProfileSubscription.unsubscribe();
            }, 500);
          }
        }
        if (Date.parse(currentDate) > Date.parse(profileData.purge_expire_on)) {
          this.deleteFileApi(action, file);
          setTimeout(() => {
            getProfileSubscription.unsubscribe();
          }, 500);
        }
      }
      else {
        this.deleteFileApi(action, file);
        setTimeout(() => {
          getProfileSubscription.unsubscribe();
        }, 500);
      }
    });
  }

  deleteFileApi(action, file) {
    this.spinner.show();
    this.networkCalls.deleteFileByIdAutoEdit(file.id).subscribe(res => {
        this.amplitudeService.logAmplitude('edit_delete',{
        'fileID': file.id,
        'file_name': file.filename,
        'status': file.status
        });
         // this.spinner.hide();
         this.getAutoEditFileList();
         let message = file.filename + " is deleted"
         let snakRef = this._snackBar.open(message, action, {
           duration: 10000,
           horizontalPosition: "right",
          });
          snakRef.onAction().subscribe(() => this.restoreFile(file));

        }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  restoreFile(file) {
    this.spinner.show();
    this.networkCalls.restoreFileByIdAutoEdit(file.id, true).subscribe(res => {
      this.spinner.hide();
      this.getAutoEditFileList();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  errorReport(file) {
    if (file.error_report != null) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        fileData: file,
        //errorCount: file.errorCount
      };
      this.dialog.open(ErrorReportComponent, dialogConfig);
      this.amplitudeService.logAmplitude('edit_summary_view',{
        'fileID': file.id,
        'file_name': file.filename
      });
    }
  }

  credits(){
    if(this.creditsDetails != null){
      this.amplitudeService.logAmplitude('auto_edit_credit_view',{
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits,
        'Subscription Plan': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'User ID': this.userId
         });
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData : this.creditsDetails,
      };
      this.dialog.open(CreditDetailsComponent, dialogConfig)
    }
  }

  scoreReport(file) {
    if (this.sharedService.featurePermission.data.auto_edit_score_popup) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        fileData: file,
      };
      this.dialog.open(ScoreComponent, dialogConfig);
    }
  }

  getUserPaymentSubscription(){
    this.networkCalls.getStoreUserPlanDetails().subscribe(resp=> {
      if(Object.keys(resp).length == 0)
      {
        this.networkCalls.getUserPaymentSubscription(this.userId).subscribe(response => {
          this.paymentDetails = response['data'];
          let wisepop_user_proparties = {
            userSubscriptionType: this.paymentDetails['is_premium']?'Premium':'Basic',
            credit_balance: this.creditsDetails.credits + this.creditsDetails.purchased_credits
          }
          setUserPropartiesWisePop(wisepop_user_proparties);
        });
      }else{
        this.paymentDetails = resp;
        let wisepop_user_proparties = {
          userSubscriptionType: this.paymentDetails['is_premium']?'Premium':'Basic',
          credit_balance: this.creditsDetails.credits + this.creditsDetails.purchased_credits
        }
        setUserPropartiesWisePop(wisepop_user_proparties);
      }
    });
  }

  addCredits() {
    this.amplitudeService.logAmplitude('auto_edit_add_credits',{
      'credit_balance_free': this.creditsDetails.credits,
      'credit_balance_purchased': this.creditsDetails.purchased_credits,
      'Subscription Plan': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'from': 'insufficient credit modal'
    });
    let redirct_url  = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url+'/user/credit/'+this.userData,"_self");
  }

  advanceSettings(){
    const dialogRef = this.dialog.open(AdvanceSettingsComponent, { data: { writing_advisor_as_comments: this.fileDataObject.writing_advisor_as_comments,
      statistics_in_document: this.fileDataObject.statistics_in_document,
      enhancement: this.fileDataObject.enhancement,
      grammar: this.fileDataObject.grammar,
      spellings: this.fileDataObject.spellings,
      writing_advisor: this.fileDataObject.writing_advisor,
      style_guide: this.fileDataObject.style_guide } });

      dialogRef.afterClosed().subscribe(result =>  {
        this.fileDataObject.writing_advisor_as_comments = result.writing_advisor_as_comments;
        this.fileDataObject.statistics_in_document = result.statistics_in_document;
        this.fileDataObject.enhancement = result.enhancement;
        this.fileDataObject.grammar = result.grammar;
        this.fileDataObject.spellings = result.spellings;
        this.fileDataObject.writing_advisor = result.writing_advisor;
        this.fileDataObject.style_guide = result.style_guide;
      })
    }

  togglePowerMode(event: MatSlideToggleChange){
    if(this.paymentDetails.is_premium){
        this.powerModeSelection = event.checked;
    } else{
      event.source.checked = false;
      this.dialog.open(FreeTrialModelComponent,  {
        data: {
          trial: "trial_auto_edit"
        }
      });
      this.powerModeSelection = false;
    }
  }

  openGoogleDrivePicker(){
    try {
      this.amplitudeService.logAmplitude('AFE_Upload_via_more', {
        'uploaded_via': 'google drive',
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits
      });
      this.resetCloudFileDetails();
      this.uploadSource["GOOGLE_DRIVE"] = true;
      this.cloudDriveFileDetails.fileId = "";

      this.googleDrivePickerService.open((data) => {
        if (data.action === 'picked') {
          let file_details = data.docs[0];
          this.fileName = file_details.name;
          if (this.fileName.endsWith(".txt")) {
            this.toastr.error('Please upload file with doc, docx format.', "Error");
            return
          }
          if (!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx") || this.fileName.endsWith(".tex"))) {
            this.fileName += ".docx"
          }
          this.cloudDriveFileDetails.fileId = file_details.id;
          this._fileUploadService.currentFileInfo.progress = 1;
          this.cloudDriveFileDetails.fileName = this.fileName;
          this.cloudDriveFileDetails.source = "GOOGLE_DRIVE";
          this.cloudDriveFileDetails.oauthToken = this.googleDrivePickerService.oauthAccessToken;
          this._fileUploadService.currentFileInfo.cloudFileDetails = this.cloudDriveFileDetails;
          this._fileUploadService.currentFileInfo.advanceSettings.power_mode = this.powerModeSelection;
          this._fileUploadService.currentFileInfo.advanceSettings.language = this.defaultLanguage;
          this._fileUploadService.currentFileInfo.fileType = this.getFileType(this.fileName);
          this.uploadCloudFile();
          // this.amplitudeService.logAmplitude('AFE_Upload_via_GoogleDrive', {
          //   'credit_balance_free': this.creditsDetails.credits,
          //   'credit_balance_purchased': this.creditsDetails.purchased_credits
          // });
        }
      });
    } catch (e) {
      console.log('Error is : ',e);
    }
  }


  launchOneDrivePicker(){
    this.resetCloudFileDetails();
    this.uploadSource["ONE_DRIVE"] = true;
    this.cloudDriveFileDetails.fileId = "";
    var that =this;
    var odOptions = {
      clientId: environment.one_drive_client_id,
      action: "download",
      multiSelect: false,
      openInNewWindow: true,
      advanced: {
        queryParameters: "select=id,name,size,file,folder,@microsoft.graph.downloadUrl",
        filter: [".docx",".doc", ".tex"],
        redirectUri: environment.cloud_redirect_url+"dashboard/auto-edit",
      },
      success: function(files) {
        for (const file of files.value) {
          that._fileUploadService.currentFileInfo.progress = 1;
          that.fileName = file.name;
          that.cloudDriveFileDetails.fileId = file.id;
          that.cloudDriveFileDetails.fileName = file.name;
          that.cloudDriveFileDetails.fileUrl = file['@microsoft.graph.downloadUrl'];
          that.cloudDriveFileDetails.source = "ONE_DRIVE";
          that.amplitudeService.logAmplitude('AFE_Upload_via_OneDrive',{
            'credit_balance_free': that.creditsDetails.credits,
            'credit_balance_purchased': that.creditsDetails.purchased_credits
          });
          that._fileUploadService.currentFileInfo.cloudFileDetails = that.cloudDriveFileDetails;
          that._fileUploadService.currentFileInfo.advanceSettings.power_mode = that.powerModeSelection;
          that._fileUploadService.currentFileInfo.advanceSettings.language = that.defaultLanguage;
          that._fileUploadService.currentFileInfo.fileType = that.getFileType(that.fileName);
          that.uploadCloudFile();
        }
       },
      cancel: function() {
       },
      error: function(e) {
       }
    }
    OneDrive.open(odOptions);
  }

  launchDropBoxPicker() {
    this.amplitudeService.logAmplitude('AFE_Upload_via_more', {
      'uploaded_via': 'dropbox',
      'credit_balance_free': this.creditsDetails.credits,
      'credit_balance_purchased': this.creditsDetails.purchased_credits
    });
    this.resetCloudFileDetails();
    this.uploadSource["DROP_BOX"] = true;
    this.cloudDriveFileDetails.fileId = "";
    let that =this;
    var options: DropboxChooseOptions = {
      success: function (files) {
        for (const file of files) {
          that._fileUploadService.currentFileInfo.progress = 1;
          that.fileName = file.name;
          that.cloudDriveFileDetails.fileName = file.name;
          that.cloudDriveFileDetails.fileUrl = file.link;
          that.cloudDriveFileDetails.source = "DROP_BOX";
          that._fileUploadService.currentFileInfo.cloudFileDetails = that.cloudDriveFileDetails;
          that._fileUploadService.currentFileInfo.advanceSettings.power_mode = that.powerModeSelection;
          that._fileUploadService.currentFileInfo.advanceSettings.language = that.defaultLanguage;
          that._fileUploadService.currentFileInfo.fileType = that.getFileType(that.fileName);
          that.uploadCloudFile();
        }
        // that.amplitudeService.logAmplitude('AFE_Upload_via_DropBox',{
        //   'credit_balance_free': that.creditsDetails.credits,
        //   'credit_balance_purchased': that.creditsDetails.purchased_credits
        // });
      },
      cancel: function () {
      },
      linkType: "direct",
      multiselect: false,
      extensions: ['.doc', '.docx', '.tex'],
  };

  Dropbox.choose(options);
  }



  launchTrinkaMyDrive() {
    this.amplitudeService.logAmplitude('AFE_Upload_via_TrinkaDrive',{
      'credit_balance_free': this.creditsDetails.credits,
      'credit_balance_purchased': this.creditsDetails.purchased_credits
     });
    this.resetCloudFileDetails();
    this.uploadSource["TRINKA_DRIVE"] = true;
    const dialogRef = this.dialog.open(ShowMyDriveFilesModalComponent,{ autoFocus: false });
    dialogRef.afterClosed().subscribe(result => {
      this._fileUploadService.currentFileInfo.progress = 1;
     this.fileName = result.file_name;
     if(!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx"))){
      this.fileName +=".docx"
     }
     this.cloudDriveFileDetails.fileId = result.file_id;
     this.cloudDriveFileDetails.fileName = this.fileName;
     this.cloudDriveFileDetails.source = "TRINKA_DRIVE";
     this.cloudDriveFileDetails.userId = this.userId;
     this._fileUploadService.currentFileInfo.cloudFileDetails = this.cloudDriveFileDetails;
     this._fileUploadService.currentFileInfo.advanceSettings.power_mode = this.powerModeSelection;
     this._fileUploadService.currentFileInfo.advanceSettings.language = this.defaultLanguage;
     this._fileUploadService.currentFileInfo.fileType = this.getFileType(this.fileName);
     this.uploadCloudFile();
    });

  }

  async uploadCloudFile() {
    if (this.fileName && this.fileName.endsWith(".txt")) {
        this.toastr.error('File type not supported. Please use a doc/docx/LaTeX format to proceed');
        return
    }
    let fileObject = new FormData();
    fileObject.append('language', this._fileUploadService.currentFileInfo.advanceSettings.language);
    fileObject.append("cloud_file_details", JSON.stringify(this.cloudDriveFileDetails));
    fileObject.append("action", "UPLOAD");

    this._fileUploadService.uploadOrProcessCloudFile(fileObject).subscribe(async (res) => {
      this.errorOccured = this.errorOccured ? this.errorOccured : res instanceof HttpHeaderResponse && res.status !== 200;
      try {
        if (!this.errorOccured) {
          if(this._fileUploadService.currentFileInfo.progress < 100) this._fileUploadService.currentFileInfo.progress += 4;
          if (res instanceof HttpResponse) {
            if (res.body.data.status == 'OVER_LIMIT') {
              this._fileUploadService.currentFileInfo.progress = 100;
              this._fileUploadService.currentFileInfo.apiResponse = res.body.data;
              this._redirectToNextStage();
            } else {
              this._fileUploadService.currentFileInfo.progress = 100;
              this._fileUploadService.currentFileInfo.isCloudFile = true;
              this._fileUploadService.currentFileInfo.apiResponse = res.body.data;
              // this.toastr.success("File uploaded successfully.");
              // setTimeout(() => {
                this._redirectToNextStage({
                  queryParams: {
                    "fileId": this._fileUploadService.currentFileInfo.apiResponse.id,
                    "writing_advisor_as_comments": this._fileUploadService.currentFileInfo.advanceSettings.writing_advisor_as_comments,
                    "statistics_in_document": this._fileUploadService.currentFileInfo.advanceSettings.statistics_in_document,
                    "revisions_as_tracks": this._fileUploadService.currentFileInfo.advanceSettings.revisions_as_tracks,
                    "language": this._fileUploadService.currentFileInfo.advanceSettings.language,
                    "enhancement": this._fileUploadService.currentFileInfo.advanceSettings.enhancement,
                    "grammar": this._fileUploadService.currentFileInfo.advanceSettings.grammar,
                    "spellings": this._fileUploadService.currentFileInfo.advanceSettings.spellings,
                    "writing_advisor": this._fileUploadService.currentFileInfo.advanceSettings.writing_advisor,
                    "style_guide": this._fileUploadService.currentFileInfo.advanceSettings.style_guide,
                    "power_mode": this._fileUploadService.currentFileInfo.advanceSettings.power_mode,
                    "isCloudFile": true,
                    "fileType": this._fileUploadService.currentFileInfo.fileType,
                    "langCode": this.langCode
                  }
                });
              // }, 2500);
            }

            this.amplitudeService.logAmplitude('FileUpload_AutoEdit', {
              'uploaded_via': this.cloudDriveFileDetails.source,
              'status': 'success',
              'word_count': res.body && res.body.data && res.body.data.word_count,
              "credit_balance_free": this.creditsDetails.credits,
              "credit_balance_purchased": this.creditsDetails.purchased_credits,
              "fileType": this._fileUploadService.currentFileInfo.fileType
            });

            this.changeDetectorRef.detectChanges();
          }
        }
        else {
          this._fileUploadService.resetFileDetails();
          this.amplitudeService.logAmplitude('FileUpload_AutoEdit', {
            'uploaded_via': this.cloudDriveFileDetails.source,
            'status': 'fail',
            'word_count': 0,
            "credit_balance_free": this.creditsDetails.credits,
            "credit_balance_purchased": this.creditsDetails.purchased_credits,
            "fileType": this._fileUploadService.currentFileInfo.fileType
          });
        }
    } catch (e) {
        console.log('eror: ', e);
    }

    }, error => {
        this.toastr.error(error.error.message, "Error");
        this.sharedService.errorHandller(error);
        this._fileUploadService.resetFileDetails();
        this.errorOccured = false
    });

    for (const temp of [1, 2, 3, 4, 5, 6]) {
          if(this._fileUploadService.currentFileInfo.progress < 100) this._fileUploadService.currentFileInfo.progress += 4;
          await this.delay(200);
          this.changeDetectorRef.detectChanges();
        }

  }

  resetCloudFileDetails(){
    let that= this;
    this.fileName = null;
    Object.keys(this.uploadSource).forEach(function(key){
      that.uploadSource[key] = false;
    });

    Object.keys(this.cloudDriveFileDetails).forEach(function(key){
      that.cloudDriveFileDetails[key] = "";
    });
    that.autoEditFileControl.nativeElement.value = null;
  }

  sample_file_details =  {
    'id': '123',
    'filename': 'Trinka AI- Sample File.docx',
    'status': 'SUCCESS',
    'message': null,
    'credits': 0,
    'words': 999,
    'language': 'US',
    'parentExtension': 'docx',
    'deleted': false,
    'error_id': -1,
    'error_report': {
      'style_guide': 0,
      'pronoun_and_determiner': 0,
      'complexity': 0,
      'other': 4,
      'symbols_notations': 0,
      'redundancy_conciseness': 2,
      'enhancement': 6,
      'conjunction': 0,
      'word_choice': 6,
      'preposition': 3,
      'writing_advisor': 2,
      'capitalization_and_spacing': 7,
      'word_form': 0,
      'abbreviations': 0,
      'sva': 0,
      'plain_language': 0,
      'spelling': 0,
      'verb': 2,
      'noun': 3,
      'word_order': 0,
      'number_style': 0,
      'article': 10,
      'fragment': 0,
      'adverb_and_adjective': 1,
      'punctuation': 4,
      'idioms': 0,
      'syntax': 0,
      'style': 0,
      'sensitive_language': 0,
      'tense': 2,
      'run_on': 0,
      'categories': {
        '': 4,
        'R:ADJ': 2,
        'U:VERB': 1,
        'U:ADJ R:NOUN': 2,
        'R:NOUN': 1,
        'M:CONJ': 1,
        'M:PUNCT': 4,
        'U:VERB:TENSE': 1,
        'U:OTHER R:OTHER R:OTHER': 1,
        'U:DET': 1,
        'U:VERB U:PREP': 1,
        'R:CONJ': 1,
        'R:NOUN:NUM': 4,
        'R:OTHER': 3,
        'R:PREP': 3,
        'R:VERB:TENSE': 1,
        'R:OTHER R:OTHER': 1,
        'M:DET R:ORTH': 1,
        'Writing Advisor': 2,
        'R:ORTH': 2,
        'Enhancement': 6,
        'M:DET': 9
      },
      'ce_label': -1,
      'language_score': 51,
      'total_corrections': 50,
      'total_suggestions': 2,
      'total_sentences': 52
    },
    'inclusive_lang': false,
    'statistics_in_document': false,
    'writing_advisor_as_comments': true,
    'revisions_as_tracks': true,
    'revisions_as_comments': false,
    'created_on': '',
    'modified_on': 1646664166844,
    'style_guide': 'NONE',
    'lang_code': 'en',
  }

  downloadOrViewSampleFile(viewFile: boolean = false) {
    if (!viewFile) {
      this.spinner.show();
      this.amplitudeService.logAmplitude('AFE_samplefile_download', {
        'User ID': this.userId,
        'Subscription Type': this.creditsDetails.user_type
      });
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', '../../../../assets/Trinka_AI_Sample_File_Auto_Edit_File.docx');
      link.setAttribute('download', 'Trinka AI- Sample File.docx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.spinner.hide();
    } else {
      this.amplitudeService.logAmplitude('AFE_view_samplefile', {});
      let collapsibleMenu = document.getElementById('ProcessedFileList');
      let lastElement = document.querySelector('.foot-section-ref');
      let arrowElement = document.querySelector('.edit_file_heading h3.card-title');

      if (!collapsibleMenu.classList.contains('show')) {
        collapsibleMenu.classList.add('show');
        arrowElement.classList.remove('collapsed')
      }
      setTimeout(() => {
        lastElement.scrollIntoView();
      }, 500);

    }
  }

  handleClickEvent() {
    document.addEventListener('click', (event: any) => {
      if (document.querySelector('.more-options-outer') && document.querySelector('.more-options-outer').contains(event.target)) return;
      else this.showMoreOptionsPanel = false;
    });
  }

  getUserProfileData() {
    this.networkCalls.getUserDetails(this.userId).subscribe(data => {
      this.defaultLanguage = data.data.language;
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  stopUploadProgress() {
    if (this.uploadRequest) {
      this.uploadRequest.unsubscribe();
      setTimeout(() => {
        this._fileUploadService.resetFileDetails();
      }, 800);
    }
  }

  openAFEInfoModal() {
    this.amplitudeService.logAmplitude('AFE_know_more', {});
    const dialogRef = this.dialog.open(AfeInfoModalComponent, {
      panelClass: 'afe-info'
    });
    dialogRef.afterClosed().subscribe(() => {
      this.amplitudeService.logAmplitude('AFE_know_more_close', {});
    })
  }

  openFeedBackModal() {
    this.amplitudeService.logAmplitude('AFE_Feedback_CTA', {});
    this.dialog.open(FeedbackModalComponent, {
      data: { user_type: this.creditsDetails.user_type == 'PREMIUM' ? 'Trinka Premium' : 'Trinka Basic' },
      disableClose: true, // Set to true if you want to prevent closing the modal by clicking outside or pressing ESC
      panelClass: 'outerSectionContactUs'
    });
  }

  openAfeFileList(event: any) {
    let collapsibleMenu = document.getElementById('ProcessedFileList');
    if (collapsibleMenu.classList.contains('show') && this.filterModel.length === 1) this.amplitudeService.logAmplitude('AFE_search_report', {});
    if (event && event.length > 0 && !collapsibleMenu.classList.contains('show')) {
      this.amplitudeService.logAmplitude('AFE_search_report', { });
      let arrowElement = document.querySelector('.edit_file_heading h3.card-title');
      if (!collapsibleMenu.classList.contains('show')) {
        collapsibleMenu.classList.add('show');
        arrowElement.classList.remove('collapsed')
      }
    }
  }

  logEditCollapsedState() {
    let collapsibleMenu = document.getElementById('ProcessedFileList');
    if (!collapsibleMenu.classList.contains('show')) this.amplitudeService.logAmplitude('AFE_View_edited_files', {action: 'expanded'});
    else this.amplitudeService.logAmplitude('AFE_View_edited_files', { action: 'collapsed' });
  }

  private _redirectToNextStage(params: any = {}) {
    console.log("params ", params)
    setTimeout(() => {
      this._zone.run(() => {
        this.route.navigate(['/dashboard/auto-edit-uploaded-file'], params);
      });
    }, 2000);
  }

  openMessageModal(message: string, title: string = 'Alert message'): void {
    const dialogRef = this.dialog.open(MessageModalComponent, {
      disableClose: true,
      panelClass: 'deleteModalSection',
      data: { message: message, title: title }
    });
    // Optional: Handle the dialog result
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getFileType(file_name) {
    const fileType = file_name.split('.').pop().toLowerCase().toString();

    if (fileType === 'doc' || fileType === 'docx' || fileType === 'tex') {
      return fileType;
    } else {
      return ''
    }
  }

  getFileNameToBeShown(file: any) {
    let fileName = '';
    fileName = file.parentExtension === 'tex' ? file.filename.replace('_tex', '') : file.filename;
    return fileName;
  }

  sendEmailClose() {
    this.sendEmailBanner = false;
  }

}
