import { Component, OnInit } from '@angular/core';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { KeywordsCheck } from 'src/app/_interfaces/author-one/keyword-check';
import { SummaryConceptsCheck } from 'src/app/_interfaces/author-one/summary-concepts-check';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-keywords-summary',
  templateUrl: './keywords-summary.component.html',
  styleUrls: ['./keywords-summary.component.scss']
})
export class KeywordsSummaryComponent implements OnInit {

  abstract = [];
  text_abstract = ""
  introduction = []
  text_introduction = ""
  conclusion = []
  text_conclusion = ""
  modal_content = ""
  summaryJson: any;
  keywordsCheck: KeywordsCheck;
  summaryConceptCheck: any;
  Contentshow = false;

  constructor(private _clipboardService: ClipboardService,
    private toastr: ToastrService,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService) {
    this.getReportData();
  }

  ngOnInit() {
  }

  getReportData() {
    this.subjectiveBehaviorSharedService.getA1AllCheckReportData.subscribe(resp => {
      this.keywordsCheck = resp['keywords']
      if (this.keywordsCheck.suggested_keywords.is_present) {
        this.keywordsCheck.suggested_keywords.keywords.filter(data => data['is_selected'] = true)
      } else {
        this.keywordsCheck.suggested_keywords['keywords'] = [];
      }
      this.processSummaryConceptCheck(resp['summary_concepts']);

      //this.filter(this.summaryConceptCheck);
    });
  }

  private processSummaryConceptCheck(summaryConcepts: SummaryConceptsCheck) {
    let summary = ''
    if (summaryConcepts && summaryConcepts.summary_sentences && summaryConcepts.summary_sentences.is_present && summaryConcepts.summary_sentences.concepts_sentences.abstract && summaryConcepts.summary_sentences.concepts_sentences.abstract.sentences) {
      let conceptsSentences = summaryConcepts.summary_sentences.concepts_sentences;

      let abstract = conceptsSentences.abstract.sentences
        .map(concept => concept.sentence)
        .join(" ").trim();

      let introduction = conceptsSentences.introduction.sentences
        .filter(concept => concept.unique != 0)
        .map(concept => concept.sentence)
        .join(" ").trim();

      let conclusion = conceptsSentences.conclusion.sentences
        .map(concept => concept.sentence)
        .join(" ").trim();

      summary = abstract;
      summary += (abstract.length > 0) ? ' ' : '';
      summary += introduction;
      summary += (introduction.length > 0) ? ' ' : '';
      summary += conclusion;
      summary += (conclusion.length > 0) ? ' ' : '';
      summary = summary.trim();
    }

    this.summaryConceptCheck = {
      'is_present': (summary.length > 0),
      'to_display': summary,
      'to_copy': summary.replace(/<[^>]*>/g, '')
    }
  }

  /**@description: filter summary data content, by introduction ,conclusion & abstract 
  filter(summaryConcepts){
    if(summaryConcepts.summary_sentences.concepts_sentences.abstract){
      if(summaryConcepts.summary_sentences.concepts_sentences.abstract.sentences){
        this.abstract = summaryConcepts.summary_sentences.concepts_sentences.abstract.sentences.map( sentence_concept => {
          return sentence_concept.sentence
        });
        this.introduction = summaryConcepts.summary_sentences.concepts_sentences.introduction.sentences
        .filter((sentence_concept)=>{
          return sentence_concept.unique != 0 
        })
        .map( sentence_concept => {
          return sentence_concept.sentence
        });
        this.conclusion = summaryConcepts.summary_sentences.concepts_sentences.conclusion.sentences.map( sentence_concept => {
          return sentence_concept.sentence
        });

        this.text_abstract = this.abstract.join(" ");
        this.text_introduction = this.introduction.join(" ");
        this.text_conclusion = this.conclusion.join(" ");
        
        this.summaryJson = {
          abstract:{
            content: summaryConcepts.summary_sentences.concepts_sentences.abstract.content,
            sentences: this.abstract
          },
          introduction : {
            content: summaryConcepts.summary_sentences.concepts_sentences.introduction.content,
            sentences: this.introduction
          },
          conclusion : {
            content: summaryConcepts.summary_sentences.concepts_sentences.conclusion.content,
            sentences: this.conclusion
          }
      }
     }
    }
  }*/

  message :string;
  insertSelectedKeywords(keyword) {
    var copy: any = [];

    keyword.filter(keyword => {
      if (keyword.is_selected === true) {
        copy.push(keyword.keyword)
      }
    });
    copy.length === 0 ?  this.toastr.warning('No keywords selected!') : this.toastr.success('Copied to clipboard!');
    this._clipboardService.copy(copy);
  }

  callServiceToCopy() {
    var plainText = this.summaryConceptCheck.to_copy;
    this._clipboardService.copy(plainText);
    this.toastr.success('Copied to clipboard!')
  }
}
