import { ChangeDetectorRef, Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { FileUploadService } from 'src/app/_services/file-upload.service'
import { AmplitudeService } from 'src/app/_services/amplitude.service'
import { PowerModeEditingComponent } from 'src/app/modules/shared/components/power-mode-editing/power-mode-editing.component'

export interface DialogDataObj {
  config: {
    writing_advisor_as_comments: boolean
    statistics_in_document: boolean
    revisions_as_tracks: boolean
    language: string
    enhancement: boolean
    grammar: boolean
    spellings: boolean
    writing_advisor: boolean
    style_guide: string,
    power_mode: boolean,
    langCode: string
  }
  fileDetails: any
}

@Component({
  selector: 'app-afe-settings-modal',
  templateUrl: './afe-settings-modal.component.html',
  styleUrls: ['./afe-settings-modal.component.scss']
})
export class AfeSettingsModalComponent {
  form: FormGroup = new FormGroup({});
  submitted = false;
  styleGuide = [
    {
      value: 'NONE',
      title: 'None'
    },
    {
      value: 'AMA',
      title: 'AMA (11th ed)'
    },
    {
      value: 'AGU',
      title: 'AGU (2017 ed)'
    },
    {
      value: 'APA',
      title: 'APA (7th ed)'
    },
    {
      value: 'ACS',
      title: 'ACS (2nd ed)'
    },
    {
      value: 'IEEE',
      title: 'IEEE'
    }
  ];
  showSettingsAppliedModal: boolean = false;
  modalData: any;
  showSelectionError: boolean = false;
  afeApplySetting: boolean = true;
  afeEditingModePopup: boolean = false;

  showRevivsionOne: boolean = false;
  showRevivsionTwo: boolean = false;
  showRevivsionThree: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataObj,
    private dialogRef: MatDialogRef<AfeSettingsModalComponent>,
    private router: Router,
    private _fileUploadService: FileUploadService,
    public amplitudeService: AmplitudeService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.showSettingsAppliedModal = false;
    this.modalData = JSON.parse(JSON.stringify(this.data));
  }

  onSubmit() {
    this.changeDetector.detectChanges();
    if (!this.modalData.config['grammar'] && !this.modalData.config['spellings'] && !this.modalData.config['writing_advisor'] && !this.modalData.config['enhancement'] && this.modalData.config['style_guide'] === 'NONE') {
      this.showSelectionError = true;
    }
    else {
      this.showSelectionError = false;
      this.router.navigate(['/dashboard/auto-edit-uploaded-file'], {
        queryParams: {
          "fileId": this._fileUploadService.currentFileInfo.fileId || this._fileUploadService.currentFileInfo.apiResponse.id,
          "writing_advisor_as_comments": this.modalData.config['writing_advisor_as_comments'],
          "statistics_in_document": this.modalData.config['statistics_in_document'],
          "revisions_as_tracks": this.modalData.config['revisions_as_tracks'],
          "language": this.modalData.config['language'],
          "enhancement": this.modalData.config['enhancement'],
          "grammar": this.modalData.config['grammar'],
          "spellings": this.modalData.config['spellings'],
          "writing_advisor": this.modalData.config['writing_advisor'],
          "style_guide": this.modalData.config['style_guide'],
          "power_mode": this.modalData.config['power_mode'],
          "langCode": this.modalData.config['langCode']
        }
      });
      let dataToBelogged = this.modalData.config;
      dataToBelogged['Editing modes'] = this.modalData.config['power_mode'] ? 'advanced' : 'basic';
      this.amplitudeService.logAmplitude('AFE_settings_apply', {
        ...dataToBelogged
      })
      this.showSettingsAppliedModal = true;

      setTimeout(() => {
        this.closeDialog();
      }, 3000);
      setTimeout(() => {
        this.showSettingsAppliedModal = false;
      }, 5000);
    }

  }

  onCloseClickAndRedirect(): void {
    this.closeDialog();
    setTimeout(() => {
      this.showSettingsAppliedModal = false;
    }, 3000);
  }

  closeDialog() {
    this.dialogRef.close(this.modalData);
  }

  selectlangCode(langCode){
    this.modalData.config.langCode = langCode;
    if(langCode == 'es'){
      this.modalData.config.writing_advisor = false;
      this.modalData.config.enhancement = false;
      this.modalData.config.spellings = true;
      this.modalData.config.grammar = true;
      this.modalData.config.statistics_in_document = false;
      this.modalData.config.writing_advisor_as_comments = false;
      this.modalData.config.power_mode = false;
    } else {
      this.modalData.config.writing_advisor = true;
      this.modalData.config.power_mode = true;
      this.modalData.config.enhancement = true
      this.modalData.config.statistics_in_document = true;
      this.modalData.config.writing_advisor_as_comments = true;
      this.modalData.config.spellings = true;
      this.modalData.config.grammar = true;
    }
  }
  logCancelEvent() {
    this.amplitudeService.logAmplitude('AFE_settings_cancel', {});
  }
  openPowerModeInfoModal() {
    // this.dialogRef.close(this.modalData);
    // const dialogRef1 = this.dialog.open(PowerModeEditingComponent, { data: {} });
    this.afeApplySetting = false;
    this.afeEditingModePopup = true;
  }
  applySettingBackButton() {
    this.afeApplySetting = true;
    this.afeEditingModePopup = false;
  }
  showRevivationActive_Slider_one() {
    this.showRevivsionOne = !this.showRevivsionOne;
  }
  showRevivationActive_Slider_two() {
    this.showRevivsionTwo = !this.showRevivsionTwo;
  }
  showRevivationActive_Slider_three() {
    this.showRevivsionThree = !this.showRevivsionThree;
  }
}
