import { Component, Inject, OnInit } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { GetMetaService } from 'src/app/_services/get-meta.service';
import { AssetService } from 'src/app/_services/networkcalls/asset.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
@Component({
  selector: 'app-delete-feedback-modal',
  templateUrl: './delete-feedback-modal.component.html',
  styleUrls: ['../browser-extension/uninstall/uninstall.component.scss', './delete-feedback-modal.component.scss']
})
export class DeleteFeedbackModalComponent implements OnInit {
  suggestion: any;
  isDisabled:boolean = true;
  emailId: any;
  paymentDetails: any;
  is_enago_user: any;
  amplitudeLogData: any = {}

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService,
    public metaString: GetMetaService,
    public networkCalls: AssetService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    public amplitudeService: AmplitudeService,
    public subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService) {
    this.createOrResetFeedback()
  }
  ngOnInit(): void {
    this.storage.set(this.metaString.getMeta(), {});
    this.emailId = this.route.snapshot.params.key;
    this.amplitudeService.setUserId(this.emailId);
    this.subjectiveBehaviorSharedService.getAmplitudeData.subscribe(data => this.amplitudeLogData = data);
    this.logAmplitudeData("Feedback_page_view");
  }

  submitFeedback() {
    let emailId = this.emailId;
    var obj = new FormData()
    obj.append("email_id",emailId);
    var feedbacks = {}
    let selectedFeedback = "";
    Object.keys(this.suggestion).forEach(item => {
      feedbacks[item] = { selected: this.suggestion[item].selected, text: this.suggestion[item].text };
      if(this.suggestion[item].selected){
        selectedFeedback += this.suggestion[item].value + ",";
      }
    })
    this.amplitudeLogData['Reason'] = selectedFeedback.substring(0, selectedFeedback.length-1);
    this.logAmplitudeData("Delete_Submit_Feedback_CTA");
    obj.append("feedback",JSON.stringify(feedbacks));
    this.networkCalls.submitDeletedUsersFeedback(obj).subscribe(() => {
      this.toastr.success("Thank you for your feedback.");
      this.createOrResetFeedback();
      this.signOut();
    },
      error => {
        this.toastr.error("Your feedback is not submitted.");
      });
  }
  
  signOut() {
    this.router.navigate(['/signin'])
  }

  createOrResetFeedback() {
    this.suggestion = {
      not_accurate: {
        "selected": false,
        "value": "Trinka does not correct my writing accurately",
        "text": "",
        "inputBox": false
      },
      slow: {
        "selected": false,
        "value": "Trinka is too slow",
        "text": "", 
        "inputBox": false
      },
      not_upto_mark: {
        "selected": false,
        "value": "The features did not meet my expectations",
        "text": "",
        "inputBox": false
      },
      costly: {
        "selected": false,
        "value": "Trinka Premium is too costly",
        "text": "",
        "inputBox": false,
      },
      moving_to_another_assistant: {
        "selected": false,
        "value": "I am going to use another writing assistant",
        "text": "",
        "inputBox": false
      },
      others: {
        "selected": false,
        "value": "Others",
        "text": "",
        "inputBox": true
      }
    };
    this.isDisabled = true;
  }
  validateSelectedOptions()
  {
      var status = Object.keys(this.suggestion).filter(item =>
       {
         if(this.suggestion[item].selected){ 
          return item;
        }
      });
      this.isDisabled = status.length > 0 ? false : true
  }

  asIsOrder() {
    return 1;
  }

  logAmplitudeData(event){
    this.amplitudeService.logAmplitude(event, this.amplitudeLogData);
  }

}
