<div class="word_plugin_body">
    <div class="word_plugin_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="ms_word_section_img">
                        <img src="../../../assets/images/ms-word-img.png" alt="">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="ms_word_section_text">
                        <h2>Trinka for Microsoft Word</h2>
                        <p>The Trinka plug-in for Word makes effective writing easier than ever! Trinka auto-checks your
                            writing and changes as you write within your familiar Word app. </p>
                        <div class="buttons_optinos">
                            <button class="plansButtons disabled">Coming Soon
                            </button>
                        </div>
                        <div class="add_notes">
                            <p>* Supports Word 2007 upwards only on Windows 7 and above.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="ms_process_sections">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="process_line first_chalid_section">
                        <div class="process_inner_section">
                            <img src="../../../assets/images/Download.png" alt="">
                        </div>
                        <div class="process_heading_title">
                            <h3>Download</h3>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="process_line">
                        <div class="process_inner_section">
                            <img src="../../../assets/images/Install_03.png" alt="">
                        </div>
                        <div class="process_heading_title">
                            <h3>Install</h3>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="process_line last_chalid_scetion">
                        <div class="process_inner_section">
                            <img src="../../../assets/images/StartWritingBetter.png" alt="">
                        </div>
                        <div class="process_heading_title">
                            <h3>Start writing better</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="better_writing_sections">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h2>Better writing that comes easy</h2>
                    <p>Trinka is available as a plug-in to the familiar Microsoft Word application that you have always
                        used to write. Get corrections and suggestions as you write. Even better, it automatically
                        checks all your changes so you don’t miss anything!</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="writing_bullet_points">
                        <p>Corrects all errors as you write</p>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="writing_bullet_points">
                        <p>Auto-checks all your changes</p>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="writing_bullet_points">
                        <p>Keeps your data secure</p>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="online_avaiable_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="avaiable_text">
                        <h2>Trinka is also available online!</h2>
                        <p>Trinka Cloud is available anytime on a web browser of your choice. Write online, save your
                            content on the cloud, and get the best writing advice anytime and anywhere you write!</p>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="avaiable_get_started" [routerLink]="['/dashboard']">
                        <div class="icons">
                            <img src="../../../assets/images/Get_stated_online_icons.png" alt="">
                        </div>
                        <div class="get_started_text">
                            <h3>Start writing better now</h3>
                            <p>Log into Trinka Cloud</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>