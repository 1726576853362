import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';
import { CreditDetailsComponent } from '../../credit-details/credit-details.component';


declare function wisepop(): any;

@Component({
  selector: 'app-reference-quality-start',
  templateUrl: './reference-quality-start.component.html',
  styleUrls: ['./reference-quality-start.component.scss']
})

export class ReferenceQualityStartComponent implements OnInit {

  creditsDetails: any;
  userId: any;
  emailId: string;
  userData: any;
  constructor(private sharedService: SharedService,
    private dashboardService: DashboardService,
    private amplitudeService: AmplitudeService,
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit() {
    wisepop();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.emailId = localMeta.sub;
    let data = {
      user_id: this.userId,
      "trinka_callback_url" : window.location.href
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.getCreditsDetails();
  }

  getCreditsDetails() {
    // this.spinner.show();
    this.dashboardService.getStoreUserCreditDetails().subscribe(resp => {
      if(Object.keys(resp).length == 0)
      {
        this.dashboardService.getCreditsDetailsByUser(this.userId).subscribe(
          (response) => {
            if (response["status"]) {
              this.creditsDetails = response.data;
              this.dashboardService.storeUserCreditDetails(this.creditsDetails);
              this.amplitudeService.logAmplitude('RFQ_Tool_pageview',{
                'Credit Balance Free': this.creditsDetails.credits,
                'Credit Balance Purchased': this.creditsDetails.purchased_credits
              });
            }
            // this.spinner.hide();
          },
          (error) => {
            this.sharedService.errorHandller(error);
          }
        );
        }
        else{
          this.creditsDetails = resp;
        }
    });
  }

  addCredits() {
    let redirct_url = environment.payment_fe;
    window.open(redirct_url + "/user/credit/" + this.userData, "_self");
  }

  credits() {
    if (this.creditsDetails != null) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData: this.creditsDetails,
      };

      this.dialog.open(CreditDetailsComponent, dialogConfig);
    }
  }

  startReferenceQuality(){
    this.amplitudeService.logAmplitude('RFQ_GetStarted_CTA',{
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits
    });
    window.open("/citation-check-tool" , "_blank");
  }

  upgradePlanPopup(){
    this.amplitudeService.logAmplitude('RFQ_Upgrade_CTA', {
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits
    });
    let data = {
      "user_id" : this.userId
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url  = environment.payment_fe;
    window.open(redirct_url+'/user/'+this.userData,"_blank") 
  }
  
  openSampleReport(){
    this.amplitudeService.logAmplitude('RFQ_SampleReport_View', {
      'Subscription Type': this.creditsDetails.user_type,
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits
    });
    const link = this.router.serializeUrl(this.router.createUrlTree(['/citation-check-report'], { queryParams: {document_id: environment.citationSampleDocID}}));
    window.open(link, '_blank');
  }

}
