import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-plicy',
  templateUrl: './privacy-plicy.component.html',
  styleUrls: ['./privacy-plicy.component.scss']
})
export class PrivacyPlicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
