<div class="show_folder_popup">
    <div class="showFolderPopupHeader">
        <h2>
            <img src="/assets/images/MyDrive_icons.svg" alt="">
            Trinka My Drive - Select a file to upload</h2>
    </div>
    <div class="folderPathShow">
        <div class="selectPathSection">
            <div class="folder_name_button">
                <button (click)="getAllFolderList()" [disabled]="!filesStatus">My Drive</button>
                <span  *ngIf="filesStatus==true && searchFileWithName==''"> / {{currentfolderName}}</span>
            </div> 
        </div>
        <div class="searchForFile">
            <div class="searchBox_outer">
                <div class="file_search_icon">
                    <img src="/assets/images/search_icon_new.svg" alt="">
                </div>
                <input placeholder="Search" [(ngModel)]="searchFileWithName" (ngModelChange)="searchFiles()">
            </div>
            <!-- <mat-form-field appearance="outline" class="">
                <mat-icon matSuffix>
                    <div class="search_file_icon">
                        <img class="mydrive_searchbox" src="../../../../assets/images/search_icon.svg" />
                    </div>
                </mat-icon>
                <input matInput placeholder="" [(ngModel)]="searchFileWithName" (ngModelChange)="searchFiles()">
                <mat-label>Search</mat-label>
            </mat-form-field> -->
        </div>
    </div>
    <div class="folder_table ">
            <table class="table table-hover"  *ngIf="folderStatus==true">
                <thead>
                    <tr>
                            <th colspan="2">File name</th>
                            <th>Created date</th>
                            <!-- <th>Word Count</th> -->
                    </tr>
                </thead>
                <tbody *ngFor="let folder of folderList" class="folder_list">
                        <tr (click)="getAllFilebyFolderId(folder)">
                            <!-- <td align="left">
                                <img src="../../../assets/images/folderIcon.svg"/>
                            </td> -->
                            <td>
                                <div class="folder-name-div">
                                    <img src="/assets/images/folderIcon.svg"/>
                                    <span class="folder-name">{{folder.folder_name}}</span> 
                                    <span class="folder-name-seperator"></span>
                                    <span class="folder-file-count" *ngIf="folder.file_count>1 || folder.file_count ==0">{{folder.file_count}} files </span>
                                    <span class="folder-file-count" *ngIf="folder.file_count==1 ">{{folder.file_count}} file </span>
                                </div>
                            </td>
                            <td>
                                {{folder.created_at | date:"dd/MMM/yy"}} {{folder.created_at | date:"shortTime"}}
                            </td>
                            <!-- <td>  </td> -->
                        </tr>
                </tbody>
                <tbody *ngFor="let file of fileList" class="folder_file_list file-list-table-body">
                    <tr [class.highlighted]="file.file_id==selectedFileId" (click)="setSelectedFileDetails(file)">
                        <!-- <td align="left">
                            <img src="../../../assets/images/file_icons.png"/>
                        </td> -->
                        <td>
                            <label>
                                <!-- <input class="checkbox" type="checkbox"> -->
                                <span [ngClass]="[file.file_id === selectedFileId ? 'higlighted-checkbox' : '', 'round-checkbox']"></span>
                                <p class="fileNameSection" title="{{file.file_name}}">{{file.file_name}}</p>
                            </label>
                        </td>
                        <td></td>
                        <td> {{file.created_at | date:"dd/MMM/yy"}} {{file.created_at | date:"shortTime"}} </td>
                        <!-- <td> {{file.wordcount}}</td> -->
                    </tr>
                
            </tbody>
            <div class="nofilefolderSerach" *ngIf="folderList?.length==0 && fileList?.length==0">
                <div class="no_files nofileSerached" *ngIf="searchFileWithName==''">
                    <p>No files to select</p>
                </div>
            </div>
        </table>
       
            <table class="table table-hover " *ngIf="filesStatus==true">
                <thead>
                    <tr> 
                        <th colspan="2">File Name</th>
                        <th>Created Date</th>
                        <!-- <th>Word Count</th> -->
                    </tr>
                </thead>
                <tbody class="file-list-table-body" *ngIf="filesStatus==true && dataSource?.length!=0">
                    <tr  *ngFor="let file of dataSource" [class.highlighted]="file.file_id==selectedFileId" (click)="setSelectedFileDetails(file)">
                        <!-- <td align="right" style="width: 60px;">
                            <img src="../../../assets/images/file_icons.png"/>
                        </td> -->
                        <td>
                            <label>
                                <!-- <input class="checkbox" type="checkbox"> -->
                                <span [ngClass]="[file.file_id === selectedFileId ? 'higlighted-checkbox' : '', 'round-checkbox']"></span>
                                <p class="fileNameSection" title="{{file.file_name}}">{{file.file_name}}</p>
                            </label>
                        </td>
                        <td></td>
                        <td> {{file.created_at | date:"dd/MMM/yy"}} {{file.created_at | date:"shortTime"}} </td>
                        <!-- <td>{{file.wordcount}}</td>                         -->
                    </tr>
            </tbody>
            <div class="nofilefolderSerach" *ngIf="filesStatus==true && dataSource?.length==0">
                <div class="no_files nofileSerached" *ngIf="searchFileWithName==''">
                    <p>No files in this folder</p>
                </div>
                <div class="no_files nofileSerached" *ngIf="searchFileWithName!=''">
                    <p>No files found for your search</p>
                </div>
            </div>
            </table>
            
    </div>

<div class="selectedFileFooter">
    <button mat-button class="CancelButtonFileUpload">
        <span mat-dialog-close> Cancel</span>
    </button>
    <button class="selected_file_button" (click)="uploadFile()" mat-button [disabled]="selectedFileName==''">
        Select File
    </button>
</div>
</div>