<div class="container h-100 language-pre mat-typography-config p-4 add_dictionary_popup changes_profile_popup"
  fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap.xs="0" fxLayout="row">
  <div class="row">
    <div class="col-sm-12">
      <div class="popup_heading">
        <h2>Download File (docx)</h2>
        <div class="heading_subtitle">
          <p>Select the type of file you would like to download.</p>
        </div>
        <button class="cancelbuttons" mat-button mat-dialog-close>
          <img src="../../../assets/images/closeIcons.png" alt="">
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="track_downloadButtons">
        <button (click)="download(false)">Clean final file</button>
        <div class="file_descriptions_text">
          <p>(You will receive a file as you see on Trinka Cloud as of now. A version history of the changes done will not be visible in this file.)</p>
        </div>
        
      </div>
    </div>
    <div class="col-md-6">
      <div class="track_downloadButtons">
        <button (click)="download(true)">File with track changes</button>
        <div class="file_descriptions_text">
          <p>(All your changes done on Trinka Cloud will be visible using the track changes feature of MS Word.)</p>
        </div>
      </div>
    </div>
  </div>
