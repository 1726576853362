<div class="compare_outer_sections">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="inside-page">
          <h2 class="page-title">Trinka provides the best writing advice</h2>
          <p class="font-18">From grammar to phrasing and word choice, Trinka’s finest language enhancements help you present your writing at its best.</p>
          <div class="results">
            <h3 class="sub-title">Advanced grammar & spelling checks for error-free writing</h3>
            <div class="slider-box">
              <div id="grammar-spellings" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="row">
                      <div class="col-md-3">
                        <h4 class="purple-color">Syntax</h4>
                        <div class="or-spacer-vertical left">
                          <div class="mask"></div>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <p class="font-18">
                          The right punctuation at the right place makes your writing readable. Trinka corrects punctuation errors typical to academic and technical writing.
                        </p>
                      </div>
                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                    <div class="row pt-4 three-parts">

                      <div class="col-md-6">
                        <div class="three-parts-inner">
                          <h5 class="text-center">Trinka</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                        <div class="or-spacer-vertical1 left">
                          <div class="mask"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="three-parts-inner">
                          <h5 class="text-center">MS Word</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row">
                      <div class="col-md-3">
                        <h4 class="purple-color">Prepositions</h4>
                        <div class="or-spacer-vertical left">
                          <div class="mask"></div>
                        </div>
                      </div>

                      <div class="col-md-9">
                        <p class="font-18">
                          Trinka is your personal AI Editor for all your academic writing needs. Trinka is your personal
                          AI Editor.
                        </p>
                      </div>
                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                    <div class="row pt-4 three-parts">
                      <div class="col-md-6">
                        <div class="three-parts-inner">
                          <h5 class="text-center">Trinka</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                        <div class="or-spacer-vertical1 left">
                          <div class="mask"></div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="three-parts-inner">
                          <h5 class="text-center">MS Word</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row">
                      <div class="col-md-3">
                        <h4 class="purple-color">Verb form</h4>
                        <div class="or-spacer-vertical left">
                          <div class="mask"></div>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <p class="font-18">
                          Trinka is your personal AI Editor for all your academic writing needs. Trinka is your personal
                          AI Editor.
                        </p>
                      </div>
                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                    <div class="row pt-4 three-parts">
                      <div class="col-md-6">
                        <div class="three-parts-inner">
                          <h5 class="text-center">Trinka</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                        <div class="or-spacer-vertical1 left">
                          <div class="mask"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="three-parts-inner">
                          <h5 class="text-center">MS Word</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <a class="carousel-control-prev" href="#grammar-spellings" data-slide="prev">
                  <span class="carousel-control-prev-icon align-self-start"></span>
                </a>
                <a class="carousel-control-next" href="#grammar-spellings" data-slide="next">
                  <span class="carousel-control-next-icon align-self-start"></span>
                </a>

              </div>
            </div>
          </div>
          <div class="results">
            <h3 class="sub-title">Enhancements beyond grammar</h3>
            <div class="slider-box">
              <div id="beyond-grammar" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="row">
                      <div class="col-md-3">
                        <h4 class="purple-color">Punctuation</h4>
                        <div class="or-spacer-vertical left">
                          <div class="mask"></div>
                        </div>
                      </div>

                      <div class="col-md-9">
                        <p class="font-18">
                          Trinka is your personal AI Editor for all your academic writing needs. Trinka is your personal
                          AI Editor.
                        </p>
                      </div>
                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                    <div class="row pt-4 three-parts">
                      <div class="col-md-4">
                        <div class="three-parts-inner">
                          <h5 class="text-center">Trinka</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                        <div class="or-spacer-vertical1 left">
                          <div class="mask"></div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="three-parts-inner">
                          <h5 class="text-center">Grammarly</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                        <div class="or-spacer-vertical1 left">
                          <div class="mask"></div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="three-parts-inner">
                          <h5 class="text-center">MS Word</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row">
                      <div class="col-md-3">
                        <h4 class="purple-color">Punctuation</h4>
                        <div class="or-spacer-vertical left">
                          <div class="mask"></div>
                        </div>
                      </div>

                      <div class="col-md-9">
                        <p class="font-18">
                          Trinka is your personal AI Editor for all your academic writing needs. Trinka is your personal
                          AI Editor.
                        </p>
                      </div>
                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                    <div class="row pt-4 three-parts">
                      <div class="col-md-4">
                        <div class="three-parts-inner">
                          <h5 class="text-center">Trinka</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                        <div class="or-spacer-vertical1 left">
                          <div class="mask"></div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="three-parts-inner">
                          <h5 class="text-center">Grammarly</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                        <div class="or-spacer-vertical1 left">
                          <div class="mask"></div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="three-parts-inner">
                          <h5 class="text-center">MS Word</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row">
                      <div class="col-md-3">
                        <h4 class="purple-color">Punctuation</h4>
                        <div class="or-spacer-vertical left">
                          <div class="mask"></div>
                        </div>
                      </div>

                      <div class="col-md-9">
                        <p class="font-18">
                          Trinka is your personal AI Editor for all your academic writing needs. Trinka is your personal
                          AI Editor.
                        </p>
                      </div>
                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                    <div class="row pt-4 three-parts">
                      <div class="col-md-4">
                        <div class="three-parts-inner">
                          <h5 class="text-center">Trinka</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                        <div class="or-spacer-vertical1 left">
                          <div class="mask"></div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="three-parts-inner">
                          <h5 class="text-center">Trinka</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                        <div class="or-spacer-vertical1 left">
                          <div class="mask"></div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="three-parts-inner">
                          <h5 class="text-center">MS Word</h5>
                          <p><span class="purple-text"><u>Trinka</u></span> is your personal <span
                              class="purple-text"><u>AI editor</u></span> for all your academic writing needs. <span
                              class="purple-text"><u>Trinka</u></span> is your personal AI editor for all your academic
                            writing needs.</p>
                        </div>
                        <div class="or-spacer-vertical1 left">
                          <div class="mask"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <a class="carousel-control-prev" href="#beyond-grammar" data-slide="prev">
                  <span class="carousel-control-prev-icon align-self-start"></span>
                </a>
                <a class="carousel-control-next" href="#beyond-grammar" data-slide="next">
                  <span class="carousel-control-next-icon align-self-start"></span>
                </a>

              </div>
            </div>
          </div>

          <div class="makes_Trinka_better_sections">
          <div class="row">
            <div class="col-md-6">
              <h1 class="page-title">What makes Trinka better</h1>
              <ul class="trinka-better">
                <li>Made by expert academic editors for researchers</li>
                <li>Trinka has analysed well written academic papers and guides you with its learning in an easy to
                  understand manner.</li>
                <li>Enhancements that make your writing better, not just grammatically correct.</li>
              </ul>
            </div>
            <div class="col-md-6 mt-3">
              <img src="../../../assets/images/graphics.png" alt="graphics" class="img-fluid">
            </div>
          </div>
        </div>

        </div>

      </div>
    </div>
  </div>
</div>