<div class="upgradePremiumSection" [ngClass]="!isPremium? 'LimitExhaustedForBasicUser' : ''">
   <div class="upgradeHeaderSection">
      <div class="upgradeHeaderSection_overlay"></div>
      <button class="closedButton" mat-button mat-dialog-close style="right: -10px;" (click)="cancelUpgrade()">
         <img src="../../../../assets/images/closed_white_icon.svg" alt="">
      </button>
      <h1 *ngIf="!isPremium">Your free usage limit is exhausted!</h1>
      <h1 *ngIf="isPremium" style="margin-bottom: 15px;">It looks like you have reached your monthly fair usage limit!</h1>
   </div>
   <div class="upgradeBodySection" *ngIf="!isPremium">
      <h2>Enjoy Full Access With Premium</h2>
      <ul>
         <li><img src="../../../../assets/images/Premium_check_icons.svg" alt="">
            <span>Enjoy unrestricted usage for all your writing needs.</span>
         </li>
         <li><img src="../../../../assets/images/Premium_check_icons.svg" alt=""> 
            <span>Get access to Trinka's powerful MS Word add-ins (Windows and Mac).</span></li>
         <li><img src="../../../../assets/images/Premium_check_icons.svg" alt=""> 
            <span>Get 5 credits monthly for file proofreading (Auto File Edit) and other reports.</span></li>
         <li><img src="../../../../assets/images/Premium_check_icons.svg" alt=""> 
            <span>Avail discounted rates when purchasing extra credits.</span></li>
         <li><img src="../../../../assets/images/Premium_check_icons.svg" alt=""> 
            <span>Be among the first to experience our exciting new features.</span></li>
      </ul>
      <div class="goupgradeButton">
         <button type="submit" mat-raised-button color="primary" (click)="upgradeToPremium()">Buy Premium<img
               src="../../../../assets/images/GetPremiumNow_icon.svg" alt=""></button>
      </div>
   </div>
   <div class="upgradeBodySection" *ngIf="isPremium">
      <div class="goupgradeButton">
         <p>In the last 30 days, you have written more than 150,000 words using Trinka. We are excited to see that you are making excellent progress toward your writing goals. However, please note that our fair usage policy allows for a maximum of 150,000 words in a 30-day rolling period. We estimate that this limit is more than sufficient for an individual's writing needs. We kindly request that you review our <strong style="color: #4d4d4d;font-weight: 600;"><a  style="color: #7a28a0;text-decoration: underline;" href="https://www.trinka.ai/termsofservices" target="_blank">Terms of Service</a></strong> for a better understanding of this policy.</p>
         <p>Furthermore, considering your usage, you may need to consider our Enterprise plan. Please feel free to contact us at <strong><a style="color: #7a28a0; font-weight: 600;" href="mailto:support@trinka.ai">support@trinka.ai</a></strong>, and we will work together to find a solution to accommodate your needs.</p>
         <button type="submit" mat-raised-button color="primary" (click)="cancelUpgrade()">Okay</button>
      </div>
   </div>
</div>