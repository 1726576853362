<div class="publicationSubSection">
<mat-card class="reference_checks_section">
    <div class="reference_card" *ngIf="referenceCheck?.is_present">
            <div class="no_of_checks">
                 <p>
                     <span>Check 1</span> References 
                     <i class="old_reference_info" alt="Shows the detected end-list references">
                        <img src="../../../../assets/images/Icon_Info.svg" alt="">
                     </i> 
                </p>
            </div>
           <div class="reference_checks_body">
                <p class="reference_founds"><span>{{referenceCheck?.count}}</span> reference(s) found</p>
                <p class="old_reference_found" style="margin-bottom: 10px;" *ngIf="referenceCheck?.obsolete_refs>0">Old References: <b>{{referenceCheck?.obsolete_refs}}</b> 
                    <span class="old_reference_info" alt="Shows the references that were published more than 10 years ago">
                        <img src="../../../../assets/images/Icon_Info.svg" alt="">
                    </span> 
                </p>
                <div class="viewReferences_button" (click)="viewReferences(referenceCheck?.individual_references)">
                    View References <span><img src="../../../../assets/images/Icon_eye.svg" alt=""></span>
                </div>
                <p class="note_text">Check whether each reference meets the style requirement of the journal.</p>    
            </div>
    </div>

    <div class="reference_card" *ngIf="!referenceCheck?.is_present">
        <div class="no_of_checks">
            <p>
                <span>Check 1</span> References 
                <i class="old_reference_info" alt="Shows the detected end-list references">
                   <img src="../../../../assets/images/Icon_Info.svg" alt="">
                </i> 
           </p>
        </div>
        <div class="reference_checks_body not_found_reference">
            <p class="reference_founds">No reference(s) found.</p>
            <p class="note_text">Please check whether you need to add a reference section to your manuscript</p>
        </div>
    </div>
</mat-card>
</div>