import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/_services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/networkcalls/website.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;

  @HostListener('input') oninput() {

    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
      }
    }
  constructor(private fb: FormBuilder,
    private networkCall: WebsiteService, 
    private toastr: ToastrService,
    private shareService: SharedService) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.email])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormMessage': ['', Validators.required]
      // 'contactFormCopy': [''],
    });
  }

  ngOnInit() {
  }
  processForm() {
    if(this.contactForm.value.contactFormEmail && this.contactForm.value.contactFormName && this.contactForm.value.contactFormSubjects && this.contactForm.value.contactFormMessage){
      var query = this.contactForm.value.contactFormName +"||"+ this.contactForm.value.contactFormSubjects +"||"+ this.contactForm.value.contactFormMessage
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail,query).subscribe(result=>{
        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
      },error=>{
        this.shareService.errorHandller(error);
      })
    } else {
      this.toastr.warning("Please fill in the necessary details on the form.")
    }
    // const allInfo = `My name is ${this.name}. My email is ${this.email}. My message is ${this.message}`;
    // alert(allInfo);
  }
}
