import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebsiteService } from 'src/app/_services/networkcalls/website.service';
@Component({
  selector: 'app-upgrade-id-page',
  templateUrl: './upgrade-id-page.component.html',
  styleUrls: ['./upgrade-id-page.component.scss']
})
export class UpgradeIdPageComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private networkCall: WebsiteService, 
  ) { }

  emailId: string = '';
  loadingStateComplete: boolean = true;
  showErrorState: boolean = false;
  token: string = "";
  isExistingUser: boolean = false;

  ngOnInit() {
    this.token = this.route.snapshot.params.emailVerificationId;
    this.apiCall();
  }

  apiCall() {
    //Main logic to be added here
    this.networkCall.upgradeUGRUser(this.token).subscribe(result => {
      console.log('result is: ', result);
      this.loadingStateComplete = false;
      this.emailId = result.data;
      this.isExistingUser = result.message === 'User is already premium';
    }, error => {
      console.log('error is: ', error);
      this.showErrorState = true;
      this.loadingStateComplete = false;
    })
  }

  redirectToSignInPage() {
    setTimeout(() => {
      window.location.href = window.location.origin + "/signin";
    }, 250)
  }

}
