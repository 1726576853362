import { NgModule } from '@angular/core';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
import { PlagiarismCheckerComponent } from './plagiarism-checker.component';
import { RouterModule, Routes } from '@angular/router';
import { PlagReportViewerComponent } from 'src/app/components/plagiarism-check/plag-report-viewer/plag-report-viewer.component';
import { PlagCheckToolPageComponent } from 'src/app/components/plagiarism-check/plag-check-tool-page/plag-check-tool-page.component';

const routes: Routes = [
   { path: '', redirectTo: 'upload', pathMatch:'full' },
   { path: 'upload',pathMatch:'full',component: PlagiarismCheckerComponent, canActivate: [AuthGuardService] },
   { path: 'report/:plagType/:fileName/:reportUrl', component:PlagReportViewerComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlagiarismCheckRoutingModule { }
