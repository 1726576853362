import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'editorAlertSort'
})
export class EditorAlertSortPipe implements PipeTransform {

  transform(value: any, document_type: any): any {

    if(document_type === 4) {
      return value.sort((a, b) => a.order.legal - b.order.legal);
    } else {
      return value.sort((a, b) => a.order.regular - b.order.regular);
    }
  }
}
