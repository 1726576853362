<div
  class="container h-100 language-pre mat-typography-config p-4 add_dictionary_popup changes_profile_popup document_popup_page"
  fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap.xs="0" fxLayout="row">
  <div class="row">
    <div class="col-sm-12">
      <div class="popup_heading">
        <h2>Academic Document Settings</h2>
        <p>Trinka understands the subtle differences between academic and general writing and aims to give you the most
          relevant suggestions. Customize Trinka to your subject area and type of manuscript by selecting the relevant
          labels.</p>
        <button class="cancelbuttons" mat-button mat-dialog-close [disabled]="showSpinner">
          <img src="../../../assets/images/closeIcons.png" alt="">
        </button>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="select_paper_sections">
        <h4>Select paper type</h4>

        <ng-container>
          <mat-chip-list multiple [selectable]="false">
            <mat-chip *ngFor="let paperTypeObject of paperType; let i=index" [selected]="paperTypeObject.isSelected"
              [value]="paperTypeObject.description" (click)="togglePaperChip(paperTypeObject)">
              {{paperTypeObject.description}}
            </mat-chip>
          </mat-chip-list>
        </ng-container>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="subject_area_outer">
        <h4>Select or type subject area
          <div class="type_subject_checking" *ngIf="subAreaLoaded  && subjectType.length<=0">
            <div class='checkingloader'>
              <div class='loader--text'></div>
              <div class='loader--dot'></div>
              <div class='loader--dot'></div>
              <div class='loader--dot'></div>
              <p>Checking</p>
            </div>
          </div>
          <div class="type_subject_checking" *ngIf="subAreaLoaded && subjectType.length>0">
            <div class='checkingloader'>
              <div class='loader--text'></div>
              <div class='loader--dot'></div>
              <div class='loader--dot'></div>
              <div class='loader--dot'></div>
              <p>Rechecking</p>
            </div>
          </div>
        </h4>
        <form [formGroup]="documentForm" (ngSubmit)="submitedSubjectArea(documentForm.value)" class="form">
          <div class="place_on_order_section">
            <div class="subject_area_section">
              <input id="typeahead-config" type="text" class="form-control" name="subjectarea"
                (selectItem)="onSubjectAreaCapture($event)" formControlName="subjectArea"
                placeholder="Type your subject area" (ngModelChange)="onTypeSubjectArea()" [ngbTypeahead]="search" />

              <div class="icon-container">
                <i [ngClass]="suggestedSAlist?'loader':''"></i>
              </div>
              <div class="error_msg">
                <label for="file_title"
                  *ngIf="(!documentForm.get('subjectArea').valid && documentForm.get('subjectArea').dirty) || (documentForm.get('subjectArea').touched && documentForm.get('subjectArea').pristine)">
                </label>
              </div>
            </div>
          </div>
          <div class="subject_area_sections">
            <mat-chip-list multiple [selectable]="false" *ngIf="subjectType.length>0">
              <mat-chip *ngFor="let chip of subjectType; let i=index" [selected]="chip.isSelected"
                (click)="toggleChip(chip)" [value]="chip.label">
                {{chip.label}} 
              </mat-chip>
            </mat-chip-list>
            <p *ngIf="subjectType.length<=0 && subAreaLoaded">We are currently determining relevant subject areas based
              on your text. You will be able to select a subject area from the list shown or type a subject area at any
              time.</p>
            <p *ngIf="subjectType.length<=0 && !subAreaLoaded">We could not determine the subject area. Please type it
              above.</p>
          </div>

          <div class="document_footer_section">
            <button type="button" class="save" (click)="onFormSubmit(documentForm.value)" [disabled]="showSpinner">Save
              <i class="fa fa-spinner fa-spin" style="font-size:24px" *ngIf="showSpinner"></i>
            </button>
            <button type="button" class="closeButton" mat-button mat-dialog-close [disabled]="showSpinner">Close</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>