<div class="CreditsPopupSection">
    <div class="CreditsHeader">
        <h2>Your Balance - <span>{{creditsDetails.purchased_credits + creditsDetails.credits}}</span> Credits</h2>
        <button mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>
    <div class="creditBodySection">
        <div class="FreeCreditsSections">
            <span>
                Free Credits
                <b class="FreeCreditTooltip" alt="These credits are renewed every month and are always used first."><img src="../../../../assets/images/Icon_Info.svg" alt=""></b>
            </span>
            <p>{{creditsDetails.credits}}</p>
            <i>Your free credits will reset on<br /> {{creditsDetails.next_renewal_date}}   </i>
        </div>

        <div class="FreeCreditsSections">
            <span>Purchased Credits
                <b alt="Purchased credits are used after your free credits are exhausted and never expire."><img src="../../../../assets/images/Icon_Info.svg" alt=""></b>
            </span>
            <p>{{creditsDetails.purchased_credits}}</p> 
            <i>Never expires</i>
        </div>

        <!-- <div *ngIf = "creditsDetails.user_type === 'BASIC' && creditsDetails.purchased_credits == 0" class="FreeCreditsSections">
            <span>Purchased Credits
                <b alt="Purchased credits are used after your free credits are exhausted and never expire."><img src="../../../../assets/images/Icon_Info.svg" alt=""></b>
            </span>
            <span class="purchesText">Purchased Credits are only available to Premium</span>

            <button (click)="upgradeToPremium()">Upgrade to Premium</button>
            <a style="cursor: pointer;" (click)="viewBenefits()">View all benefits</a>
        </div> -->
        <!-- <div *ngIf = "creditsDetails.user_type === 'BASIC'" class="FreeCreditsSections">
            
        </div> -->
    </div>


    <div class="addCreditSection">
        <!-- <span>Use these credits for both Auto File Edit and Publication Checks.</span> -->
        <button (click)="addCredit()" >Add Credits</button>
    </div>

</div>