export class FileObject {

    "status": boolean;
    "message": string;
    "data": {
        "content": string
        "file_id": string
        "file_name": string
        "language": string
        "snippet": string
        "style_guide": string
        "document_type": any
        "selected_subject_area": string
        "paper_type": any
        "added_subject_area": string,
        "subject_area_list": any[],
        "powermode_status": boolean
        "skip_status": boolean,
        "lang_code": string,
        "module": string,
        "instruction": string
    }
    constructor() {
        var temp = {
            "content": "",
            "file_id": "",
            "file_name": "",
            "language": "",
            "snippet": "",
            "style_guide": "",
            "document_type": 0,
            "selected_subject_area": "",
            "paper_type": "",
            "added_subject_area": "",
            "subject_area_list": [],
            "powermode_status": false,
            "skip_status": false,
            "lang_code": "en",
            "module": "advanced",
            "instruction": ""
        }
        this.status = false;
        this.message = "";
        this.data = temp
    }
}
