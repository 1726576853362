import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-editor-advance-plag-check-credit-modal',
  templateUrl: './editor-advance-plag-check-credit-modal.component.html',
  styleUrls: ['./editor-advance-plag-check-credit-modal.component.scss']
})
export class EditorAdvancePlagCheckCreditModalComponent implements OnInit {

  creditDetails : any;
  isCreditBased: boolean = true;
  userData: any;
  userId: any;

  totalCredits: number ;
  constructor(private dialogRef: MatDialogRef<EditorAdvancePlagCheckCreditModalComponent>,
    public amplitudeService: AmplitudeService,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) data) {
      this.creditDetails = data['creditDetails'];
      this.totalCredits = this.creditDetails['purchased_credits'] + this.creditDetails['credits'] ;
      this.isCreditBased = data['isCreditBased'];
     }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
  }

  submit(){
    this.dialogRef.close(1);
  }

  close_modal(){
    this.dialogRef.close(0);
  }


  upgradePlanPopup() {
    let data = {
      "user_id" : btoa(this.creditDetails['email_id']),
      "plan_name": this.uppercaseFirstLetter(this.creditDetails['user_type']).toLowerCase(),
      "from":"Trinka"
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url = environment.payment_fe;
    this.amplitudeService.logAmplitude('ED_Plag_check_upgrdae_clicked', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditDetails.user_type),
      'user_id': this.userId,
      "credit_balance_free": this.creditDetails.credits,
      "credit_balance_purchased": this.creditDetails.purchased_credits
    });
    window.open(redirct_url + '/user/' + this.userData, "_blank")
  }

  uppercaseFirstLetter(phrase):string{
    return phrase
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }

  addCredits() {
    let redirct_url  = environment.payment_fe;
    let data = {
      "user_id" : btoa(this.creditDetails['email_id']),
      "is_premium" : this.creditDetails['user_type'] == 'PREMIUM' || this.creditDetails['user_type'] == 'PREMIUM_PLUS' ? true : false,
      "trinka_callback_url" : window.location.href
    };
    this.amplitudeService.logAmplitude('ED_Plagcheck_buy_credits', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditDetails.user_type),
      'user_id': this.userId,
      "credit_balance_free": this.creditDetails.credits,
      "credit_balance_purchased": this.creditDetails.purchased_credits
    });
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url+'/user/credit/'+this.userData,"_self");
  }  

}
