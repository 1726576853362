import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material'
import { Router } from '@angular/router'
import { FileUploadService } from 'src/app/_services/file-upload.service'
import { SharedService } from 'src/app/_services/shared.service'
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service'
import { AmplitudeService } from 'src/app/_services/amplitude.service'

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss']
})
export class DeleteConfirmationModalComponent implements OnInit {
  isFileDeleted: boolean = false; 
  file: any = {};
  @Output() removeFileFromList = new EventEmitter<boolean>();

  constructor (
    public dialogRef: MatDialogRef<DeleteConfirmationModalComponent>,
    private router: Router,
    private _fileUploadService: FileUploadService,
    private _snackBar: MatSnackBar,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private networkCall: DashboardService,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit() {
    if(this.data && this.data.file) this.file = this.data.file;
  }

  onCloseClick (): void {
    this.dialogRef.close();
    this.amplitudeService.logAmplitude('AFE_cancel_delete', {
      'file_name': this.file.id ? this.file.filename : this._fileUploadService.currentFileInfo.apiResponse.filename,
      'type': this.file.id ? 'report' : 'document'
    });
  }
  onCloseClickAndRedirect (): void {
    
    setTimeout(() => {
      this.dialogRef.close();
      this._fileUploadService.resetFileDetails();
      this.router.navigate(['/dashboard/auto-edit'])
    }, 2000)
  }

  deletepopupButton() {
    let fileIdToBeDeleted = this.file.id ? this.file.id : this._fileUploadService.currentFileInfo.apiResponse.id;
    this._fileUploadService
      .deleteFileByAutoEditId(fileIdToBeDeleted)
      .subscribe(
        res => {
          this.isFileDeleted = true;
          // TODO: Commented as per disucssion with Design team
          /*let messageToBeShown = '';
          if (this.file && this.file.filename) {
            messageToBeShown = `${this.file.filename} is deleted`;
          } else {
            messageToBeShown = this._fileUploadService.currentFileInfo.isCloudFile ? `${this._fileUploadService.currentFileInfo.apiResponse.filename} is deleted` : `${this._fileUploadService.currentFileInfo.file.name} is deleted`;
          }
          this._snackBar.open(messageToBeShown, '', {
            duration: 10000,
            horizontalPosition: 'right'
          })*/
          this.amplitudeService.logAmplitude('edit_delete',{
            'fileID': this.file.id ? this.file.id : this._fileUploadService.currentFileInfo.apiResponse.id,
            'file_name': this.file.id ? this.file.filename : this._fileUploadService.currentFileInfo.apiResponse.filename,
            'status': 'success',
            'type': this.file.id ? 'report' : 'document'
          });
          this.networkCall.setDashboardLocalFileDetails(null);
          this.networkCall.setDashboardCloudFileDetails(null);
        },
        error => {
          this.amplitudeService.logAmplitude('edit_delete',{
            'fileID': this.file.id ? this.file.id : this._fileUploadService.currentFileInfo.apiResponse.id,
            'file_name': this.file.id ? this.file.filename : this._fileUploadService.currentFileInfo.apiResponse.filename,
            'status': 'failed',
            'type': this.file.id ? 'report' : 'document'
            });
          this.sharedService.errorHandller(error);
        },
        () => {
          this.removeFileFromList.emit(true);
          this.onCloseClickAndRedirect();
        }
      )
  }
}
