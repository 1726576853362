// tawk.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TawkService {
  constructor() { }

  showWidget(): void {
    window['Tawk_API'].showWidget();
  }

  hideWidget(): void {
    if(window['Tawk_API'].isChatMaximized()) {
      window['Tawk_API'].minimize();
    }
    window['Tawk_API'].hideWidget();
  }

  waitTillTawkIsLoaded() {
    let attemptCounter = 0;
    return new Promise((resolve, reject) => {
      const check = () => {
        if (window['Tawk_API'] && window['Tawk_API'].showWidget && window['Tawk_API'].hideWidget) {
          resolve(true);
        } else {
          setTimeout(check, 1000);
        }

        attemptCounter++;
        if(attemptCounter > 500) {
          reject();
        }
      };
      check();
    });
  }
}
