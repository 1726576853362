<div class="outer_login_wrap_section">
    <div class="login_left_side">
        <div class="left_login_overlay"></div>
        <div class="login_content">
            <div class="top_logo">
                <a href="https://trinka.ai" target="_blank">
                    <img src="/assets/images/trinka-logo-login.png" alt="">
                </a>
            </div>
            <div class="login_text_contents">
                <h2>Help us improve</h2>
                <p style="max-width: 450px;">As you are starting your journey with Trinka, please register or login to access a range of unique features.</p>
            </div>
            <div class="login_footer">
                <ul>
                    <li><a href="https://www.trinka.ai/privacypolicy" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://www.trinka.ai/termsofservices" target="_blank">Terms of Services</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="login_right_side browserInstall_page">
        <div class="login_body_section">
            <div class="login_changeLogs installPage_bellIcon">
                <a *ngIf="assetType == 'ext_firefox'" href="https://addons.mozilla.org/en-US/firefox/addon/trinka-ai-firefox-browser/" target="_blank" class="reinstall_extension">Reinstall extension</a>
                <a *ngIf="assetType == 'ext_edge'" href="https://microsoftedge.microsoft.com/addons/detail/trinka-ai-for-edge/kjhkcgjfbpppkbhhaocagkmfkkiibeap" target="_blank" class="reinstall_extension">Reinstall extension</a>
                <a *ngIf="assetType == 'ext_chrome'" href="https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp?hl=en-GB" target="_blank" class="reinstall_extension">Reinstall extension</a>
            </div>
            <div class="uninstall_by_mistake">
                <h2> We're sorry to see you go!</h2>

            </div>
            <div class="feedback_forms">
                <p style="margin-bottom: 25px;">If you have a moment, please tell us why you chose to uninstall the Trinka browser extension.</p>
                <ul>
                    <li>
                        <mat-checkbox checked id="incorrect_suggestions" [(ngModel)]="feedbackDataObject.incorrect_suggestions.selected" (change)="validateSelectedOptions()">
                            Suggested corrections are wrong/Doesn't provide corrections
                        </mat-checkbox>
                    </li>
                    <li>
                        <mat-checkbox checked id="favorite_platform" [(ngModel)]="feedbackDataObject.favorite_platform.selected" (change)="validateSelectedOptions()">
                            Doesn't work on my favorite platform
                        </mat-checkbox>
                    </li>
                    <li *ngIf="feedbackDataObject.favorite_platform.selected">
                        <textarea name="" id="" [(ngModel)]="feedbackDataObject.favorite_platform.text" placeholder="Please share the platfrom name"></textarea>
                    </li>
                    <li>
                        <mat-checkbox checked id="favorite_websites" [(ngModel)]="feedbackDataObject.favorite_websites.selected" (change)="validateSelectedOptions()">
                            Doesn't work on my favorite websites
                        </mat-checkbox>
                    </li>
                    <li *ngIf="feedbackDataObject.favorite_websites.selected">
                        <textarea name="" id="" [(ngModel)]="feedbackDataObject.favorite_websites.text" placeholder="Please share website names / URLs"></textarea>
                    </li>
                    <li>
                        <mat-checkbox checked id="no_signup" [(ngModel)]="feedbackDataObject.no_signup.selected" (change)="validateSelectedOptions()">
                            Don't want to sign up or create an account
                        </mat-checkbox>
                    </li>
                    <li>
                        <mat-checkbox checked id="others" [(ngModel)]="feedbackDataObject.others.selected" (change)="validateSelectedOptions()">
                            Others
                        </mat-checkbox>
                    </li>
                    <li *ngIf="feedbackDataObject.others.selected">
                        <textarea name="" id="" [(ngModel)]="feedbackDataObject.others.text" placeholder="How can we make Trinka better?"></textarea>
                    </li>
                </ul>
            </div>
            <div class="feedback_submit_buttons">
                <button (click)="submitFeedback()" [disabled]="isDisabled">Submit</button>
                
            </div>
        </div>
    </div>
</div>