import { Component, OnInit } from '@angular/core';
import { WebsiteService } from 'src/app/_services/networkcalls/website.service';
import { SharedService } from 'src/app/_services/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {
  email: string;
  constructor(private networkCall: WebsiteService,
    private toastr: ToastrService,
    private shareService: SharedService, ) { }

  ngOnInit() {
    
  }
  onActivate(event) {
    window.scroll(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }

  subscribeNow() {
    if (this.email) {

      this.networkCall.postSubscribe(this.email).subscribe(result => {
        this.toastr.success("Subscription successful.")
        this.email = "";
      }, error => {
        this.shareService.errorHandller(error);
      })
    } else {
      this.toastr.warning("Please enter the email address.")
    }
  }
}
