<div class="file-upload-loader-wrapper" *ngIf="currentFileInfo && (currentFileInfo.file || (currentFileInfo.cloudFileDetails && currentFileInfo.cloudFileDetails.fileName))" [ngStyle]="{'display': _fileUploadService.currentFileInfo.progress > 0 ? 'block' : 'none' }">
  <div class="container" *ngIf="currentFileInfo.progress < 100; else uploadCompleted">

    <div class="fileUploadSection">
      <span class="file-icon-outer">
        <span *ngIf="currentFileInfo?.fileType !== 'tex'" class="afe-icon-fileUpload"></span>
        <span *ngIf="currentFileInfo?.fileType === 'tex'" class="afe-icon-fileUpload-tex"></span>
      </span>
      <div class="fileNameSection">
        <h2>{{ (currentFileInfo.file && currentFileInfo.file.name) || (currentFileInfo.cloudFileDetails && currentFileInfo.cloudFileDetails.fileName) }}</h2>
      </div>
    </div>
    <div class="upload-progress-bar-txt-outer">
      <p>Uploading file...</p>
      <div class="upload-progress-bar-inner">
        <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="70" aria-valuemin="0"
          aria-valuemax="100">
          <div class="progress-bar" [ngStyle]="{ width: currentFileInfo.progress + '%' }"></div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #uploadCompleted>
    <div class="container">
      <div class="upload-success-msg" *ngIf="showProgressState; else showProofReadOptionDiv">
        <div class="SVGwrapper">
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </div>
        <p>File uploaded successfully!</p>
      </div>
      <ng-template #showProofReadOptionDiv>
        <div *ngIf="currentFileInfo.apiResponse && currentFileInfo.apiResponse.status !== 'OVER_LIMIT'; else showSizeLimitExceededDiv" class="proof-read-file-outer">
          <div class="fileUploadSection">
            <span class="file-icon-outer">
              <span *ngIf="currentFileInfo?.fileType !== 'tex'" class="afe-icon-fileUpload"></span>
              <span *ngIf="currentFileInfo?.fileType === 'tex'" class="afe-icon-fileUpload-tex"></span>
            </span>
            <div class="fileNameSection">
              <h2>{{ (currentFileInfo.file && currentFileInfo.file.name) || (currentFileInfo.cloudFileDetails && currentFileInfo.cloudFileDetails.fileName) }}</h2>
              <div class="fileDetailsSection">
                <span class="fileWordCount">{{currentFileInfo.apiResponse.word_count}} words</span>

                <span class="editingmodeKnow" (click)="openAdvanceSettingsModal('editingMode')" *ngIf="currentFileInfo.advanceSettings.langCode === 'en'">
                  <span class="knowIsPowerMode" *ngIf="currentFileInfo.advanceSettings.power_mode === true">
                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.01611 0.29167C2.04048 0.207442 2.09107 0.133501 2.16029 0.0809202C2.22951 0.0283393 2.31364 -5.13359e-05 2.40008 6.96853e-08H5.59988C5.66322 4.89044e-05 5.72565 0.0153765 5.78201 0.0447209C5.83838 0.0740654 5.88708 0.116588 5.92411 0.168787C5.96113 0.220986 5.98542 0.28137 5.99497 0.344967C6.00453 0.408565 5.99907 0.473557 5.97905 0.534593L4.55514 4.87471H7.59975C7.67465 4.87466 7.74806 4.89597 7.81162 4.93621C7.87519 4.97646 7.92635 5.03402 7.95927 5.10235C7.9922 5.17067 8.00558 5.24702 7.99788 5.32269C7.99018 5.39836 7.96172 5.47031 7.91573 5.53036L2.31609 12.8424C2.25872 12.9177 2.17716 12.9701 2.08562 12.9905C1.99409 13.011 1.8984 12.9981 1.81525 12.9542C1.73211 12.9103 1.66678 12.8381 1.63068 12.7502C1.59458 12.6624 1.59 12.5645 1.61773 12.4736L3.05844 7.71829H0.400209C0.338024 7.71833 0.276688 7.70364 0.221071 7.67539C0.165454 7.64714 0.117088 7.60611 0.0798152 7.55556C0.0425419 7.505 0.0173879 7.44632 0.0063507 7.38417C-0.00468647 7.32201 -0.00130278 7.2581 0.0162332 7.19751L2.01611 0.29167Z" fill="#FDB933"/>
                    </svg>
                    <span>Power Mode</span>
                  </span>
                  <span class="knowIsLiteMode"  *ngIf="currentFileInfo.advanceSettings.power_mode === false ">
                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Lite Mode</span>
                  </span>
                </span>

                <span class="selectedLanguageOptionTxt" (click)="openAdvanceSettingsModal('language')" *ngIf="currentFileInfo.advanceSettings.langCode === 'en'">
                  <span class="pf-language-icon"></span>
                  {{currentFileInfo.advanceSettings.language === 'US' ? 'US English' : 'UK English'}}
                </span>
                <span class="styleGuideTxt" (click)="openAdvanceSettingsModal('style_guide')" *ngIf="currentFileInfo.advanceSettings.langCode === 'en'">
                  <span class="pf-style-guide-icon"></span>
                  {{currentFileInfo.advanceSettings.style_guide === 'NONE' ? 'None' : currentFileInfo.advanceSettings.style_guide }}
                </span>
                <span class="styleGuideTxt" (click)="openAdvanceSettingsModal('langCode')" *ngIf="currentFileInfo.advanceSettings.langCode === 'es'">
                  <span class="pf-language-icon"></span>
                  {{currentFileInfo.advanceSettings.langCode === 'en' ? 'English' : 'Spanish' }}
                </span>
              </div>
            </div>
            <div (click)="deleteFile()" *ngIf="!showProofreadInProgressLoader" class="fileDeleteIcon">
              <span class="pf-delete-icon"></span>
            </div>
          </div>

          <div *ngIf="!showProofreadInProgressLoader && currentUserCredits >= currentFileInfo.apiResponse.credits" class="proofread-details-n-btn-section">
            <div class="proofread-details-txt">
              <span class="credit-txt">{{currentFileInfo.apiResponse.credits}} credit<span *ngIf="currentFileInfo.apiResponse.credits > 1">s</span> </span>will be deducted from your credit balance

              <div class="pf-info-circle-icon">
                <div class="pf-info-popup">
                  <p class="pf-info-popup-heading">For proofread</p>
                  <p class="pf-info-popup-fixed-msg">1 credit is used per 5000 words.</p>
                  <div class="pf-info-popup-credit-div">
                    <p class="pf-info-popup-credit-details">Your file has <span class="pf-info-popup-word-count-value">{{currentFileInfo.apiResponse.word_count}} words</span> so {{currentFileInfo.apiResponse.credits}} credit<span *ngIf="currentFileInfo.apiResponse.credits > 1">s</span> will be used.</p>
                  </div>
                </div>
              </div>

            </div>

            <button class="proofread-start-btn" (click)="onStartProofread()">
              Start Proofreading
            </button>
          </div>

          <div *ngIf="!showProofreadInProgressLoader && currentUserCredits < currentFileInfo.apiResponse.credits" class="proofread-details-n-btn-section">
            <div class="proofread-details-txt" style="align-items: initial;">
              <span class="pf-change-file-btn-icon" style="position: relative;top: 2px;width: 25px"></span>
              <span class="credit-alert-txt"> Oops! You are running low on credits. You only need {{currentFileInfo.apiResponse.credits}} to proceed. Buy them here at discounted rates!
                <div class="pf-info-circle-icon" style="position: relative;top: 3px;left: 0px;">
                  <div class="pf-info-popup">
                    <p class="pf-info-popup-heading">For proofread</p>
                    <p class="pf-info-popup-fixed-msg">1 credit is per 5000 words.</p>
                    <div class="pf-info-popup-credit-div">
                      <p class="pf-info-popup-credit-details">Your file has <span class="pf-info-popup-word-count-value">{{currentFileInfo.apiResponse.word_count}} words</span> so {{currentFileInfo.apiResponse.credits}} credit<span *ngIf="currentFileInfo.apiResponse.credits > 1">s</span> will be used.</p>
                    </div>
                  </div>
                </div>
              </span>
              <!-- <span class="credit-alert-txt"> Insufficient credits. {{currentFileInfo.apiResponse.credits}} credits needed. Buy credits to proceed!</span> -->
            </div>
            <button class="proofread-start-btn" (click)="onBuyCredits()">
              Buy Credits
            </button>
          </div>

          <div *ngIf="showProofreadInProgressLoader" class="proofread-progress-loader">
            <svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background:#fff;display:block;" width="48px" height="48px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx="50" cy="50" r="28" stroke="#d9d9d9" stroke-width="8" fill="none"></circle>
              <circle cx="50" cy="50" r="28" stroke="#7e22ce" stroke-width="8" stroke-linecap="round" fill="none">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.3888888888888888s" values="0 50 50;180 50 50;720 50 50" keyTimes="0;0.5;1"></animateTransform>
                <animate attributeName="stroke-dasharray" repeatCount="indefinite" dur="1.3888888888888888s" values="17.59291886010284 158.33626974092556;87.96459430051421 87.96459430051421;17.59291886010284 158.33626974092556" keyTimes="0;0.5;1"></animate>
              </circle>
            </svg>
            <span class="proofread-progress-loader-txt">Your file is being proofread...</span>
          </div>

        </div>
        <ng-template #showSizeLimitExceededDiv>
          <div class="file-exceeded-outer">
            <span class="pf-change-file-btn-icon" style="margin-top: -15px;"></span>
            <p class="file-size-exceeded-msg" style="margin-bottom: 0;">
              It looks like your file exceeds the <b>{{getNumberWithCommas(currentFileInfo.apiResponse.word_limit)}} words</b> or <b>250 MB</b> limit, which is the maximum size we can accept per file. To use the Proofread feature, please split your document into smaller files. For optimal use of your credits, we recommend keeping each file's word count in multiples of 5,000.
              <!-- Your file has more than <b> {{getNumberWithCommas(currentFileInfo.apiResponse.word_limit)}} words</b>, please split the file and upload again. -->
            </p>
            <p class="file-size-exceeded-msg" style="margin: 0;padding-top: 0;">However, with the Trinka Enterprise plan, you can upload files larger than 30,000 words or 250 MB. For assistance, contact us at <a href="mailto:support@trinka.ai">support@trinka.ai</a>.</p>
            <button class="change-file-btn" (click)="onUploadAgainAction()">
              Upload again
            </button>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </ng-template>
</div>
