<div class="CreditsPopupSection" *ngIf = "!isConfirmation">
    <div class="CreditsHeader">
        <h2>Transaction History</h2>
        <button mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>

    <div class="paymentHistoryBody">
        <div class="detailsOfPayment" *ngIf="!isData" > 
            No transaction found. 

        </div>
        <div class="detailsOfPayment" *ngFor="let transaction of transactionDetails; let i= index">
            <ul>
                <li>
                    <h3 *ngIf = "!transaction.is_renew_payment && !transaction.is_credit_transaction">{{transaction.plan_name}} Subscription Purchased</h3>
                    <h3 *ngIf = "!transaction.is_renew_payment && transaction.is_credit_transaction">{{transaction.plan_name}} Pack Purchased</h3>
                    <h3 *ngIf = "transaction.is_renew_payment">{{transaction.plan_name}} Subscription Renewed</h3>
                    <!-- Transaction Date -->
                    <span>{{transaction.transactionDate | customPaymentTransactionDate}} UTC</span>
                    <b *ngIf = "!transaction.is_credit_transaction">Subscription Id - {{transaction.subscription_id}}</b>
                    <b *ngIf = "transaction.is_credit_transaction && transaction.payment_mode!='PAYPAL'">Transaction Id -  {{transaction.transaction_id}}</b>
                    <b *ngIf = "transaction.is_credit_transaction && transaction.payment_mode=='PAYPAL'" >Transaction Id -  {{transaction.subscription_id}}</b>
                </li>
                <li *ngIf = "transaction.payment_mode == 'CARD' && transaction.currency !== 'INR'">
                    <h5>STRIPE</h5>
                    <!-- <h5 *ngIf = "!transaction.is_manual_payment">Credit Card</h5>
                    <span *ngIf = "!transaction.is_manual_payment">**** **** {{transaction.card_number}}</span> -->
                </li>
                <li *ngIf = "transaction.payment_mode == 'WECHAT_PAY'">
                    <h5>WeChat Pay</h5>
                </li>
                <li *ngIf = "transaction.payment_mode == 'APPLE_PAY'">
                    <h5>Apple Pay</h5>
                </li>
                <li *ngIf = "transaction.payment_mode == 'PAYPAL'">
                    <h5>PayPal</h5>
                </li>
                <li *ngIf = "(transaction.payment_mode == 'UPI' || transaction.payment_mode == 'CARD') && transaction.currency === 'INR'">
                    <h5>RAZORPAY</h5>
                </li>
                <li *ngIf = "transaction.payment_mode == 'WALLET'">
                    <h5>Wallet</h5>
                </li>
                <li *ngIf = "transaction.payment_mode == 'NETBANKING'">
                    <h5>Net Banking</h5>
                </li>
                <li *ngIf = "transaction.payment_mode == 'EMI' || transaction.payment_mode == 'CARDLESS_EMI'">
                    <h5>EMI</h5>
                </li>
                <li>
                    <h4>{{transaction.currency_symbol}}{{transaction.amount}}</h4>
                </li>
            </ul>
        </div>    
    </div>
</div>

<div class="CreditsPopupSection CancelSubscriptionPopup" *ngIf = "isConfirmation">
    <div class="CreditsHeader">
        <h2>Sure to cancel your Premium subscription?</h2>
        <button mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>  
    <div class="CancelSubscriptionBody">
        <ul>
            <li>You will only get 10,000 words of usage with Basic.</li>
            <li>Only 2 credits will be offered for Auto Edit and Publication Check services.</li>
            <li>You will not have access to upcoming features.</li>
        </ul>
        <button (click)= "save()">Yes, cancel now </button>
    </div>
</div>


