import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as amplitude from 'amplitude-js';
amplitude.getInstance().init(environment.amplitude_api_key,null,{
    // optional configuration options
    includeUtm: true,
    includeReferrer: true,
    saveParamsReferrerOncePerSession: true,
    logAttributionCapturedEvent: true
}); 

@Injectable({
    providedIn: 'root'
  })

export class AmplitudeService{
    user_id: string;
    setUserId(user_id:string)
    {   
        this.user_id = user_id;
        amplitude.getInstance().setUserId(user_id);
    }

    logAmplitude(eventName:string,eventObj)
    {
        var identify = new amplitude.Identify().setOnce("Domain Name", atob(this.user_id).split("@")[1]);
        amplitude.getInstance().identify(identify);
        amplitude.logEvent(eventName, eventObj); 
    }

    logAmplitudeWithoutUser(eventName:string,eventObj)
    {
        amplitude.logEvent(eventName, eventObj); 
    }

    setUserProperties(key:string,value){
        var data = new amplitude.Identify().set(key,value);
        amplitude.getInstance().identify(data);
    }
}