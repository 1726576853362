import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
@Component({
  selector: 'app-language-alert-modal',
  templateUrl: './language-alert-modal.component.html',
  styleUrls: ['./language-alert-modal.component.scss']
})
export class LanguageAlertModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<LanguageAlertModalComponent>,) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
