<div *ngIf="isEmailVerificationModal">
  <div class="verifyYourEmail_popup" *ngIf="!is_confirm">
    <div class="verifyYourEmail_header_popup">
      <div *ngIf="!profileData?.is_verified">
        <button class="cancelbuttons" mat-button mat-dialog-close *ngIf="profileData?.trial_expire_on != -1">
          <img src="../../../assets/images/closeIcons.png" alt="">
        </button>
      </div>
    </div>

    <div class="verifyYourEmail_body_popup">
      <img src="../../../../assets/images/verifyEmail_icon.svg" alt="">
      <h2>Verify your email address</h2>
      <div class="verify_opt_section">
        <ng-otp-input autofocus #ngOtpInput (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent"
          [config]="invalid_otp ?invalid_otp_config:config"></ng-otp-input>
      </div>
      <div class="confrimButtons_verify">
        <button type="button" class="save" [disabled]="!enable_mode" (click)="confirmEmail()">Confirm</button>
      </div>

      <p>
        We have sent a 4-digit verification code to <span>{{emailId}}</span><br />
        <span style="font-size: 12px;font-style: normal;font-weight: 400;">Can’t find it in your inbox? Check spam or
          junk folder too.</span>
      </p>
      <span class="or_text_section">or</span>
      <button class="resendEmailButton" mat-button [disabled]="btnDisabled"
        [ngStyle]="{'color': (btnDisabled)? '#2FA03A': '#7a28a0'}" (click)="resendVerificationCode()"> <span
          [innerHTML]="btnText"> </span>
        <address></address>
      </button>

      <div class="popup_logout_section" *ngIf="!profileData?.is_verified && profileData?.trial_expire_on == -1">
        <span></span>
        <button mat-button mat-dialog-close (click)="signOut()"> Logout</button>
      </div>
    </div>
  </div>
  <div class="verifyYourEmail_popup" *ngIf="is_confirm">
    <div class="verifyYourEmail_header_popup">
      <button class="cancelbuttons" mat-button mat-dialog-close>
        <img src="../../../assets/images/closeIcons.png" alt="">
      </button>
    </div>

    <div class="verifyYourEmail_body_popup">
      <img src="../../../../assets/images/verifyedEmaild_icon.svg" alt="">
      <h2>Success</h2>
      <p>Your email id <span>{{emailId}}</span> has been verified. </p>
    </div>
  </div>
</div>
<div class="verificationLinkSent" *ngIf="!isEmailVerificationModal">
  <div class="verificationLink_section" *ngIf="profileData?.trial_expire_on != -1">
    <img mat-button mat-dialog-close class="verificationLinkCloused" src="../../../../assets/images/closeIcons.png" alt="">
    <img src="../../../../assets/images/verifyEmail_icon.svg" alt="">
    <h2>Verification link sent</h2>
    <p>Check for our email on <span>{{emailId}}</span></p>
    <span>If you can't find it in your inbox, do check your spam or junk folders too!</span>
    <button mat-button mat-dialog-close>OK</button>
  </div>

  <div class="verificationLink_section" *ngIf="profileData?.trial_expire_on == -1">
    <img src="../../../../assets/images/verifyEmail_icon.svg" alt="">
    <h2>You must verify your email address</h2>
    <p>We have sent the verification link to <span>{{emailId}}</span></p>
    <span>If you can't find it in your inbox, do check your spam or junk folders too!</span>
    <button (click)="resendVerificationMailLink()">Resend verification link</button>
    <a mat-button mat-dialog-close (click)="signOut()">Logout</a>
    <p class="green" *ngIf="isLinkSend">A new verification link has been sent to {{emailId}}</p>
  </div>
</div>