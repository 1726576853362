import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'journalFilter',
    pure: false
  })
  export class FilterPipe implements PipeTransform {
    transform(items: any, filter: any): any {
      let currentfilter = filter["Subject Area"].data;
      // console.log("currentfilter: ",currentfilter);
      if (!items) return [];
      
      if (currentfilter.length == 0) return items;
      let data = [];
      items.forEach(journal => {
        let currentJournal = journal;
        let current = journal.similar_article_data.forEach(article => {
          // console.log("article: ",article);
          article.article_subject_areas.filter(item => currentfilter.includes(item));
        });
        // console.log("current: ",current);
        currentJournal.similar_article_data = current;

        let graph = currentJournal.graphdata;
        let singleGraph = {};
        let graphData = [];

        currentJournal.similar_article_data.forEach(element => {
          if(!singleGraph.hasOwnProperty(element.year)){
            singleGraph[element.year] = 0;
          }
          singleGraph[element.year] =  singleGraph[element.year] + 1;
        });
        
        Object.keys(singleGraph).forEach((key) => {
          let current = {'label': key, 'value': singleGraph[key]};
          graphData.push(current);
        });

        graphData.sort(function(a, b) {
          return a.label - b.label;
        });

        let articlecount = currentJournal.similar_article_data.length;
        currentJournal.similar_article_count = articlecount;
        currentJournal.graphdata.data = graphData;
        data.push(currentJournal);
      });
      // console.log("data: ",items);
      return items;
    }

    checkFilter(article, currentfilter){
      let i;
          let flag = false;
          for(i=0; i<currentfilter.length; i++) {
            if(article.article_subject_areas.includes(currentfilter[i])) {
              flag = true;
            }
          }
          // console.log("flag: ",flag);
          if(flag){
            // console.log("article: ",article);
            return article;
          }
    }
  } 