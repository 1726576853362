import { Injectable } from '@angular/core';


const noiselocation = 4;
const noiselength = 10;

@Injectable({
    providedIn: 'root'
  })
export class EncryptDecrypt {
    constructor() { }

    encryptJSON = (json) =>{
        return this.encryptText(JSON.stringify(json))
    }

    decryptJSON = (json) =>{
        return JSON.parse(this.decryptText(json))
    }


    encryptText = (text)=>{
        /*
            phase1 logic
            encrypt text
        */

        const phase1 = this.simpleEncode(text)
        /*
            phase2 logic - add noise
            
            get random text with length less than 6
            insert at index from start (any index from 0 to 4)
            take note of start index and end index
            put that at the end(eg : 49)(last two digits of phase2 text is always start index and end index)
        */

    const rtext =   this.makenoise(phase1);


        const insertPos = noiselocation
        const phase2 = phase1.slice(0,insertPos)+rtext+phase1.slice(insertPos)

        /*
            phase3 logic
            encrypt phase2 text
        */

        const phase3 = this.simpleEncode(phase2)
        return phase3
    }


     decryptText = (text) => {
        /*
            phase1 logic
            decrypt text
        */

    const phase1 = this.simpleDecode(text)
        /*
        phase2 logic - remove noise
        
            remove the noise by slicing the text by targetting noise location and noise length
        */

        const phase2 = phase1.slice(0,noiselocation)+phase1.slice(noiselocation+noiselength)
        /*
        phase3 logic
        
        decrypt text
        */
    
    const phase3 = this.simpleDecode(phase2)
    return phase3
    }

    simpleEncode(text){
        return btoa(encodeURIComponent(text))
    }


    simpleDecode(text){
        return decodeURIComponent(atob(text))
    }


    makenoise(text){
        let tempText;
        if(text.length >= 4){
            tempText = btoa(text)
        }else{
            tempText = btoa("bVYyYVdGMGFXOXVjeVV6UVNVeU1FRkdKVEpESlRJd1lXNXVkV3gxY3lVeU1HWnBZbkp2YzNWekpUTkNKVEl3UVU1UFZrRWxNa01sTWpCdmJtVXRkMkY1SlRJd1lXNWhiSGx6YVhNbE1qQnZaaVV5TUhaaGNtbGhibU5sSlROQ0pUSXdRazBsTWtNbE1qQmliMjVsSlRJd2JXRnljbTkzSlROQ0pUSXdRMWhEVWkwMEpUSkRKVEl3UXkxWUxVTWxNakJqYUdWdGIydHBibVVsTWpCeVpXTmxjSFJ2Y2kwMEpUTkNKVEl3UTFoRFVpMDNKVEpESlRJd1F5MVlMVU1sTWpCamFHVnRiMnRwYm1VbE1qQnlaV05sY0hSdmNpMDNKVE5DSlRJd1JFaEpKVEpESlR")
        }

        return tempText.slice(0,noiselength)
    }
}
