import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-update-dictionary-word',
  templateUrl: './update-dictionary-word.component.html',
  styleUrls: ['./update-dictionary-word.component.scss']
})
export class UpdateDictionaryWordComponent implements OnInit {
  win  = window['dataLayer'] || {}

  public categoryList = [
    { name: "Correct Spelling", value: "CS" },
    { name: "Technical Procedure Name", value: "TP" },
    { name: "Biological Name", value: "BI" },
    { name: "Product Name", value: "PD" },
    { name: "Other", value: "OT" }
  ];

  public languageType = [
    { "name": "American English", value: "US" },
    { "name": "British English", value: "UK" },
    { "name": "American & British English", value: "BOTH" }
  ]
  constructor(
    public dialogRef: MatDialogRef<UpdateDictionaryWordComponent>,
    public sharedService: SharedService,
    private networkCalls: DashboardService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public amplitudeService: AmplitudeService
  ) { }

  ngOnInit() {
  }

  updateWord(selectedWord) {
    this.spinner.show();
    this.networkCalls.updateDictWord(selectedWord
      ).subscribe(result => {
      this.spinner.hide();
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        'action': 'edit_word',
        'category': selectedWord.category,
        'english_type': selectedWord.localization,
        'case_sensitive': selectedWord.case_sensitive, //yes or no values
        'event_name': 'my_dictionary',
        'event': 'my_dictionary'
         });
     
         this.amplitudeService.logAmplitude('word_edit',{
          'category': selectedWord.category,
          'locale': selectedWord.localization,
          'case': selectedWord.case_sensitive,
          'word':selectedWord.word
           });
      this.dialogRef.close(selectedWord);
    }, error => {
      this.spinner.hide();
      this.sharedService.errorHandller(error);
    })

  }

}
