import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FigureTableCheckComponent } from '../figure-table-check.component'

@Component({
  selector: 'app-figure-table-dialog',
  templateUrl: './figure-table-dialog.component.html',
  styleUrls: ['./figure-table-dialog.component.scss']
})
export class FigureTableDialogComponent implements OnInit {

  variable: number = 0
  individualData;
  section;
  tableNumberList = 0;
  constructor(private dialog: MatDialogRef<FigureTableCheckComponent>, @Inject(MAT_DIALOG_DATA) IndividualData) {
    this.individualData = IndividualData.individualData['individual'];
    this.section = IndividualData.individualData['section'];
    // if (this.section != 'Table') {
      this.tableNumberList = this.individualData.map(item => item.caption_number)
    // }
    this.individualData = this.sortByKey(this.individualData, "caption_number")
  }

  ngOnInit() {
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  getNext() {
    if (this.variable < this.individualData.length - 1) {
      this.variable = this.variable + 1
    }
  }

  getPrevisous() {
    if (this.variable > 0) {
      this.variable = this.variable - 1
    }
  }
}
