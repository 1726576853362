import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/_services/networkcalls/payment.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upgrade-to-premium',
  templateUrl: './upgrade-to-premium.component.html',
  styleUrls: ['./upgrade-to-premium.component.scss']
})
export class UpgradeToPremiumComponent implements OnInit {

  userData: any;
  localMeta: any;
  isPremium: boolean = false;
  constructor(private router: Router,
    private networkCalls: PaymentService,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public dataParam: any,
    private dialogRef: MatDialogRef<UpgradeToPremiumComponent>,
    public amplitudeService: AmplitudeService) { }

  ngOnInit() {
    this.localMeta = this.sharedService.getLocalStorageMeta();
    let data = {
      "user_id": btoa(this.localMeta.sub)
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.isPremium = this.dataParam.isPremium;
  }

  upgradeToPremium() {
    let redirct_url = environment.payment_fe;
    this.amplitudeService.logAmplitude('limit_over_action', {
      'userID': btoa(this.localMeta.sub),
      "upgrade_cta_clicked": "Yes"
    });
    window.open(redirct_url + '/user/' + this.userData, "_blank")
  }
  cancelUpgrade() {
    this.dialogRef.close();
    this.amplitudeService.logAmplitude('limit_over_action', {
      'userID': btoa(this.localMeta.sub),
      "upgrade_cta_clicked": "No"
    });
    this.dialogRef.close();
  }
}
