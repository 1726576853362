import { Inject, Injectable } from '@angular/core';
import Dexie from 'dexie';
import { EncryptDecrypt } from 'src/app/_services/encryption/encrypt-decrypt.service'
import { SharedService } from 'src/app/_services/shared.service'
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  private db;
  encrypt: EncryptDecrypt;
  isbrowserCachingEnable: boolean = false;

  constructor(
    private sharedServices: SharedService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    // this.initDatabase();
    if (this.storage.get("meta") != undefined) {
      var token = this.storage.get("meta")['token'];
      token = btoa(JSON.parse(atob(token.split(".")[1])).sub);
      this.initBgDatabase(token);
      this.encrypt = new EncryptDecrypt();
    }

  }

  /*private initDatabase() { //Initialise database
    this.db = new Dexie('trinkaCloud');
    this.db.version(2).stores({
      split: '&splitText',
      check: 'searchKey, sentence, language, style_guide, fragment, runOn, complexity, cachDate, power_mode',
      cache_meta: 'id,server_state,flush'
    });
  }*/

  private initBgDatabase(token) { //Initialise database
    this.db = new Dexie('trinkaCloud_V3_' + token);
    this.db.version(2).stores({
      splt: '&spltTxt, lc',
      chk: 'srchKey, s, lang, s_g, d_t, f, r, c, s_w,cachDate, f_id, langCode',
      c_m: 'id,s_s,f',
      us: "s_i, t,o",
      d_m: 'i_d,ts'
    });
    this.checkIfdbExists(token);
    this.deleteOldData();
  }

  getSentenceFromCache(sentence: string, language: string,
    style_guide: string, document_type: string, fragment: boolean, run_on: boolean,
    complexity: boolean, power_mode: boolean, langCode: string = 'en', instuctionModule) { // Return promise object for get sentence from cache
      instuctionModule.module == ""? instuctionModule.module = "advanced": instuctionModule.module;
    if (this.sharedServices.featurePermission.data.browser_caching) {
      var searchString = this.encodeStringResponse(sentence)
        + "#"
        + this.encodeStringResponse(language)
        + "#"
        + this.encodeStringResponse(style_guide)
        + "#"
        + this.encodeStringResponse(document_type)
        + "#"
        + this.encodeStringResponse(power_mode)
        + "#"
        + this.encodeStringResponse(langCode)
        + "#"
        + this.encodeStringResponse(instuctionModule.module)
        + "#"
        + this.encodeStringResponse(instuctionModule.instruction)
      return this.db.chk.where({
        srchKey: searchString
      }).first();
    }
  }

  setSentenceCache(alerts: string, response: any,
    file_id, language: string, style_guide: string, document_type: string,
    fragment: boolean, run_on: boolean,
    complexity: boolean, spelling_words: any, power_mode: boolean, langCode: string = 'en', instuctionModule) { //Set sentence into cache
    //Search string append
    if (this.sharedServices.featurePermission.data.browser_caching) {

      var searchString = this.encodeStringResponse(response)
        + "#"
        + this.encodeStringResponse(language)
        + "#"
        + this.encodeStringResponse(style_guide)
        + "#"
        + this.encodeStringResponse(document_type)
        + "#"
        + this.encodeStringResponse(power_mode)
        + "#"
        + this.encodeStringResponse(langCode)
        + "#"
        + this.encodeStringResponse(instuctionModule.module)
        + "#"
        + this.encodeStringResponse(instuctionModule.instruction)
      // + "#"
      //+ this.encodeStringResponse(file_id)
      // =============================
      //PUT Data in table
      this.db.chk.put({
        srchKey: searchString,
        s: this.encodeStringResponse(response),
        response: this.encodeStringResponse(JSON.stringify(alerts)),
        lang: this.encodeStringResponse(language),
        s_g: this.encodeStringResponse(style_guide),
        d_t: this.encodeStringResponse(document_type),
        f: this.encodeStringResponse(fragment),
        r: this.encodeStringResponse(run_on),
        c: this.encodeStringResponse(complexity),
        s_w: spelling_words.toString(),
        cachDate: new Date(Date.now()).getTime(),
        power_mode: this.encodeStringResponse(power_mode),
        f_id: this.encodeStringResponse(file_id),
        langCode: this.encodeStringResponse(langCode)
        // ,language: this.encodeStringResponse(JSON.stringify(language))
      }).then(function (rec) {
      }).catch(function (error) {
        alert("Ooops: " + error);
      });

      // =======================================
    }
  }

  deleteCacheSentence(
    sentence: string, language: string,
    style_guide: string, document_type: string, fragment: boolean,
    run_on: boolean, complexity: boolean, power_mode: boolean, langCode: string = "", instuctionModule) {
    if (this.sharedServices.featurePermission.data.browser_caching) {

      var searchString = this.encodeStringResponse(sentence)
        + "#"
        + this.encodeStringResponse(language)
        + "#"
        + this.encodeStringResponse(style_guide)
        + "#"
        + this.encodeStringResponse(document_type)
        + "#"
        + this.encodeStringResponse(power_mode)
        + "#"
        + this.encodeStringResponse(langCode)
        + "#"
        + this.encodeStringResponse(instuctionModule.module)
        + "#"
        + this.encodeStringResponse(instuctionModule.instruction)
      this.db.chk.where({
        srchKey: searchString
      }).delete()
        .then(function (deleteCount) {
        })
    }

  }

  getSplitFromCache(response, langCode = "en") { //Get splited list from cache
    if (this.sharedServices.featurePermission.data.browser_caching) {

      return this.db.splt.where({
        spltTxt: this.encodeStringResponse(response),
        lc: this.encodeStringResponse(langCode)
      }).first();
    }

  }

  setSplitCache(splitText: string, response: any, langCode: string = 'en') {
    if (this.sharedServices.featurePermission.data.browser_caching) {

      this.db.splt.put({
        spltTxt: this.encodeStringResponse(splitText),
        lc: this.encodeStringResponse(langCode),
        response: this.encodeStringResponse(JSON.stringify(response))
      }).then(function (rec) {
      }).catch(function (error) {
      });
    }

  }

  setDictonaryData(id: number, timestamp: Date) {
    this.db.d_m.clear();
    this.db.d_m.put({
      i_d: id,
      ts: timestamp
    }).then(function (rec) {
    }).catch(function (error) {
    });
  }

  getDictonaryData() {
    return this.db.d_m.toArray();
  }
  decodeStringResponse(encodedString) {
    return this.encrypt.decryptText(encodedString)
  }

  encodeStringResponse(encodedString) {
    return this.encrypt.encryptText(encodedString)
  }

  setCacheMetaData(id: number, server_state: Array<object>, flush: boolean) {
    if (this.sharedServices.featurePermission.data.browser_caching) {
      this.db.c_m.clear();
      this.db.c_m.put({
        id: this.encodeStringResponse(id),
        s_s: this.encodeStringResponse(server_state),
        f: this.encodeStringResponse(flush),
        response: this.encodeStringResponse(JSON.stringify({ "id": id, "server_state": server_state, "flush": flush }))
      }).then(function (rec) {
      }).catch(function (error) {
      });
    }
  }

  getCacheMetaData() {
    if (this.sharedServices.featurePermission.data.browser_caching) {
      return this.db.c_m.toArray();
    }
  }

  deleteCacheServerDownTime(response: any) {
    if (this.sharedServices.featurePermission.data.browser_caching) {

      var time = 0;

      response.server_state.forEach(element => {
        var id = element.id;
        var time1 = element.time1 + new Date().getTimezoneOffset() * 60000;
        var time2 = element.time2 + new Date().getTimezoneOffset() * 60000;
        if (element.flush == true) {
          this.db.chk.clear();
          this.db.splt.clear();
        }
        else {
          this.db.chk.where("cachDate").between(time1, time2, true, true).delete().then(function (deleteCount) {

          }).catch(function (error) {
          });
        }
        if (time1 > time) {
          this.setCacheMetaData(id, element.server_state, element.flush);
        }
      })
    }
  }

  insertRecord(sentence_id, file_id, txt) {
    var s_i = this.encrypt.encryptJSON(file_id) + '*' + this.encrypt.encryptJSON(sentence_id);
    var t = this.encrypt.encryptJSON(txt);
    this.db.us.put({
      s_i: s_i,
      t: t,
      o: 1
    }).then(function (res) {
    }).catch(function (error) {
    })
  }


  updateRecord(sentence_id, file_id, txt) {
    var s_i = this.encrypt.encryptJSON(file_id) + '*' + this.encrypt.encryptJSON(sentence_id);
    var t = this.encrypt.encryptJSON(txt);
    this.db.us.update(s_i,
      {
        "t": t
      }).then(function (data) {

      });
  }

  deleteRecord(sentence_id, file_id) {
    var s_i = sentence_id;
    this.db.us.update(s_i,
      {
        "o": 0
      }).then(function (data) {

      });
  }


  async deleteFileData(file_id) {
    if (this.sharedServices.featurePermission.data.browser_caching) {
      var encodedString = this.encodeStringResponse(file_id);

      await this.db.chk.where({
        f_id: encodedString
      })
        .delete()
        .then(function (deleteCount) {
        })
    }
  }

  async deleteDatabase() {
    this.db.delete().then(function () {
    })
  }

  async getSentenceByText(text) {
    var t = this.encrypt.encryptJSON(text);
    var data = await this.db.us.where({ "t": t }).toArray().then((data) => { return data });
    return data;
  }

  async resetDb() {
    await this.db.us.where({ "o": 1 }).modify({ "o": 0 })
  }


  checkIfdbExists(token) {
    Dexie.exists('trinkaCloud_V2_' + token).then(function (exists) {
      if (exists) {
        Dexie.delete('trinkaCloud_V2_' + token);
      }
    });
  }


  deleteDictonaryFromCache(response: any) {
    if (!response || !response.data) return;
    response.data.forEach(element => {
      var word = btoa(encodeURIComponent(element.word));
      this.db.chk.toArray().then((res => {
        res.forEach(result => {
          if (result.s_w != "" && result.s_w.includes(word)) {
            this.db.chk.update(result.srchKey,
              {
                "response": ""
              }).then(function (response) { });
          }
        })
      }));
    });

    this.setDictonaryData(response.id, response.timestamp);
  }

  deleteOldData() {
    const fourtyDaysAgo = new Date();
    fourtyDaysAgo.setDate(fourtyDaysAgo.getDate() - 40);

    this.db.chk
      .where('cachDate')
      .below(fourtyDaysAgo.getTime())
      .delete()
      .then((deleteCount) => {
        console.log("Data older "+ deleteCount + " than 5 days has been deleted.");
      })
      .catch((error) => {
        console.error('Error deleting data:', error);
      });
  }
}
