import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor(private http: HttpClient) { }

  deleteAssetById(assetType, assetId){
    return this.http.delete(environment.asset_api +"/api/v1/"+assetType+"/"+assetId)
  }

  submitFeedback(assetType, assetId, data): Observable<any> {
    return this.http.post<any>(environment.asset_api +"/api/v1/"+assetType+"/"+assetId+"/feedback", data)
  }

  submitDeletedUsersFeedback(data) :Observable<any> {
    return this.http.post<any>(environment.server_address +"/trinka/api/v1/delete/feedback" ,data)
  }

}
