import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { ReferenceCheckService } from 'src/app/_services/networkcalls/reference-check.service';
import { VerifiedUserService } from 'src/app/_services/networkcalls/verified-user.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { environment } from 'src/environments/environment';
import { CreditDetailsComponent } from '../../dashboard/credit-details/credit-details.component';

@Component({
  selector: 'app-editor-reference-quality-report',
  templateUrl: './editor-reference-quality-report.component.html',
  styleUrls: ['./editor-reference-quality-report.component.scss'],
})
export class EditorReferenceQualityReportComponent implements OnInit {

  isReadMore = true

  data:any = {};
  reportTagWiseData: any = {};
  fileName:any = "";
  noOfRef:number = -1;
  qualitySummary:any = {};
  retractedRefSum:any = {};
  oldRefSum:any = {};
  nonStdRefSum:any = {};
  poorlyCitedRefSum:any = {};
  journalOveruseSum:any = {}
  badreport: any = {}; 
  tagCount: number = 0;
  highRisk: number = 0;
  mediumRisk: number = 0;
  lowRisk: number = 0;
  qualityReportData : any = {};
  retractedRefs:any = [];
  oldRefs:any = [];
  nonStdRefs:any = [];
  journalOveruse:any = [];
  poorlyCitedRefs:any = [];

  hideme:any= {};
  currentYear:number;
  documentId:string = "";
  emailId:string = "";
  reportId:string="";

  slicedPoorlyCited:any = [];
  processedPoorlyCited:any = [];
  paymentDetails: any;
  selectedProfile:any;
  userId:any;
  creditsDetails : any = {};
  userData : any;
  pageNo :number = 1;
  setCurrentSelectedPoorCitation: any ;
  critialRef : any = [];
  goodRef: any = [];
  profileName: any;
  showProgressTop: boolean = false;
  loadPoorCitationCount: number = 0;
  creditUsed: number =0;
  journalBiasTotalPerc : number = 0;
  journalBiasCount: number = 0;
  badPercentage: any = 0;
  math = Math;
  highPerc: any = 0;
  mediumPerc: any = 0;
  lowPerc: any = 0;
  goodPerc: any =0;


  width = 600;
  height = 400;
  type = "pie2d";
  dataFormat = "json";
  dataSource: any = [];
  sampleDocId:any;


  constructor(
    private networkCalls: ReferenceCheckService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private verifiedUserService: VerifiedUserService,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    private dashboardService: DashboardService,
    public amplitudeService: AmplitudeService,
    public dialog: MatDialog,
    private onlineEditorService: OnlineEditorService,
    private router: Router
  ) { }

  ngOnInit() {
    
    this.currentYear = new Date().getFullYear();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.emailId = localMeta.sub;
    this.userId = btoa(localMeta.sub);
    let data = {
      user_id: this.userId,
      "trinka_callback_url" : window.location.href
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.amplitudeService.setUserId(this.userId);
    this.getUserPaymentSubscription();
    this.getSelectedProfileData();
    this.getCreditsDetails();
    this.subjectiveBehaviorSharedService.setEditorFileId(null);
    this.subjectiveBehaviorSharedService.setEditorFileName(null);
    this.documentId = this.route.snapshot.queryParamMap.get('document_id');
    this.reportId = this.route.snapshot.queryParamMap.get('report_id');
    this.sampleDocId = environment.citationSampleDocID;

    if(this.documentId){
      this.getRefQualityReportOverview();
      this.getRefQualityTagWiseReport();
    }
  }

  showText(event, tagCount) {
    if (event.target.closest('ul').className == ''){
      event.target.closest('ul').classList.add('limitText');
      event.target.closest('span').innerText = "+"+(tagCount-1)+" More";
    }else{
      event.target.closest('ul').classList.remove('limitText');
      event.target.closest('span').innerText =  "Less";
    } 
  } 

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if(this.processedPoorlyCited.length < this.loadPoorCitationCount){
        this.pageNo++;
        this.loadPoorCitationData();
      }
    }
  }

  backToReferenceQuality(){
    this.amplitudeService.logAmplitude('RFQ_Back_CTA',{
      'FileID': this.documentId,
    });
  }

signOut() {
  this.sharedService.SignOut();
}

  getCreditsDetails() {
    this.dashboardService.getCreditsDetailsByUser(this.userId).subscribe(
      (response) => {
        if (response["status"]) {
          this.creditsDetails = response.data;
        }
      },
      (error) => {
        this.sharedService.errorHandller(error);
      }
    );
  }

  credits() {
    if (this.creditsDetails != null) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData: this.creditsDetails,
      };

      this.dialog.open(CreditDetailsComponent, dialogConfig);
    }
  }

  addCredits() {
    let redirct_url = environment.payment_fe;
    window.open(redirct_url + "/user/credit/" + this.userData, "_self");
  }

  getSelectedProfileData() {
    this.onlineEditorService.getSelectedProfileData(this.userId).subscribe(result => {
      if (result["status"]) {
        this.selectedProfile = result['data']['profile_data'];
        this.profileName = (this.selectedProfile['firstname'].substr(0,1) +""+ this.selectedProfile['lastname'].substr(0,1)).toUpperCase();
        this.subjectiveBehaviorSharedService.setSelectedProfileData(this.selectedProfile)
      } 
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }
  
  getUserPaymentSubscription(){
    this.dashboardService.getUserPaymentSubscription(this.userId).subscribe(response => {
      this.paymentDetails = response['data'];
      this.amplitudeService.setUserProperties('subscription_type',(this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase()));
    });
  }
  

  getRefQualityTagWiseReport(){
    this.networkCalls.getRefQualityReport(this.documentId,this.emailId).subscribe(res => {
      if(res['status']){
        this.reportTagWiseData = res['data'];
        this.setQualityReportTagWiseData();
      }
      },
      error => {
      this.sharedService.errorHandller(error);
    });
  }

  getRefQualityReportOverview(){
    this.networkCalls.getRefQualityDocument(this.documentId,this.emailId).subscribe(res => {
      if(res['status']){
        this.data = res['data'];
        this.setQualityReportOverviewData();
      }
      },error => {
      this.sharedService.errorHandller(error);
    });
  }

  setQualityReportOverviewData(){
    this.fileName = this.data['filename'];
    this.creditUsed = this.data['credit_req'];
    this.noOfRef = this.data['no_of_references'];
    this.qualitySummary = this.data['quality_summary'];
    this.retractedRefSum = this.qualitySummary['retracted'];
    this.oldRefSum = this.qualitySummary['old_reference'];
    this.nonStdRefSum = this.qualitySummary['non_std_references'];
    this.poorlyCitedRefSum = this.qualitySummary['poorly_cited'];
    this.journalOveruseSum = this.qualitySummary['Journal_overuse']['summary'];
    this.badreport = this.data["bait"];
    this.badPercentage = ~~this.badreport['percentage']
    let that = this;
    this.data['quality_report'].forEach(function (matric){
        if (matric['quality_metrics']['is_good']==true) {
          that.goodRef.push(matric);
        }else{
          that.critialRef.push(matric);
        }
        if(matric['quality_metrics']['is_retracted'] || matric['quality_metrics']['is_non_std_reference']){
          that.highRisk++;
        }
        if(matric['quality_metrics']['is_old_reference'] || matric['quality_metrics']['is_poorly_cited']){
          that.mediumRisk++;
        }
        if(matric['quality_metrics']['is_journal_overuse']){
          that.lowRisk++;
        }
    });

    this.amplitudeService.logAmplitude('RFQ_Report_pageview',{
      'FileID': this.documentId,
      'Credits Used ': this.creditUsed,
      'Total References': this.noOfRef,
      'Score': ~~this.badreport['percentage']
    });
    this.qualitySummary['Journal_overuse']['detail'].forEach(function(data){
      that.journalBiasTotalPerc += ~~((data['count']/that.noOfRef)*100);
      that.journalBiasCount ++;
    });
    
    let data = {
      chart: {
        showlegend: "1",
        showpercentvalues: "0",
        theme: "fusion",
        // showValues: '1',
      },
      data: [
        {
          label: "Retracted",
          value: this.retractedRefSum.count,
          color: "#F07575",
          labelFontColor: "#F07575" 
        },
        {
          label: "Non-Standard",
          value: this.nonStdRefSum.count,
          color: "#F07575",
          labelFontColor: "#F07575" 
        },
        {
          label: "Old",
          value: this.oldRefSum.count,
          color: "#FFC700",
          labelFontColor: "#FFC700" 
        },
        // {
        //   label: "Poorly Cited",
        //   value: this.poorlyCitedRefSum.count,
        //   color: "#FFC700",
        //   labelFontColor: "#FFC700" 
        // },
        {
          label: "Journal Bias ",
          value: this.journalBiasCount,
          color: "#756AE1",
          labelFontColor: "#756AE1" 
        },
        {
          label: "Good to use",
          value: this.goodRef.length,
          color: "#63C083",
          labelFontColor: "#63C083" 
        }
      ]
    };
    this.dataSource = data;
  }

  setQualityReportTagWiseData(){
    let that = this;
    this.reportTagWiseData['retracted_refs'] = this.reportTagWiseData['retracted_refs'].filter(function (matric){
        matric['tagCount'] = that.calulateTagCount(matric['quality_metrics']);
        return matric;
    });

    this.reportTagWiseData['old_refs'] = this.reportTagWiseData['old_refs'].filter(function (matric){
      matric['tagCount'] = that.calulateTagCount(matric['quality_metrics']);
      return matric;
    });

    this.reportTagWiseData['non_std_refs'] = this.reportTagWiseData['non_std_refs'].filter(function (matric){
      matric['tagCount'] = that.calulateTagCount(matric['quality_metrics']);
      return matric;
    });

    this.reportTagWiseData['journal_overuse'] = this.reportTagWiseData['journal_overuse'].filter(function (matric){
      for(let i =0; i<matric['references'].length; i++){
      matric['references'][i]['tagCount'] = that.calulateTagCount(matric['references'][i]['quality_metrics']);
      }
      return matric;
    });

    this.reportTagWiseData['poorly_cited_refs'] = this.reportTagWiseData['poorly_cited_refs'].filter(function (matric){
      matric['tagCount'] = that.calulateTagCount(matric['reference']['quality_metrics']);
      return matric;
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  loadPoorCitationData(){
    this.spinner.show();
    this.networkCalls.getPoorCitationData(this.documentId,this.emailId, this.setCurrentSelectedPoorCitation["crossref_response"]["doi"],this.pageNo).subscribe(res => {
      if(res['status']){
        this.loadPoorCitationCount = res['data']['citationCount'];
        if(this.processedPoorlyCited.length>0){
          this.processedPoorlyCited.push(...res['data']['citations']);
        }else{
          this.processedPoorlyCited = res['data']['citations'];
        }
        this.spinner.hide();
      }
      },
      error => {
      this.sharedService.errorHandller(error);
    });
  }

  setPoorCitationDetails(data){
    this.setCurrentSelectedPoorCitation = data;
    this.processedPoorlyCited = [];
    this.amplitudeService.logAmplitude('RFQ_papersCiting_popup_view',{
      'FileID': this.documentId,
    });
    this.loadPoorCitationData();
  }

  calulateTagCount(quality_metrics)
  {
   let that = this;
   that.tagCount = 0;
    Object.keys(quality_metrics).forEach(function(key){
      if(key != "is_good" && key !="journal" && quality_metrics[key]){
        that.tagCount++;
      }
    });
    return that.tagCount;
  }

  hideTopProgress(type){
    window.scrollTo(0, 0);
    if(type == 0)
    {
      this.amplitudeService.logAmplitude('RFQ_At_risk_references_CTA',{
        'FileID': this.documentId,
        'Total References': this.noOfRef,
        'No. References': this.badreport['count'],
        'Score': ~~this.badreport['percentage'],
        'OverallScore': ~~this.badreport['percentage']
      });
    }
    else if(type == 1){
      this.amplitudeService.logAmplitude('RFQ_Retracted_refereces_CTA',{
        'FileID': this.documentId,
        'Total References': this.noOfRef,
        'No. References': this.retractedRefSum['count'],
        'Score': ~~this.qualitySummary['retracted']['percentage'],
        'OverallScore': ~~this.badreport['percentage']
      });
    }
    else if(type == 2)
    {
      this.amplitudeService.logAmplitude('RFQ_Old_refereces_CTA',{
        'FileID': this.documentId,
        'Total References': this.noOfRef,
        'No. References': this.oldRefSum['count'],
        'Score': ~~this.qualitySummary['old_reference']['percentage'],
        'OverallScore': ~~this.badreport['percentage']
      });
    }
    else if(type == 3)
    {
      this.amplitudeService.logAmplitude('RFQ_Journal_overuse_CTA',{
        'FileID': this.documentId,
        'Total References': this.noOfRef,
        'No. References': this.journalOveruseSum['count'],
        'Score': ~~this.qualitySummary['Journal_overuse']['summary']['percentage'],
        'OverallScore': ~~this.badreport['percentage']
      });
    }
    else if(type == 4)
    {
      this.amplitudeService.logAmplitude('RFQ_Nonstand-references_CTA',{
        'FileID': this.documentId,
        'Total References': this.noOfRef,
        'No. References': this.nonStdRefSum['count'],
        'Score': ~~this.qualitySummary['non_std_references']['percentage'],
        'OverallScore': ~~this.badreport['percentage']
      });
    }
    else if(type == 5)
    {
      this.amplitudeService.logAmplitude('RFQ_Poor_references_CTA',{
        'FileID': this.documentId,
        'Total References': this.noOfRef,
        'No. References': this.poorlyCitedRefSum['count'],
        'Score': ~~this.qualitySummary['poorly_cited']['percentage'],
        'OverallScore': ~~this.badreport['percentage']
      });
    }
    else if(type == 6)
    {
      this.amplitudeService.logAmplitude('RFQ_good_references_CTA',{
        'FileID': this.documentId,
        'Total References': this.noOfRef,
        'No. References': this.qualitySummary['good']['count'],
        'Score': ~~this.qualitySummary['good']['percentage'],
        'OverallScore': ~~this.badreport['percentage']
      });
    }
    else if(type == 7)
    {
      this.amplitudeService.logAmplitude('RFQ_Report_Overview',{
        'FileID': this.documentId,
        'Total References': this.noOfRef,
        'Score': ~~this.qualitySummary['good']['percentage'],
      });
    }else{}

  }

  viewDetails(type){
    this.amplitudeService.logAmplitude('RFQ_Ref_detail_view_CTA',{
      'FileID': this.documentId,
      'Tag': type
    });
  }

}
