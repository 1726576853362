<div class="container mat-typography-config rename_modal">
    <form [formGroup]="form" (ngSubmit)="submit(form)">
        <h1 mat-dialog-title>Rename file</h1>
        <mat-dialog-content>
            <mat-form-field>
                <input matInput formControlName="filename" placeholder="Enter file name">
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="rename_footer_bottom">
                <button mat-button class="update_button" type="submit" [disabled]="!form.valid">Update</button>
                <button mat-button class="cancel_button" type="button" mat-dialog-close>Cancel</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>