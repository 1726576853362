<div class="deleteConformationPopup">
    <div class="deleteConfText" *ngIf="!isFileDeleted">
        <h2>Delete Document</h2>
        <p>Are you sure you want to delete the uploaded document?</p>
        <div class="deletedFooterButtons">
            <button class="cancelDeleteButton" (click)="onCloseClick()">Cancel</button>
            <button class="deleteButton" (click)="deletepopupButton()">Delete</button>
        </div>
    </div>
    <!-- animate__animated animate__fadeOut animate__delay-2s -->
    <div class="deleteFileAnimation" *ngIf="isFileDeleted">
        <div class="fileUloadingStatus">
            <div class="SVGwrapper">
              <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
            </div>
              <p>File is deleted successfully.</p>
        </div>
    </div>
</div>