<div class="container error_popup_section" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap.xs="0" fxLayout="row">
    <div class="row">
      <div class="col-sm-12">
        <div class="popup_heading">
          <h2>Score Summary</h2>
          <button class="cancelbuttons" mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
          </button>
        </div>
    
        <div class="scroe_card_body">
            <p>This file contained {{fileData.words}} words & {{fileData.error_report.total_sentences}} sentences </p> 

            <table>
                <thead class="my-table headers">
                  <tr>
                    <th>Error Category</th>
                    <th>Instances</th>
                  </tr>
                </thead>
                <tbody class="my-table body">
                  <tr *ngFor="let obj of dataSource;">
                    <td>{{obj.key}}</td>
                    <td>{{obj.value}}</td>
                  </tr>
                </tbody>
              </table>
        </div>
      </div>
    </div>
  </div>