import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByFileName'
})
export class FilterByFileNamePipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
    
      if (it.filename) {
        return it.filename.toLowerCase().includes(searchText);
      } else {
        //return alert('No file');

        return it.file_name.toLowerCase().includes(searchText);
      }

    
    });
  }
}