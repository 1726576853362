import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-downloadfilepopup',
  templateUrl: './downloadfilepopup.component.html',
  styleUrls: ['./downloadfilepopup.component.scss']
})
export class DownloadfilepopupComponent implements OnInit {

  check: boolean = false;

  constructor(
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<DownloadfilepopupComponent>,
    ) { }

  ngOnInit() {
  }

  onSelectionChange(e) {
    if (e.value == true) {
      this.check = e.value;
    }
    else {
      this.check = e.value;
    }
  }

  download(flag) {
    this.sharedService.downloadFile(flag);
    this.dialogRef.close();
  }

}
