import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChildren } from "@angular/core";
import {  FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { JournalFinderService } from "src/app/_services/journal.finder.service";
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DashboardService } from "src/app/_services/networkcalls/dashboard.service";
import { SubjectiveBehaviorSharedService } from "src/app/_services/subjective-behavior-shared.service";
import { AmplitudeService } from "src/app/_services/amplitude.service";
import { OnlineEditorService } from "src/app/_services/networkcalls/online-editor.service";
import { environment } from "src/environments/environment";
import { CreditDetailsComponent } from "src/app/components/dashboard/credit-details/credit-details.component";
import { SortPipe } from "src/app/_pipes/sort.pipe";
import { MAT_MENU_SCROLL_STRATEGY, MAT_SELECT_SCROLL_STRATEGY } from '@angular/material';
import { Overlay, BlockScrollStrategy, RepositionScrollStrategy, CloseScrollStrategy, NoopScrollStrategy } from '@angular/cdk/overlay';
import { ViewportScroller } from "@angular/common";


declare function wisepop(): any;

const isToucheDevice = () => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
};

const scrollBlockFactory = (overlay: Overlay): () => BlockScrollStrategy  => {
  return () => overlay.scrollStrategies.block();
};

const scrollRepositionFactory = (overlay: Overlay): () => CloseScrollStrategy  => {
  return () => overlay.scrollStrategies.close();
};

@Component({
  selector: "app-main",
  templateUrl: "./journal-finder.html",
  styleUrls: ["./journal-finder.scss"],
  providers: [
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: isToucheDevice() ? scrollBlockFactory : scrollRepositionFactory, deps: [Overlay] },
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: isToucheDevice() ? scrollBlockFactory : scrollRepositionFactory, deps: [Overlay] }
  ]
})
export class JournalFinder implements OnInit {
  show = false;
  emailId: string;
  creditsDetails: any = {};
  paymentDetails: any;
  selectedProfile:any = {};
  profileName: any = "";
  userData: any;
  totalCredit: number = 0;
  isEnable: any = false;
  journalData: any[] = [];
  resultJournalData: any[] = [];
  concepts: any[] = [];
  filter: any;
  filterList: any;
  filterDisplayList: any;
  filterResp: any;
  showResults: boolean = true;
  userId: string;
  simliarArticleData : any = [];
  fileId: string = "";
  abstractText : string = "";
  report: any;
  processed_report: any;
  selectedJournalName: any;
  selectedSort: string = "most";
  lowIndex :number = 0;
  highIndex : number = 0;
  searchJournallist = [];
  searchJournalText = "";
  journalLoader: boolean = true;
  similarArticleLoader: boolean = false;
  defaultRangeValue: any = [];
  impactSelectionType: boolean = true;
  searchSubjectText: string = "";
  searchSubjectList: any = []; 
  searchRegionText: string = "";
  searchRegionList: any = [];
  searchConceptText: string = "";
  searchConceptList: any = [];
  impact_factor_custom: any = {
    min: "",
    max : ""
  }

  hIndexSelectionType: boolean = true;
  hIndex_custom: any = {
    min: "",
    max : ""
  }

  citeScoreSelectionType: boolean = true;
  citeScore_custom: any = {
    min: "",
    max : ""
  }

  chartTypeStatus: boolean = true;
  @ViewChildren('someVar') filterResult= [];
  articleFetchRequest: any;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    public journalFinderService: JournalFinderService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public networkCalls: DashboardService,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    public amplitudeService: AmplitudeService,
    private onlineEditorService: OnlineEditorService,
    private cdr: ChangeDetectorRef,
    private scroll: ViewportScroller
  ) {
   }

   pageYoffset = 0;
   @HostListener('window:scroll', ['$event']) onScroll(event){
     this.pageYoffset = window.pageYOffset;
   }

   scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.fileId = params['id'];
    });
    window.scroll(0,0);

    wisepop();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.emailId = localMeta.sub;
    let data = {
      user_id: this.userId,
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.getUserPaymentSubscription();
    this.getCreditsDetails(true);
    this.getSelectedProfileData();
    this.amplitudeService.setUserId(this.userId);
    this.networkCalls.setDashboardCloudFileDetails(null);
    this.networkCalls.setDashboardLocalFileDetails(null);
    this.subjectiveBehaviorSharedService.setEditorFileId(null);
    this.subjectiveBehaviorSharedService.setEditorFileName(null);


    this.filterList = {
      'concept_filter': {
        'added_by_you': [],
        'detected_by_AI': []
      },
      'subject_area_filter': {
        'added_by_you': [],
        'detected_by_AI': []
      },
      'geography':  {
        'added_by_you': [],
        'detected_by_AI': []
      },
      'target_journal':  {
        'added_by_you': [],
        'detected_by_AI': []
      },
      'publisher': [],
      'impact_factor': {
        'added_by_you': [],
        'detected_by_AI': []
      },
      'h_index':  {
        'added_by_you': [],
        'detected_by_AI': []
      },
      'cite_score':  {
        'added_by_you': [],
        'detected_by_AI': []
      },
      'show_open_access': false,
      'hide_predatory_journals': true
    }

    this.filterDisplayList = {
      "Target Journal": {
        data: [],
        filter_title: "target_journal",
        default_value: {
          'added_by_you': [],
          'detected_by_AI': []
        },
        type: "array"
      },
      "Geography": {
        data: [],
        filter_title: "geography",
        default_value: {
          'added_by_you': [],
          'detected_by_AI': []
        },
        type: "array"
      },
      "Subject Area": {
        data: [],
        filter_title: "subject_area_filter",
        default_value: {
          'added_by_you': [],
          'detected_by_AI': []
        },
        type: "array"
      },
      "Impact Factor": {
        data: [],
        filter_title: "impact_factor",
        default_value: {
          'added_by_you': [],
          'detected_by_AI': []
        },
        type: "array"
      },
      "H Index": {
        data: [],
        filter_title: "h_index",
        default_value: {
          'added_by_you': [],
          'detected_by_AI': []
        },
        type: "array"
      },
      "Cite Score": {
        data: [],
        filter_title: "cite_score",
        default_value: {
          'added_by_you': [],
          'detected_by_AI': []
        },
        type: "array"
      },
      "Concepts": {
        data: [],
        filter_title: "concept_filter",
        default_value: {
          'added_by_you': [],
          'detected_by_AI': []
        },
        type: "array"
      },
      "Show open access only": {
        data: this.filterList.show_open_access,
        filter_title: "show_open_access",
        default_value: false,
        type: "boolean"
      },
      "Hide predatory journals": {
        data: this.filterList.hide_predatory_journals,
        filter_title: "hide_predatory_journals",
        default_value: true,
        type: "boolean"
      }
    }

    // this.defaultRangeValue = [
    //   {
    //     min: 0,
    //     max : 2,
    //     status: false,
    //   },
    //   {
    //     min: 2.1,
    //     max : 4,
    //     status: false,
    //   },
    //   {
    //     min: 4.1,
    //     max : 7,
    //     status: false,
    //   },
    //   {
    //     min: 7.1,
    //     max : 10,
    //     status: false,
    //   }];
    this.getAbstractReport();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
 }

  signOut() {
    this.sharedService.SignOut();
  }

  addCredits() {
    // this.amplitudeService.logAmplitude("RFQ_Insuff-Credit_AddCredits", {
    //   'Credit Balance Free': this.creditsDetails.credits,
    //   'Credit Balance Purchased': this.creditsDetails.purchased_credits,
    // });
    let redirct_url = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url + "/user/credit/" + this.userData, "_self");
  }

  credits() {
    if (this.creditsDetails != null) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData: this.creditsDetails,
      };

      this.dialog.open(CreditDetailsComponent, dialogConfig);
    }
  }

  getUserPaymentSubscription(){
    this.networkCalls.getUserPaymentSubscription(this.userId).subscribe(response => {
      this.paymentDetails = response['data'];
      this.amplitudeService.setUserProperties('subscription_type',(this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase()));
    });
  }

  unsorted()
  { return 0; }

  getCreditsDetails(flag=false) {
    // this.spinner.show();
    this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(
      (response) => {
        if (response["status"]) {
          this.creditsDetails = response.data;
          this.totalCredit = this.creditsDetails.credits  + this.creditsDetails.purchased_credits;
        }
        if(flag){
        // this.amplitudeService.logAmplitude("RFQ_Landing_Pageview", {
        //   'Credit Balance Free': this.creditsDetails.credits,
        //   'Credit Balance Purchased': this.creditsDetails.purchased_credits
        // });
      }
        // this.spinner.hide();
      },
      (error) => {
        this.sharedService.errorHandller(error);
      }
    );
  }

  getSelectedProfileData() {
    this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(profileData=>{
        this.selectedProfile = profileData;
        this.profileName = this.selectedProfile!="" ? (this.selectedProfile['firstname'].substr(0,1) +""+ this.selectedProfile['lastname'].substr(0,1)).toUpperCase(): "";
    });
    if(this.selectedProfile==''){
    this.onlineEditorService.getSelectedProfileData(this.userId).subscribe(result => {
      if (result["status"]) {
        this.selectedProfile = result['data']['profile_data'];
        this.profileName = (this.selectedProfile['firstname'].substr(0,1) +""+ this.selectedProfile['lastname'].substr(0,1)).toUpperCase();
        this.subjectiveBehaviorSharedService.setSelectedProfileData(this.selectedProfile)
      } 
    }, error => {
      this.sharedService.errorHandller(error);
    });
   }
  }

  getAbstractReport() {
    this.amplitudeService.setUserId(this.userId);
      this.journalFinderService.getAbstractReport(this.userId, this.fileId).subscribe(
        (resp) => {
          window.scroll(0,0);
          this.journalLoader = false;
          this.report = resp["data"][0];
          this.processed_report = JSON.parse(resp["data"][0].processed_report);
          this.journalData = this.processed_report["journal_data"];
          this.filterResult = this.processed_report["journal_data"];
          this.filterResp = this.processed_report["filter_support_data"];


          // this.filterDisplayList['H Index'].default_value = this.defaultRangeValue;
          // this.filterDisplayList['Impact Factor'].default_value = this.defaultRangeValue;
          // this.filterDisplayList['Cite Score'].default_value = this.defaultRangeValue;

          var subject_area = this.arrayToArrayList(this.processed_report['subject_area']);
          var concepts = this.arrayToArrayList(this.processed_report['concepts']);
          var region = this.arrayToArrayList(this.filterResp['regions']);
          this.filterList['concept_filter'].detected_by_AI = concepts.filterlist;
          this.filterList['subject_area_filter'].detected_by_AI = subject_area.filterlist;
          this.filterList['geography'].detected_by_AI = region.filterlist;
          // this.filterList['impact_factor'].detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterlist;
          // this.filterList['h_index'].detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterlist;
          // this.filterList['cite_score'].detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterlist;
          this.filterDisplayList['Subject Area'].default_value.detected_by_AI = subject_area.filterdisplaylist;
          this.filterDisplayList['Concepts'].default_value.detected_by_AI = concepts.filterdisplaylist;
          this.filterDisplayList['Geography'].default_value.detected_by_AI = region.filterdisplaylist;
          // this.filterDisplayList['Impact Factor'].default_value.detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterdisplaylist;
          // this.filterDisplayList['H Index'].default_value.detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterdisplaylist;
          // this.filterDisplayList['Cite Score'].default_value.detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterdisplaylist;
          this.abstractText = this.processed_report.abstract;
          
          let num = this.journalData.length >= 8 ? 8 : this.journalData.length;
         
          let filterJournalList = this.journalData.filter(element => {
            if((element.open_access || element.open_access == null) && (element.predatory_journal == "false" || element.predatory_journal == null))
            {
              return element;
            }
          });
          filterJournalList = new SortPipe().transform(filterJournalList, "");
          for (var i = 0; i < num; i++) {
            var current = { 'id': filterJournalList[i].journal_title, 'status': false };
            this.filterList['target_journal'].detected_by_AI.push(current);
            var displaycurrent = { 'id': filterJournalList[i].journal_title, 'status': false };
            this.filterDisplayList['Target Journal'].default_value.detected_by_AI.push(displaycurrent);
            //this.filterDisplayList["Target Journal"].data.push(this.journalData[i].journal_title);
          }      
          this.showResults = true;    
        },
        (errorResp) => {
          this.journalLoader = false;
          let alert_message = errorResp.error.message
            ? errorResp.error.message
            : "Something went wrong";
          this.toastr.error(alert_message, "Error");
          this.spinner.hide();
        }
      );
    //}
  }

  deleteFilter(e, filterType) {
    e.stopPropagation();
    this.amplitudeService.logAmplitude('JFR_Filter_Cancel_CTA',{
      "Filter Type ": filterType
    });
    let value = document.getElementsByClassName(filterType);
    this.filterDisplayList[filterType].type == "array" ? this.filterDisplayList[filterType].data = [] : this.filterDisplayList[filterType].type == "string" ? this.filterDisplayList[filterType].data = "" : this.filterDisplayList[filterType].type == "boolean" ? this.filterDisplayList[filterType].data = false : "";

    if(this.filterDisplayList[filterType].type == "array"){
      this.filterList[this.filterDisplayList[filterType].filter_title] = this.filterDisplayList[filterType].default_value;
      this.filterList[this.filterDisplayList[filterType].filter_title]["detected_by_AI"].forEach(element => {
        element.status = false;
      });
      this.filterList[this.filterDisplayList[filterType].filter_title]['added_by_you'] = [];
    }
    else if(this.filterDisplayList[filterType].type == "boolean"){
      this.filterList[this.filterDisplayList[filterType].filter_title] = false;
    }

    if(filterType == "Impact Factor")
    {
      this.impact_factor_custom = {
        min: "",
        max : ""
      }
    }
    if(filterType == "H Index"){
      this.hIndex_custom = {
        min: "",
        max : ""
      }
    }
    if(filterType == "Cite Score"){
      this.citeScore_custom = {
        min: "",
        max : ""
      }
    }
    this.searchJournalText = filterType =="Target Journal" ? "" : this.searchJournalText;
    this.searchRegionText = filterType == "Geography" ? "" : this.searchRegionText;
  }

  resetAllFilter(){
    this.resetTargetJournals("", false);
    this.resetSelectionFilter("","Geography", false);
    this.resetSelectionFilter("", "Subject Area", false);
    this.resetRangeFilter("", "Impact Factor", false);
    this.resetRangeFilter("", "H Index", false);
    this.resetRangeFilter("", "Cite Score", false);
    this.filterList.show_open_access = false;
    this.filterList.hide_predatory_journals = true;
  }

  resetTargetJournals(event, flag = true){
    if(flag){
      event.stopPropagation();
      this.amplitudeService.logAmplitude('JFR_Filter_Reset_CTA',{
        "Filter Type ": "Target Journal"
      });
    }
    this.filterDisplayList["Target Journal"].data = [];
    this.filterList[this.filterDisplayList["Target Journal"].filter_title]["detected_by_AI"].forEach(element => {
      element.status = false;
      //this.filterDisplayList["Target Journal"].data.push(element.id);
    });
    this.filterList["target_journal"] = this.filterDisplayList["Target Journal"].default_value;
    this.filterList['target_journal']['added_by_you'] = [];
    this.searchJournalText = "";

  }

  resetSelectionFilter(event, filterType, flag=true){
    if(flag){
      this.amplitudeService.logAmplitude('JFR_Filter_Reset_CTA',{
        "Filter Type ": filterType
      });
      event.stopPropagation();
    }
    this.filterList[this.filterDisplayList[filterType].filter_title] = this.filterDisplayList[filterType].default_value;
    this.filterList[this.filterDisplayList[filterType].filter_title]["detected_by_AI"].forEach(element => {
      element.status = false;
    });
    this.filterDisplayList[filterType].data = [];
    this.searchRegionText = filterType == "Geography" ? "" : this.searchRegionText;
  }

  resetRangeFilter(event, filterType, flag=true){
    if(flag){
      this.amplitudeService.logAmplitude('JFR_Filter_Reset_CTA',{
        "Filter Type ": filterType
      });

      event.stopPropagation();
    }
    if(filterType == "Impact Factor"){
      this.filterList['impact_factor'].detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterlist;
      this.filterDisplayList["Impact Factor"].data = [];
      this.impact_factor_custom = {min : "", max: ""};
      this.impactSelectionType = true;
    }
    else if(filterType == "H Index"){
      this.filterList['h_index'].detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterlist;
      this.filterDisplayList["H Index"].data = [];
      this.hIndex_custom = {min : "", max: ""};
      this.hIndexSelectionType = true;
    }
    else if(filterType == "Cite Score"){
      this.filterList['cite_score'].detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterlist;
      this.filterDisplayList["Cite Score"].data = [];
      this.citeScore_custom = {min : "", max: ""};
      this.citeScoreSelectionType = true;
    }else{}
  }

  openAbstract(event, doc_id, index) {
    let page = Math.round(this.lowIndex / 15);
    index = page >0 ?  (page*15) + index : index;
    let abstract = this.simliarArticleData.article_data[index].abstract || "";
    if(abstract==""){
      this.simliarArticleData.article_data[index]["abstractLoader"] = true;
      event.target.parentElement.parentElement.parentElement.closest("div").querySelector(".ellipseContent").classList.remove("ellipseContent");
      this.articleFetchRequest = this.journalFinderService.fetchArticleAbstract(doc_id).subscribe(
        (resp)=>{
          this.simliarArticleData.article_data[index]["abstract"] = resp["response"]!=null ? resp["response"].response.text : "NA";
          this.simliarArticleData.article_data[index]["abstractLoader"] = false;
          this.simliarArticleData.article_data[index]["abstractViewText"] = "View less";
      });
    }else{
      if(event.target.parentElement.parentElement.parentElement.querySelector('.collapse.show') == null)
      {
        event.target.parentElement.parentElement.parentElement.closest("div").querySelector(".concept-content").querySelector("span").classList.remove("ellipseContent");
        this.simliarArticleData.article_data[index]["abstractViewText"] = "View less";
      }
      else{
       event.target.parentElement.parentElement.parentElement.closest("div").querySelector(".concept-content").querySelector("span").classList.add("ellipseContent");
       this.simliarArticleData.article_data[index]["abstractViewText"] = "View Abstract & More";
      }
    }
  }

  filterChange(filterType, filterMode, filterName, current) {
    switch (filterType) {
      case "concepts":
        this.filterList['concept_filter'][filterMode] = this.filterList['concept_filter'][filterMode].map(item => {
          item.id == filterName ? item.status = !item.status : item.status = item.status
          return item;
        });

        if (current.target.checked) {
          this.filterDisplayList["Concepts"].data.push(filterName);
        } else {
          var index = this.filterDisplayList["Concepts"].data.indexOf(filterName);
          if (index > -1) {
            this.filterDisplayList["Concepts"].data.splice(index, 1);
          }
        }
        
        this.amplitudeService.logAmplitude('JFR_Filter-Concept_CTA',{ });
        break;
      case "subject_area":
        this.filterList['subject_area_filter'][filterMode] = this.filterList['subject_area_filter'][filterMode].map(item => {
          item.id == filterName ? item.status = !item.status : item.status = item.status
          return item;
        });

        if (current.target.checked) {
          this.filterDisplayList["Subject Area"].data.push(filterName);
        } else {
          var index = this.filterDisplayList["Subject Area"].data.indexOf(filterName);
          if (index > -1) {
            this.filterDisplayList["Subject Area"].data.splice(index, 1);
          }
        }

        this.amplitudeService.logAmplitude('JFR_Filter-SubArea_CTA',{ });
        break;
      case "target_journal":
        this.filterList['target_journal'][filterMode] = this.filterList['target_journal'][filterMode].map(item => {
          item.id == filterName ? item.status = !item.status : item.status = item.status
          return item;
        });

        if (current.target.checked) {
          this.filterDisplayList["Target Journal"].data.push(filterName);
        } else {
          var index = this.filterDisplayList["Target Journal"].data.indexOf(filterName);
          if (index > -1) {
            this.filterDisplayList["Target Journal"].data.splice(index, 1);
          }
          this.filterList['target_journal']["added_by_you"] = this.filterList['target_journal']["added_by_you"].filter(a=> a.id != filterName)
        }
        this.amplitudeService.logAmplitude('JFR_Filter-TJournal_CTA',{ });
        break;
        case "geography":
          this.filterList['geography'][filterMode] = this.filterList['geography'][filterMode].map(item => {
            item.id == filterName ? item.status = !item.status : item.status = item.status
            return item;
          });
  
          if (current.target.checked) {
            this.filterDisplayList["Geography"].data.push(filterName);
          } else {
            var index = this.filterDisplayList["Geography"].data.indexOf(filterName);
            if (index > -1) {
              this.filterDisplayList["Geography"].data.splice(index, 1);
            }
          }
          this.amplitudeService.logAmplitude('JFR_Filter-Region_CTA',{ });
          break;
    }
  }

  addUserFilter(event, type, value) {
    let newobject = {};
    let newdisplayobject = {};
    let text = value;
    newobject = { 'id': text, 'status': true };
    newdisplayobject = { 'id': text, 'status': true };
    if (text) {
      switch (type) {
        case "concepts":
          this.filterList['concept_filter']['added_by_you'].push(newobject);
          this.filterDisplayList['Concepts'].data.push(text);
          this.filterDisplayList['Concepts'].default_value.added_by_you.push(newdisplayobject);
          break;
        case "subject_area":
          this.filterList['subject_area_filter']['added_by_you'].push(newobject);
          this.filterDisplayList['Subject Area'].data.push(text);
          this.filterDisplayList['Subject Area'].default_value.added_by_you.push(newdisplayobject);
          break;
        case "target_journal":
          if (event.target.checked) {
            this.filterList['target_journal']['added_by_you'].push(newobject);
            this.filterDisplayList['Target Journal'].data.push(text);
            // this.filterDisplayList['Target Journal'].default_value.added_by_you.push(newdisplayobject);
          } else {
            var index = this.filterDisplayList['Target Journal'].data.indexOf(text);
            if (index > -1) {
              this.filterDisplayList["Target Journal"].data.splice(index, 1);
            }
            index = -1;
            this.filterList['target_journal']['added_by_you'].some((el,i) =>{
                if(el.id==text){
                  index= i;
                }
            });
            if(index !=-1){
              this.filterList['target_journal']['added_by_you'].splice(index, 1);
            }
          }
          break;
      }
    }
  }


  selectImfactorType(val, is_edit, event)
  {

    this.amplitudeService.logAmplitude('JFR_Filter-ImpFactor_CTA',{ });
    this.impactSelectionType = val == 0 ? true: false; 
    if(val==0){
      this.impact_factor_custom = {min: "", max:""};
      if(this.filterDisplayList["Impact Factor"].data.length>0 && !is_edit){
        this.filterDisplayList["Impact Factor"].data = [];
      }
    }else{
      this.filterList['impact_factor'].detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterlist;
      this.filterDisplayList["Impact Factor"].data = [];
    }

    if(is_edit && val==0){
      let text = event.target.id;
      this.filterList['impact_factor'].detected_by_AI = this.filterList['impact_factor'].detected_by_AI.map(item => {
        item.id == text ? item.status = !item.status : item.status = item.status
        return item;
      });

      if(event.target.checked) {
        this.filterDisplayList["Impact Factor"].data.push(text);
      } else {
        var index = this.filterDisplayList["Impact Factor"].data.indexOf(text);
        if (index > -1) {
          this.filterDisplayList["Impact Factor"].data.splice(index, 1);
        }
      }

    }else if(is_edit && val==1)
    {
      if(this.impact_factor_custom.min!=null && this.impact_factor_custom.min!=0 && this.impact_factor_custom.max!=null && this.impact_factor_custom.max!=0){
        let text = this.impact_factor_custom.min+" - "+this.impact_factor_custom.max;
        this.filterDisplayList["Impact Factor"].data.push(text); 
      }
    }else{}

  }

  isNumber(val){
    return typeof(val);
  }

  selectHIndexType(val, is_edit, event)
  {
    this.amplitudeService.logAmplitude('JFR_Filter-HIndex_CTA',{ });
    this.hIndexSelectionType = val == 0 ? true: false; 
    if(val==0){
      this.hIndex_custom = {min: "", max:""};
      if(this.filterDisplayList["H Index"].data.length>0 && !is_edit){
        this.filterDisplayList["H Index"].data = [];
      }
    }else{
      this.filterList['h_index'].detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterlist;
      this.filterDisplayList["H Index"].data = [];
    }
 

    if(is_edit && val==0){
      let text = event.target.id;

      this.filterList['h_index'].detected_by_AI = this.filterList['h_index'].detected_by_AI.map(item => {
        item.id == text ? item.status = !item.status : item.status = item.status
        return item;
      });

      if(event.target.checked) {
        this.filterDisplayList["H Index"].data.push(text);
      } else {
        var index = this.filterDisplayList["H Index"].data.indexOf(text);
        if (index > -1) {
          this.filterDisplayList["H Index"].data.splice(index, 1);
        }
      }

    }else if(is_edit && val==1)
    {
      if(this.hIndex_custom.min!=null && this.hIndex_custom.min!=0 && this.hIndex_custom.max!=null && this.hIndex_custom.max!=0){
        let text = this.hIndex_custom.min+" - "+this.hIndex_custom.max;
        this.filterDisplayList["H Index"].data.push(text); 
      }
    }else{}
  }

  selectCiteScoreType(val, is_edit, event)
  {
    this.amplitudeService.logAmplitude('JFR_Filter-CiteScore_CTA',{ });
    this.citeScoreSelectionType = val == 0 ? true: false; 
    if(val==0){
      //this.filterList['impact_factor'].detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterlist;
      this.citeScore_custom = {min: "", max:""};
      if(this.filterDisplayList["Cite Score"].data.length>0 && !is_edit){
        this.filterDisplayList["Cite Score"].data = [];
      }
    }else{
      this.filterList['cite_score'].detected_by_AI = this.setDefaultRange(this.defaultRangeValue).filterlist;
      this.filterDisplayList["Cite Score"].data = [];
    }
 

    if(is_edit && val==0){
      let text = event.target.id;

      this.filterList['cite_score'].detected_by_AI = this.filterList['cite_score'].detected_by_AI.map(item => {
        item.id == text ? item.status = !item.status : item.status = item.status
        return item;
      });

      if(event.target.checked) {
        this.filterDisplayList["Cite Score"].data.push(text);
      } else {
        var index = this.filterDisplayList["Cite Score"].data.indexOf(text);
        if (index > -1) {
          this.filterDisplayList["Cite Score"].data.splice(index, 1);
        }
      }

    }else if(is_edit && val==1)
    {
      if(this.citeScore_custom.min!=null && this.citeScore_custom.min!=0 && this.citeScore_custom.max!=null && this.citeScore_custom.max!=0){
        let text = this.citeScore_custom.min+" - "+this.citeScore_custom.max;
        this.filterDisplayList["Cite Score"].data.push(text); 
      }
    }else{}
  }

  openSubFilters(element) {
    let checkelement = document.getElementsByClassName('filter-li-selected');
    for (const item of Array.from(checkelement)) {
      if (element.target.parentNode.children[1] != item) {
        item.classList.remove('filter-li-selected');
      }
    }
    element.target.parentNode.children[1].classList.toggle('filter-li-selected');
  }


  setDefaultRange(arr){
    var returnobj = { 'filterlist': [], 'filterdisplaylist': [] };
    var obj = [];
    for (var i = 0; i < arr.length; ++i) {
      var current = { 'id': arr[i].min+" - "+arr[i].max, 'status': false };
      returnobj['filterlist'].push(current);
      var displaycurrent = { 'id':  arr[i].min+" - "+arr[i].max, 'status': false };
      returnobj['filterdisplaylist'].push(displaycurrent);
    }
    return returnobj;
  }

  arrayToArrayList(arr) {
    var returnobj = { 'filterlist': [], 'filterdisplaylist': [] };
    var obj = [];
    for (var i = 0; i < arr.length; ++i) {
      var current = { 'id': arr[i], 'status': false };
      returnobj['filterlist'].push(current);
      var displaycurrent = { 'id': arr[i], 'status': false };
      returnobj['filterdisplaylist'].push(displaycurrent);
    }
    return returnobj;
  }

  changeCheckboxFilter(filterListItem, displayFilterListItem) {
    this.filterList[filterListItem] = !this.filterList[filterListItem];
    this.filterDisplayList[displayFilterListItem].data = !this.filterDisplayList[displayFilterListItem].data;

    if(filterListItem == "show_open_access"){
      this.amplitudeService.logAmplitude('JFR_Filter-OpenAccess_CTA',{
        "Changed to": this.filterList[filterListItem] ? "Show" : "Hide"
      });
    }else{
      this.amplitudeService.logAmplitude('JFR_Filter-PredatoryJournals_CTA',{
        "Changed to": this.filterList[filterListItem] ? "Show" : "Hide"
      });
    }
  }


  showSimilarArticle(journal_name, count,rank){

    if(!this.report.is_report_expire){
      this.amplitudeService.logAmplitude('JFR_SimiArticles_CTA',{ 
        "Journal Name": journal_name,
        "No. of Articles": count,
        "Rank": rank
      });
      this.similarArticleLoader = true;
      this.showResults = false;
     this.selectedJournalName = journal_name;
      let payload = {"text": this.abstractText , "journal_name": [journal_name], "sample_count": count};
      this.simliarArticleData = [];
      this.lowIndex = -1;
      this.highIndex = 0;
      
      window.scroll(0,0);
      this.journalFinderService.fetchSimilarArticle(payload).subscribe(
        (resp) => {
          this.simliarArticleData = resp["data"];
          this.lowIndex = 0;
          this.highIndex = this.simliarArticleData.article_data.length > 15 ? 15 : this.simliarArticleData.article_data.length;
          this.similarArticleLoader = false;
        },
        (errorResp) => {
          let alert_message = errorResp.error.message
            ? errorResp.error.message
            : "Something went wrong";
          this.toastr.error(alert_message, "Error");
          this.spinner.hide();
        }
      );
    }
  }


  backToJournalFinderReport(){
    this.amplitudeService.logAmplitude('JFR_Back_CTA',{
      'Credit Balance Free ': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits,
      'Report ID': this.fileId,
    });
  }

  logJournalWebsite(journal_name, rank){
     this.amplitudeService.logAmplitude('JFR_JournalSite_CTA',{
      'Journal Name': journal_name,
      "Rank": rank
    });
  }

  getSimilarArticleBasedonSelection(journal){
    this.selectedJournalName = journal.value;
    this.selectedSort = "most";
    //this.showSimilarArticle(this.selectedJournalName, 10, 0);
  }

  setValues(rank, count)
  {
    this.showSimilarArticle(this.selectedJournalName, count, rank);
  }
  backToJournalReport(){
    this.showResults = true;
    this.similarArticleLoader = false;
  }

  changeGraph(type){
    this.chartTypeStatus = type==0 ? true : false;
  }

  sortArticle(event){
    this.selectedSort = event.value == "most" ? "most" : "least";
    this.simliarArticleData.article_data.reverse();   
  }

  nextRecord(lowIndex, highIndex){
    if(this.articleFetchRequest!=undefined){
      this.articleFetchRequest.unsubscribe();
    }
   this.stopSimilarArticleLoading();
    if(highIndex+15 < this.simliarArticleData.article_data.length)
    {
      this.lowIndex = highIndex;
      this.highIndex = highIndex+15;

    }
    else{
      if(highIndex != this.simliarArticleData.article_data.length){
      this.lowIndex = highIndex;
      this.highIndex = this.simliarArticleData.article_data.length;
      }
    }
  }

  stopSimilarArticleLoading(){
    this.simliarArticleData.article_data.forEach(element => {
      element['abstractLoader'] = false;
    });
  }

  prevRecord(lowIndex, highIndex){
    if(this.articleFetchRequest!=undefined){
      this.articleFetchRequest.unsubscribe();
    }
    this.stopSimilarArticleLoading();
    if(lowIndex-15>=0){
      if(lowIndex-15 == 0)
      {
        this.highIndex = lowIndex;
        this.lowIndex = 0;
      }
      else if(lowIndex-15 >= 1)
      {
        this.highIndex = lowIndex;
        this.lowIndex = lowIndex-15;
      }else{
        this.highIndex = lowIndex;
        this.lowIndex = (this.simliarArticleData.article_data.length +lowIndex)- lowIndex;
      }
    }
  }

  renewJournalReport()
  {
    if(this.report.is_report_expire){
      this.spinner.show();
      let payload = {"file_id": this.report.file_id, "user_id": this.userId};
      this.journalFinderService.renewExpiredReport(payload).subscribe((resp) => {
          if(resp["status"]){
            this.spinner.hide();
            this.toastr.success(resp["message"]);
            this.router.navigate(['/journal-finder']);
          }
        },
        (errorResp) => {
          let alert_message = errorResp.error.message
            ? errorResp.error.message
            : "Something went wrong";
          this.toastr.error(alert_message, "Error");
          this.spinner.hide();
        }
      );
    }  
  }


  searchJournalTitle(event){
    this.searchJournallist = [];
    this.searchJournalText = event.target.value;
    if(this.journalData.length){
      this.journalData.forEach(result => 
        {
          if(result.journal_title.toLowerCase().includes(this.searchJournalText.toLowerCase()))
          {
            this.searchJournallist.push(result.journal_title);
          }
      });

      let selected_filter_journal_list = [];
      if(this.filterList.target_journal.detected_by_AI.length>0){
        this.filterList.target_journal.detected_by_AI.forEach((element) => {
          selected_filter_journal_list.push(element.id);
        });
      }
      if(this.filterList.target_journal.added_by_you.length>0){
        this.filterList.target_journal.added_by_you.forEach(element => {
          selected_filter_journal_list.push(element.id);
        });
      }
      let DupSearchJournallist = this.searchJournallist;
      this.searchJournallist = selected_filter_journal_list.filter((element) => {
          let index = this.searchJournallist.indexOf(element)
          if(index!= -1){
            DupSearchJournallist.splice(index, 1);
          }
      });
      this.searchJournallist = DupSearchJournallist;
    }else{
        this.searchJournallist = [];
    }
  }

  searchSubjectArea(event){
    this.searchSubjectText = event.target.value;
    if(this.processed_report['subject_area'].length){
    this.searchSubjectList = this.processed_report['subject_area'].filter(result => {return result.toLowerCase().includes(this.searchSubjectText.toLowerCase())});
    }else{
      this.searchSubjectList = [];
    }
  }

  searchRegion(event){
    this.searchRegionText = event.target.value;
    if(this.filterResp['regions'].length){
    this.searchRegionList = this.filterResp['regions'].filter(result => {return result.toLowerCase().includes(this.searchRegionText.toLowerCase())});
    }else{
      this.searchRegionList = [];
    }
  }

  searchConcept(event){
    this.searchConceptText = event.target.value;
    if(this.processed_report['concepts'].length){
    this.searchConceptList = this.processed_report['concepts'].filter(result => {return result.toLowerCase().includes(this.searchRegionText.toLowerCase())});
    }else{
      this.searchConceptList = [];
    }
  }

  uppercaseFirstLetter(userType){
    return this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type);
  }
  
}
