import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../_services/shared.service';

@Pipe({
  name: 'filterByCategory'
})
export class FilterByCategoryPipe implements PipeTransform {
  catList = [];
  constructor( private sharedService: SharedService){ }

  transform(items: any[], searchCategory: any, isInclusiveFilterChanged: boolean): any[] {
    if (!items) return [];

    return items.filter(it => {
      if(it.suggestions){

        if(it.suggestions[0].type === 10) {
          if(searchCategory.has(10) && searchCategory.has(it.suggestions[0].error_category)) {
            it['node'].removeClass('tempTagRemove')
            return true;
          } else {
            it['node'].addClass('tempTagRemove')
          }
        } else {
          if (searchCategory.has(it.suggestions[0].type)) {
            it['node'].removeClass('tempTagRemove')
            return true;
          } else {
            it['node'].addClass('tempTagRemove')
          }
        }
      }
    });
  }
}
