import { ChangeDetectionStrategy, Component, OnInit, ViewChild, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { CustomNgxCkeditorComponent } from 'src/app/_directives/custom-ngx-ckeditor.component';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service'
import { ConnectionService } from 'src/app/_socket/connection.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileObject } from 'src/app/_interfaces/editor/file';
import { SharedService } from 'src/app/_services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { ConnectionService as ConnectionStatusService } from 'ng-connection-service';
import * as $ from 'jquery';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { ModuleCheck } from 'src/app/_enums/module-check.enum';
import { MatRadioChange, MatRadioButton, MatSnackBar } from '@angular/material';
import { KeyValue } from '@angular/common';
import { EnablePublicationCheck } from 'src/app/_interfaces/author-one/enable-publication-check';
import { environment } from 'src/environments/environment';
import { SignupaccessComponent } from 'src/app/_modal/signupaccess/signupaccess.component';
import { ElementRef } from '@angular/core';
import { DictionaryModalComponent } from './dictionary-modal/dictionary-modal.component';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { UpgradeToPremiumComponent } from '../upgrade-to-premium/upgrade-to-premium.component';
import Dexie from 'dexie';
import { IndexedDbService } from 'src/app/_services/Dexie/dexie-js.service';
import { LocaldbService } from 'src/app/_services/Dexie/localdb.service';
import { v4 as uuid } from 'uuid';
import { RenameFileComponent } from 'src/app/components/dashboard/mydrive/rename-file/rename-file.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { PaymentDetails } from 'src/app/_interfaces/dashboard/payment-details';
import { EncryptionDecryption } from 'src/app/utilities/encryptionModule';
import { FreeTrialModelComponent } from '../power-mode/free-trial-model/free-trial-model.component';
import { SaveNewFileDetailsModalComponent } from './save-new-file-details-modal/save-new-file-details-modal.component';
import { EditorComponent } from 'src/app/modules/editor/editor.component'
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { Stack } from "src/app/utilities/stack";
import { ParaphraserService } from 'src/app/_services/paraphraser.service';
import { AutoEdit } from 'src/app/_enums/auto-edit.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { EditorPlagiarismModalComponent } from '../editor-plagiarism-modal/editor-plagiarism-modal.component';
import { EditorAdvancePlagCheckCreditModalComponent } from '../editor-advance-plag-check-credit-modal/editor-advance-plag-check-credit-modal.component';
import { PowerModeEditingComponent } from 'src/app/modules/shared/components/power-mode-editing/power-mode-editing.component';

declare function wisepop(): any;

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('300ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [listAnimation]
})
export class MainComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  // Constants
  EVENT_LOOP_DELAY: number = 2000;
  COMMENT_ID_SESSION_COUNTER: number = Math.round(Math.random() * 10000);
  LAST_KNOWN_TEXT: string = "last_known_text";
  SENTENCE_TAG: string = "sentence";
  REQUEST_ID: string = 'request_id'
  REQ_ID: string = '';
  ALERT_TAG: string = "gcalert";
  CC_ALERT_TAG: string = "ccalert";
  VALID_TEXT: RegExp = /[a-zA-Z0-9]+/g;
  TERMINAL: RegExp = /[.!?]/g;
  TEXT_NODE: number = 3;
  COMMENT_NODE: number = 8;
  ELEMENT_NODE: number = 1;
  POSITION_PRECEDING: number = 4;
  CHUNK_SIZE: number = 100;
  HEALTH_CHECK_TIMEOUT: number = 1000000000;
  CHUNK_RETRY_TIMEOUT: number = 1000;
  REQUEST_RETRY_TIMEOUT: number = 60000;
  CHECK_TYPE: string = "check";
  SPLIT_TYPE: string = "split";
  SUPER_SCRIPT: string = "sup";
  SUB_SCRIPT: string = "sub";
  ALERT_REGEX: RegExp = /<\/?gcalert[^><]*>/g;
  CC_ALERT_REGEX: RegExp = /<\/?ccalert[^><]*>/g;
  private db;
  SENTENCE_REGEX: RegExp = /<\/?sentence[^><]*>/g;
  COMPETITOR_TAGS = ["grammarly-popups", "grammarly-card", "grammarly-extension", "grammarly-btn"];
  COMPETITOR_BLOCK_SUPPORTED_BROWSER = new Set(["chrome", "firefox", "safari"]);
  INVALID_TAGS_WITHIN_ALERT = ['img', 'sup', 'sub'];
  CONNECTION_STATUS: string = 'ONLINE';
  IS_CONNECTED: boolean = true;
  SPECIAL_SPACES = /[\u200B-\u200D\uFEFF]/g;
  styleGuideDispay = {
    "NONE": "NONE",
    "AMA": "AMA (11th ed)",
    "APA": "APA (7th ed)",
    "ACS": "ACS (2nd ed)",
    "AGU": "AGU (2017 ed)",
    "IEEE": "IEEE",
    "Redbook (3rd ed)-Legal": "USA-The Redbook (3rd ed.)",
    "SCI stereotypical language": "India-SCI Gender Handbook"
  }
  languageCodeOptions = [{
    "code": "en",
    "value": "English (EN)",
    "isSelected": false,
    "disabled": false
  },
  // {
  //   "code": "de",
  //   "value": "German (DE)",
  //   "isSelected": false,
  //   "disabled": false
  // },
  {
    "code": "es",
    "value": "Spanish (ES)",
    "isSelected": false,
    "disabled": false
  }];
  editingModeSelectedOption = "Power Mode"
  editingModeOption = [{
    "isBeta": true,
    "value": "Power Mode",
    "isSelected": false,
    "disabled": false,
    "description": "For grammar, spelling correction, as well as language enhancement",
    "specialClass": "hoverPowerModeSection"
  }, {
    "isBeta": true,
    "value": "Lite Mode",
    "isSelected": true,
    "disabled": false,
    "description": "For essential grammar and spelling correction",
    "specialClass": ""
  }];
  selectedLanguageCode: string[] = ['en'];
  defaultLanguageCode: string[] = [];
  COMMENT_HOLDER: string = "<div comment-holder=\"true\"><span start=\"true\" comment=\"_cmnt1\"><span></span></span><span end=\"true\" comment=\"_cmnt1\"></span></div>";
  COMMENT_DIV_REGEX: RegExp = /<div\s+id="_cmnt[0-9]+"[^><]*>.*<\/div>/g;
  @ViewChild('ckEditor', { static: true }) ckEditor: CustomNgxCkeditorComponent;
  // Constants end

  // Declairations
  prevCCData = null;
  win = window['dataLayer'] || {};
  sentToCCApi: boolean = false;
  styleGuide: string = 'NONE';
  lastSavedTime: Date = new Date();
  saving: boolean = false;
  readOnly: boolean = false;
  isInitialised: boolean = false;
  toggleFlag: boolean = false;
  fileHolder = new FileObject();
  moduleCheck = ModuleCheck;
  innerHeight: number;
  editorValue: any = "";
  alertList: Array<any> = [];
  ccInProgressStopGC = false;
  requestToElementMap: Map<string, any> = new Map();
  contentChangeObservable: Subject<any> = new Subject();
  contentChangeForCCObservable: Subject<any> = new Subject();
  healthCheckObservable: Subject<any> = new Subject();
  chunkObservable: Subject<any> = new Subject();
  documentId: string;
  paramObs: any;
  userId: string;
  preBody: string;
  postBody: string;
  trinkaOnHold = false;
  userLimitExhausted = false;
  instructionModule = {
    instruction: "Fix all the Grammatical Errors of this text",
    module: "advanced"
  }
  viewMoreViewLessText: boolean = false;
  summaryDetails = {
    'total_error': 0,
    // 'grammar_error': 0,
    // 'enhancement_error': 0,
    // 'spellings_error': 0,
    // 'advisor_error': 0,
    // 'displayError': 0
  };
  enableSentenceFragment = false;
  enableSentenceComplexity = false;
  enableSentenceRunon = false;
  enableSending = false;
  selectedCat = false;
  grammar: boolean = false;
  enabledModule: string = "";
  spelling: boolean = false;
  enhancement: boolean = false;
  advisor: boolean = false;
  master_checked: boolean = true;
  cc_master_checked: boolean = true;
  lang_code_master_checked: boolean = false;
  master_indeterminate: boolean = false;
  reportHistoryTab: boolean = true;
  isAdvancePlagCheckReport: boolean = false;
  isGeneratingPlagCheckReport: boolean = false;
  shimmerLoaderPowerMode: boolean = true;
  // Declairations end
  config = {
    // forceEnterMode: true,
    startupFocus: true,
    allowedContent: true,
    // forcePasteAsPlainText: true,
    disableNativeSpellChecker: true,
    // disallowedContent: 'a',
    // removeButtons: 'Cut,Copy,Paste,Undo,Redo,Anchor,Underline,Strike,Subscript,Superscript',
    // removeDialogTabs: 'link:advanced',
    toolbar: [
      // { name: 'document', items: [ 'Save', 'Preview'] },
      // { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
      { name: 'styles', items: ['Format', 'Font', 'FontSize'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
      { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', '-', 'NumberedList', 'BulletedList'] },
      // { name: 'clipboard', items: ['Undo', 'Redo'] },
      // { name: 'editing', items: ['Find'] },
      // { name: 'links', items: [ 'Link', 'Unlink' ] },
      // '/',
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'insert', items: ['Table', 'SpecialChar', 'PageBreak'] },
      // { name: 'tools', items: [ 'ShowBlocks' ] },
      // { name: 'about', items: [ 'About' ] }
    ],
    // width: "auto",
    contentsCss: ["div[id^='_cmnt'] {display: none;},body{overflow-y: hidden}", "table{width: 100%!important;max-width:100%}", "pre{white-space: pre-wrap;}", "gcalert{border-bottom-style: solid;}", "gcalert.paraphraser{border-bottom-style: none;}", "ccalert{border-bottom-style: solid;border-bottom-color: transparent;}", ".grammar{border-bottom-color: rgba(225,0,0,0.50);}", ".grammarcc{border-bottom-color: rgba(225,0,0,0.50);}", ".spelling{border-bottom-color: rgba(225,0,0,0.50);}", ".suggestion{border-bottom-color: rgba(225,0,0,0.50);}", ".grammar_active{background-color: rgb(255,0,0,0.25);border-bottom-color: rgba(225,0,0,0.70);}", ".grammarcc_active{background-color: rgb(255,0,0,0.25);border-bottom-color: rgba(225,0,0,0.70);}",
      ".spelling_active{background-color: rgb(255,0,0,0.25);border-bottom-color: rgba(225,0,0,0.70);}", ".suggestion_active{background-color: rgb(255,0,0,0.25);border-bottom-color: rgba(225,0,0,0.70);}", ".sticky-top{top: 0;bottom: 0;z-index: 1;position: fixed;height: 30px;background: white;width: 100%;}", ".tempTagRemove{border-bottom-style: none !important;background-color: white !important;}", "sentence[focused='true'] { background: #E3EFF4; color: #1E2938}"],
    removePlugins: 'iframe,contextmenu,liststyle,tabletools,tableselection,magicline,link',
    stylesSet: "/src/styles.css",
    // extraAllowedContent: 'span(*)[*]{*}',
    extraPlugins: 'autogrow,confighelper',
    language: 'en',
    autoGrow_onStartup: true,
    autoGrow_minHeight: window.innerHeight - 100,
    autoGrow_maxHeight: 150000,
    on: {
      paste: this.onPaste.bind(this),
      key: this.onKeyPress.bind(this),
    }
    // fontSize_defaultLabel: '12px',
    // toolbarLocation : 'bottom',
  }
  document_type: any = 1;
  commentDiv: string = "";
  selectedItem: any;
  acceptAllList: any = [];
  selection: number

  //passing values from parent to child
  // sending values from parent to child
  master: any;
  flagCCnum: boolean = true;
  defaultSelected = 0;
  selectedNum = 0;
  numberArray = [
    { text: 'string', value: '0', displayText: 'Spelled-out', displayText1: 'Numeral' },
    { text: 'digit', value: '1', displayText: 'Numeral', displayText1: 'Spelled-out' }
  ]
  selectedSym = 0;
  symbolArray = [
    { text: 'string', value: '0', displayText: 'Spelled-out', displayText1: 'Symbol' },
    { text: 'symbol', value: '1', displayText: 'Symbol', displayText1: 'Spelled-out' },
    { text: 'measurement', value: '2', displayText: 'Measurement', displayText1: 'Spelled-out' }
  ]
  selectedPunc = 0;
  punctuationArray = [
    { text: 'out-side', value: '0', displayText: 'Outside quotes', displayText1: 'Inside quotes' },
    { text: 'in-side', value: '1', displayText: 'Inside quotes', displayText1: 'Outside quotes' }
  ];

  selectedSpace = 0;
  spacingArray = [
    { text: 'add-space', value: '0', displayText: 'Add space', displayText1: 'Remove space' },
    { text: 'remove-space', value: '1', displayText: 'Remove space', displayText1: 'Add space' }
  ]

  selectedSymbolSpace = 0;
  spacingSymbolArray = [
    { text: 'add-space', value: '0', displayText: 'Add space', displayText1: 'Remove space' },
    { text: 'remove-space', value: '1', displayText: 'Remove space', displayText1: 'Add space' }
  ]

  selectedPvalue = 0;
  pValueArray = [
    { text: 'to-lower', value: '0', displayText: 'To lowercase', displayText1: 'To Uppercase' },
    { text: 'to-upper', value: '1', displayText: 'To uppercase', displayText1: 'To Lowercase' }
  ]

  selectedPvalueZero = 0;
  pValueArrayZero = [
    { text: 'add-zero', value: '0', displayText: 'Add zero', displayText1: 'Remove zero' },
    { text: 'remove-zero', value: '1', displayText: 'Remove zero', displayText1: 'Add zero' }
  ]

  dataGroupedByText: any = [];
  previousIndex: number = 0;
  lastIndex: number = 0;
  viewEndIndexArray1: number = 3;
  viewEndIndexArray2: number = 3;
  consistencyClicked: boolean = true;

  emptyDocument = false;

  trialMode: Boolean = false;
  wordCountApiCall = true;
  wordCountApiFailed = false;

  wordCountSections = [
    //{ text: "dict_count", value: 0, displayText: "Words in Dictionary" },
    // {text:"page_count",value:0, displayText: 'Pages'},
    //{ text: "character_count_with_space", value: 0, displayText: 'Characters' },
    { text: "word_count", value: 0, displayText: 'Words' },
    //{ text: "sentence_count", value: 0, displayText: 'Sentences' },
    //{ text: "paragraph_count", value: 0, displayText: 'Paragraphs' }
  ]
  // indexCache: IndexedDbService;

  wordLimitExceed = false;
  transitionAlertObj: any = null;
  paymentDetails: PaymentDetails;
  powerModeSelection: any;

  localDb: LocaldbService;
  encrypt_decrypt: EncryptionDecryption;
  editorLoader: boolean = false;
  editorCardLoader: boolean = false;

  private _stack: Stack;
  sentencesSelectedForParaphraser = [];
  private _snackBarDuration: number = 3000;
  creditsDetails: any;
  plagCheckFileList: any = [];
  reportData: any = [];
  viewerEnabled: boolean = false;
  plagCheckReportUrl: any = "";
  isScanCalculated: boolean = false;
  noOfScanRequired: number = 0;
  avaialbleScan: number = 0;
  sample_file_id = environment.plag_check_sample_report_id;
  sampleCard = {
    "file_id": this.sample_file_id,
    "submission_id": "47be6e58-9b4c-4c44-9eac-18c866b98e09",
    "turnitine_submission_id": "47be6e58-9b4c-4c44-9eac-18c866b98e09",
  };
  isSamplePlagCheckReport: boolean = false;
  plagFileWordCount: number = 0;
  userData: any;
  getModuleCheckSubscription;
  languageString;
  isEnglishLanguageDisabled: boolean = false;
  private _documentSettings: any = null;
  isInclusiveFilterChanged: boolean = false;
  isActiveLearnMore: boolean = false;

  // Functions
  constructor(
    public subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    private networkCalls: OnlineEditorService,
    private socketService: ConnectionService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService,
    private changeDetector: ChangeDetectorRef,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private connectionStatusService: ConnectionStatusService,
    private subjectiveBehaviourService: SubjectiveBehaviorSharedService,
    public amplitudeService: AmplitudeService,
    public indexCache: IndexedDbService,
    private dashboardService: DashboardService,
    private _paraphraserService: ParaphraserService,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private router: Router,
  ) {
    this.connectionStatusService.monitor().subscribe(isConnected => {
      this.IS_CONNECTED = isConnected;
      if (this.IS_CONNECTED) {
        this.CONNECTION_STATUS = "ONLINE"
        // this.toastr.success("You are back online!")
      } else {
        this.CONNECTION_STATUS = "OFLINE"
        // this.toastr.warning('You are not connected to the internet')
      }
    })

    // this.indexCache = new IndexedDbService(sharedService);
    this.localDb = new LocaldbService()
    this.trialMode = environment.trialMode;
    this.encrypt_decrypt = new EncryptionDecryption();
    this._stack = new Stack();

    this.subjectiveBehaviorSharedService.inclusiveFilterChange.subscribe((data) => {
      this.filterCategories();
      this.isInclusiveFilterChanged = !this.isInclusiveFilterChanged;
      this.updateInclusiveFilterState();
      this.calculateSummary();
      this.changeDetector.detectChanges();
    });

    this.subjectiveBehaviorSharedService.getInstruction.subscribe(data => {
      if (data && data.mode === 'parent_to_child') {
        // this.instructionModule.instruction = data.instruction== ""? "advanced": data.instruction;
        this.instructionModule.module = data.module;
        this.sentDataCall(() => window.location.reload(), true);
        // setTimeout(() => {
        //   this._restartSocket();
        // }, 1000);
      }
    })

    function _getCKEditorNotificationCloseBtn() {
      return new Promise((resolve, reject) => {
        let counter = 0;
        const checkInterval = setInterval(() => {
          const btn = document.getElementsByClassName('cke_notification_close')[0];
          if (btn) {
            clearInterval(checkInterval);
            resolve(btn);
          }

          counter++;

          if (counter > 200) {
            clearInterval(checkInterval);
            reject();
          }
        }, 50);
      });
    }
    _getCKEditorNotificationCloseBtn().then((btn: HTMLElement) => {
      btn.click();
    });
  }


  @ViewChild("WordCoutnMenu", { static: false })
  WordCoutnMenu: ElementRef;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewPort: CdkVirtualScrollViewport;
  @ViewChild('paraphraseFloatingOption', { static: false }) private _paraphraseFloatingOption!: ElementRef;

  SentenceObserver: any;
  mutationConfig = { attributes: true, childList: true, characterData: true };

  ngAfterViewInit() {
    setTimeout(() => {
      // this.getInstuction();
      this.getSelectedStyleGuide();
      this.getSelectedDocumentType();
    }, 10000);
    this.onResize(event);
    this.getModuleSelectionOption();
    this.getSelectedPowerModeOption();
    this.callSentenceObserver();

    this._paraphraserService.paraphraseContentAddedInEditor.subscribe((isDocumentUpdated) => {
      if (isDocumentUpdated) {
        setTimeout(() => {
          this._clearPreviousSelectionNodes();
          this.saveTextFile();
        }, 3000);
      }
    });
  }

  callSentenceObserver() {
    var _this = this;
    this.SentenceObserver = new MutationObserver((mutations) => {
      mutations.forEach(function (mutation) {
        if (mutation.removedNodes.length > 0 && mutation.removedNodes[0].nodeName == "SENTENCE") {
          var sentence = mutation.removedNodes[0];
          _this.localDb.deleteRecord(sentence["id"], this.sharedService.currentFileId)
        }
      });
    });
  }

  renameFile(file) {
    this.sharedService.renameFile = file
    var dialogRef = this.dialog.open(RenameFileComponent);
    dialogRef.afterClosed().subscribe(response => {
      this.fileHolder.data.file_name = response;
    })
  }

  loadCreditDetails() {
    this.dashboardService.getStoreUserCreditDetails().subscribe(resp => {
      if (Object.keys(resp).length == 0) {
        this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
          if (response["status"]) {
            this.creditsDetails = response.data;
            this.avaialbleScan = this.creditsDetails['plagiarism_free_page_count_scan'];
            this.dashboardService.storeUserCreditDetails(this.creditsDetails);
          }
          // this.totalCredits = this.creditsDetails.credits + this.creditsDetails.purchased_credits;
        }, error => {
          this.sharedService.errorHandller(error);
        })
      }
      else {
        this.creditsDetails = resp;
        this.avaialbleScan = this.creditsDetails['plagiarism_free_page_count_scan'];
        this.dashboardService.storeUserCreditDetails(this.creditsDetails);
      }
    });
  }

  private _resetAllFilterErrorCount() {
    this.sharedService.filterCheckList.forEach(filter => filter.errorCount = 0);
  }

  getModuleSelectionOption() {
    this.getModuleCheckSubscription = this.subjectiveBehaviourService.getModuleCheck.subscribe(module => {
      if (module != "" && this.ckEditor.instance) {
        this.enabledModule = module;
        this.isEnglishLanguageDisabled = this.enabledModule === this.moduleCheck.CC;
        // clear selection if exist
        let userSelection = this.ckEditor.instance.window.$.getSelection();
        if (userSelection.toString() !== '') {
          userSelection.empty();
          this._paraphraseFloatingOption.nativeElement.style.display = 'none';
        }
        switch (module) {
          case ModuleCheck.CC:
            if (this.readOnly)
              return
            this.ccInProgressStopGC = true;
            var body = this.ckEditor.instance.document.getBody();
            this.removeSentences(body);
            this.alertList = [];
            this.cardCountForTrial = 0;
            this._resetAllFilterErrorCount();
            this.sendDataToCOnsistencyCheckerAPI();
            window.dispatchEvent(new Event('resize'));
            this.viewerEnabled = false;
            break;
          case ModuleCheck.GC:
            if (this.readOnly)
              return

            try {
              if (this.cc_result.length != 0) {
                for (let index = 0; index < this.cc_result.length; index++) {
                  for (let j = 0; j < this.cc_result[index].alerts.length; j++) {
                    this.cc_result[index].alerts[j].node.removeClass(this.cc_result[index].alerts[j].alert_class)
                    this.cc_result[index].alerts[j].node.removeClass(this.cc_result[index].alerts[j].alert_class + '_active')
                  }
                }
              }
            } catch (error) {
              console.log('error ===> ', error);
            }

            this.ccInProgressStopGC = false;
            this.requestToElementMap.clear();
            this.readOnly = false
            this.cc_result = [];
            this.alertList = [];
            this.cardCountForTrial = 0;
            this.prevCCData = null;
            var body = this.ckEditor.instance.document.getBody();
            this.removeSentences(body);
            this.scanDocument();
            window.dispatchEvent(new Event('resize'));
            this.viewerEnabled = false;
            break;

          case ModuleCheck.A1:
            this.ccInProgressStopGC = true;
            var body = this.ckEditor.instance.document.getBody();
            this.removeSentences(body);
            this.alertList = [];
            this.cardCountForTrial = 0;
            this.prevCCData = null;
            window.dispatchEvent(new Event('resize'));
            this.viewerEnabled = false;

          case ModuleCheck.PP:
            this.ccInProgressStopGC = false;
            this.requestToElementMap.clear();
            this.readOnly = false
            this.cc_result = [];
            this.alertList = [];
            this.cardCountForTrial = 0;
            this.prevCCData = null;
            var body = this.ckEditor.instance.document.getBody();
            this.removeSentences(body);
            this.scanDocument();
            window.dispatchEvent(new Event('resize'));
            this._resetAllFilterErrorCount();
            break;

          case ModuleCheck.PC:
            this.ccInProgressStopGC = true;
            var body = this.ckEditor.instance.document.getBody();
            this.removeSentences(body);
            this.alertList = [];
            this.cardCountForTrial = 0;
            this.enabledModule = module;
            this.prevCCData = null;
            window.dispatchEvent(new Event('resize'));
            this.getEditorPlagCheckFiles();
            this.getFileWordCountDetails();

          // this.getReportDataOfAuthorOne();

          default:
            setTimeout(function () {
              window.dispatchEvent(new Event('resize'));
            }, 2000)
            this.viewerEnabled = false;
            break;
        }
      } else {
        this.enabledModule = this.moduleCheck.GC;
      }
    })
  }


  getSelectedStyleGuide() {
    this.subjectiveBehaviorSharedService.getStyleGuide.subscribe(styleguide => {
      this.styleGuide = styleguide[0];
      let styleFilter = this.sharedService.filterCheckList.find(filter => filter.flag === 5);
      if (!styleguide || !styleguide[0] || styleguide[0] == "NONE") {
        styleFilter.display = false;
        styleFilter.checked = false;
      } else {
        styleFilter.display = true;
        styleFilter.checked = true;
        styleFilter.name = this.styleGuideDispay[styleguide[0]] + " Style";
      }
      if (styleguide[0] && styleguide[1]) {
        this._restartSocket();
      }
    })
  }

  // getInstuction() { }

  paper_type = 1;
  added_subject_area = '';
  getSelectedDocumentType() {
    this.subjectiveBehaviorSharedService.getDocTypeObject.subscribe(document_type => {
      this.docTypeObject['selected_document_type'] = document_type['selected_document_type']
      this.docTypeObject['selected_paper_type'] = document_type['selected_paper_type']
      this.docTypeObject['user_added_subject_area'] = document_type['user_added_subject_area']
      this.docTypeObject['selected_subject_area'] = document_type['selected_subject_area']
      this.docTypeObject['subject_area_list'] = document_type['subject_area_list'] ? document_type['subject_area_list'] : []

      let optionsToDisable = [1, 3];
      if (document_type.selected_document_type === 4) {
        for (let i in optionsToDisable) {
          let filterOption = this.sharedService.filterCheckList.find(filter => filter.flag === optionsToDisable[i]);
          filterOption.disabled = true;
          filterOption.checked = false;
        }
        let filterOption = this.sharedService.filterCheckList.find(filter => filter.flag === 11);
        filterOption.disabled = false;
        filterOption.checked = true;
        filterOption.display = true;

        this.languageCodeOptions.forEach(option => {
          if (option.code !== 'en') option.disabled = true;
        });

        let inclusiveFilterOption = this.sharedService.filterCheckList.find(filter => filter.flag === 10);
        inclusiveFilterOption.disabled = false;
        inclusiveFilterOption.checked = true;
      } else {
        for (let i in optionsToDisable) {
          let filterOption = this.sharedService.filterCheckList.find(filter => filter.flag === optionsToDisable[i]);
          filterOption.disabled = false;
          filterOption.checked = true;
        }
        let filterOption = this.sharedService.filterCheckList.find(filter => filter.flag === 11);
        filterOption.disabled = true;
        filterOption.checked = false;
        filterOption.display = false;

        this.languageCodeOptions.forEach(option => {
          if (option.code !== 'en') option.disabled = false;
        });

        let inclusiveFilterOption = this.sharedService.filterCheckList.find(filter => filter.flag === 10);
        if (!this.selectedLanguageCode.includes('en')) {
          inclusiveFilterOption.disabled = true;
          inclusiveFilterOption.checked = false;
        } else {
          inclusiveFilterOption.disabled = false;
          inclusiveFilterOption.checked = true;
        }
      }

      this.updateInclusiveFilterState();
    });
  }

  updateInclusiveFilterState() {
    let isAllInclusiveFiltersUnseleted = Object.values(this.sharedService.inclusiveLanguageFilterOptions).every(filter => filter.checked === false);
    let inclusiveFilterOption = this.sharedService.filterCheckList.find(filter => filter.flag === 10);
    if (!inclusiveFilterOption.disabled) {
      if (isAllInclusiveFiltersUnseleted) {
        inclusiveFilterOption.checked = false;
      } else {
        inclusiveFilterOption.checked = true;
      }
    }
  }

  ngAfterViewChecked() {
    this.onResize(event);
    try {
      let editor = document.querySelector('[id^="cke_editor-"]') as HTMLElement | null;
      let editorBottom = document.querySelector('[id^="cke_"][id$="_bottom"]') as HTMLElement | null;
      editor.style.border = 'none';
      editorBottom.style.border = 'none';
    } catch (ngAfterViewInit) { }
  }

  ngOnInit() {
    setTimeout(() => {
      this.shimmerLoaderPowerMode = false;
    }, 1500);
    this.indexCache.resetDb();
    wisepop();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.enabledModule = ModuleCheck.GC;
    this.userId = btoa(localMeta.sub)
    this.onResize(event)
    this.route.params.subscribe(params => {
      this.documentId = params['id'];
      if (this.trialMode && this.documentId == null) {
        this.createNewfile()
        return;
      }
      else if (!this.trialMode && this.documentId == null) {
        document.location.href = document.location.origin + "/404"

      }
      this.sharedService.setCurrentFileAndUserId(this.userId, this.documentId);
    });
    try {
      setTimeout(() => {
        let editor = document.querySelector('[id^="cke_editor-"]') as HTMLElement | null;
        let editorBottom = document.querySelector('[id^="cke_"][id$="_bottom"]') as HTMLElement | null;
        editor.style.border = 'none';
        editorBottom.style.border = 'none';
      }, 3000);
    } catch (DOMException) {

    }
    this.onWindowScroll();
    this.getPublicationAllCheckReport();
    this.navigationConfigure();
    this.getUserPaymentSubscription();
    this.loadCreditDetails();
    this.getEditorPlagCheckFiles();
  }

  createNewfile() {
    this.networkCalls.createNewFile(this.userId).subscribe(result => {
      //Enabling new editor for BASIC & PREMIUM_PLUS user for now
      let redirectRoute = this.creditsDetails['user_type'] === 'BASIC' || this.creditsDetails['user_type'] === 'PREMIUM_PLUS' ? "/editor/main-v1/" : "/editor/main/";
      document.location.href = document.location.origin + redirectRoute + result['data']['file_id'];
      this.editorLoader = false;
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  private getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  private addOrUpdateStatsComment() {
    let statsComment = 'There are no unresolved Trinka alerts for your file.';
    let length = this.alertList.length;
    if (length > 0) {
      statsComment = (length === 1) ? 'is ' + length + ' unresolved Trinka alert' : 'are ' + length + ' unresolved Trinka alerts';
      statsComment = 'There ' + statsComment + ' for your file. Please resolve them by opening the document at <a href="https://cloud.trinka.ai/editor/main/' + this.documentId + '">cloud.trinka.ai.</a>';
    }

    this.commentDiv = `<div id="_cmnt1" style="-aw-comment-author:'Trinka'; -aw-comment-datetime:'${(new Date()).toISOString()}'; -aw-comment-initial:'T'"><p><a data-cke-saved-href="#_cmntref1" href="#_cmntref1"><span>[a1]</span></a><span>${statsComment}</span></p></div>`
    this.onChange(null);
  }

  private createComment(id: string) {
    let document = this.ckEditor.instance.document;

    let anchorSpan = document.createElement('span');
    anchorSpan.setText('[a1]');

    let anchor = document.createElement("a");
    anchor.setAttribute('href', "#_cmntref" + id);
    anchor.append(anchorSpan);

    let commentSpan = document.createElement('span');
    commentSpan.setText('');

    let commentParagraph = document.createElement('p');
    commentParagraph.append(anchor);
    commentParagraph.append(commentSpan);

    let commentDiv = document.createElement("div");
    commentDiv.setAttribute("id", '_cmnt' + id);
    commentDiv.setAttribute("style", `-aw-comment-author:'Trinka'; -aw-comment-datetime:'${(new Date()).toISOString()}'; -aw-comment-initial:'a'`);
    commentDiv.append(commentParagraph);

    document.getBody().append(commentDiv);

    return commentDiv;
  }

  private clearTags() {
    this.COMPETITOR_TAGS.forEach((tag) => {
      if (document.querySelector(tag)) {
        document.querySelector(tag).remove()
      }
    })
  }

  private getSentenceID(node) {
    try {
      const potential_parent = node.getParent()
      if (potential_parent.getName() === "sentence") {
        return potential_parent.getUniqueId()
      }
      else {
        return this.getSentenceID(potential_parent)
      }
    } catch {
      return null
    }
  }

  onlyNonCTACardsLeft(alertList): boolean {
    let onlynonctas = true
    alertList.forEach((alert, j) => {
      if (alert.suggestions[0].cta_present) {
        onlynonctas = false
      }
    })
    return onlynonctas
  }

  removeComments() { }

  acceptAllCards() {
    if (this.alertList.length > 50) {
      this.spinner.show()
    }
    let processed_sentences = []
    let alertToRemove = []
    let onlynonctas = this.onlyNonCTACardsLeft(this.alertList)

    this.alertList.forEach((alert, j) => {
      if (onlynonctas) {
        const cval = this.COMMENT_ID_SESSION_COUNTER++
        const commidattr = "#_cmntref" + cval
        const commnameattr = "_cmnt" + cval
        // Use this to create new ekeditor html elements
        const ckdocument = this.ckEditor.instance.document.getBody().getDocument()


        const outerspan = ckdocument.createElement("span")
        outerspan.setAttribute("start", "true")
        outerspan.setAttribute("comment", commnameattr)

        const innerSpan = ckdocument.createElement("span")
        innerSpan.setText(alert.text)
        outerspan.append(innerSpan)

        const adjspan = ckdocument.createElement("span")
        adjspan.setAttribute("end", "true")
        adjspan.setAttribute("comment", commidattr)
        adjspan.insertAfter(alert.node)
        alert.node.insertBeforeMe(outerspan)


        const commDiv = ckdocument.createElement("div")
        commDiv.setAttribute("id", commnameattr)
        var dd = new Date()
        commDiv.setAttribute("style", `-aw-comment-author:'${atob(this.userId)}'; -aw-comment-datetime:'${dd.toISOString()}'; -aw-comment-initial:'a'`)

        const divp = ckdocument.createElement("p")
        const anchorp = ckdocument.createElement("a")
        anchorp.setAttribute("href", commidattr)

        const anchspan = ckdocument.createElement("span")
        anchspan.setText("[a1]")

        const adjanchspan = ckdocument.createElement("span")
        adjanchspan.setText(alert.suggestions[0].comment)
        anchorp.append(anchspan)
        divp.append(anchorp)
        divp.append(adjanchspan)
        commDiv.append(divp)
        alert.node.remove()
        alertToRemove.push(alert)

        const ckbody = this.ckEditor.instance.document.getBody()
        ckbody.append(commDiv)
      } else {
        const sentenceId = this.getSentenceID(alert.node)
        let i = 0;
        let ctaindexfound = true
        alert.suggestions.forEach((suggestion, ind) => {
          if (!ctaindexfound && suggestion.cta_present) {
            ctaindexfound = true;
            i = ind
          }
        })
        if (sentenceId != null && alert.suggestions[i].cta_present && !processed_sentences.includes(sentenceId)) {
          const suggestion = alert.suggestions[i]
          processed_sentences.push(sentenceId)
          var correction = suggestion['suggestion'];
          this.setTextAndRetainFormat(alert['node'], correction);
          this.socketService.accept(alert, suggestion, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.languageString.toString(), this.docTypeObject.selected_document_type, this.instructionModule);
          alertToRemove.push(alert)
        }
      }
    })

    // change only the below three lines if you don't want the changes to reflect(debug only)
    alertToRemove.forEach((alert) => {
      this.removeCard(alert);
    });
    this.changeDetector.detectChanges();
    this.spinner.hide()
  }

  private handleExtension(browserName: string, target: string) {
    if (this.COMPETITOR_BLOCK_SUPPORTED_BROWSER.has(browserName)) {
      this.clearTags()
    }
  }

  public onFocus(event) {
    setTimeout(() => {
      const browserName = this.getBrowserName()
      this.handleExtension(browserName, "grammarly")
    }, 1000);
  }

  public onDestroy(event) { }

  public onPaste(event) {
    if (this.trialMode) {
      let word_count = event.editor.document.getBody().getText().split(' ').length
      if (word_count >= 500) {
        this.setWordLimitExceed();
        event.data.dataValue = "";
        return;
      }

      if (word_count < 500) {
        let array_data = event.data.dataValue.replace("<p>", "").replace("</p>", "").split(' ');
        let r_word_count = 501 - word_count;
        let end_index = 0;
        if (r_word_count == array_data.length || array_data.length < r_word_count) {
          end_index = array_data.length;
          event.data.dataValue = array_data.splice(0, end_index).join(' ')
        }

        if (r_word_count < array_data.length) {
          end_index = r_word_count;
          event.data.dataValue = array_data.splice(0, end_index).join(' ')
        }
      }
      else {
        if (!this.wordLimitExceed) {
          this.setWordLimitExceed();
        }
        event.data.dataValue = "";
        return;
      }
    }
  }

  public onKeyPress(event) {
    if (this.trialMode) {
      if ((event.data.keyCode >= 48 && event.data.keyCode <= 57) || (event.data.keyCode >= 65 && event.data.keyCode <= 90) || (event.data.keyCode >= 96 && event.data.keyCode <= 105)) {
        let word_count = event.editor.document.getBody().getText().split(' ').length
        if (word_count >= 500) {
          this.setWordLimitExceed();
          return false;
        }
      }
      return true;
    }
    return true;
  }

  public onEditorReady() {
    if (!this.isInitialised) {
      this.editorLoader = true;
      this.contentChangeObservable
        .pipe(debounceTime(this.EVENT_LOOP_DELAY))
        .subscribe(() => {
          this.saveTextFile()
        });

      this.contentChangeForCCObservable
        .pipe(debounceTime(this.EVENT_LOOP_DELAY))
        .subscribe(() => this.sendDataToCOnsistencyCheckerAPI());

      this.healthCheckObservable
        .pipe(debounceTime(this.HEALTH_CHECK_TIMEOUT))
        .subscribe(() => this.connnectionHelthCheck());

      this.chunkObservable
        .pipe(debounceTime(this.CHUNK_RETRY_TIMEOUT))
        .subscribe(() => this.scheduleRetry());

      if (this.documentId) {
        this.getFileByIdCall();
      }
      this.isInitialised = true;

      let editorWrapper = document.getElementById('cke_editor-1');
      editorWrapper.addEventListener("scroll", () => {
        if (this._paraphraseFloatingOption.nativeElement.style.display === 'block') {
          this._paraphraseFloatingOption.nativeElement.style.display = 'none';
        }
      });

      let toolbar = document.querySelector(`[id^="cke_"][id$="_top"]`);
      toolbar.addEventListener('click', ($event) => {
        setTimeout(() => {
          this._paraphraseFloatingOption.nativeElement.style.display = 'none';
        }, 0);
      });
    }
    setTimeout(() => {
      const browserName = this.getBrowserName()
      this.handleExtension(browserName, "grammarly")
    }, 1000);
  }

  dialogLimitOver = null;
  private connectToSocket() {
    this.socketService.connectToSocket(this.documentId, this.paymentDetails.is_premium);

    this.socketService.socket.addEventListener('open', () => { });
    this.socketService.socket.addEventListener('close', () => { });
    this.socketService.socket.addEventListener('message', (event) => {
      var response = JSON.parse(this.socketService.decrypt_message(event.data));
      let socket_response = response['response'];
      if (socket_response != undefined || socket_response != null) {
        for (let index = 0; index < socket_response.length; index++) {
          if (socket_response[index]["suggestions"] != undefined) {
            if (socket_response[index]["suggestions"][0]["spell_status"] == 'conf') {
              socket_response[index]["suggestions"][0]["type"] = 1;
            }
          }
        }
      }
      if (!this.ckEditor.instance) {
        return;
      }
      if (response['type'] === 'ping') {
        this.socketService.ping();
        return;
      }
      if (this.sharedService.featurePermission.data.browser_caching && response['type'] === 'cache') {
        this.indexCache.deleteCacheServerDownTime(response);
      }

      if (this.sharedService.featurePermission.data.browser_caching && response['type'] === 'dict_action') {
        this.indexCache.deleteDictonaryFromCache(response['response']);
        this.removeAlerts(response['response']);
        return;
      }

      // TODO: find a better way to re-calculate hight of the document
      // setInterval(() => {
      // window.dispatchEvent(new Event('resize'));
      // this.onResize({});
      // }, 5000);

      switch (response['conn_status']) {
        case 1:
          if (response['message'] == "Trinka has resumed checking your content.") {
            // this.saveTextFile(false);
            var id = 0;
            this.indexCache.getDictonaryData().then((res => {
              if (res && res[0]) {
                id = res[0].i_d;
              }
            }));
            this.socketService.dict_action(id, new Date().getTime());
          }
          this.trinkaOnHold = false;
          this.readOnly = false;
          this.subjectiveBehaviourService.setFileReaduOnly(false);
          if (!this.ccInProgressStopGC) {
            if (response['response']) {
              this.processMessage(response);
            }
            if (this.requestToElementMap.size == 0) {
              this.scheduleRetry();
            }
          }
          break;
        case 3:
          if (response['message'] == 'LIMIT_EXHAUSTED') {
            if (!this.dialogLimitOver) {
              this.dialogLimitOver = this.dialog.open(UpgradeToPremiumComponent, { id: 'premium-pop-up', data: { "isPremium": this.creditsDetails['user_type'] == 'BASIC' ? false : true } })
              this.amplitudeService.logAmplitude('limit_over_popup', {
                'userID': this.userId
              });
            }
            this.userLimitExhausted = true;
            this.subjectiveBehaviourService.setFileReaduOnly(false);
            this.requestToElementMap.clear();
            this.saveTextFile();
          } else {
            this.dialogLimitOver = null;
            this.toastr.warning(response['message']);
            // this.sharedService.SignOut();
            this.readOnly = true;
            this.subjectiveBehaviourService.setFileReaduOnly(true);
            this.requestToElementMap.clear();
            this.enableSending = false;
            this.saveTextFile(false);
          }

          break;
        case 4:
          this.toastr.warning(response['message']);
          setTimeout(() => {
            this.sharedService.SignOut();
          }, 2000)
          break;
        case 5:
          this.dialog.open(FreeTrialModelComponent, {
            data: {
              trial: "trial_end",
              message: response['message']
            }
          });
          this.saveTextFile();
          break;
        default:
          // this.toastr.warning(response['message']);
          this.trinkaOnHold = true;
          this.enableSending = false;
          this.readOnly = false;
          this.subjectiveBehaviourService.setFileReaduOnly(false);
          break;
      }
    });
  }

  enableSendingRequest() {
    this.networkCalls.enableEditingCall(this.documentId).subscribe(result => {
      window.location.reload();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }
  private connnectionHelthCheck() {
    if (this.requestToElementMap.size > 0) {
      this.requestToElementMap.clear();
      this.socketService.close();
      if (this.IS_CONNECTED) {
        setTimeout(() => {
          this.connectToSocket();
        }, 2000);
      }
    }
  }
  cardCountForTrial = 0;
  cardCount = 15;
  processMessage(response) {
    switch (response['type']) {
      case this.CHECK_TYPE: {
        this.setIntoCache(response);
        this.processAlerts(response['response'], response['sentence']);
        this.calculateSummary();
        break;
      }
      case this.SPLIT_TYPE: {
        if (this.sharedService.featurePermission.data.browser_caching) {
          this.indexCache.setSplitCache(response['text'], response['response'], this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml')
        }
        this.createSentences(response['response'], response['text']);
        break;
      }
      case "dict_action": {
        this.indexCache.deleteDictonaryFromCache(response['response']);
        this.removeAlerts(response);
        break;
      }
    }
  }

  private removeAlerts(res: any) {
    this.alertList.forEach((alert) => {
      if (res.data) {
        res.data.forEach(element => {
          if (element.word == alert.text) {
            this.removeCard(alert);
            alert.node.remove(true);
          }
        })
      }
    })
  }

  private setIntoCache(response: any) {
    if (this.sharedService.featurePermission.data.browser_caching) {
      var spelling_words = [];
      if (response && response.hasOwnProperty('spelling_words')) {
        var spelling = response['spelling_words'];
        if (spelling) {
          spelling.forEach(result => {
            spelling_words.push(btoa(encodeURIComponent(result)));
          });
        }
      }

      this.indexCache.setSentenceCache(
        response['response'], response['sentence'],
        this.fileHolder.data.file_id,
        this.fileHolder.data.language,
        this.fileHolder.data.style_guide, this.docTypeObject.selected_document_type, this.enableSentenceFragment,
        this.enableSentenceRunon, this.enableSentenceComplexity, spelling_words, this.fileHolder.data.powermode_status, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.instructionModule);
    }
  }
  processResponceIfTryDemo(response) {
    if (response['response'].length) {
      this.cardCountForTrial = this.cardCountForTrial + this.calculateCont(response['response']);
    }
    this.processAlerts(response['response'], response['sentence']);
    this.calculateSummary();
  }
  calculateCont(alert) {
    var count = 0;
    return alert.map(item => count + item['suggestions'].length).reduce((a, b) => a + b, 0);
  }

  private scheduleRetry() {
    this.enableSending = true;
    this.saveTextFile();
  }


  itemList = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 10: 0, 11: 0 }
  private calculateSummary(flag = false, alert = null) {
    this.summaryDetails['total_error'] = this.alertList.length;
    if (!flag) {
      this.itemList = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 10: 0, 11: 0 };
      let selectedInclusiveFilterList = Object.values(this.sharedService.inclusiveLanguageFilterOptions).filter(filter => filter.checked);

      this.alertList.map(value => {
        if (value.alert_class == "grammarcc") {
          return false;
        } else {
          if (value.suggestions[0].type === 10) {
            let matchFilter = selectedInclusiveFilterList.filter(filter => filter.error_category === value.suggestions[0].error_category);
            if (matchFilter && matchFilter.length) {
              this.itemList[10] += 1;
            }
          } else {
            this.itemList[value.suggestions[0].type] += 1;
          }
        }
      });
    } else {
      let foundFilter = this.sharedService.filterCheckList.find(filter => filter.flag === alert.suggestions[0].type);
      if (foundFilter) {
        foundFilter.errorCount = this.itemList[foundFilter.flag] - 1;
      }
    }

    if (this.alertList.length) {
      this.sharedService.filterCheckList.forEach((filter: any) => {
        filter.errorCount = this.itemList[filter.flag];
      });
    } else {
      this._resetAllFilterErrorCount();
    }
  }

  displayVisibleErrorCount() {
    let totalCount = 0;
    this.sharedService.filterCheckList.forEach(filter => {
      if (filter.checked) { totalCount += filter.errorCount; }
    });
    return totalCount;
  }

  displayVisialbeCCErrorCount() {
    let total = 0
    this.sharedService.CCFilterCheckList.forEach((fcl) => {
      fcl.errorCount = this.cc_result.filter((res) => { return res.module == (fcl.name.toString().toLowerCase()) }).length
      total += this.cc_result.filter((res) => { return fcl.checked && res.module == (fcl.name.toString().toLowerCase()) }).length;
    })
    return total;
  }

  displayNoError() {
    // TODO: To be replaced with error handling logic
    try {
      return this.isInitialised
        && !this.readOnly
        && !this.trinkaOnHold
        && (this.alertList.length === 0)
        && (this.requestToElementMap.size === 0)
        && (this.ckEditor && this.ckEditor.instance && this.ckEditor.instance.document && this.ckEditor.instance.document.getBody() && this.ckEditor.instance.document.getBody().getElementsByTag(this.SENTENCE_TAG).count() > 0);
    } catch (e) {
      console.log('Catching error: ', e);
    }
  }

  selectedCategories() {
    var count = 0;
    this.sharedService.filterCheckList.forEach(filter => {
      if (filter.checked) { count += 1; }
    });
    return count;
  }


  filterByInclusiveCategory() {
    let categoriesToFilter = new Set();
    let filters: any = Object.values(this.sharedService.inclusiveLanguageFilterOptions)
    for (let index = 0, length = filters.length; index < length; index++) {
      const filter = filters[index];
      if (filter.checked) {
        categoriesToFilter.add(filter.error_category);
      } else {
        if (categoriesToFilter.has(filter.error_category)) {
          categoriesToFilter.delete(filter.error_category);
        }
      }
    }

    return categoriesToFilter;
  }

  filterCategories() {
    var countList = new Set();
    for (let index = 0, length = this.sharedService.filterCheckList.length; index < length; index++) {
      const filter = this.sharedService.filterCheckList[index];
      if (filter.checked) {
        countList.add(filter.flag);
      } else {
        if (countList.has(filter.flag)) {
          countList.delete(filter.flag);
        }
      }
    }

    let filters: any = Object.values(this.sharedService.inclusiveLanguageFilterOptions);
    for (let index = 0, length = filters.length; index < length; index++) {
      const filter = filters[index];
      if (filter.checked) {
        countList.add(filter.error_category);
      } else {
        if (countList.has(filter.error_category)) {
          countList.delete(filter.error_category);
        }
      }
    }

    return countList;
  }

  ccFilterCategory() {
    var countList = new Set();
    //Dashes
    if (this.sharedService.CCFilterCheckList[0].checked) {
      countList.add("dashes");
    } else {
      if (countList.has("dashes")) {
        countList.delete("dashes");
      }
    }
    //Spelling
    if (this.sharedService.CCFilterCheckList[1].checked) {
      countList.add("spelling");
    } else {
      if (countList.has("spelling")) {
        countList.delete("spelling");
      }
    }
    //Numbers
    if (this.sharedService.CCFilterCheckList[2].checked) {
      countList.add("numbers");
    } else {
      if (countList.has("numbers")) {
        countList.delete("numbers");
      }
    }
    //Symbol
    if (this.sharedService.CCFilterCheckList[3].checked) {
      countList.add("symbol");
    } else {
      if (countList.has("symbol")) {
        countList.delete("symbol");
      }
    }
    //Punctuations
    if (this.sharedService.CCFilterCheckList[4].checked) {
      countList.add("punctuations");
    } else {
      if (countList.has("punctuations")) {
        countList.delete("punctuations");
      }
    }
    //Spacing
    if (this.sharedService.CCFilterCheckList[5].checked) {
      countList.add("spacing");
    } else {
      if (countList.has("spacing")) {
        countList.delete("spacing");
      }
    }
    //table_title
    if (this.sharedService.CCFilterCheckList[6].checked) {
      countList.add("table_title");
    } else {
      if (countList.has("table_title")) {
        countList.delete("table_title");
      }
    }
    //figure_title
    if (this.sharedService.CCFilterCheckList[7].checked) {
      countList.add("figure_title");
    } else {
      if (countList.has("figure_title")) {
        countList.delete("figure_title");
      }
    }
    //box_title
    if (this.sharedService.CCFilterCheckList[8].checked) {
      countList.add("box_title");
    } else {
      if (countList.has("box_title")) {
        countList.delete("box_title");
      }
    }
    //boxes_title
    if (this.sharedService.CCFilterCheckList[9].checked) {
      countList.add("boxes_title");
    } else {
      if (countList.has("boxes_title")) {
        countList.delete("boxes_title");
      }
    }
    //p_value
    if (this.sharedService.CCFilterCheckList[10].checked) {
      countList.add("p_value");
    } else {
      if (countList.has("p_value")) {
        countList.delete("p_value");
      }
    }
    //accent_chars
    if (this.sharedService.CCFilterCheckList[11].checked) {
      countList.add("accent_chars");
    } else {
      if (countList.has("accent_chars")) {
        countList.delete("accent_chars");
      }
    }
    if (this.sharedService.CCFilterCheckList[12].checked) {
      countList.add("tables_title");
    } else {
      if (countList.has("tables_title")) {
        countList.delete("tables_title");
      }
    }
    if (this.sharedService.CCFilterCheckList[13].checked) {
      countList.add("figures_title");
    } else {
      if (countList.has("figures_title")) {
        countList.delete("figures_title");
      }
    }
    if (this.sharedService.CCFilterCheckList[14].checked) {
      countList.add("symbol_spacing");
    } else {
      if (countList.has("symbol_spacing")) {
        countList.delete("symbol_spacing");
      }
    }
    return countList;
  }

  ngModelChangeCCEvent(event: KeyboardEvent) {
    if (event != this.prevCCData) {
      this.prevCCData = event;
      this.contentChangeObservable.next(event);
      if (event) {
        var content = this.ckEditor.instance.document.getBody().getHtml();
        content = content.replace(this.ALERT_REGEX, "").replace(this.SENTENCE_REGEX, "").replace(this.SPECIAL_SPACES, "");
        content = content.replace(this.CC_ALERT_REGEX, "").replace(this.SENTENCE_REGEX, "").replace(this.SPECIAL_SPACES, "");

        content = this.preBody + "<body>" + this.COMMENT_HOLDER + content + "</body>" + this.postBody;

        if (this.enabledModule == ModuleCheck.CC) {
          content = this.preBody + "<body>" + this.COMMENT_HOLDER + content + "</body>" + this.postBody;
          this.contentChangeForCCObservable.next(event);
        }
      }
    }
  }

  @HostListener('window:load', ['$event'])
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHeight = window.innerHeight;
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    try {
      var toolbar = document.querySelector('[id^="cke_"][id$="_top"]') as HTMLElement | null;
      if (toolbar && document.documentElement.scrollTop > 50) {
        toolbar.style.top = '0';
        toolbar.style.bottom = '0';
        toolbar.style['z-index'] = '1';
        toolbar.style.position = 'fixed';
        toolbar.style.height = '44px';
        toolbar.style.background = 'white';
        toolbar.style.width = 'calc(100% - 33.33%)';
        toolbar.style.left = '44px';
        toolbar.style.borderBottom = 'none';
        toolbar.style.boxShadow = '0px 0px 9px rgba(0, 0, 0, 0.21)';
        document.getElementsByClassName('filter')[0]['classList']['add']('errorPanelToolbar');
        document.getElementsByClassName('errorPanel')[0]['classList']['add']('errorWindowScroll');
        document.getElementsByClassName('filter_toggle_button')[0]['classList']['add']('mobile_alerts');
      } else {
        document.getElementsByClassName('filter')[0]['classList']['remove']('errorPanelToolbar');
        document.getElementsByClassName('errorPanel')[0]['classList']['remove']('errorWindowScroll');
        document.getElementsByClassName('filter_toggle_button')[0]['classList']['remove']('mobile_alerts');
        toolbar.style.removeProperty('top');
        toolbar.style.removeProperty('bottom');
        toolbar.style.removeProperty('position');
        // toolbar.style.removeProperty('background');
        toolbar.style.removeProperty('width');
        toolbar.style.removeProperty('z-index');
        toolbar.style.height = 'auto';
        toolbar.style.borderBottom = 'none';
        toolbar.style.boxShadow = 'none';
      }
    } catch (error) {
    }
  }

  openFilterModal() {
    var filterModal = this.dialog.open(FilterModalComponent);
    filterModal.afterClosed().subscribe(result => {
    });
  }

  docTypeObject = {
    selected_document_type: null,
    selected_paper_type: null,
    user_added_subject_area: null,
    selected_subject_area: null,
    subject_area_list: null,
  };

  getModelName(model) {
    this.instructionModule.module = model;
    if (model == 'basic') {
      return "Lite Mode";
    } else if (model == 'advanced') {
      return "Power Mode";
    } else {
      return "Power Mode";
    }
  }
  getFileByIdCall() {
    this.editorLoader = true;
    this.networkCalls.getFileById(this.userId, this.documentId).subscribe(result => {
      this.sharedService.renameFile = result.data;
      //console.log("result.data ", result.data)
      var content = result.data.content.split(/<\/?body[^>]*>/g);
      this.fileHolder = result;
      this.sharedService.setCurrentFileName(this.fileHolder.data.file_name);
      this.editingModeSelectedOption = this.getModelName(this.fileHolder.data.module);

      this.amplitudeService.logAmplitude('File_Open_CTA', {
        'User ID': this.userId,
        'File Id': this.fileHolder.data.file_id,
        'editor': 'V0',
        'file': result.data.skip_status ? 'New' : 'Existing'
      });
      this.preBody = content[0]
      var editiorContent = "" + content[1];
      editiorContent = editiorContent.replace(this.ALERT_REGEX, "").replace(this.SPECIAL_SPACES, "").replace(this.COMMENT_HOLDER, "").replace(this.COMMENT_DIV_REGEX, "");
      this.editorValue = editiorContent;
      this.postBody = content[2];
      this.changeDetector.detectChanges();
      this.selectedLanguageCode = result.data.lang_code.length > 0 ? result.data.lang_code.split(",") : [];
      this.defaultLanguageCode = result.data.lang_code.length > 0 ? result.data.lang_code.split(",") : [];
      this.selectLanguageOptions();
      this.subjectiveBehaviourService.changeStyleGuide(this.fileHolder.data.style_guide, false);
      //console.log("this.fileHolder.data ", this.fileHolder.data)
      this.subjectiveBehaviourService.changeInstuctionModule('child_to_parent', this.fileHolder.data['instruction'], this.fileHolder.data['module'].trim() == "" ? "advanced" : this.fileHolder.data['module']);
      this.subjectiveBehaviorSharedService.setPowerModePremiumStatus(this.fileHolder.data.powermode_status);

      let filterOption = this.sharedService.filterCheckList.find(filter => filter.flag === 10);
      if (!this.selectedLanguageCode.includes('en')) {
        filterOption.disabled = true;
        filterOption.checked = false;
      } else {
        filterOption.disabled = false;
        filterOption.checked = true;
      }

      this.docTypeObject = {
        selected_document_type: this.fileHolder.data.document_type,
        selected_paper_type: this.fileHolder.data.paper_type,
        user_added_subject_area: this.fileHolder.data.added_subject_area,
        selected_subject_area: this.fileHolder.data.selected_subject_area,
        subject_area_list: JSON.parse(JSON.stringify(this.fileHolder.data.subject_area_list))
      }
      this.docTypeObject['subject_area_list'] = this.docTypeObject['subject_area_list'].sort((a, b) => b.user_added_flag - a.user_added_flag).sort((a, b) => b.isSelected - a.isSelected)
      this.subjectiveBehaviorSharedService.setDocumentTypeData(this.docTypeObject, false);
      this.subjectiveBehaviorSharedService.setPowerModePremiumStatus(this.fileHolder.data.powermode_status)
      if (this.docTypeObject['selected_document_type'] != 1) {
        this.amplitudeService.logAmplitude('writing_type_set', {
          'fileID': this.sharedService.currentFileId,
          'type': this.docTypeObject['selected_document_type'] == 3 ? 'academic' : 'general',
          'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
        });
      }
      // this.spinner.hide();
      this.editorLoader = false;
      if (this.sharedService.featurePermission.data.browser_caching) {
        this.indexCache.getCacheMetaData().then((res) => {
          if (res.length != 0) {
            this.socketService.cache(JSON.parse(this.indexCache.decodeStringResponse(res[0].response)));
          } else {
            this.socketService.cache(JSON.parse("{\"id\":0,\"server_state\":[],\"flush\":false}"));
          }
        })
      }
      if (this.IS_CONNECTED) {
        this.connectToSocket();
      }

      if (result.data.skip_status) {
        let defaultLang = this.fileHolder.data.language;
        const dialogRef = this.dialog.open(SaveNewFileDetailsModalComponent, { data: { file_id: this.documentId, lang: defaultLang, lang_code: this.selectedLanguageCode } });
        dialogRef.afterClosed().subscribe(resp => {
          this._documentSettings = resp;
          if (resp) {
            this.selectedLanguageCode = resp.data.lang_code.split(",");
            this.selectLanguageOptions();
            if (defaultLang != resp.data.language || resp.data.document_type != 1) {
              this.subjectiveBehaviourService.changeStyleGuide(resp.data.style_guide, false);
              this.docTypeObject = {
                selected_document_type: resp.data.document_type,
                selected_paper_type: resp.data.paper_type,
                user_added_subject_area: resp.data.added_subject_area,
                selected_subject_area: resp.data.selected_subject_area,
                subject_area_list: JSON.parse(JSON.stringify(resp.data.subject_area_list))
              };

              this.docTypeObject['subject_area_list'] = this.docTypeObject['subject_area_list'].sort((a, b) => b.user_added_flag - a.user_added_flag).sort((a, b) => b.isSelected - a.isSelected);
              this.subjectiveBehaviorSharedService.setDocumentTypeData(this.docTypeObject, true);

              this.ccInProgressStopGC = false;
              this.requestToElementMap.clear();
              this.readOnly = false
              this.cc_result = [];
              this.alertList = [];
              this._resetAllFilterErrorCount();
              this.cardCountForTrial = 0;
              this.prevCCData = null;
              var body = this.ckEditor.instance.document.getBody();
              this.removeSentences(body);
              this.scanDocument();
              window.dispatchEvent(new Event('resize'));
            }
            this.changeDetector.detectChanges();
          }

        });
      }
    }, error => {
      this.sharedService.errorHandller(error);
    });
    this.sharedService.instructionModule.languages = this.showAllSelectedCode();
  }

  muteCard(alert, suggestion, index, parrentIndex) {
    if (environment.trialMode) {
      this.dialog.open(SignupaccessComponent);
      return;
    }
    if (this.sharedService.featurePermission.data.browser_caching) {
      this.deleteAddToDictionaryWordRespectiveSentenceFromCache(alert);
    }

    this.socketService.mute(alert, suggestion, this.fileHolder.data.language, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.languageString.toString(), this.instructionModule);
    this.processSentence(alert.sentence_node)
    this.removeCard(alert);
    this.removeSimillarCards(alert);
  }
  deleteAddToDictionaryWordRespectiveSentenceFromCache(alert) {
    var sameSpellingList = this.alertList.filter(item => {
      return item.alert_class == "spelling" && item.text == alert.text
    })
    sameSpellingList.forEach(alrt => {
      this.indexCache.deleteCacheSentence(alrt['sentence'], this.fileHolder.data.language, this.styleGuide, this.docTypeObject.selected_document_type, this.enableSentenceFragment, this.enableSentenceRunon, this.enableSentenceComplexity, this.fileHolder.data.powermode_status, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.instructionModule);
    })
  }

  acceptNonCta(alert, suggestion, index, parrentIndex) {
    this.socketService.acceptnoncta(alert, suggestion, this.fileHolder.data.language, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.languageString.toString());
    this.removeCard(alert);
    this.removeSimillarCards(alert);
  }

  hasSimilarCardToAccept(alertToCheck, suggestion) {
    return suggestion.type == 2 && this.getSimilarAcceptCard(alertToCheck, suggestion).length > 1;
  }

  acceptCard(alertToCheck, suggestion, index, acceptAll) {
    if (acceptAll && suggestion.type == 2) {
      this.getSimilarAcceptCard(alertToCheck, suggestion)
        .forEach(alert => this.innerAcceptCard(alert[0], alert[1]));
    } else {
      this.innerAcceptCard(alertToCheck, suggestion);
    }
    this.changeDetector.detectChanges();
  }

  private getSimilarAcceptCard(alertToCheck, suggestion) {
    return this.alertList
      .filter(alert => alert['text'] === alertToCheck['text'])
      .map(alert => {
        let matchSuggestion = alert['suggestions']
          .filter(sugg => sugg['type'] === suggestion['type'])
          .filter(sugg => sugg['tag'].indexOf('us_uk_spelling') >= 0)
          .filter(sugg => sugg['suggestion'] === suggestion['suggestion']);
        return [alert, (matchSuggestion.length > 0) ? matchSuggestion[0] : null];
      })
      .filter(alert => alert[1] !== null);
  }

  private innerAcceptCard(alert, suggestion) {
    var correction = suggestion['suggestion'];
    this.setTextAndRetainFormat(alert['node'], correction);
    this.socketService.accept(alert, suggestion, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.languageString.toString(), this.docTypeObject.selected_document_type, this.instructionModule);
    this.removeCard(alert);
    this.cardCountForTrial = --this.cardCountForTrial;
  }


  hasSimilarCardToIgnore(alertToCheck) {
    return this.getSimilarIgnoreCard(alertToCheck).length > 1;
  }

  ignoreCard(alertToCheck, index, ignoreAll) {
    let similarAlerts = this.getSimilarIgnoreCard(alertToCheck)
    if (!ignoreAll) {
      similarAlerts = similarAlerts.filter(alert => alert['sentence'] === alertToCheck['sentence'])
    }
    similarAlerts.forEach(alert => this.innerIgnoreCard(alert));
  }

  private getSimilarIgnoreCard(alertToCheck) {
    let type = alertToCheck['suggestions'][0]['type'];
    if (type !== 1) {
      return this.alertList
        .filter(alert => alert['text'] === alertToCheck['text'])
        .filter(alert => alert['suggestions'][0]['type'] === type)
    }
    return [alertToCheck];
  }

  private innerIgnoreCard(alert) {
    alert.suggestions.forEach((suggestion) => {
      if (suggestion.error_category === "Fragment") {
        suggestion.type = 6;
      }
      if (suggestion.error_category === "Complexity") {
        suggestion.type = 7;
      }
      if (suggestion.error_category === "RunOn") {
        suggestion.type = 8;
      }
      if (this.sharedService.featurePermission.data.browser_caching) {
        this.indexCache.deleteCacheSentence(alert['sentence'], this.fileHolder.data.language, this.styleGuide, this.docTypeObject.selected_document_type, this.enableSentenceFragment, this.enableSentenceRunon, this.enableSentenceComplexity, this.fileHolder.data.powermode_status, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.instructionModule);
      }
      this.socketService.ignore(alert, suggestion, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.languageString.toString(), this.docTypeObject.selected_document_type)
      this.processSentence(alert.sentence_node)
    })
    this.removeCard(alert);
    this.cardCountForTrial = --this.cardCountForTrial;
  }

  rejectSuggestions(alert, parrentIndex) {
    alert.suggestions.forEach((suggestion) => {
      if (this.sharedService.featurePermission.data.browser_caching) {
        this.indexCache.deleteCacheSentence(alert['sentence'], this.fileHolder.data.language, this.styleGuide, this.docTypeObject.selected_document_type, this.enableSentenceFragment, this.enableSentenceRunon, this.enableSentenceComplexity, this.fileHolder.data.powermode_status, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.instructionModule);
      }
      this.socketService.reject(alert, suggestion, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.languageString.toString(), this.docTypeObject.selected_document_type, this.instructionModule);
      this.processSentence(alert.sentence_node)
    })
    this.removeCard(alert);
    this.cardCountForTrial = --this.cardCountForTrial;
  }

  private removeSimillarCards(alertToCheck) {
    this.alertList
      .filter(alert => alert.suggestions[0].type == 2)
      .filter(alert => alert.text.toLowerCase() == alertToCheck.text.toLowerCase())
      .forEach(alert => {
        this.removeCard(alert);
      });
  }


  private removeCard(alert) {
    alert['node'].remove(true);
    this.saveTextFile();
    this.calculateSummary(true, alert);
    this.addOrUpdateStatsComment();
  }

  showAlertTextOnEditor(original_scroll, original_element) {
    var editor_height = original_element.clientHeight
    var half_window = editor_height / 2;
    if ((original_scroll - original_element.scrollTop) != 0) {
      // scrolled down
      original_element.scrollTop = original_element.scrollTop - (half_window)
    }
  }

  ScrollErrorPanel(original_scroll, index) {
    var scroll_offset = window.innerHeight * 0.3;
    let card_scroll_val = (index * (48 + 10)) - scroll_offset;
    var errorPanel = document.getElementsByClassName('errorPanel');
    setTimeout(() => {
      errorPanel[0].scrollTop = card_scroll_val;
    }, 500);
  }

  openCard(alert, flag = false) {
    var index = this.alertList.filter((element, array, index) => {
      return this.filterCategories().has(element.suggestions[0].type);
    }).findIndex(itm => itm == alert);
    var original_element = document.getElementsByClassName("outer_scroll_sections")
    var original_scroll = original_element[0].scrollTop
    alert['node'].scrollIntoView(true)
    this.showAlertTextOnEditor(original_scroll, original_element[0])
    this.removeNodeHeighlight();
    if (flag) {
      this.ScrollErrorPanel(original_scroll, index)
    }
    alert['node'].addClass(alert['alert_class'] + '_active');
    alert['isExpanded'] = true;
    this.subjectiveBehaviorSharedService.setCardIsExpanded(alert['isExpanded']);
  }

  closeCard(alert) {
    alert['node'].removeClass(alert['alert_class'] + '_active');
    alert['isExpanded'] = false;
    this.subjectiveBehaviorSharedService.setCardIsExpanded(alert['isExpanded']);
  }

  // Private Function
  private getAlertClass(alert) {
    if (alert.suggestions[0].type == 1) {
      return "grammar";
    } else if (alert.suggestions[0].type == 2) {
      return "spelling"
    } else {
      return "suggestion"
    }
  }

  private saveTextFile(flag = true) {
    if (this.readOnly) {
      var body = this.ckEditor.instance.document.getBody();
      this.removeSentences(body);
      this.scanAlerts();
      this.calculateSummary();
    } else {
      this.processDocumentForBreak();
      this.scanDocument();
      this.scanAlerts();
      this.calculateSummary();
      if (this.enableSending) {
        this._stack.jobsProcessingIntervalTime = 5000;
        this._stack.restartJobProcessing();
        this.sentDataCall();
      } else {
        this.sentDataCall();
      }
    }
    this.isScanCalculated = false;
    this.noOfScanRequired = 0;
  }

  private scanAlerts() {
    var alertsCount = this.alertList.length;
    for (var i = this.alertList.length - 1; i >= 0; i--) {
      if (!this.alertList[i]['node'].isVisible()) {
        this.alertList.splice(i, 1);
      }
    }
    if (alertsCount !== this.alertList.length) {
      this.addOrUpdateStatsComment();
    }
  }
  addTransitionCard(i: number) {
    if (this.transitionAlertObj) {
      var temp = this.alertList.filter(itm => { return itm['isTransitionCard'] });
      if (temp.length <= 0) {
        this.transitionAlertObj['isTransitionCard'] = true;
        this.alertList.splice(i, 0, this.transitionAlertObj);
      }
    }
  }
  savingSection = false;
  interval = null;

  private sentDataCall(callback?, forceUpdate: boolean = false) {
    this._stack.add((callback, forceUpdate) => this._saveFile(callback, forceUpdate), { params: { callback, forceUpdate }, timeStamp: new Date() });
    if (!this._stack.isProcessingStarted) { // control when to start job processing
      this._stack.isProcessingStarted = true;
      this._stack.startJobProcessing();
    }
  }

  private _saveFile(callback?, forceUpdate: boolean = false) {
    console.log("saving ....")
    return new Promise((resolve, reject) => {

      var content = this.ckEditor.instance.document.getBody().getHtml();
      content = content.replace(this.ALERT_REGEX, "")
        .replace(this.SENTENCE_REGEX, "")
        .replace(this.CC_ALERT_REGEX, "")
        .replace(this.SPECIAL_SPACES, "")
        .replace("</div><p>&nbsp;</p><p>&nbsp;</p><div><div style=\"-aw-headerfooter-type:header-primary\">", "</div><br style=\"clear:both; mso-break-type:section-break\"/><div><div style=\"-aw-headerfooter-type:header-primary\">")
        .replace("</div><p>&nbsp;</p><div><div style=\"-aw-headerfooter-type:header-primary\">", "</div><br style=\"clear:both; mso-break-type:section-break\"/><div><div style=\"-aw-headerfooter-type:header-primary\">");

      content = this.preBody + "<body>" + this.COMMENT_HOLDER + content + this.commentDiv + "</body>" + this.postBody;
      this.subjectiveBehaviorSharedService.getEditorLanguage().subscribe(language => {
        this.selectedLanguageCode = language.split(',');
        this.languageCodeOptions.forEach((x) => {
          x.isSelected = this.selectedLanguageCode.indexOf(x.code) > -1;
        });
        this.languageString = this.languageCodeOptions.filter((x) => x.isSelected).map(obj => obj.value);
      })
      // Document type payload
      this.fileHolder.data.document_type = this.docTypeObject['selected_document_type'];
      this.fileHolder.data.paper_type = this.docTypeObject['selected_paper_type'];
      this.fileHolder.data.selected_subject_area = this.docTypeObject['selected_subject_area'];
      this.fileHolder.data.added_subject_area = this.docTypeObject['user_added_subject_area'];
      this.fileHolder.data.subject_area_list = this.docTypeObject['subject_area_list'].sort((a, b) => b.user_added_flag - a.user_added_flag).sort((a, b) => b.isSelected - a.isSelected);
      this.fileHolder.data['powermode_active'] = this.powerModeSelection;
      this.fileHolder.data['lang_code'] = this.selectedLanguageCode.toString();
      this.fileHolder.data['module'] = this.instructionModule.module
      this.fileHolder.data['instruction'] = this.instructionModule.instruction

      if (forceUpdate || this.fileHolder.data.content !== content) {
        this.savingSection = true;
        this.fileHolder.data.content = content;
        this.saving = true;
        if (this.interval != null) {
          clearInterval(this.interval);
        }
        this.fileHolder.data['style_guide'] = this.styleGuide ? this.styleGuide : "NONE"
        this.networkCalls.putUpdateFile(this.userId, this.fileHolder.data).subscribe(result => {
          this.saving = false;
          this.savingSection = true;
          this.lastSavedTime = new Date();
          if (callback) { callback(); }

          this.interval = setTimeout(() => {
            this.savingSection = false;
          }, 3000);

          resolve(result);
        }, error => {
          this.saving = false;
          this.sharedService.errorHandlerForFIleSave(error);

          reject(error);
        });
      } else {
        resolve(true);
      }

    });
  }

  private addAlert(alert) {
    var minIndex = 0;
    var maxIndex = this.alertList.length;
    while (maxIndex - minIndex > 1) {
      var index = Math.floor((maxIndex + minIndex) / 2);
      if (this.alertList[index]['node'].getPosition(alert['node']) === this.POSITION_PRECEDING) {
        minIndex = index;
      } else {
        maxIndex = index;
      }
    }
    if (minIndex === 0 && this.alertList.length > 0 && this.alertList[minIndex]['node'].getPosition(alert['node']) !== this.POSITION_PRECEDING) {
      maxIndex = 0;
    }
    alert['isVisible'] = true;
    this.alertList.splice(maxIndex, 0, alert);
    this.changeDetector.detectChanges();
  }

  private canProcessRequest(element, type: string) {
    var key = type + "_" + element.getText().trim();
    if (this.requestToElementMap.has(key)) {
      var request = this.requestToElementMap.get(key);
      this.requestToElementMap.set(key, request)
      if (request['next_retry'] >= Date.now()) {
        return false;
      }
    }
    this.changeDetector.detectChanges()
    return true;
  }

  private createRequest(element, type: string) {
    if (this.enabledModule === ModuleCheck.PP && type !== this.SPLIT_TYPE) return;
    var key = type + "_" + element.getText().trim();
    var request = {
      'element': element,
      'next_retry': (Date.now()) + this.REQUEST_RETRY_TIMEOUT
    }
    this.requestToElementMap.set(key, request);
    this.changeDetector.detectChanges();
  }

  private getAndDeleteRequest(text: string, type: string) {
    var key = type + "_" + text.trim();
    if (this.requestToElementMap.has(key)) {
      var request = this.requestToElementMap.get(key);
      this.requestToElementMap.delete(key);
      this.changeDetector.detectChanges();
      return request['element'];
    }
    return null;
  }

  private processDocumentForBreak() {
    var body = this.ckEditor.instance.document.getBody();
    if (body.getText().trim() == '') {
      this.cardCountForTrial = 0;
      return;
    }
    var breaks = body.getElementsByTag('br');
    while (breaks.count() > 0) {
      this.fixDomBr(breaks.getItem(0));
      var breaks = body.getElementsByTag('br');
    }
  }

  private fixDomBr(breaker) {
    while (!breaker.getParent().isBlockBoundary()) {
      this.breakParent(breaker);
    }
    if (breaker.getParent().getName() != 'p') {
      var newParent = breaker.getDocument().createElement("p");
      newParent.insertAfter(breaker);
      newParent.append(breaker, true);
    }
    this.breakParent(breaker);
    this.insertSpace(breaker.getPrevious());
    this.insertSpace(breaker.getNext());
    breaker.remove();
  }

  private breakParent(breaker) {
    var parent = breaker.getParent();
    if ((breaker.getParent().getChildCount() - 1) != breaker.getIndex() && breaker.getIndex() != 0) {
      breaker.breakParent(parent);
    }
    if (breaker.getIndex() == 0) {
      breaker.insertBefore(parent);
    } else {
      breaker.insertAfter(parent);
    }
  }

  private insertSpace(element) {
    if (element == null) {
      return;
    }
    let text = element.getText();
    text = text.replace(this.SPECIAL_SPACES, '');
    if (text !== "") {
      return;
    }
    while (element.getChildCount() > 0) {
      let child = element.getChild(0);
      if (child.type != this.ELEMENT_NODE) {
        break;
      }
      element = child;
    }
    element.setText("\u00A0");
  }
  // Private Function end

  // Grammar Checking Logic
  private setTextAndRetainFormat(node, text) {
    while (node.getChildCount() > 0 && node.getChild(0).type == this.ELEMENT_NODE) {
      for (let index = 1; index < node.getChildCount();) {
        node.getChild(index).remove();
      }
      node = node.getChild(0);
    }
    node.setText(text);
  }

  private scanDocument() {
    if (!this.ccInProgressStopGC) {
      var body = this.ckEditor.instance.document.getBody();
      var sections = this.hasAllSections(body) ? this.getSections(body) : [body];
      let sectionsFiltered = sections.filter(section => section.getText().trim() != "");
      let text = sectionsFiltered.map(section => section.getText())
      this.emptyDocument = !(text.length > 0);
      if (this.emptyDocument) {
        this.requestToElementMap.clear();
        this.changeDetector.detectChanges();
      }

      sections.forEach(node =>
        this.processSection(node)
      );
    }
  }

  private getSections(element) {
    var sections = [];
    for (let index = 0; index < element.getChildCount(); index++) {
      var child = element.getChild(index);
      if (!this.isCommentSection(child)) {
        if (this.hasAllSections(child)) {
          sections = sections.concat(this.getSections(child));
        } else {
          sections.push(child);
        }
      }
    }
    return sections;
  }

  private isCommentSection(element) {
    let id = element.getId();
    return (id != null && id.startsWith("_cmnt"));
  }

  private hasAllSections(element) {
    var children = element.getChildren();
    for (let index = 0; index < children.count(); index++) {
      var child = children.getItem(index);
      if (child.type != this.ELEMENT_NODE || !child.isBlockBoundary()) {
        return false;
      }
    }
    return true;
  }

  private processSection(section) {
    if (section.getText().trim() == "") {
      this.rememberText(section);
    } else if (this.isProcessingRequired(section)) {
      if (this.isSingleSentence(section)) {
        var sentences = section.getElementsByTag(this.SENTENCE_TAG);
        var modifiedSentences = this.getModifiedSentences(sentences);
        this.rememberText(section);
        modifiedSentences.forEach(sentence => this.processSentence(sentence));
      } else {
        this.removeSentences(section);
        this.splitSection(section);
      }
    } else {
      this.scanSection(section);
    }
  }

  private getModifiedSentences(sentences) {
    var modifiedSentences = [];
    for (let index = 0; index < sentences.count(); index++) {
      const element = sentences.getItem(index);
      if (this.isProcessingRequired(element)) {
        modifiedSentences.push(element);
      }
    }
    return modifiedSentences;
  }

  private isSingleSentence(sentence) {
    var lastKnownText = sentence.getCustomData(this.LAST_KNOWN_TEXT);
    if (lastKnownText) {
      var lastKnownTerminals = (lastKnownText.match(this.TERMINAL) || []).length
      var currentTerminals = (sentence.getText().match(this.TERMINAL) || []).length
      return lastKnownTerminals === currentTerminals;
    } else {
      return false;
    }
  }

  private removeSentences(section) {
    this.removeTags(section, this.ALERT_TAG);
    this.removeTags(section, this.SENTENCE_TAG);
    section.forEach(node => {
      node.setCustomData(this.LAST_KNOWN_TEXT, "");
    });
  }

  private splitSection(section) {
    var text = section.getText();
    if (text.match(this.TERMINAL)) {
      if (this.enableSending) {
        if (this.requestToElementMap.size >= this.CHUNK_SIZE) {
          this.enableSending = false;
          this.chunkObservable.next();
        }
        if (this.canProcessRequest(section, this.SPLIT_TYPE)) {
          this.createRequest(section, this.SPLIT_TYPE);
          if (!this.sharedService.featurePermission.data.browser_caching) {
            this.socketService.split(text, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.docTypeObject.selected_document_type, this.styleGuide);
            this.healthCheckObservable.next();
          } else {
            this.indexCache.getSplitFromCache(text, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml').then((res) => {
              if (res) {
                this.createSentences(JSON.parse(this.indexCache.decodeStringResponse(res['response'])), this.indexCache.decodeStringResponse(res['spltTxt']));
              } else {
                this.socketService.split(text, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.docTypeObject.selected_document_type, this.styleGuide);
                this.healthCheckObservable.next();
              }
            })
          }

        }
      }
    } else {
      var indexes = [{ 'begin': 0, 'end': text.length }];
      this.createTags(section, indexes, this.SENTENCE_TAG);
      this.rememberText(section);
      this.scanSection(section);
    }
  }



  private createSentences(sentences, text: string) {
    var section = this.getAndDeleteRequest(text, this.SPLIT_TYPE);
    if (section && section.isVisible() && text === section.getText()) {
      this.createTags(section, sentences, this.SENTENCE_TAG);
      this.rememberText(section);
      this.scanSection(section);
    }
  }

  private scanSection(section) {
    var sentences = section.getElementsByTag(this.SENTENCE_TAG);
    for (let index = 0; index < sentences.count(); index++) {
      var node = sentences.getItem(index);
      if (this.isProcessingRequired(node) && this.enabledModule !== ModuleCheck.PP) {
        this.processSentence(node);
      }
    }
  }

  private processSentence(sentence) {
    this.removeTags(sentence, this.ALERT_TAG);
    if (sentence.isVisible() && this.enableSending) {
      if (this.requestToElementMap.size >= this.CHUNK_SIZE) {
        this.enableSending = false;
        this.chunkObservable.next();
      }
      if (this.canProcessRequest(sentence, this.CHECK_TYPE)) {
        this.createRequest(sentence, this.CHECK_TYPE);
        if (!this.sharedService.featurePermission.data.browser_caching) {
          this.instructionModule.module =  this.CheckModule(this.instructionModule);
          this.socketService.check(sentence.getText(), this.fileHolder.data.language, this.styleGuide, this.enableSentenceFragment, this.enableSentenceComplexity, this.enableSentenceRunon, this.REQ_ID, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.docTypeObject.selected_document_type, this.instructionModule);
          this.healthCheckObservable.next();
        } else {
          this.indexCache.getSentenceFromCache(sentence.getText(), this.fileHolder.data.language,
            this.fileHolder.data.style_guide, this.docTypeObject.selected_document_type, this.enableSentenceFragment,
            this.enableSentenceRunon, this.enableSentenceComplexity, this.fileHolder.data.powermode_status, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.instructionModule).then((res) => {
              this.processSentenceWithId(res, sentence, (result, request_id) => {
                if (result && result.response) {
                  let socket_response = JSON.parse(this.indexCache.decodeStringResponse(result['response']));
                  for (let index = 0; index < socket_response.length; index++) {
                    if (socket_response[index]["suggestions"][0]["spell_status"] == 'conf') {
                      socket_response[index]["suggestions"][0]["type"] = 1;
                    }
                  }
                  this.processAlerts(socket_response, this.indexCache.decodeStringResponse(result['s']));
                  this.calculateSummary();
                } else {
                  this.instructionModule.module = this.CheckModule(this.instructionModule);
                  this.socketService.check(sentence.getText(), this.fileHolder.data.language, this.styleGuide, this.enableSentenceFragment, this.enableSentenceComplexity, this.enableSentenceRunon, request_id, this.selectedLanguageCode.length === 1 ? this.selectedLanguageCode[0] : 'ml', this.docTypeObject.selected_document_type, this.instructionModule);
                  this.healthCheckObservable.next();
                }
              });
            })
        }
      }
    }
  }

  CheckModule(instructionModule){
    if(["Turtle","turtle","","megainstruct"].includes(instructionModule.module)){
      return "advanced"
    } else {
      return instructionModule.module;
    }
  }
  onLanguageTypeSelect() {
    this.sentDataCall(() => {
      this.amplitudeService.logAmplitude('language_preference', {
        'locale': this.showAllSelectedCode()
      });
      window.location.reload();
    }, true);
  }

  private processAlerts(alerts, text: string) {
    var sentence = this.getAndDeleteRequest(text, this.CHECK_TYPE);
    if (sentence && sentence.isVisible() && text === sentence.getText()) {
      this.rememberText(sentence);
      if (alerts.length > 0) {
        let filteredAlerts = [];
        for (const alert of alerts) {
          if (alert.suggestions[0]["spell_status"] !== "unknown_not_in_dict")
            filteredAlerts.push(alert)
        }

        var indexes: Array<any> = [];
        for (const alert of filteredAlerts) {
          indexes.push({
            'begin': alert['begin'],
            'end': alert['end'] + 1
          });
        }
        this.createTags(sentence, indexes, this.ALERT_TAG, (index, node) => this.createAlert(filteredAlerts[index], node, text, sentence));
      }
    }
  }

  private createAlert(alert, node, sentence: string, sentenceNode) {
    if (this.isValidAlert(node)) {
      alert['sentence'] = sentence
      alert['sentence_node'] = sentenceNode
      alert['node'] = node;
      alert['alert_class'] = this.getAlertClass(alert);
      node.addClass(alert['alert_class']);
      node.on("click", (event) => {
        var index = this.alertList.filter((element, array, index) => {
          return this.filterCategories().has(element.suggestions[0].type);
        }).findIndex(itm => itm == alert);
        this.openCard(alert);
        this.viewPort.scrollToIndex(index, "smooth");
        this.changeDetector.detectChanges();
      });
      this.addAlert(alert);
      this.addOrUpdateStatsComment();
    } else {
      node.remove(true)
    }
  }

  private isValidAlert(node) {
    for (var i = 0; i < this.INVALID_TAGS_WITHIN_ALERT.length; i++) {
      var tags = node.getElementsByTag(this.INVALID_TAGS_WITHIN_ALERT[i])
      if (tags.count() > 0) {
        return false
      }
    }
    return true
  }

  private isProcessingRequired(element) {
    if (element.type == this.COMMENT_NODE) {
      return false;
    }
    var currentText = element.getText().trim();
    if (!currentText.match(this.VALID_TEXT)) {
      return false;
    } else {
      var lastKnownText = element.getCustomData(this.LAST_KNOWN_TEXT);
      return currentText != lastKnownText;
    }
  }

  private removeTags(element, tag: string) {
    var ranges = this.ckEditor.instance.getSelection().getRanges();
    var nodes = element.getElementsByTag(tag);
    while (nodes.count() > 0) {
      nodes.getItem(0).remove(true);
      nodes = element.getElementsByTag(tag);
    }
    this.ckEditor.instance.getSelection().selectRanges(ranges);
  }

  private createTags(element, indexes, tag: string, callback?: Function) {
    var ranges = this.ckEditor.instance.getSelection().getRanges();
    indexes = this.adjustIndexes(indexes);
    var pendingNodes: Array<any> = [];
    pendingNodes.push(element);

    var currentIndex = 0;
    var newNode = [];
    var pendingLength = indexes[currentIndex]['end'] - 0;
    while (pendingNodes.length != 0) {
      var currentNode = pendingNodes.pop();
      if (currentNode.type == this.TEXT_NODE) {

        var currentNodeLength = currentNode.getLength();
        if (pendingLength > currentNodeLength) {
          pendingLength = pendingLength - currentNodeLength;
          newNode.push(currentNode);
        } else {
          if (pendingLength < currentNodeLength) {
            var nextTextNode = currentNode.split(pendingLength);
            pendingNodes.push(nextTextNode);
          }

          newNode.push(currentNode);
          this.fixDomAndAppend(element, newNode, tag, indexes[currentIndex], callback);

          var currentIndex = currentIndex + 1;
          if (indexes.length == currentIndex) {
            break;
          }
          var pendingLength = indexes[currentIndex]['end'] - indexes[currentIndex - 1]['end'];
          newNode = [];
        }

      } else if (currentNode.getChildCount() > 0) {
        var nodes = currentNode.getChildren();
        for (let index = nodes.count() - 1; index >= 0; index--) {
          pendingNodes.push(nodes.getItem(index));
        }
      }
    }
    if (newNode.length > 0) {
      this.fixDomAndAppend(element, newNode, tag);
    }

    try {
      this.ckEditor.instance.getSelection().selectRanges(ranges);
    } catch (DOMException) {
      /*
      Below exception occurs on Chrome when entire document is processed. In such cases, Firefox removes the focus from the editor.
      Hence, As of now, reflecting the same behavior in Chrome.

      core.js:6014 ERROR DOMException: Failed to execute 'setStart' on 'Range': The offset 531 is larger than the node's length (32).
      at CKEDITOR.dom.selection.selectRanges (http://cdn.ckeditor.com/4.7.1/full/ckeditor.js:461:130)
      at MainComponent.createTags (http://localhost:4200/main.js:2010:47)
      at SafeSubscriber._next (http://localhost:4200/main.js:1898:26)

      */
    }

  }

  private adjustIndexes(indexes: Array<any>) {
    var adjustedIndexes: Array<any> = [];
    for (let index = 0; index < indexes.length; index++) {
      var previousEnd = (index == 0) ? 0 : indexes[index - 1]['end'];
      if (indexes[index]['begin'] > previousEnd) {
        adjustedIndexes.push({
          'begin': previousEnd,
          'end': indexes[index]['begin'],
          'create': false,
          'original': -1
        });
      }
      indexes[index]['create'] = true;
      indexes[index]['original'] = index;
      adjustedIndexes.push(indexes[index]);
    }
    return adjustedIndexes;
  }

  private fixDomAndAppend(root, childNodes: Array<any>, newTag: string, index?: any, callback?: Function) {
    var firstNode = childNodes[0];
    var lastNode = childNodes[childNodes.length - 1];
    this.fixDom(root, lastNode);
    if (index != null && index['create']) {
      var newNode = this.appendAll(root, firstNode, lastNode, newTag);
      if (index['original'] != -1 && callback) {
        callback(index['original'], newNode);
      }
    }
  }

  private fixDom(root, lastNode) {
    var breaker = root.getDocument().createElement("break");
    breaker.insertAfter(lastNode);
    while (!root.equals(breaker.getParent())) {
      var parent = breaker.getParent();
      if ((breaker.getParent().getChildCount() - 1) != breaker.getIndex()) {
        breaker.breakParent(parent);
      }
      breaker.insertAfter(parent);
    }
    breaker.remove();
  }

  private appendAll(root, firstNode, lastNode, newTag) {
    var newNode = root.getDocument().createElement(newTag);
    var ancestor = firstNode.getCommonAncestor(lastNode);

    if (ancestor.equals(root)) {
      firstNode = this.getAscendant(root, firstNode);
      lastNode = this.getAscendant(root, lastNode);

      newNode.insertAfter(lastNode);
      while (!firstNode.equals(newNode.getPrevious())) {
        newNode.append(newNode.getPrevious(), true);
      }
      newNode.append(firstNode, true);

    } else {

      newNode.insertAfter(ancestor);
      ancestor.appendTo(newNode);
    }
    return newNode;
  }

  private getAscendant(parentNode, containsNode) {
    var parents = containsNode.getParents();
    for (let index = 0; index < parents.length; index++) {
      if (parents[index].equals(parentNode)) {
        return parents[index + 1];
      }
    }
  }

  private rememberText(element) {
    element.setCustomData(this.LAST_KNOWN_TEXT, element.getText().trim());
  }

  master_change() {
    for (let value of this.sharedService.filterCheckList.filter(filter => !filter.disabled)) {
      value.checked = this.master_checked;
    }
  }

  lang_code_all_option_clicked() {
    //Code to push all the languages inside the array
    for (let value of this.languageCodeOptions) {
      value.isSelected = this.lang_code_master_checked;
    }
  }

  cc_Master_change() {
    if (this.cc_result.length != 0) {
      for (let index = 0; index < this.cc_result.length; index++) {
        for (let j = 0; j < this.cc_result[index].alerts.length; j++) {
          this.cc_result[index].alerts[j].node.removeClass(this.cc_result[index].alerts[j].alert_class)
          this.cc_result[index].alerts[j].node.removeClass(this.cc_result[index].alerts[j].alert_class + '_active')
          this.closeCardCC(this.cc_result[index]);
        }
      }
    }
    for (let value of Object.values(this.sharedService.CCFilterCheckList)) {
      value.checked = this.cc_master_checked;
    }
  }

  private _isAllFilterSelected() {
    let listOfFilters = this.sharedService.filterCheckList.filter(filter => !filter.disabled).filter(filter => filter.display);
    return listOfFilters.every(filter => filter.checked === true);
  }

  list_change(filter: any) {
    this.master_checked = this._isAllFilterSelected();

    this.amplitudeService.logAmplitude('Alerts_categories', {
      [filter.name]: filter.checked,
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }

  cc_list_change(item) {
    if (!item.checked) {
      this.removeUnderlineSelectedFilter(item.name);
    }
    let checked_count = 0;
    //Get total checked items
    for (let value of Object.values(this.sharedService.CCFilterCheckList)) {
      if (value.checked)
        checked_count++;
    }

    if (checked_count > 0 && checked_count < this.sharedService.CCFilterCheckList.length) {
      // If some checkboxes are checked but not all; then set Indeterminate state of the master to true.
      // this.master_indeterminate = true;
      this.cc_master_checked = false;
    } else if (checked_count == this.sharedService.CCFilterCheckList.length) {
      //If checked count is equal to total items; then check the master checkbox and also set Indeterminate state to false.
      // this.master_indeterminate = false;
      this.cc_master_checked = true;
    } else {
      //If none of the checkboxes in the list is checked then uncheck master also set Indeterminate to false.
      // this.master_indeterminate = false;
      this.cc_master_checked = false;
    }
  }

  removeUnderlineSelectedFilter(module) {
    let item = this.cc_result.filter(result => result.module == module.toString().toLowerCase());
    let expandedItem = item.filter(alert => alert.isExpanded == true);
    this.closeCardCC(expandedItem[0]);
  }

  //Getting value from child
  childToParent(event) {
    this.fileHolder.data['language'] = event;
    this.sentDataCall(() => window.location.reload(), true);
  }

  outputAlertsFromChild(event) {
    if (event.flag === 'open') {
      this.openCard(event.alert)
    }
    else if (event.flag === 'close') {
      this.closeCard(event.alert)
    }
    else if (event.flag === 'ignore') {
      this.ignoreCard(event.alert, event.parentIndex, event.indexK);
    }
    else if (event.flag === 'reject') {
      this.rejectSuggestions(event.alert, event.parentIndex);
    }
    else if (event.flag === 'accept') {
      this.acceptCard(event.alert, event.suggestion, event.indexK, event.parentIndex);
    }
    else if (event.flag === 'mute') {
      this.muteCard(event.alert, event.suggestion, event.indexK, event.parentIndex);
    }
    else if (event.flag === 'accept_noncta') {
      this.acceptNonCta(event.alert, event.suggestion, event.indexK, event.parentIndex);
    }
  }

  groupType2Cards(data) {
    let group = data.alerts.reduce((reduce, array) => {
      reduce[array.type] = [...reduce[array.type] || [], array];
      return reduce;
    }, {});
    return group;
  }

  noTextFound() {

    var body = this.ckEditor.instance.document.getBody();
    var sections = this.hasAllSections(body) ? this.getSections(body) : [body];
    sections = sections.filter(section => section.getText().trim() != "");
    let text = sections.map(section => section.getText())
    return text.length === 0;
  }

  cc_result = [];
  sendDataToCOnsistencyCheckerAPI() {
    this.selectedNum = 0;

    var body = this.ckEditor.instance.document.getBody();
    var sections = this.hasAllSections(body) ? this.getSections(body) : [body];
    sections = sections.filter(section => section.getText().trim() != "");
    let text = sections.map(section => section.getText())

    if (this.sentToCCApi)
      return

    if (text.length > 0) {
      this.emptyDocument = false;
      for (var i = 0; i <= text.length; i++) {
        if (typeof (text[i]) != "undefined") {
          text[i] = text[i].toString().replace(/&nbsp;/g, ' ')
        }
      }

      for (let node = 0; node < sections.length; node++) {
        for (let childNode = 0; childNode < sections[node]["$"].childNodes.length; childNode++) {
          if (sections[node]["$"].childNodes[childNode].nodeName == "CCALERT") {
            sections[node]["$"].childNodes[childNode].replaceWith(sections[node]["$"].childNodes[childNode].textContent);
          }
        }
      }

      this.cc_result = [];

      this.sentToCCApi = true;
      this.networkCalls.consistencyCheckerRequest({ "text": text, "language": this.fileHolder.data.language, "file_id": this.fileHolder.data.file_id }).subscribe(result => {
        if (result['status']) {
          this.sentToCCApi = false;
          result.data.forEach(data => {
            data.alerts = this.sortAlert(data.alerts);
          });
          (result.data).forEach(dt => {
            dt.alerts = this.overlapFilterData(dt.alerts);
            if (dt.type === 1 && dt.alerts.length >= 2) {
              this.cc_result.push(dt);
            }
            if (dt.type === 2 && dt.module === 'numbers') {
              let numRes = this.groupType2Cards(dt);
              if (Object.keys(numRes).length === 2) {
                if (numRes.digit.length != 0 && numRes.string.length != 0) {
                  this.cc_result.push(dt);
                }
              }
            }
            if (dt.type === 2 && dt.module === 'spacing') {
              let spaceRes = this.groupType2Cards(dt);
              if (spaceRes['remove-space'].length != 0 && spaceRes['add-space'].length != 0) {
                this.cc_result.push(dt);
              }
            }
            if (dt.type === 2 && dt.module === 'symbol_spacing') {
              let spaceRes = this.groupType2Cards(dt);
              if (spaceRes['remove-space'].length != 0 && spaceRes['add-space'].length != 0) {
                this.cc_result.push(dt);
              }
            }
            if (dt.type === 2 && dt.module === 'symbol') {
              let symbolRes = this.groupType2Cards(dt);
              if (symbolRes.symbol.length != 0 && symbolRes.string.length != 0) {
                this.cc_result.push(dt);
              }
            }
            if (dt.type === 2 && dt.module === 'punctuations') {
              let puncRes = this.groupType2Cards(dt);
              if (puncRes['out-side'].length != 0 && puncRes['in-side'].length != 0) {
                this.cc_result.push(dt);
              }
            }
            if (dt.type === 2 && dt.module === 'p_value') {
              //console.log(dt)
              let pValue = this.groupType2Cards(dt);
              //console.log("pValue ", pValue)
              try {
                if (pValue['to-lower'].length != 0 && pValue['to-upper'].length != 0) {
                  this.cc_result.push(dt);
                }
              } catch (error) {
                // console.log(pValue)
                if (pValue.hasOwnProperty('add-zero') && pValue.hasOwnProperty('remove-zero')) {
                  if (pValue['add-zero'].length != 0 && pValue['remove-zero'].length != 0) {
                    this.cc_result.push(dt);
                  }
                }
              }
            }
          });

          this.cc_result.forEach(card => {
            let alerts = card['alerts']
            alerts.forEach(alert => {
              let paragraph = sections[alert['paragraph'] - 1]
              this.createTagsCC(paragraph, [alert], this.CC_ALERT_TAG, (index, node) =>
                this.createAlertCC(card, alert, node)
              );
            })
          });
          window.dispatchEvent(new Event('resize'));
        } else {
          this.sentToCCApi = false
          this.toastr.error(result.message)
        }
      }, error => {
        this.sentToCCApi = false;
        this.sharedService.errorHandller(error);
      })
    } else {
      this.cc_result = [];
      this.prevCCData = text;
      this.emptyDocument = true;
    }
    window.dispatchEvent(new Event('resize'));
  }

  overlapFilterData(data) {
    return data.filter((alert) => { return alert.overlap != true });
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  groupArray(data) {
    this.dataGroupedByText = [];
    this.dataGroupedByText = this.groupBy(data.alerts, 'text');
  }

  groupBy = (data, key) => {
    return data.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  sortAlert(alert) {
    return alert.sort((a, b) => { return a.paragraph - b.paragraph })
  }

  totalVariations(item) {
    let result = this.groupBy(item.alerts, 'text');
    return " has " + Object.values(result).length + " Variations ";
  }

  totalLength = 0;
  totalOccurence(dataGroupedByText) {
    var y = Object.values(dataGroupedByText).map((x: any[]) => { return x.length })
    this.totalLength = y.reduce((a, b) => a + b, 0)
    return this.totalLength;
  }

  previousHighlight(item, p, last) {
    if (p == last + 1) {
      this.previousIndex = 1;
    }
    else if (1 <= this.previousIndex) {
      this.previousIndex = this.previousIndex - 1;
    }
    var original_element = document.getElementsByClassName("outer_scroll_sections")
    var original_scroll = original_element[0].scrollTop;
    if (item.module === 'dashes' || item.module === 'spelling' || item.module === 'unit') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
        if (item.alerts[this.previousIndex] === item.alerts[index]) {
          item.alerts[this.previousIndex].node.addClass(item.alerts[this.previousIndex].alert_class);
          item.alerts[this.previousIndex].node.addClass(item.alerts[this.previousIndex].alert_class + '_active');
          this.selectedItem = item.alerts[this.previousIndex];

          item.alerts[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          item.alerts[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(item.alerts[this.previousIndex]['isExpanded']);
        }
      }
    }
    if (item.module === 'numbers') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      var data = this.filteredCcNumArray(this.selectedNum)
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }
    if (item.module === 'spacing') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      var data = this.filteredCcSpacingArray(this.selectedSpace)
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }
    if (item.module === 'symbol_spacing') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      var data = this.filteredCcSymbolSpacingArray(this.selectedSpace)
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }

    if (item.module === 'p_value') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      if (item.casing) {
        var data = this.filteredCcPvalueArray(this.selectedSpace)
      } else {
        var data = this.filteredCcPvalueArrayAddRemoveZero(this.selectedPvalueZero)
      }
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }

    if (item.module === 'punctuations') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      var data = this.filteredCcPuncArray(this.selectedPunc)
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }
    if (item.module === 'symbol') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      var data = this.filteredCcSymbolArray(this.selectedSym)
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }
  }

  nextHighlight(item, p, last) {
    if (p == last - 1) {
      this.previousIndex = 0;
    }
    else {
      this.previousIndex = this.previousIndex + 1;
    }
    var original_element = document.getElementsByClassName("outer_scroll_sections")
    var original_scroll = original_element[0].scrollTop;
    if (item.module === 'dashes' || item.module === 'spelling' || item.module === 'unit' || item.module === 'table_title' || item.module === 'tables_title' || item.module === 'figure_title' || item.module === 'figures_title' || item.module === 'box_title' || item.module === 'boxes_title' || item.module === 'accent_chars') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
        if (item.alerts[this.previousIndex] === item.alerts[index]) {
          item.alerts[this.previousIndex].node.addClass(item.alerts[this.previousIndex].alert_class);
          item.alerts[this.previousIndex].node.addClass(item.alerts[this.previousIndex].alert_class + '_active');

          item.alerts[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          item.alerts[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(item.alerts[this.previousIndex]['isExpanded']);
        }
      }
    }
    if (item.module === 'numbers') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      var data = this.filteredCcNumArray(this.selectedNum, 'next')
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }
    if (item.module === 'spacing') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      var data = this.filteredCcSpacingArray(this.selectedSpace, 'next')
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }
    if (item.module === 'symbol_spacing') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      var data = this.filteredCcSymbolSpacingArray(this.selectedSymbolSpace, 'next')
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }
    if (item.module === 'p_value') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      //pending
      if (item.casing) {
        var data = this.filteredCcPvalueArray(this.selectedPvalue, 'next')
      } else {
        var data = this.filteredCcPvalueArrayAddRemoveZero(this.selectedPvalueZero, 'next')
      }
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }
    if (item.module === 'punctuations') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      var data = this.filteredCcPuncArray(this.selectedPunc)
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }

    }
    if (item.module === 'symbol') {
      for (let index = 0; index < item.alerts.length; index++) {
        item.alerts[index].node.removeClass(item.alerts[index].alert_class + '_active');
      }
      var data = this.filteredCcSymbolArray(this.selectedSym)
      for (let index = 0; index < data.length; index++) {
        if (data[this.previousIndex] === data[index]) {
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class);
          data[this.previousIndex].node.addClass(data[this.previousIndex].alert_class + '_active');
          this.selectedItem = data[this.previousIndex];

          data[this.previousIndex]['node'].scrollIntoView(true)
          this.showAlertTextOnEditor(original_scroll, original_element[0])
          data[this.previousIndex]['isExpanded'] = true;
          this.subjectiveBehaviorSharedService.setCardIsExpanded(data[this.previousIndex]['isExpanded']);
        }
      }
    }
  }

  navigationEditorCC(data, p) {
    var original_element = document.getElementsByClassName("outer_scroll_sections")
    var original_scroll = original_element[0].scrollTop;
    data[p]['node'].scrollIntoView(true)
    this.showAlertTextOnEditor(original_scroll, original_element[0])
    data[p]['isExpanded'] = true;
    this.subjectiveBehaviorSharedService.setCardIsExpanded(data[p]['isExpanded']);
  }

  /**@description: Consistency logic from here */
  openCardCC(alert) {
    this.previousIndex = 0;
    this.lastIndex = 0;
    this.defaultSelected = 0;
    this.selectedNum = 0;
    this.selectedSym = 0;
    this.selectedPunc = 0;
    this.selectedItem = alert.alerts[0];
    alert['isExpanded'] = true;

    if (alert.module === 'dashes') {
      this.getDashesSpellingUnitHighlights(alert);
    }
    if (alert.module === 'table_title') {
      this.getDashesSpellingUnitHighlights(alert);
    }
    if (alert.module === 'tables_title') {
      this.getDashesSpellingUnitHighlights(alert);
    }
    if (alert.module === 'figure_title') {
      this.getDashesSpellingUnitHighlights(alert);
    }
    if (alert.module === 'box_title') {
      this.getDashesSpellingUnitHighlights(alert);
    }
    if (alert.module === 'figures_title') {
      this.getDashesSpellingUnitHighlights(alert);
    }
    if (alert.module === 'boxes_title') {
      this.getDashesSpellingUnitHighlights(alert);
    }
    if (alert.module === 'p_value') {
      this.getDashesSpellingUnitHighlights(alert);
    }
    if (alert.module === 'accent_chars') {
      this.getDashesSpellingUnitHighlights(alert);
    }
    if (alert.module === 'spelling') {
      this.getDashesSpellingUnitHighlights(alert);
    }
    if (alert.module === 'numbers') {
      for (let index = 0; index < alert.alerts.length; index++) {
        if (alert.alerts[index].type === 'string') {
          this.filteredCcNumArray(this.selectedNum, 'open');
        }
      }
    }
    if (alert.module === 'symbol') {
      for (let index = 0; index < alert.alerts.length; index++) {
        if (alert.alerts[index].type === 'string') {
          this.filteredCcSymbolArray(this.selectedSym, 'open')
        }
        else if (alert.alerts[index].type === 'measurement') {
          this.selectedSym = 2;
          this.filteredCcSymbolArray(this.selectedSym, 'open')
        }
      }
    }
    if (alert.module === 'punctuations') {
      for (let index = 0; index < alert.alerts.length; index++) {
        if (alert.alerts[index].type === 'out-side') {
          this.filteredCcPuncArray(this.selectedPunc, 'open')
        }
      }
    }
    if (alert.module === 'spacing') {
      for (let index = 0; index < alert.alerts.length; index++) {
        if (alert.alerts[index].type === 'add-space') {
          this.filteredCcSpacingArray(this.selectedSpace, 'open')
        }
      }
    }
    if (alert.module === 'symbol_spacing') {
      for (let index = 0; index < alert.alerts.length; index++) {
        if (alert.alerts[index].type === 'add-space') {
          this.filteredCcSymbolSpacingArray(this.selectedSpace, 'open')
        }
      }
    }
    if (alert.module === 'p_value') {
      if (alert.casing) {
        for (let index = 0; index < alert.alerts.length; index++) {
          if (alert.alerts[index].type === 'to-lower') {
            this.filteredCcPvalueArray(this.selectedPvalue, 'open')
          }
        }
      } else {
        this.filteredCcPvalueArrayAddRemoveZero(this.selectedPvalueZero, 'open')
      }
    }
    if (alert.module === 'unit') {
      this.getDashesSpellingUnitHighlights(alert);
    }
  }

  getDashesSpellingUnitHighlights(alert) {
    var original_element = document.getElementsByClassName("outer_scroll_sections")
    var original_scroll = original_element[0].scrollTop;
    this.groupArray(alert);
    for (let index = 0; index < alert.alerts.length; index++) {
      alert.alerts[0]['node'].scrollIntoView(true)
      this.showAlertTextOnEditor(original_scroll, original_element[0])
      alert.alerts[index]['isExpanded'] = true;
      this.subjectiveBehaviorSharedService.setCardIsExpanded(alert.alerts[0]['isExpanded']);
      if (alert.alerts[0] === alert.alerts[index]) {
        alert.alerts[0].node.addClass(alert.alerts[0].alert_class)
        if (alert.type !== "p_value") {
          alert.alerts[0]['node'].addClass(alert.alerts[0]['alert_class'] + '_active');
        }
      } else {
        alert.alerts[index]['node'].addClass(alert.alerts[index]['alert_class']);
      }
    }
  }

  getNumberSymbolPunctuationHighlights(filterData, flag?) {
    var original_element = document.getElementsByClassName("outer_scroll_sections")
    var original_scroll = original_element[0].scrollTop;
    for (let j = 0; j < filterData.length; j++) {
      filterData[j]['node'].scrollIntoView(true)
      this.showAlertTextOnEditor(original_scroll, original_element[0])
      filterData[j]['isExpanded'] = true;
      this.subjectiveBehaviorSharedService.setCardIsExpanded(filterData[j]['isExpanded']);

      if (filterData[0].text === filterData[j].text && (flag === 'open' || flag === 'change')) {
        filterData[j].node.addClass(filterData[j].alert_class)
        filterData[0]['node'].addClass(filterData[0]['alert_class'] + '_active');
      } else {
        filterData[j]['node'].addClass(filterData[j]['alert_class']);
      }
    }
  }

  closeCardCC(alert) {
    this.viewEndIndexArray1 = 3;
    this.viewEndIndexArray2 = 3;
    alert['isExpanded'] = false;
    for (let index = 0; index < alert.alerts.length; index++) {
      alert.alerts[index].node.removeClass(alert.alerts[index].alert_class)
      alert.alerts[index].node.removeClass(alert.alerts[index].alert_class + '_active')
      alert.alerts[index]['isExpanded'] = true;
    }
  }

  removeNodeHeighlight() {
    for (let index = 0; index < this.alertList.length; index++) {
      this.alertList[index]["isExpanded"] = false;
      this.alertList[index].node.removeClass(this.alertList[index].alert_class + '_active')
    }
  }

  ignoreConsistencyCard(alert, parrentIndex) {
    this.socketService.ignoreCC(alert)
    for (let index = 0; index < alert.alerts.length; index++) {
      alert.alerts[index].node.removeClass(alert.alerts[index].alert_class)
      alert.alerts[index].node.removeClass(alert.alerts[index].alert_class + '_active')
    }
    this.removeCardCC(alert, parrentIndex);
    if (this.cc_result[parrentIndex + 1]) {
      this.cc_result[parrentIndex]['isExpanded'] = true;
      this.subjectiveBehaviorSharedService.setCardIsExpanded(this.cc_result[parrentIndex]['isExpanded']);
    }
  }

  rejectConsistencySuggestions(alert, parrentIndex) {
    this.socketService.rejectCC(alert)
    for (let index = 0; index < alert.alerts.length; index++) {
      alert.alerts[index].node.removeClass(alert.alerts[index].alert_class)
      alert.alerts[index].node.removeClass(alert.alerts[index].alert_class + '_active')
    }
    this.removeCardCC(alert, parrentIndex);
    if (this.cc_result[parrentIndex + 1]) {
      this.cc_result[parrentIndex]['isExpanded'] = true;
      this.subjectiveBehaviorSharedService.setCardIsExpanded(this.cc_result[parrentIndex]['isExpanded']);
    }
  }

  private removeCardCC(alert, parrentIndex) {
    this.cc_result.splice(parrentIndex, 1)
    this.saveTextFile();
  }

  filteredDataStringToNumeral = [];
  filteredDataNumeraltoString = [];
  filteredSymbolDataToString = [];
  filteredStringDataToSymbol = [];
  filteredPuncDataOutSide = [];
  filteredPuncDataInSide = [];
  filteredSpacingDataAddSpace = [];
  filteredSpacingDataRemoveSpace = []

  filteredPvalueDataToLower = [];
  filteredPvalueDataToUppar = []

  filteredPvalueDataAddZero = [];
  filteredPvalueDataRemoveZero = []
  pValueCase = "ZERO"; //ZERO/CASING;

  onCCchangeSelection(mrChange: MatRadioChange, item, index, module) {
    this.previousIndex = 0;
    this.lastIndex = 0;
    for (var i = 0; i < item.alerts.length; i++) {
      item.alerts[i].node.removeClass(item.alerts[i].alert_class);
      item.alerts[i].node.removeClass(item.alerts[i].alert_class + '_active');
    }
    if (module === 'numbers') {
      this.selectedNum = index;
      this.filteredDataStringToNumeral = [];
      this.filteredDataNumeraltoString = [];
      this.filteredCcNumArray(index, 'change');
    }
    if (module === 'symbol') {
      this.selectedSym = index;
      this.filteredSymbolDataToString = [];
      this.filteredStringDataToSymbol = [];
      this.filteredCcSymbolArray(index, 'change');
    }
    if (module === 'punctuations') {
      this.selectedPunc = index;
      this.filteredPuncDataOutSide = [];
      this.filteredPuncDataInSide = [];
      this.filteredCcPuncArray(index, 'change');
    }
    if (module === 'spacing') {
      this.selectedSpace = index;
      this.filteredSpacingDataAddSpace = [];
      this.filteredSpacingDataRemoveSpace = [];
      this.filteredCcSpacingArray(index, 'change');
    }
    if (module === 'symbol_spacing') {
      this.selectedSymbolSpace = index;
      this.filteredSpacingDataAddSpace = [];
      this.filteredSpacingDataRemoveSpace = [];
      this.filteredCcSymbolSpacingArray(index, 'change');
    }

    if (module === 'p_value') {
      this.selectedPvalue = index;
      this.filteredPvalueDataToLower = [];
      this.filteredPvalueDataToUppar = [];
      this.selectedPvalueZero = index;
      //console.log("item =>", item.casing)
      if (item.casing) {
        this.filteredCcPvalueArray(index, 'change');
      } else {
        this.filteredCcPvalueArrayAddRemoveZero(index, 'change');
      }
    }
  }
  filteredCcNumArray(selectedVal, flag?) {
    this.lastIndex = 0;
    if (selectedVal === 0) {
      selectedVal = this.numberArray[0].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'numbers')
      this.filteredDataStringToNumeral = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredDataStringToNumeral.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredDataStringToNumeral, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'numbers')
      this.filteredDataNumeraltoString = data1[0].alerts.filter(filter => filter.type === 'digit')
    }
    else if (selectedVal === 1) {
      selectedVal = this.numberArray[1].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'numbers')
      this.filteredDataNumeraltoString = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredDataNumeraltoString.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredDataNumeraltoString, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'numbers')
      this.filteredDataStringToNumeral = data1[0].alerts.filter(filter => filter.type === 'string')
    }
    return selectedVal === 'string' ? this.filteredDataStringToNumeral : this.filteredDataNumeraltoString;
  }

  filteredCcSymbolArray(selectedVal, flag?) {
    this.lastIndex = 0;
    if (selectedVal === 0) {
      selectedVal = this.symbolArray[0].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'symbol')
      this.filteredSymbolDataToString = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredSymbolDataToString.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredSymbolDataToString, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'symbol')
      this.filteredStringDataToSymbol = data1[0].alerts.filter(filter => filter.type === 'symbol')
    } else {
      selectedVal = this.symbolArray[1].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'symbol')
      this.filteredStringDataToSymbol = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredStringDataToSymbol.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredStringDataToSymbol, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'symbol')
      this.filteredSymbolDataToString = data1[0].alerts.filter(filter => filter.type === 'string')
    }
    return selectedVal === 'string' ? this.filteredSymbolDataToString : this.filteredStringDataToSymbol;
  }

  filteredCcPuncArray(selectedVal, flag?) {
    this.lastIndex = 0;
    if (selectedVal === 0) {
      selectedVal = this.punctuationArray[0].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'punctuations')
      this.filteredPuncDataOutSide = data[0].alerts.filter(filter => filter.type === selectedVal);
      this.lastIndex = this.filteredPuncDataOutSide.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredPuncDataOutSide, flag);

      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'punctuations')
      this.filteredPuncDataInSide = data[0].alerts.filter(filter => filter.type === 'in-side');
    } else {
      selectedVal = this.punctuationArray[1].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'punctuations')
      this.filteredPuncDataInSide = data[0].alerts.filter(filter => filter.type === selectedVal);
      this.lastIndex = this.filteredPuncDataInSide.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredPuncDataInSide, flag);

      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'punctuations')
      this.filteredPuncDataOutSide = data[0].alerts.filter(filter => filter.type === 'out-side');
    }
    return selectedVal === 'out-side' ? this.filteredPuncDataOutSide : this.filteredPuncDataInSide;
  }

  filteredCcSpacingArray(selectedVal, flag?) {
    this.lastIndex = 0;
    if (selectedVal === 0) {
      selectedVal = this.spacingArray[0].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'spacing')
      this.filteredSpacingDataAddSpace = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredSpacingDataAddSpace.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredSpacingDataAddSpace, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'spacing')
      this.filteredSpacingDataRemoveSpace = data1[0].alerts.filter(filter => filter.type === 'remove-space')
    }
    else if (selectedVal === 1) {
      selectedVal = this.spacingArray[1].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'spacing')
      this.filteredSpacingDataRemoveSpace = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredSpacingDataRemoveSpace.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredSpacingDataRemoveSpace, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'spacing')
      this.filteredSpacingDataAddSpace = data1[0].alerts.filter(filter => filter.type === 'add-space')
    }
    return selectedVal === 'add-space' ? this.filteredSpacingDataAddSpace : this.filteredSpacingDataRemoveSpace;
  }


  filteredCcSymbolSpacingArray(selectedVal, flag?) {
    this.lastIndex = 0;
    if (selectedVal === 0) {
      selectedVal = this.spacingArray[0].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'symbol_spacing')
      this.filteredSpacingDataAddSpace = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredSpacingDataAddSpace.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredSpacingDataAddSpace, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'symbol_spacing')
      this.filteredSpacingDataRemoveSpace = data1[0].alerts.filter(filter => filter.type === 'remove-space')
    }
    else if (selectedVal === 1) {
      selectedVal = this.spacingArray[1].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'symbol_spacing')
      this.filteredSpacingDataRemoveSpace = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredSpacingDataRemoveSpace.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredSpacingDataRemoveSpace, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'symbol_spacing')
      this.filteredSpacingDataAddSpace = data1[0].alerts.filter(filter => filter.type === 'add-space')
    }
    return selectedVal === 'add-space' ? this.filteredSpacingDataAddSpace : this.filteredSpacingDataRemoveSpace;
  }

  filteredCcPvalueArray(selectedVal, flag?) {
    //console.log("filteredCcPvalueArray")
    this.lastIndex = 0;
    if (selectedVal === 0) {
      selectedVal = this.pValueArray[0].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'p_value')
      this.filteredPvalueDataToLower = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredPvalueDataToLower.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredPvalueDataToLower, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'p_value')
      this.filteredPvalueDataToUppar = data1[0].alerts.filter(filter => filter.type === 'to-upper')
    }
    else if (selectedVal === 1) {
      selectedVal = this.pValueArray[1].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'p_value')
      this.filteredPvalueDataToUppar = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredPvalueDataToUppar.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredPvalueDataToUppar, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'p_value')
      this.filteredPvalueDataToLower = data1[0].alerts.filter(filter => filter.type === 'to-lower')
    }
    //console.log(this.filteredPvalueDataToLower, this.filteredPvalueDataToUppar)
    return selectedVal === 'to-lower' ? this.filteredPvalueDataToLower : this.filteredPvalueDataToUppar;
  }

  filteredCcPvalueArrayAddRemoveZero(selectedVal, flag?) {
    this.lastIndex = 0;
    if (selectedVal === 0) {
      selectedVal = this.pValueArrayZero[0].text;
      //console.log("selectedVal ", selectedVal)
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'p_value')
      this.filteredPvalueDataAddZero = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredPvalueDataAddZero.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredPvalueDataAddZero, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'p_value')
      this.filteredPvalueDataRemoveZero = data1[0].alerts.filter(filter => filter.type === 'remove-zero')
    }
    else if (selectedVal === 1) {
      selectedVal = this.pValueArrayZero[1].text;
      var data = this.cc_result.filter(module => module.type == 2 && module.module === 'p_value')
      this.filteredPvalueDataRemoveZero = data[0].alerts.filter(filter => filter.type === selectedVal)
      this.lastIndex = this.filteredPvalueDataRemoveZero.length;
      this.getNumberSymbolPunctuationHighlights(this.filteredPvalueDataRemoveZero, flag);

      var data1 = this.cc_result.filter(module => module.type == 2 && module.module === 'p_value')
      this.filteredPvalueDataRemoveZero = data1[0].alerts.filter(filter => filter.type === 'remove-zero')
      //console.log(this.filteredPvalueDataRemoveZero)
    }
    //console.log(this.filteredPvalueDataRemoveZero, this.filteredPvalueDataAddZero)
    return selectedVal === 'add-zero' ? this.filteredPvalueDataAddZero : this.filteredPvalueDataRemoveZero;
  }

  private createTagsCC(element, indexes, tag: string, callback?: Function) {
    var ranges = this.ckEditor.instance.getSelection().getRanges();
    indexes = this.adjustIndexesCC(indexes);
    var pendingNodes: Array<any> = [];
    pendingNodes.push(element);

    var currentIndex = 0;
    var newNode = [];
    var pendingLength = indexes[currentIndex]['end'] - 0;

    while (pendingNodes.length != 0) {
      var currentNode = pendingNodes.pop();
      if (currentNode.type == this.TEXT_NODE) {

        var currentNodeLength = currentNode.getLength();
        if (pendingLength > currentNodeLength) {
          pendingLength = pendingLength - currentNodeLength;
          newNode.push(currentNode);
        } else {
          if (pendingLength < currentNodeLength) {
            var nextTextNode = currentNode.split(pendingLength);
            pendingNodes.push(nextTextNode);
          }

          newNode.push(currentNode);
          this.fixDomAndAppendCC(element, newNode, tag, indexes[currentIndex], callback);

          var currentIndex = currentIndex + 1;
          if (indexes.length == currentIndex) {
            break;
          }
          var pendingLength = indexes[currentIndex]['end'] - indexes[currentIndex - 1]['end'];
          newNode = [];
        }

      }
      else if (currentNode.getChildCount() > 0) {
        var nodes = currentNode.getChildren();
        for (let index = nodes.count() - 1; index >= 0; index--) {
          pendingNodes.push(nodes.getItem(index));
        }
      }
    }

    if (newNode.length > 0) {
      this.fixDomAndAppendCC(element, newNode, tag);
    }
  }

  private adjustIndexesCC(indexes: Array<any>) {
    var adjustedIndexes: Array<any> = [];
    for (let index = 0; index < indexes.length; index++) {
      var previousEnd = (index == 0) ? 0 : indexes[index - 1]['end'];
      if (indexes[index]['begin'] > previousEnd) {
        adjustedIndexes.push({
          'begin': previousEnd,
          'end': indexes[index]['begin'],
          'create': false,
          'original': -1
        });
      }
      indexes[index]['create'] = true;
      indexes[index]['original'] = index;
      adjustedIndexes.push(indexes[index]);
    }
    return adjustedIndexes;
  }
  private fixDomAndAppendCC(root, childNodes: Array<any>, newTag: string, index?: any, callback?: Function) {
    var firstNode = childNodes[0];
    var lastNode = childNodes[childNodes.length - 1];
    this.fixDom(root, lastNode);
    if (index != null && index['create']) {
      var newNode = this.appendAll(root, firstNode, lastNode, newTag);
      if (index['original'] != -1 && callback) {
        callback(index['original'], newNode);
      }
    }
  }

  private createAlertCC(card, alert, node) {
    if (this.isValidAlertCC(node)) {
      alert['node'] = node;
      alert['alert_class'] = this.getAlertClassCC(alert);
      this.addAlertCC(alert);
      this.addOrUpdateStatsCommenCC();
    } else {
      node.remove(true)
    }
  }

  private addAlertCC(alert) {
    var minIndex = 0;
    var maxIndex = this.alertList.length;
    while (maxIndex - minIndex > 1) {
      var index = Math.floor((maxIndex + minIndex) / 2);
      if (this.alertList[index]['node'].getPosition(alert['node']) === this.POSITION_PRECEDING) {
        minIndex = index;
      } else {
        maxIndex = index;
      }
    }
    if (minIndex === 0 && this.alertList.length > 0 && this.alertList[minIndex]['node'].getPosition(alert['node']) !== this.POSITION_PRECEDING) {
      maxIndex = 0;
    }
    alert['isVisible'] = true;
    this.alertList.splice(maxIndex, 0, alert);
  }

  private getAlertClassCC(alert) {
    if (alert) {
      return "grammarcc";
    }
  }

  private isValidAlertCC(node) {
    for (var i = 0; i < this.INVALID_TAGS_WITHIN_ALERT.length; i++) {
      var tags = node.getElementsByTag(this.INVALID_TAGS_WITHIN_ALERT[i])
      if (tags.count() > 0) {
        return false
      }
    }
    return true
  }

  private addOrUpdateStatsCommenCC() {
    let body = this.ckEditor.instance.document.getBody();
    let statsComment = body.findOne('#_cmnt1');
    if (!statsComment) {
      statsComment = this.createComment("1");
    }
    statsComment = statsComment.findOne('p>span');

    let statsCommentHtml = 'There are no unresolved Trinka alerts for your file.';
    let length = this.alertList.length;
    if (length > 0) {
      statsCommentHtml = (length === 1) ? 'is ' + length + ' unresolved Trinka alert' : 'are ' + length + ' unresolved Trinka alerts';
      statsCommentHtml = 'There ' + statsCommentHtml + ' for your file. Please resolve them by opening the document at <a href="https://cloud.trinka.ai/editor/main/' + this.documentId + '">cloud.trinka.ai.</a>';
    }
    statsComment.setHtml(statsCommentHtml);
    this.onChange(null);
  }

  onSelectionChange(item, i, text, error, j) {
    this.defaultSelected = j;
    this.selectedItem = error.value[0];
    this.acceptAllList = [];
    setTimeout(() => {
      for (let index = 0; index < item.alerts.length; index++) {
        if (item.alerts[index].text != text) {
          this.acceptAllList.push(item.alerts[index]);
        }
        if (error.value[0].text === text) {
          error.value[0].node.addClass(error.value[0].alert_class)
        }
      }
      for (var i = 0; i < this.acceptAllList.length; i++) {
        this.acceptAllList[i].node.addClass(this.acceptAllList[i].alert_class)
      }
    }, 0);
  }

  acceptCardCC(alert, i) {
    if (alert.module === 'dashes' || alert.module === 'spelling' || alert.module === 'unit' || alert.module === 'table_title' || alert.module === 'tables_title' || alert.module === 'figure_title' || alert.module === 'box_title' || alert.module === 'figures_title' || alert.module === 'boxes_title' || alert.module === 'accent_chars') {
      for (let index = 0; index < alert.alerts.length; index++) {
        alert.alerts[index].node.removeClass(alert.alerts[index].alert_class);
        alert.alerts[index].node.removeClass(alert.alerts[index].alert_class + '_active');
      }
      for (let i = 0; i < alert.alerts.length; i++) {
        this.setTextAndRetainFormat(alert.alerts[i].node, this.selectedItem.text);
        this.saveTextFile()
      }
    }
    else if (alert.module === 'numbers') {
      var data = this.filteredCcNumArray(this.selectedNum)
      for (var index = 0; index < data.length; index++) {
        this.setTextAndRetainFormat(data[index].node, data[index].correction);
        data[index].node.removeClass(data[index].alert_class);
        data[index].node.removeClass(data[index].alert_class + '_active');
      }
    }
    else if (alert.module === 'symbol') {
      var symbolData = this.filteredCcSymbolArray(this.selectedSym)
      for (var index = 0; index < symbolData.length; index++) {
        this.setTextAndRetainFormat(symbolData[index].node, symbolData[index].correction);
        symbolData[index].node.removeClass(symbolData[index].alert_class);
        symbolData[index].node.removeClass(symbolData[index].alert_class + '_active');
      }
    }
    else if (alert.module === 'punctuations') {
      var puncData = this.filteredCcPuncArray(this.selectedPunc)
      for (var index = 0; index < puncData.length; index++) {
        this.setTextAndRetainFormat(puncData[index].node, puncData[index].correction);
        puncData[index].node.removeClass(puncData[index].alert_class);
        puncData[index].node.removeClass(puncData[index].alert_class + '_active');
      }
    }
    else if (alert.module === 'spacing') {
      var spaceData = this.filteredCcSpacingArray(this.selectedSpace)
      for (var index = 0; index < spaceData.length; index++) {
        this.setTextAndRetainFormat(spaceData[index].node, spaceData[index].correction);
        spaceData[index].node.removeClass(spaceData[index].alert_class);
        spaceData[index].node.removeClass(spaceData[index].alert_class + '_active');
      }
    } else if (alert.module === 'symbol_spacing') {
      var spaceData = this.filteredCcSymbolSpacingArray(this.selectedSymbolSpace)
      for (var index = 0; index < spaceData.length; index++) {
        this.setTextAndRetainFormat(spaceData[index].node, spaceData[index].correction);
        spaceData[index].node.removeClass(spaceData[index].alert_class);
        spaceData[index].node.removeClass(spaceData[index].alert_class + '_active');
      }
    } else if (alert.module === 'p_value') {
      if (alert.casing) {
        var spaceData = this.filteredCcPvalueArray(this.selectedPvalue)
      } else {
        var spaceData = this.filteredCcPvalueArrayAddRemoveZero(this.selectedPvalueZero)
      }
      for (var index = 0; index < spaceData.length; index++) {
        this.setTextAndRetainFormat(spaceData[index].node, spaceData[index].correction);
        spaceData[index].node.removeClass(spaceData[index].alert_class);
        spaceData[index].node.removeClass(spaceData[index].alert_class + '_active');
      }
    }
    this.removeCardCC(alert, i);
    this.sendDataToCOnsistencyCheckerAPI();
    this.amplitudeService.logAmplitude('consistency_accept', {
      'fileID': this.sharedService.currentFileId,
      'category': alert.module,
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }

  enableA1Module = false;
  showPublication = false;
  /**@description:  Get publication all checks data*/
  getPublicationAllCheckReport() {
    this.subjectiveBehaviorSharedService.getA1AllCheckReportData.subscribe(reportData => {
      if (reportData == '') {
        this.showPublication = false;
      } else {
        this.showPublication = true;
        this.enableA1Module = true
        this.navigationConfigure()
      }
    });
  }

  activeCheck;
  initialIndex;
  lastA1Index;
  navigationArray = [];
  /**@description:  Initial configuration of Navigation Author One*/
  navigationConfigure() {
    this.navigationArray = [];
    var filterEnableModule = EnablePublicationCheck.filter(data => data.is_enable == true)
    for (var i = 0; i < filterEnableModule.length; i++) {
      this.navigationArray.push({ value: i, label: filterEnableModule[i].label, is_enable: filterEnableModule[i].is_enable })
    }
    this.activeCheck = this.navigationArray[0].label;
    this.initialIndex = this.navigationArray[0].value;
    this.lastA1Index = this.navigationArray.length;
  }

  /**@description:  Navigation for previous check ofAuthor One*/
  previousA1Check(initialIndex) {
    if (initialIndex == 0) {
      this.initialIndex = this.navigationArray.length - 1;
      this.activeCheck = this.navigationArray[this.initialIndex].label;
    }
    else if (1 <= initialIndex) {
      this.initialIndex = initialIndex - 1;
      this.initialIndex = this.navigationArray[this.initialIndex].value
      this.activeCheck = this.navigationArray[this.initialIndex].label
    }
  }

  /**@description:  Navigation for next check ofAuthor One*/
  nextA1Check(initialIndex) {
    if (initialIndex == this.navigationArray.length - 1) {
      this.initialIndex = 0;
      this.activeCheck = this.navigationArray[this.initialIndex].label;
    }
    else {
      this.initialIndex = initialIndex + 1
      this.initialIndex = this.navigationArray[this.initialIndex].value
      this.activeCheck = this.navigationArray[this.initialIndex].label
    }
    this.enableA1Module = true
  }

  @HostListener('window:beforunload')
  ngOnDestroy() {
    // cleanup
    this.getModuleCheckSubscription.unsubscribe();

    this._stack.stopJobProcessing();
    this._resetAllFilterErrorCount();
    this.socketService.close();
  }
  // In tab view closed funcation
  tabviewCCResult: boolean = true;
  closedViewreport() {
    this.showPublication = false;
  }
  consistencyclosed() {
    this.tabviewCCResult = false;
  }

  addSampleData(content: String) {
    this.ckEditor.instance.setData(content);
  }

  openSignUpAccessModel() {
    this.dialog.open(SignupaccessComponent)
  }
  wordCountSectionOpen() {
    if (this.wordCountApiCall) {
      this.wordCountApiFailed = false;
      this.wordCountApiCall = false
      const localMeta = this.sharedService.getLocalStorageMeta();
      let userId = btoa(localMeta.sub);
      let fileId = this.sharedService.currentFileId;

      this.networkCalls.getDocumentDetailsRequest(userId, fileId).subscribe(result => {
        if (result.status) {
          this.wordCountSections.forEach(data => {
            if (data.text == "word_count") {
              data.value = result.word_count;
            }
          })
        }
        this.wordCountApiCall = true;
        this.changeDetector.detectChanges();
      }, error => {
        this.wordCountApiCall = true;
        this.wordCountApiFailed = true;
      })

    }

    this.amplitudeService.logAmplitude('stats_view', {
      'userID': this.userId
    });
  }

  btnWordCountDictClick() {
    this.dialog.open(DictionaryModalComponent, {
      panelClass: 'dictModal'
    });
  }

  setWordLimitExceed() {
    this.wordLimitExceed = true;
    var _this = this;
    this.dialog.open(SignupaccessComponent);
  }
  exceededLimit() {
    this.wordLimitExceed = false;
  }

  getUserPaymentSubscription() {
    this.dashboardService.getStoreUserPlanDetails().subscribe(resp => {
      if (Object.keys(resp).length == 0) {
        this.networkCalls.getUserPaymentSubscription(this.userId).subscribe(response => {
          this.paymentDetails = response['data'];
          this.dashboardService.storeUserPlanDetails(this.paymentDetails);
        });
      }
      else {
        this.paymentDetails = resp;
      }

    });
  }

  getSelectedPowerModeOption() {
    this.subjectiveBehaviorSharedService.getPowerModePremiumStatus.subscribe(powerModeSelection => {
      this.powerModeSelection = powerModeSelection;
    });
  }

  outputAlertsFromPowerMode(event) {
    this.powerModeSelection = event.status;
    this.fileHolder.data.powermode_status = event.status;
    this.alertList = [];
    this.cardCountForTrial = 0;
    this.prevCCData = null;
    this.sentDataCall(() => false, true);
    if (this.IS_CONNECTED) {
      setTimeout(() => {
        this.socketService.close();
        this.connectToSocket();
        var body = this.ckEditor.instance.document.getBody();
        this.removeSentences(body);
        this.scanDocument();
      }, 500)
    }
  }

  processSentenceWithId(resp, sentence, callback) {
    var request_id = sentence.getCustomData(this.REQUEST_ID);

    if (request_id == null) {
      this.getOrCreateRequestId(resp, sentence, (resp, request_id, callback) => {
        sentence.setAttribute("id", request_id);
        this.rememberRequestId(request_id, sentence);
        this.rememberSentenceOldText(sentence);
        callback(resp, request_id);
      }, callback)
    }
    else {
      this.updateSentencebyRequestID(request_id, sentence);
      callback(resp, request_id);
    }
  }
  // added to update sentence on update, reload
  updateSentencebyRequestID(request_id, sentence) {
    this.indexCache.updateRecord(request_id, this.sharedService.currentFileId, sentence.getText().trim());
  }

  fillRequestID(req_id) {
    this.REQ_ID = req_id;
    return this.REQ_ID;
  }

  async getOrCreateRequestId(resp, sentence, callbackfirst, callbacksecond) {
    this.SentenceObserver.observe(sentence.$, this.mutationConfig)
    this.indexCache.getSentenceByText(sentence.getText().trim()).then((result) => {
      var request_id = uuid();
      result = result.filter(record => !record.o)
      if (result.length > 0) {
        request_id = this.encrypt_decrypt.decryptJSON(result[0].s_i.split('*')[1]);
      }
      this.fillRequestID(request_id);
      this.indexCache.insertRecord(request_id, this.sharedService.currentFileId, sentence.getText().trim());
      callbackfirst(resp, request_id, callbacksecond)
    })

  }


  rememberRequestId(request_id, sentence) {
    var req_id = sentence.getCustomData(this.REQUEST_ID);
    sentence.setCustomData(this.REQUEST_ID, request_id);
  }

  rememberSentenceOldText(node) {
    var old_text = node.getCustomData(this.LAST_KNOWN_TEXT);
    if (old_text == null) {
      node.setCustomData(this.LAST_KNOWN_TEXT, node.getText().trim());
    }
  }

  /* paraphraser start */
  public switchToParaphraser() {
    let userSelection = this.ckEditor.instance.window.$.getSelection();
    this.sentencesSelectedForParaphraser = this._processUserSelection(userSelection);
    this._paraphraserService.userSelectionSentencesChangeEvent(userSelection, this.sentencesSelectedForParaphraser, false);
    this._paraphraseFloatingOption.nativeElement.style.display = 'none';
  }

  public onChange(event) { }

  public onAfterCommandExec(event) { }

  public onKeyup(event) {
    this._paraphraseFloatingOption.nativeElement.style.display = 'none';
  }

  public onMouseup(event) {
    setTimeout(() => {
      this._paraphraseFloatingOption.nativeElement.style.display = 'none';
      this._onMouseup(event);
    }, 0);
  }

  private _onMouseup(event: any) {
    if (this.enabledModule === ModuleCheck.GC) {
    } else if (this.enabledModule === ModuleCheck.PP) {
      this._clearPreviousSelectionNodes();
      this._paraphraserService.userSelectionSentencesChangeEvent(null, this.sentencesSelectedForParaphraser, false);
      let position = this._showParaphraserFloatingOption(this.ckEditor.instance.window.$);
      if (position.display) {
        this._paraphraseFloatingOption.nativeElement.style.display = 'block';
        this._paraphraseFloatingOption.nativeElement.style.top = `${position.top}px`;
        this._paraphraseFloatingOption.nativeElement.style.left = `${position.left}px`;
      } else {
        this._paraphraseFloatingOption.nativeElement.style.display = 'none';
      }
    } else {
      return;
    }
  }

  private _getParentParagraph(endNode) {
    let currentNode = endNode.parentElement;
    while (currentNode.nodeName != 'P') {
      currentNode = currentNode.parentElement;
    }
    return currentNode;
  }

  private _getPreviousParagraph(endNode) {
    let currentNode = endNode.previousSibling;
    while (currentNode.nodeName !== 'P') {
      currentNode = currentNode.lastChild;
    }
    return currentNode;
  }


  private _getLastTextNode(endNode) {
    let currentNode = endNode.lastChild;
    while (currentNode.nodeType != 3) {
      currentNode = currentNode.lastChild;
    }
    return currentNode;
  }

  private _processUserSelection(userSelection: any) {
    if (userSelection.toString() === '') return [];

    let documentBody = this.ckEditor.instance.document.getBody();
    let rootNode = documentBody;
    let isBackward = this._checkIfUserSelectionIsBackward(userSelection);
    let startNode = userSelection.anchorNode;
    let endNode = userSelection.focusNode;
    if (isBackward) {
      startNode = userSelection.focusNode;
      endNode = userSelection.anchorNode;
    }
    let endNodeParent = endNode;

    // endNode correction start
    // find endNode parent
    if (endNodeParent.nodeName !== 'P') {
      endNodeParent = this._getParentParagraph(endNode);
    } else if (endNode.nodeName === 'P') {
      endNodeParent = this._getPreviousParagraph(endNode);
      endNode = this._getLastTextNode(endNodeParent);
    }

    // check if paragraph is empty
    if (endNodeParent.innerText.trim() === '') {
      endNodeParent = this._getPreviousParagraph(endNodeParent);
      while (endNodeParent.innerText.trim() === '') {
        endNodeParent = this._getPreviousParagraph(endNodeParent);
      }
      endNode = this._getLastTextNode(endNodeParent);
    }
    // endNode correction end

    let listOfSelectedTextNodes = this._findAllSelectedNodes(rootNode, startNode, endNode);
    this._focusAllSelectedSentences(listOfSelectedTextNodes);
    return this._getListOfSelectedSentencesForParaphraser();
  }

  private _showParaphraserFloatingOption(selection: any) {
    let editorContentElement = document.querySelector(`[id^="cke_"][id$="_contents"]`);
    let editorRects = editorContentElement ? editorContentElement.getBoundingClientRect() : null;

    let sel = selection.getSelection();
    let position = {
      display: false,
      isBackward: false,
      top: 0,
      left: 0
    };
    if (!sel.isCollapsed) {
      let range = document.createRange();
      range.setStart(sel.anchorNode, sel.anchorOffset);
      range.setEnd(sel.focusNode, sel.focusOffset);
      range.detach();
      position.isBackward = this._checkIfUserSelectionIsBackward(selection.getSelection());

      let rects = sel.getRangeAt(0).getClientRects();
      let selectionNodesLength = rects.length - 1;
      let editorWrapper = document.getElementById('cke_editor-1');
      if (position.isBackward) {
        position.display = true;
        position.top = rects[0].top - editorWrapper.scrollTop - 2;
        position.left = rects[0].left - 95;
      } else {
        position.display = true;
        position.top = (rects[selectionNodesLength].top + 18) - editorWrapper.scrollTop;
        position.left = rects[selectionNodesLength].left + rects[selectionNodesLength].width + 40;
      }
    } else {
      position.display = false;
    }

    if (position.left < 0) {
      position.left = 40;
      position.top = position.top - 15;
    } else if (editorRects && (position.left + 125) >= editorRects.width) {
      position.left = editorRects.width - 125;
      position.top = position.top + 18;
    }

    return position;
  }

  private _clearPreviousSelectionNodes() {
    if (this.ckEditor.instance) {
      this.sentencesSelectedForParaphraser = [];
      let previousSelectionNodes = this.ckEditor.instance.document.getBody().$.querySelectorAll(`${this.SENTENCE_TAG}[focused="true"]`);
      previousSelectionNodes.forEach(nodes => nodes.removeAttribute('focused'));
    }
  }

  private _getListOfSelectedSentencesForParaphraser() {
    let sentencesSelectedForParaphraser = [];
    let currentSelectionNodes = this.ckEditor.instance.document.getBody().find(`${this.SENTENCE_TAG}[focused="true"]`);
    for (let i = 0, l = currentSelectionNodes.count(); i < l; i++) {
      let sentenceNode = currentSelectionNodes.getItem(i);
      let uniqueId = sentenceNode.getCustomData(this.REQUEST_ID) || uuid();
      sentencesSelectedForParaphraser.push({
        id: uniqueId,
        node: sentenceNode,
        text: sentenceNode.getText(),
        requestBody: {
          "sentence": sentenceNode.getText(),
          "lang_type": "US",
          "file_id": uniqueId,
          "temperature": 1.0
        }
      });
    }

    return sentencesSelectedForParaphraser;
  }

  private _focusAllSelectedSentences(listOfSelectedTextNodes: any[]) {
    let miscellaneousSelection = false;
    listOfSelectedTextNodes.every(selectionNodes => {
      let currentNode = selectionNodes;
      while (currentNode.$.nodeName !== this.SENTENCE_TAG.toUpperCase()) {
        currentNode = currentNode.getParent();
        if (!currentNode) {
          miscellaneousSelection = true;
          break;
        };
      }

      if (currentNode) {
        currentNode.setAttribute('focused', true);
      } else {
        this._clearPreviousSelectionNodes();
      }

      return !miscellaneousSelection;
    });
  }

  private _checkIfUserSelectionIsBackward(sel: any) {
    let position = sel.anchorNode.compareDocumentPosition(sel.focusNode);
    let isBackward = false;
    if (!position && sel.anchorOffset > sel.focusOffset || position === Node.DOCUMENT_POSITION_PRECEDING) {
      isBackward = true;
    }

    return isBackward;
  }

  private _findAllSelectedNodes(rootNode, startNode, endNode) {
    let pastStartNode = false, reachedEndNode = false, textNodes = [];
    let getTextNodes = (node) => {
      if (node.type == 3) {
        if (startNode === endNode) {
          if (node.$ == startNode) {
            textNodes.push(node);
            return;
          }
        } else if (node.$ == startNode) {
          textNodes.push(node);
          pastStartNode = true;
        } else if (node.$ == endNode) {
          textNodes.push(node);
          reachedEndNode = true;
        } else {
          if (pastStartNode && !reachedEndNode && !/^\s*$/.test(node.getText())) {
            textNodes.push(node);
          }
        }
      } else {
        for (let i = 0, len = node.getChildCount(); !reachedEndNode && i < len; ++i) {
          getTextNodes(node.getChild(i));
        }
      }
    }

    getTextNodes(rootNode);
    return textNodes;
  }

  /* paraphraser end */

  getEditorPlagCheckFiles() {
    this.networkCalls.requestAllSubmitedPlgiarismReport(this.userId, this.documentId).subscribe(resp => {
      this.plagCheckFileList = resp.data;
      this.changeDetector.detectChanges();
      for (let i = 0; i < this.plagCheckFileList.length; i++) {
        if (this.plagCheckFileList[i].file_status === AutoEdit.IN_PROGRESS) {
          setTimeout(() => {
            this.getEditorPlagCheckFiles();
          }, 30000);
          return;
        }
      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  calculatenoOfScanRequired(recheckFlag: boolean = false) {
    if (this.creditsDetails['user_type'] == "PREMIUM_PLUS" && this.avaialbleScan >= this.calulatePageCount()) {
      this.isScanCalculated = true;

      this.networkCalls.getDocumentDetailsRequest(this.userId, this.documentId).subscribe(result => {
        if (result.status) {
          if (recheckFlag) {
            this.amplitudeService.logAmplitude('ED_run_plagcheck', {
              'scans_available': this.creditsDetails.plagiarism_free_page_count_scan,
              'word_count': result.word_count,
              'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
              'user_id': this.userId
            });
          }
          else {
            this.amplitudeService.logAmplitude('ED_run_plagcheck', {
              'scans_available': this.creditsDetails.plagiarism_free_page_count_scan,
              'word_count': result.word_count,
              'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
              'user_id': this.userId
            });
          }
          this.wordCountSections.forEach(data => {
            if (data.text == "word_count") {
              this.noOfScanRequired = Math.ceil(result.word_count / 250);
              this.plagFileWordCount = result.word_count;
              this.changeDetector.detectChanges();
            }
          })
        }
      }, error => {
        this.isScanCalculated = false;
        this.sharedService.errorHandller(error);
      });
    } else {
      this.generateReportOnCreditBased(false);
    }
  }

  process_plagiarism_check(plag_check_type) {
    if (this.creditsDetails['user_type'] == "PREMIUM_PLUS") {
      this.isGeneratingPlagCheckReport = true;
      let payload = new FormData();
      payload.append('plagiarism_type', plag_check_type.toString());
      payload.append('file_id', this.documentId);
      payload.append('file_name', this.fileHolder.data.file_name);
      payload.append('user_id', this.userId);
      payload.append('d_user_id', this.creditsDetails.user_id);
      if (this.avaialbleScan < this.noOfScanRequired) {
        this.subjectiveBehaviorSharedService.setEditorFileId(this.documentId);
        this.subjectiveBehaviorSharedService.setEditorFileName(this.fileHolder.data.file_name);
        this.router.navigate(['/plagiarism-checker/upload']);
        return;
      }


      this.amplitudeService.logAmplitude('ED_plagcheck_proceed', {
        'scans_available': this.creditsDetails.plagiarism_free_page_count_scan,
        'word_count': this.plagFileWordCount,
        'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'user_id': this.userId,
        'scans utilised': this.noOfScanRequired
      });

      this.networkCalls.submitDocumentForPlagiarismCheck(payload).subscribe(resp => {
        this.changeDetector.detectChanges();
        this.isGeneratingPlagCheckReport = false;
        this.noOfScanRequired = 0;
        this.isScanCalculated = false;
        this.getEditorPlagCheckFiles();
        this.getCreditDetails();
      }, error => {
        this.noOfScanRequired = 0;
        this.isScanCalculated = false;
        this.isGeneratingPlagCheckReport = false;
        this.sharedService.errorHandller(error);
      });
    } else {
      this.generateReportOnCreditBased(false);
    }
  }


  view_plag_check_report(file) {
    this.isAdvancePlagCheckReport = file.plagiarism_check_type == 2 ? true : false;
    this.amplitudeService.logAmplitude('ED_plagcheck_view_report', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId,
      'report_type': this.isAdvancePlagCheckReport ? 'Advance Check Report' : 'Standard Check Report'
    });

    this.dashboardService.getithenticateURL(this.userId, file.file_id, file.plagiarism_check_type, this.creditsDetails.user_id, file.submission_id).subscribe(result => {
      this.isSamplePlagCheckReport = false;
      this.plagCheckReportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.data);
      this.viewerEnabled = true;
      this.changeDetector.detectChanges();
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  close_report(event) {
    this.viewerEnabled = event;
    this.isSamplePlagCheckReport = false;
    this.plagCheckReportUrl = "";
    this.amplitudeService.logAmplitude('ED_back_to_document', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId,
      'report_type': this.isAdvancePlagCheckReport ? 'Advance Check Report' : 'Standard Check Report'
    });
  }

  generate_advance_report(resp) {

    this.amplitudeService.logAmplitude('ED_advancedcheck_CTA', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId
    });
    if (this.creditsDetails['user_type'] == "PREMIUM_PLUS") {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditDetails: this.creditsDetails,
        isCreditBased: true
      };
      let dialogRef = this.dialog.open(EditorAdvancePlagCheckCreditModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.amplitudeService.logAmplitude('ED_generate_advancecheck', {
            'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
            'user_id': this.userId,
            'word_count': result.word_count,
            "credit_balance_free": this.creditsDetails.credits,
            "credit_balance_purchased": this.creditsDetails.purchased_credits
          });
          let payload = new FormData();
          payload.append('plagiarism_type', "2");
          payload.append('file_id', this.documentId);
          payload.append('file_name', this.fileHolder.data.file_name);
          payload.append('user_id', this.userId);
          payload.append('d_user_id', this.creditsDetails.user_id);
          this.networkCalls.submitDocumentForPlagiarismCheck(payload).subscribe(resp => {
            this.noOfScanRequired = 0;
            this.isScanCalculated = false;
            this.viewerEnabled = false;
            this.changeDetector.detectChanges();
            this.getEditorPlagCheckFiles();
            this.getCreditDetails();
          }, error => {
            this.noOfScanRequired = 0;
            this.isScanCalculated = false;
            this.viewerEnabled = false;
            this.changeDetector.detectChanges();
            this.sharedService.errorHandller(error);
          })
        }
      });

    } else {
      this.generateReportOnCreditBased(true);
    }
  }

  generateReportOnCreditBased(isCreditBased: boolean = false) {
    if (this.creditsDetails['user_type'] == "PREMIUM_PLUS") {
      this.subjectiveBehaviorSharedService.setEditorFileId(this.documentId);
      this.subjectiveBehaviorSharedService.setEditorFileName(this.fileHolder.data.file_name);
      this.amplitudeService.logAmplitude('ED_Plagcheck_with_credits_clicked', {
        'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'user_id': this.userId
      });
      this.router.navigate(['/plagiarism-checker/upload']);
    } else {
      this.subjectiveBehaviorSharedService.setEditorFileId(this.documentId);
      this.subjectiveBehaviorSharedService.setEditorFileName(this.fileHolder.data.file_name);
      this.amplitudeService.logAmplitude('ED_Plagcheck_with_credits_clicked', {
        'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'user_id': this.userId
      });
      this.router.navigate(['/plagiarism-checker/upload']);
    }
  }

  viewSampleReport(plag_type = 3) {
    let submission_id = this.sampleCard["plagiarism_check_type"] == plag_type ? this.sampleCard['submission_id'] : this.sampleCard['turnitine_submission_id'];
    this.isAdvancePlagCheckReport = true;
    this.amplitudeService.logAmplitude('ED_plagcheck_viewsample', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId
    });
    this.dashboardService.getithenticateURL(this.userId, this.sample_file_id, plag_type, this.creditsDetails.user_id, submission_id).subscribe(result => {
      this.isSamplePlagCheckReport = true;
      this.plagCheckReportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.data);
      this.viewerEnabled = true;
      this.changeDetector.detectChanges();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }


  getCreditDetails() {
    this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
      if (response["status"]) {
        this.creditsDetails = response.data;
        this.avaialbleScan = this.creditsDetails['plagiarism_free_page_count_scan'];
        this.dashboardService.storeUserCreditDetails(this.creditsDetails);
      }
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  getFileWordCountDetails() {
    this.networkCalls.getDocumentDetailsRequest(this.userId, this.documentId).subscribe(result => {
      if (result.status) {
        this.wordCountSections.forEach(data => {
          if (data.text == "word_count") {
            this.plagFileWordCount = result.word_count;
            this.changeDetector.detectChanges();
          }
        })
      }
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  calulatePageCount() {
    return Math.ceil(this.plagFileWordCount / 250)
  }


  upgradeToPremium() {
    this.amplitudeService.logAmplitude('ED_Plag_check_upgrdae_clicked', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails['user_type']),
      'user_id': this.userId,
      "credit_balance_free": this.creditsDetails['credits'],
      "credit_balance_purchased": this.creditsDetails['purchased_credits']
    });
    let redirct_url = environment.payment_fe;

    let data = {
      "user_id": this.userId,
      "plan_name": this.sharedService.uppercaseFirstLetter(this.creditsDetails['user_type']).toLowerCase(),
      "from": "Trinka"
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url + '/user/' + this.userData, "_blank")
  }

  checkReportHistory() {
    if (this.plagCheckFileList.length == 2 && this.plagCheckFileList[0].file_status == 'IN_PROGRESS') {
      return false;
    } else if (this.plagCheckFileList.length == 2 && this.plagCheckFileList[0].file_status == 'SUCCESS') {
      return true;
    } else {
      return true;
    }
  }

  checkLastGeneratedReport() {
    if (this.plagCheckFileList.length == 1 && this.plagCheckFileList[0].file_status == 'IN_PROGRESS') {
      return false;
    } else if (this.plagCheckFileList.length == 1 && this.plagCheckFileList[0].file_status == 'SUCCESS') {
      return true;
    } else {
      return true;
    }
  }

  onLanguageCodeChange() {
    this.selectedLanguageCode = this.languageCodeOptions.filter((x) => x.isSelected).map(obj => obj.code);
    this.languageString = this.languageCodeOptions.filter((x) => x.isSelected).map(obj => obj.value);
    this.languageCodeOptions.filter((x) => x.isSelected)
    if (this.selectedLanguageCode.length === 0) {
      //@ts-ignore
      this.selectedLanguageCode = [...this.defaultLanguageCode];
      this.selectLanguageOptions();
      this.changeDetector.detectChanges();
    }
    let filterOption = this.sharedService.filterCheckList.find(filter => filter.flag === 10);
    if (!this.selectedLanguageCode.includes('en')) {
      filterOption.disabled = true;
      filterOption.checked = false;
    } else {
      filterOption.disabled = false;
      filterOption.checked = true;
    }
    this.subjectiveBehaviorSharedService.setEditorLanguage(this.selectedLanguageCode.toString());
    this.fileHolder.data['lang_code'] = this.selectedLanguageCode.toString();
    this._restartSocket();
    this.networkCalls.putUpdateFile(this.userId, this.fileHolder.data).subscribe(result => {
      this.saving = false;
      this.savingSection = true;
      this.lastSavedTime = new Date();

      this.interval = setTimeout(() => {
        this.savingSection = false;
      }, 3000);
    }, error => {
      this.saving = false;
      this.sharedService.errorHandlerForFIleSave(error);
    });

    let eventObj = {};
    this.languageCodeOptions.forEach((x: any) => {
      eventObj[x.value] = x.isSelected;
    })
    this.amplitudeService.logAmplitude('EN_Language_change',{
      ...eventObj,
      'editor': 'V0' 
    });
    this.sharedService.instructionModule.languages = this.showAllSelectedCode();
    this.updateInclusiveFilterState();
  }

  private _restartSocket() {
    this.socketService.close();
    this.userLimitExhausted = false;
    this.dialogLimitOver = null;
    this.connectToSocket();
    if (this.ckEditor.instance && this.ckEditor.instance.document) {
      let body = this.ckEditor.instance.document.getBody();
      this.removeSentences(body);
      this.scanDocument();
      this.requestToElementMap.clear();
      this.changeDetector.detectChanges();
    }
  }

  showAllSelectedCode() {
    let languageToBeDisplayed = ''
    // if (this.selectedLanguageCode.length === 1) {
    //   let tempArr = this.languageCodeOptions.filter((x) => x.code === this.selectedLanguageCode[0]);
    //   console.log(tempArr[0])
    //   languageToBeDisplayed = tempArr && tempArr.length > 0 ? tempArr[0].value.slice(0, -4) : '';
    // }
    // else
    languageToBeDisplayed = this.selectedLanguageCode.toString().toLocaleUpperCase().replace(/,/g, ', ');
    return languageToBeDisplayed;
  }

  selectLanguageOptions() {
    this.languageCodeOptions.forEach((x) => {
      x.isSelected = this.selectedLanguageCode.indexOf(x.code) > -1;
    });
    this.languageString = this.languageCodeOptions.filter((x) => x.isSelected).map(obj => obj.value);
    this.subjectiveBehaviorSharedService.setEditorLanguage(this.selectedLanguageCode.toString());
  }
  viewMoreText(){
    this.viewMoreViewLessText = !this.viewMoreViewLessText;
  }
  
  learnMoreClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isActiveLearnMore = !this.isActiveLearnMore;
    const dialogRef = this.dialog.open(PowerModeEditingComponent, { data: {} });
  }

  closedEventOfEditingModeDropdown() {
    this.isActiveLearnMore = false;
  }
  onEditingModeSelectionChange(evt) {
    var InstModel = [
      {
        'inst': "",
        'module': "basic"
      }, {
        'inst': "Fix all the Grammatical Errors of this text",
        'module': "advanced"
      }];
    this.sharedService.instructionModule.languages = this.showAllSelectedCode();
    switch (evt.value) {
      case "Power Mode":
        this.sharedService.instructionModule.module = "advanced";
        setTimeout(() => {
          this.toastr.success("Power Mode is active now!", '',
            {
              messageClass: 'powerModeSelected'
            })
        }, 1500);
        this.amplitudeService.logAmplitude('Editing mode', {
          'mode': '1. Power Mode',
          'user_id': this.userId
        });
        this.instructionModule.module = InstModel[1].module;
        this.instructionModule.instruction = InstModel[1].inst;
        this.subjectiveBehaviorSharedService.changeInstuctionModule('parent_to_child', InstModel[1].inst, InstModel[1].module);
        break;
      case "Lite Mode":
        this.sharedService.instructionModule.module = "basic";
        setTimeout(() => {
          this.toastr.success("Lite Mode is active now!")
        }, 1500);
        this.amplitudeService.logAmplitude('Editing mode', {
          'mode': '2. Lite Mode',
          'user_id': this.userId
        });
        this.instructionModule.module = InstModel[0].module;
        this.instructionModule.instruction = InstModel[0].inst;
        this.subjectiveBehaviorSharedService.changeInstuctionModule('parent_to_child', InstModel[0].inst, InstModel[0].module);
        break;
      default:
        // this.subjectiveBehaviorSharedService.changeInstuctionModule('parent_to_child', InstModel[0].inst, InstModel[0].module);
        break;
    }
    let payload = {
      file_id: this.sharedService.currentFileId,
      instruction: this.instructionModule.instruction,
      module: this.instructionModule.module,
      lang_code: this.selectedLanguageCode.toString()
    }
    //TODO: Combine all putUpdateFile calls like done previously
    this.networkCalls.putUpdateFile(this.userId, payload).subscribe(result => {

    }, error => {
      this.sharedService.errorHandlerForFIleSave(error);
    });
  }
}
