import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from "@angular/router"

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
  deleteDone: boolean = false; 
  constructor(
    public dialogRef: MatDialogRef<MessageModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
  }
  onCloseClick(): void {
    this.dialogRef.close();
  }
  onCloseClickAndRedirect(): void {
    this.dialogRef.close();
  }
 
}
