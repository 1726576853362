<div class="content_wrap_section">
    <div class="wrap_header_sectons">
        <div class="top_header_overlay"></div>
        <h2>Privacy Policy</h2>
    </div>

    <div class="body_wrap_contents">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <p>Trinka.ai is a fully owned product of Crimson AI LLP. Crimson AI LLP and its subsidiary and affiliate companies (collectively hereafter, "Crimson AI,"
                        "we," "us," or "our") recognize the importance of protecting the personal information collected
                        from users in the operation of our website, products, content, and services (collectively
                        hereafter, “Services”) and taking reasonable steps to maintain the security, integrity and
                        privacy of any information in accordance with this Privacy Policy. By submitting your
                        information to Crimson AI you consent to the practices described in this policy.</p>
                    <p>We take your privacy very seriously. This Privacy Policy describes how Crimson AI collects and
                        uses the personal information you provide to Crimson AI. It also describes the choices available
                        to you regarding our use of your personal information and how you can access and update this
                        information. This Privacy Policy forms part of Crimson AI's <a href="/termsOfServices">Terms of Service</a>. If you need more
                        information, please contact us at <a href="mailto:support@trinka.ai">support@trinka.ai</a>.</p>
                    <p>Our personal information handling policy and procedures have been developed in line with the
                        requirements of the 1995 European Union Data Protection Directive (Directive 95/46/EC) and the
                        General Data Protection Regulation (in force from 25 May 2018) and the applicable national law.
                    </p>

                    <div class="question_answer_section">
                        <h3>Questions and Answer</h3>
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne"> <i class="fa"
                                                aria-hidden="true"></i>
                                                What information do we collect?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <p>We collect and process personal data about you when you interact with us and our products and when you use or purchase Services from us. The personal data we process include the following.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            <i class="fa" aria-hidden="true"></i>
                                            What are your rights in relation to your personal data?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <p>The "Site" is owned and operated by Crimson AI. You may not modify, copy,
                                            reproduce, republish, upload, post, transmit, or distribute in any way, any
                                            material from this site, including code and software. You may not download
                                            material from this site for your personal, non-commercial use.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            <i class="fa" aria-hidden="true"></i>
                                            For how long will we keep your personal data?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <ul>
                                            <li>Our use of your data is governed by Crimson AI’s Privacy Policy. You
                                                acknowledge that you have read the Privacy Policy and understand how we
                                                collect, store, and use Customer Data. If you do not agree with our
                                                Privacy Policy, you must stop using our services.</li>
                                            <li>Crimson AI reserves the right to save documents on its systems according
                                                to its policy and Indian government regulations for conducting business.
                                            </li>
                                            <li>We may delete our copies of Customer Data from time to time and you
                                                agree that we will not have any liability for any damages that you may
                                                incur from such deletion.</li>
                                            <li>You agree that we may disclose Customer Data, including content of
                                                communications, if we believe that such disclosure is necessary (i) to
                                                comply with any applicable laws, (ii) to enforce our policies, and (iii)
                                                to protect our services and products.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>