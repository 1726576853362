<div class="content_wrap_section">
  <div class="wrap_header_sectons">
    <div class="top_header_overlay"></div>
    <h2>Terms of Services</h2>
  </div>

  <div class="body_wrap_contents">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <p>Welcome to Trinka AI. Trinka AI is a fully owned product of Crimson AI LLP. Along with its subsidiaries and
            other affiliates, Crimson AI LLP makes
            available the
            "Site" (websites including, without limitation, www.Trinkai.ai and all sub-domains), "Software"
            (software
            and mobile applications), and "Services" (including, without limitation, writing feedback
            suggestions and/or
            corrections) to help write effectively. Access to and use of Trinka AI LLP’s existing "Site",
            "Software",
            and "Services" (hereafter Services) as well as any future Services provided by Crimson AI LLP are
            governed
            by this Terms of Service (hereafter "Terms").</p>
          <p>In this document, “we”, “us”, and “our” refer to Crimson AI LLP (hereafter Crimson AI). The terms
            “you” and “your” refer to our customers, users of our services, or visitors to our websites.</p>
          <p>Crimson AI cares about your privacy. Please read the Crimson AI Privacy Policy carefully for
            information relating to our collection, use, storage, disclosure of your personal information.
            The Crimson AI <a href="/privacypolicy">Privacy Policy</a> is incorporated by this reference into, and made a part of, these
            Terms.</p>
          <p><span>By clicking “I Accept”, or by downloading, installing, or otherwise accessing or using the
              service, you agree that you have read and understood, and, as a condition to your use of the
              service, you agree to be bound by, the following terms of Service, including Crimson AI’s
              privacy policy and any additional terms and policies Crimson AI may provide from time to
              time. If you are not eligible, or do not agree to the Terms, then you do not have our
              permission to use the service. Your use of the service, and Crimson AI’s provision of the
              service to you, constitutes an agreement by Crimson AI and by you to be bound by these
              Terms.</span></p>


          <div class="question_answer_section">
            <div id="accordion">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                      aria-controls="collapseOne"> <i class="fa" aria-hidden="true"></i>
                      Limitation of Liability
                    </button>
                  </h5>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    <p>Under no circumstances shall Crimson AI be liable for any losses or damages whatsoever, whether
                      in contract or otherwise, from the use of or reliance on, the materials on the website. Crimson AI
                      shall not be liable for any loss howsoever arising or any indirect, special, incidental, or
                      consequential damages, even if advised of the possibility of such damages.</p>
                    <p>Crimson AI includes all its divisions, subsidiaries, successors, parent companies and their
                      employees, partners, principals, agents, and representatives as well as any third-party providers
                      or sources of material. While Crimson AI takes all necessary precautions to safeguard all
                      submitted documents, it shall not be held liable for the loss of documents due to power failure,
                      hardware/software failure, computer viruses, natural disasters, or any other unforeseen matters
                      and factors beyond our control. You will be responsible for creating back-up and archival copies
                      of your documents. In no event will Crimson AI be responsible to you or any other person for any
                      loss, corruption, or alteration of documents.</p>
                    <p>Crimson AI is only responsible for providing selected services or products as per our company
                      definitions. All views and thoughts expressed in the manuscripts or content are solely of the
                      writer and members using the product or service of Crimson AI.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                      aria-expanded="false" aria-controls="collapseTwo">
                      <i class="fa" aria-hidden="true"></i>
                      Website
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    <p>The "Site" is owned and operated by Crimson AI. You may not modify, copy, reproduce, republish,
                      upload, post, transmit, or distribute in any way, any material from this site, including code and
                      software. You may not download material from this site for your personal, non-commercial use.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"
                      aria-expanded="false" aria-controls="collapseThree">
                      <i class="fa" aria-hidden="true"></i>
                      Use of Customer Data
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    <ul>
                      <li>Our use of your data is governed by Crimson AI’s <a href="/privacypolicy">Privacy Policy</a>.
                        You acknowledge that you have read the Privacy Policy and understand how we collect, store, and
                        use Customer Data. If you do not agree with our Privacy Policy, you must stop using our
                        services.</li>
                      <li>Crimson AI reserves the right to save documents on its systems according to its policy and
                        Indian government regulations for conducting business.</li>
                      <li>We may delete our copies of Customer Data from time to time and you agree that we will not
                        have any liability for any damages that you may incur from such deletion.</li>
                      <li>You agree that we may disclose Customer Data, including content of communications, if we
                        believe that such disclosure is necessary (i) to comply with any applicable laws, (ii) to
                        enforce our policies, and (iii) to protect our services and products.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>