<div class="contactUsPopup">
  <form [formGroup]="feedbackForm" (submit)="onSubmitClick()" *ngIf="!feedbackSubmitted">
    <div class="contactUsHeader">
      <h2>Rate your experience</h2>
    </div>
    <div class="contactUsBody">
      <ul>
        <li>
          <span *ngFor="let rating of ratings" [ngClass]="{'active': rating <= ratingFeedback}"
            (click)="ratingFeedback = rating">
            <svg class="starWithNonBackground" width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.2003 1.66699L19.3203 10.0137L28.5336 11.3603L21.8669 17.8537L23.4403 27.027L15.2003 22.6937L6.96028 27.027L8.53361 17.8537L1.86694 11.3603L11.0803 10.0137L15.2003 1.66699Z" stroke="#F59E0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg class="starWithBackgound" width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.8001 1.66699L18.9201 10.0137L28.1335 11.3603L21.4668 17.8537L23.0401 27.027L14.8001 22.6937L6.56013 27.027L8.13346 17.8537L1.4668 11.3603L10.6801 10.0137L14.8001 1.66699Z" fill="#F59E0B" stroke="#F59E0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </li>
      </ul>
      <textarea matInput formControlName="feedbackText" placeholder="Share your feedback with us..."
        class="feedback-textarea" [readonly]="submitInProgress"></textarea>
    </div>
    <div class="contactUsFooter">
      <button class="contactusCloseButton" (click)="onCloseClick()" mat-button mat-dialog-close>Cancel</button>
      <button type="submit" class="contactUsSubmitButton" [disabled]="ratingFeedback <=0 || checkIfValidComment() || submitInProgress">Submit</button>
    </div>
  </form>
  <div class="feedbackThankYou" *ngIf="feedbackSubmitted">
    <div class="SVGwrapper"> 
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
    </div>
    <h2 class="animate__animated animate__fadeInUp animate__delay-1s">Thank you for sharing your feedback!</h2>
    <button class="contactusCloseButton animate__animated animate__fadeInUp animate__delay-1s" mat-button mat-dialog-close>Close</button>
  </div>
</div>
