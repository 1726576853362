import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
 
export class FileUploadService {

  CONSTANT_ADVANCE_SETTINGS: any = {
    "writing_advisor_as_comments": true,
    "statistics_in_document": true,
    "revisions_as_tracks": true,
    "language": "US",
    "enhancement": true,
    "grammar": true,
    "spellings": true,
    "writing_advisor": true,
    "style_guide": "NONE",
    "power_mode": false
  };

  currentFileInfo: any = {
    file: null,
    progress: 0,
    advanceSettings: JSON.parse(JSON.stringify(this.CONSTANT_ADVANCE_SETTINGS)),
    apiResponse: null,
    cloudFileDetails: null,
    fileId: null,  //TODO: Can be deleted once GET API is available,
    isUploadRequestCancelled: false,
    isCloudFile: false,
    fileType: ''
  };

  currentUserCredits: number = 0;

  creditRedirectUrl: string = '';

  changeFileClicked: boolean = false;

  currentFileChanges: Subject<any> = new Subject<any>();

  userCreditDetails: any = {};


  constructor(private _http: HttpClient) {
    this.currentFileChanges.subscribe((value) => {
      this.currentFileInfo = value;
    });
  }

  uploadOrProcessLocalFile(file: FormData): Observable<any> {
    return this._http.post<any>(environment.autoedit_api + "/autoedit/v1/document", file, {
      reportProgress: true,
      observe: 'events'
    })
  }

  uploadOrProcessCloudFile(file: FormData): Observable<any> {
    return this._http.post<any>(environment.autoedit_api + "/autoedit/v1/cloud/document", file, {
      reportProgress: true,
      observe: 'events'
    })
  }

  deleteFileByAutoEditId(fileId) {
    return this._http.delete(environment.autoedit_api + "/autoedit/v1/document/" + fileId)
  }

  getAutoEditFileById(fileId) {
    return this._http.get<any>(environment.autoedit_api + `/autoedit/v1/document/${fileId}`);
  }

  resetFileDetails() {
    this.currentFileInfo = {
      file: null,
      progress: 0,
      advanceSettings: JSON.parse(JSON.stringify(this.CONSTANT_ADVANCE_SETTINGS)),
      apiResponse: null,
      cloudFileDetails: null,
      fileId: null,
      isUploadRequestCancelled: false,
      isCloudFile: false,
      fileType: ''
    };
  }

}