import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FilesList } from 'src/app/_interfaces/dashboard/file-list';
import { IndexedDbService } from 'src/app/_services/Dexie/dexie-js.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';

@Component({
  selector: 'app-purge-plan',
  templateUrl: './purge-plan.component.html',
  styleUrls: ['./purge-plan.component.scss']
})
export class PurgePlanComponent implements OnInit {

  userId: string;
  fileId:any;
  fileListMyDrive: FilesList;

  constructor(public networkCalls: DashboardService,
              public toaster: ToastrService,
              private dialogRef: MatDialog,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private spinner: NgxSpinnerService,
              private subjectBehaviourSharedService: SubjectiveBehaviorSharedService,
              public indexCache: IndexedDbService
              ) {
                this.fileId = data.file;
               }

  ngOnInit() {
  }

  keepFile(){

  }

  deletePermanently(){
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    this.spinner.show();
    if(this.data['source'] == 'drive'){
      this.fileId = this.data['file']
      this.indexCache.deleteFileData(this.fileId)
    } 
     this.networkCalls.deletePurgeFile(this.userId, this.fileId  , this.data['source'] ).subscribe(response => {
      this.spinner.hide();
        if(response['message'] == 'success'){
          this.toaster.success('File deleted');
          var data= { 
            message : true,
            is_archive: this.data['is_archive']
          };
          this.subjectBehaviourSharedService.setpurgeFileStatus(data);
          this.dialogRef.closeAll(); 
        } else{
          this.toaster.error('Error occured while deleting the file')
          this.dialogRef.closeAll(); 
        }
    }, error => {
          this.toaster.error('Error occured while deleting the file')
          this.dialogRef.closeAll(); 
    });
   }
}