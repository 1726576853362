import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-rename-file',
  templateUrl: './rename-file.component.html',
  styleUrls: ['./rename-file.component.scss']
})
export class RenameFileComponent implements OnInit {
  selectedFile: any;
  form: FormGroup;
  userId: string;
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<RenameFileComponent>,
    public sharedService: SharedService,
    private networkCalls: DashboardService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService, ) {
  }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    this.selectedFile = this.sharedService.renameFile;
    this.form = this.formBuilder.group({
      filename: new FormControl('', [Validators.required, Validators.minLength(1)
        , Validators.pattern(/^[^/<>:\"\\|?*.]+$/)
      ])
    });
    this.form.setValue({'filename':this.selectedFile.file_name});
  }
  submit(form) {
    this.selectedFile.file_name = form.value.filename;
    this.spinner.show();
    this.networkCalls.putUpdateFile(this.userId, this.selectedFile).subscribe(result => {
      this.dialogRef.close(`${form.value.filename}`);
      this.toastr.success("File name changed successfully.")
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

}
