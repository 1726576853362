<div class="outer_login_wrap_section">
    <div class="login_left_side">
        <div class="left_login_overlay"></div>
        <div class="login_content">
            <div class="top_logo">
                <a href="https://trinka.ai" target="_blank">
                    <img src="/assets/images/trinka-logo-login.png" alt="">
                </a>
            </div>
            <div class="login_text_contents">
                <h2>Welcome to Trinka!</h2>
                <p>With one free account, access Trinka’s powerful language correction within Microsoft Word and on the
                    Web.</p>
            </div>
            <div class="login_footer">
                <ul>
                    <li><a href="https://trinka.ai/privacypolicy" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://trinka.ai/termsofservices" target="_blank">Terms of Services</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="login_right_side">
        <div class="personalize_sections alreadyVerifiedSection">
            <div *ngIf= "userLoggedIn" class="text-right w-100 dashbaord_user_name">
                <span class="account">
                    <button mat-icon-button [matMenuTriggerFor]="menu1" class="p-0">
                        <img class="img-fluid" src="../../../../assets/images/Icon_Account.svg" alt="logo favicon">
                    </button>
                    <mat-menu #menu1="matMenu" class="profile-menu personalize_top_menus">
                        <a mat-menu-item [routerLink]="['/dashboard/profile']">
                            <img class="img-fluid" src="../../../../assets/images/Icon_ManageProfile.svg"
                                alt="logo favicon">
                            <span> Account Settings</span>
                        </a>
                        <button mat-menu-item (click)="signOut()"><img class="img-fluid"
                                src="../../../../assets/images/Icon_Logout.svg" alt="logo favicon">
                            Logout</button>
                    </mat-menu>
                </span>
                
            </div>

            <div class="userEmailVerify_section">
                <img src="../../../assets/images/verifyedEmaild_icon.svg" alt="">
                <h2>{{verificationMessage}}</h2>
                <p>Now enjoy the benefits of Trinka with your secured account!</p>
                <a *ngIf= "userLoggedIn" href="/dashboard">Continue to Trinka</a>
                <a *ngIf= "!userLoggedIn" href="/signin">Login Now</a>
            </div>
        </div>
    </div>
</div>