import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Profile } from 'src/app/_interfaces/editor/profile';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-free-trial-model',
  templateUrl: './free-trial-model.component.html',
  styleUrls: ['./free-trial-model.component.scss']
})
export class FreeTrialModelComponent implements OnInit {

  exampleEnable: boolean=  false;
  isDisabled : boolean= false;
  userId: string;
  timeLeft: any;
  trialStatus ;
  userData : any;
  profileData: Profile;

  constructor(private subjectSharedService: SubjectiveBehaviorSharedService,
    private sharedService: SharedService,
    private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public basicTrialUser: any,
    private dialogRef: MatDialog
    ) {
      const localMeta = this.sharedService.getLocalStorageMeta();
      this.userId = btoa(localMeta.sub);
      let data = {
        "user_id" : btoa(localMeta.sub)
      };
      this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
      this.trialStatus = basicTrialUser.trial;
     }

  ngOnInit() {
  }

  trialEnable(){
    var request={
      "power_mode": true
    }
    this.dashboardService.putselectedUserProfile(this.userId, request).subscribe(result=>{
      if(result['status']){
        this.profileData = result['data']['profile_data']
        this.subjectSharedService.setBasicUserPowerModeTrialData(this.profileData);
        this.subjectSharedService.setTrialMode(true);
      }
    });
    this.dialogRef.closeAll();
  }

  seeExample(){
    this.exampleEnable = true;
  }

  goBack(){
    this.exampleEnable = false;
  }

  upgradeToPremium(){
    let redirct_url  = environment.payment_fe;
    if(this.basicTrialUser.message){
      this.getSelectedProfileData()
      this.dialogRef.closeAll();
    }
    window.open(redirct_url+'/user/'+this.userData,"_blank")
  }

  freeTrialClose(){
    if(this.basicTrialUser.message){
      this.getSelectedProfileData()
    }
    this.dialogRef.closeAll();
  }

  getSelectedProfileData(){
    this.dashboardService.getSelectedProfileData(this.userId).subscribe(result => {
      this.profileData = result['data']['profile_data'];
      this.subjectSharedService.setBasicUserPowerModeTrialData(this.profileData);
      this.timeLeft = this.profileData.powermode_available_time * 1000;
    });
  }
}
