import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReferenceCheckComponent } from '../reference-check.component';

@Component({
  selector: 'app-reference-check-dialog',
  templateUrl: './reference-check-dialog.component.html',
  styleUrls: ['./reference-check-dialog.component.scss']
})
export class ReferenceCheckDialogComponent implements OnInit {

  individualData;
  constructor(  private dialogRef: MatDialogRef<ReferenceCheckComponent>,
    @Inject(MAT_DIALOG_DATA) IndividualData) { 
      this.individualData = IndividualData.individualData;
    }

  ngOnInit() {
  }

}
