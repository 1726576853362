<div class="needHelpChoosingPopup">
    <button class="cancelbuttons" mat-button (click)="close()">
        <img src="../../../../assets/images/closeIcons.png" alt="">
    </button>
    <h1>Need help choosing the right plagiarism report?</h1>
    <p>Trinka offers 3 options to run a plagiarism check for your document. Plagiarism checks can be performed using credits.</p>
    <div class="row">
        <div class="col-md-4"  [ngClass]="selectedPlagCheckType==1 ? 'boxSelected': ''">
            <div class="ChoosingContent">
                <div class="choosingHeader">
                    <h2>Score Only</h2>
                </div>
                <p  style="margin-bottom: 20px;">For academic and research writing</p>
                <p style="height: 40px;margin-bottom: 22px;">Checks across Internet and <br /><span>paid publications</span></p>
                <p style="margin-bottom: 20px;">No report </p>
                <div class="choosingCredits">
                    <p style="color: #FF5C5C;">Up to 2 Free checks</p>
                    <span>per month</span>
                </div>
                <button [disabled]="free_score==0" (click)="setplagcheckType(1)" [ngClass]="selectedPlagCheckType==1 ? 'selectedPlag': ''">
                    <span *ngIf="selectedPlagCheckType!=1">Select</span>
                    <span *ngIf="selectedPlagCheckType==1">Selected <i class="fa fa-check-circle-o"></i></span>
                </button>
            </div>
        </div>
        <!-- iThenticateSection -->
        <div class="col-md-4" [ngClass]="selectedPlagCheckType==3 ? 'boxSelected': ''">
            <div class="ChoosingContent popularCardBox">
                <b>Popular</b>
                    <div class="choosingHeader">
                        <h2>Trinka Plagiarism</h2>
                    </div>
                    <p style="margin-bottom: 20px;">For general writing</p>
                    <p style="height: 40px;margin-bottom: 22px;">Checks across Internet<br /><span style="font-style: normal;text-decoration: none;">publications</span></p>
                    <p style="margin-bottom: 20px;">Complete report</p>
                    <div class="choosingCredits">
                        <p>12 Credits</p>
                        <span>per report</span>
                    </div>
                    <button (click)="setplagcheckType(3)" [ngClass]="selectedPlagCheckType==3 ? 'selectedPlag': ''">
                        <span *ngIf="selectedPlagCheckType!=3">Select</span>
                        <span *ngIf="selectedPlagCheckType==3">Selected <i class="fa fa-check-circle-o"></i></span>
                    </button>
                    <span *ngIf="credits<12">You’re {{12-credits}}  
                        <span *ngIf="credits>1 || credits==0">&nbsp;credits&nbsp;</span>
                        <span *ngIf="credits==1">&nbsp;credit&nbsp;</span>
                        short. </span>
                </div>
        </div>
        <div class="col-md-4 "  [ngClass]="selectedPlagCheckType==2 ? 'boxSelected': ''">
            <!-- HighestQualityBox -->
            <div class="ChoosingContent">
                <b>Highest Quality</b>
                <div class="choosingHeader">
                        <h2>iThenticate Plagiarism</h2>
                    </div>
                    <p style="margin-bottom: 20px;">For academic and research writing</p>
                    <p style="height: 40px;margin-bottom: 22px;">Checks across Internet and <br /><span>paid publications</span></p>
                    <p style="margin-bottom: 20px;">Complete report</p>
                    <div class="choosingCredits">
                        <p>18 Credits</p>
                        <span>per report</span>
                    </div>
                    <button (click)="setplagcheckType(2)" [ngClass]="selectedPlagCheckType==2 ? 'selectedPlag': ''">
                        <span *ngIf="selectedPlagCheckType!=2">Select</span>
                        <span *ngIf="selectedPlagCheckType==2">Selected <i class="fa fa-check-circle-o"></i></span>
                    </button>
                    <span *ngIf="credits<18">You’re {{18-credits}} 
                        <span *ngIf="credits>1 || credits==0">&nbsp;credits&nbsp;</span>
                        <span *ngIf="credits==1">&nbsp;credit&nbsp;</span>
                        short. </span>
            </div>
        </div>
    </div>
    <p class="creditBalance">Your Credit Balance : <b>{{credits}}
         <span *ngIf="credits>1 || credits==0">credits</span>
         <span *ngIf="credits==1">credit</span>
        </b></p>
</div>