import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-language-preferences',
  templateUrl: './language-preferences-global.component.html',
  styleUrls: ['./language-preferences-global.component.scss']
})

export class LanguagePreferencesGlobalComponent implements OnInit {
  globalLanguage = 'US';
  userId: string;
  userDetails = {
    language: 'US'
  };
  constructor(
    private sharedService: SharedService,
    public networkCalls: DashboardService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public amplitudeService: AmplitudeService
  ) { }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    this.getUserProfileData()
  }

  getUserProfileData() {
    this.spinner.show();
    this.networkCalls.getUserDetails(this.userId).subscribe(data => {
      this.userDetails = data.data
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  languagePrefe(langType) {
    this.userDetails['language'] = langType;
    this.spinner.show();
    this.networkCalls.putUserDetails(this.userId, this.userDetails).subscribe(data => {
      this.userDetails = data.data
      this.spinner.hide();
      this.amplitudeService.logAmplitude('en_preference_set', {
        'userID':this.userId,
        'locale':langType
         });
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  closedLanguageModal(){
    // alert("Hello");
    $(".cdk-overlay-backdrop-showing").click();
  }
}