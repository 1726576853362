<div class="word_plugin_body">
    <div class="word_plugin_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="ms_word_section_img">
                        <img src="../../../assets/images/ms-word-img.png" alt="">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="ms_word_section_text">
                        <h2>All the power, unrestricted</h2>
                        <p>Trinka Enterprise comes with unlimited access to all of Trinka’s powerful capabilities.</p>
                        <p>Best suited for academic institutions, localization businesses, publishers, and enterprises working with content.</p>
                        <div class="buttons_optinos">
                            <input type="email" class="form-control" id="email-add1"
                            placeholder="Enter your email address" [(ngModel)]="email" name="email">
                            <button class="plansButtons" (click)="contactToSales()">Contact Sales
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 31.49 31.49"
                                    style="enable-background:new 0 0 31.49 31.49;" xml:space="preserve">
                                    <path
                                        d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
                                   C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
                                   c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z" />
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="better_writing_sections">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-sm-6">
                    <div class="writing_bullet_points">
                        <p>Localization businesses</p>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="writing_bullet_points">
                        <p>Copy-editing and pre-press businesses</p>
                    </div>
                </div>

                <div class="col-md-2 col-sm-6">
                    <div class="writing_bullet_points">
                        <p>Publishers</p>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="writing_bullet_points">
                        <p>Universities and institutions</p>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="writing_assistant_sections">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="assistant_heading">
                        <h2>The only writing assistant you'll need for your team</h2>
                        <p>Power up all your teams to write better. Team and enterprise users get unlimited access and attractive benefits.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6">
                    <div class="assistant_cards">
                        <img src="../../../assets/images/UnlimitedAccess.png" alt="">
                        <h3>Unlimited access</h3>
                        <p>Enterprise users get unlimited access to Trinka and all of its features across all platforms.</p>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="assistant_cards">
                        <img src="../../../assets/images/ExclusiveBenefits.png" alt="">
                        <h3>Exclusive benefits</h3>
                        <p>Enterprise users get Trinka at an attractive value. The bigger the team, the better it gets.</p>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="assistant_cards">
                        <img src="../../../assets/images/TeamCustomization.png" alt="">
                        <h3>Team customizations</h3>
                        <p>Make Trinka as unique as your needs and increase productivity with customizations.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="writing_assistant_sections get_trinka_API">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="assistant_heading">
                        <h2>Get Trinka API</h2>
                        <p>Need Trinka within your product? Trinka’s powerful API lets you easily integrate it into any application. The simple to use API offers high reliability and scalability.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6">
                    <div class="assistant_cards">
                        <img src="../../../assets/images/RobustAPI.png" alt="">
                        <h3>Robust API</h3>
                        <p>Trinka API offers high service availability so you get the help you need any time you want.
                        </p>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="assistant_cards">
                        <img src="../../../assets/images/API_Customizations.png" alt="">
                        <h3>API customizations</h3>
                        <p>Have unique needs for your application? Just ask. Trinka API is highly customizable.</p>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="assistant_cards">
                        <img src="../../../assets/images/UnmatchedFlexibility.png" alt="">
                        <h3>Unmatched flexibility</h3>
                        <p>The powerful API is easy to use and resilient too. Trinka matches your product scale to scale.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="container" style="display: none;">
        <div class="row">
            <div class="col-sm-12">
                <div class="clients_logo">
                    <h2>Trusted by world leading businesses</h2>
                    
                </div>
            </div>
        </div>
    </div>

</div>