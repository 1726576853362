import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-select-profile-modal',
  templateUrl: './select-profile-modal.component.html',
  styleUrls: ['./select-profile-modal.component.scss']
})
export class SelectProfileModalComponent implements OnInit {

  constructor(
    private sharedService: SharedService,
    public networkCalls: DashboardService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<SelectProfileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    dialogRef.disableClose = true;
  }
  userId: any;
  profileList = [];
  selectedProfile : any;
  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
  }
  
  onSelect(selectedProfile){
    // selectedProfile['subdomains'][0]['subdomainId'] = 108;
      this.networkCalls.putselectedUserProfile(this.userId,selectedProfile).subscribe(result=>{
        if(result['status']){
          this.toastr.success(result['message']);
          this.dialogRef.close();
        }
      })
  }

  closeDProfileModal(){
    this.dialogRef.close();
  }

}
