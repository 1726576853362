import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ms-word-app',
  templateUrl: './ms-word-app.component.html',
  styleUrls: ['./ms-word-app.component.scss']
})
export class MsWordAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
