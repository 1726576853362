import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { JournalFinder } from './journal-finder';
// import { MydriveComponent } from 'src/app/components/dashboard/mydrive/mydrive.component';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
import { MainComponent } from 'src/app/components/journal-finder/main/main.component';

const routes: Routes = [
  // { path: '', component: JournalFinder, children: [
   { path: 'report/:id', component: JournalFinder, canActivate: [AuthGuardService]},
    {path:'',component: MainComponent, canActivate: [AuthGuardService] },
  // ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JournalFinderRoutingModule { }
