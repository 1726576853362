<div class="container popup reference_check_popup">
  <div class="row">
    <div class="col-sm-12">
      <div class="popup_heading">
        <h2>{{title}}</h2>
        <button class="cancelbuttons" mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
          </button>
      </div>
      <div class="reference_check_popup_body">
        <div class="technical_complianc_popup" [innerHTML]="data">
        </div>
      </div>
    </div>
  </div>
</div>