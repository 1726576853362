<div class="afe-info-modal-outer">
    <div class="afe-info-modal-content">
        <div class="afe-info-modal-header">
            <h2>How it works</h2>
        </div>
        <div class="afe-info-modal-first-content">
            <div class="afe-info-modal-first-content-step">
                <span class="afe-info-cloud-upload-icon"></span>
                <p class="step-heading">Step 1</p>
                <p class="step-content">Upload (doc/docx/LaTeX) file</p>
            </div>
            <div class="afe-info-modal-first-content-step">
                <span class="afe-info-refresh-square-icon"></span>
                <p class="step-heading">Step 2</p>
                <p class="step-content">Apply settings and start proofreading</p>
            </div>
            <div class="afe-info-modal-first-content-step">
                <span class="afe-info-download-icon"></span>
                <p class="step-heading">Step 3</p>
                <p class="step-content">Download file with trackable changes</p>
            </div>
        </div>
        <div class="afe-info-modal-second-content">
            <h2 class="afe-info-modal-second-content-heading">More Details</h2>
            <ul>
                <li>You can proofread a file up to 30,000 words or 250 MB in one go.</li>
                <li>1 credit will be used per 5,000 words.</li>
                <li>Get a word file with trackable changes even when you process the LaTeX file.</li>
                <li>For basic user, if file is processed with free credits, downloaded word file will be read only.</li>
            </ul>
        </div>
    </div>
    <div class="afe-info-modal-footer">
            <div class="afe-info-modal-footer-btn">
                <button mat-dialog-close class="afe-info-modal-footer-cancel-btn">Close</button>
            </div>
    </div>
</div>