import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BrowserExtensionComponent } from './browser-extension.component';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
import { MainComponent } from 'src/app/components/browser-extension/main/main.component';
import { InstallComponent } from 'src/app/components/browser-extension/install/install.component';
import { UninstallComponent } from '../../components/browser-extension/uninstall/uninstall.component';

const routes: Routes = [
    { path: '', component: BrowserExtensionComponent, children: [
    { path: '', redirectTo: 'main', pathMatch:'full' },
    { path: 'main/:id', component: MainComponent, canActivate: [AuthGuardService]},
    // { path: 'main/:id/transaction/:transactionPayload', component: MainComponent,canActivate: [AuthGuardService] },
    { path: 'success', component: InstallComponent },
    { path: 'uninstall', component: UninstallComponent },
  ]
 }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BrowserExtensionRoutingModule { }
