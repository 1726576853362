import { ApplicationRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import {
  MatSnackBar,
  MatDialog,
  MatDialogConfig,
} from "@angular/material";
import { SharedService } from "src/app/_services/shared.service";
import { DashboardService } from "src/app/_services/networkcalls/dashboard.service";
import { AmplitudeService } from "src/app/_services/amplitude.service";
import { CreditDetailsComponent } from "../credit-details/credit-details.component";
import { environment } from "src/environments/environment";
import { AutoRef } from "src/app/_enums/auto-refer.enum copy";
import { ReferenceCheckService } from "src/app/_services/networkcalls/reference-check.service";
import { SubjectiveBehaviorSharedService } from "src/app/_services/subjective-behavior-shared.service";
import { VerifiedUserService } from "src/app/_services/networkcalls/verified-user.service";
import { ShowMyDriveFilesModalComponent } from "../show-my-drive-files-modal/show-my-drive-files-modal.component";
import { GoogleDrivePickerService } from "src/app/_services/google-drive-picker.service";
import { DropboxChooseOptions,Dropbox } from 'src/app/_interfaces/dashboard/dropbox-choose-options';
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { OnlineEditorService } from "src/app/_services/networkcalls/online-editor.service";
import { Router } from "@angular/router";
import { ReferralRewardModalComponent } from "../referral-reward-modal/referral-reward-modal.component";

declare function wisepop(): any;
declare const OneDrive: any;
declare var Dropbox: Dropbox;
@Component({
  selector: "app-reference-quality",
  templateUrl: "./reference-quality.component.html",
  styleUrls: ["./reference-quality.component.scss"],
})
export class ReferenceQualityComponent implements OnInit {
  creditsDetails: any = {};
  userId: any;
  emailId: string;
  element: HTMLElement;
  @ViewChild("referenceQuality", { static: true }) referenceQualityControl: any;

  referenceQuality = null;
  files_submitted = [];
  selectedFiles: FileList;
  fileName: string;
  referenceList: any;
  noOfRef = -1;
  per_credit_ref: number = 0;
  base_credit: number = 0;
  credit_used: number;
  totalCredit: number = 0;

  refQualityFileList = [];
  fileSelected: boolean = false;
  fileUploaded : boolean = false;
  userData: any;
  paymentDetails: any;
  selectedProfile:any = {};
  profileName: any = "";
  loaderStatus: boolean = true;
  cloudDriveFileDetails = {"fileId":"", "fileName": "", "fileUrl":"", "source": "", "oauthToken": "", "userId": ""};
  uploadSource =[
    {
     "GOOGLE_DRIVE" : false,
     "TRINKA_DRIVE" : false,
     "ONE_DRIVE"    : false,
     "DROP_BOX"     : false,
     "LOCAL_SYSTEM" : false
     }
   ];
   selectedFileName : any;
   selectedCreditReq  : any;
   selectedFileId : any;
   selectedTotalScore : any;
   filterModel = "";
   selectedPerc: any;
   referralReward: number = 0;
   country:string = "";
   citationCheckPageLoader: boolean = true;

  constructor(
    public sharedService: SharedService,
    public networkCalls: ReferenceCheckService,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public amplitudeService: AmplitudeService,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    private googleDrivePickerService: GoogleDrivePickerService,
    private appRef: ApplicationRef,
    private onlineEditorService: OnlineEditorService,
    private verifiedUserService: VerifiedUserService,
    private router: Router
  ) {}

  ngOnInit() {
    wisepop();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.emailId = localMeta.sub;
    let data = {
      user_id: this.userId,
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.getUserPaymentSubscription();
    this.getCreditsDetails(true);
    this.getSelectedProfileData();
    this.amplitudeService.setUserId(this.userId);
    this.fileUploaded = false;
    this.fileSelected = false;
    this.subjectiveBehaviorSharedService.getEditorFileId().subscribe(file_id => {
      if(file_id != "" && file_id != null){
        this.uploadSource["TRINKA_DRIVE"] = true;
        this.cloudDriveFileDetails.fileId = file_id;
        this.fileSelected = true;
      }
    });
    this.subjectiveBehaviorSharedService.getEditorFileName().subscribe(file_name => {
      if(file_name!= "" && file_name!=null)
      {
        this.fileName = file_name;
        if(file_name){
          if(!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx"))){
            this.fileName +=".docx"
          }
        }
        this.cloudDriveFileDetails.fileName = this.fileName;
        this.cloudDriveFileDetails.source = "TRINKA_DRIVE";
        this.cloudDriveFileDetails.userId = this.userId;
        this.fileSelected = true;
      }
    });
    this.country = localStorage.getItem("country-trinka");
    this.per_credit_ref = environment.per_credit_ref;
    this.base_credit = environment.base_credit;
    this.getRefQualityFileList(true);
    this.fetchReferralRewards();
    setTimeout(() => {
      this.citationCheckPageLoader = false;
    }, 3000);
  }


  getSelectedProfileData() {
    this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(profileData=>{
        this.selectedProfile = profileData;
        this.profileName = this.selectedProfile!="" ? (this.selectedProfile['firstname'].substr(0,1) +""+ this.selectedProfile['lastname'].substr(0,1)).toUpperCase(): "";
    });
    if(this.selectedProfile==''){
    this.onlineEditorService.getSelectedProfileData(this.userId).subscribe(result => {
      if (result["status"]) {
        this.selectedProfile = result['data']['profile_data'];
        this.profileName = (this.selectedProfile['firstname'].substr(0,1) +""+ this.selectedProfile['lastname'].substr(0,1)).toUpperCase();
        this.subjectiveBehaviorSharedService.setSelectedProfileData(this.selectedProfile)
      }
    }, error => {
      this.sharedService.errorHandller(error);
    });
   }
  }

  signOut() {
    this.sharedService.SignOut();
  }

  getUserPaymentSubscription(){
    this.dashboardService.getUserPaymentSubscription(this.userId).subscribe(response => {
      this.paymentDetails = response['data'];
      this.amplitudeService.setUserProperties('subscription_type',(this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase()));
    });
  }

  getCreditsDetails(flag=false) {
    // this.spinner.show();
    this.dashboardService.getCreditsDetailsByUser(this.userId).subscribe(
      (response) => {
        if (response["status"]) {
          this.creditsDetails = response.data;
          this.totalCredit = this.creditsDetails.credits  + this.creditsDetails.purchased_credits;
        }
        if(flag){
        this.amplitudeService.logAmplitude("RFQ_Landing_Pageview", {
          'Credit Balance Free': this.creditsDetails.credits,
          'Credit Balance Purchased': this.creditsDetails.purchased_credits
        });
      }
        // this.spinner.hide();
      },
      (error) => {
        this.sharedService.errorHandller(error);
      }
    );
  }

  addCredits() {
    this.amplitudeService.logAmplitude("RFQ_Insuff-Credit_AddCredits", {
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits,
      'FileId': this.selectedFileId,
      'Total References': this.selectedTotalScore
    });
    let redirct_url = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url + "/user/credit/" + this.userData, "_self");
  }

  logCancelInsuffeicent(){
    this.amplitudeService.logAmplitude("RFQ_Insuff-Credit_Cancel", {
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits,
      'FileId': this.selectedFileId,
      'Total References': this.selectedTotalScore
    });
  }

onDragOver(event) {
  event.preventDefault();
}

onDropSuccess(event) {
  event.preventDefault();
  this.onFileChange(event.dataTransfer.files);
}

  onFileChange(event) {
    this.resetCloudFileDetails();
    this.noOfRef = -1;
    this.files_submitted = [];
    this.selectedFiles = event;
    if (this.selectedFiles.length > 1) {
      this.fileName = this.selectedFiles.length + " files selected";
    } else {
      this.fileName = this.selectedFiles[0].name;
    }
    try {
      for (var i = 0; i < this.selectedFiles.length; i++) {
        var file = event[i];
        this.files_submitted.push(file);

        this.validateFileType(file);
        if (!this.validFile) {
          this.toastr.error(
            "Please upload file with doc, docx format.",
            "Error"
          );
          this.fileSelected = false;
          this.fileName = "";
          this.uploadSource["LOCAL_SYSTEM"] = false;
          break;
        } else {
          this.referenceQuality = file;
          this.fileSelected = true;
          this.uploadSource["LOCAL_SYSTEM"] = true;
          this.cloudDriveFileDetails.source = "LOCAL_SYSTEM";
          this.cloudDriveFileDetails.fileName = file.name;
          this.amplitudeService.logAmplitude('RFQ_upload_via_MyDevice',{
            'Credit Balance Free': this.creditsDetails.credits,
            'Credit Balance Purchased': this.creditsDetails.purchased_credits
          });
        }
      }
    } catch (error) {
      this.uploadSource["LOCAL_SYSTEM"] = false;
    }
  }

  validFile = false;
  validateFileType(file) {
    const fileType = file.name.split(".").pop().toLowerCase().toString();

    if (fileType === "doc" || fileType === "docx") {
      this.validFile = true;
    } else {
      this.validFile = false;
    }
  }

  getRefQualityFileList(flag=false) {
    if(flag){
      this.loaderStatus = true;
    }
    this.networkCalls.getRefQualityFileList(this.emailId).subscribe((res) => {
      this.refQualityFileList = res.data.documents;
      if(flag){
        this.loaderStatus = false;
      }
      for (let i = 0; i < this.refQualityFileList.length; i++) {
        if (
          this.refQualityFileList[i].status === AutoRef.IN_PROGRESS ||
          this.refQualityFileList[i].status === AutoRef.WAITING_IN_QUEUE
        ) {
          setTimeout(() => {
            this.getRefQualityFileList();
            this.getCreditsDetails();
          }, 5000);
          return;
        }
      }
    });
  }

  credits() {
    if (this.creditsDetails != null) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData: this.creditsDetails,
      };

      this.dialog.open(CreditDetailsComponent, dialogConfig);
    }
  }

  startAutoRefQuality() {
    this.fileUploaded = true;
    if (!this.fileSelected) {
      this.toastr.error("Please upload a file", "Error");
    } else {
      this.amplitudeService.logAmplitude('RFQ_CheckReferences_CTA',{
        'Credit Balance Free': this.creditsDetails.credits,
        'Credit Balance Purchased': this.creditsDetails.purchased_credits,
        'FileID': this.cloudDriveFileDetails.fileId
      });
      this.appRef.tick();
      if(this.uploadSource["LOCAL_SYSTEM"]){
        this.uploadAutoRefQualityFile();
      }else{
        this.uploadCloudFileForReferenceCheck();
      }

    }
  }

  cancelFileSelection(){
    this.amplitudeService.logAmplitude('RFQ_Cancel_CTA',{
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits,
      'FileID': this.cloudDriveFileDetails.fileId
    });
    this.resetCloudFileDetails();
  }

  arrayChunk(list: any[], chunkSize: number) {
    var R = [];
    for (var i = 0; i < list.length; i += chunkSize)
      R.push(list.slice(i, i + chunkSize));
    return R;
  }
  uploadAutoRefQualityFile(initialIndex = 0, flag = false) {
    this.fileUploaded = true;
    var file_length = 0;
    if (this.referenceQuality || flag) {
      try {
        var chunkArray = this.arrayChunk(this.files_submitted, 5);
        let index = 0;
        for (index = 0; index < chunkArray[initialIndex].length; index++) {
          const element = chunkArray[initialIndex][index];

          var fileObject = new FormData();
          file_length = initialIndex;
          fileObject.append("file", element);
          fileObject.append("email_id", this.emailId);
          fileObject.append('cloudFileDetails', JSON.stringify(this.cloudDriveFileDetails));

          // if (!flag) {
          //   this.spinner.show();
          // }
          this.networkCalls.uploadAutoRefQualityFile(fileObject).subscribe(
            (res) => {
              if (res.data.status == "OVER_LIMIT") {
                this.toastr.error(
                  "Word count greater than 25,000 words is not currently supported.",
                  "Error"
                );
              } else {
                this.noOfRef = -1;
                if (initialIndex == chunkArray.length - 1) {
                  this.toastr.success(
                    "Citation check has begun. Check back in a few minutes"
                  );
                  this.referenceQuality = null;
                  this.fileName = null;
                  this.fileSelected = false;
                  this.fileUploaded = false;
                  this.getRefQualityFileList();
                  this.spinner.hide();
                }
              }
            },
            (error) => {
              file_length = initialIndex;
              this.referenceQuality = null;
              this.fileName = null;
              this.fileSelected = false;
              this.fileUploaded = false;
              this.sharedService.errorHandller(error);
            }
          );
        }
        // if (index != chunkArray[initialIndex].length - 1) {
        //   setTimeout(() => {
        //     this.uploadAutoRefQualityFile(initialIndex + 1, true);
        //     this.getRefQualityFileList();
        //   }, 2000);
        // }
      } catch (error) {
        this.fileSelected = false;
        this.fileUploaded = false;
      }
    } else {
      this.toastr.error("Please upload file with doc, docx format.", "Error");
    }
  }

  reUploadFile(file) {
    if (file.status == "FAILED") {
      let data = new FormData();
      data.append("email_id", this.emailId);
      this.amplitudeService.logAmplitude('RFQ_Report_Retry_CTA',{
        'Credit Balance Free': this.creditsDetails.credits,
        'Credit Balance Purchased': this.creditsDetails.purchased_credits,
        'FileID': file.id
      });
      this.networkCalls.reUploadOnholdQualityFile(file.id, data).subscribe(
        (res) => {
          this.getRefQualityFileList();
          this.getCreditsDetails();
        },
        (error) => {
          this.sharedService.errorHandller(error);
        }
      );
    }
  }

  buyReport(){
      let data = new FormData();
      data.append("email_id", this.emailId);
      this.amplitudeService.logAmplitude('RFQ_BuyReport_Confirmation',{
        'Credit Balance Free': this.creditsDetails.credits,
        'Credit Balance Purchased': this.creditsDetails.purchased_credits,
        'FileID': this.selectedFileId,
        'Total References': this.selectedTotalScore
      });
      this.networkCalls.getRefQualityDocument(this.selectedFileId, this.emailId).subscribe(
        (res) => {
          if(res["status"]){
            this.getRefQualityFileList();
            this.getCreditsDetails();
            const link = this.router.serializeUrl(this.router.createUrlTree(['/citation-check-report'], { queryParams: {document_id: res["data"]["id"]}}));
            window.open(link, '_blank');
          }

        },
        (error) => {
          this.sharedService.errorHandller(error);
        }
      );
  }

  cancelBuyReport()
  {
    this.amplitudeService.logAmplitude('RFQ_BuyReport_Cancel',{
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits,
      'FileID': this.selectedFileId,
      'Total References': this.selectedTotalScore
    });
  }

  viewReport(fileId){
    this.amplitudeService.logAmplitude('RFQ_ViewReport_CTA',{
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits,
      'FileID': fileId,
      'Credits Used': this.selectedCreditReq,
      'Total References': this.selectedTotalScore,
      'score': ~~this.selectedPerc
    });
  }

  setCurrentFileDetails(file, buyLog){
      this.selectedFileName = file.filename;
      this.selectedCreditReq = file.credit_req;
      this.selectedFileId = file.id;
      this.selectedTotalScore = file.no_of_references;
      this.selectedPerc = file.bait.percentage;
      if(buyLog){
        this.amplitudeService.logAmplitude('RFQ_BuyReport_CTA',{
          'Credit Balance Free': this.creditsDetails.credits,
          'Credit Balance Purchased': this.creditsDetails.purchased_credits,
          'FileID': this.selectedFileId,
          'Credits Used': this.selectedCreditReq,
          'Total References': this.selectedTotalScore
        });
      }
  }


  resetCloudFileDetails(){
    let that= this;
    this.fileName = null;
    this.fileSelected = false;
    this.fileUploaded = false;
    this.files_submitted = [];
    Object.keys(this.uploadSource).forEach(function(key){
      that.uploadSource[key] = false;
    });

    Object.keys(this.cloudDriveFileDetails).forEach(function(key){
      that.cloudDriveFileDetails[key] = "";
    });
    that.cloudDriveFileDetails["userId"] = this.userId;
    this.appRef.tick();
  }

  launchTrinkaDrivePicker(){
    this.resetCloudFileDetails();
    this.uploadSource["TRINKA_DRIVE"] = true;
    const dialogRef = this.dialog.open(ShowMyDriveFilesModalComponent,{ autoFocus: false ,scrollStrategy: new NoopScrollStrategy()
    });
    dialogRef.afterClosed().subscribe(result => {
     this.fileName = result.file_name;
     if(!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx"))){
      this.fileName +=".docx"
     }
     this.cloudDriveFileDetails.fileId = result.file_id;
     this.cloudDriveFileDetails.fileName = this.fileName;
     this.cloudDriveFileDetails.source = "TRINKA_DRIVE";
     this.fileSelected = true;
     this.amplitudeService.logAmplitude('RFQ_upload_via_TrinkaDrive',{
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits
     });
    });
  }

  launchOneDrivePicker(){

    this.resetCloudFileDetails();
     this.uploadSource["ONE_DRIVE"] = true;
     this.cloudDriveFileDetails.fileId = "";
     var that =this;
     var odOptions = {
      clientId: environment.one_drive_client_id,
      action: "download",
      multiSelect: false,
      openInNewWindow: true,
      advanced: {
        queryParameters: "select=id,name,size,file,folder,@microsoft.graph.downloadUrl",
        filter: [".docx",".doc"],
        redirectUri: environment.cloud_redirect_url+"dashboard/auto-edit",
        },
       success: function(files) {
         for (const file of files.value) {
             that.fileName = file.name;
             that.cloudDriveFileDetails.fileId = file.id;
             that.cloudDriveFileDetails.fileName = file.name;
             that.cloudDriveFileDetails.fileUrl = file['@microsoft.graph.downloadUrl'];
             that.cloudDriveFileDetails.source = "ONE_DRIVE";
             that.fileSelected = true;
             that.amplitudeService.logAmplitude('RFQ_upload_via_OneDrive',{
              'Credit Balance Free': that.creditsDetails.credits,
              'Credit Balance Purchased': that.creditsDetails.purchased_credits
              });
            that.appRef.tick();
         }
        },
       cancel: function() {
         console.log("cancel   ");
        },
       error: function(e) {
         console.log("Error occured at one drive  "+e);
        }
     }
     OneDrive.open(odOptions);
  }

  launchDropBoxPicker(){

    this.resetCloudFileDetails();
     this.uploadSource["DROP_BOX"] = true;
     this.cloudDriveFileDetails.fileId = "";
     let that =this;
     var options: DropboxChooseOptions = {
       success: function (files) {
            for (const file of files) {
                   that.fileName = file.name;
                   that.cloudDriveFileDetails.fileName = file.name;
                   that.cloudDriveFileDetails.fileUrl = file.link;
                   that.cloudDriveFileDetails.source = "DROP_BOX";
                   that.fileSelected = true;
                   that.amplitudeService.logAmplitude('RFQ_upload_via_DropBox',{
                    'Credit Balance Free': that.creditsDetails.credits,
                    'Credit Balance Purchased': that.creditsDetails.purchased_credits
                   });
                   that.appRef.tick();
               }
       },
       cancel: function () {
         console.log("cancel  selected..")
       },
       linkType: "direct",
       multiselect: false,
       extensions: ['.doc', '.docx'],
   };

   Dropbox.choose(options);
  }

  launchGoogleDrivePicker(){
    this.resetCloudFileDetails();
    this.uploadSource["GOOGLE_DRIVE"] = true;
    this.cloudDriveFileDetails.fileId = "";
    this.googleDrivePickerService.open((data) => {
      if (data.action === 'picked') {
        let file_details = data.docs[0];
        this.fileName = file_details.name;
        if(file_details.name.endsWith(".txt")){
          this.toastr.error('Please upload file with doc, docx format.',"Error");
          return;
        }
        if(!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx"))){
          this.fileName +=".docx"
        }
        this.cloudDriveFileDetails.fileId = file_details.id;
        this.cloudDriveFileDetails.fileName = this.fileName;
        this.cloudDriveFileDetails.source = "GOOGLE_DRIVE";
        this.cloudDriveFileDetails.oauthToken = this.googleDrivePickerService.oauthAccessToken;
        this.fileSelected = true;
        this.amplitudeService.logAmplitude('RFQ_upload_via_GDrive',{
          'Credit Balance Free': this.creditsDetails.credits,
          'Credit Balance Purchased': this.creditsDetails.purchased_credits
        });
        this.appRef.tick();
      }
    });
  }

  uploadCloudFileForReferenceCheck(){
    try {
        this.fileUploaded = true;
        var fileObject = new FormData();
        fileObject.append("email_id", this.emailId);
        fileObject.append('cloudFileDetails', JSON.stringify(this.cloudDriveFileDetails));

        // this.spinner.show();
        this.networkCalls.uploadAutoRefQualityFile(fileObject).subscribe(res => {
          this.spinner.hide();
            if (res['status']) {
              this.toastr.success("Citation check has begun. Check back in a few minutes.");
              this.fileName = null;
              this.referenceQuality = null;
              this.fileName = null;
              this.fileSelected = false;
              this.fileUploaded = false;
              this.getRefQualityFileList();
            }
        }, error => {
          this.fileName = null;
          this.referenceQuality = null;
          this.fileName = null;
          this.fileSelected = false;
          this.fileUploaded = false;
          this.spinner.hide();
          this.sharedService.errorHandller(error);
          this.getRefQualityFileList();
        });
    }
    catch (error) {
      this.fileSelected = false;
      this.fileUploaded = false;
      this.spinner.hide();
      this.sharedService.errorHandller(error);
    }
}
//Redirecting to the billing page
  redirectToUpgradeToPremiumPage(fromTopPanel: boolean = false) {
    let data = {
      "user_id" : this.userId,
      "subscription_type": this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      "from":"Trinka"
    };
    if (!fromTopPanel) this.amplitudeService.logAmplitude('Plag_upgrade_nudge_clicked', {
      "user_id" : this.userId,
      "subscription_type": this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
    });
    else this.amplitudeService.logAmplitude('Upgrade_nudge_clicked', {
      'from': 'Citation Checker',
      "user_id" : this.userId,
      "Current Plan": this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url  = environment.payment_fe;
    window.open(redirct_url + '/user/' + this.userData, "_blank");
  }

  fetchReferralRewards(){
    this.dashboardService.getReferralRewards(this.userId).subscribe(response => {
        this.referralReward = response["referral_reward"] || 0;
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  showReferralRewardModal(){

    let data = {
      "user_id" : this.userId,
      "plan_name": this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      "from":"Trinka"
    };

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let subscriptionUpgradeUrl  = environment.payment_fe + '/user/' + this.userData;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      userId : this.userId,
      referralReward : this.referralReward,
      subscriptionUpgradeUrl: subscriptionUpgradeUrl,
      userType: this.creditsDetails.user_type
    };
    this.dialog.open(ReferralRewardModalComponent, dialogConfig);
  }

  uppercaseFirstLetter(phrase):string{
    if(!phrase) return '';
    else return phrase
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }
}
