import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './components/dashboard/page404/page404.component';
import { EditorReferenceQualityReportComponent } from './components/editor/editor-reference-quality-report/editor-reference-quality-report.component';
import { DeleteFeedbackModalComponent } from './components/delete-feedback-modal/delete-feedback-modal.component';
import { UserVerifyComponent } from './components/user-verify/user-verify.component';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
import { ReferenceQualityComponent } from './components/dashboard/reference-quality/reference-quality.component';
import { WordPluginSinginComponent } from './Components/word-plugin-singin/word-plugin-singin.component';
import { PlagiarismCheckModule } from './modules/plagiarism-check/plagiarism-check.module';
import { UpgradeIdPageComponent } from './Components/upgrade-id-page/upgrade-id-page.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full'},
  { path: 'dashboard', loadChildren: () => import('./modules/dashbaord/dashbaord.module').then(m => m.DashbaordModule) }, 
  { path: 'plagiarism-checker', loadChildren: () => import('./modules/plagiarism-check/plagiarism-check.module').then(m => m.PlagiarismCheckModule) },
  { path: 'journal-finder', loadChildren: () => import('./modules/journal-finder/journal-finder.module').then(m => m.JournalFinderModule) },
  { path: 'editor', loadChildren: () => import('./modules/editor/editor.module').then(m => m.EditorModule) },
  { path: '', loadChildren: () => import('./modules/sign-insign-out/sign-insign-out.module').then(m => m.SignInsignOutModule) },
  { path: 'human-editing', loadChildren: () => import('./modules/human-editing/human-editing.module').then(m => m.HumanEditingModule) },
  { path: 'profile', loadChildren: () => import('./modules/work-flow/work-flow.module').then(m => m.WorkFlowModule) },
  { path: 'extension', loadChildren: () => import('./modules/browser-extension/browser-extension.module').then(m => m.BrowserExtensionModule) },
  { path: 'verifyUser/:verify_key', component:UserVerifyComponent },
  { path: 'citation-check-report', component: EditorReferenceQualityReportComponent, canActivate: [AuthGuardService] },
  { path: 'citation-check-tool', component: ReferenceQualityComponent, canActivate: [AuthGuardService]},
  { path: 'feedback/:key', component: DeleteFeedbackModalComponent},
  { path: 'word-plugin-signin', component: WordPluginSinginComponent },
  { path: 'trinka-native-app', component: WordPluginSinginComponent },
  { path: 'premiumupgrade/:emailVerificationId', component: UpgradeIdPageComponent },
  { path: '404', component: Page404Component },
  { path: '**', component: Page404Component },
  { path: 'my-drive-master', loadChildren: () => import('./modules/my-drive-master/my-drive-master.module').then(m => m.MyDriveMasterModule) }, 
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
