import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends
  DatePipe implements PipeTransform {

  transform(value: any, timeZone?: any): any {
    var date_time = super.transform(value, 'MMMM, hh:mm a');

    var date_time_timeZone = date_time + ', ' + timeZone;
    return this.english_ordinal_suffix(new Date(value).getDate())+" "+date_time_timeZone;
  }

  english_ordinal_suffix(date) {
    return date + (date % 10 == 1 && date != 11 ? 'st' : (date % 10 == 2 && date != 12 ? 'nd' : (date % 10 == 3 && date != 13 ? 'rd' : 'th')));
  }


}
