<div class="container h-100 language-pre mat-typography-config p-4 add_dictionary_popup changes_profile_popup"
  fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap.xs="0" fxLayout="row">
  <div class="row">
    <div class="col-sm-12">
      <div class="popup_heading">
        <h2>{{data.selectedword.word}}</h2>
        <button class="cancelbuttons" mat-button mat-dialog-close>
          <img src="../../assets/images/closeIcons.png" alt="">
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="category_sections">
        <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select name="category" [(ngModel)]="data.selectedword.category">
            <mat-option *ngFor="let cat of categoryList" [value]="cat.value">
              {{cat.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-4">
      <div class="category_sections">
        <mat-form-field>
          <mat-label>American / British English</mat-label>
          <mat-select name="languageType" [(ngModel)]="data.selectedword.localization">
            <mat-option *ngFor="let lang of languageType" [value]="lang.value">
              {{lang.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-4" style="margin: -6px 0 0 0;">
      <div class="category_sections text-center case_sensetive_sections">
        <mat-label>Case Sensitive</mat-label>
        <br>
        <mat-slide-toggle [(ngModel)]="data.selectedword.case_sensitive">Match</mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="dictionary_footer">
    <div class="changes_date_time">
      <span>Last updated on {{data.selectedword.updated_at| date:"short"}}</span>
    </div>

    <div class="changes_buttons">
      <mat-dialog-actions>
        <button class="saveButton" mat-button (click)="updateWord(data.selectedword)">Save</button>
      </mat-dialog-actions>
    </div>
  </div>
</div>