<mat-list class="row headerBoxShadow ManageProfilemobileHeader">
    <div class="col-md-12 top_new_header">
        <div class="page_top_header">
            <h1 class="mat-display-1 mb-0">Plagiarism Check</h1>
            <div *ngIf="creditsDetails" class="AutoEditCreditDetails">
                <img src="../../../../assets/images/Credits_icon.svg" alt="">
                Credits:
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits != 0"
                    (click)="credits()">{{creditsDetails.credits + creditsDetails.purchased_credits}}</span>
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0"
                    (click)="credits()">Nil</span>
                <span *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0" class="autoEditBuyOption"
                    (click)="addCredits()">Buy</span>
            </div>
        </div>
    </div>
</mat-list>

<div class="plagCheckLandingPage">
    <div class="landingPageMainSection">
        <div class="row">
            <div class="col-md-6">
                <div class="lp_mainImage">
                    <img src="../../../../assets/images/plagCheckMainImage.png" alt="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="lp_mainContents">
                    <h2>Find and reduce text similarity</h2>
                    <p>Check and identify the occurrence of plagiarism or copyright infringement within your work over the internet and paid publications.</p>
                    <div class="checkPlagiarismButton">
                        <a [routerLink]="['/plagiarism-checker/upload']" target="_blank">Check Plagiarism</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="whyPlagCheckSection">
        <div class="row">
            <div class="col-md-12">
                <h2>Why choose Trinka plagiarism checker?</h2>
                <div class="row">
                    <div class="col-md-4">
                        <div class="plagCheckBox">
                            <img src="../../../../assets/images/languageScore_icon.svg" alt="">
                            <h3>Free Plagiarism Score</h3>
                            <p>Get a plagiarism score and an overview of sources for free. Get the full report only when you need it!</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="plagCheckBox">
                            <img src="../../../../assets/images/Easy_understand_report.svg" alt="">
                            <h3>Easy to Understand Report</h3>
                            <p>The intuitive PDF report is easy to understand, with unique highlights for different sources.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="plagCheckBox">
                            <img src="../../../../assets/images/Internet-Paid.svg" alt="">
                            <h3>Internet & Paid Publications</h3>
                            <p>Get the most robust check with a database of 82+ million <span class="yellow-bg">Paid publications</span> and Internet content.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="plagCheckBox">
                            <img src="../../../../assets/images/Superior_Technology.svg" alt="">
                            <h3>Superior Detection Technology</h3>
                            <p>Our plagiarism detection technology is used by the largest academic publishers and intelligently excludes cited content to give you reliable results.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="plagCheckBox">
                            <img src="../../../../assets/images/RetainOriginalFormatting.png" alt="">
                            <h3>Daily Database Updates</h3>
                            <p>Our most comprehensive database is updated daily to include  new sources and changes to give you the best results.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="multipleCheckerPlagChecks">
        <div class="row">
            <div class="col-md-12">
                <h3>Multiple Check Options that Suit Your Need</h3>
                <p>Trinka lets you choose the most suitable plagiarism check for your writing to deliver the right value.</p>
                <div class="row">
                    <div class="col-md-12 col-lg-4">
                        <div class="multipleTextContent multipleFristContents">
                            <h4>Free Plagiarism Score</h4>
                            <ul>
                                <li>Get a plagiarism score and an overview of sources</li>
                                <li>Checks against Internet, Open Access, and <span>Paid publications</span></li>
                                <li>Best for getting an overview before deciding to generate a full report</li>
                                <li>Up to two free checks per month</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <div class="multipleTextContent">
                            <h4>Trinka Plagiarism Report</h4>
                            <ul>
                                <li>Checks against Internet sources and some Open Access publications</li>
                                <li>Daily updates and superior detection technology ensure unmatched accuracy</li>
                                <li>Best for non-journal publication needs</li>
                                <li>Uses 12 credits per report</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <div class="multipleTextContent">
                            <h4>iThenticate® Plagiarism Check</h4>
                            <ul>
                                <li>Checks against Internet, Open Access, and <span>Paid publications</span></li>
                                <li>The largest database with daily updates and superior technology trusted by publishers</li>
                                <li>Recommended for publication needs</li>
                                <li>Uses 18 credits per report</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>