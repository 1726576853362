<div class="container h-100 sign-in-wrap mat-typography-config p-0 login_boxs" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap.xs="0" fxLayout="row">

    <div fxFlex="40%" class="welcome-trinka">
        <div class="h-100 purple position-relative">
            <div class="loginoverlay"></div>
            <mat-card class=" d-inline-block">
                <mat-card-title class="d-block">Welcome to Trinka!</mat-card-title>
                <mat-card-content class="d-block">
                    <p class="d-block">As you are starting your journey with Trinka, please register or login to access a range of unique features.
                    </p>
                </mat-card-content>
            </mat-card>

            <mat-card class="card-footer login-footer shadow-none">
                <mat-card-content class="d-block">
                    <a href="https://www.trinka.ai/privacypolicy" target="_blank">Privacy Policy</a> | <a href="https://www.trinka.ai/termsOfServices" target="_blank">Terms of Services</a>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div class="sign-in-right" fxFlex="60%" fxFlexOrder="2">
        <div class="sign-in-inner">

            <form [formGroup]="signinform" (ngSubmit)="login()" *ngIf="enableSignInPanelCall(1)" class="signin ">
                <mat-dialog-content class="mat-typography">
                    <h1 class="login-title font-weight-bold">
                        Account Login
                    </h1>
                    <p>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Email address</mat-label>
                            <input type="email" matInput placeholder="Email address" formControlName="username" required>
                        </mat-form-field>

                    </p>
                    <p>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Password</mat-label>

                            <input [type]="hide ? 'password' : 'text'" matInput placeholder="Password" formControlName="password" required>
                            <mat-icon matSuffix (click)="hide = !hide"><img [src]="hide ? '../../../assets/images/not-visible.png': '../../../assets/images/visible.png'" class="right-tik" /></mat-icon>

                        </mat-form-field>
                    </p>
                    <p class="login_button_area">
                        <mat-label class="text-left"><span (click)="enablePanel(2)" class="forgot-pass text-link">Forgot
                                password ?</span></mat-label>
                        <button type="submit" mat-raised-button color="primary" [disabled]="!signinform.valid" class="float-right purple-btn ">Login</button>
                    </p>
                    <p class="pt-2 font-14 enago_text">
                        <span>Enago and AuthorONE users already have access to Trinka!</span>
                        <br />Simply login with your username and password.</p>
                    <p *ngIf="enableSignInPanelCall(1)" class="pt-2 text-dark">Don't have any account yet? <strong (click)="enablePanel(0)" class="text-link font-weight-normal"style="font-size: 14px;font-weight: normal;">Register now!</strong></p>

                    <mat-card class="card-footer login-footer shadow-none">
                        <mat-card-content class="d-block">
                            <a href="/home/privacypolicy" target="_blank">Privacy Policy</a> |
                            <a href="/home/termsOfServices" target="_blank">Terms of Services</a>
                        </mat-card-content>
                    </mat-card>
                </mat-dialog-content>
            </form>
            <form [formGroup]="signupform" (ngSubmit)="signup()" *ngIf="enableSignInPanelCall(0)" class="signup">
                <mat-dialog-content class="mat-typography">
                    <h1 class="login-title font-weight-bold">
                        Create Account
                    </h1>
                    <p>
                        <mat-form-field appearance="outline" class="half-width">
                            <mat-label>First Name</mat-label>
                            <input type="text" matInput placeholder="First Name" formControlName="first_name" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="pl-2 half-width">
                            <mat-label>Last Name</mat-label>
                            <input type="text" matInput placeholder="Last Name" formControlName="last_name" required>

                        </mat-form-field>
                    </p>

                    <p>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Email Address</mat-label>
                            <input type="text" matInput placeholder="Email address" formControlName="email_id" required>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Password</mat-label>

                            <input [type]="hide ? 'password' : 'text'" matInput placeholder="Password" formControlName="password" required>
                            <mat-icon matSuffix (click)="hide = !hide"><img [src]="hide ? '../../../assets/images/not-visible.png': '../../../assets/images/visible.png'" class="right-tik" /></mat-icon>
                        </mat-form-field>
                    </p>
                    <p class="text-right">
                        <button type="submit" mat-raised-button color="primary" [disabled]="!signupform.valid" class="purple-btn ml-1">Register</button>
                    </p>
                    <p class="text-right">
                        <mat-label class="already_acount">
                            <span *ngIf="enableSignInPanelCall(0)" class="display_in_mbole">
                                <span class="account_text">Already have an account with Trinka, AuthorONE, or Enago?</span>
                            <strong (click)="enablePanel(1)" class="text-link font-weight-normal"> Login
                                    Now!</strong>
                            </span>
                        </mat-label>
                    </p>

                    <mat-card class="card-footer login-footer shadow-none">
                        <mat-card-content class="d-block">
                            <a href="/home/privacypolicy" target="_blank">Privacy Policy</a> |
                            <a href="/home/termsOfServices" target="_blank">Terms of Services</a>
                        </mat-card-content>
                    </mat-card>
                </mat-dialog-content>

            </form>

            <form [formGroup]="ForgetPwdform" (ngSubmit)="forgetPassword()" *ngIf="enableSignInPanelCall(2)" class="signin ">
                <mat-dialog-content class="mat-typography">
                    <h1 class="font-24 font-weight-bold">
                        Forgot Password
                    </h1>
                    <p>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Email Address</mat-label>
                            <input type="text" matInput placeholder="email address" formControlName="email_id" required>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-label class="float-left"><span *ngIf="enableSignInPanelCall(2)"><strong
                                    (click)="enablePanel(1)" class="text-link font-weight-normal">Login
                                    Now</strong></span></mat-label>

                        <button type="submit" mat-raised-button color="primary" [disabled]="!ForgetPwdform.valid" class="float-right purple-btn ">Submit</button>
                    </p>

                    <span *ngIf="enableSignInPanelCall(0)">Don't have any account yet? <strong (click)="enablePanel(1)"
                            class="text-link">Sign Up!</strong></span>
                </mat-dialog-content>

                <mat-card class="card-footer login-footer shadow-none">
                    <mat-card-content class="d-block">
                        <a href="/home/privacypolicy" target="_blank">Privacy Policy</a> | <a href="/home/termsOfServices" target="_blank">Terms of Services</a>
                    </mat-card-content>
                </mat-card>

            </form>


        </div>
        <div class="close-btn">
            <div class="close-btn-inner">
                <mat-dialog-actions>
                    <button [mat-dialog-close]="'asdf()'" cdkFocusInitial class="close-btn-font">&times;</button>
                </mat-dialog-actions>
            </div>
        </div>
    </div>
</div>