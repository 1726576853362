import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { PaymentService } from 'src/app/_services/networkcalls/payment.service';
import { ManageProfileComponent } from '../manage-profile/manage-profile.component';

@Component({
  selector: 'app-saved-cards',
  templateUrl: './saved-cards.component.html',
  styleUrls: ['./saved-cards.component.scss']
})
export class SavedCardsComponent implements OnInit {

  subscriptionDetails: any;
  savedCardList: any;
  isConfirmationModal: boolean = false;
  savedCard: any;
  savedCardIndex: number;
  userId: string;


  constructor(private dialogRef: MatDialogRef<ManageProfileComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    public networkCallsPayments: PaymentService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public amplitudeService: AmplitudeService,
    private dialog: MatDialog) {
      this.subscriptionDetails = data['userSubscriptionDetails'];
      this.isConfirmationModal = data['isConfirmationModal'];
      this.savedCardList = data['savedCardList'];
      this.userId = data['userId'];
    }


  ngOnInit() {
    if (!this.isConfirmationModal) {
      let subscriptionType: string = 'Basic';
      if (this.subscriptionDetails != undefined) {
        subscriptionType = this.subscriptionDetails.is_expired ? 'Basic' : 'Premium';
      }
      this.amplitudeService.logAmplitude('Pay_savedcards_CTA', {
        'Subscription Type': subscriptionType,
        'No. of cards': this.savedCardList.length
      });
    }
  }


  formatExpiryDate(savedCard) {
    let expiryDate = savedCard.expiry_month < 10 ? "0" + savedCard.expiry_month : savedCard.expiry_month;
    expiryDate += "/" + savedCard.expiry_year.toString().slice(2);
    return expiryDate;
  }


  delete() {
    this.spinner.show();
    this.networkCallsPayments.deleteSavedCard(this.userId, this.savedCard['id']).subscribe(result => {
      this.spinner.hide();
      this.savedCardList.splice(this.savedCardIndex, 1);
      this.toastr.success("Saved card deleted!", "Success");
    }, error => {
      this.spinner.hide();
      this.toastr.error("Couldn't delete the saved card!", "Error");
    });
  }


  isSavedCardExpired(savedCard) {
    let expiryDate = this.formatExpiryDate(savedCard);
    let expiryMonth = expiryDate.split("/")[0];
    let expiryYear = 20 + expiryDate.split("/")[1];
    let today : any = new Date();
    return (expiryYear > today.getUTCFullYear()) ? false :
    (expiryYear == today.getUTCFullYear() && expiryMonth  >= today.getUTCMonth()+1 ? false : true);
  }
  
  
  takeConfirmation(savedCard, savedCardIndex) {
    this.amplitudeService.logAmplitude('Pay_delete_card_CTA', {});
    this.savedCard = savedCard;
    this.savedCardIndex = savedCardIndex;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      isConfirmationModal: true
    };
    const dialogRefResp = this.dialog.open(SavedCardsComponent, dialogConfig);
    dialogRefResp.afterClosed().subscribe(data => {
      if (data) {
        this.amplitudeService.logAmplitude('Pay_delete_card_confirm', {});
        this.delete();
      } else {
        this.amplitudeService.logAmplitude('Pay_delete_card_cancel', {});
      }
    });
  }


  confirmDelete(result: boolean) {
    this.dialogRef.close(result);
  }

}
