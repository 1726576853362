import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plagiarism-report-generate',
  templateUrl: './plagiarism-report-generate.component.html',
  styleUrls: ['./plagiarism-report-generate.component.scss']
})
export class PlagiarismReportGenerateComponent implements OnInit {

  fileDetails : any;
  requestedPlagiarismType: any;
  userId : any;
  userDecodedId:any;
  totalCredits: any;
  requiredCredits: any;
  userData:any;
  creditsDetails: any;
  pages_used: any;
  pages_available: any;

  PLAG_CHECK_FILE_UPLOAD_WROD_COUNT_LIMIT: number = 125000;
  CREDIT_CALCULATION_WORD_COUNT_BRACKET: number = 25000;
  STANDARD_CREDIT: number = 12;
  ADVANCE_CREDIT: number = 18;

  constructor( @Inject(MAT_DIALOG_DATA) data,
  private dialogRef: MatDialogRef<PlagiarismReportGenerateComponent>,
    private sharedService : SharedService,
    public networkCalls: DashboardService,
    private spinner: NgxSpinnerService,
    public amplitudeService: AmplitudeService,) {
    
      const localMeta = this.sharedService.getLocalStorageMeta();
      this.userId = btoa(localMeta.sub)
      this.fileDetails = data.file;
      this.requestedPlagiarismType = data.requested_plagiarism_type;
      this.userDecodedId = data.user_decoded_id;
      this.creditsDetails = data.creditsDetails;
      this.totalCredits = this.creditsDetails.credits + this.creditsDetails.purchased_credits;
      this.requiredCredits = this.requestedPlagiarismType == 2 ? this.getAdvanceCreditCount(data.wordCount) : this.getStandardPlagCheckWordCount(data.wordCount);
      this.pages_used = data.pages_used;
      this.pages_available = data.creditsDetails.plagiarism_free_page_count_scan
   }

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(v=>{
      this.dialogRef.close(1);
  });
  }

  generatePlagiarismReport(){
    this.spinner.show();
    if(this.fileDetails.plagiarism_check_type != this.requestedPlagiarismType || (this.fileDetails.file_status =="FAILED" && this.fileDetails.plagiarism_check_type == this.requestedPlagiarismType)){
      this.networkCalls.reUploadOnholdPlagiarismFile(this.userId,this.fileDetails.file_id,this.requestedPlagiarismType, this.userDecodedId).subscribe(res => {
          this.spinner.hide();
          this.dialogRef.close(res);
        }, error => {
          this.sharedService.errorHandller(error);
        });
     }
     else{
         this.dialogRef.close(1);
     }
  }

  close(){
       this.dialogRef.close(1);
       event.stopPropagation();
  }


  addCredits() {
    this.amplitudeService.logAmplitude('Plag_Buy_Credit', {
      'Report type': this.requestedPlagiarismType,
      'free_credits': this.creditsDetails.credits,
      'Credits_purchased': this.creditsDetails.purchased_credits
    });
    this.dialogRef.close();
    let redirct_url  = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);
    
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url+'/user/credit/'+this.userData,"_self");
  }

  getStandardPlagCheckWordCount(noOfWords: number){
    if (noOfWords){ 
      return Math.ceil(noOfWords / this.CREDIT_CALCULATION_WORD_COUNT_BRACKET) * this.STANDARD_CREDIT;
    }
    else {
      return 0;
    }
  }

  getAdvanceCreditCount(noOfWords: number){
    if (noOfWords){ 
      return Math.ceil(noOfWords / this.CREDIT_CALCULATION_WORD_COUNT_BRACKET) * this.ADVANCE_CREDIT;
    }
    else {
      return 0;
    }
  }
}
