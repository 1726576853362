<div class="publicationSubSection">
<mat-card class="figures_tables_sections" *ngIf="!(figuresCheck?.status == 3 || figuresCheck?.status == 4)">

  <div class="figures_tables_cards" *ngIf="allFigures.length==0">
    <div class="figures_tables_title">
      <p>
        <span>Check 1</span> Figures 
        <i class="figures_info_section" alt="Shows the figure captions in the manuscript and whether the figures were cited in the correct order.">
          <img src="../../../../assets/images/Icon_Info.svg" alt="">
      </i>
      </p>
    </div>
    <div class="figures_tables_body">
      <p class="figures_tables_title with_not_found_case">No figure caption(s) found</p>
      <p class="no_found_figure">
        Figures help in presenting information in a condensed manner and help reduce word count.
        Every figure used in the paper must be cited in the paper body.
      </p>
    </div>
  </div>


  <div class="figures_tables_cards" *ngIf="allFigures.length>0">
    <div class="figures_tables_title">
      <p>
        <span>Check 1</span> Figures 
        <i class="figures_info_section" alt="Shows the figure captions in the manuscript and whether the figures were cited in the correct order.">
          <img src="../../../../assets/images/Icon_Info.svg" alt="">
      </i>
      </p>
    </div>
        
      <div class="figures_tables_body">
        <p class="figures_tables_title"><span>{{figuresWithCaptionCount}}</span> figure caption(s) found</p>
        <div class="figures_tables_data figureMissingCaption" *ngIf="this.figuresNumberWithoutCaption.length">
          Missing caption(s): Fig(s). {{figuresNumberWithoutCaption.join(', ')}}
          <ul style="display: none;">
            <li *ngFor="let item of figuresNumberWithoutCaption">Figure {{item}}</li>
          </ul>
        </div>
        <div class="figures_tables_data" *ngIf="this.figuresNumberWithoutCitation.length">
          Missing citation(s): Fig(s). {{figuresNumberWithoutCitation.join(', ')}}
        </div>
      </div>
  
    <div class="viewReferences_button" (click)="viewdata('Figure')"
      *ngIf="allFigures.length>0">
      View captions <span><img style="margin-left: 4px;" src="../../../../assets/images/Icon_eye.svg" alt=""></span>
    </div>

    <div class="figures_tables_cards citationsAreInOrder_section" *ngIf="allFigures.length>0">
      <div class="figures_tables_body" *ngIf="!allCitationMissingFigure" style="border-top: 1px solid #d7d2d2; padding-top: 15px; margin-top: 15px;" >
        <p class="figures_tables_title " *ngIf="!figuresCheck?.unordered_citations?.is_present"> Citation order</p>
        <p class="figures_tables_title with_not_found_case" *ngIf="figuresCheck?.unordered_citations?.is_present">
          Citation order</p>
        <div class="figures_tables_data" style="margin: 0;">
          {{figuresCheck?.unordered_citations?.text}}
        </div>
      </div>
    </div>
  </div>
</mat-card>


<mat-card class="figures_tables_sections" *ngIf="!(tableCheck?.status == 3 || tableCheck?.status == 4)">
  <div class="figures_tables_cards" *ngIf="allTables.length == 0">
    <div class="figures_tables_title">
      <p>
        <span>Check 2</span> Tables 
        <i class="figures_info_section" alt="Shows the table captions in the manuscript and whether the tables were cited in the correct order.">
          <img src="../../../../assets/images/Icon_Info.svg" alt="">
        </i>
      </p>
    </div>
    <div class="figures_tables_body">
      <p class="figures_tables_title with_not_found_case">No table caption(s) found</p>
      <p style="font-size: 13px; color: rgba(77, 77, 77, 0.8);margin-bottom: 0;line-height: 17px;">
        Tables help in presenting information in a condensed manner and help reduce word count.
        Every table used in the paper must be cited in the paper body.
      </p>
    </div>
  </div>

  <div class="figures_tables_cards" *ngIf="allTables.length>0">
    <div class="figures_tables_title">
      <p><span>Check 2</span> Tables
        <i class="figures_info_section" alt="Shows the table captions in the manuscript and whether the tables were cited in the correct order.">
          <img src="../../../../assets/images/Icon_Info.svg" alt="">
        </i>
      </p>
    </div>
    <div class="figures_tables_body">
      <p class="figures_tables_title"><span>{{tablesWithCaptionCount}}</span> table caption(s) found</p>
      <div class="figures_tables_data" *ngIf="this.tablesNumberWithoutCaption.length">
        Missing caption(s): Table(s) {{tablesNumberWithoutCaption.join(', ')}}
      </div>
      <div class="figures_tables_data" *ngIf="this.tablesNumberWithoutCitation.length">
        Missing citation(s): Table(s) {{tablesNumberWithoutCitation.join(', ')}}
      </div>

    </div>
  </div>

  <div class="viewReferences_button" (click)="viewdata('Table')"
    *ngIf="allTables.length>0">
    View captions <span><img src="../../../../assets/images/Icon_eye.svg" alt=""></span>
  </div>

  <div class="figures_tables_cards" *ngIf="allTables.length>0">
    <div class="figures_tables_body" style="border-top: 1px solid #d7d2d2; padding-top: 15px; margin-top: 15px;"  *ngIf="!allCitationMissingTable">
      <p class="figures_tables_title " *ngIf="!tableCheck?.unordered_citations?.is_present"> Citation order</p>
      <p class="figures_tables_title with_not_found_case" *ngIf="tableCheck?.unordered_citations?.is_present"> Citation order</p>
      <div class="figures_tables_data" style="margin: 0;">
        {{tableCheck?.unordered_citations?.text}}
      </div>
    </div>
  </div>

</mat-card>
</div>