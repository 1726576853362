<div class="container popup tables_figures_popup" *ngIf="section == 'Figure'">
    <div class="popup_heading">
        <h2>{{section}}s</h2>
        <div class="figure_slider">
            <span class="carousel_control_prev_icon align-self-start" (click)="getPrevisous()">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
            </span>
            <span class="slider_counts"> Figure caption {{variable + 1}} of {{individualData.length}} </span>
            <span class="carousel_control_next_icon align-self-start" (click)="getNext()">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </span>
        </div>
        <button class="cancelbuttons" mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>
    <div class="reference_check_popup_body">
        <div class="figures_text_contents">
            <div class="img_caption_section">
                <div *ngIf="individualData[variable]?.is_present">
                    <h3>Caption:</h3>
                    <p>{{individualData[variable].text}}</p>
                </div>
                <div *ngIf="!individualData[variable]?.is_present">
                    <h3>Caption:</h3>
                    <span style="color:red">Not found </span> 
                </div>
                <br>
                <div *ngIf="individualData[variable]?.citation_list.length" class="popup_citations_section">
                    <h3>Citation(s):</h3>
                    <ul>
                        <li *ngFor="let citation of individualData[variable]?.citation_list"><a>{{citation}}</a> </li>
                    </ul>
                </div>
                <div *ngIf="!individualData[variable]?.citation_list.length">
                    <h3>Citation(s):</h3>
                    <span style="color:red"> Not found </span> 
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container popup tables_figures_popup" *ngIf="section == 'Table'">
    <div class="popup_heading">
        <h2>{{section}}s</h2>
        <div class="figure_slider">
            <span class="carousel_control_prev_icon align-self-start" (click)="getPrevisous()">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
            </span>
            <span class="slider_counts"> Table caption  {{variable + 1}} of {{individualData.length}} </span>
            <span class="carousel_control_next_icon align-self-start" (click)="getNext()">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </span>
        </div>
        <button class="cancelbuttons" mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>
    <div class="reference_check_popup_body">
            <div class="img_caption_section">
                <div *ngIf="individualData[variable]?.is_present">
                    <h3>Caption:</h3>
                    <p>{{individualData[variable].text}}</p>
                </div>
                <div *ngIf="!individualData[variable]?.is_present">
                    <h3>Caption:</h3>
                    <span style="color:red">Not found </span> </div>
                    <br>
                <div class="popup_citations_section" *ngIf="individualData[variable]?.citation_list.length">
                    <h3>Citation(s):</h3>
                    <ul>
                        <li *ngFor="let citation of individualData[variable]?.citation_list"><a>{{citation}}</a></li>
                    </ul>
                </div>
                <div *ngIf="!individualData[variable]?.citation_list.length">
                    <h3>Citation(s):</h3>
                    <span style="color:red">Not found </span> 
                </div>
            </div>
    </div>
</div>