import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';
import { CreditDetailsComponent } from '../../dashboard/credit-details/credit-details.component';

@Component({
  selector: 'app-plag-check-tool-page',
  templateUrl: './plag-check-tool-page.component.html',
  styleUrls: ['./plag-check-tool-page.component.scss']
})
export class PlagCheckToolPageComponent implements OnInit {

  creditsDetails: any = {};
  userData: any;
  userId: any;
  emailId: string;

  constructor(public networkCalls: DashboardService,
    public sharedService: SharedService,
    public amplitudeService: AmplitudeService,
    public dialog: MatDialog,) { }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.emailId = localMeta.sub;
    let data = {
      user_id: this.userId,
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.getCreditsDetails(true);
  }

  getCreditsDetails(isOnInit: boolean = false) {
    this.networkCalls.getStoreUserCreditDetails().subscribe(resp => {
      if(Object.keys(resp).length == 0)
      {
        this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
          if (response["status"]) {
            this.creditsDetails = response.data;
            this.networkCalls.storeUserCreditDetails(this.creditsDetails);
          }
        }, error => {
          this.sharedService.errorHandller(error);
        });
      }
      else{
        this.creditsDetails = resp;
      }
      this.amplitudeService.logAmplitude('Plag_toolpage_view', {
        'userID': this.userId,
        'free_credit_balance': this.creditsDetails.credits,
        'premium_credit_balance': this.creditsDetails.purchased_credits
      });
    });
  }

  addCredits() {
    this.amplitudeService.logAmplitude('Add_credits_CTA', {
      'Subscription Type': this.creditsDetails.user_type,
      'credit_balance_free': this.creditsDetails.credits,
      'credit_balance_purchased': this.creditsDetails.purchased_credits
    });
    let redirct_url = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url + '/user/credit/' + this.userData, "_self");
  }


  credits(){
    if(this.creditsDetails != null){
      this.amplitudeService.logAmplitude('plag_check_credit_view',{
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits,
        'Subscription Plan': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'User ID': this.userId
         });
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData : this.creditsDetails,
      };
      this.dialog.open(CreditDetailsComponent, dialogConfig)
    }
  }
}
