import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-language-code-preference-modal',
  templateUrl: './language-code-preference-modal.component.html',
  styleUrls: ['./language-code-preference-modal.component.scss']
})
export class LanguageCodePreferenceModalComponent implements OnInit {

  globalLanguage = 'US';
  userId: string;
  userDetails = {
    lang_code: '',
    language: 'UK'
  };
  languageOptions = [{
    'value': 'English (EN)',
    'code': 'en',
    'isSelected': false
  },
  {
    'value': 'Spanish (ES)',
    'code': 'es',
    'isSelected': false
  },
  // {
  //   'value': 'German (DE)',
  //   'code': 'de',
  //   'isSelected': false
  // }
  ];
  selectedLanguageCode: string[] = [''];

  constructor(
    private sharedService: SharedService,
    public networkCalls: DashboardService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public amplitudeService: AmplitudeService
  ) { }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    this.getUserProfileData()
  }

  getUserProfileData() {
    this.spinner.show();
    this.networkCalls.getUserDetails(this.userId).subscribe(data => {
      this.userDetails = data.data;
      this.selectedLanguageCode = this.userDetails['lang_code'].split(",");
      this.selectLanguageOptions();
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  languagePrefe(code: string) {
    const index = this.selectedLanguageCode.findIndex((o) => o === code);
    if (index !== -1) {
      this.selectedLanguageCode.splice(index, 1);
    } else {
      this.selectedLanguageCode.push(code);
    }
    this.userDetails['lang_code'] = this.selectedLanguageCode.toString();
  }

  closedLanguageModal(){
    // alert("Hello");
    $(".cdk-overlay-backdrop-showing").click();
  }

  setLangCode() {
    if (this.selectedLanguageCode.length === 0) {
      this.selectedLanguageCode = ['en'];
      this.userDetails['lang_code'] = this.selectedLanguageCode.toString();
      this.userDetails['language'] = 'UK';
      this.selectLanguageOptions();
    }
    let languageString = [''];
    languageString = this.languageOptions.filter((x) => x.isSelected).map(obj => obj.value);

    let eventObj = {};
    this.languageOptions.forEach((x: any) => {
      eventObj[x.value] = x.isSelected;
    });
    this.amplitudeService.logAmplitude('Language_setting_set', {
      ...eventObj,
      'subLanguage': languageString.toString().includes('English') ? this.userDetails['language'] : undefined
    });
    this.spinner.show();
    this.networkCalls.putUserDetails(this.userId, this.userDetails).subscribe(data => {
      this.userDetails = data.data
      this.spinner.hide();
      // this.amplitudeService.logAmplitude('en_preference_set', {
      //   'userID':this.userId,
      //   'locale':this.userDetails['lang_code']
      // });
      this.closedLanguageModal();
      this.toastr.success('Language settings applied successfully');
    }, error => {
      this.sharedService.errorHandller(error);
      this.closedLanguageModal();
    });
  }

  selectLanguageOptions() {
    this.languageOptions.forEach((x) => {
      x.isSelected = this.selectedLanguageCode.indexOf(x.code) > -1;
    });
  }

}
 