// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const api_base_url = "dev.channels.trinka.ai"
export const api_auth_url = "dev.auth.trinka.ai"
export const api_autoedit_url = "dev.autoedit.trinka.ai"
export const api_asset_url = "dev.asset.trinka.ai"
export const api_payment_url="dev.payments-api.trinka.ai"
export const fe_payment_url="dev.payments.trinka.ai"
export const link_refer = "dev.cloud.trinka.ai/signup"
export const credit_service_url = "188.214.128.75:9022"
export const per_credit_ref = 20
export const base_credit = 2
export const api_refcheck_url = "dev.refcheck.trinka.ai"
export const plagiarism_url = "dev.plagcheck-api.trinka.ai"
export const purge_service_url = "93.115.28.93:5022"
export const journal_finder_service_url = "dev.api-journal-finder.trinka.ai"
const paraphraser_address = "dev.paraphraser.trinka.ai"
/**Local env. url's
export const api_auth_url = "0.0.0.0:7000"
export const api_base_url = "0.0.0.0:9000"
export const api_autoedit_url = "0.0.0.0:9004"
export const purge_service_url = "0.0.0.0:5022"*/

export const environment = {
  production: false,
  paraphraser_address: "http://"+paraphraser_address,
  server_address: "http://"+api_base_url,
  for_socket: "ws://"+api_base_url,
  for_new_socket: "ws://gecapi.dev.trinka.ai",
  yjs_server: "wss://yjs.uat.trinka.ai",
  sso_login_api: "http://"+api_base_url+"/trinka/api/v1/auth",
  sso_sign_up_api: "http://"+api_base_url+"/trinka/api/v1/register",
  sso_social_login_api: "http://" + api_base_url + "/trinka/api/v1/social/auth",
  partial_sign_up_update: "http://"+api_base_url+"/trinka/api/v1/registration/update",
  auth_api: "http://"+api_auth_url,
  autoedit_api: "http://"+api_autoedit_url,
  asset_api : "http://"+api_asset_url,
  refer_link:"http://"+link_refer,
  chrome_extension_id: "bbcmnbnmngpeofmpcdlcfalbniefegbp",
  firefox_extension_id: "d42a831e-4c55-4c5d-9774-068157088ea7",
  social_redirectURL: "http://dev.cloud.trinka.ai/signin",
  word_plugin_redirectURL: "http://dev.cloud.trinka.ai/word-plugin/signin",
  mac_plugin_redirectURL: "http://dev.cloud.trinka.ai/mac-plugin/signin",
  // social_redirectURL: "http://localhost:4200"
  payment_api: "http://"+api_payment_url,
  payment_fe: "http://"+fe_payment_url,
  credit_service: "http://" + credit_service_url,
  plagiarism_api: "http://"+plagiarism_url,
  purge_service:  "http://" + purge_service_url,
  trialMode : false,
  accessToken:"",
  sitekey: '6LeMLVIaAAAAAJyP2i1De50fl91j_wNIVPMaFtBJ',
  amplitude_api_key: "d6d0919d33fe750608c491612d7bcd97",
  per_credit_ref: per_credit_ref,
  base_credit: base_credit,
  refcheck_api: "http://"+api_refcheck_url,
  enago_social_redirect_url: "http://accountsuat.trinka.ai/ExternalProvider/",
  journal_finder_service:  "http://" + journal_finder_service_url,
  one_drive_client_id: "bbfb7945-ab71-4526-b6f5-18037c7ae0a8",
  cloud_redirect_url: "http://dev.cloud.trinka.ai/",
  google_client_id: '134099162937-b4auhchdq888dok9v2gddorsatbtks9b.apps.googleusercontent.com',
  google_apiKey : 'AIzaSyCyaBf7tVzN6Je4Vhf7X4BWKRRDDmhVyks',
  google_appId: '134099162937',
  showGoogleCaptcha:false,
  citationSampleDocID: "643a5d2f-78ca-49c2-83ef-607e4ef5a74f",
  plag_check_sample_report_id: "46fdbfb7-4d7a-4530-9366-8e9a5cb48621",
  mac_redirect_url: "http://d1hcfkbvsb2n7r.cloudfront.net/redirectPage.html",
  stripe_public_key : "pk_test_51KJYRDBOAkLRyixTis3eZvFR3BSWa5l2c7XzHDR1eom4vXOecGit20YJqjHjhT19bZbwsq0TjVgpuJDKOOd8FD9d00JxmYH8fT",
  feedback_api: "https://uat-common-api.trinka.ai/api/feedback/submit/"
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
