import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ShowMyDriveFilesModalComponent } from 'src/app/components/dashboard/show-my-drive-files-modal/show-my-drive-files-modal.component';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { GoogleDrivePickerService } from 'src/app/_services/google-drive-picker.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { VerifiedUserService } from 'src/app/_services/networkcalls/verified-user.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { environment } from "src/environments/environment";
import { NoopScrollStrategy } from "@angular/cdk/overlay";

import { DropboxChooseOptions, Dropbox } from 'src/app/_interfaces/dashboard/dropbox-choose-options';
import { CreditDetailsComponent } from 'src/app/components/dashboard/credit-details/credit-details.component';
import { AutoEdit } from 'src/app/_enums/auto-edit.enum';
import { PlagiarismReportGenerateComponent } from 'src/app/components/dashboard/plagiarism-check/plagiarism-report-generate/plagiarism-report-generate.component';
import { PlagCheckOnboardingModalComponent } from 'src/app/components/plagiarism-check/plag-check-onboarding-modal/plag-check-onboarding-modal.component';
import { PlagCheckSampleReportPopupComponent } from 'src/app/components/plagiarism-check/plag-check-sample-report-popup/plag-check-sample-report-popup.component';
import { PushNotificationService } from 'src/app/_services/push-notification.service';
import { CommonDeleteConfirmationModalComponent } from 'src/app/components/common-delete-confirmation-modal/common-delete-confirmation-modal.component';
import { DialogDataService } from 'src/app/_services/common-delete-confirmation-service';
import { MessageModalComponent } from 'src/app/components/dashboard/message-modal/message-modal.component';
import { ReferralRewardModalComponent } from 'src/app/components/dashboard/referral-reward-modal/referral-reward-modal.component';

declare function wisepop(): any;


declare const OneDrive: any;
declare var Dropbox: Dropbox;
@Component({
  selector: 'app-plagiarism-checker',
  templateUrl: './plagiarism-checker.component.html',
  styleUrls: ['./plagiarism-checker.component.scss']
})
export class PlagiarismCheckerComponent implements OnInit {
  constructor(
    public sharedService: SharedService,
    public networkCalls: DashboardService,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public amplitudeService: AmplitudeService,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    private googleDrivePickerService: GoogleDrivePickerService,
    private appRef: ApplicationRef,
    private onlineEditorService: OnlineEditorService,
    private verifiedUserService: VerifiedUserService,
    private router: Router,
    private _notificationService: PushNotificationService,
    private ngZone: NgZone,
    private dialogDataService: DialogDataService
  ) { }


  prevPlagFileList: any = [];
  freeStatus: boolean = false;
  plagiarismFilesList: any = [];
  plagiarism_check_list: any = [];
  show_delay = false;
  referenceQuality = null;
  files_submitted = [];
  selectedFiles: FileList;
  fileName: string;
  referenceList: any;
  noOfRef = -1;
  per_credit_ref: number = 0;
  base_credit: number = 0;
  credit_used: number;
  totalCredit: number = 0;

  refQualityFileList = [];
  fileSelected: boolean = false;
  fileUploaded: boolean = false;
  userData: any;
  user_decoded_id: any;
  dataSource: Object;
  report_data: any = [];
  free_score_reset_date: any;
  remainig_free_score: number;
  total_free_score: number;

  range: number;
  paymentDetails: any;
  selectedProfile: any = {};
  profileName: any = "";
  loaderStatus: boolean = true;
  cloudDriveFileDetails = { "fileId": "", "fileName": "", "fileUrl": "", "source": "", "oauthToken": "", "userId": "" };
  uploadSource = [
    {
      "GOOGLE_DRIVE": false,
      "TRINKA_DRIVE": false,
      "ONE_DRIVE": false,
      "DROP_BOX": false,
      "LOCAL_SYSTEM": false
    }
  ];
  selectedFileName: any;
  selectedCreditReq: any;
  selectedFileId: any;
  selectedTotalScore: any;
  filterModel = "";
  selectedPerc: any;

  creditsDetails: any = {};
  userId: any;
  emailId: string;
  element: HTMLElement;
  inProgressFileList: any = [];
  notificationPermisson: string = 'granted';
  step: number = 1;


  cardLimit = 0;
  cardIncrease = 5;
  currentPage = 1;
  allPlagiarismFilesList: any = [];
  pageCount;
  loadmorebtn: boolean = false;
  sample_file_id = environment.plag_check_sample_report_id;
  sampleCard = {
    "key_score": true,
    "key_standard": true,
    "key_advance": true,
    "credit": 9,
    "email_id": "",
    "file_id": this.sample_file_id,
    "file_name": "Trinka Plagiarism Checker-Sample.docx",
    "file_status": "SUCCESS",
    "percentage": 96,
    "plagiarism_check_type": "2",
    "source": "sample_card",
    "open": false,
    "submission_id": "47be6e58-9b4c-4c44-9eac-18c866b98e09",
    "turnitine_submission_id": "47be6e58-9b4c-4c44-9eac-18c866b98e09",
    "final_report": "{\"overall_match_percentage\": 94, \"internet_match_percentage\": 94, \"publication_match_percentage\": 27, \"submitted_works_match_percentage\": null, \"status\": \"COMPLETE\", \"time_generated\": \"2023-06-27T12:35:20.165Z\", \"time_requested\": \"2023-06-27T12:34:45.655Z\", \"submission_id\": \"28ea17c2-6992-47fd-9cde-c34fdde8fdc4\", \"top_matches\": [{\"percentage\": 94.08851, \"source_type\": \"INTERNET\", \"matched_word_count_total\": 14627, \"name\": \"www.trinka.ai\"}, {\"percentage\": 94.05635, \"source_type\": \"INTERNET\", \"matched_word_count_total\": 14622, \"name\": \"www.trinka.ai\"}, {\"percentage\": 93.94057, \"source_type\": \"INTERNET\", \"matched_word_count_total\": 14604, \"name\": \"www.trinka.ai\"}, {\"percentage\": 11.147562, \"source_type\": \"INTERNET\", \"matched_word_count_total\": 1733, \"name\": \"www.njcmindia.org\"}, {\"percentage\": 10.787341, \"source_type\": \"INTERNET\", \"matched_word_count_total\": 1677, \"name\": \"healthdocbox.com\"}], \"top_source_largest_matched_word_count\": 1771}",
    "turnitine_final_report": "{\"overall_match_percentage\": 94, \"internet_match_percentage\": 94, \"publication_match_percentage\": null, \"submitted_works_match_percentage\": null, \"status\": \"COMPLETE\", \"time_generated\": \"2023-06-27T12:37:50.396Z\", \"time_requested\": \"2023-06-27T12:37:23.084Z\", \"submission_id\": \"19a03f3f-45e6-4881-8a77-aeff32a96ce5\", \"top_matches\": [{\"percentage\": 94.08851, \"source_type\": \"INTERNET\", \"matched_word_count_total\": 14627, \"name\": \"www.trinka.ai\"}, {\"percentage\": 94.05635, \"source_type\": \"INTERNET\", \"matched_word_count_total\": 14622, \"name\": \"www.trinka.ai\"}, {\"percentage\": 93.94057, \"source_type\": \"INTERNET\", \"matched_word_count_total\": 14604, \"name\": \"www.trinka.ai\"}, {\"percentage\": 11.147562, \"source_type\": \"INTERNET\", \"matched_word_count_total\": 1733, \"name\": \"www.njcmindia.org\"}, {\"percentage\": 10.787341, \"source_type\": \"INTERNET\", \"matched_word_count_total\": 1677, \"name\": \"healthdocbox.com\"}], \"top_source_largest_matched_word_count\": 1771}",
    "ithenticate_report_pdf_url": "aHR0cHM6Ly9zMy5hbWF6b25hd3MuY29tL3RyaW5rYS1jbG91ZC1wbGFnLWNoZWNrLXVhdC8zYzVjYTJlYy1iOTA4LTQ2MzMtOGViNy01YmE4Zjg2OWJlMTcvYTliYWJiNDUtODE5OC00MGVhLTg3OGYtNDE3ZTM5NDg5ZGZlL2RvY3VtZW50L2lUaGVudGljYXRlL1RyaW5rYSBQbGFnaWFyaXNtIFNhbXBsZSBSZXBvcnQtQWR2YW5jZWQucGRm",
    "turnitine_report_pdf_url": "aHR0cHM6Ly9zMy5hbWF6b25hd3MuY29tL3RyaW5rYS1jbG91ZC1wbGFnLWNoZWNrLXVhdC8zYzVjYTJlYy1iOTA4LTQ2MzMtOGViNy01YmE4Zjg2OWJlMTcvODdhODBmZDAtYTI3Zi00NjU5LWIyZDctZDM5YTZiZmQ2NWI5L2RvY3VtZW50L3R1cm5pdGluL1RyaW5rYSBQbGFnaWFyaXNtIFNhbXBsZSBSZXBvcnQtU3RhbmRhcmQgLnBkZg==",
    "uploaded_at": "2023-01-16T10:42:52.772605Z",
    "turnitine_uploaded_at": null,
    "created_at": "2023-01-16T10:40:46.747815Z",
    "user_id": "61f471b5-95b6-4219-9c18-d7ec3995435d",
    "word_count": 25000
  }
  plansCTAToShow: any = {
    score: 'SUCCESS',
    standard: 'SUCCESS',
    advance: 'SUCCESS'
  };

  plagoptionAndType: any = [
    {
      option: 'score',
      type: 1
    },
    {
      option: 'advance',
      type: 2
    },
    {
      option: 'standard',
      type: 3
    }
  ];
  fileUploadProgress: number = 0;
  delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  uploadedFileWordCount: number = 0;
  isFileFromEditor: boolean = false;
  editorFileID: string = '';
  referralReward: number = 0;
  country:string = "";

  PLAG_CHECK_FILE_UPLOAD_WROD_COUNT_LIMIT: number = 125000;
  CREDIT_CALCULATION_WORD_COUNT_BRACKET: number = 25000;
  STANDARD_CREDIT: number = 12;
  ADVANCE_CREDIT: number = 18;

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.emailId = localMeta.sub;
    let data = {
      user_id: this.userId,
    };
    this.amplitudeService.setUserId(this.userId);
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.loadCreditDetails();
    this.getUserPaymentSubscription();
    this.getSelectedProfileData();
    this.getPlagiarismFileList();
    let plgcheckOnboarded = localStorage.getItem(this.userId + "_plgchk");
    if (plgcheckOnboarded != 'true') {
      setTimeout(() => {
        this.dialog.open(PlagCheckOnboardingModalComponent, {
        });
      }, 2000)
      localStorage.setItem(this.userId + "_plgchk", 'true');
    }
    this.country = localStorage.getItem("country-trinka");

    window.addEventListener("dragenter", function (e) {
      let pop_up = document.getElementById('onboard_plag')
      if(!pop_up){
        var dropzone = document.querySelector("#dropzone") as HTMLElement
        dropzone.style.visibility = "";
        dropzone.style.opacity = '1';
        dropzone.style.fontSize = "48px";
      }


    });

    this.fileSelected = false;
    this.subjectiveBehaviorSharedService.getEditorFileId().subscribe(file_id => {
      if(file_id != "" && file_id != null){
        this.uploadSource["TRINKA_DRIVE"] = true;
        this.cloudDriveFileDetails.fileId = file_id;
        this.editorFileID = file_id;
        this.fileSelected = true;
      }
    });
    this.subjectiveBehaviorSharedService.getEditorFileName().subscribe(async (file_name) => {
      if(file_name!= "" && file_name!=null)
      {
        // this.show_delay = true;
        // await this.showUploadProgressBar();
        this.cloudDriveFileDetails.fileName = file_name;
        this.fileName = file_name;
        if(file_name){
          if(!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx"))){
            this.fileName +=".docx"
          }
        }

        this.cloudDriveFileDetails.source = "TRINKA_DRIVE";
        this.user_decoded_id = this.creditsDetails['user_id'];
        this.cloudDriveFileDetails.userId = this.userId;
        this.isFileFromEditor = true;
        this.checkWordCountOfUploadedFile();

        // this.showUploadProgressBar();
        this.fileSelected = true;
        // setTimeout(() => { this.show_delay = false }, 3000);
      }
    });

    this.fetchReferralRewards();
  }

  loadCreditDetails() {
    this.networkCalls.getStoreUserCreditDetails().subscribe(resp => {

      if (Object.keys(resp).length == 0) {
        this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
          if (response["status"]) {
            this.creditsDetails = response.data;
            this.networkCalls.storeUserCreditDetails(this.creditsDetails);
            this.amplitudeService.logAmplitude('Plag_toolpage_view', {
              'userID': this.userId,
              'free_credit_balance': this.creditsDetails.credits,
              'premium_credit_balance': this.creditsDetails.purchased_credits
            });
          }

        }, error => {
          this.sharedService.errorHandller(error);
        });
      }
      else {
        this.creditsDetails = resp;
      }
    });
  }

  getUserPaymentSubscription() {
    this.networkCalls.getStoreUserPlanDetails().subscribe(resp => {
      if (Object.keys(resp).length == 0) {
        this.networkCalls.getUserPaymentSubscription(this.userId).subscribe(response => {
          this.paymentDetails = response['data'];
          this.networkCalls.storeUserPlanDetails(this.paymentDetails);
          this.amplitudeService.setUserProperties('subscription_type', (this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase()));
        });
      }
      else {
        this.paymentDetails = resp;
        this.amplitudeService.setUserProperties('subscription_type', (this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase()));
      }
    });
  }

  disableAllPlansCTA(card) {
    this.plansCTAToShow.score = 'SUCCESS';
    this.plansCTAToShow.standard = 'SUCCESS';
    this.plansCTAToShow.advance = 'SUCCESS';
    card.score = 'SUCCESS';
    card.standard = 'SUCCESS';
    card.advance = 'SUCCESS';
  }
  parseJson(str: any): any {
    this.report_data = JSON.parse(str);
  }
  strToJson(str: any): any {
    return JSON.parse(str);
  }
  trimDeci(percent) {
    return (Math.round(percent * 100) / 100).toFixed(2);
  }
  getLowestPercent(percentage, plagiarism_check_type) {
    let percent = percentage;
    if (percent == null) {
      percent = 0;
    }
    if (plagiarism_check_type == 1) {
      if (percentage > 10) {
        percent = (Math.round(percentage / 10 - 1) * 10);
      }
    }
    return percent;
  }
  getScoreReport(file) {
    file.score_report = true;
  }

  OpenRow(card_id) {
    let index = this.plagiarismFilesList.findIndex(e => e.id == card_id);
    if (Object.keys(this.plagiarismFilesList[index]).includes('open')) {

      this.plagiarismFilesList[index].open = !this.plagiarismFilesList[index].open;
    }
    else {
      this.plagiarismFilesList[index].open = true;
    }

  }

  viewResult(plag_type, file, i) {
    let scoreId = 'scores' + i + file.file_id;
    let card = 'main_card' + file.id;
    if (plag_type == 'view_score') {
      document.getElementById(scoreId).style.border = '1px solid';
      document.getElementById(scoreId).style.paddingBottom = '40px';
      let el = document.getElementById(card);
      el.scrollIntoView();

      this.amplitudeService.logAmplitude('Plag_view_score', {
        'from': 'report card',
        'Report Type': 'score only'
      });



      setTimeout(() => {
        document.getElementById(scoreId).style.border = 'none';
        document.getElementById(scoreId).style.paddingBottom = '0';
      }, 3000)
    }
    else {
      this.generatePlagiarismReport(file, plag_type);
    }

  }
  goToRelatedCard(card_details) {
    let card_to_view = this.plagiarismFilesList.filter(x => x.file_id === card_details.fileId).filter(x => x.id != card_details.card_id)[0]
    card_to_view.open = true;
    this.scroll(card_to_view.id)
    let report_type = card_details.plag_type_number == 1 ? 'free score' :  card_details.plag_type_number == 2 ? 'advance check' : 'standard check';
    if (card_to_view.plagiarism_check_type == 1) {
      this.amplitudeService.logAmplitude('Plag_view_score', {
        'from': 'report card',
        'Report Type': report_type
      });
    }
    if (card_to_view.plagiarism_check_type == 2) {
      this.amplitudeService.logAmplitude('Plag_view_advancecheck', {
        'from': 'report card',
        'Report Type': report_type
      });
    }
    if (card_to_view.plagiarism_check_type == 3) {
      this.amplitudeService.logAmplitude('Plag_view_standardcheck', {
        'from': 'report card',
        'Report Type': report_type,
      });
    }
  }
  scroll(id) {
    id = 'main_card' + id;
    let el = document.getElementById(id);
    if (el) {
      setTimeout(() => el.scrollIntoView({ behavior: 'smooth', block: 'start' }), 300);
    }
  }

  compareByIgnoringKeys(j1, j2) {
    var validkeys = ["file_status", "final_report", "plagiarism_check_type", "turnitine_final_report"]
    var jsonChanged = [];
    for (var i = 0; i < j1.length; i++) {
      for (var k = 0; k < validkeys.length; k++) {
        if (j1[i][validkeys[k]] == j2[i][validkeys[k]]) {
          jsonChanged.push(false)
        }
        else {
          //console.log("json changed");
          jsonChanged.push(true)
        }
      }
    }
    var change = false;
    if (jsonChanged.includes(true) || jsonChanged.length >= 0) {
      change = true;
    }

    return change;
  }

  normalizeJson(j1, j2) {
    var validkeys = ["open", "hide", "hideShowText", "score_report"];

    for (var i = 0; i < j1.length - 1; i++) {

      for (var k = 0; k < validkeys.length; k++) {
        //console.log(j2, validkeys[k], i)
        if (validkeys[k] != "hideShowText") {
          j2[i][validkeys[k]] = j1[i].hasOwnProperty([validkeys[k]]) ? j1[i][validkeys[k]] : false;
        }
        else {
          j2[i][validkeys[k]] = j1[i].hasOwnProperty([validkeys[k]]) ? j1[i][validkeys[k]] : 'Hide';
        }
      }

    }
    return j2;
  }

  getPlagiarismFileList(flag: boolean = true) {
    this.networkCalls.getPlagiarismFileList(this.userId).subscribe(res => {
      if (res["status"]) {

        let server_ui = this.normalizeJson(this.plagiarismFilesList, res.data);
        let reRender = this.compareByIgnoringKeys(server_ui, res.data)

        if (reRender) {
          server_ui.push(this.sampleCard)
          this.allPlagiarismFilesList = server_ui;
          this.plagiarismFilesList = server_ui;
          this.plagiarismFilesList[0].open = true;

          this.cardLimit = this.plagiarismFilesList.length;
          this.pageCount = Math.ceil(this.cardLimit / this.cardIncrease);
          // if (this._notificationService.permission != 'denied') {
          //   this.checkFileStatusForNotification();
          // }
        }
        this.loadMore(this.currentPage);
        this.spinner.hide();



        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].file_status === AutoEdit.IN_PROGRESS) {
            //console.log("data is in progress", res.data[i])
            let id = res.data[i].file_id;
            let type = res.data[i].plagiarism_check_type;
            let status = res.data[i].file_status;

            this.plagiarismFilesList.filter(x => x.file_id == id).forEach(element => {
              this.disableAllPlansCTA(element);
              let key = "score";
              if (type == 3) {
                key = 'standard'
              }
              else if (type == 2) {
                key = 'advance'
              }
              element[key] = status;
              this.plansCTAToShow[key] = status;
            });
            //console.log("key added", this.plagiarismFilesList)
            setTimeout(() => {
              this.getPlagiarismFileList(false);
            }, 30000);
            return;
          }
          else {
            let id = res.data[i].file_id;
            let type = res.data[i].plagiarism_check_type;
            this.plagiarismFilesList.filter(x => x.file_id == id).forEach(element => {
              this.disableAllPlansCTA(element);
              let key = "score";
              if (type == 3) {
                key = 'standard'
              }
              else if (type == 2) {
                key = 'advance'
              }
              element[key] = res.data[i].file_status
              this.plansCTAToShow[key] = res.data[i].file_status
            });
          }
        }
      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
    this.getCreditsDetails()
  }

  displayLoaderForReUploadFile(card) {
    var list = this.plagiarismFilesList.filter(x => x.file_id == card.file_id)

    if (list.length > 1) {
      var list_for_status = list.filter(x => x.file_status == AutoEdit.IN_PROGRESS)

      if (list_for_status.length > 1) {
        return true
      }
      return false
    }
    // let check_key = this.plagoptionAndType.filter(x => x.type == card.plagiarism_check_type)[0].option
    // if (card[check_key] === AutoEdit.IN_PROGRESS){
    //   return false;
    // }
    return true;
  }

  generatePlagiarismReport(file, plagiarism_type) {
    let wordCountOfFile = file.word_count ? file.word_count : this.uploadedFileWordCount;
    if (plagiarism_type == 3) {
      let scans = {};
      let credits = {};

      let report_type = file.plagiarism_check_type == 1 ? 'free score' : ''
      this.amplitudeService.logAmplitude('Plag_generate_standardcheck_CTA', {
        'Report Type': report_type,
        'scans_available': this.creditsDetails.plagiarism_free_page_count_scan,
        'scans_utilised': this.getPageCount(wordCountOfFile),
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased':  this.creditsDetails.purchased_credits
      });
    }
    if (plagiarism_type == 2) {
      let report_type = file.plagiarism_check_type == 1 ? 'free score' : 'standard check'
      this.amplitudeService.logAmplitude('Plag_generate_advancedcheck_CTA', {
        'Report Type': report_type,
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits
      });
    }
    this.dialog.open(PlagiarismReportGenerateComponent, { width: "500px", data: { file: file, requested_plagiarism_type: plagiarism_type, user_decoded_id: this.user_decoded_id, creditsDetails: this.creditsDetails, pages_used: this.getPageCount(wordCountOfFile), wordCount: wordCountOfFile} }).afterClosed().subscribe(response => {
      if(response!=1){
        let report_type = file.plagiarism_check_type == 1 ? 'free score' :  file.plagiarism_check_type == 2 ? 'advance check' : 'standard check';
        if (plagiarism_type == 1) {
          this.amplitudeService.logAmplitude('Plag_generated_freescore', {
            'Location 1': 'plagiarism_file_upload_pageview',
            'Report Type': report_type

          });
        }
        if (plagiarism_type == 2) {
          this.amplitudeService.logAmplitude('Plag_generated_advancedcheck', {
            'Location 2': 'Generate report for 18 credits',
            'Report Type': report_type
          });
        }
        if (plagiarism_type == 3) {
          this.amplitudeService.logAmplitude('Plag_generated_standardcheck', {
            'Location 2': 'plagiarism_free_score_report_card',
            'Report Type': report_type,
            'credit_balance_free': this.creditsDetails.credits,
            'credit_balance_purchased': this.creditsDetails.purchased_credits,
            'scans_available': this.creditsDetails.plagiarism_free_page_count_scan,
            'scans_used':  this.getPageCount(wordCountOfFile)
          });
        }
      }
      this.getPlagiarismFileList(false);
    });
  }

  getCreditsDetails(isOnInit: boolean = false) {
    this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
      if (response["status"]) {
        //console.log("response------",response)
        this.creditsDetails = response["data"];
        this.networkCalls.storeUserCreditDetails(this.creditsDetails);
        this.user_decoded_id = this.creditsDetails["user_id"];
        this.freeStatus = this.creditsDetails["plagiarism_free_score"] > 0 ? true : false;
        this.free_score_reset_date = this.creditsDetails["next_renewal_date"];
        this.total_free_score = this.creditsDetails["user_type"] == "BASIC" ? 1 : 2;
        this.remainig_free_score = this.creditsDetails["plagiarism_free_score"] ? this.creditsDetails["plagiarism_free_score"] : 0;

      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  getSelectedProfileData() {
    this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(profileData => {
      this.selectedProfile = profileData;
      this.profileName = this.selectedProfile != "" ? (this.selectedProfile['firstname'].substr(0, 1) + "" + this.selectedProfile['lastname'].substr(0, 1)).toUpperCase() : "";
    });
    if (this.selectedProfile == '') {
      this.onlineEditorService.getSelectedProfileData(this.userId).subscribe(result => {
        if (result["status"]) {
          this.selectedProfile = result['data']['profile_data'];
          this.profileName = (this.selectedProfile['firstname'].substr(0, 1) + "" + this.selectedProfile['lastname'].substr(0, 1)).toUpperCase();
          this.subjectiveBehaviorSharedService.setSelectedProfileData(this.selectedProfile)
        }
      }, error => {
        this.sharedService.errorHandller(error);
      });
    }
  }


  onDragOver(event) {
    var dropzone = document.querySelector("#dropzone") as HTMLElement
    dropzone.style.visibility = "visible";
    dropzone.style.opacity = '1';
    event.preventDefault();
  }
  onDragEnd(event) {
    event.preventDefault();
    var dropzone = document.querySelector("#dropzone") as HTMLElement
    dropzone.style.visibility = "hidden";
    dropzone.style.opacity = '0';
  }

  onDropSuccess(event) {
    event.preventDefault();
    this.onFileChange(event.dataTransfer.files);
    var dropzone = document.querySelector("#dropzone") as HTMLElement
    dropzone.style.visibility = "hidden";
    dropzone.style.opacity = '0';
  }

  async onFileChange(event) {
    this.resetCloudFileDetails();
    this.noOfRef = -1;
    this.files_submitted = [];
    this.selectedFiles = event;
    if (this.selectedFiles.length > 1) {
      this.toastr.error('Please upload a single file only', 'Error')
      return;
    } else {
      this.fileName = this.selectedFiles[0].name;
    }
    try {

      for (var i = 0; i < this.selectedFiles.length; i++) {
        var file = event[i];
        this.validateFileType(file);

        if (!this.validFile) {
          this.toastr.error(
            "Please upload file with doc, docx, pdf format.",
            "Error"
          );
          this.fileSelected = false;
          this.fileName = "";
          this.uploadSource["LOCAL_SYSTEM"] = false;
          break;
        } else {
          this.referenceQuality = file;
          this.uploadSource["LOCAL_SYSTEM"] = true;
          this.fileSelected = true;
          this.cloudDriveFileDetails.source = "LOCAL_SYSTEM";
          this.cloudDriveFileDetails.fileName = file.name;
          this.files_submitted.push(file);
          //Sending file to calculate the word count via plag check word count API
          this.checkWordCountOfUploadedFile(file);

          this.amplitudeService.logAmplitude('Plag_upload_localdevice', {
            'free_credit_balance': this.creditsDetails.credits,
            'premium_credit_balance': this.creditsDetails.purchased_credits
          });
        }
      }
    } catch (error) {
      this.uploadSource["LOCAL_SYSTEM"] = false;
    }
  }

  validFile = false;
  validateFileType(file) {
    const fileType = file.name.split(".").pop().toLowerCase().toString();

    if (fileType === "doc" || fileType === "docx" || fileType === "pdf") {
      this.validFile = true;
    } else {
      this.validFile = false;
    }
  }

  openDeleteConfirmationModal() {
    const dialogRef = this.dialog.open(CommonDeleteConfirmationModalComponent, {
      disableClose: true,
      panelClass: 'deleteModalSection',
      data: {'isFileDeleted': false, 'errorOccured': false}
    });
    this.amplitudeService.logAmplitude('Plag_check_doc_delete_clicked', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId,
    });
    dialogRef.componentInstance.removeFileFromList.subscribe((res: any) => {
      this.amplitudeService.logAmplitude('Plag_check_doc_confirm_delete', {
        'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'user_id': this.userId,
      });
      if (res) {
        this.networkCalls.deleteUploadedFileByIdForPlagCheck(this.userId, this.cloudDriveFileDetails.fileId, this.user_decoded_id).subscribe(result => {
          this.dialogDataService.setDialogData({'isFileDeleted': true, 'errorOccured': false});
        }, error => {
          this.dialogDataService.setDialogData({'isFileDeleted': false, 'errorOccured': true});
        })
      }
    });

    dialogRef.componentInstance.resetFileOnParent.subscribe((res: any) => {
      this.cancelFileSelection();
    });
  }

  cancelFileSelection() {

    this.resetCloudFileDetails();
    this.amplitudeService.logAmplitude('Plag_back_file_upload', {
      'from': 'plagiarism_file_upload_pageview'
    });
  }
  resetCloudFileDetails() {
    let that = this;
    this.fileName = null;
    this.fileSelected = false;
    this.fileUploaded = false;
    this.uploadedFileWordCount = 0;
    this.files_submitted = [];
    Object.keys(this.uploadSource).forEach(function (key) {
      that.uploadSource[key] = false;
    });

    Object.keys(this.cloudDriveFileDetails).forEach(function (key) {
      that.cloudDriveFileDetails[key] = "";
    });
    that.cloudDriveFileDetails["userId"] = this.userId;
    this.isFileFromEditor = false;
    this.fileUploadProgress = 0;
    this.appRef.tick();
  }

  launchTrinkaDrivePicker() {

    this.resetCloudFileDetails();
    this.uploadSource["TRINKA_DRIVE"] = true;
    const dialogRef = this.dialog.open(ShowMyDriveFilesModalComponent, {
      autoFocus: false, scrollStrategy: new NoopScrollStrategy()
    });
    dialogRef.afterClosed().subscribe(result => {
      this.fileName = result.file_name;
      if (!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx"))) {
        this.fileName += ".docx"
      }
      this.cloudDriveFileDetails.fileId = result.file_id;
      this.cloudDriveFileDetails.fileName = this.fileName;
      this.cloudDriveFileDetails.source = "TRINKA_DRIVE";
      this.fileSelected = true;

      //Sending cloud file details to calculate the word count via plag check word count API
      this.checkWordCountOfUploadedFile();

      this.amplitudeService.logAmplitude('Plag_upload_trinka_mydrive', {
        'Credit Balance Free': this.creditsDetails.credits,
        'Credit Balance Purchased': this.creditsDetails.purchased_credits
      });
    });
  }

  launchOneDrivePicker() {

    this.resetCloudFileDetails();
    this.uploadSource["ONE_DRIVE"] = true;
    this.cloudDriveFileDetails.fileId = "";
    var that = this;
    var odOptions = {
      clientId: environment.one_drive_client_id,
      action: "download",
      multiSelect: false,
      openInNewWindow: true,
      advanced: {
        queryParameters: "select=id,name,size,file,folder,@microsoft.graph.downloadUrl",
        filter: [".docx", ".doc", ".pdf"],
        redirectUri: environment.cloud_redirect_url + "dashboard/auto-edit",
      },
      success: function (files) {
        for (const file of files.value) {
          that.fileName = file.name;
          that.cloudDriveFileDetails.fileId = file.id;
          that.cloudDriveFileDetails.fileName = file.name;
          that.cloudDriveFileDetails.fileUrl = file['@microsoft.graph.downloadUrl'];
          that.cloudDriveFileDetails.source = "ONE_DRIVE";
          that.fileSelected = true;
          that.amplitudeService.logAmplitude('Plag_upload_onedrive', {
            'Credit Balance Free': that.creditsDetails.credits,
            'Credit Balance Purchased': that.creditsDetails.purchased_credits
          });
          //Sending cloud file details to calculate the word count via plag check word count API
          that.checkWordCountOfUploadedFile();
          that.appRef.tick();
        }
      },
      cancel: function () {
      },
      error: function (e) {
      }
    }
    OneDrive.open(odOptions);
  }

  launchDropBoxPicker() {

    this.resetCloudFileDetails();
    this.uploadSource["DROP_BOX"] = true;
    this.cloudDriveFileDetails.fileId = "";
    let that = this;
    var options: DropboxChooseOptions = {
      success: function (files) {
        for (const file of files) {
          that.fileName = file.name;
          that.cloudDriveFileDetails.fileName = file.name;
          that.cloudDriveFileDetails.fileUrl = file.link;
          that.cloudDriveFileDetails.source = "DROP_BOX";
          that.fileSelected = true;
          that.amplitudeService.logAmplitude('Plag_upload_DropBox', {
            'Credit Balance Free': that.creditsDetails.credits,
            'Credit Balance Purchased': that.creditsDetails.purchased_credits
          });
          //Sending cloud file details to calculate the word count via plag check word count API
          that.checkWordCountOfUploadedFile();
          that.appRef.tick();
        }
      },
      cancel: function () {
      },
      linkType: "direct",
      multiselect: false,
      extensions: ['.doc', '.docx', '.pdf'],
    };

    Dropbox.choose(options);
  }

  launchGoogleDrivePicker() {
    this.resetCloudFileDetails();
    this.uploadSource["GOOGLE_DRIVE"] = true;
    this.cloudDriveFileDetails.fileId = "";
    this.googleDrivePickerService.open((data) => {

      if (data.action === 'picked') {
        let file_details = data.docs[0];
        this.fileName = file_details.name;
        if (file_details.name.endsWith(".txt")) {
          this.toastr.error('Please upload file with doc, docx, pdf format.', "Error");
          return;
        }
        if (!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx")|| this.fileName.endsWith(".pdf"))) {
          this.fileName += ".docx"
        }
        this.cloudDriveFileDetails.fileId = file_details.id;
        this.cloudDriveFileDetails.fileName = this.fileName;
        this.cloudDriveFileDetails.source = "GOOGLE_DRIVE";
        this.cloudDriveFileDetails.oauthToken = this.googleDrivePickerService.oauthAccessToken;
        this.fileSelected = true;
        this.amplitudeService.logAmplitude('Plag_upload_Gdrive', {
          'Credit Balance Free': this.creditsDetails.credits,
          'Credit Balance Purchased': this.creditsDetails.purchased_credits
        });
        //Sending cloud file details to calculate the word count via plag check word count API
        this.checkWordCountOfUploadedFile();
        this.appRef.tick();
      }
    }, true);
  }

  addCredits() {

    let redirct_url = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url + '/user/credit/' + this.userData, "_self");
  }
  credits() {
    if (this.creditsDetails != null) {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData: this.creditsDetails,
      };
      this.dialog.open(CreditDetailsComponent, dialogConfig)
      this.amplitudeService.logAmplitude('Credits_clicked', {
        'from': 'Plagiarism',
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits,
        'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'user_id': this.userId,
      });
    }
  }

  showReports(event) {
    if (event.status) {
      this.fileSelected = false;
     // this.ngZone.run(() => { this.notificationPermisson = 'granted'; });
     // this._notificationService.requestPermission();
      // this._notificationService.getPermissionStatus().subscribe(resp => {
      //   if (resp == null) {
      //     this._notificationService.requestPermission();
      //   } else {
      //     this.ngZone.run(() => { this.notificationPermisson = resp; this.step++; });
      //   }
      // });
    }
    this.getPlagiarismFileList();
  }

  downloadFile(file, plag_type) {
    this.router.navigate(['plagiarism-checker/report'])
  }

  deleteFile(action, file) {
    this.spinner.show();
    this.networkCalls.deleteFileByIdPlagiarism(this.userId, file.file_id, this.user_decoded_id).subscribe(res => {
      // this.spinner.hide();
      this.amplitudeService.logAmplitude('plag_file_delete', {
        'fileID': file.file_id,
        'selected_check': this.plagiarism_check_list[file.plagiarism_check_type - 1].type
      });
      let message = file.file_name + " is deleted"
      let snakRef = this._snackBar.open(message, action, {
        duration: 10000,
        horizontalPosition: "right",
      });
      snakRef.onAction().subscribe(() => this.restoreFile(file));
      this.getPlagiarismFileList();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  restoreFile(file) {
    this.spinner.show();
    this.networkCalls.restoreFileByIdPlagiarism(this.userId, file.file_id, this.user_decoded_id).subscribe(res => {
      this.spinner.hide();
      this.getPlagiarismFileList();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }



  openSampleReportModal(isIthenticate) {
    this.dialog.open(PlagCheckSampleReportPopupComponent, { data: { "isIthenticate": isIthenticate } });
  }

  signOut() {
    this.sharedService.SignOut();
  }

  checkFileStatusForNotification() {
    this.plagiarismFilesList.forEach(element => {
      if (!this.inProgressFileList.includes(element.id) && element.file_status == 'IN_PROGRESS') {
        this.inProgressFileList.push(element.id);
      }
      else if (this.inProgressFileList.includes(element.id) && element.file_status == 'SUCCESS') {
        let reportLink = element.plagiarism_check_type == 3 ? element.turnitine_report_pdf_url : element.ithenticate_report_pdf_url;
        //this.notify(element.file_name, reportLink);
        let index = this.inProgressFileList.findIndex(e => e.id === element.id);
        this.inProgressFileList.splice(index, 1);
      }
      else { }
    });
  }

  notify(fileName, reportLink) {
    let data: Array<any> = [];
    let reportUrl = environment.cloud_redirect_url + "plagiarism-checker/report/" + btoa(encodeURI(fileName)).replace(/=/gi, "") + "/" + reportLink;
    data.push({
      'title': 'Trinka AI',
      'alertContent': 'Plagarism report generated successfully',
      'reportLink': reportUrl
    });
    this._notificationService.generateNotification(data);
  }

  toggleReport(file: any) {
    let index = this.plagiarismFilesList.findIndex(e => e.file_id === file.file_id);
    if (Object.keys(this.plagiarismFilesList[index]).includes('hide')) {
      if (this.plagiarismFilesList[index].hide) {
        this.plagiarismFilesList[index].hideShowText = "Hide";
      }
      else {
        this.plagiarismFilesList[index].hideShowText = "Show";
      }
      this.plagiarismFilesList[index].hide = !this.plagiarismFilesList[index].hide;
    }
    else {

      this.plagiarismFilesList[index].hideShowText = "Show";
      this.plagiarismFilesList[index].hide = true;
    }

  }

  loadMore(pageIndex) {
    this.currentPage = pageIndex;
    this.handleLoadMoreBtn()
    const startRange = (pageIndex - 1) * this.cardIncrease;
    const endRange = pageIndex * this.cardIncrease > this.cardLimit ? this.cardLimit : pageIndex * this.cardIncrease;
    this.plagiarismFilesList = this.allPlagiarismFilesList.slice(0, endRange);
    this.range = endRange;
  }

  handleLoadMoreBtn() {
    if (this.pageCount === this.currentPage) {
      this.loadmorebtn = true;
    }
  }
  download(url, filename) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
    document.body.removeChild(a);
    if (filename.includes('Advanced')) {
      this.amplitudeService.logAmplitude('Plag_view_sample', {
        'Report Type': 'advance check'
      });
    }
    else {
      this.amplitudeService.logAmplitude('Plag_view_sample', {
        'Report Type': 'standard check'
      });
    }

  }

  searchReport() {
    this.amplitudeService.logAmplitude('Plag_search', {
      'searched_query': this.filterModel
    });
  }
  navigationToDashboard() {
    this.amplitudeService.logAmplitude('Trinka_Logo', {
      'from': 'Plag_toolpage_view_ backnavigation'
    });
  }

  view_report(plag_type){
    let submission_id = this.sampleCard["plagiarism_check_type"] == '2' ? this.sampleCard['submission_id'] : this.sampleCard['turnitine_submission_id'];
    this.networkCalls.getithenticateURL(this.userId, this.sample_file_id, plag_type, this.user_decoded_id, submission_id).subscribe(result => {
      // window.open(result.data, "_blank");
      let filename = "sample_report.docx"
      let report_url = 'plagiarism-checker/report/' + plag_type + '/' + btoa(encodeURI(filename)).replace(/=/gi, "") +"/"+ btoa(encodeURIComponent(result.data));
      //console.log(report_url);
      window.open(report_url.replace(/=/gi, ""),"_blank");
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  async showUploadProgressBar() {
    for (let i = 0; i < 10; i++) {
      if (this.fileUploadProgress < 100) this.fileUploadProgress += 5;
      else break;
      await this.delay(1500);
    }
  }

  /**
   * Function to calculate the word count via plag check word count API
   * @param file can be undefined for Cloud files and will be a file when file upload from local
   */
  checkWordCountOfUploadedFile(file: any = undefined) {
    try {
      let fileObject = new FormData();
      //Appending file only if the file is present
      if (file) fileObject.append("file", file);

      fileObject.append("user_id", this.userId);
      fileObject.append("d_user_id", this.user_decoded_id);
      fileObject.append('cloudFileDetails', JSON.stringify(this.cloudDriveFileDetails));

      this.show_delay = true;
      this.showUploadProgressBar();
      //API call to fetch word count & file id for further process
      this.networkCalls.plagFileWordCountAPI(fileObject).subscribe(res => {
        this.cloudDriveFileDetails.fileId = res.data.file_id;
        this.fileUploadProgress = 100;
        setTimeout(() => {
          this.uploadedFileWordCount = res.data.word_count
          this.cloudDriveFileDetails['word_count'] = this.uploadedFileWordCount
          this.show_delay = false;
          if (!this.uploadedFileWordCount) {
            // setTimeout(() => {
              this.openMessageModal("You have uploaded a file with no text. Please upload another file with text.", "No text found!");
              this.cancelFileSelection();
            // }, 600);
          }
          this.appRef.tick();
        }, 500);

      }, error => {
        this.toastr.error(error.statusText, "Error");
        this.resetCloudFileDetails();
      })
    } catch (e) {
      this.toastr.error(e, "Error");
      this.resetCloudFileDetails();
    }
    this.appRef.tick();
  }

  uppercaseFirstLetter(phrase):string{
    if (phrase == null) {return};
    return phrase
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }

  //Redirecting to the billing page
  redirectToUpgradeToPremiumPage(fromTopPanel: boolean = false) {
    let data = {
      "user_id" : this.userId,
      "plan_name": this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      "from":"Trinka"
    };

    if (!fromTopPanel) this.amplitudeService.logAmplitude('Plag_upgrade_nudge_clicked', {
      "user_id" : this.userId,
      "subscription_type": this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
    });
    else this.amplitudeService.logAmplitude('Upgrade_nudge_clicked', {
      'from': 'Plagiarism Checker',
      "user_id" : this.userId,
      "Current Plan": this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url  = environment.payment_fe;
    window.open(redirct_url + '/user/' + this.userData, "_blank");
  }

  //Function to get the number of pages(scans) required for uploaded file as per the word count of the file
  getPageCount(noOfWords: number) {
    const perPageCount = 250;
    if (noOfWords) return Math.ceil(noOfWords / perPageCount)
    else return 0;
  }

  openMessageModal(message: string, title: string = 'Alert message'): void {
      const dialogRef = this.dialog.open(MessageModalComponent, {
        disableClose: true,
        panelClass: 'deleteModalSectionPlagCheck',
        data: { message: message, title: title }
      });
      // Optional: Handle the dialog result
      dialogRef.afterClosed().subscribe(result => {
      });
  }

  getFinalReportScore(file: any) {
    return file.plagiarism_check_type == 1 ? file.free_score_final_report : file.final_report;
  }

  wordLimitReachedAction(data: string) {
    if (data === 'uploadAgainAction') {
      this.amplitudeService.logAmplitude('Plag_check_upload_again', {
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits,
        'user_id': this.userId,
      });
      if (this.editorFileID && this.isFileFromEditor) {
        window.location.href = `${document.location.origin}/editor/main/${this.editorFileID}`
      }
      else {
        this.resetCloudFileDetails();
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    }
    else if (data === 'redirectToBillingPage') {
      this.amplitudeService.logAmplitude('Upgrade_nudge_clicked', {
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits,
        'user_id': this.userId,
        'from': 'freescore',
        "Current Plan": this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
        'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
      });
      let data = {
        "user_id": this.userId,
        "plan_name": this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
        "from":"Trinka"
      };
      this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
      let redirct_url = environment.payment_fe;
      // this.resetCloudFileDetails();
      window.open(redirct_url + '/user/' + this.userData, "_blank");
    }
  }

  fetchReferralRewards(){
    this.dashboardService.getReferralRewards(this.userId).subscribe(response => {
        this.referralReward = response["referral_reward"] || 0;
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  showReferralRewardModal(){

    let data = {
      "user_id" : this.userId,
      "plan_name": this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      "from":"Trinka"
    };

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let subscriptionUpgradeUrl  = environment.payment_fe + '/user/' + this.userData;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      userId : this.userId,
      referralReward : this.referralReward,
      subscriptionUpgradeUrl: subscriptionUpgradeUrl,
      userType: this.creditsDetails.user_type
    };
    this.dialog.open(ReferralRewardModalComponent, dialogConfig);
  }

  getStandardPlagCheckWordCount(noOfWords: number){
    if (noOfWords){ 
      let credits = Math.ceil(noOfWords / this.CREDIT_CALCULATION_WORD_COUNT_BRACKET) * this.STANDARD_CREDIT;
      return  credits > 1 ? `${credits} credits` : `${credits} credit`;
    }
    else {
      return `0 credit`;
    }
  }

  getAdvanceCreditCount(noOfWords: number){
    if (noOfWords){ 
      let credits = Math.ceil(noOfWords / this.CREDIT_CALCULATION_WORD_COUNT_BRACKET) * this.ADVANCE_CREDIT;
      return  credits > 1 ? `${credits} credits` : `${credits} credit`;
    }
    else {
      return `0 credit`;
    }
  }

}
