import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/networkcalls/website.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-enterprice',
  templateUrl: './enterprice.component.html',
  styleUrls: ['./enterprice.component.scss']
})
export class EnterpriceComponent implements OnInit {
  email: any;
  constructor(
    private toastr: ToastrService,
    private networkCall: WebsiteService,
    private sharedService: SharedService,
  ) { }

  ngOnInit() {
  }

  contactToSales(){
    this.networkCall.postContactSales(this.email).subscribe(result=>{
      this.toastr.success("Thanks for your interest! We will reach out to you shortly.")
      this.email="";
    },error=>{
      this.sharedService.errorHandller(error);
    })
  }
}
