import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'citeScoreFilter',
  pure: false
})
export class CiteScorePipe implements PipeTransform {

  transform(items: any, filter: any): any {
    let currentfilter = filter["Cite Score"].data;
      
    if (!items) return [];
    
    if (currentfilter.length == 0) return items;

    let start, end;
    var data = [];
    for(let i=0;i<currentfilter.length; i++)
    {
      start = Number(currentfilter[i].split("-",2)[0].trim());
      end = Number(currentfilter[i].split("-",2)[1].trim());

      items.filter(result => {
        if(result.cite_score>= start && result.cite_score<=end){
            data.push(result);
            return result;
          }
        })
    }
    return [...data];
  }

}
