<div class="CreditsPopupSection">
    <div class="CreditsHeader">
        <h2>Sure to delete?</h2>
        <button mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>

    <div class="paymentHistoryBody">
          <p style="margin-bottom: 20px;">You will lose access to all files and related data on Trinka.</p>
          <p>Once deleted, your data on both platforms cannot be restored. </p>
    </div>
    
    <div class="paymentHistoryBody" *ngIf = "enagoFlag && provider != 'Google_Trinka'">
        <p style="margin-top: 20px; font-weight: 600;">Your access to Enago account with this email will remain. </p>
  </div>

    <div class="CancelSubscriptionBody">
        <span (click)= "close()">No, keep the account</span>&nbsp;
        <button (click)= "save()">Yes, delete</button>
    </div>
</div>



