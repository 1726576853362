import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { ManageProfileComponent } from '../manage-profile/manage-profile.component';

@Component({
  selector: 'app-payment-transaction',
  templateUrl: './payment-transaction.component.html',
  styleUrls: ['./payment-transaction.component.scss']
})
export class PaymentTransactionComponent implements OnInit {

  transactionDetails: any
  isConfirmation: boolean = false
  isData: boolean= false

  constructor(public sharedService: SharedService,
    private dialogRef: MatDialogRef<ManageProfileComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      var details = data.transactionDetails;
      if(details === "CONFIRMATION"){
        this.isConfirmation = true
      }
      else{
        this.isConfirmation = false
        this.transactionDetails = data.transactionDetails;
        this.isData = Array.isArray(this.transactionDetails)?true:false;
      }
    }

  ngOnInit() {
    
  }

  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  } 

}
