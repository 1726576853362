import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-editor-reference-quality-check-modal',
  templateUrl: './editor-reference-quality-check-modal.component.html',
  styleUrls: ['./editor-reference-quality-check-modal.component.scss']
})
export class EditorReferenceQualityCheckModalComponent implements OnInit {

  details: any;
  userId: any;
  constructor(private dialogRef : MatDialogRef<EditorReferenceQualityCheckModalComponent>,
    public amplitudeService: AmplitudeService,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.details = data;
      let localMeta = this.sharedService.getLocalStorageMeta();
      this.userId = btoa(localMeta.sub);
    }

  ngOnInit() {
  }

  submit(){
    this.amplitudeService.logAmplitude('RFQ_EditorPopup_CheckRFQ_CTA', {
      'Credit Balance Free': this.details['freeCredit'],
      'Credit Balance Purchased': this.details['purchaseCredit'],
      'File ID': this.details['fileId'],
    });
    this.dialogRef.close(1);
  }

  upgradePlanPopup(){
    this.amplitudeService.logAmplitude('RFQ_Upgrade_CTA', {
      'Credit Balance Free': this.details['freeCredit'],
      'Credit Balance Purchased': this.details['purchaseCredit'],
      'File ID': this.details['fileId'],
    });
    let data = {
      "user_id" : this.userId
    };
    let userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url  = environment.payment_fe;
    window.open(redirct_url+'/user/'+userData,"_blank") 
  }
}
