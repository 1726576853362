<div class="publicationSubSection">
<mat-card class="clinical_trial_sections" *ngIf ="clinicalCheck?.required_to_show">
    <div class="clinical_trial_cards" *ngIf="clinicalCheck?.is_present">
        <div class="clinical_trial_title">
            <p>
                <span>Check 1</span> Clinical Trials Information
                <i class="clinicalTrial_info_section" alt="Shows the clinical trial IDs detected in the manuscript and the available clinical trial registry information.">
                    <img src="../../../../assets/images/Icon_Info.svg" alt="">
                </i>
            </p>
        </div>
        <div class="clinical_trial_body">
            <p class="clinical_body_title"><span>{{clinicalCheck.count}}</span> Clinical trial ID(s) found</p>
            <div class="clinicalObject_section" *ngFor="let clinicalObject of clinicalCheck.individual_ids;">
                <p> ID: <a (click)="redirectOnUrl(clinicalObject.url)" class="urllink"> {{clinicalObject.trial_id}}</a></p>
                <ul>
                    <li>Official Title: {{clinicalObject.official_title}} </li>
                    <li>Brief Summary: {{clinicalObject.brief_summary}}</li>
                    <li>Overall Status: {{clinicalObject.overall_status}}</li>
                    <li>Registry: {{clinicalObject.registry}}</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="clinical_trial_cards" *ngIf="!clinicalCheck?.is_present">
        <div class="clinical_trial_title">
            <p>
                <span>Check 1</span> Clinical Trials Information
                <i class="clinicalTrial_info_section" alt="Shows the clinical trial IDs detected in the manuscript and the available clinical trial registry information.">
                    <img src="../../../../assets/images/Icon_Info.svg" alt="">
                </i>
            </p> 
        </div>
        <div class="clinical_trial_body">
            <p class="clinical_body_title with_not_found_case">No clinical trial ID(s) found</p> 
            <p style="font-size: 13px;color: rgba(77,77,77,0.8); line-height: 17px;">When studies are linked to clinical trial or present results from a trial, the trial numbers
                should be included in the manuscript so the readers can easily access more Information on the trial 
                include complete context of the study and the result
            </p>
        </div>
    </div>
</mat-card>

<mat-card class="clinical_trial_sections" *ngIf ="!clinicalCheck?.required_to_show">
        <div class="clinical_trial_cards">
            <div class="clinical_trial_title">
                <p>
                    <span>Check 1</span> Clinical Trial Information 
                    <i class="clinicalTrial_info_section" alt="Shows the clinical trial IDs detected in the manuscript and the available clinical trial registry information.">
                        <img src="../../../../assets/images/Icon_Info.svg" alt="">
                    </i>
                </p>
            </div>
            <div class="clinical_trial_body">
                <p class="clinical_body_title with_not_required">This check is not suitable for your subject area.</p>
            </div>
        </div>
    </mat-card>
</div>