import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { JournalFinderRoutingModule } from './journal-finder-routing.module';
import { JournalFinder } from './journal-finder';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { MaterialModuleList } from 'src/app/material-modules';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { MainComponent } from 'src/app/components/journal-finder/main/main.component';
import {MatSliderModule} from '@angular/material/slider';
import { DndDirective } from 'src/app//_directives/dnd.directive';
import { NgbModule,NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FusionChartsModule } from "angular-fusioncharts";
import { FilterPipe } from "src/app/_pipes/filter.pipe"
import { SubjectAreaFilterPipe } from "src/app/_pipes/subjectarea.pipe"
import { ConceptsFilterPipe } from "src/app/_pipes/concepts.pipe"
import { OpenAccessFilterPipe } from "src/app/_pipes/open-access.pipe"
import { PredatoryFilterPipe } from "src/app/_pipes/predatory.pipe"
import { PublisherFilterPipe } from "src/app/_pipes/publisher.pipe"
import { TargetJournalFilterPipe } from "src/app/_pipes/target-journal.pipe"
import { SortPipe } from "src/app/_pipes/sort.pipe"
import { OrdinalPipe } from "src/app/_pipes/ordinal.pipe"
import { ResetFilterPipe } from "src/app/_pipes/resetData.pipe"
// import { SliderModule } from "angular-double-slider"
import { NgxSliderModule } from '@angular-slider/ngx-slider';

// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { FilterByFileNamePipe } from 'src/app/_pipes/filter-by-file-name.pipe';
import { SharedModule } from '../shared/shared.module';
import { RegionPipe } from 'src/app/_pipes/region.pipe';
import { ImpactFactorPipe } from 'src/app/_pipes/impact-factor.pipe';
import { CiteScorePipe } from 'src/app/_pipes/cite-score.pipe';
import { HIndexPipe } from 'src/app/_pipes/h-index.pipe';

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);

// FusionCharts.options['license']({
//   key: '<YOUR KEY>',
//   creditLabel: false,
// });

@NgModule({
  declarations: [
    JournalFinder,
    MainComponent,
    FilterPipe,
    OpenAccessFilterPipe,
    PredatoryFilterPipe,
    SubjectAreaFilterPipe,
    ConceptsFilterPipe,
    PublisherFilterPipe,
    TargetJournalFilterPipe,
    SortPipe,
    ResetFilterPipe,
    OrdinalPipe,
    RegionPipe,
    ImpactFactorPipe,
    HIndexPipe,
    CiteScorePipe,
  ],
  imports: [
    CommonModule,
    JournalFinderRoutingModule,
    CreditCardDirectivesModule,
    FormsModule,
    HttpClientModule,
    MaterialModuleList,
    NgxSpinnerModule,
    MatSliderModule,
    ReactiveFormsModule ,
    ToastrModule.forRoot(),
    NgbModule,
    NgbPaginationModule, 
    NgbAlertModule,
    FusionChartsModule,
    NgxSliderModule,
    SharedModule
  ],
  providers: [FilterPipe,SubjectAreaFilterPipe],
  bootstrap: [JournalFinder],
})
export class JournalFinderModule { }
