export class PlagiarismReport{
  'status':  boolean;
  'message': string;
  'data':   [
    {
      'plagiarism_check_type':   number,
      'file_status': string,
      'credit': number,
      'percentage': number
      'uploaded_at': Date,
  }
]

constructor() {
   var Datum  : any= [{
    plagiarism_check_type: 0,
    file_status: 'NA',
    credit:  0 ,
    percentage: 0,
    uploaded_at: 'NA'
  }]
  
    this.status  = false;
    this.message = 'NA';
    this.data = Datum
}
}
