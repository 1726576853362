import { Injectable, Inject } from '@angular/core';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ShareWidgetComponent } from '../components/dashboard/share-widget/share-widget.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguagePreferencesComponent } from '../components/language-preferences/language-preferences.component';
import { FileUploadComponent } from '../components/dashboard/file-upload/file-upload.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignInSignOutService } from './networkcalls/sign-in-sign-out.service';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { LoginOutput } from '../_enums/login-output.enum';
import { throwError, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DashboardService } from './networkcalls/dashboard.service';
import { HttpResponse } from '@angular/common/http';
import { WhiteList } from "src/app/_enums/whitlist.enum";
import { FeaturePermission } from '../_interfaces/feature-permission.interface';
import { PluginsService } from './plugins.service';
import { GetMetaService } from './get-meta.service';

@Injectable({
  providedIn: 'root'
})
export class SharedBrowserExtensionService {
  signInpopUp;
  isSignIn: boolean = false;
  userId: string;
  currentFileId: string;
  enableSignInPanel: number = 0;
  filterCheckList = [];
  renameFile: any;
  offsetSeconds = 5;
  featurePermission = new FeaturePermission();
  autoEditFileCredit: number = 0;
  
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService,
    public dialog: MatDialog,
    private route: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public jwtHelper: JwtHelperService,
    public loginservice: SignInSignOutService,
    public networkCalls: DashboardService,
    private pluginService: PluginsService,
    public metaString : GetMetaService
    ) {
      this.filterCheckList = [
        {
          name: "Grammar",
          disabled: false,
          checked: true,
          labelPosition: "after",
          flag: 1,
          errorCount: 0,
          display: true
        }, {
          name: "Spelling",
          disabled: false,
          checked: true,
          labelPosition: "after",
          flag: 2,
          errorCount: 0,
          display: true
        }, {
          name: "Writing Advisor",
          disabled: false,
          checked: true,
          labelPosition: "after",
          flag: 3,
          errorCount: 0,
          display: true
        }, {
          name: "Enhancements",
          disabled: false,
          checked: true,
          labelPosition: "after",
          flag: 4,
          errorCount: 0,
          display: true
        }, {
          name: "Style Guide",
          disabled: false,
          checked: true,
          labelPosition: "after",
          flag: 5,
          errorCount: 0,
          display: true
        }
      ];
  }

  openShareDialog() {
    this.dialog.open(ShareWidgetComponent);
  }

  openLanguagePreferences() {
    this.dialog.open(LanguagePreferencesComponent)
  }

  openFileUploadDialog() {
    this.dialog.open(FileUploadComponent);
  }


  getLocalStorageMeta() {
    try {
      const storg = this.storage.get(this.metaString.getMeta());
      if (storg) { if(storg['token']){return JSON.parse(atob(storg['token'].split('.')[1]))}; }
      return false;
    } catch (error) {
      const storg = localStorage.getItem(this.metaString.getMeta());
      if (storg) { if(storg['token']){return JSON.parse(atob(storg['token'].split('.')[1]))}; }
      return false;
    }

  }

  refreshToken() {
      let payload = {
        "token": this.storage.get(this.metaString.getMeta())['token'],
        "refresh_token": this.storage.get(this.metaString.getMeta())['refresh_token']
      }
      return this.loginservice.requestAccessToken(payload)
        .pipe(tap((result: LoginOutput) => {
          this.storage.set(this.metaString.getMeta(), result);
        }),catchError(error => {
          if (error.status === 401) {
            this.storage.set(this.metaString.getMeta(), {});
            window.location.href = window.location.origin;
            return throwError(error);
          }
        }));
  }

  getAccessToken() {
    try {
      if(!this.isAccessTokenExpired()){
        const storg = this.storage.get(this.metaString.getMeta());
        if (storg) {
          if(storg['token']){
            return storg['token']
          };
        }
        return '';
      }else{
        this.refreshToken().subscribe(result=>{
        },error =>{ console.log(error)})
        return this.storage.get(this.metaString.getMeta())["token"]
      }
    } catch (error) {
      const storg = localStorage.getItem(this.metaString.getMeta());
      if (storg) { if(storg['token']){return storg['token']}; }
      return '';
    }
  }

  getRefreshToken() {
    try {
      const storg = this.storage.get(this.metaString.getMeta());
      if (storg) { if(storg['refresh_token']){return storg['refresh_token']}; }
      return '';
    } catch (error) {
      const storg = localStorage.getItem(this.metaString.getMeta());
      if (storg) { if(storg['refresh_token']){return storg['refresh_token']}; }
      return '';
    }
  }

  isAccessTokenExpired(): boolean {
    if(this.storage.get(this.metaString.getMeta()) != undefined && this.storage.get(this.metaString.getMeta())['token'] !=''){
    return this.jwtHelper.isTokenExpired(this.storage.get(this.metaString.getMeta())['token'],this.offsetSeconds)
    // return this.jwtHelper.isTokenExpired(this.storage.get(this.metaString.getMeta())['token'],5)
    };
    return true;
  }
  
  
  SignOut(): void {
    var logout_payload = {
      "refresh_token": this.getRefreshToken(),
      "access_token": this.getAccessToken()

    };
    this.spinner.show();
    this.loginservice.logout(logout_payload).subscribe(result => {
      this.spinner.hide();
    }, error => {
      this.errorHandller(error);
    });
    this.storage.set(this.metaString.getMeta(), {});
    this.route.navigate(['/signin'])
  }

  // downloadFile() {
  //   window.location.href = environment.server_address + "/trinka/api/v1/user/" + this.userId + "/file/" + this.currentFileId + "/export";
  // }

  downloadFileAutoEdit(fileId,bool?) {
    this.spinner.show();
    this.networkCalls.downloadAutoEdit(fileId).subscribe(response=> {
      this.spinner.hide();
      let url: string = response['data']

      window.location.href = url;
      },error =>{
      this.toastr.error("Unable to download file",'Error')
      this.spinner.hide();
    })
  }

  downloadFile(bool?){
    this.spinner.show();
    this.networkCalls.download(this.userId,this.currentFileId, bool).subscribe((response: HttpResponse<Blob>) => {
      this.spinner.hide();
      let filename: string = this.getFileName(response)
      let binaryData = [];
      binaryData.push(response.body);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      },error =>{
      this.toastr.error("Unable to download file",'Error')
      this.spinner.hide();
    })
  }

  private getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get('Content-Disposition');
      const r = /(?:filename=")(.+)(?:")/
      filename = r.exec(contentDisposition)[1];
    }catch (e) {
      filename = 'Untitled.docx'
    }
    return filename
  }

  setCurrentFileAndUserId(userId, file_id) {
    this.userId = userId;
    this.currentFileId = file_id;
    localStorage.setItem('fileId', this.currentFileId)
  }


  getCurrentFileId(){
    var fileId=  localStorage.getItem('fileId');
    return fileId;
  }

  errorHandller(error) {
    let alert_message = error.error.message? error.error.message : 'Something went wrong';
    switch (error.status) {
      case 401:
        this.toastr.error(alert_message, 'Error');
        this.storage.set(this.metaString.getMeta(), {});
        window.location.href = window.location.origin;
        break;
      case 403:
        this.toastr.error(alert_message, 'Error');
        this.storage.set(this.metaString.getMeta(), {});
        window.location.href = window.location.origin;
        break;
      case 400:
      case 404:
      case 409:
      case 500:
      case 502:
        this.toastr.error(alert_message, 'Error');
        break;

      default:
        // this.toastr.error("Please check your network connection and try again.", 'Error');
        break;
    }
    this.spinner.hide();
  }

  updateFileName(file){
    this.renameFile = file;
  }

  mainLogo(){
    switch (window.location.host) {
      case WhiteList.TRINKA:
        return "../../../assets/images/Trinka_Logo.svg"
      case WhiteList.CLIENT:
        return "../../../assets/images/blank_main_editor_logo.png"
      case WhiteList.SMARTMANUSCRIPT:
        return "../../../assets/images/blank_main_editor_logo.png"
      default:
        return "../../../assets/images/Trinka_Logo.svg"
    }
  }

  threeDotLogo(){
    switch (window.location.host) {
      case WhiteList.TRINKA:
        return "../../../../assets/images/trinka_dot_logo.png"
      case WhiteList.CLIENT:
        return "../../../assets/images/blank_editor_logo.png"
      case WhiteList.SMARTMANUSCRIPT:
        return "../../../assets/images/blank_editor_logo.png"
      default:
        return "../../../../assets/images/trinka_dot_logo.png"
    }

  }

  brandName(){
    switch (window.location.host) {
      case WhiteList.TRINKA:
        return "Trinka"
      case WhiteList.CLIENT:
        return ""
      case WhiteList.SMARTMANUSCRIPT:
        return ""
      default:
        return "Trinka"
    }
  }

  whiteListDisplay(){
    switch (window.location.host) {
      case WhiteList.TRINKA:
        return true
      case WhiteList.CLIENT:
        return true
      case WhiteList.SMARTMANUSCRIPT:
        return false
      default:
        return true
    } 
  }
}
