import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FilesList } from 'src/app/_interfaces/dashboard/file-list';

@Injectable({
  providedIn: 'root'
})
export class MyDriveService {

  getFilesBySearchCall(userId: string, searchString: FormData):Observable<any>{
    return this.http.post<FilesList>(environment.server_address + "/trinka/api/v1/user/"+userId+"/search",searchString);
  }
  getArchiveFilesRequest(userId: string): Observable<FilesList> {
    return this.http.get<FilesList>(environment.server_address + "/trinka/api/v1/user/"+userId+"/file/archive");
  }
  
  constructor(public http: HttpClient) { }

  archiveFileRequest(userId: string, fileId: FormData):Observable<any> {
    return this.http.put<any>(environment.server_address + "/trinka/api/v1/user/"+userId+"/file/archive", fileId);
  }
  
  getFilesByUser(userid):Observable<any>{
      let folder_id = null;
    return this.http.get(environment.server_address+"/trinka/api/v1/user/"+userid+"/file/"+folder_id)
  }

  getFoldersByUser(userid):Observable<any>{
    return this.http.get(environment.server_address+"/trinka/api/v1/user/"+userid+"/folder");
  }

  createFolder(userid, folderName: FormData):Observable<any>{
    return this.http.post(environment.server_address+"/trinka/api/v1/user/"+userid+"/folder", folderName);
  }

  getFilesByFolderId(userid, folderId):Observable<FilesList>{
    return this.http.get<FilesList>(environment.server_address+"/trinka/api/v1/user/"+userid+"/folder/"+folderId);
  }

  putRenameFolder(userid, folderId, folder: FormData):Observable<any>{
    return this.http.put(environment.server_address+"/trinka/api/v1/user/"+userid+"/folder/"+folderId, folder);
  }

  deleteFolderById(userId: any, folderId){
    return this.http.delete(environment.server_address+"/trinka/api/v1/user/"+userId+"/folder/"+folderId)
  }

  restoreFolderById(userId: any,folderId: string,undo:boolean){
    return this.http.patch(environment.server_address+"/trinka/api/v1/user/"+userId+"/folder/"+folderId,{undo:undo})
  }

  fileMoveToFolder(userId: any,fileID: string, folder_id: FormData){
    return this.http.put(environment.server_address+"/trinka/api/v1/user/"+userId+"/file/"+fileID+"/move",folder_id)
  }

}
