<div class="purgePlan_wrap">
   <div class="purgePlanHeader">
      <button class="closedButton" mat-button mat-dialog-close>
         <img src="../../../../assets/images/closeIcons.png" alt="">
      </button>
      <h3>All data will be erased</h3>
   </div>
   <div class="purgePlanBody">
      <p>Since you are on Purge Plan, all data related to this file will be deleted. </p>
      <p>The deletion cannot be undone, make sure you download the file before deleting.</p>
   </div>
   <div class="purgePlanFooter">
      <button type="submit" class="keepMyFileButton" mat-raised-button mat-dialog-close>
         No, keep my file
      </button>
      <button type="submit" mat-raised-button color="primary" (click)="deletePermanently()">
         Yes, delete my file 
         <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.295 0.205078L7.66838 1.8317L14.1749 8.3382H0V10.662H14.1749L7.66838 17.1685L9.295 18.7951L18.59 9.50008L9.295 0.205078Z" fill="white"/>
            </svg>
      </button>
   </div>
</div>