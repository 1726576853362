import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetMetaService {

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,

  ) { }

  getMeta() {
    if (environment.trialMode) {
      return "trial_meta";
    } else {
      return "meta";
    }
  }
}
