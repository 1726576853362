import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material'
import { SharedService } from 'src/app/_services/shared.service'
import { DialogDataService } from 'src/app/_services/common-delete-confirmation-service'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-common-delete-confirmation-modal',
  templateUrl: './common-delete-confirmation-modal.component.html',
  styleUrls: ['./common-delete-confirmation-modal.component.scss']
})
export class CommonDeleteConfirmationModalComponent implements OnInit {
  //To show file deleted successfully message
  isFileDeleted: boolean = false

  //To show error occured message
  errorOccured: boolean = false

  @Output() removeFileFromList = new EventEmitter<boolean>()
  @Output() resetFileOnParent = new EventEmitter<any>()

  constructor (
    public dialogRef: MatDialogRef<CommonDeleteConfirmationModalComponent>,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogDataService: DialogDataService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    
    //Detecting dataUpdated event from service(whenever data is changed)
    this.dialogDataService.dataUpdated.subscribe((data: any) => {
      //Show toast message and hide the modal when error occurred while deleting the file
      if (data.errorOccured) {
        this.toastrService.error('Error in deleting the file.', 'Error')
        this.resetFileOnParent.emit()
        this.dialogRef.close()
      }
      //Show success message on popup and hide the modal when deleting the file was success
      if (data.isFileDeleted) {
        this.isFileDeleted = true
        setTimeout(() => {
          this.dialogRef.close()
          this.resetFileOnParent.emit()
        }, 2000)
      }
    })
  }

  //Called when we click the cancel button on modal
  onCloseClick (): void {
    this.dialogRef.close()
  }

  //Called whenever we click the delete button on modal
  deletepopupButton () {
    this.removeFileFromList.emit(true)
  }
}
