import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { GetMetaService } from 'src/app/_services/get-meta.service';
import { SignInSignOutService } from 'src/app/_services/networkcalls/sign-in-sign-out.service';
import { PluginsService } from 'src/app/_services/plugins.service';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/_guards/auth.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';

@Component({
  selector: 'app-word-plugin-singin',
  templateUrl: './word-plugin-singin.component.html',
  styleUrls: ['./word-plugin-singin.component.scss']
})
export class WordPluginSinginComponent implements OnInit {
  bid: any;
  region: any;
  code: any;
  agnosticId: any;
  rftkn: any;
  allowedIPCk: any;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private activateRoute: ActivatedRoute,
    public loginservice: SignInSignOutService,
    private pluginService: PluginsService,
    public metaString: GetMetaService,
    public subjectSharedService: SubjectiveBehaviorSharedService,
    public amplitudeService: AmplitudeService,
    public auth: AuthService,
    public spinner: NgxSpinnerService,
    private sharedService: SharedService,

  ) {

  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(params => {
      this.rftkn = params["refresh-token"]
      this.bid = params["bid"]
      this.region = params["rg"]
      this.code = params["code"]
      this.agnosticId = params["profile_agnostic_id"]
      this.allowedIPCk = params["wips"]
      if (this.code && this.agnosticId) {
        let url = "trinka://oauth/?code=" + this.code + "&profile_agnostic_id=" + this.agnosticId + "&source=TRINKA&login=google"
        url = this.checkWipsCookiesPresent(url)
        window.location.href = url
      } else {
        let url = "trinka://oauth/?rftkn=" + this.rftkn + "&source=TRINKA&login=trinka_login"
        url = this.checkWipsCookiesPresent(url)
        window.location.href = url
      }
    })
  }

  clickForTrinka() {
    if (this.code && this.agnosticId) {
      let url = "trinka://oauth/?code=" + this.code + "&profile_agnostic_id=" + this.agnosticId + "&source=TRINKA&login=google"
      url = this.checkWipsCookiesPresent(url)
      window.location.href = url
    } else {
      let url = "trinka://oauth/?rftkn=" + this.rftkn + "&source=TRINKA&login=trinka_login"
      url = this.checkWipsCookiesPresent(url)
      window.location.href = url
    }
  }

  loginToTrinka() {
    window.location.href = "/signin";
    // var temp = {}
    // temp["code"] = this.code
    // temp["profile_agnostic_id"] = this.agnosticId
    // temp["source"] = "TRINKA";
    // this.loginservice.postSocialLogin(temp).subscribe(result => {
   
    //   const { token, refresh_token, token_type } = result
    //   const pluginPayload = { token, refresh_token, token_type }
    //   pluginPayload["email_id"] = btoa(result['email_id']);
    //   var newUser = result['is_new_user']
    //   this.pluginService.handleLogin(pluginPayload)
    //   this.storage.set("meta", result);
    //   this.sharedService.isSignIn = this.auth.isAuthenticated()
    //   this.amplitudeService.logAmplitudeWithoutUser('SignIn_by_Google', {
    //     'User ID': pluginPayload["email_id"]
    //   });
    //   this.getPreviousUrl(newUser);
    //   // this.router.navigate(['/dashboard']);
    // }, error => {
    //   this.amplitudeService.logAmplitudeWithoutUser('Error_SignIn', {
    //     "Error": true
    //   });
    //   let alert_message = error.error.message ? error.error.message : 'Something went wrong';
    //   // let alert_message = 'Something went wrong';
    //   switch (error.status) {
    //     case 401:
    //     case 403:
    //     case 400:
    //     case 404:
    //     case 409:
    //     case 500:
    //     case 502:
    //       break;
    //     default:
    //       // this.toastr.error("Please check your network connection and try again.", 'Error');
    //       break;
    //   }
    // });
  }

  getPreviousUrl(newuser) {
    this.subjectSharedService.getPreviousUrl.subscribe(prev => {
      if (this.auth.isAuthenticated()) {
        if (prev === '') {
          if (environment.trialMode) {
            window.location.href = "/editor/main/";
          }
          else {
            if (!newuser) {
              window.location.href = "/dashboard";
            }
            else {
              window.location.href = "/profile";
            }
          }
        } else {
          // this.router.navigate([prev])
          window.location.href = window.location.origin + prev;
        }
      }
    });
  }

  checkWipsCookiesPresent(url){
    return this.allowedIPCk != undefined ? url+ "&wips="+ this.allowedIPCk : url
  }

}
