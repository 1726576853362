<div class="publicationSubSection">
<mat-card class="journal_checks_section" *ngIf="!targetedJournal">
    <div class="journal_text_contents">
        <div class="journal_title_heading target_journal_section">
            <p>
                <span>Check 1</span> Target Journal
                <i alt="The similarity of your text to the target journal is shown, along with a judgment on whether the journal is a good match.">
                    <img src="../../../../assets/images/Icon_Info.svg" alt="">
                </i>
            </p>
        </div>
        <div class="journal_card_body">
                <div class="journal_title"><p>Target journal not provided</p></div>
        </div>
    </div>
</mat-card>
<mat-card class="journal_checks_section" *ngIf="targetedJournal">
    <span [ngClass]="journalScopeMatch?.status == 2 ? 'fail' : 'pass'"
        *ngIf="journalScopeMatch?.status != 4 && !isDocLocked" hidden>
        {{ journalScopeMatch?.status == 2 ? "Fail" : "Pass" }}
    </span>
    <span [ngClass]="journalScopeMatch?.status == 4 ? 'possible' : 'fail'" *ngIf="journalScopeMatch?.status == 4"
        hidden>
        {{ journalScopeMatch?.status == 4 ? "Not Applicable" : "Fail" }}
    </span>
    <div class="journal_text_contents">
        <div class="journal_title_heading target_journal_section">
            <p>
                <span>Check 1</span> Target Journal
                <i alt="The similarity of your text to the target journal is shown, along with a judgment on whether the journal is a good match.">
                    <img src="../../../../assets/images/Icon_Info.svg" alt="">
                </i>
            </p>
        </div>
        <div class="journal_card_body">
            <div class="journal_title">
                <p>{{journalScopeMatch?.scope?.name}}</p>
            </div>
            <div *ngIf="journalScopeMatch?.scope?.present" class="journalName_presents">
                <div
                    [ngClass]="{'lowpercent':journalScopeMatch?.scope?.score < 40,'average':journalScopeMatch?.scope?.score > 40 && journalScopeMatch?.scope?.score < 80,'percent':journalScopeMatch?.scope?.score > 80}">
                    <span class="colored" [innerHTML]="scorePercent(journalScopeMatch?.scope?.score)"></span>
                </div>
            </div>
            <div class="currently_not_avaibale_text" *ngIf="!journalScopeMatch?.scope?.present">
                <div class="journal_title dataNot_InDatabase">Currently not available in our database.</div>
            </div>
        </div>
    </div>
</mat-card>


<mat-card class="journal_checks_section" *ngIf="journalScopeMatch?.required_to_show">
    <span [ngClass]="journalScopeMatch?.status == 2 ? 'fail' : 'pass'"
        *ngIf="journalScopeMatch?.status != 4 && !isDocLocked" hidden>
        {{ journalScopeMatch?.status == 2 ? "Fail" : "Pass" }}
    </span>
    <span [ngClass]="journalScopeMatch?.status == 4 ? 'possible' : 'fail'" *ngIf="journalScopeMatch?.status == 4"
        hidden>
        {{ journalScopeMatch?.status == 4 ? "Not Applicable" : "Fail" }}
    </span>

    <div class="journal_text_contents"
        *ngIf="journalScopeMatch?.suggestions?.length > 0 || journalScopeMatch?.mega_suggestions?.length > 0">
        <div class="journal_title_heading recommended_journal_sections" *ngIf=targetedJournal>
            <p>
                <span>Check 2</span> Alternative Journal(s)
                <i alt="The top 3 best matched journals in our database for your text. If the score of the top journal is less than 50%, it means that our database does not contain any journal particularly well suited for the manuscript."><img
                    src="../../../../assets/images/Icon_Info.svg" alt=""></i>
            </p>
        </div>
        <div class="journal_title_heading recommended_journal_sections" *ngIf=!targetedJournal>
            <p>
                <span>Check 2</span> Recommended Journal(s)
                <i alt="The top 3 best matched journals in our database for your text. If the score of the top journal is less than 50%, it means that our database does not contain any journal particularly well suited for the manuscript."><img
                        src="../../../../assets/images/Icon_Info.svg" alt=""></i>
            </p>
        </div>
        <div class="journal_card_body" *ngFor="let journal of journalScopeMatch?.suggestions">
            <mat-chip hidden>Check 2</mat-chip>
            <div class="journal_title" [ngClass]="{'lowpercent':journal.score < 40,'average':journal.score > 40 && journal.score < 80,'percent':journal.score > 80}">
                <p>{{ journal.name }}</p>
            </div>
            <div class="percentage_color_journal"
                [ngClass]="{'lowpercent':journal.score < 40,'average':journal.score > 40 && journal.score < 80,'percent':journal.score > 80}">
                <span class="journal_match_name" [innerHTML]="scorePercent(journal.score)"></span>
            </div>
        </div>
    </div>
</mat-card>

<mat-card class="journal_checks_section" *ngIf="!journalScopeMatch?.required_to_show">
    <span [ngClass]="journalScopeMatch?.status == 2 ? 'fail' : 'pass'"
        *ngIf="journalScopeMatch?.status != 4 && !isDocLocked" hidden>
        {{ journalScopeMatch?.status == 2 ? "Fail" : "Pass" }}
    </span>
    <span [ngClass]="journalScopeMatch?.status == 4 ? 'possible' : 'fail'" *ngIf="journalScopeMatch?.status == 4"
        hidden>
        {{ journalScopeMatch?.status == 4 ? "Not Applicable" : "Fail" }}
    </span>

    <div class="journal_text_contents">
        <div class="journal_title_heading recommended_journal_sections" *ngIf=!targetedJournal>
            <p>
                <span>Check 2</span> Recommended Journal(s)
                <i alt="The top 3 best matched journals in our database for your text. If the score of the top journal is less than 50%, it means that our database does not contain any journal particularly well suited for the manuscript."><img
                    src="../../../../assets/images/Icon_Info.svg" alt=""></i>
            </p>
        </div>
        <div class="journal_card_body">
            <div class="heading_title">
                <p>Journal Recommendation is not yet available for your subject area. </p>
            </div>
        </div>
    </div>
</mat-card>

</div>