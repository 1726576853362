<ngx-spinner></ngx-spinner>

<div class="viewReferenceWrap">

  <header class="header-wrapper">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-6">
          <div class="logo">
            <img src="../../../assets/images/Trinka_Logo.svg" alt="logo" [routerLink]="['/dashboard']"/>
            <span class="tags-box" *ngIf="!paymentDetails?.is_premium">Free</span>
            <span class="tags-box-premium" *ngIf="paymentDetails?.is_premium">Premium</span>
          </div>
        </div>
        <div class="col-6">
          <div class="media media-credits">
            <div class="media-body">
              <p>
                <img src="../../../assets/images/reference-quality/Vector.svg" alt="vector" />
                Credits: <strong>
                  <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits != 0"
                    (click)="credits()">{{ creditsDetails.credits + creditsDetails.purchased_credits || 0 }}</span>
                  <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0"
                    (click)="credits()">Nil</span>
                  <span *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0
                  " class="autoEditBuyOption" (click)="addCredits()">Buy</span>

                </strong>
              </p>
            </div>
            <div class="media-right">
              <button mat-icon-button [matMenuTriggerFor]="menu1" class="p-0">
                <span class="name-initial">{{profileName}}</span>
              </button>
              <mat-menu #menu1="matMenu" class="profile-menu personalize_top_menus">
                <a mat-menu-item [routerLink]="['/dashboard/profile']" class="tx-black">
                    <img class="img-fluid" src="../../../../assets/images/Icon_ManageProfile.svg"
                        alt="logo favicon">
                    <span> Account Settings</span>
                </a>
                <button mat-menu-item (click)="signOut()"><img class="img-fluid"
                        src="../../../../assets/images/Icon_Logout.svg" alt="logo favicon">
                    Logout
                  </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="sub-header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-9">
          <div class="media align-items-center">
            <div class="media-left">
              <div class="qualityScoreSection">
                <svg viewBox="0 0 36 36" class="circular-chart" [ngClass]="qualitySummary?.good?.percentage>80 ? 'green': 'red'">
                  <path class="circle-bg"
                    d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <path class="circle"
                  [attr.stroke-dasharray] = "qualitySummary?.good?.percentage+',100'"
                    d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <text x="10" y="22" class="percentage" style="font-size: 11px; font-weight: 600;" *ngIf="qualitySummary?.good?.percentage<=9">{{qualitySummary?.good?.percentage | number:'.0-0'}}%</text>
                  <text x="9" y="22" class="percentage" style="font-size: 10px;font-weight: 600;" *ngIf="qualitySummary?.good?.percentage<=99 && qualitySummary?.good?.percentage>9">{{qualitySummary?.good?.percentage | number:'.0-0'}}%</text>
                  <text x="8" y="21" class="percentage" style="font-size: 8px;font-weight: 600;" *ngIf="qualitySummary?.good?.percentage>=100">{{qualitySummary?.good?.percentage | number:'.0-0'}}%</text>
                </svg>
              </div>
            </div>
            <div class="media-body">
              <div class="section-title">
                <h3>Citation Checker Report</h3>
                <p> {{fileName}} <span *ngIf="sampleDocId!=documentId">| Generated on: {{data.created_on | date: "medium" }} </span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="search-box">
            <a (click)="backToReferenceQuality()"[routerLink]="['/citation-check-tool']">Back to reports</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-first section-md">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <ul class="nav nav-pills reference-tabs">
            <li class="nav-item" (click)="hideTopProgress(7)">
              <a class="nav-link big active" data-toggle="pill" href="#Overview">Overview</a>
            </li>
            <li class="nav-item" (click)="hideTopProgress(0)">
              <a class="nav-link big" data-toggle="pill" href="#CitationsNeedAttention">Citations need Review</a>
            </li>
            <li class="nav-item" (click)="hideTopProgress(1)">
              <a class="nav-link" data-toggle="pill" href="#RetractedReferences">Retracted
                <span>{{retractedRefSum.count || 0}}</span></a>
            </li>
            <li class="nav-item" (click)="hideTopProgress(2)">
              <a class="nav-link" data-toggle="pill" href="#OldReferences">Published Long Ago
                <span>{{oldRefSum.count|| 0}}</span></a>
            </li>
            <li class="nav-item" (click)="hideTopProgress(3)">
              <a class="nav-link" data-toggle="pill" href="#JournalOveruse">Journal Bias
                <span>{{journalBiasCount || 0}}</span></a>
            </li>
            <li class="nav-item" (click)="hideTopProgress(4)">
              <a class="nav-link" data-toggle="pill" href="#NonStandardReferences">Non-Standard
                <span>{{nonStdRefSum.count || 0}}</span></a>
            </li>
            <!-- <li class="nav-item" (click)="hideTopProgress(5)">
              <a class="nav-link" data-toggle="pill" href="#PoorCitations">Poorly Cited
                <span>{{poorlyCitedRefSum.count || 0}}</span></a>
            </li> -->
            <li class="nav-item" (click)="hideTopProgress(6)">
              <a class="nav-link big" data-toggle="pill" href="#goodCitations">Good-to-use Citations</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-8">
          <div class="tab-content">
            <!-- Overview-->
            <div class="tab-pane active" id="Overview">
              <div class="shine-loader" [ngClass]="critialRef.length > 0 ? 'shine_loader_hide' : ''">
                <div class="full-shine">
                  <span class="shine lines"></span>
                </div>
                <span class="shine photo shine-lg"></span>
                <section class="row">
                  <section class="col-6">
                    <span class="shine photo shine-md"></span>
                  </section>
                  <section class="col-6">
                    <span class="shine photo shine-md"></span>
                  </section>
                  <section class="col-6">
                    <span class="shine photo shine-md"></span>
                  </section>
                  <section class="col-6">
                    <span class="shine photo shine-md"></span>
                  </section>
                </section>
                <br>
              </div>
              <div class="panel-card">
                <h3>Citation Analysis Overview</h3>
                <div class="row match-box justify-content-center">
                  <div class="col-12">
                    <div class="fusion-chart">
                      <fusioncharts
                        width="100%"
                        height="350"
                        type="column2d"
                        dataFormat="json"
                        [dataSource]="dataSource"
                      >
                      </fusioncharts>
                    </div>
                  </div>
                </div>
                <div class="row match-box justify-content-center">
                  <div class="col-sm-12 col-md-12 col-lg-6 col-12 dflex">
                    <div class="overview-card c-card high-border">
                      <div class="media">
                        <div class="media-left">
                          <div class="overview-right">
                            <h6 class="tx-high">{{retractedRefSum.count + nonStdRefSum.count}}</h6>
                            <h1>High <br/>Risk</h1>
                          </div>
                        </div>
                        <div class="media-body high-border">
                          <div class="row">
                            <div class="col-6">
                              <div class="overview-count">
                                <h3>{{retractedRefSum.count}}</h3>
                                <span>Retracted </span>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="overview-count">
                                <h3>{{nonStdRefSum.count}}</h3>
                                <span>Non-Standard</span>
                              </div>
                            </div>
                          </div>
                          <p>These citations highly likely weaken the strength of argumentation and must be replaced with credible alternatives.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 col-12 dflex">
                    <div class="overview-card c-card medium-border">
                      <div class="media">
                        <div class="media-left">
                          <div class="overview-right">
                            <h6 class="tx-medium">{{oldRefSum.count + poorlyCitedRefSum.count}}</h6>
                            <h1>Medium <br/> Risk</h1>
                          </div>
                        </div>
                        <div class="media-body medium-border">
                          <div class="row">
                            <div class="col-12">
                              <div class="overview-count">
                                <h3>{{oldRefSum.count}}</h3>
                                <span>Old </span>
                              </div>
                            </div>
                            <!-- <div class="col-6">
                              <div class="overview-count">
                                <h3>{{poorlyCitedRefSum.count}}</h3>
                                <span>Poorly Cited</span>
                              </div>
                            </div> -->
                          </div>
                          <p>These citations may weaken your argumentation. We highly recommend replacing with better alternatives.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row match-box justify-content-center">
                  <div class="col-sm-12 col-md-12 col-lg-6 col-12 dflex">
                    <div class="overview-card c-card low-border">
                      <div class="media">
                        <div class="media-left">
                          <div class="overview-right">
                            <h6 class="tx-low">{{journalBiasCount}}</h6>
                            <h1>Low <br/>Risk</h1>
                          </div>
                        </div>
                        <div class="media-body low-border">
                          <div class="row">
                            <div class="col-12">
                              <div class="overview-count">
                                <h3>{{journalBiasCount}}</h3>
                                <span>Journal Bias </span>
                              </div>
                            </div>
                          </div>
                          <p>These citations may not adequately support your argumentation. Do review and replace with better alternative where possible.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 col-12 dflex">
                    <div class="overview-card c-card success-border">
                      <div class="media">
                        <div class="media-left">
                          <div class="overview-right">
                            <h6 class="tx-success">{{goodRef.length}}</h6>
                            <h1>No <br/>Risk</h1>
                          </div>
                        </div>
                        <div class="media-body success-border">
                          <div class="row">
                            <div class="col-12">
                              <div class="overview-count">
                                <h3>{{goodRef.length}}</h3>
                                <span>Good to use </span>
                              </div>
                            </div>
                          </div>
                          <p>Our Al could not find any issues for these citations. You may review to ensure that these are credible for your research context.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- CitationsNeedAttention -->
            <div class="tab-pane" id="CitationsNeedAttention">
              <div class="shine-loader" [ngClass]="critialRef.length > 0 ? 'shine_loader_hide' : ''">
                <div class="full-shine">
                  <span class="shine lines"></span>
                </div>
                <br/>
                <span class="shine box"></span>
                <div>
                  <span class="shine lines"></span>
                  <span class="shine lines"></span>
                  <span class="shine lines"></span>
                </div>
                <span class="shine photo"></span>
                <span class="shine photo"></span>
                <br>
              </div>
              <div class="panel-card">
                <h3>{{critialRef.length}} out of {{noOfRef}} total citations need attention</h3>
                <div *ngIf="critialRef.length > 0">
                <div id="accordion" class="rf-accordion">

                  <div class="card" *ngFor="let critRef of critialRef; let i=index">
                    <div class="card-header">
                      <a class="card-link" data-toggle="collapse" href="#collapseOne{{i}}" [ngClass]="critRef['quality_metrics']['is_non_std_reference'] ? 'no-cursor': ''">
                        <ul class="icon-list">
                          <li>{{critRef["text"]}}</li>
                        </ul>
                      </a>
                    </div>

                    <div class="checklist-tags">
                      <ul *ngIf="!critRef['quality_metrics']['is_non_std_reference']">
                        <li class="badge high-risk" *ngIf="critRef['quality_metrics']['is_retracted']">Retracted after publication</li>
                        <li class="badge medium-risk " *ngIf="critRef['quality_metrics']['is_old_reference']">Published {{currentYear - critRef['crossref_response']['published_year']}} years ago</li>
                        <li class="badge low-risk" *ngIf="critRef['quality_metrics']['is_journal_overuse']">Publishing journal used too many times</li>
                        <li class="badge medium-risk" *ngIf="critRef['quality_metrics']['is_poorly_cited']">Cited less than 3 times in a year</li>
                      </ul>
                      <ul *ngIf="critRef['quality_metrics']['is_non_std_reference']">
                        <li class="badge high-risk" *ngIf="critRef['quality_metrics']['is_non_std_reference']">Not a standard, reviewed publication</li>
                      </ul>
                    </div>
                    <div id="collapseOne{{i}}" class="collapse" *ngIf="!critRef['quality_metrics']['is_non_std_reference']">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <p class="pc-para pc-title"><b>Title:</b><a href='{{critRef["crossref_response"]["URL"]}}' target="_blank"><span> {{critRef["crossref_response"]["title"]}}</span></a></p>
                          </div>
                          <div class="col-sm-12" *ngIf='critRef["crossref_response"]["journal_name"]'>
                            <p class="pc-para"><b>Journal:</b><span> {{critRef["crossref_response"]["journal_name"]}}</span>
                            </p>
                          </div>
                          <div class="col-sm-12" *ngIf='critRef["crossref_response"]["author"]'>
                            <p class="pc-para"><b>Author:</b><span class="popover-item"> {{critRef["crossref_response"]["author"]}}
                              <div class="popover-content">
                                <ul *ngFor="let author of critRef['crossref_response']['authors']">
                                  <li>{{author}}</li>
                                </ul>
                              </div>
                            </span>
                            </p>
                          </div>
                          <div class="col-12">
                            <p class="pc-para"><b>Published:</b><span> {{critRef["crossref_response"]["published_year"]}}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div *ngIf="critialRef.length==0" class="no_reference">No citations need attention were found in your document.</div>
              </div>
            </div>
            <!-- CitationsNeedAttention Ends-->

            <!-- RetractedReferences -->
            <div class="tab-pane" id="RetractedReferences">
              <div class="panel-card">
                <h3>Retracted: {{retractedRefSum.count}} citations out of {{noOfRef}} <span class="badge high-risk">High
                    Risk</span></h3>
                <div class="warning mt-4 mb-4">
                  <div class="media">
                    <div class="media-left">
                      <img src="../../../assets/images/Icon_Info.svg" alt="info">
                    </div>
                    <div class="media-body">
                      <p>A retracted citation is one that was published by a journal but is later recalled for research quality, integrity, or other ethical reasons. You should never cite a retracted paper as that seriously impacts the credibility of your argumentation.</p>
                    </div>
                  </div>
                </div>
                <div class="danger-outline mb-4 ">
                  Action Required: You will have to remove or replace these citations with more credible articles
                </div>
                <div *ngIf="retractedRefSum.count>0">
                <div id="accordion" class="rf-accordion">

                  <div class="card" *ngFor="let retractedRef of reportTagWiseData['retracted_refs']; let i=index">
                    <div class="card-header">
                      <a class="card-link" data-toggle="collapse" href="#collapseFour{{i}}" (click)="viewDetails('Retracted')">
                        <ul class="icon-list">
                          <li> {{retractedRef['text']}}</li>
                        </ul>
                      </a>
                    </div>
                    <div class="checklist-tags">
                      <ul [ngClass]="{'limitText': isReadMore}">
                        <li class="badge high-risk" *ngIf="retractedRef['quality_metrics']['is_retracted']">Retracted after publication</li>
                        <li class="badge medium-risk hide-li" *ngIf="retractedRef['quality_metrics']['is_old_reference']">Published {{currentYear - retractedRef['crossref_response']['published_year']}} years ago</li>
                        <li class="badge low-risk hide-li" *ngIf="retractedRef['quality_metrics']['is_journal_overuse']">Publishing journal used too many times</li>
                        <li class="badge high-risk hide-li" *ngIf="retractedRef['quality_metrics']['is_non_std_reference']">Not a standard, reviewed publication</li>
                        <li class="badge medium-risk hide-li" *ngIf="retractedRef['quality_metrics']['is_poorly_cited']">Cited less than 3 times in a year</li>
                        <li *ngIf="retractedRef['tagCount']>1"><a href="javascript:void(0)" class="read-more" (click)="showText($event, retractedRef['tagCount'])" ><span>{{ '+'+(retractedRef['tagCount']-1) +' More'}}</span></a></li>
                      </ul>
                    </div>
                    <div id="collapseFour{{i}}" class="collapse" data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <p class="pc-para pc-title"><b>Title:</b> <a href='{{retractedRef["crossref_response"]["URL"]}}' target="_blank"><span> {{retractedRef['crossref_response']['title']}}</span></a></p>
                          </div>
                          <div class="col-sm-12" *ngIf="retractedRef['crossref_response']['journal_name']">
                            <p class="pc-para"><b>Journal:</b><span> {{retractedRef['crossref_response']['journal_name']}}</span>
                            </p>
                          </div>
                          <div class="col-sm-12" *ngIf="retractedRef['crossref_response']['author']">
                            <p class="pc-para"><b>Author:</b><span class="popover-item"> {{retractedRef['crossref_response']['author'] }}
                              <div class="popover-content">
                                <ul *ngFor="let author of retractedRef['crossref_response']['authors']">
                                  <li>{{author}}</li>
                                </ul>
                              </div>
                            </span>
                            </p>
                          </div>
                          <div class="col-12">
                            <p class="pc-para"><b>Published:</b><span> {{retractedRef['crossref_response']['published_year']}}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div *ngIf="retractedRefSum.count==0" class="no_reference">No retracted citations were found in your document.</div>
              </div>
            </div>
            <!-- RetractedReferences Ends-->

            <!-- OldReferences -->
            <div class="tab-pane" id="OldReferences">
              <div class="panel-card">
                <h3>Published Long Ago: {{oldRefSum.count}} citations out of {{noOfRef}} <span
                    class="badge medium-risk">Medium Risk</span></h3>
                <div class="warning mt-4 mb-4">
                  <div class="media">
                    <div class="media-left">
                      <img src="../../../assets/images/Icon_Info.svg" alt="info">
                    </div>
                    <div class="media-body">
                      <p>An old citations is one that was published over 10 years before the year of creation of this report. Journals prefer newer citations as they reflect the current status of research on the topic. Note that some old citations may be seminal works and hence would be acceptable (groundbreaking works).</p>
                    </div>
                  </div>
                </div>
                <div class="danger-outline mb-4 ">
                  Action Required: Review and replace these citations with newer citations published in peer-reviewed
                  journals.
                </div>
                <div *ngIf="oldRefSum.count>0">
                <div id="accordion" class="rf-accordion">

                  <div class="card" *ngFor="let ref of reportTagWiseData['old_refs']; let i=index">
                    <div class="card-header">
                      <a class="card-link" data-toggle="collapse" href="#collapseSeven{{i}}" (click)="viewDetails('Old')">
                        <ul class="icon-list">
                          <li>{{ref['text']}} </li>
                        </ul>
                      </a>
                    </div>
                  
                    <div class="checklist-tags">
                      <ul class="limitText">
                        <li class="badge medium-risk " *ngIf="ref['quality_metrics']['is_old_reference']">Published {{currentYear - ref['crossref_response']['published_year']}} years ago</li>
                        <li class="badge high-risk hide-li" *ngIf="ref['quality_metrics']['is_retracted']">Retracted after publication</li>
                        <li class="badge low-risk hide-li" *ngIf="ref['quality_metrics']['is_journal_overuse']">Publishing journal used too many times</li>
                        <li class="badge high-risk hide-li" *ngIf="ref['quality_metrics']['is_non_std_reference']">Not a standard, reviewed publication</li>
                        <li class="badge medium-risk hide-li" *ngIf="ref['quality_metrics']['is_poorly_cited']">Cited less than 3 times in a year</li>
                        <li *ngIf="ref['tagCount']>1"><a href="javascript:void(0)" class="read-more" (click)="showText($event, ref['tagCount'])" ><span>{{ '+'+(ref['tagCount']-1) +' More'}}</span></a></li>
                      </ul>
                    </div>
                    <div id="collapseSeven{{i}}" class="collapse" data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <p class="pc-para pc-title"><b>Title:</b><a href='{{ref["crossref_response"]["URL"]}}' target="_blank"><span> {{ref['crossref_response']['title']}}</span></a></p>
                          </div>
                          <div class="col-sm-12" *ngIf="ref['crossref_response']['journal_name']">
                            <p class="pc-para"><b>Journal:</b><span> {{ref['crossref_response']['journal_name']}}</span>
                            </p>
                          </div>
                          <div class="col-sm-12" *ngIf="ref['crossref_response']['author']">
                            <p class="pc-para"><b>Author:</b><span class="popover-item"> {{ref['crossref_response']['author']}}
                              <div class="popover-content">
                                <ul *ngFor="let author of ref['crossref_response']['authors']">
                                  <li>{{author}}</li>
                                </ul>
                              </div>
                            </span>
                          </p>
                          </div>
                          <div class="col-12">
                            <p class="pc-para"><b>Published:</b><span> {{ref['crossref_response']['published_year']}}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                </div>
                <div *ngIf="oldRefSum.count==0" class="no_reference">No published long ago citations were found in your document.</div>
              </div>
            </div>
            <!-- OldReferences Ends-->

            <!-- JournalOveruse -->
            <div class="tab-pane" id="JournalOveruse">
              <div class="panel-card">
                <h3>Journal Bias: {{journalBiasTotalPerc}}% of citations are from
                  {{journalBiasCount}} Journals <span class="badge low-risk">Low Risk</span></h3>
                <div class="warning mt-4 mb-4">
                  <div class="media">
                    <div class="media-left">
                      <img src="../../../assets/images/Icon_Info.svg" alt="info">
                    </div>
                    <div class="media-body">
                      <p>Journal bias implies citing too many papers from a particular journal. This could be considered unethical practice to favour a particular journal. The report below helps you identify which journals have been cited too many times.</p>
                    </div>
                  </div>
                </div>
                <div class="danger-outline mb-4 ">
                  Action Required: Review every citation and replace with credible alternatives from other peer-reviewed
                  journals.
                </div>
                <div *ngIf="journalOveruseSum.count > 0">

                <div id="accordion1" class="rf-accordion">
                  <!-- Panel 1-->
                  <div class="card journal-card" *ngFor="let journal_name of reportTagWiseData['journal_overuse'];let i=index">
                    <div class="card-header journal-header">
                      <a class="card-link collapsed" data-toggle="collapse" href="#JournalOne{{i}}">
                        <h3> {{journal_name.name}}</h3>
                        <p>{{journal_name.count}} <span style="color:#4D4D4D;padding-right: 6px;" *ngIf="journal_name.count>1 || journal_name.count ==0">Citations</span> <span style="color:#4D4D4D;padding-right: 6px;" *ngIf="journal_name.count ==1">Citation</span>
                           <span>({{ math.trunc((journal_name.count/noOfRef)*100)}}% of all citations)</span></p>
                        <span class="icon"><i class="fa fa-caret-up"></i></span>
                      </a>
                    </div>
                    <div id="JournalOne{{i}}" class="collapse" data-parent="#accordion1">
                      <div class="card-body">
                        <div id="accordion" class="rf-accordion">

                          <div class="card" *ngFor="let journal_ref of journal_name['references'];let j=index">
                            <div class="card-header">
                              <a class="card-link" data-toggle="collapse" href="#collapseTen{{j}}" (click)="viewDetails('J.overuse')">
                                <ul class="icon-list">
                                  <li>{{journal_ref['text']}} </li>
                                </ul>
                              </a>
                            </div>
                          
                            <div class="checklist-tags">
                              <ul [ngClass]="{'limitText': isReadMore}">
                                <li class="badge low-risk " *ngIf="journal_ref['quality_metrics']['is_journal_overuse']">Publishing journal used too many times</li>
                                <li class="badge medium-risk hide-li" *ngIf="journal_ref['quality_metrics']['is_old_reference']">Published {{currentYear - journal_ref['crossref_response']['published_year']}} years ago</li>
                                <li class="badge high-risk hide-li" *ngIf="journal_ref['quality_metrics']['is_retracted']">Retracted after publication</li>
                                <li class="badge high-risk hide-li" *ngIf="journal_ref['quality_metrics']['is_non_std_reference']">Not a standard, reviewed publication</li>
                                <li class="badge medium-risk hide-li" *ngIf="journal_ref['quality_metrics']['is_poorly_cited']">Cited less than 3 times in a year</li>
                                <li *ngIf="journal_ref['tagCount']>1"><a href="javascript:void(0)" class="read-more" (click)="showText($event, journal_ref['tagCount'])" ><span>{{ '+'+(journal_ref['tagCount']-1) +' More'}}</span></a></li>
                              </ul>
                            </div>
                            <div id="collapseTen{{j}}" class="collapse" data-parent="#accordion">
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-12">
                                    <p class="pc-para pc-title"><b>Title:</b><a href='{{journal_ref["crossref_response"]["URL"]}}' target="_blank"><span> {{journal_ref['crossref_response']['title']}}</span></a></p>
                                  </div>
                                  <div class="col-sm-12" *ngIf="journal_ref['crossref_response']['journal_name']">
                                    <p class="pc-para"><b>Journal:</b><span> {{journal_ref['crossref_response']['journal_name']}}</span>
                                    </p>
                                  </div>
                                  <div class="col-sm-12" *ngIf="journal_ref['crossref_response']['author']">
                                    <p class="pc-para"><b>Author:</b><span  class="popover-item"> {{journal_ref['crossref_response']['author']}}
                                      <div class="popover-content">
                                        <ul *ngFor="let author of journal_ref['crossref_response']['authors']">
                                          <li>{{author}}</li>
                                        </ul>
                                      </div>
                                    </span>
                                  </p>
                                  </div>
                                  <div class="col-12">
                                    <p class="pc-para"><b>Published:</b><span> {{journal_ref['crossref_response']['published_year']}}</span></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Panel 1 Ends-->
                </div>
                </div>
                <div *ngIf="journalOveruseSum.count==0" class="no_reference">No journal bias citations were found in your document.</div>


              </div>
            </div>
            <!-- JournalOveruse Ends-->

            <!-- NonStandardReferences -->
            <div class="tab-pane" id="NonStandardReferences">
              <div class="panel-card">
                <h3>Non-Standard: {{nonStdRefSum.count}} citations out of {{noOfRef}} <span
                    class="badge high-risk">High Risk</span></h3>
                <div class="warning mt-4 mb-4">
                  <div class="media">
                    <div class="media-left">
                      <img src="../../../assets/images/Icon_Info.svg" alt="info">
                    </div>
                    <div class="media-body">
                      <p>A non-standard citations is one that is not an appropriately reviewed and published citations. This includes citations of personal communication, internet articles, discussions on forums, and similar non-reviewed sources. Journals prefer citations of standard published citations such as journal articles, books, and similar reviewed works. Only cite standard citations to the extent possible to avoid peer review critique.</p>
                    </div>
                  </div>
                </div>
                <div class="danger-outline mb-4 ">
                  Action Required: Replace these citations with standard citations such as published journal articles
                  or books.
                </div>
                <div *ngIf="nonStdRefSum.count>0">
                <div class="rf-accordion">

                  <div class="card" *ngFor="let nonStdRef of reportTagWiseData['non_std_refs'];let i=index">
                    <div class="card-header">
                      <ul class="icon-list">
                        <li class="tx-14">{{nonStdRef['text']}}</li>
                      </ul>
                    </div>

                    <div class="checklist-tags">
                      <ul [ngClass]="{'limitText': isReadMore}">
                        <li class="badge high-risk " *ngIf="nonStdRef['quality_metrics']['is_non_std_reference']">Not a standard, reviewed publication</li>
                      </ul>
                    </div>
                  </div>

                </div>
                </div>
                <div *ngIf="nonStdRefSum.count==0" class="no_reference">No non standard citations were found in your document.</div>
              </div>
            </div>
            <!-- NonStandardReferences Ends-->

            <!-- PoorCitations -->
            <div class="tab-pane" id="PoorCitations">
              <div class="panel-card">
                <h3>Poorly Cited: {{poorlyCitedRefSum.count}} citations out of {{noOfRef}} <span
                    class="badge medium-risk">Medium Risk</span></h3>
                <div class="warning mt-4 mb-4">
                  <div class="media">
                    <div class="media-left">
                      <img src="../../../assets/images/Icon_Info.svg" alt="info">
                    </div>
                    <div class="media-body">
                      <p>Poorly cited are those which have not been frequently cited by other authors,
                        assessed as <3 citations per year since publication. These may not add much credibility to your
                        argumentation. However, newly published citations may not gather many citations; assess the
                        report accordingly. </p>
                    </div>
                  </div>
                </div>
                <div class="danger-outline mb-4 ">
                  Action Required: Review these citations and replace with credible alternatives from other
                  peer-reviewed journals.
                </div>
                <div *ngIf="poorlyCitedRefSum.count>0">
                <div id="accordion" class="rf-accordion">

                  <div class="card" *ngFor="let poorRef of reportTagWiseData['poorly_cited_refs'];let i =index">
                    <div class="card-header">
                      <a class="collapsed card-link" data-toggle="collapse" href="#collapseSixteen{{i}}" (click)="viewDetails('poor')">
                        <ul class="icon-list">
                          <li>{{poorRef['reference']['text']}} </li>
                        </ul>
                      </a>
                    </div>

                    <div class="checklist-tags">
                      <ul class="limitText">
                        <li class="badge medium-risk"  *ngIf="poorRef['reference']['quality_metrics']['is_poorly_cited']">Cited less than 3 times in a year</li>
                        <li class="badge medium-risk hide-li" *ngIf="poorRef['reference']['quality_metrics']['is_old_reference']">Published {{currentYear - poorRef['reference']['crossref_response']['published_year']}} years ago</li>
                        <li class="badge high-risk hide-li" *ngIf="poorRef['reference']['quality_metrics']['is_retracted']">Retracted after publication</li>
                        <li class="badge low-risk hide-li" *ngIf="poorRef['reference']['quality_metrics']['is_journal_overuse']">Published Journal used to many times</li>
                        <li class="badge high-risk hide-li" *ngIf="poorRef['reference']['quality_metrics']['is_non_std_reference']">Not a standard, reviewed publication</li>
                        <li *ngIf="poorRef['tagCount']>1"><a href="javascript:void(0)" class="read-more" (click)="showText($event, poorRef['tagCount'])" ><span>{{ '+'+(poorRef['tagCount']-1) +' More'}}</span></a></li>
                      </ul>
                    </div>
                    <div id="collapseSixteen{{i}}" class="collapse" data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <p class="pc-para pc-title"><b>Title:</b><a href='{{poorRef["reference"]["crossref_response"]["URL"]}}' target="_blank"><span> {{poorRef['reference']['crossref_response']['title']}}</span></a></p>
                          </div>
                          <div class="col-sm-12" *ngIf="poorRef['reference']['crossref_response']['journal_name']">
                            <p class="pc-para"><b>Journal:</b><span> {{poorRef['reference']['crossref_response']['journal_name']}}</span>
                            </p>
                          </div>
                          <div class="col-sm-12"  *ngIf="poorRef['reference']['crossref_response']['author']">
                            <p class="pc-para"><b>Author:</b><span class="popover-item"> {{poorRef['reference']['crossref_response']['author']}}
                              <div class="popover-content">
                                <ul *ngFor="let author of poorRef['reference']['crossref_response']['authors']">
                                  <li>{{author}}</li>
                                </ul>
                              </div>
                            </span>
                          </p>
                          </div>
                          <div class="col-12">
                            <p class="pc-para"><b>Published:</b><span> {{poorRef['reference']['crossref_response']['published_year']}}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="view-papers" *ngIf="poorRef['citation_count']>0">
                      <a href="#viewPapersModal" data-toggle="modal" data-target="#viewPapersModal"
                        (click)="setPoorCitationDetails(poorRef['reference'])">View papers citing this study</a>
                    </div>
                  </div>

                </div>
                </div>
                <div *ngIf="poorlyCitedRefSum.count==0" class="no_reference">No poorly citated citations were found in your document.</div>
              </div>
            </div>
            <!-- PoorCitations Ends-->

            <!-- goodCitations -->
            <div class="tab-pane" id="goodCitations">
              <div class="panel-card">
                <h3>{{goodRef.length}} citations out of {{noOfRef}} are good to use</h3>
                <div *ngIf="goodRef.length > 0">
                <p class="good-msg" *ngIf="goodRef.length > 0">These citations are good to use in your document.</p>
                <div id="accordion" class="rf-accordion">

                  <div class="card" *ngFor="let gref of goodRef;let i=index">
                    <div class="card-header">
                      <a class="collapsed card-link" data-toggle="collapse" href="#collapseSixteen{{i}}">
                        <ul class="icon-list">
                          <li>{{gref["text"]}}</li>
                        </ul>
                      </a>
                    </div>
                    <div id="collapseSixteen{{i}}" class="collapse" data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <p class="pc-para pc-title"><b>Title:</b><a href='{{gref["crossref_response"]["URL"]}}' target="_blank"><span> {{gref["crossref_response"]["title"]}}</span></a></p>
                          </div>
                          <div class="col-sm-12"  *ngIf='gref["crossref_response"]["journal_name"]'>
                            <p class="pc-para"><b>Journal:</b><span> {{gref["crossref_response"]["journal_name"]}}</span>
                            </p>
                          </div>
                          <div class="col-sm-12" *ngIf='gref["crossref_response"]["author"]'>
                            <p class="pc-para"><b>Author:</b><span class="popover-item"> {{gref["crossref_response"]["author"]}}

                              <div class="popover-content">
                                <ul *ngFor="let author of gref['crossref_response']['authors']">
                                  <li>{{author}}</li>
                                </ul>
                              </div>
                            </span>
                          </p>
                          </div>
                          <div class="col-12">
                            <p class="pc-para"><b>Published:</b><span> {{gref["crossref_response"]["published_year"]}}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                </div>
                <div *ngIf="goodRef.length==0" class="no_reference">No good-to-use citations were found in your document.</div>
              </div>
            </div>
            <!-- goodCitations Ends-->


          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- The viewPapersModal -->
  <div class="modal" id="viewPapersModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <div class="md-title">
            <h3>Papers Citing this study</h3>
            <p>Title: <span>{{setCurrentSelectedPoorCitation != null ? setCurrentSelectedPoorCitation['crossref_response']['title'] : ""}}</span></p>
          </div>
          <div class="md-modal-content" (scroll)="onScroll($event)">
            <div class="modal-inner-wrapper">
              <div class="row" *ngFor="let data of processedPoorlyCited">
                <div class="col-12">
                  <div class="media">
                    <div class="media-left">
                      <i class="fa fa-circle" aria-hidden="true"></i>
                    </div>
                    <div class="media-body">
                      <h3>{{data["title"]}}</h3>
                      <ul>
                        <li *ngIf='data["author"]'>Author: <span> {{data["author"]}}</span></li>
                        <li>Published: <span>{{data["published_year"]}}</span></li>
                        <li  *ngIf='data["journal_name"]'>Journal: <span>{{data["journal_name"]}}</span></li>
                        <li><a href="{{data['URL']}}" target="_blank">View Article</a></li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div> <!-- The viewPapersModal  Ends -->
</div>