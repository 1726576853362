import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { JournalFinderService } from "src/app/_services/journal.finder.service";
import { SharedService } from "src/app/_services/shared.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DashboardService } from "src/app/_services/networkcalls/dashboard.service";
import { AmplitudeService } from "src/app/_services/amplitude.service";
import { SubjectiveBehaviorSharedService } from "src/app/_services/subjective-behavior-shared.service";
import { OnlineEditorService } from "src/app/_services/networkcalls/online-editor.service";
import { environment } from "src/environments/environment";
import { CreditDetailsComponent } from "../../dashboard/credit-details/credit-details.component";
import { AutoRef } from "src/app/_enums/auto-refer.enum copy";
import { ReferralRewardModalComponent } from "../../dashboard/referral-reward-modal/referral-reward-modal.component";

declare function wisepop(): any;
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {


  creditsDetails: any = {};
  userId: any;
  emailId: string;
  processedAbstractList : any = [];
  userData: any;
  paymentDetails: any;
  selectedProfile:any = {};
  profileName: any = "";
  totalCredit: number = 0;
  fileName: string;
  abstractText: string = "";
  loaderStatus: boolean = true;
  filterModel: string = "";
  uploadStatus: boolean = false;
  creditLimit: number = -1;
  referralReward: number = 0;
  country:string = "";
  jouranlFinderPageLoader: boolean = true;

  constructor(
    public sharedService: SharedService,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public amplitudeService: AmplitudeService,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    private onlineEditorService: OnlineEditorService,
    public journalFinderService: JournalFinderService,
  ) {

   }


  ngOnInit(): void {
    wisepop();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.emailId = localMeta.sub;
    let data = {
      user_id: this.userId,
    };
    this.country = localStorage.getItem("country-trinka");
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.getUserPaymentSubscription();
    this.getCreditsDetails(true);
    this.getSelectedProfileData();
    this.amplitudeService.setUserId(this.userId);
    this.getAllProcessedAbstractResultList();
    this.fetchReferralRewards();
    setTimeout(() => {
      this.jouranlFinderPageLoader = false;
    }, 3000);
  }

  signOut() {
    this.sharedService.SignOut();
  }

  checkBeforeExpiryStatus(expiry_date){
    let endDate:any = new Date(expiry_date);
    let currentDate:any = new Date();
    let no_day = Math.ceil(Math.abs(currentDate-endDate) / (1000 * 3600 * 24));
    return no_day>15 ? false: true;
  }

  getUserPaymentSubscription(){
    this.dashboardService.getUserPaymentSubscription(this.userId).subscribe(response => {
      this.paymentDetails = response['data'];
      this.amplitudeService.setUserProperties('subscription_type',((this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase())));
    });
  }


  getCreditsDetails(flag=false) {
    this.dashboardService.getCreditsDetailsByUser(this.userId).subscribe(
      (response) => {
        if (response["status"]) {
          this.creditsDetails = response.data;
          this.totalCredit = this.creditsDetails.credits  + this.creditsDetails.purchased_credits;
        }
        if(flag){
        this.amplitudeService.logAmplitude("JFR_LandingPage_pageview", {
          'Credit Balance Free': this.creditsDetails.credits,
          'Credit Balance Purchased': this.creditsDetails.purchased_credits
        });
      }
      },
      (error) => {
        this.sharedService.errorHandller(error);
      }
    );
  }

  getSelectedProfileData() {
    this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(profileData=>{
        this.selectedProfile = profileData;
        this.profileName = this.selectedProfile!="" ? (this.selectedProfile['firstname'].substr(0,1) +""+ this.selectedProfile['lastname'].substr(0,1)).toUpperCase(): "";
    });
    if(this.selectedProfile==''){
    this.onlineEditorService.getSelectedProfileData(this.userId).subscribe(result => {
      if (result["status"]) {
        this.selectedProfile = result['data']['profile_data'];
        this.profileName = (this.selectedProfile['firstname'].substr(0,1) +""+ this.selectedProfile['lastname'].substr(0,1)).toUpperCase();
        this.subjectiveBehaviorSharedService.setSelectedProfileData(this.selectedProfile)
      }
    }, error => {
      this.sharedService.errorHandller(error);
    });
   }
  }

  focusAbstractTxt(event_type) {
    if(event_type==1){
      this.amplitudeService.logAmplitude('JFR_Paste_abstract',{
        // 'Credit Balance Free': this.creditsDetails.credits,
        // 'Credit Balance Purchased': this.creditsDetails.purchased_credits
      });
    }
  }

  cancelAbsBtn(){
    this.amplitudeService.logAmplitude('JFR_Cancel_CTA',{
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits,
    });
    this.abstractText = "";
  }


  addCredits() {
    this.amplitudeService.logAmplitude("JFR_InsuffCredit_AddCredits", {
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits,
    });
    let redirct_url = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url + "/user/credit/" + this.userData, "_self");
  }

  credits() {
    if (this.creditsDetails != null) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData: this.creditsDetails,
      };

      this.dialog.open(CreditDetailsComponent, dialogConfig);
    }
  }

  logCancelInsuffeicent(){
    this.amplitudeService.logAmplitude("JFR_InsuffCredit_Cancel", {
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits
    });
  }

  logViewResult(file_id){
    this.amplitudeService.logAmplitude("JFR_ViewReport_CTA", {
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits,
      'Report ID': file_id,
    });
  }

  searchJournal(){
    if(this.abstractText.trim().length>0){
      if(this.totalCredit>=this.creditLimit){
        this.uploadStatus = true;
        let payload = new FormData();
        payload.append("text", this.abstractText);
        payload.append("journal_name", "");
        payload.append("subject_area", "");
        payload.append("concept", "");
        payload.append("user_id", this.userId);
        payload.append("email_id", this.emailId);
        this.amplitudeService.logAmplitude('JFR_FindJournal_CTA',{
          'Credit Balance Free': this.creditsDetails.credits,
          'Credit Balance Purchased': this.creditsDetails.purchased_credits,
        });
        this.journalFinderService.fetchJournalData(payload).subscribe(
          (resp) => {
            this.uploadStatus = false;
            this.loaderStatus = false;
            if(resp["status"]){
              this.abstractText = "";
              this.getAllProcessedAbstractResultList();
              this.toastr.success("Generating your recommendations, this may take a few minutes.");
            }
          },
          (errorResp) => {
            this.uploadStatus = false;
            this.loaderStatus = false;
            let alert_message = errorResp.error.message
              ? errorResp.error.message
              : "Something went wrong";
            this.toastr.error(alert_message, "Error");
          });
        }
        else{
          this.amplitudeService.logAmplitude('JFR_InsuffCredit_Popup',{
            'Credit Balance Free': this.creditsDetails.credits,
            'Credit Balance Purchased': this.creditsDetails.purchased_credits,
          });
        }
    }
    else{
      this.toastr.error("Please type or paste text ", "Error");
    }
  }

  getAllProcessedAbstractResultList(){
    this.journalFinderService.fetchProcessedAbstractList(this.userId).subscribe(
      (response) => {
        if (response["status"]) {
          this.loaderStatus = false;
          this.processedAbstractList = response["data"];

          for (let i = 0; i < this.processedAbstractList.length; i++) {
            if (
              this.processedAbstractList[i].file_status === AutoRef.IN_PROGRESS ||
              this.processedAbstractList[i].file_status === AutoRef.WAITING_IN_QUEUE
            ) {
              setTimeout(() => {
                this.getAllProcessedAbstractResultList();
                this.getCreditsDetails();
              }, 9000);
            }
          }
        }
      },
      (error) => {
        this.loaderStatus = false;
        this.sharedService.errorHandller(error);
      }
    );
  }
//Redirecting to the billing page
  redirectToUpgradeToPremiumPage(fromTopPanel: boolean = false) {
    let data = {
      "user_id" : this.userId,
      "subscription_type": this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      "from":"Trinka"
    };

    if (!fromTopPanel) this.amplitudeService.logAmplitude('Plag_upgrade_nudge_clicked', {
      "user_id" : this.userId,
      "subscription_type": this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
    });
    else this.amplitudeService.logAmplitude('Upgrade_nudge_clicked', {
      'from': 'Journal Finder',
      "user_id" : this.userId,
      "Current Plan": this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url  = environment.payment_fe;
    window.open(redirct_url + '/user/' + this.userData, "_blank");
  }

  fetchReferralRewards(){
    this.dashboardService.getReferralRewards(this.userId).subscribe(response => {
        this.referralReward = response["referral_reward"] || 0;
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  showReferralRewardModal(){

    let data = {
      "user_id" : this.userId,
      "plan_name": this.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
      "from":"Trinka"
    };

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let subscriptionUpgradeUrl  = environment.payment_fe + '/user/' + this.userData;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      userId : this.userId,
      referralReward : this.referralReward,
      subscriptionUpgradeUrl: subscriptionUpgradeUrl,
      userType: this.creditsDetails.user_type
    };
    this.dialog.open(ReferralRewardModalComponent, dialogConfig);
  }

  uppercaseFirstLetter(phrase):string{
    if(!phrase) return '';
    else return phrase
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }
}
