import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebsiteRoutingModule } from './website-routing.module';
import { WebsiteComponent } from './website.component';
import { PricingComponent } from 'src/app/components/pricing/pricing.component';
import { UseCaseComponent } from 'src/app/components/use-case/use-case.component';
import { EnterpriceComponent } from 'src/app/components/enterprice/enterprice.component';
import { FeaturesComponent } from 'src/app/components/features/features.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { MaterialModuleList } from 'src/app/material-modules';
import { SignInSignOutComponent } from 'src/app/components/sign-in-sign-out/sign-in-sign-out.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MainComponent } from 'src/app/components/main/main.component';
import { AuthService } from 'src/app/_guards/auth.service';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedService } from 'src/app/_services/shared.service';
import { CompareWithComponent } from 'src/app/components/compare-with/compare-with.component';
import { MsWordAppComponent } from 'src/app/components/ms-word-app/ms-word-app.component'
import { WebAppComponent } from 'src/app/components/web-app/web-app.component';
import { ExamplesComponent } from 'src/app/components/examples/examples.component';
import { AboutUsComponent } from 'src/app/components/about-us/about-us.component';
import { ContactUsComponent } from 'src/app/components/contact-us/contact-us.component';
import { WorkWithUsComponent } from 'src/app/components/work-with-us/work-with-us.component';
import { HelpCenterComponent } from 'src/app/components/help-center/help-center.component';
import { TermofServicesComponent } from 'src/app/components/termof-services/termof-services.component';
import { PrivacyPlicyComponent } from 'src/app/components/privacy-plicy/privacy-plicy.component';
import { MediaComponent } from 'src/app/components/media/media.component';
import { DataSecurityComponent } from 'src/app/components/data-security/data-security.component';


@NgModule({
  declarations: [
    WebsiteComponent,
    PricingComponent,
    UseCaseComponent,
    EnterpriceComponent,
    FeaturesComponent,
    HeaderComponent,
    SignInSignOutComponent,
    MainComponent,
    CompareWithComponent,
    MsWordAppComponent,
    WebAppComponent,
    ExamplesComponent,
    AboutUsComponent,
    ContactUsComponent,
    WorkWithUsComponent,
    HelpCenterComponent,
    DataSecurityComponent,
    MediaComponent,
    PrivacyPlicyComponent,
    TermofServicesComponent,
  ],
  imports: [
    CommonModule,
    WebsiteRoutingModule,
    StorageServiceModule,
    NgxSpinnerModule,
    MaterialModuleList,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule
  ], entryComponents: [
    SignInSignOutComponent,
  ], 
  providers: [
    AuthService,
    AuthGuardService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    SharedService
  ]
})
export class WebsiteModule { }
