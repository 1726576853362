import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare const gapi: any;
declare const google: any;
@Injectable({
  providedIn: 'root'
})
export class GoogleDrivePickerService {

  constructor() { }

  private clientId = environment.google_client_id;
  private apiKey =  environment.google_apiKey;
  private appId = environment.google_appId;
  private scope = 'https://www.googleapis.com/auth/drive.file';
  private isPdfAllowed = false;

  public oauthAccessToken = null;
  private pickerApiLoaded = false;
  private pickerCallback = null;

  public open(callback, isPdfAllowed=false): any {
    this.oauthAccessToken = null;
    this.pickerApiLoaded = false
    this.pickerCallback = callback;
    this.isPdfAllowed = isPdfAllowed;
    gapi.load('auth', {'callback': this.onAuthApiLoad.bind(this)});
    gapi.load('picker', {'callback': this.onPickerApiLoad.bind(this)});
  }
  private onAuthApiLoad(): void {
    gapi.auth.authorize({
      'client_id': this.clientId,
      'scope': this.scope,
      'immediate': false,
    }, this.handleAuthResult.bind(this));
  }

  private onPickerApiLoad(): void {
    this.pickerApiLoaded = true;
    this.createPicker();
  }

  private handleAuthResult(authResult): void {
    if (authResult && !authResult.error) {
      this.oauthAccessToken = authResult.access_token;
      this.createPicker();
    }
  }

  private createPicker(): void { //.setIncludeFolders(true)
    if (this.pickerApiLoaded && this.oauthAccessToken) {
      let allowedMimeTypes;
      if(this.isPdfAllowed){
        allowedMimeTypes = "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,text/x-tex,application/pdf";
      }else{
        allowedMimeTypes = "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,text/x-tex"
      }
      let view = new google.picker.DocsView(google.picker.ViewId.DOCS).setMimeTypes(allowedMimeTypes);
      let picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId(this.appId)
        .setOAuthToken(this.oauthAccessToken)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setDeveloperKey(this.apiKey)
        .setCallback(this.pickerCallback)
        .build();
      picker.setVisible(true);
    }
  }
}