import { ApplicationRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbTypeahead, NgbTypeaheadConfig, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from "@angular/forms";
import { TranslationObject } from 'src/app/_interfaces/editor/transationObject';
import { WhiteList } from 'src/app/_enums/whitlist.enum';
import { Title } from '@angular/platform-browser';
import { MatSnackBar, MatDialog, MatDialogConfig, MatOption } from '@angular/material';
import { DownloadfilepopupComponent } from 'src/app/components/downloadfilepopup/downloadfilepopup.component';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { DocumentTypeComponent } from 'src/app/components/document-type/document-type.component';
import { ModuleCheck } from 'src/app/_enums/module-check.enum';
import { VerifiedUserService } from 'src/app/_services/networkcalls/verified-user.service';
import { humanize } from 'humanize';
import { parse } from 'querystring';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { UpgradeToPremiumComponent } from 'src/app/components/editor/upgrade-to-premium/upgrade-to-premium.component';
import { PaymentDetails } from 'src/app/_interfaces/dashboard/payment-details';
import { environment } from 'src/environments/environment';
import { ContentChild } from '@angular/core';
import { SignupaccessComponent } from 'src/app/_modal/signupaccess/signupaccess.component';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Inject } from '@angular/core';
import { MatRadioChange } from '@angular/material';
import { GetMetaService } from 'src/app/_services/get-meta.service';
import { Meta } from '@angular/platform-browser';
import Dexie from 'dexie';
import { CreditDetailsComponent } from 'src/app/components/dashboard/credit-details/credit-details.component';
import { AutoRef } from 'src/app/_enums/auto-refer.enum copy';
import { AutoEdit } from 'src/app/_enums/auto-edit.enum';
import { FileObject } from 'src/app/_interfaces/editor/file';
import { EditorPlagiarismModalComponent } from 'src/app/components/editor/editor-plagiarism-modal/editor-plagiarism-modal.component';
import { GoogleDrivePickerService } from 'src/app/_services/google-drive-picker.service';
import { DropboxChooseOptions, Dropbox } from 'src/app/_interfaces/dashboard/dropbox-choose-options';
import { EditorReferenceQualityCheckModalComponent } from 'src/app/components/editor/editor-reference-quality-check-modal/editor-reference-quality-check-modal.component';
import { EditorJournalFinderModalComponent } from 'src/app/components/editor/editor-journal-finder-modal/editor-journal-finder-modal.component';
import { FeedbackModalComponent } from 'src/app/components/feedback-modal/feedback-modal.component';
import { ParaphraserService } from 'src/app/_services/paraphraser.service';
import { LanguageAlertModalComponent } from 'src/app/components/language-alert-modal/language-alert-modal.component';
import { DocumentAndStyleModalComponent } from 'src/app/components/editor/document-and-style-modal/document-and-style-modal.component';
import { DocumentLegalInfoModalComponent } from 'src/app/components/editor/document-legal-info-modal/document-legal-info-modal.component';
import { ConfirmAccessTrinkaComponent } from '../shared/components/confirm-access-trinka/confirm-access-trinka.component';
import { StartTrinkaLabsModalComponent } from '../shared/components/start-trinka-labs-modal/start-trinka-labs-modal.component';
import { InstructionFeedbackModalComponent } from 'src/app/components/editor/instruction-feedback-modal/instruction-feedback-modal.component';
import { SecondsLoaderModalComponent } from '../shared/components/seconds-loader-modal/seconds-loader-modal.component';
import { RenameFileComponent } from 'src/app/components/dashboard/mydrive/rename-file/rename-file.component';
import { ConnectionService } from 'src/app/_socket/connection.service';
import { TrinkaUpdatesComponent } from '../shared/components/trinka-updates/trinka-updates.component';
import { PaymentService } from 'src/app/_services/networkcalls/payment.service';
import { PowerModeEditingComponent } from 'src/app/modules/shared/components/power-mode-editing/power-mode-editing.component';

declare const OneDrive: any;
declare var Dropbox: Dropbox;
declare const trinkaSDK: any;
declare const CKEDITOR: any

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  enableModelPreference = null;
  enableTrinkaAccessDefault = false;
  modelPreferenceTour = false;
  subjectType = [];
  selected_paper_type: any;
  win = window['dataLayer'] || {};
  userId: any;
  public model: any;
  show: boolean = true;
  styleGuide: string = 'NONE';
  instuctionGuide: string = '';
  subjectArea: string;
  subjectAreaList = [];
  subjectAreaListArray = [];
  transactionPayload = undefined;
  transactionStatus: boolean = false;
  enagoPlans = [];
  enagoProofReadingfForm: FormGroup;
  make_payment = true;
  Contentshow = false;
  enabledModule = "";
  documentTypeDropdown = [
    {
      "id": 1,
      "name": "Select"
    },
    {
      "id": 2,
      "name": "General"
    },
    {
      "id": 3,
      "name": "Academic"
    },
    {
      "id": 4,
      "name": "Legal"
    }
  ]
  validateJournalURL: boolean = false;
  docTypeObject = {};
  isAutoMode: boolean = true;
  obj_present: boolean = false;
  displaEnagoProofReadingButton = false;
  selectedDeliveryPlan = {
    'text': "",
    'tat_list': []
  };
  documentTypeList = ["Select", "General", "Academic", "Legal"]
  styleGuideDispay = {
    "NONE": "None",
    "AMA": "AMA (11th ed)",
    "APA": "APA (7th ed)",
    "ACS": "ACS (2nd ed)",
    "AGU": "AGU (2017 ed)",
    "IEEE": "IEEE",
  }

  inclusiveLanguageSelectAllChecked: boolean = false;
  inclusiveLanguageSelectAllCheckIndeterminate: boolean = false;
  isEnglishLanguageDisabled: boolean = false;

  InstuctionDisplay = {
    "Megainstruct": "Base Model",
    "Fix all the Grammatical Errors of this text": "Beta Model",
    // "Fix all the Grammatical Errors by performing Proof Reading on this text": "Megainstruct - Proof Reading",
    // "Fix all the Grammatical Errors by performing Substantive Editing on this text": "Megainstruct - Substantive Editing"
  }

  styleGuideLegalDispay = {
    "Redbook (3rd ed)-Legal": "USA-The Redbook (3rd ed.)",
    "SCI stereotypical language": "India-SCI Gender Handbook",
    "NONE": "Common Style"
  }

  languageCodeOptions = [{
    "code": "en",
    "value": "English (EN)",
    "isSelected": false,
    "disabled": false
  },
  // {
  //   "code": "de",
  //   "value": "German (DE)",
  //   "isSelected": false,
  //   "disabled": false
  // }, 
  {
    "code": "es",
    "value": "Spanish (ES)",
    "isSelected": false,
    "disabled": false
  }];
  selectedLanguageCode: string[] = ['en'];
  defaultLanguageCode: string[] = [];

  languageTranslation: TranslationObject;
  selectedDilevery = {};
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  @ViewChild('editModal', { static: true }) editModal: TemplateRef<any>;
  @ViewChild('refList', { static: true }) refList: TemplateRef<any>;
  @ViewChild('paymentStatus', { static: true }) paymentStatus: TemplateRef<any>;
  enagoPlansFullData: any;
  public datePickerModel: NgbDateStruct
  today = this.calendar.getToday();
  paymentResponceObj: any;
  document_type: any = 1;
  journalFlag = true;
  timeZone: any;
  planDataLabelList: any;
  viewMode = 'GRAMMER';
  activeModule: any = ModuleCheck.GC;
  enableCheck: string;
  reportArray = [];
  journalNameList = [];
  paramFileId = "";
  readOnly = false;
  trialMode = false;
  wordLimitExeed = false;
  processing_flag = false;
  isUserVerified: any;
  userEmailid: any;
  paymentDetails: PaymentDetails;
  userData: any;
  refBoxToggle: boolean = false;
  references = [];
  currFileId: any;
  textRefList: any;
  selectedStyle: string = null;
  refEmailId: string;
  creditsDetails: any;
  totalCredits: number = 0;
  user_decoded_id: any;
  file_name: any;
  file_id: string;
  editorLoader: boolean = false;
  languageString;
  // editorCardLoader: boolean = false;
  cloudDriveFileDetails = { "fileId": "", "fileName": "", "fileUrl": "", "source": "", "oauthToken": "", "userId": "" };
  uploadSource = [
    {
      "GOOGLE_DRIVE": false,
      "TRINKA_DRIVE": false,
      "ONE_DRIVE": false,
      "DROP_BOX": false,
      "LOCAL_SYSTEM": false
    }
  ];

  noOfReferences: number = 0;
  per_credit_ref: number = 0
  base_credit: number = 0;
  credit_used: number = 0;
  childComponent: any;
  currentLanguage: string = '';
  isInclusiveFilterDisabled: boolean = false;
  isNewEditor: boolean = false;
  paraphraserCloseBtn = null;
  wordCountApiCall = true;
  wordCountApiFailed = false;
  language: string = 'US';
  wordCountSections = [
    { text: "word_count", value: 0, displayText: 'Words' },
  ];
  getDisableRightPanelMenuOptionsSubscription: any;
  disableRightPanelMenu: boolean = false;
  trialDayRemaing: number = 0;
  userSubscriptionDetails: any;

  disablePalgCheck = window.location.pathname.includes("new");
  isActiveLearnMore: boolean = false;
  instructionModule = {
    instruction: "Fix all the Grammatical Errors of this text",
    module: "advanced"
  }
  editingModeSelectedOption = "Power Mode"
  editingModeOption = [{
    "isBeta": true,
    "value": "Power Mode",
    "isSelected": false,
    "disabled": false,
    "description": "For grammar, spelling correction, as well as language enhancement",
    "specialClass": "hoverPowerModeSection"
  }, {
    "isBeta": true,
    "value": "Lite Mode",
    "isSelected": true,
    "disabled": false,
    "description": "For essential grammar and spelling correction",
    "specialClass": ""
    }];
    shimmerLoaderPowerMode: boolean = true;

  constructor(
    public subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    public sharedService: SharedService,
    public networkCalls: OnlineEditorService,
    public networkCallsDashboard: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public fb: FormBuilder,
    private calendar: NgbCalendar,
    private titleService: Title,
    private dialog: MatDialog,
    private verifiedUserService: VerifiedUserService,
    public amplitudeService: AmplitudeService,
    public metaString: GetMetaService,
    private metaTagService: Meta,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private googleDrivePickerService: GoogleDrivePickerService,
    private appRef: ApplicationRef,
    private dashboardService: DashboardService,
    private _paraphraserService: ParaphraserService,
    private socketService: ConnectionService,
    private networkCallsPayments: PaymentService
  ) {

    let localMeta = this.sharedService.getLocalStorageMeta();
    if (!localMeta) {
      let meta = environment.accessToken;
      this.storage.set(metaString.getMeta(), meta);
      localMeta = JSON.parse(atob(meta['token'].split('.')[1]));
    }

    this.userId = btoa(localMeta.sub);
    this.refEmailId = localMeta.sub;
    this.amplitudeService.setUserId(this.userId);
    this.subjectiveBehaviorSharedService.setModuleCheck("");
    this.subjectiveBehaviorSharedService.setEditorFileId(null);
    this.subjectiveBehaviorSharedService.setEditorFileName(null);
    route.firstChild.paramMap.subscribe(
      (params: ParamMap): void => {
        this.transactionPayload = params.get("transactionPayload");
        this.paramFileId = params.get("id");
        if (this.transactionPayload != undefined) {
          this.paymentResponceObj = JSON.parse(atob(this.transactionPayload));
          this.transactionStatus = true;
          this.confirmOrderRequest(this.paymentResponceObj)
          setTimeout(() => {
            this.modalService.open(this.paymentStatus, { size: 'md paymentPopup', backdrop: 'static' });
          }, 4000);
        }
      }
    );
    this.loadCreditDetails();
    this.featurePermissionRequest();
    this.trialMode = environment.trialMode;
    this.enagoProofReadingfForm = this.fb.group({
      subjectArea: new FormControl('', [Validators.required]),
      journalFormatting: new FormControl(false, [Validators.required]),
      journalUrl: new FormControl('', []),
    });

    this.subjectiveBehaviorSharedService.getWordLimitExeed.subscribe(data => {
      this.wordLimitExeed = data;
    }, error => {
      this.wordLimitExeed = false;
    })

    this._paraphraserService.paraphraseFloatingOptionClick.subscribe((input) => {
      if (input) {
        this.switchToParaphraser('PP', true);
      }
    });

    this.inclusiveLanguageSelectAllChecked = this._isAllSelected();
    // this.getSelectedTrinkaLab();
    this.subjectiveBehaviorSharedService.getInstruction.subscribe(data => {
      if (data && data.mode === 'child_to_parent') {
        if (data.module === "megainstruct") {
          this.enablePaniniflag = true;
        } else {
          this.enablePaniniflag = false;
        }
        this.editingModeSelectedOption = this.getModelName(data.module);

        // this.sentDataCall(() => window.location.reload(), true);
      }
      if (data && data.mode === 'parent_to_child') {
        this.instructionModule.module = data.module;
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    });
  }

  returnZero() {
    return 0;
  }

  ngAfterViewInit() {
    this.getSelectedStyleguide();
    // this.getSelectedTrinkaLab();
    this.getSelectedDocumentType();
    this.getFileReadOnlyStatus();
    this.currFileId = this.sharedService.getCurrentFileId();
    this.getUserFileDetails();
  }

  openDocumentAndStyleModal() {
    let dialogRef = this.dialog.open(DocumentAndStyleModalComponent, {
      disableClose: true,
      data: {
        document_type: this.document_type,
        styleGuide: this.styleGuide,
        documentTypeList: this.documentTypeDropdown.filter(documentType => documentType.id !== 1),
        styleGuideList: this.styleGuideDispay,
        styleGuideLegalList: this.styleGuideLegalDispay
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (!response) return;

      if (this.document_type !== response.document_type) {
        this.document_type = response.document_type;
        this.documentTypeChange();
        if (this.document_type === 4) {
          this.subjectiveBehaviorSharedService.setEditorLanguage('en');
          this.updateFileLanguage()
          let legalInfoDialogRef = this.dialog.open(DocumentLegalInfoModalComponent, {
            disableClose: true
          });
          legalInfoDialogRef.afterClosed().subscribe(result => { });
        }

        this.inclusiveLanguageSelectAllChecked = false;
        Object.keys(this.sharedService.inclusiveLanguageFilterOptions).forEach(key => {
          this.sharedService.inclusiveLanguageFilterOptions[key].checked = false;
        });
        this.subjectiveBehaviorSharedService.inclusiveFilterChange.next(this.sharedService.inclusiveLanguageFilterOptions);
      }
      if (this.styleGuide !== response.styleGuide) {
        this.styleGuide = response.styleGuide;
        this.styleGuideChange();
      }

      this.sharedService.grammarModuleConfig.style_guide = {
        value: this.styleGuide,
        title: this.document_type === 4 ? this.styleGuideLegalDispay[this.styleGuide] : this.styleGuideDispay[this.styleGuide]
      }
      this.sharedService.grammarModuleConfig.document_type = this.document_type;

      if(trinkaSDK.initializedModule.name === 'grammar') {
        trinkaSDK.initializedModule.updateTrinkaEditorConfig(this.sharedService.grammarModuleConfig, true);
      }

      this.toastr.success("Your selection has been saved");
    });
  }

  private _isAllSelected() {
    return Object.values(this.sharedService.inclusiveLanguageFilterOptions).every(filter => filter.checked === true);
  }

  isAtleast1CategorySelected() {
    return Object.values(this.sharedService.inclusiveLanguageFilterOptions).some(filter => filter.checked === true);
  }

  getInclusiveLanguageDropdownTitle() {
    let totalCount = Object.values(this.sharedService.inclusiveLanguageFilterOptions).filter(filter => filter.checked === true).length;
    let response = Object.values(this.sharedService.inclusiveLanguageFilterOptions).find(filter => filter.checked === true);
    if (response) {
      return `${totalCount > 1 ? `${response.title} +${totalCount - 1}` : `${response.title}`}`;
    }

    return 'try re-selecting';
  }

  inclusiveLanguageSelectAllChange() {
    Object.keys(this.sharedService.inclusiveLanguageFilterOptions).forEach(key => {
      this.sharedService.inclusiveLanguageFilterOptions[key].checked = this.inclusiveLanguageSelectAllChecked;
    });
    this.subjectiveBehaviorSharedService.inclusiveFilterChange.next(this.sharedService.inclusiveLanguageFilterOptions);
    if(this.isNewEditor) this.setInclusiveLanguageErrorCategories();
  }

  inclusiveLanguageOptionChange(option: any) {
    setTimeout(() => {
      this.inclusiveLanguageSelectAllChecked = this._isAllSelected();
      this.subjectiveBehaviorSharedService.inclusiveFilterChange.next(this.sharedService.inclusiveLanguageFilterOptions);
      if(this.isNewEditor) this.setInclusiveLanguageErrorCategories();
      this.amplitudeService.logAmplitude('Inclusive_writing_categories_selection', {
        [option.title]: option.checked,
        'editor': this.isNewEditor ? 'V1' : 'V0'
      });
    }, 0);

  }

  loadCreditDetails() {
    this.dashboardService.getStoreUserCreditDetails().subscribe(resp => {
      if (Object.keys(resp).length == 0) {
        this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
          if (response["status"]) {
            this.creditsDetails = response.data;
            this.dashboardService.storeUserCreditDetails(this.creditsDetails);
          }
          this.totalCredits = this.creditsDetails.credits + this.creditsDetails.purchased_credits;
        }, error => {
          this.sharedService.errorHandller(error);
        })
      }
      else {
        this.creditsDetails = resp;
      }
    });
  }

  getSelectedDocumentType() {
    this.subjectiveBehaviorSharedService.getDocTypeObject.subscribe(document_type => {
      this.document_type = document_type.selected_document_type;
      this.docTypeObject = {
        selected_document_type: this.document_type,
        selected_paper_type: document_type.selected_paper_type,
        user_added_subject_area: document_type.user_added_subject_area,
        selected_subject_area: document_type.selected_subject_area,
        subject_area_list: document_type.subject_area_list
      };

      this.inclusiveLanguageSelectAllChecked = false;
      Object.keys(this.sharedService.inclusiveLanguageFilterOptions).forEach(key => {
        this.sharedService.inclusiveLanguageFilterOptions[key].checked = false;
      });
      this.subjectiveBehaviorSharedService.inclusiveFilterChange.next(this.sharedService.inclusiveLanguageFilterOptions);
    });
  }

  getSelectedStyleguide() {
    this.subjectiveBehaviorSharedService.getStyleGuide.subscribe(styleguide => {
      this.styleGuide = styleguide[0] ? styleguide[0] : 'NONE'
    })
  }
  getSelectedTrinkaLab() {
    // this.subjectiveBehaviorSharedService.getInstruction.subscribe(instruction => {
    //   // this.styleGuide = styleguide[0] ? styleguide[0] : 'NONE'
    //   console.log("instruction ", instruction);
    //   debugger
    //   if(instruction[1] === "megainstruct"){
    //     console.log("instruction[0][1] true", instruction[0][1])
    //     this.enablePaniniflag = false;
    //   } else {
    //     console.log("instruction[0][1] false", instruction[0][1])
    //     this.enablePaniniflag = true;
    //   }
    // })
  }

  getFileReadOnlyStatus() {
    this.subjectiveBehaviorSharedService.getFileReadOnly.subscribe(r => {
      this.readOnly = r;
    })

  }

  confirmOrderRequest(paymentResponceObj) {
    let payload = {}
    payload['OrderRefNum'] = paymentResponceObj['orderRefNum'];
    payload['SubdomainId'] = paymentResponceObj['subDomainId'];
    payload['paymentInfo'] = {}
    payload['paymentInfo']['status'] = paymentResponceObj['status'];
    payload['paymentInfo']['revisedAdvancePercentage'] = paymentResponceObj['revisedAdvancePercentage'];
    payload['paymentInfo']['isEligibleForPayOnDelivery'] = paymentResponceObj['isEligibleForPayOnDelivery'];
    payload['paymentInfo']['transactionId'] = paymentResponceObj['transactionId'];
    payload['paymentInfo']['amount'] = paymentResponceObj['amount'];
    this.networkCalls.submitOrderMin(this.userId, payload).subscribe(res => {
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  private _waitForTrinkaScriptToLoad() {
    return new Promise((resolve, reject) => {
      let counter = 0;
      const checkInterval = setInterval(() => {
        if(trinkaSDK) {
          clearInterval(checkInterval);
          resolve(true);
        }

        counter++;

        if(counter > 50) {
          clearInterval(checkInterval);
          reject();
        }
      }, 2000);
    });
  }

  async ngOnInit() {
    setTimeout(() => {
      this.shimmerLoaderPowerMode = false;
    }, 1500);
    // this.subjectiveBehaviorSharedService.getStyleGuide.subscribe(styleguide => this.styleGuide = styleguide)
    // this.subjectiveBehaviorSharedService.changeStyleGuide(this.styleGuide)
    this.setTitle();
    this.getJournalNameList();
    this.getUserPaymentSubscription();
    this.metaTagService.updateTag({ name: 'robots', content: 'noindex' });

    this.per_credit_ref = environment.per_credit_ref;
    this.base_credit = environment.base_credit;
    this.checkCurrentLanguage();
    this.getUserProfileData();
    this.isNewEditor = window.location.pathname.includes('main-v1');

    this.getAllSubmitedReportRevision();
    if (this.isNewEditor) {
      this._waitForTrinkaScriptToLoad().then(() => {
        this.toggleGrammarNParaphrase('grammar');
      }).catch((error) => {
        console.log('Error TrinkaSDK ===> ', error);
      });

      this.getDisableRightPanelMenuOptionsSubscription = this.subjectiveBehaviorSharedService.getDisableRightPanelMenuOptionsFlag.subscribe(async (flag) => {
        this.disableRightPanelMenu = flag;
      })
    }
  }

  onDateSelect($event) {
    this.validateDatepicker()
  }

  enablePaniniflag = false;
  enablePanini() {
    //console.log("this.enablePaniniflag", this.enablePaniniflag)
    var InstModel = [
      {
        'inst': "",
        'module': "basic"
      }, {
        'inst': "Fix all the Grammatical Errors of this text",
        'module': "advanced"
      }];
    if (!this.modelPreferenceTour) {
      if (InstModel[this.enablePaniniflag ? 0 : 1].module === "") {
        this.secondsLoaderModal("");
        this.amplitudeService.logAmplitude('TrinkaLabs -Toggle', {
          'model': "basic"
        });
      } else {
        this.secondsLoaderModal("megainstruct");
        this.amplitudeService.logAmplitude('TrinkaLabs -Toggle', {
          'model': "Panini-1"
        });
      }
    }

    this.subjectiveBehaviorSharedService.changeInstuctionModule('parent_to_child', InstModel[this.enablePaniniflag ? 0 : 1].inst, InstModel[this.enablePaniniflag ? 0 : 1].module);
    let payload = {
      file_id: this.file_id,
      instruction: InstModel[this.enablePaniniflag ? 0 : 1].inst,
      module: InstModel[this.enablePaniniflag ? 0 : 1].module,
      lang_code: this.selectedLanguageCode.toString()
    }
    //TODO: Combine all putUpdateFile calls like done previously
    this.networkCalls.putUpdateFile(this.userId, payload).subscribe(result => {

    }, error => {
      this.sharedService.errorHandlerForFIleSave(error);
    });

    this.sharedService.grammarModuleConfig.instructionModule = {
      'module': InstModel[this.enablePaniniflag ? 0 : 1].module,
      'instruction': InstModel[this.enablePaniniflag ? 0 : 1].inst
    }

    if(trinkaSDK.initializedModule.name === 'grammar') {
      trinkaSDK.initializedModule.updateTrinkaEditorConfig(this.sharedService.grammarModuleConfig, true);
    }
  }

  secondsLoaderModal(model: any) {
    const dialogRef = this.dialog.open(SecondsLoaderModalComponent, { data: { model: model } });
    setTimeout(() => {
      dialogRef.close();
    }, 5000)
    dialogRef.afterClosed().subscribe(result => {
      if (model === '') {
        this.toastr.success("Live model suggestions have been enabled", "", {
          timeOut: 1000,
        })
      } else {
        this.toastr.success("Panini-2 suggestions have been enabled", "", {
          timeOut: 1000,
        })
      }
    })
  }

  instuctionGuideChange() {
    //console.log(this.instuctionGuide)
  }

  styleGuideChange() {
    if (environment.trialMode) {
      this.dialog.open(SignupaccessComponent);
      return;
    }
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'action': 'click',
      'style_type': this.styleGuide,
      'event_name': 'style_guide',
      'event': 'style_guide_settings'
    });

    this.subjectiveBehaviorSharedService.changeStyleGuide(this.styleGuide, true);

    let data = {
      style_guide: this.styleGuide
    };

    this.networkCalls.putUpdateFileForDocumentType(this.userId, this.sharedService.currentFileId, data).subscribe(resp => {
      this.amplitudeService.logAmplitude('style_guide_set', {
        'guide': this.styleGuide
      });
    });
  }

  setTitle() {
    if (window.location.host == WhiteList.SMARTMANUSCRIPT) {
      this.titleService.setTitle("");
    }
  }
  changeRoute() {
    // this.router.navigate(['/editor/main', this.sharedService.currentFileId])
    document.location.href = "/editor/main/" + this.sharedService.currentFileId;
  }
  pageConfigDetails = {};
  getPageConfig() {
    // this.spinner.show();
    this.editorLoader = true;
    this.networkCalls.pageConfigRequest(this.userId).subscribe(result => {
      if (result['status']) {
        this.pageConfigDetails = result['data'];
        this.getLanguageTranslationData(this.pageConfigDetails['locale']);
        this.displaEnagoProofReadingButton = this.pageConfigDetails.hasOwnProperty('disableForTrinka') ? (this.pageConfigDetails['disableForTrinka'] ? false : true) : true;
        this.getSelectedProfileData();
      } else {
      }
    }, error => {
      this.displaEnagoProofReadingButton = false;
      // this.spinner.hide();
      this.editorLoader = false;
      this.sharedService.errorHandller(error);
    })
  }

  getLanguageTranslationData(culture) {
    this.networkCalls.getLocalCultureData(culture).subscribe(res => {
      this.languageTranslation = res;
    }, error => {
    })
  }


  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => (term === '' ? this.subjectAreaListArray
        : this.subjectAreaListArray.filter(v => v.text.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10).map(item => {
          if (item.levelNo == 3) {
            return item.text
          }
        }))
    );
  searchJournal = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => (term === '' ? this.journalNameList
        : this.journalNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );



  getJournalNameList() {
    this.networkCalls.getJournalNameListRequest().subscribe(result => {
      this.journalNameList = result;
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }
  searchFormatter = (x: { text: string }) => x.text;
  enagoProofreadingStart() {
    this.getSubjectAreas();
    if (environment.trialMode) {
      this.dialog.open(SignupaccessComponent);
      return;
    }
    this.transactionStatus = false;
    this.spinner.show();
    this.editorLoader = true;
    this.networkCalls.sendToProofreadingFile(this.userId, this.sharedService.currentFileId).subscribe(result => {

      if (result['status']) {
        this.timeZone = result['data']['pageConfigData']['timezoneDisplay'];
        this.planDataLabelList = result['data']['suggested_subject_area'];
        this.spinner.hide();
        this.editorLoader = false;
        if (result['data']['word_count'] == 0) {
          this.toastr.warning("We can't process document with 0 word count")
        } else {
          this.enagoPlansFullData = { ...result['data'] };
          // console.log(this.enagoPlansFullData);

          this.selectedProfile = result['data']['profile_data'];

          this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(data => {
            this.userEmailid = data.email_id
            this.isUserVerified = data.is_verified
          })


          if (this.selectedProfile['invoicetypeid'] == 1) {// International Invoice
            this.obj_present = true;
          } else {
            this.obj_present = false;
          }
          if (result['data']['pageConfigData']['pageMode'] == 1) {
            this.isAutoMode = false;
            this.enagoPlans = this.processEnagoDataCsAssist(result['data']['pageConfigData']['servicesAndTats']);
          } else {
            this.enagoPlans = this.processEnagoDataAutoMode(result['data']['plan_data'])
          }
          this.modalService.open(this.editModal, { size: 'lg proofreading_popup', backdrop: 'static' });
        }
        this.getMatchValue();
      } else {
        this.spinner.hide();
        this.editorLoader = false;
        this.toastr.warning(result['message'])
      }
      this.amplitudeService.logAmplitude('edit_service_view', {
        'fileID': this.sharedService.currentFileId,
      });
    }, error => {
      // this.spinner.hide();
      this.editorLoader = false;
      this.sharedService.errorHandller(error);
    });
  }

  verifyEmail() {
    // this.verifiedUserService.verifyEmail(this.selectedProfile);
  }

  resendVerificationMailLink() {
    this.networkCalls.resendVerifyMail(this.userEmailid).subscribe(resp => {

      this.verifyEmail()

    })
  }


  removeServices(plans: any[], geography: string) {

    var finalPlans = []

    if (geography == "en-US") {
      for (var i = 0; i < plans.length; i++) {
        var plan = plans[i]
        if (![231, 246].includes(plan['service']['serviceMasterId'])) { //serviceMasterId: 231
          finalPlans.push(plan)
        }
      }
    }

    if (geography == "zh-TW") {
      for (var i = 0; i < plans.length; i++) {
        var plan = plans[i]
        if (![231, 246].includes(plan['service']['serviceMasterId'])) {
          finalPlans.push(plan)
        }
      }
    }

    if (geography == "ko-KR") {
      for (var i = 0; i < plans.length; i++) {
        var plan = plans[i]
        if (![231, 246].includes(plan['service']['serviceMasterId'])) {
          finalPlans.push(plan)
        }
      }
    }

    if (geography == "ja-JP") {
      for (var i = 0; i < plans.length; i++) {
        var plan = plans[i]
        if (![231, 246].includes(plan['service']['serviceMasterId'])) {
          finalPlans.push(plan)
        }
      }
    }

    return finalPlans
  }

  isFloatFlag = false; // This is for sending data while make payment
  changePlanDataValues(plans) {
    var updated_plans = []
    for (let index = 0; index < plans.length; index++) {
      var plan = plans[index]
      if (this.obj_present) {
        if ("decimalPlaces" in this.pageConfigDetails && this.pageConfigDetails["decimalPlaces"] != 0) {
          this.isFloatFlag = true;
          plan['pricing']['totalDiscountedOrderAmount'] = parseFloat(plan['pricing']['totalDiscountedOrderAmount']).toLocaleString(this.pageConfigDetails['localeCulture']).toString()
        } else {
          this.isFloatFlag = false;
          plan['pricing']['totalDiscountedOrderAmount'] = parseInt(plan['pricing']['totalDiscountedOrderAmount']).toLocaleString(this.pageConfigDetails['localeCulture']).toString();
        }
      } else {
        if ("decimalPlaces" in this.pageConfigDetails && this.pageConfigDetails["decimalPlaces"] != 0) {
          this.isFloatFlag = true;
          plan['pricing']['netPayableDiscountedAmount'] = parseFloat(plan['pricing']['netPayableDiscountedAmount']).toLocaleString(this.pageConfigDetails['localeCulture']).toString()
        } else {
          this.isFloatFlag = false;
          plan['pricing']['netPayableDiscountedAmount'] = parseInt(plan['pricing']['netPayableDiscountedAmount']).toLocaleString(this.pageConfigDetails['localeCulture']).toString()
        }
      }
      updated_plans.push(plan)
    }
    return updated_plans

  }


  processEnagoDataAutoMode(plan_data) {
    plan_data = this.removeServices(plan_data, this.pageConfigDetails['locale'])
    let temp = {};
    let plans = [];
    plan_data = this.changePlanDataValues(plan_data)

    for (let index = 0; index < plan_data.length; index++) {
      if (temp[plan_data[index]['service']['id']] == undefined) {
        plans.push(plan_data[index]['service']);
        temp[plan_data[index]['service']['id']] = [];
        var tm = {
          "deadline": plan_data[index]['deadline'],
          "tat": plan_data[index]['tat'],
          "pricing": plan_data[index]['pricing']
        }
        temp[plan_data[index]['service']['id']].push(tm);
      } else {
        var tm = {
          "deadline": plan_data[index]['deadline'],
          "tat": plan_data[index]['tat'],
          "pricing": plan_data[index]['pricing']
        }
        temp[plan_data[index]['service']['id']].push(tm);
      }
    }

    let tempPlanList = plans.map(item => {
      item['tat_list'] = temp[item.id];
      item['isSelected'] = false;
      return item;
    })
    plans = [];
    temp = {};
    if (tempPlanList.length > 0) {
      tempPlanList[0].isSelected = true;
      this.selectedDilevery = tempPlanList[0]['tat_list'][tempPlanList[0]['tat_list'].length - 1];
      this.selectedDeliveryPlan = tempPlanList[0];
    }
    return tempPlanList;
  }
  processEnagoDataCsAssist(plan_data) {
    plan_data = this.removeServices(plan_data, this.pageConfigDetails['localeCulture'])

    var temp = plan_data.map(item => {
      item['service']['isSelected'] = false;
      return item;
    })
    if (temp.length > 0) {
      temp[0]['service'].isSelected = true;
    }
    this.selectedDeliveryPlan = temp[0]['service'];
    this.selectedDilevery = temp[0]['tats'][temp[0]['tats'].length - 1];

    return temp;
  }

  changeDeliveryPlans(selectedDeliveryPlan) {
    if (this.isAutoMode) {
      this.selectedDeliveryPlan = selectedDeliveryPlan;
      this.enagoPlans = this.enagoPlans.map(item => {
        item['isSelected'] = false;
        return item;
      })
      this.selectedDeliveryPlan['isSelected'] = true;
      this.selectedDilevery = this.selectedDeliveryPlan['tat_list'][this.selectedDeliveryPlan['tat_list'].length - 1];
    } else {
      this.selectedDeliveryPlan = selectedDeliveryPlan['service'];
      this.selectedDilevery = selectedDeliveryPlan;
      this.enagoPlans = this.enagoPlans.map(item => {
        item['service']['isSelected'] = false;
        return item;
      })
      this.selectedDeliveryPlan['isSelected'] = true;
      this.selectedDilevery = this.selectedDilevery['tats'][this.selectedDilevery['tats'].length - 1];
    }
  }

  openLangPrefModal() {
    this.sharedService.openLanguagePreferences()
  }

  onSelectionChange(e) {
    if (e.value == true) {
      this.journalFlag = false;
    }
    else {
      this.journalFlag = true;
      this.validateJournalURL = false;
      this.enagoProofReadingfForm.value.journalUrl = '';
    }
  }

  filterSubjectArea(SAlist) {

    var temp = [];
    return SAlist.filter(sa => {
      if (sa.levelNo == 3) {
        if (!temp.includes(sa.text)) {
          temp.push(sa.text);
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })
  }

  async getSubjectAreas() {
    this.subjectAreaListArray = [];
    this.networkCalls.subjectAreaRequest(this.userId).subscribe(result => {
      this.subjectAreaList = [];
      if (result['status']) {
        this.subjectAreaListArray = this.filterSubjectArea(result['data']['areaDetails']);
        this.subjectiveBehaviorSharedService.setSubjectAreaList(this.subjectAreaListArray)
        // this.subjectAreaList = this.subjectAreaListArray.filter(item => {
        //   if (item.levelNo === 3) {
        //     return item;
        //   }
        // });
        // this.subjectAreaListArray = this.subjectAreaList;
        this.getMatchValue();
        // this.validateAllFormFields(this.enagoProofReadingfForm);
      } else {
        this.toastr.warning(result['message'])
      }
    }, error => {
      // this.spinner.hide();
      this.editorLoader = false;
      this.sharedService.errorHandller(error);
    });
  }



  user_added_subject_area: any;
  selected_subject_area: any;
  selectDateAlert = false;
  getMatchValue() {
    var x = 0

    this.subjectiveBehaviorSharedService.getDocTypeObject.subscribe(document_type => {
      this.user_added_subject_area = document_type.user_added_subject_area;
      this.selected_subject_area = document_type.selected_subject_area;
      if (document_type && document_type.subject_area_list) {
        this.subjectType = JSON.parse(JSON.stringify(document_type.subject_area_list));
        var tempDocType = this.subjectType.filter(item => { return item.isSelected; })
        if (tempDocType.length > 0) {
          this.enagoProofReadingfForm.controls['subjectArea'].setValue(tempDocType[0]['label']);
        }
      }


      if (this.enagoProofReadingfForm.value.subjectArea == "" && this.planDataLabelList && this.planDataLabelList.length) {
        for (var j = 0; j < this.planDataLabelList.length; j++) {
          for (var i = 0; i < this.subjectAreaListArray.length; i++) {
            if (this.subjectAreaListArray[i].text == this.planDataLabelList[j].label) {
              x = 1
              this.enagoProofReadingfForm.controls['subjectArea'].setValue(this.subjectAreaListArray[i].text);
              break;
            }
          }
          if (x == 1) {
            break;
          }
        }
      }

      if (this.enagoProofReadingfForm.value.subjectArea == "") {
        for (var i = 0; i < this.subjectAreaListArray.length; i++) {
          if (this.subjectAreaListArray[i].text == this.selectedProfile['othersubjectarea']) {
            this.enagoProofReadingfForm.controls['subjectArea'].setValue(this.subjectAreaListArray[i].text);
          }
        }
      }
      if (this.enagoProofReadingfForm.value.subjectArea == "") {
        if (this.planDataLabelList && this.planDataLabelList.length) {
          this.enagoProofReadingfForm.controls['subjectArea'].setValue(this.planDataLabelList[0].label);
        }

      }
      // this.validateAllFormFields(this.enagoProofReadingfForm);
    });

  }



  onEnagoFormSubmit(enagoFormValues) {
    if (this.enagoProofReadingfForm.valid) {
      if (this.validJournalUrl()) {
        if (this.validateDatepicker()) {
          this.submitEnagoFormRequest();
        }
      }
    } else {
      this.validJournalUrl();
      this.validateDatepicker();
      this.validateAllFormFields(this.enagoProofReadingfForm);
    }
  }

  validateDatepicker() {
    if (this.datePickerModel == undefined && this.isAutoMode == false) {
      this.selectDateAlert = true;
      return false;
    } else {
      this.selectDateAlert = false;
      return true;
    }
  }

  submitEnagoFormRequest() {
    let payload = {};
    payload['file_name'] = this.enagoPlansFullData['file_name'];
    payload['file_size'] = this.enagoPlansFullData['file_size'];
    payload['word_count'] = this.enagoPlansFullData['word_count'];
    payload['image_count'] = this.enagoPlansFullData['image_count'];
    payload['upload_file_data'] = this.enagoPlansFullData['upload_file_data'];
    payload['subject_area'] = this.getSelectedSubjectArea(this.enagoProofReadingfForm.value.subjectArea);
    payload['journal_formatting'] = this.enagoProofReadingfForm.value.journalFormatting;
    payload['journal_url'] = this.enagoProofReadingfForm.value.journalUrl;
    payload['is_automode'] = this.isAutoMode;
    // this.spinner.show();
    this.editorLoader = true;
    if (this.isAutoMode) {
      this.selectedDilevery['service'] = {
        "text": this.selectedDeliveryPlan['text'],
        "id": this.selectedDeliveryPlan['id'],
        "index": this.selectedDeliveryPlan['index'],
        "description": this.selectedDeliveryPlan['description'],
      }
      if (isNaN(this.selectedDilevery['pricing']['totalDiscountedOrderAmount'])) {
        if (this.selectedDilevery['pricing']['totalDiscountedOrderAmount'].includes(",")) {
          this.selectedDilevery['pricing']['totalDiscountedOrderAmount'] = this.selectedDilevery['pricing']['totalDiscountedOrderAmount'].replace(",", "")
        }
      }
      if (isNaN(this.selectedDilevery['pricing']['netPayableDiscountedAmount'])) {
        if (this.selectedDilevery['pricing']['netPayableDiscountedAmount'].includes(",")) {
          this.selectedDilevery['pricing']['netPayableDiscountedAmount'] = this.selectedDilevery['pricing']['netPayableDiscountedAmount'].replace(",", "")
        }
      }
      if (this.isFloatFlag) {
        this.selectedDilevery['pricing']['totalDiscountedOrderAmount'] = parseFloat(this.selectedDilevery['pricing']['totalDiscountedOrderAmount'])
        this.selectedDilevery['pricing']['netPayableDiscountedAmount'] = parseFloat(this.selectedDilevery['pricing']['netPayableDiscountedAmount'])
      } else {
        this.selectedDilevery['pricing']['totalDiscountedOrderAmount'] = parseInt(this.selectedDilevery['pricing']['totalDiscountedOrderAmount'])
        this.selectedDilevery['pricing']['netPayableDiscountedAmount'] = parseInt(this.selectedDilevery['pricing']['netPayableDiscountedAmount'])

      }
      payload['plan_data'] = this.selectedDilevery;
      payload['pageConfigData'] = this.enagoPlansFullData['pageConfigData'];
      payload['full_profile_data'] = this.enagoPlansFullData['profile_data'];
      payload['iscreditriskapplicable'] = this.pageConfigDetails['evaluateCreditRiskAtPayment'];
      payload['iccode'] = this.pageConfigDetails['subdomainMeta']['icCode'];
      payload['brandId'] = this.pageConfigDetails['subdomainMeta']['brandId'];
      payload['locale'] = this.pageConfigDetails['locale'].split("-")[0];
      payload['deadline'] = this.selectedDilevery['deadline']['output'];
      payload['deadlineformated'] = this.dateFormatTransformLogi(this.selectedDilevery['deadline']['output'], this.pageConfigDetails['dateTimeFormat']);
    } else {
      var temp = this.datePickerModel.year + "-" + this.datePickerModel.month + "-" + this.datePickerModel.day;
      var iso = new Date(temp).toISOString();
      payload['deadlineObject'] = {
        "date": this.datePickerModel,
        "jsdate": iso,
        "formatted": temp,
        "epoc": new Date(iso).getTime() / 1000
      }
      payload['plan_data'] = {
        "service": this.selectedDeliveryPlan,
        "tat": this.selectedDilevery
      }
      payload['clientDeadline'] = temp + " 00:00";
      payload['pageConfigData'] = this.enagoPlansFullData['pageConfigData'];
      payload['full_profile_data'] = this.enagoPlansFullData['profile_data'];
    }
    if (this.isAutoMode && this.pageConfigDetails['subdomainMeta']['advancedPaymentPercentage'] > 0 && !this.selectedProfile['isMonthlyInvoiceClient']) {
      payload['make_payment'] = true;
    } else {
      payload['make_payment'] = false;
    }
    this.processing_flag = true;
    this.networkCalls.sendToProofreadingMakePayment(this.userId, this.sharedService.currentFileId, payload).subscribe(result => {
      if (result['status']) {
        // this.spinner.hide();
        this.editorLoader = false;
        if (result['data']['payment_url'] != undefined) {
          window.location.href = result['data']['payment_url'];
        } else {
          this.modalService.dismissAll();
          this.paymentResponceObj = result['data'];
          this.paymentResponceObj['status'] = 1;
          this.transactionStatus = true;
          this.make_payment = false;
          setTimeout(() => {
            this.modalService.open(this.paymentStatus, { size: 'md paymentPopup', backdrop: 'static' });
          }, 2000);
        }
      } else {
        // this.spinner.hide();
        this.editorLoader = false;
        this.toastr.warning(result['message'])
      }
      this.amplitudeService.logAmplitude('edit_service_confirm', {
        'fileID': this.sharedService.currentFileId,
        'level': this.selectedDeliveryPlan['text'],
        'tat': this.selectedDilevery['deadline']['process'],
        'cost': this.selectedDilevery['pricing']['totalDiscountedOrderAmount'],
        'subject': payload['subject_area']['text'],
        'journal': payload['journal_url'],
        'journal_format ': payload['journal_formatting']
      });
    }, error => {
      this.processing_flag = false;
      // this.spinner.hide();
      this.editorLoader = false;
      this.sharedService.errorHandller(error);
    })
  }

  dateFormatTransformLogi(value: string, format: string): string {
    if (value && format) {
      let str = humanize.date(format, new Date(value));
      return str
      // .replace(/\b([10-9]{1,3})(th|nd|rd|st)\b/g, "$1<sup>$2<\/sup>");
    }
    else
      return "";
  }


  getSelectedSubjectArea(subjectArea) {
    let holder = this.subjectAreaListArray.filter(item => {
      if (item.text == subjectArea) {
        return true;
      } else {
        return false
      }
    })
    if (holder.length > 0) {
      holder[0]['userInputText'] = holder[0]['text'];
      return holder[0]
    } else {
      return {
        "id": -1,
        "areaType": 1,
        "levelNo": 3,
        "parentId": 9,
        "text": "",
        "userInputText": subjectArea
      }
    }
  }

  validJournalUrl() {
    if (this.enagoProofReadingfForm.value.journalFormatting == true && this.enagoProofReadingfForm.value.journalUrl == "") {
      this.validateJournalURL = true;
      return false;
    } else {
      this.validateJournalURL = false;
      return true;
    }
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  onTypeSubjectArea() {
  }
  onSubjectAreaCapture(model) {
    this.subjectArea = model.item;
  }
  journalName = "";
  onJournalNameCapture(model) {
    // this.journalName = model.item;
  }

  createNewFileCall() {
    if (environment.trialMode) {
      this.dialog.open(SignupaccessComponent);
      return;
    }
    // this.spinner.show();
    this.editorLoader = true;
    this.networkCalls.createNewFile(this.userId).subscribe(result => {

      this.amplitudeService.logAmplitude('file_new', {
        'userID': this.userId,
        'fileID': result['data']['file_id'],
        'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0'
      });
      // this.router.navigate(['/editor/main', result['data']['file_id']], { relativeTo: this.route })
      //Enabling new editor for BASIC & PREMIUM_PLUS user for now
      let redirectRoute = this.creditsDetails['user_type'] === 'BASIC' || this.creditsDetails['user_type'] === 'PREMIUM_PLUS' ? "/editor/main-v1/" : "/editor/main/";
      document.location.href = document.location.origin + redirectRoute + result['data']['file_id'];
      // this.spinner.hide();
      this.editorLoader = false;
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }


  uploadFile() {
    if (environment.trialMode) {
      this.dialog.open(SignupaccessComponent);
      return;
    }

    var input = document.createElement('input');
    input.type = 'file';

    input.onchange = e => {
      this.amplitudeService.logAmplitude('ED_Upload_via_Localdevice', {
        'editor': this.isNewEditor ? 'V1' : 'V0'
      });
      var file = e.target['files'][0];
      let fileSize = file.size;
      fileSize = (fileSize / 1024) / 1024;
      let fileType = file.name.split('.').pop().toLowerCase().toString();
      if (['doc', 'docx', 'txt'].indexOf(fileType) != -1) {
        if (fileSize <= 25) {
          var formData = new FormData();
          formData.append('file', file);
          // this.spinner.show();
          this.editorLoader = true;
          this.networkCalls.uploadFile(this.userId, formData).subscribe(result => {

            this.amplitudeService.logAmplitude('file_upload', {
              'userID': this.userId,
              'format': fileType,
              'fileID': result['data']['file_id'],
              'editor': this.isNewEditor ? 'V1' : 'V0'
            });
            // this.spinner.hide();
            this.editorLoader = false;
            // this.router.navigate(['/editor/main', result['data']['file_id']])
            //Enabling new editor for BASIC & PREMIUM_PLUS user for now
            let redirectRoute = this.creditsDetails['user_type'] === 'BASIC' || this.creditsDetails['user_type'] === 'PREMIUM_PLUS' ? "/editor/main-v1/" : "/editor/main/";
            document.location.href = document.location.origin + redirectRoute + result['data']['file_id'];
            // document.location.reload();
          }, error => {
            this.sharedService.errorHandller(error);
          })
        } else {
          this.toastr.error("File size is greater than 25MB", "Error");
        }
      } else {
        this.toastr.error('Please upload file with doc, docx,txt format.', "Error");
      }
    }
    input.click();
  }


  signOut() {
    this.sharedService.SignOut();
  }

  downloadFile() {
    if (environment.trialMode) {
      this.dialog.open(SignupaccessComponent);
      return;
    }

    if (this.sharedService.featurePermission.data.track_changes === false) {
      this.sharedService.downloadFile(false);
    } else {
      this.dialog.open(DownloadfilepopupComponent, { width: '500px' });
    }
  }

  selectedProfile = {};
  getSelectedProfileData() {
    this.verifiedUserService.getSelectedProfileData();
  }
  private dbTemp;
  featurePermissionRequest() {
    this.dashboardService.getStoreUserFeatureDetails().subscribe(resp => {
      if (Object.keys(resp).length == 0) {
        this.networkCalls.featurePermission().subscribe(res => {
          // =========================================
          this.dbTemp = new Dexie('test');
          this.dbTemp.version(2).stores({
            test: '&splitText',
          });
          // this.sharedService.featurePermission.browser_caching = false;
          this.dbTemp.open().catch(function (err) {
            console.error('Failed to open db: ' + (err.stack || err));
            res.data.browser_caching = false;
          });
          this.sharedService.featurePermission = res;
          this.dashboardService.storeUserFeatureDetails(res);
          // =========================================

          // if (this.sharedService.featurePermission.data.enago_proofreading) {
          //   this.getPageConfig();
          // }
          if (this.sharedService.featurePermission.data.document_style || this.sharedService.featurePermission.data.enago_proofreading) {
            // this.getSubjectAreas();
          }
          if (this.sharedService.featurePermission.data.publication) {
            this.getAllSubmitedReportRevision(true);
          }
        }, error => {
          this.sharedService.errorHandller(error);
        })
      }
      else {
        this.dbTemp = new Dexie('test');
        this.dbTemp.version(2).stores({
          test: '&splitText',
        });
        // this.sharedService.featurePermission.browser_caching = false;
        this.dbTemp.open().catch(function (err) {
          console.error('Failed to open db: ' + (err.stack || err));
          resp.data.browser_caching = false;
        });
        this.sharedService.featurePermission = resp;
        // if (this.sharedService.featurePermission.data.enago_proofreading) {
        //   this.getPageConfig();
        // }
        if (this.sharedService.featurePermission.data.document_style || this.sharedService.featurePermission.data.enago_proofreading) {
          // this.getSubjectAreas();
        }
        if (this.sharedService.featurePermission.data.publication) {
          this.getAllSubmitedReportRevision(true);
        }
      }
    });

  }

  documentTypeChange() {
    if (environment.trialMode) {
      this.dialog.open(SignupaccessComponent);
      return;
    } else {
      if (this.document_type != 1) {
        this.styleGuide = 'NONE';
        this.subjectiveBehaviorSharedService.changeStyleGuide(this.styleGuide, true);
        this.docTypeObject['selected_document_type'] = this.document_type;
        // this.docTypeObject['subject_area_list']

        var data = {
          document_type: this.document_type,
          is_document_type: true,
          style_guide: this.styleGuide
        };

        this.networkCalls.putUpdateFileForDocumentType(this.userId, this.sharedService.currentFileId, data).subscribe(resp => {
          this.docTypeObject['selected_document_type'] = resp.data['document_type'];
          this.subjectiveBehaviorSharedService.setDocumentTypeData(this.docTypeObject, false);

          if (this.docTypeObject['selected_document_type'] != 1) {
            this.amplitudeService.logAmplitude('writing_type_set', {
              'fileID': this.sharedService.currentFileId,
              'type': this.docTypeObject['selected_document_type'] == 3 ? 'academic' : 'general',
              'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0'
            });
            this.amplitudeService.logAmplitude('PU_Document_Type', {
              'fileID': this.sharedService.currentFileId,
              'type': this.documentTypeList[this.docTypeObject['selected_document_type'] - 1],
              'editor': this.isNewEditor ? 'V1' : 'V0'
            });
          }
        });
      }
    }
  }

  openAcademicPopUp() {
    if (environment.trialMode) {
      return
    }

    const dialogRef = this.dialog.open(DocumentTypeComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result == "success") {
        this.docTypeObject['selected_document_type'] = this.document_type;
      } else {
        this.document_type = this.docTypeObject['selected_document_type']
      }
    });
    // this.subjectiveBehaviorSharedService.setDocumentTypeData(this.docTypeObject, false);
  }

  checkStatus: boolean = false;
  previousCheck = "A1";
  oldestCheck = "";
  publicationEvent(selectedMode, module) {
    this.checkCurrentLanguage();
    if (!this.currentLanguage.includes('en')) {
      this.dialog.open(LanguageAlertModalComponent, {
        panelClass: 'afe-info'
      });
      this.amplitudeService.logAmplitude('Important_note_pu_language_alert', {
        'option': 'publication check'
      });
      return;
    }
    if (this.trialMode) {
      this.sharedService.openSignUpAccessDialog();
      return;

    }
    this.checkStatus = !this.checkStatus;
    this.plagiarismCheckStatus = false;
    if (this.previousCheck != module) {
      this.previousCheck = module;
      if (this.checkStatus == false) {
        this.activeModule = ModuleCheck.GC;
      } else {
        this.activeModule = module;
      }
      this.subjectiveBehaviorSharedService.setModuleCheck(this.activeModule)
    } else {
      // this.activeModule = module;
      // this.subjectiveBehaviorSharedService.setModuleCheck(this.oldestCheck);
    }
    this.subjectiveBehaviorSharedService.moduleChange.next('none');
    this.amplitudeService.logAmplitude('ED_Rightpanel', {
      'option': 'publication check',
      'editor': this.isNewEditor ? 'V1' : 'V0'
    });
  }
  publishModuleCheck(module) {
    this.checkCurrentLanguage();
    if (!this.currentLanguage.includes('en') && module !== ModuleCheck.GC) {
      this.dialog.open(LanguageAlertModalComponent, {
        panelClass: 'afe-info'
      });
      this.amplitudeService.logAmplitude('Important_note_pu_language_alert', {
        'option': 'consistency check'
      });
      return;
    }
    if (this.trialMode) {
      if (module != ModuleCheck.GC) {
        this.sharedService.openSignUpAccessDialog();
      }
      return;

    }

    this.oldestCheck = module;
    this.checkStatus = false;
    this.isParaphraserTabOpen = false;
    this.activeModule = module;
    this.subjectiveBehaviorSharedService.setModuleCheck(module);

    let mapping = {
      [ModuleCheck.GC]: 'grammar',
      [ModuleCheck.CC]: 'consistency'
    }

    this.amplitudeService.logAmplitude('ED_Rightpanel', {
      'option': mapping[module] ? mapping[module] : module,
      'editor': this.isNewEditor ? 'V1' : 'V0'
    });
  }

  referenceCheckEvent(type, module) {
    this.checkCurrentLanguage();
    if (!this.currentLanguage.includes('en')) {
      this.dialog.open(LanguageAlertModalComponent, {
        panelClass: 'afe-info'
      });
      this.amplitudeService.logAmplitude('Important_note_pu_language_alert', {
        'option': 'citation checker'
      });
      return;
    }
    this.amplitudeService.logAmplitude('RFQ_Editor_CTA', {
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits,
      'File ID': this.sharedService.currentFileId,
    });

    // TODO: amplitude event duplicate
    setTimeout(() => {
      this.amplitudeService.logAmplitude('ED_Rightpanel', {
        'option': 'citation checker',
        'editor': this.isNewEditor ? 'V1' : 'V0'
      });
    }, 1000);

    // this.checkStatus = false;
    // if (this.previousCheck != module) {
    //   this.previousCheck = module;
    //   if (this.checkStatus == false) {
    //     this.activeModule = ModuleCheck.RC;
    //   } else {
    //     this.activeModule = module;
    //   }
    // }
    // this.subjectiveBehaviorSharedService.setModuleCheck(this.activeModule)

    let dialogRef = this.dialog.open(EditorReferenceQualityCheckModalComponent, {
      data: {
        fileId: this.sharedService.currentFileId,
        freeCredit: this.creditsDetails.credits,
        purchaseCredit: this.creditsDetails.purchased_credits,
        is_premium: this.paymentDetails["is_premium"]
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.subjectiveBehaviorSharedService.setEditorFileId(this.sharedService.currentFileId);
        this.subjectiveBehaviorSharedService.setEditorFileName(this.file_name);
        this.router.navigate(['/citation-check-tool']);
      }
      else {
        this.amplitudeService.logAmplitude('RFQ_EditorPopup_Cancel_CTA', {
          'Credit Balance Free': this.creditsDetails.credits,
          'Credit Balance Purchased': this.creditsDetails.purchased_credits,
          'File ID': this.sharedService.currentFileId
        });
      }
    });
  }
  getAllSubmitedReportRevision(initial = false) {
    // var fileId = this.sharedService.getCurrentFileId();
  //   this.reportArray = [
  //     {
  //         "submission_id": "21831114-ff3e-4de4-a385-337f5b81a2de",
  //         "document_status": "IN_PROGRESS",
  //         "credit_used": 1,
  //         "created_at": "2024-06-04T10:22:56.696556Z",
  //         "updated_at": "2024-06-04T10:22:56.696580Z"
  //     }
  // ];
    this.networkCalls.requestAllSubmitedReportRevision(this.paramFileId).subscribe(result => {
      // this.spinner.hide();
      this.editorLoader = false;
      if (result['message'] === 'success') {
        this.sharedService.a1ReportData.data = result['data']

        if (result['data'].length > 0) {
          var checkStatus = this.sharedService.a1ReportData.data.filter(data => {
            return data.document_status === 'IN_PROGRESS';
          });
          if (checkStatus.length > 0) {
            setTimeout(() => {
              this.getAllSubmitedReportRevision();
            }, 60000);
          }
          this.reportArray = result['data'];
          console.log("this.reportArray ", this.reportArray)
        }
      }
    }, error => {
      // this.spinner.hide();
      this.editorLoader = false;
      this.sharedService.errorHandller(error);
    });
    if (initial) {
      this.loadCreditDetails();
    }
    else {
      this.getCreditsDetails();
    }
  }


  plagiarismCheckStatus: boolean = false;

  plagiarismEvent(selectedMode, module) {
    this.checkCurrentLanguage();
    if (!this.currentLanguage.includes('en')) {
      this.dialog.open(LanguageAlertModalComponent, {
        panelClass: 'afe-info'
      });
      this.amplitudeService.logAmplitude('Important_note_pu_language_alert', {
        'option': 'plagiarism checker'
      });
      return;
    }
    if (this.trialMode) {
      this.sharedService.openSignUpAccessDialog();
      return;

    }

    this.amplitudeService.logAmplitude('Plagiarism_Editor_CTA', {
      'userID': this.userId,
      'credit_balance_free': this.creditsDetails.credits,
      'credit_balance_purchased': this.creditsDetails.purchased_credits,
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId,
    });

    this.plagiarismCheckStatus = !this.plagiarismCheckStatus;
    this.checkStatus = false;
    this.previousCheck = this.activeModule;
    this.isParaphraserTabOpen = false;
    if (this.previousCheck != module) {
      this.previousCheck = module;
      if (this.checkStatus == false) {
        this.activeModule = ModuleCheck.PC;
      } else {
        this.activeModule = module;
      }
      this.subjectiveBehaviorSharedService.setModuleCheck(this.activeModule)
    } else {
    }

    // let dialogRef = this.dialog.open(EditorPlagiarismModalComponent);
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.subjectiveBehaviorSharedService.setEditorFileId(this.sharedService.currentFileId);
    //     this.subjectiveBehaviorSharedService.setEditorFileName(this.file_name);
    //     this.router.navigate(['/plagiarism-checker/upload']);
    //   }
    // });

    // TODO: amplitude event duplicate
    setTimeout(() => {
      this.amplitudeService.logAmplitude('ED_Rightpanel', {
        'option': 'plagiarism checker',
        'editor': this.isNewEditor ? 'V1' : 'V0'
      });
    }, 1000);
  }

  journalFinderStatus: boolean = false;
  journalFinderEvent(selectedMode, module) {
    this.amplitudeService.logAmplitude('ED_Rightpanel', {
      'option': module,
      'editor': this.isNewEditor ? 'V1' : 'V0'
    });
    window.open('/journal-finder', '_blank')
    // this.checkCurrentLanguage();
    // if (!this.currentLanguage.includes('en')) {
    //   this.dialog.open(LanguageAlertModalComponent, {
    //     panelClass: 'afe-info'
    //   });
    //   this.amplitudeService.logAmplitude('Important_note_pu_language_alert', {
    //     'option': 'journal finder'
    //   });
    //   return;
    // }
    // if (this.trialMode) {
    //   this.sharedService.openSignUpAccessDialog();
    //   return;

    // }

    // this.amplitudeService.logAmplitude('JFR_Editor_CTA', {
    //   'Credit Balance Free ': this.creditsDetails.credits,
    //   'Credit Balance Purchased': this.creditsDetails.purchased_credits,
    //   'File ID': this.sharedService.currentFileId,
    // });

    // // TODO: amplitude event duplicate
    // setTimeout(() => {
    //   this.amplitudeService.logAmplitude('ED_Rightpanel', {
    //     'option': 'journal finder'
    //   });
    // }, 1000);

    // let dialogRef = this.dialog.open(EditorJournalFinderModalComponent);
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.amplitudeService.logAmplitude('JFR_Editpopup_Confirm', {
    //       'Credit Balance Free ': this.creditsDetails.credits,
    //       'Credit Balance Purchased': this.creditsDetails.purchased_credits,
    //       'File ID': this.sharedService.currentFileId,
    //     });
    //     this.router.navigate(['/journal-finder']);
    //   }
    //   else {
    //     this.amplitudeService.logAmplitude('JFR_Editpopup_cancel', {
    //       'Credit Balance Free ': this.creditsDetails.credits,
    //       'Credit Balance Purchased': this.creditsDetails.purchased_credits,
    //       'File ID': this.sharedService.currentFileId,
    //     });
    //   }
    // });
  }

  /**@description:  upload document for Author One on RUN CHECK */
  runFlag = false;
  submitDocumentForCheck(journalName: string) {
    // var fileId = this.sharedService.getCurrentFileId();
    var formData = new FormData();
    formData.append("journal_name", journalName)
    // this.spinner.show();
    this.editorLoader = true;
    this.networkCalls.submitDocumentForCheck(this.paramFileId, formData).subscribe(result => {
      // this.spinner.hide();
      this.editorLoader = false;
      this.amplitudeService.logAmplitude('pubcheck_run', {
        'journal': journalName,
        'fileID': this.sharedService.currentFileId,
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits
      });
      if (result['message'] == 'success') {
        this.runFlag = true;
        this.journalName = "";
        this.getAllSubmitedReportRevision();
      }
    }, error => {
      // this.spinner.hide();
      this.editorLoader = false;
      this.sharedService.errorHandller(error);
    })
    this.getCreditsDetails()
  }

  /**@description:  VIEW REPORT of particular file of Author One*/
  viewReport(submission_id) {
    this.checkStatus = false;
    // var fileId = this.sharedService.getCurrentFileId();
    // this.spinner.show();
    this.editorLoader = true;
    this.networkCalls.getGeneratedReportByFileIdSubmissionId(this.paramFileId, submission_id).subscribe(result => {
      // this.spinner.hide();
      this.editorLoader = false;
      this.amplitudeService.logAmplitude('pubcheck_view', {
        'fileID': this.sharedService.currentFileId
      });
      if (result['message'] === 'success') {
        this.activeModule = ModuleCheck.A1;
        this.subjectiveBehaviorSharedService.setA1AllCheckReportData(result['data']['report'])
      }
      // this.spinner.hide();
      this.editorLoader = false;
    }, error => {
      // this.spinner.hide();
      this.editorLoader = false;
      this.sharedService.errorHandller(error);
    });
    this.subjectiveBehaviorSharedService.setModuleCheck('A1');
  }

  openShareModal() {
    if (environment.trialMode) {
      this.dialog.open(SignupaccessComponent);
      return;
    }
    this.sharedService.openShareDialog();
  }

  sampleData = [
    { 'key': "General", 'text': "The article was written on 1984. It was about this experiments that were carried out on some isolated village. The villages (Lulbushki) was located in a mountaineous region and well built yak roamed the pastures. The inhabitants in Lulbushki depended of the pastures. On 25st of Nvember 1984, some of the yak were rounded up in accordance to the new animal husbandry law of Peking and informations about what would be done was announced. The yak were coloured white to distinguished them but it was hard to make out teh difference. They were terrified and could possibly have undergone trauma. The yak like to roam freely in nature and do grazing in pastures with their herdsmen guarding them. The herdsmen understand how valuable theur yak are to they. However, they may end up to sell them." },
    { 'key': "Medicine", 'text': "Pre-treatment using antibiotic is said to affect clinical features of meningitis. That is why it is stated that LP should been carried out in childrens experiencing fever and seizures who have received antibiotic. As it has been found in a recent study. CSF becomes sterile for meningococccal and pneumococcal bacteria between 2 to 4 hours of administrating third-generation cephalosporin antibiotics. A lot of studies have been done on efficacy of lumbar puncture in febrile convulsion patients. Futhermore retrospective cohort reviews for children age 7 month to 6 year in 2011 found that probability for bacterial meningitis first presenting as an simple febrile seizure is very low. And they recommend that teh current PMC guidelines should be reconsidered. Hence, this observational , cross sectional, hospital-based study was carried out in Hemon Clinic, Samoa, during the period from June 15, 2019 and 28 November 2019. Fifty five children aged 7 month to 6 years who presented with fever and convulsion underwent lubmar puncture." },
    { 'key': "Management", 'text': "At the time of the period of the study, the dummy variables representing personal and workplace characters reached to different values. Although both genders experienced increase in the number of jobs involving the management and supervision of other employee, but this increase was greater for men. This finding may due to firms retaining workers whom are more integrted with in the structure of the company. These worker would be able to do more tasks needing higher level of responsibility then those who had joined the company more recent. Regarding types of contract there was no significant change into the mean percentage of full-time contracts, in spite of the fact that it remains less common for women to get this types of contract. Never the less there was a average increase of 10 percentage points in indefinite hiring for women, which is suggesting that there was increased job stabilization for qualified white collar women with average values approaching those for men." },
    {
      'key': "Psychology", 'text': "Depression is most common mental disorder among HIV/AIDS patients with prevalence rates of about 60 %. Studies found that HIV patients had twice the risk for depression than those who were at risk for HIV. Depression and HIV/AIDS are estimated become the world’s leading causes of disability by 2030 [4-6]. Low- and middle-income countries are bearing the high burden of depression among HIV/AIDS patients. The symptoms of depressive disorders decrease adherence to HIV antiretroviral therapy leading to a drug resistant virus [7]. " +
        "Decreased social support with in the context of HIV/AIDS is related to increase depression because of various factor, like educational disability and food insecurity. Low social support could result in poor adherence of medication, leads to immune suppression which finally leads to depression. Furhtermore, poor social support may lead to social isolation, which can be responsible in depression. Social isolation by HIV patients reduces social support that can results in a negative impcat on their physical and mental well being."
    },
    // {'key':"Science", 'text': "Science is a systematic enterprise that builds and organizes knowledge in the form of testable explanations and predictions about the universe."},
    // {'key':"Social", 'text': "If you are social, you like to be around people. The word social comes from the Latin socius meaning friend."},
    // {'key':"Engineering", 'text': "Engineering, the application of science to the optimum conversion of natural resources to the uses of humankind."},
    // {'key':"Chemistry", 'text': "This text should be in Chemistry"},
    // {'key':"Geology", 'text': "This text should be in Geology"},
    // {'key':"Computer", 'text': "This text should be in Computer"},
    // {'key':"Economics", 'text': "This text should be in Economics"}
  ]

  addSampleData(event: MatRadioChange) {
    let data = this.sampleData.filter(obj => { return obj.key == event.value })
    let content = data[0].text;
    this.childComponent.addSampleData(content);
  }

  onActivate(componentRef) {
    this.childComponent = componentRef;

  }

  redirectTo(location, isNewTab = false) {
    if (this.trialMode) {
      this.dialog.open(SignupaccessComponent)
      return
    } else {
      if (location == "/dashboard/citation-check") {
        this.amplitudeService.logAmplitude('RFQ_MyDrive_CTA', {
          'Credit Balance Free': this.creditsDetails.credits,
          'Credit Balance Purchased': this.creditsDetails.purchased_credits
        });
      }
      if (isNewTab) {
        const link = this.router.serializeUrl(this.router.createUrlTree([location]));
        window.open(link, '_blank');
      }
      else {
        this.router.navigate([location]);
      }
    }
  }

  upgradePlanPopup() {
    let data = {
      "user_id": this.userId
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url = environment.payment_fe;
    this.amplitudeService.logAmplitude('ED_upgrade_nudge', {
      'from': '1. Top strip',
      'credit_balance_purchased': this.creditsDetails.purchased_credits,
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId,
      'editor': this.isNewEditor ? 'V1' : 'V0'
    });
    window.open(redirct_url + '/user/' + this.userData, "_blank")
  }

  getUserPaymentSubscription() {
    this.dashboardService.getStoreUserPlanDetails().subscribe(resp => {
      if (Object.keys(resp).length == 0) {
        this.networkCalls.getUserPaymentSubscription(this.userId).subscribe(response => {
          //console.log("response['data'] ", response['data'])
          this.paymentDetails = response['data'];
          this.dashboardService.storeUserPlanDetails(this.paymentDetails);
          if(this.paymentDetails.user_type=="PREMIUM_TRIAL"){
            this.getSubsciptionDetails();
          }
        });
      }
      else {
        this.paymentDetails = resp;
        if(this.paymentDetails.user_type=="PREMIUM_TRIAL"){
          this.getSubsciptionDetails();
        }
      }

    });
  }

  getCreditsDetails() {

    this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
      if (response["status"]) {
        this.creditsDetails = response.data;
        this.dashboardService.storeUserCreditDetails(this.creditsDetails);
      }
      this.totalCredits = this.creditsDetails.credits + this.creditsDetails.purchased_credits;
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }


  credits() {
    if (this.creditsDetails != null) {
      if (this.checkStatus) {
        this.amplitudeService.logAmplitude('pubcheck_credit_view', {
          'credit_balance_free': this.creditsDetails.credits,
          'credit_balance_purchased': this.creditsDetails.purchased_credits
        });
      }
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData: this.creditsDetails,
      };
      this.dialog.open(CreditDetailsComponent, dialogConfig)
    }
  }

  addCredits() {
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type, window.location.href);
    this.amplitudeService.logAmplitude('pubcheck_credit_buy', {
      'credit_balance_free': this.creditsDetails.credits,
      'credit_balance_purchased': this.creditsDetails.purchased_credits
    });
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url = environment.payment_fe;
    window.open(redirct_url + '/user/credit/' + this.userData, "_self");
  }

  getUserFileDetails() {
    this.networkCalls.getEditorUploadFileDetails(this.userId, this.paramFileId).subscribe(result => {
      let data = result.data;
      this.user_decoded_id = data.user_id;
      this.file_name = data.file_name;
      this.file_id = data.file_id;
    });
    this.subjectiveBehaviorSharedService.getEditorEnglishPrefernce().subscribe((language) => {
      this.language = language;
    });
  }

  launchGoogleDrivePicker() {
    this.resetCloudFileDetails();
    this.uploadSource["GOOGLE_DRIVE"] = true;
    this.cloudDriveFileDetails.fileId = "";

    this.googleDrivePickerService.open((data) => {
      if (data.action === 'picked') {
        let file_details = data.docs[0];
        if (!(file_details.name.endsWith(".doc") || file_details.name.endsWith(".docx") || file_details.name.endsWith(".txt"))) {
          file_details.name += ".docx"
        }
        this.cloudDriveFileDetails.fileId = file_details.id;
        this.cloudDriveFileDetails.fileName = file_details.name;
        this.cloudDriveFileDetails.source = "GOOGLE_DRIVE";
        this.cloudDriveFileDetails.oauthToken = this.googleDrivePickerService.oauthAccessToken;
        this.appRef.tick();
        this.amplitudeService.logAmplitude('ED_Upload_via_GoogleDrive', {});
        this.uploadCloudFileFormEditorMain();
      }
    });
  }

  launchOneDrivePicker() {
    this.resetCloudFileDetails();
    this.uploadSource["ONE_DRIVE"] = true;
    this.cloudDriveFileDetails.fileId = "";
    var that = this;
    var odOptions = {
      clientId: environment.one_drive_client_id,
      action: "download",
      multiSelect: false,
      openInNewWindow: true,
      advanced: {
        queryParameters: "select=id,name,size,file,folder,@microsoft.graph.downloadUrl",
        filter: [".docx", ".doc", ".txt"],
        redirectUri: environment.cloud_redirect_url + "dashboard/auto-edit",
      },
      success: function (files) {
        for (const file of files.value) {
          that.cloudDriveFileDetails.fileId = file.id;
          that.cloudDriveFileDetails.fileName = file.name;
          that.cloudDriveFileDetails.fileUrl = file['@microsoft.graph.downloadUrl'];
          that.cloudDriveFileDetails.source = "ONE_DRIVE";
          that.appRef.tick();
          that.amplitudeService.logAmplitude('ED_Upload_via_OneDrive', {});
          that.uploadCloudFileFormEditorMain();
        }
      },
      cancel: function () {
      },
      error: function (e) {
      }
    }
    OneDrive.open(odOptions);
  }

  launchDropBoxPicker() {
    this.resetCloudFileDetails();
    this.uploadSource["DROP_BOX"] = true;
    this.cloudDriveFileDetails.fileId = "";
    let that = this;
    var options: DropboxChooseOptions = {
      success: function (files) {
        for (const file of files) {
          that.cloudDriveFileDetails.fileName = file.name;
          that.cloudDriveFileDetails.fileUrl = file.link;
          that.cloudDriveFileDetails.source = "DROP_BOX";
        }
        that.amplitudeService.logAmplitude('ED_Upload_via_DropBox', {});
        that.uploadCloudFileFormEditorMain();
      },
      cancel: function () {
      },
      linkType: "direct",
      multiselect: false,
      extensions: ['.doc', '.docx', '.txt'],
    };

    Dropbox.choose(options);
  }

  resetCloudFileDetails() {
    let that = this;

    Object.keys(this.uploadSource).forEach(function (key) {
      that.uploadSource[key] = false;
    });

    Object.keys(this.cloudDriveFileDetails).forEach(function (key) {
      that.cloudDriveFileDetails[key] = "";
    });
  }

  uploadCloudFileFormEditorMain() {
    // this.spinner.show();
    this.editorLoader = true;
    var formData = new FormData();
    formData.append('cloudFileDetails', JSON.stringify(this.cloudDriveFileDetails));

    this.networkCalls.uploadFile(this.userId, formData).subscribe(result => {
      // this.spinner.hide();
      this.editorLoader = false;
      //Enabling new editor for BASIC & PREMIUM_PLUS user for now
      let redirectRoute = this.creditsDetails['user_type'] === 'BASIC' || this.creditsDetails['user_type'] === 'PREMIUM_PLUS' ? "/editor/main-v1/" : "/editor/main/";
      document.location.href = document.location.origin + redirectRoute + result['data']['file_id'];
    }, error => {
      // this.spinner.hide();
      this.editorLoader = false;
      this.toastr.error("Failed to Upload File", "Error");
      this.sharedService.errorHandller(error);
    });

  }

  openFeedbackModal(): void {
    const dialogRef = this.dialog.open(FeedbackModalComponent, {
      // width: '600px',
      // height: 'auto',
      // padding: '24px 32px',
      data: { userType: this.creditsDetails.user_type == 'PREMIUM' ? 'Trinka Premium' : 'Trinka Basic', "model_and_language": this.sharedService.instructionModule.module + "+" + this.sharedService.instructionModule.languages  },
      // data: { userType: 'Trinka Premium' },
      disableClose: true, // Set to true if you want to prevent closing the modal by clicking outside or pressing ESC
      panelClass: ['feedbackModalPanel']
    });
    // Optional: Handle the dialog result
    this.amplitudeService.logAmplitude('Feedback CTA', {
      'editor': this.isNewEditor ? 'V1' : 'V0'
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
    });
  }


  /* paraphraser start */
  isParaphraserTabOpen: boolean = false;

  switchToParaphraser(module, state) {
    this.checkCurrentLanguage();
    if (!this.currentLanguage.includes('en')) {
      this.dialog.open(LanguageAlertModalComponent, {
        panelClass: 'afe-info'
      });
      this.amplitudeService.logAmplitude('Important_note_pu_language_alert', {
        'option': 'paraphraser'
      });
      return;
    }
    if (state) {
      if (this.trialMode) {
        if (module != ModuleCheck.GC) {
          this.sharedService.openSignUpAccessDialog();
        }
        return;
      }
      this.oldestCheck = module;
      this.checkStatus = false;
      this.isParaphraserTabOpen = state;
      this.activeModule = module;
      this.subjectiveBehaviorSharedService.setModuleCheck(module);

      this.amplitudeService.logAmplitude('ED_Rightpanel', {
        'option': 'paraphraser',
        'editor': this.isNewEditor ? 'V1' : 'V0'
      });
    } else {
      this.publishModuleCheck('GC');
    }
  }
  // userSelectionSentences: any[] = [];

  // paraphraserSentenceSelectionUpdated($event) {
  //   console.log('paraphraserSentenceSelectionUpdated ===> ', $event);
  // }

  /* paraphraser end */


  checkCurrentLanguage() {
    this.subjectiveBehaviorSharedService.getEditorLanguage().subscribe(language => {
      this.currentLanguage = language;
      if (this.isNewEditor) {
        this.selectedLanguageCode = language.length > 0 ? language.split(",") : [];
        this.defaultLanguageCode = language.length > 0 ? language.split(",") : [];
        this.selectLanguageOptions();
      }
      if (!this.currentLanguage.includes('en')) {
        this.isInclusiveFilterDisabled = true;
      } else {
        this.isInclusiveFilterDisabled = false;
      }
    })
  }

  updateFileLanguage() {
    let data = {
      lang_code: 'en'
    };

    this.networkCalls.putUpdateFileForDocumentType(this.userId, this.sharedService.currentFileId, data).subscribe(resp => {
      //console.log('resp is: ', resp);
    }, error => {
      this.sharedService.errorHandlerForFIleSave(error);
    });
  }

  accessTrinkaLabs() {
    //console.log("this.paymentDetails ", this.paymentDetails)
    const dialogRef = this.dialog.open(ConfirmAccessTrinkaComponent, { data: { data: this.paymentDetails, userId: this.userId } });
    // ,{hasBackdrop:false}
    // Optional: Handle the dialog result
    this.amplitudeService.logAmplitude('Trinka_Labs_CTA', {
      'userID': this.userId,
      'trinkaLab': true
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      if (result) {
        // ,{hasBackdrop:false}
        const startTrinkaLabsDialogRef = this.dialog.open(StartTrinkaLabsModalComponent);
        startTrinkaLabsDialogRef.afterClosed().subscribe(result => {
          this.enableModelPreference = !this.enableModelPreference;
          this.modelPreferenceTour = true;
          this.optTrinkaLabs(true);
          this.enablePanini();
          this.enablePaniniflag = true;
          this.toastr.success("Your selection has been saved", "")
        })
      }
    });
  }

  showInstructionFeedbackModal(isLiked: boolean) {
    this.dialog.open(InstructionFeedbackModalComponent, {
      data: {
        isLiked: isLiked,
        userId: this.userId,
        fileId: this.currFileId
      }
    });
    this.amplitudeService.logAmplitude('Trinka_Lab_Feedback', {});
  }

  userDetailsAuth;
  getUserProfileData() {
    // this.spinner.show();
    this.networkCallsDashboard.getUserDetails(this.userId).subscribe(data => {
      this.userDetailsAuth = data.data;
      this.enableModelPreference = this.userDetailsAuth['trinka_labs'];
      this.enableTrinkaAccessDefault = true
      //console.log("this.userDetailsAuth ", this.userDetailsAuth)
      // this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  optTrinkaLabs(trinkaLab) {
    this.userDetailsAuth['trinkaLab'] = trinkaLab;
    this.spinner.show();
    this.amplitudeService.logAmplitude('Enable_ Trinka Labs', {})
    this.networkCallsDashboard.putUserDetails(this.userId, this.userDetailsAuth).subscribe(data => {
      this.userDetailsAuth = data.data
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  toggleGrammarNParaphrase(module: string) {
    this.amplitudeService.logAmplitude('ED_Rightpanel', {
      'option': module,
      'editor': this.isNewEditor ? 'V1' : 'V0'
    });
    switch (module) {
      case 'grammar':
        this.subjectiveBehaviorSharedService.moduleChange.next(module);
        this.oldestCheck = 'GC';
        this.checkStatus = false;
        this.isParaphraserTabOpen = false;
        this.activeModule = 'GC';
        this.subjectiveBehaviorSharedService.setModuleCheck('GC');
        break;
      case 'paraphraser':
        this.checkCurrentLanguage();
        if (!this.currentLanguage.includes('en')) {
          this.dialog.open(LanguageAlertModalComponent, {
            panelClass: 'afe-info'
          });
          this.amplitudeService.logAmplitude('Important_note_pu_language_alert', {
            'option': 'paraphraser'
          });
        }
        else {
          this.subjectiveBehaviorSharedService.moduleChange.next(module);
          this.checkStatus = false;
          this.activeModule = 'PP';
          this.subjectiveBehaviorSharedService.setModuleCheck('PP');
          // this.amplitudeService.logAmplitude('ED_Rightpanel', {
          //   'option': 'paraphraser',
          //   'editor': this.isNewEditor ? 'V1' : 'V0'
          // });

          //TODO: Check for paraphraser close btn functionality
          //Adding event listener to close button

          this._waitForParaphraserToLoad().then((shadowRoot: any) => {
            this.paraphraserCloseBtn = shadowRoot.querySelector('.publicationClosedbutton');
            (this.paraphraserCloseBtn as any).addEventListener('click', () => {
              this.toggleGrammarNParaphrase('grammar');
            }, true);
          }).catch((error) => {
            console.log('Error paraphraser ===> ', error);
          });
        }
        this.enabledModule = 'PP'
        break;
      case 'consistencyChecker':
        this.checkCurrentLanguage();
        if (!this.currentLanguage.includes('en')) {
          this.dialog.open(LanguageAlertModalComponent, {
            panelClass: 'afe-info'
          });
          this.amplitudeService.logAmplitude('Important_note_pu_language_alert', {
            'option': 'paraphraser'
          });
        }
        else {
          this.subjectiveBehaviorSharedService.moduleChange.next(module);
          this.oldestCheck = 'CC';
          this.checkStatus = false;
          this.isParaphraserTabOpen = false;
          this.activeModule = this.oldestCheck;
          this.subjectiveBehaviorSharedService.setModuleCheck(this.oldestCheck);
        }
        this.enabledModule = 'CC'
        break;
      case 'plagCheck':
        this.subjectiveBehaviorSharedService.moduleChange.next(module);
        this.oldestCheck = 'PC';
        this.activeModule = this.oldestCheck;
        this.subjectiveBehaviorSharedService.setModuleCheck(this.oldestCheck);
        // window.open('/plagiarism-checker/upload',"_blank");
        break;
      case 'citation-checker':
        // this.subjectiveBehaviorSharedService.moduleChange.next(module);
        // this.oldestCheck = 'PC';
        // this.activeModule = this.oldestCheck;
        // this.subjectiveBehaviorSharedService.setModuleCheck(this.oldestCheck);
        // this.router.navigate(['/citation-check-tool']);
        window.open('/citation-check-tool',"_blank");
        break;
    }
  }

  private _waitForParaphraserToLoad() {
    return new Promise((resolve, reject) => {
      let counter = 0;
      const checkInterval = setInterval(() => {
        let shadowRoot = document.querySelector('trinka-paraphrase-popup') ? document.querySelector('trinka-paraphrase-popup').shadowRoot : null;
        if(shadowRoot) {
          clearInterval(checkInterval);
          resolve(shadowRoot);
        }

        counter++;

        if(counter > 50) {
          clearInterval(checkInterval);
          reject();
        }
      }, 1000);
    });
  }

  setInclusiveLanguageErrorCategories() {
    let inclusiveErrorCategories = [];
    for (const key in this.sharedService.inclusiveLanguageFilterOptions) {
      if (this.sharedService.inclusiveLanguageFilterOptions[key].checked) inclusiveErrorCategories.push(this.sharedService.inclusiveLanguageFilterOptions[key].error_category);
    }
    this.sharedService.grammarModuleConfig.inclusiveErrorCategories = inclusiveErrorCategories;

    if(trinkaSDK.initializedModule.name === 'grammar') {
      trinkaSDK.initializedModule.updateTrinkaEditorConfig(this.sharedService.grammarModuleConfig, false);
    }
  }

  showAllSelectedCode() {
    let languageToBeDisplayed = '';
    if (this.selectedLanguageCode.length === 1) {
      // let tempArr = this.languageCodeOptions.filter((x) => x.code === this.selectedLanguageCode[0]);
      languageToBeDisplayed = this.selectedLanguageCode[0].toLocaleUpperCase();
    }
    else languageToBeDisplayed = this.selectedLanguageCode.toString().toLocaleUpperCase().replace(/,/g, ', ');
    return languageToBeDisplayed;
  }

  onLanguageCodeChange() {
    this.selectedLanguageCode = this.languageCodeOptions.filter((x) => x.isSelected).map(obj => obj.code);
    this.languageString = this.languageCodeOptions.filter((x) => x.isSelected).map(obj => obj.value);
    this.languageCodeOptions.filter((x) => x.isSelected)
    if (this.selectedLanguageCode.length === 0) {
      this.selectedLanguageCode = [...this.defaultLanguageCode];
      this.selectLanguageOptions();
      // this.changeDetector.detectChanges();
    }
    let filterOption = this.sharedService.filterCheckList.find(filter => filter.flag === 10);
    if (!this.selectedLanguageCode.includes('en')) {
      filterOption.disabled = true;
      filterOption.checked = false;
    } else {
      filterOption.disabled = false;
      filterOption.checked = true;
    }
    this.subjectiveBehaviorSharedService.setEditorLanguage(this.selectedLanguageCode.toString());
    this.sharedService.grammarModuleConfig.langCode = this.selectedLanguageCode.toString();
    if(trinkaSDK.initializedModule.name === 'grammar') {
      trinkaSDK.initializedModule.updateTrinkaEditorConfig(this.sharedService.grammarModuleConfig, true);
    }
    // if(this.sharedService && this.sharedService.trinkaGrammarObject && this.sharedService.trinkaGrammarObject.updateConfig)this.sharedService.trinkaGrammarObject.updateConfig({
    //   'lang_code': this.selectedLanguageCode.toString(),
    //   'refresh_process': true
    // });
    // this.fileHolder.data['lang_code'] = this.selectedLanguageCode.toString();
    // this._restartSocket();
    let payload = {
      file_id: this.file_id,
      lang_code: this.selectedLanguageCode.toString()
    }
    //TODO: Combine all putUpdateFile calls like done previously
    this.networkCalls.putUpdateFile(this.userId, payload).subscribe(result => {
    }, error => {
      this.sharedService.errorHandlerForFIleSave(error);
    });

    let eventObj = {};
    this.languageCodeOptions.forEach((x: any) => {
      eventObj[x.value] = x.isSelected;
    })
    this.amplitudeService.logAmplitude('EN_Language_change',{
      ...eventObj,
      'editor': this.isNewEditor ? 'V1' : 'V0'
    });
  }

  selectLanguageOptions() {
    this.languageCodeOptions.forEach((x) => {
      x.isSelected = this.selectedLanguageCode.indexOf(x.code) > -1;
    });
    this.languageString = this.languageCodeOptions.filter((x) => x.isSelected).map(obj => obj.value);
  }

  renameFile(fileName, fileId) {
    let file = {
      file_name: fileName,
      file_id: fileId
    }
    this.file_name = fileName;
    this.sharedService.renameFile = file
    var dialogRef = this.dialog.open(RenameFileComponent);
    dialogRef.afterClosed().subscribe(response => {
      this.file_name = response;
    })
  }

  getWordCount() {
    let text = ''
    let editor = (document.querySelector('.ck-editor__editable') as any);
    if(editor) text = editor.textContent.trim();
    return text.length > 0 ? text.split(/\s+/).length : 0;
  }

  childToParent(event) {
    // this.fileHolder.data['language'] = event;
    let payload = {
      file_id: this.file_id,
      language: event
    }
    //TODO: Combine all putUpdateFile calls like done previously
    this.networkCalls.putUpdateFile(this.userId, payload).subscribe(result => {
      setTimeout(() => {
        // window.location.reload();
      }, 1000);
    })
    // this.sentDataCall(() => window.location.reload(), true);
  }
  trinkaUpdatePopup(){
    // console.log('Flag is ' + this.isNewEditor)
    const dialogRef = this.dialog.open(TrinkaUpdatesComponent, {data: { isNewEditor: this.isNewEditor }});
  }

  getSubsciptionDetails() {
    this.networkCallsPayments.getPaymentSubscriptionDetails(this.userId).subscribe(response => {
      this.userSubscriptionDetails = response.data;
      let epochDate = new Date(this.userSubscriptionDetails['next_renewal_date']);
      let currentDate = new Date().getTime();
      let differenceInTime = epochDate.getTime() - currentDate;
      this.trialDayRemaing = Math.floor(differenceInTime / (1000 * 3600 * 24));
    }, error => {
       this.sharedService.errorHandller(error);
    });
  }
  learnMoreClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isActiveLearnMore = !this.isActiveLearnMore;
    const dialogRef = this.dialog.open(PowerModeEditingComponent, { data: {} });
  }

  closedEventOfEditingModeDropdown() {
    this.isActiveLearnMore = false;
  }
  async onEditingModeSelectionChange(evt) {
    var InstModel = [
      {
        'inst': "",
        'module': "basic"
      }, {
        'inst': "Fix all the Grammatical Errors of this text",
        'module': "advanced"
      }];
    let payload = {
      file_id: this.sharedService.currentFileId,
      lang_code: this.selectedLanguageCode.toString()
    };
    this.sharedService.instructionModule.languages = this.showAllSelectedCode();
    switch (evt.value) {
      case "Power Mode":
        this.sharedService.instructionModule.module = "advanced";
        setTimeout(() => {
          this.toastr.success("Power Mode is active now!", '',
            {
              messageClass: 'powerModeSelected'
            })
        }, 1500);
        this.amplitudeService.logAmplitude('Editing mode', {
          'mode': '1. Power Mode',
          'user_id': this.userId
        });
        this.instructionModule.module = InstModel[1].module;
        this.instructionModule.instruction = InstModel[1].inst;
        payload['instruction'] = this.instructionModule.instruction;
        payload['module'] = this.instructionModule.module;
        await this.updateFileAsync(payload);
        this.subjectiveBehaviorSharedService.changeInstuctionModule('parent_to_child', InstModel[1].inst, InstModel[1].module);
        break;
      case "Lite Mode":
        this.sharedService.instructionModule.module = "basic";
        setTimeout(() => {
          this.toastr.success("Lite Mode is active now!")
        }, 1500);
        this.amplitudeService.logAmplitude('Editing mode', {
          'mode': '2. Lite Mode',
          'user_id': this.userId
        });
        this.instructionModule.module = InstModel[0].module;
        this.instructionModule.instruction = InstModel[0].inst;
        payload['instruction'] = this.instructionModule.instruction;
        payload['module'] = this.instructionModule.module;
        await this.updateFileAsync(payload);
        this.subjectiveBehaviorSharedService.changeInstuctionModule('parent_to_child', InstModel[0].inst, InstModel[0].module);
        break;
      default:
        // this.subjectiveBehaviorSharedService.changeInstuctionModule('parent_to_child', InstModel[0].inst, InstModel[0].module);
        break;
    }

    // //TODO: Combine all putUpdateFile calls like done previously
    // this.networkCalls.putUpdateFile(this.userId, payload).subscribe(result => {

    // }, error => {
    //   this.sharedService.errorHandlerForFIleSave(error);
    // });
  }

  updateFileAsync(payload: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
    this.networkCalls.putUpdateFile(this.userId, payload).subscribe(result => {
      resolve();
    }, error => {
      reject();
    });
    })

  }

  getModelName(model) {
    this.instructionModule.module = model;
    if (model == 'basic') {
      return "Lite Mode";
    } else if (model == 'advanced') {
      return "Power Mode";
    } else {
      return "Power Mode";
    }
  }
}
