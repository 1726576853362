import { Pipe, PipeTransform } from '@angular/core';
import { orderBy, sortBy } from 'lodash';

@Pipe({
  name: 'sortByDate'
})
export class SortCreatedBy implements PipeTransform {
  transform(items: any[], flag: boolean): any[] {
    if (!Array.isArray(items)) {
      return;
    }
    if (flag) {
      var order = 'asc';
      var column = 'word';

      // if (!items || order === '' || !order) { return items; } // no array
      // if (!column || column === '') {
      //   if (order === 'asc') { return items.sort() }
      //   else { return items.sort().reverse(); }
      // } // sort 1d array
      // if (items.length <= 1) { return items; } // array with only one item
      items.sort(function (a, b) {
        if (a.word.toLowerCase() < b.word.toLowerCase()) { return -1; }
        if (a.word.toLowerCase() > b.word.toLowerCase()) { return 1; }
        return 0;
      })
      // return orderBy(items, [column], [order]);
      return items;
    } else {
      items.sort((a: any, b: any) => {
        if (b.updated_at < a.updated_at) {
          return -1;
        } else if (b.updated_at > a.updated_at) {
          return 1;
        } else {
          return 0;
        }
      });
      return items;
    }
  }
}
