<div class="seletectDocumentStyle">
  <div class="seletectDocumentStyleHeader">
    <h1>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5982 8.34219C13.6317 8.23118 13.7014 8.13372 13.7967 8.06442C13.892 7.99512 14.0078 7.9577 14.1269 7.95776H18.5326C18.6198 7.95783 18.7057 7.97803 18.7833 8.01671C18.861 8.05538 18.928 8.11143 18.979 8.18023C19.03 8.24903 19.0634 8.32862 19.0766 8.41244C19.0897 8.49626 19.0822 8.58193 19.0546 8.66237L17.0941 14.3828H21.2861C21.3893 14.3827 21.4903 14.4108 21.5779 14.4638C21.6654 14.5169 21.7358 14.5927 21.7811 14.6828C21.8265 14.7729 21.8449 14.8735 21.8343 14.9732C21.8237 15.073 21.7845 15.1678 21.7212 15.2469L14.0112 24.8844C13.9322 24.9837 13.8199 25.0527 13.6939 25.0797C13.5679 25.1066 13.4361 25.0896 13.3216 25.0317C13.2071 24.9739 13.1172 24.8787 13.0675 24.763C13.0178 24.6472 13.0115 24.5181 13.0497 24.3983L15.0333 18.1307H11.3733C11.2877 18.1307 11.2032 18.1114 11.1267 18.0741C11.0501 18.0369 10.9835 17.9828 10.9322 17.9162C10.8808 17.8496 10.8462 17.7722 10.831 17.6903C10.8158 17.6084 10.8205 17.5242 10.8446 17.4443L13.5982 8.34219Z"
          fill="#7A28A0" />
        <circle cx="16" cy="16" r="13.2" stroke="#7A28A0" stroke-width="1.6" />
        <circle cx="25.6004" cy="4.8001" r="4" fill="#7A28A0" stroke="#FAF5FF" stroke-width="1.6" />
      </svg>
      Welcome to Trinka Labs
    </h1>
    <button (click)="closeModal(null)">
      <img src="../../../../assets/images/close-black.svg" alt="">
    </button>
  </div>
  <div class="seletectDocumentStyleBody" *ngIf="!paymentDetails.is_premium">
    <p>We continuously engage in improving our product by developing better AI models. Our next model, named “Panini-1”, is ready for user testing. Trinka subscribed users will be among the first to use this.</p>
      <p style="margin-bottom: 12px;"><strong>Here is what you'll get:</strong></p>
      <ul>
        <li>Up to <strong>40%</strong> more suggestions</li>
        <li>Suggestions with <strong>higher accuracy</strong></li>
      </ul>
    </div>
    <div class="seletectDocumentStyleBody" *ngIf="paymentDetails.is_premium">
      <p>We continuously engage in improving our product by developing better AI models. Our next model, named "Panini-1", is ready for user testing. We invite you to use this model and share your experience with us.</p>
        <p style="margin-bottom: 12px;"><strong>Here is what you'll get:</strong></p>
        <ul>
          <li>Up to <strong>40%</strong> more suggestions</li>
          <li>Suggestions with <strong>higher accuracy</strong></li>
        </ul>
    </div>
  <div class="seletectDocumentStyleFooter">
    <button class="cancelRenameButton" style="display: none;" (click)="closeModal(false)">May be later</button>
    <!-- <div class="footerCTASection" *ngIf="!paymentDetails.is_premium">
      <button (click)="upgradePlanPopup()">Upgrade to Access Panini-1 Model</button>
    </div> -->
    <div class="footerCTASection">
      <button (click)="closeModal(true)">Enable Panini-1</button>
      <span>You can always switch back to the Live Model.</span>
    </div>
  </div>
</div>