

<div class="outer_login_wrap_section">
    <div class="login_left_side">
        <div class="left_login_overlay"></div>
        <div class="login_content">
            <div class="top_logo">
                <a href="https://trinka.ai" target="_blank">
                    <img src="/assets/images/trinka-logo-login.png" alt="">
                </a>
            </div>
            <div class="login_text_contents">
                <h2>Welcome aboard!</h2>
                <p>As you are starting your journey with Trinka, please register or login to access a range of unique features.</p>
            </div>
            <div class="login_footer">
                <ul>
                    <li><a href="https://www.trinka.ai/privacypolicy" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://www.trinka.ai/termsofservices" target="_blank">Terms of Services</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="login_right_side browserInstall_page">
        
        <div class="login_body_section">
            <div class="text-right w-100 dashbaord_user_name">
                <div class="changeslog_header">
                    <span *ngIf = "isLoggedIn" class="name">
                        <span class="userFirstName">{{selectedProfile?.firstname}}</span>
                        <span class="userLastName">{{selectedProfile?.lastname}}</span>
                    </span>
                    <span  *ngIf = "isLoggedIn" class="account">
                        <button mat-icon-button [matMenuTriggerFor]="menu1" class="p-0">
                            <img class="img-fluid" src="../../../../assets/images/Icon_Account.svg" alt="logo favicon">
                        </button>
                        <mat-menu #menu1="matMenu" class="profile-menu personalize_top_menus">
                            <a mat-menu-item [routerLink]="['/dashboard/profile']">
                                <img class="img-fluid" src="../../../../assets/images/Icon_ManageProfile.svg"
                                    alt="logo favicon">
                                <span> Account Settings</span>
                            </a>
                            <button mat-menu-item (click)="signOut()"><img class="img-fluid"
                                    src="../../../../assets/images/Icon_Logout.svg" alt="logo favicon">
                                Logout</button>
                        </mat-menu>
                    </span>
                </div>
            </div>
            <h2 *ngIf="assetType == 'ext_chrome'" style="font-size: 30px; margin-top: 60px;">You have successfully installed Trinka for Chrome</h2>
            <h2 *ngIf="assetType == 'ext_firefox'" style="font-size: 30px; margin-top: 60px;">You have successfully installed Trinka for Firefox</h2>
            <h2 *ngIf="assetType == 'ext_edge'" style="font-size: 30px; margin-top: 60px;">You have successfully installed Trinka for Edge</h2>
            <div class="feedback_content">
                <p>Access powerful features & personalize your experience</p>
                <ul>
                    <li>Real-time grammar and spelling correction</li>
                    <li>Word choice and language enhancement</li>
                    <li>Add to Dictionary, Auto File Edit & much more</li>
                </ul>
                <div *ngIf = "!isLoggedIn"  class="install_pages_button">
                    <a *ngIf="assetType == 'ext_chrome'" href="https://cloud.trinka.ai/signup?bid=1&utm_source=chrome&utm_medium=extension&utm_campaign=ext_signup" target="_blank">Register for Free</a>
                    <a *ngIf="assetType == 'ext_chrome'" href="https://cloud.trinka.ai/signin?bid=1&utm_source=chrome&utm_medium=extension&utm_campaign=ext_signin" target="_blank">Login</a>
                    <!-- <a [routerLink]="['/signup']">Register for Free</a> -->
                    <!-- <a [routerLink]="['/signin']">Login</a> -->
                    <a *ngIf="assetType == 'ext_firefox'" href="https://cloud.trinka.ai/signup?bid=2&utm_source=firefox&utm_medium=extension&utm_campaign=ext_signup" target="_blank">Register for Free</a>
                    <a *ngIf="assetType == 'ext_firefox'" href="https://cloud.trinka.ai/signin?bid=2&utm_source=firefox&utm_medium=extension&utm_campaign=ext_signin" target="_blank">Login</a>
                    
                    <a *ngIf="assetType == 'ext_edge'" href="https://cloud.trinka.ai/signup?bid=2&utm_source=edge&utm_medium=extension&utm_campaign=ext_signup" target="_blank">Register for Free</a>
                    <a *ngIf="assetType == 'ext_edge'" href="https://cloud.trinka.ai/signin?bid=2&utm_source=edge&utm_medium=extension&utm_campaign=ext_signin" target="_blank">Login</a>
                </div>
            </div> 

            <div class="use_trinka_others">
                <div class="row">
                    <div class="col-lg-12">
                        <h3>Other Trinka apps for your writing needs</h3>
                    </div>
                    <div class="col-lg-6">
                        <div class="trinka_others_scetion">
                            <a href="https://www.trinka.ai/trinkacloud" target="_blank" *ngIf = "!isLoggedIn" >
                                <img src="../../../../assets/images/Get_stated_online_icons.png" alt="">
                                <div class="trinka_button_text">
                                    <p>Start writing better</p>
                                    <span>with FREE Trinka Cloud</span>
                                </div>
                            </a>
                            <a [routerLink]="['/signin']" *ngIf = "isLoggedIn" >
                                <img src="../../../../assets/images/Get_stated_online_icons.png" alt="">
                                <div class="trinka_button_text">
                                    <p>Start writing better</p>
                                    <span>with FREE Trinka Cloud</span>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="trinka_others_scetion" id="successDeviceDetector">
                                                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>