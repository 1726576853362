import { Component, OnInit } from '@angular/core';
 import { MatDialogRef } from '@angular/material';
 
 @Component({
   selector: 'app-plag-check-onboarding-modal',
   templateUrl: './plag-check-onboarding-modal.component.html',
   styleUrls: ['./plag-check-onboarding-modal.component.scss']
 })
 export class PlagCheckOnboardingModalComponent implements OnInit {
 
   step: number = 1;
   constructor(private matDialogRef : MatDialogRef<PlagCheckOnboardingModalComponent>) { }
 
   ngOnInit() {
   }
 
   next()
   {
     this.step++;
     if(this.step>3){
         this.matDialogRef.close();
     }
   }
 
   previous()
   {
     this.step = this.step >0 ? this.step-1 : 1;
   }
 
   close(){
     this.matDialogRef.close(); 
   }
 
 }
