<div class="seletectDocumentStyle">
  <div class="seletectDocumentStyleHeader">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#6B21A8"/>
      <path d="M10.5799 15.58C10.3799 15.58 10.1899 15.5 10.0499 15.36L7.21994 12.53C6.92994 12.24 6.92994 11.76 7.21994 11.47C7.50994 11.18 7.98994 11.18 8.27994 11.47L10.5799 13.77L15.7199 8.63001C16.0099 8.34001 16.4899 8.34001 16.7799 8.63001C17.0699 8.92001 17.0699 9.40001 16.7799 9.69001L11.1099 15.36C10.9699 15.5 10.7799 15.58 10.5799 15.58Z" fill="#6B21A8"/>
    </svg>
    <h1>Document Style Saved Successfully!</h1>
  </div>
  <div class="seletectDocumentStyleBody">
    <div class="seletectDocumentLeftSide">
      <h2>What is Included in Legal Style</h2>
      <ol start="1">
        <li>Style Guides, Spelling, Enhancements, and Inclusive Language Suggestions.</li>
        <li>Choose from different Legal Style Guides to write in a specific style.</li>
        <li>Available for English only.</li>
        <li>Switch to General/Academic writing for grammar check.</li>
      </ol>
    </div>
    <div class="seletectDocumentRightSide">
      <svg width="369" height="364" viewBox="0 0 369 364" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_11862_25760)">
        <path d="M104.443 167.525C95.0029 153.997 101.353 134.277 116.439 130.275L228.07 100.659C234.669 98.9087 241.632 100.69 246.848 105.462L336.271 187.279C348.72 198.67 345.626 220.523 330.615 227.225L205.093 283.263C196.139 287.261 185.855 284.191 179.975 275.765L104.443 167.525Z" fill="#FAF5FF"/>
        </g>
        <defs>
        <filter id="filter0_f_11862_25760" x="0" y="0" width="444" height="385" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_11862_25760"/>
        </filter>
        </defs>
      </svg>
      <img src="../../../../assets/images/ImportantNotesFilter.svg" alt="">
    </div>
  </div>
  <div class="seletectDocumentStyleFooter">
    <button (click)="closeModal(null)">Okay</button>
  </div>
</div>
