<div class="container h-100 language-pre mat-typography-config p-4 changes_profile_popup" fxLayout.xs="column"
  fxLayoutAlign="left" fxLayoutGap.xs="0" fxLayout="row">
  <button class="closedButton" (click)= closeDProfileModal() title="Close" *ngIf="data.closeButton">
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 47.971 47.971"
            style="enable-background:new 0 0 47.971 47.971;" xml:space="preserve">
            <g>
                    <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                            c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                            C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                            s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z" />
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
    </svg>
</button>
  <h2>Select your account to proceed</h2>
  <p>Your Email ID is associated with multiple membership accounts at Enago. Please select one account you want to
    proceed with.</p>
  <div class="list-group profileList">
    <ng-container *ngFor="let profile of data.profileList">
      <span class="list-group-item list-group-item-action flex-column align-items-start" (click)="onSelect(profile)"
        [ngClass]="profile.clientid == data.selectedProfile.clientid?'active':''">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{profile.firstname}} {{profile.lastname}} ({{profile.membershipid}})</h5>
        </div>
        <div class="active_arrow">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
            <g>
              <g>
                <polygon points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128 		" />
              </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
          </svg>
        </div>
        <small *ngIf="profile.accounttype == 0">Individual account</small>
        <small *ngIf="profile.accounttype == 1">Group account</small>
      </span>
    </ng-container>
  </div>
</div>