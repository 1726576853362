import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/_services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent {
  feedbackText: string = '';
  ratingFeedback: number = 0;
  ratings: number[] = [1, 2, 3, 4, 5];
  emailId = "";
  feedbackForm!: FormGroup;
  feedbackSubmitted: boolean = false;
  thankYouPopu: boolean = false;
  isNewEditor = window.location.pathname.includes('main-v1');

  constructor(
    public dialogRef: MatDialogRef<FeedbackModalComponent>,
    private _onlineEditorService: OnlineEditorService,
    private _sharedService: SharedService,
    private _amplitudeService: AmplitudeService,
    private spinner: NgxSpinnerService,
    private _toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.emailId = this._sharedService.getLocalStorageMeta().sub;
    this.feedbackForm = new FormGroup({
      feedbackText: new FormControl(''),
    });
  }

  ngOnInit(): void {
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onSubmitClick(): void {
    // Add your logic for the Submit button here
    if (this.feedbackForm.valid) {
      const payload = {
        "feedback_text": this.feedbackForm.get('feedbackText').value,
        "rating": this.ratingFeedback,
        "source": this.isNewEditor ? "TRINKA-EDITOR-NEW" : "TRINKA-EDITOR",
        "email_id": this.emailId,
        "user_type": this.data.userType,
        "model_and_language": this.data.model_and_language
      }
      this.thankYouPopu = true;
      this.spinner.show();
      this._onlineEditorService.submitFeedbackCall(payload).subscribe({
        next: response => {
          if (response.success) {
            this._amplitudeService.logAmplitude('Feedback_submit', {
              ...payload,
              'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
            })
            this.feedbackSubmitted = true;
            // this._toast.success("Feedback is submitted successfully!");
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.feedbackSubmitted = false;
            this._toast.error("Failed to create feedback!");
          }
          // this.dialogRef.close();
        },
        complete: () => {
          // this.dialogRef.close();
          this.spinner.hide();
        },
        error: err => {
          this.spinner.hide();
          this.feedbackSubmitted = false;
          this._toast.error("Failed to submit feedback!");
        }
      })
    } else {
      this.validateAllFormFields(this.feedbackForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  cancelEvent() {
    this._amplitudeService.logAmplitude('Feedback _cancel', {
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0'
    });
  }
}
