<div class="paymentLoaderSection">
    <div class="loaderPaymentBox">
        <svg class="spinner">
            <circle style="cx: 50%;cy: 50%; r: 50%;">
                <animateTransform attributeName="transform" type="rotate" values="-90;810" keyTimes="0;1" dur="2s" repeatCount="indefinite"></animateTransform>
                <animate attributeName="stroke-dashoffset" values="0%;0%;-157.080%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
                <animate attributeName="stroke-dasharray" values="0% 314.159%;157.080% 157.080%;0% 314.159%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
            </circle>
        </svg>
        <h2>
            Please wait for a moment as we switch from 
            <span *ngIf="data.model === 'turtle'">Panini-1 to the Live model.</span>
            <span *ngIf="data.model !== 'turtle'">the Live model to Panini-1.</span>
        </h2>
    </div>
</div>