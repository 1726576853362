import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-editor-plag-check-report-viewer',
  templateUrl: './editor-plag-check-report-viewer.component.html',
  styleUrls: ['./editor-plag-check-report-viewer.component.scss']
})
export class EditorPlagCheckReportViewerComponent implements OnInit {

  @Input() plagCheckReportUrl: string = "";
  @Input() isSamplePlagCheckReport: boolean = false;
  @Input() isAdvancePlagCheckReport: boolean = false;
  @Output() viewerEnabled= new EventEmitter();
  @Output() generate_advance_report = new EventEmitter();
  constructor() {
    // console.log("const url ", this.isSamplePlagCheckReport);
   }

  ngOnInit() {
    // console.log("ng init url ", this.isSamplePlagCheckReport);
  }

  close_report(){
    this.viewerEnabled.emit(false);
  }

  generate_advance_plag_check(){
    this.generate_advance_report.emit(true);
  }
}
