<mat-card class="reference_checks_section" style="margin-bottom:8px;">
  <div class="reference_card" *ngIf='menuscript_present'>
    <div class="no_of_checks manuscript_overview_section">
      <p>
        <span>Check 1</span> Manuscript Overview
        <i class="wordCoutn_alternat_text" alt="Information about the title and total word count of the manuscript.">
          <img src="../../../../assets/images/Icon_Info.svg" alt="">
        </i>
      </p>
    </div>
    <div class="reference_checks_body" *ngIf="summaryData.info.title != 'NA' && summaryData.info.title != ''">
      <p class="reference_founds"><span>Title: </span> <b>Present</b>
        <span class="viewReferences_button" (click)="viewDetails('Title',summaryData.info.title)">View
          Title&nbsp;<span><img style="margin: 0 0 0 4px;" src="../../../../assets/images/Icon_eye.svg"
              alt=""></span></span>
      </p>
      
      <div class="impact_sections">
        <ul>
          <li>Words:&nbsp;<span [ngClass]="summaryData.info.title_word_count>20? 'word_count_more': 'word_count_less' ">{{summaryData.info.title_word_count}}</span> </li>
          <li>Characters:&nbsp;<span [ngClass]="summaryData.info.title_word_count>20? 'word_count_more': 'word_count_less' ">{{summaryData.info.title_char_count}}</span> </li>
        </ul>

        <div class="present_text_description" *ngIf="summaryData.info.title_word_count>20">
          <p class="reference_founds">Your title is longer than 20 Words. Please check the journal requirements.</p>
        </div>

      </div>
    </div>
    <div class="reference_checks_body not_found_reference not_persent_title" *ngIf="summaryData.info.title == 'NA'">
      <p class="reference_founds"><span>Title: &nbsp;</span> <b>Not Present</b></p>
    </div>
  </div>

  <div class="reference_card"  *ngIf="!menuscript_present">
    <div class="no_of_checks manuscript_overview_section">
      <p>
        <span>Check 1</span> Manuscript Overview
        <i class="wordCoutn_alternat_text" alt="Information about the title and total word count of the manuscript.">
          <img src="../../../../assets/images/Icon_Info.svg" alt="">
        </i>
      </p>
    </div>
    <div class="reference_checks_body not_found_reference not_persent_title">
      <p class="reference_founds"><span>Title:</span> <b>Not Present</b></p>
    </div>
  </div>

  <div class="reference_checks_body" [ngClass]="summaryData.word_count>5000? 'word_count_less': 'word_count_more' ">
    <p class="reference_founds" style="margin: 0;">
      <span>Word Count: </span>
      <span class="wordCountColor">{{summaryData.word_count}}</span>
    </p>
    <div *ngIf="summaryData.word_count>5000" class="present_text_description">
      <p class="reference_founds">Your manuscript (excluding references) is longer than 5000 words. Please check the
        journal requirements.</p>
    </div>
  </div>

</mat-card>

<mat-card class="reference_checks_section" style="margin-bottom: 8px;">
  <div class="reference_card" *ngIf="abstract_present">
    <div class="no_of_checks manuscript_overview_section">
      <p><span>Check 2</span>Abstract
        <i class="wordCoutn_alternat_text set_top_alignment" alt="Information about the word count of the abstract and whether it is structured or unstructured.">
          <img src="../../../../assets/images/Icon_Info.svg" alt="">
        </i>
      </p>
    </div>
    <div class="reference_checks_body">
      <p class="reference_founds"><span>Abstract:&nbsp; </span> 
        <b *ngIf="abstractData.type == 'unstructured'">Unstructured</b>
        <b *ngIf="abstractData.type == 'structured'">Structured</b>
      </p>
    </div>
    <div class="impact_sections" style="border-bottom: 0;margin: 0;padding: 0;">
      <p style="margin: 0;" class="abstractWordSection" [ngClass] = "{'abstract_word_count': abstractData.length <100 || abstractData.length >250}">Words:&nbsp;
        <span class="abstractWordCountColor">{{abstractData.length}}</span>
        <span class="viewReferences_button" (click)="viewDetails('Abstract',abstractData.content)">View
          Abstract&nbsp;<span><img style="margin: 0 0 0 4px;" src="../../../../assets/images/Icon_eye.svg"
              alt=""></span>
        </span></p>
      <div class="present_text_description" style="margin-top: 15px;" *ngIf="abstractData.length <100 || abstractData.length >250">
        <p class="reference_founds">Normally an abstract is between 100 and 250 Words. Please check the journal
          requirements.
        </p>
      </div>
    </div>
  </div>

  <div class="reference_card" *ngIf="!abstract_present">
    <div class="no_of_checks manuscript_overview_section">
      <p><span>Check 2</span> Abstract
        <i class="wordCoutn_alternat_text set_top_alignment" alt="Information about the word count of the abstract and whether it is structured or unstructured.">
          <img src="../../../../assets/images/Icon_Info.svg" alt="">
        </i>
      </p>
    </div>
    <div class="reference_checks_body not_found_reference">
      <p class="reference_founds"><span>Abstract:&nbsp; </span> <b class="notFoundText">Not found</b></p>
      <div class="present_text_description">
        <p class="reference_founds" style="line-height: 17px;">An abstract presents a summary of the paper. 
          Most journals require an abstract to be provided for most types of publications. 
          Check the journal instructions for the inclusion of the abstract, 
          the type of abstract to be used, and the word count limitation. </p>
      </div>
    </div>
  </div>
</mat-card>

<mat-card class="reference_checks_section">
  <div class="reference_card">
    <div class="no_of_checks manuscript_overview_section">
      <p><span>Check 3</span>Manuscript Sections
        <i class="wordCoutn_alternat_text" alt="Information of sections found in the manuscript and a list of probable sections missing for your paper type.">          <img src="../../../../assets/images/Icon_Info.svg" alt="">
        </i>
      </p>
    </div>

    <div class="bottom_border manuScriptData_founds manuscrip_section_border" *ngIf="articleTypeData?.is_missing && articleTypeData?.trinka_article_type != 'Communication' && articleTypeData?.trinka_article_type != 'Other'">
      <span class="data_found_heading">The following sections are missing for your paper type "{{articleTypeData?.trinka_article_type}}".</span>
      <ul>
        <li *ngFor="let missingObject of articleTypeData.missing_items">{{missingObject}}</li>
      </ul>
    </div>

    <div class="determine_missing_sentence manuscrip_section_border" *ngIf="!articleTypeData?.is_missing && articleTypeData?.trinka_article_type == ''">
      <p class="determine_missing_text">We cannot determine the missing sections as paper type is not provided.</p>
      <div class="present_text_description">
        <p class="reference_founds"> You can set your paper type from the academic writing settings and run the check
          again.</p>
      </div>
    </div>

    <div class="impact_sections missing_data_others manuscrip_section_border"
      *ngIf="articleTypeData?.is_missing && articleTypeData?.trinka_article_type == 'Other'">
      <p class="missing_other">We cannot determine the missing sections for your paper type "Other".</p>
      <div class="present_text_description">
        <p class="reference_founds"> You can set your paper type from the academic writing settings and run the check again.</p>
      </div>
    </div>
    
    <div class="bottom_border manuScriptData_founds manuscrip_section_border"  *ngIf="!manuScriptData?.is_present && articleTypeData?.trinka_article_type == 'Communication'">
      <span class="data_found_heading"> The following section(s) are missing for your paper type “Communication”.</span>
      <ul>
        <li>References</li>
      </ul>
    </div>
    
    <div class="manuScriptData_founds following_section_founds manuscrip_section_border" *ngIf="manuScriptData?.is_present">
      <span class="data_found_heading" *ngIf="articleTypeData?.is_missing">The following sections are found.</span>
      <span class="data_found_heading manuscriptSection_other" *ngIf="!articleTypeData?.is_missing && manuScriptData?.communication_article_section">All important sections are present for your paper type "{{articleTypeData?.trinka_article_type}}".</span> 
      <span class="data_found_heading manuscriptSection_communication" *ngIf="!articleTypeData?.is_missing && !manuScriptData?.communication_article_section">Your selected document type "Communication" normally does not have sections. Please check the journal guidelines.</span> 
      <span class="data_found_heading" *ngIf="!articleTypeData?.is_missing">The following sections are found.</span> 
      <ul>
        <li *ngFor="let object of manuScriptData?.sections_list">{{object}}</li>
      </ul>     
    </div>

    <div class="bottom_border manuScriptData_founds manuscrip_section_border" *ngIf="!manuScriptData?.is_present">
      <span class="data_found_heading">No sections found in your document.</span>
    </div>

  </div>
</mat-card>