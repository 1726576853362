<div class="seletectDocumentStyle">
  <div class="seletectDocumentStyleHeader">
    <h1>Select Document Style</h1>
    <button (click)="closeModal(null)">
      <img src="../../../../assets/images/close-black.svg" alt="">
    </button>
  </div>
  <div class="seletectDocumentStyleBody">
    <div class="bodyContentLabel">
      <label class="styleLabelText">Document Type</label>
      <ul>
        <li *ngFor="let documentType of selection.documentTypeList">
          <div class="ds_radioButton">
            <input type="radio" id="document_type_{{documentType.id}}" name="document_{{documentType.id}}" (change)="selection.document_type = documentType.id; selection.styleGuide = 'NONE';" [checked]="documentType.id === selection.document_type">
            <label for="document_type_{{documentType.id}}">
              {{documentType.name}}
              <i *ngIf="documentType.name === 'Legal' ">New</i>
            </label>
          </div>
        </li>
      </ul>
    </div>

    <!-- style guide option -->
    <div class="styleGuideOptions" [ngClass]="(selection.document_type === 1 || selection.document_type === 2)? 'NormalstyleGuide' : '' ">
      <label style="min-width: 190px;" class="styleLabelText" [ngStyle]="{'opacity': (selection.document_type === 1 || selection.document_type === 2) ? '0.4' : '1'}">Style Guide</label>
      <ul>
        <ng-container *ngIf="selection.document_type === 1 || selection.document_type === 2;">
          <li>
            <input id="static-none-radio" name="styleGuide" type="radio" disabled checked>
            <label for="static-none-radio" class="radio-label" style="width: 100px; opacity: 0.4;">None</label>
          </li>
        </ng-container>

        <ng-container *ngIf="selection.document_type === 3;">
          <li *ngFor="let style of selection.styleGuideList | keyvalue">
            <input id="{{style.value}}" name="styleGuide" type="radio" (change)="selection.styleGuide = style.key;" [checked]="style.key == selection.styleGuide ? true : false">
            <label for="{{style.value}}" class="radio-label">{{style.value}}</label>
          </li>
        </ng-container>

        <ng-container *ngIf="selection.document_type === 4">
          <li class="row-view" *ngFor="let style of selection.styleGuideLegalList | keyvalue: returnZero">
            <input id="{{style.value}}" name="styleGuide" type="radio" (change)="selection.styleGuide = style.key;" [checked]="style.key == selection.styleGuide ? true : false">
            <label for="{{style.value}}" class="radio-label">{{style.value}}</label>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="seletectDocumentStyleFooter">
    <button (click)="saveModal()" [disabled]="loader">
      Save
      <i class="fa fa-spinner fa-spin" *ngIf="loader"></i>
    </button>
  </div>
</div>
