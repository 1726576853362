export const EnablePublicationCheck =[
     {value:0, label:"Journal Scope Match",is_enable: true},
     {value:1, label:"Technical Compliance",is_enable: true},
     {value:2, label:"Ethical Compliance",is_enable: true},
     {value:3, label:"Authorship Details",is_enable: true},
     {value:4, label:"Reference Details",is_enable: true},
     {value:5, label:"Figures & Tables",is_enable: true},
     {value:6, label:"Clinical Trials",is_enable: true},
     {value:7, label:"Keywords & Summary",is_enable: true},
]
