import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { VerifyEmailComponent } from 'src/app/components/dashboard/verify-email/verify-email.component';
import { SharedService } from '../shared.service';
import { SubjectiveBehaviorSharedService } from '../subjective-behavior-shared.service';
import { DashboardService } from './dashboard.service';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class VerifiedUserService {

  userId: string;
  selectedProfile: any = {};

  constructor(private networkCalls: DashboardService,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private subjectiveBehaviorSharedService:SubjectiveBehaviorSharedService,
    public amplitudeService: AmplitudeService,
    private dashboardService: DashboardService,
    ) {
      const localMeta = this.sharedService.getLocalStorageMeta();
      this.userId = btoa(localMeta.sub);
  }

  getSelectedProfileData(showPopUp?) {
    this.dashboardService.getStoreUserProfileDetails().subscribe(resp => {
      if(Object.keys(resp).length == 0){
          this.networkCalls.getSelectedProfileData(this.userId).subscribe(result => {
            if (result["status"]) {
              this.amplitudeService.setUserId(this.userId);
              this.selectedProfile = result['data']['profile_data'];
              this.dashboardService.storeUserProfileDetails(this.selectedProfile);
              this.subjectiveBehaviorSharedService.setSelectedProfileData(this.selectedProfile)
              if(result['data']['profile_data']['is_verified']){
                return;
              }
              if(showPopUp){
                this.verifyEmail(this.selectedProfile);
              }
              if(result['data']['profile_data']['trial_expire_on'] > 0){
                setTimeout(() => {
                  this.getSelectedProfileData();
              }, 300000);
              }
              else if(result['data']['profile_data']['trial_expire_on'] < 0){
                this.verifyEmail(this.selectedProfile);
              }
            } 
          }, error => {
            this.sharedService.errorHandller(error);
          });
        }
        else{
          this.subjectiveBehaviorSharedService.setSelectedProfileData(resp);
          this.selectedProfile = resp;
        }
    });
  }

verifyEmail(selectedProfile){
  // const dialogRef = this.dialog.open(VerifyEmailComponent, {
  //   data: { profileData: selectedProfile },
  //   disableClose: true
  // });
}
}
