<div class="container error_popup_section" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap.xs="0" fxLayout="row">
  <div class="row">
    <div class="col-sm-12">
      <div class="popup_heading">
        <h2>Revision Summary</h2>
        <button class="cancelbuttons" mat-button mat-dialog-close>
          <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
        <p>A summary of revisions suggested by Trinka is presented below.</p>
      </div>
  
      <div class="error_card_body">
        <div class="error_heading">
            <p>Total Revisions: </p>
            <span>{{fileData.error_report.total_corrections+fileData.error_report.total_suggestions}}</span>
        </div>

          <ul>
            <ng-container *ngFor="let obj of dataSource; let i = index;">
              <li  *ngIf="obj.isVisible">
                <p>{{obj.key}}  {{obj.displayIndex}}</p>
                <span>{{obj.value}}</span>
              </li>
            </ng-container>
          </ul>
      </div>
    </div>
  </div>
</div>