import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/_services/shared.service';
import { SignInSignOutComponent } from '../sign-in-sign-out/sign-in-sign-out.component';
import { MatDialog } from '@angular/material/dialog';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/networkcalls/website.service';
declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  email: any;
  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private networkCall: WebsiteService
  ) { }
  open: string;
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.open = params['open'];
      this.openModalByParam(this.open);
    });

    document.getElementById('main_header').classList.add('homepageHeader');
    $.getScript('../../../assets/js/hubslider.js', function(){});
    // document.getElementById('main_header').classList.add('homepageHeader');    
  }

  ngOnDestroy() {
    // alert("Hello this is ngOnDestroy alert");
    document.getElementById('main_header').classList.remove('homepageHeader');
  }

  openModalByParam(param: string) {
    switch (param) {
      case "login":
        this.sharedService.signInpopUp = this.dialog.open(SignInSignOutComponent);
        this.sharedService.signInpopUp.afterClosed().subscribe(result => {
        });
        break;
      case "signup":
        this.sharedService.signInpopUp = this.dialog.open(SignInSignOutComponent);
        this.sharedService.signInpopUp.afterClosed().subscribe(result => {
        });
        this.sharedService.enableSignInPanel = 1;
        break;
      case "forgetpassword":
        this.sharedService.signInpopUp = this.dialog.open(SignInSignOutComponent);
        this.sharedService.signInpopUp.afterClosed().subscribe(result => {
        });
        this.sharedService.enableSignInPanel = 2;
        break;

      default:
        break;
    }
  }

  contactToSales() {
    this.networkCall.postContactSales(this.email).subscribe(result => {
      this.toastr.success("Thanks for your interest! We will reach out to you shortly.")
      this.email = "";
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }
}
