import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Login } from 'src/app/_enums/login.enum';
import { SignUpInterface } from 'src/app/_enums/signup.enum';
import { ForgotPassword } from 'src/app/_enums/forgot-password.enum';
import { ResetPassword, ResetPasswordResponce } from 'src/app/_enums/reset-password.enum';
import { ForgotPasswordOutput } from 'src/app/_enums/forgot-password-output.enum';
import { SignUpOutPutInterface } from 'src/app/_enums/signUpOutPutOutput.enum';
import { LoginOutput } from 'src/app/_enums/login-output.enum';
import { environment, api_base_url } from 'src/environments/environment';
import { SocialLoginOutput } from 'src/app/_enums/social-login-output.enum';

@Injectable({
  providedIn: 'root'
})

export class SignInSignOutService {

  constructor(private http: HttpClient) {
  }

  postLoginToServer(login: Login): Observable<LoginOutput> {
    return this.http.post<LoginOutput>(environment.sso_login_api, login)
  }

  postSocialLogin(socialLogin: any): Observable<SocialLoginOutput>{
    return this.http.post<SocialLoginOutput>(environment.sso_social_login_api, socialLogin)
  }

  postSignUpToServer(signup: SignUpInterface): Observable<SignUpOutPutInterface> {
    return this.http.post<SignUpOutPutInterface>(environment.sso_sign_up_api, signup)
  }

  partialRegistrationUpdate(signup: any): Observable<SignUpOutPutInterface> {
    return this.http.post<SignUpOutPutInterface>(environment.partial_sign_up_update, signup)
  }

  postForgotPasswordServer(fpass: ForgotPassword): Observable<ForgotPasswordOutput> {
    fpass.source = 'TRINKA'
    const forgot_pass_api_url = environment.auth_api + "/api/v1/user/password/forgot"
    return this.http.post<ForgotPasswordOutput>(forgot_pass_api_url, fpass)
  }

  logout(logout: any): Observable<SignUpOutPutInterface> {
    const logout_api_url = environment.auth_api + "/api/v1/logout"
    return this.http.post<SignUpOutPutInterface>(logout_api_url, logout)
  }

  requestAccessToken(payload: any): Observable<LoginOutput> {
    const request_token_api_url = environment.auth_api + "/api/v1/token/refresh"
    return this.http.post<LoginOutput>(request_token_api_url, payload);
  }

  postResetPasswordRequestCall(resetPassword: ResetPassword): Observable<ResetPasswordResponce> {
    return this.http.put<ResetPasswordResponce>(environment.auth_api + "/api/v1/user/password/reset", resetPassword);
  }

  // postForPaymentVarification(obj: PaymentVerifySubmit): Observable<PaymentVerifySubmit[]> {
  //   return this.http.post<LoginOutput>(this.paymentVarificationRequestUrl, obj)
  // }
}
