  <div class="select_language">
        <div class="enable_editing" [ngClass]="langCode.indexOf('en') === -1 ? 'disabled-class' : ''"> 
            <strong class="blue_dots"></strong>
                  <mat-form-field class="custom_dropdown_sections">
                    <mat-select [disabled]="langCode.indexOf('en') === -1" [(ngModel)]="masterName" (ngModelChange)="onLanguageTypeSelect()">
                      <mat-option value="US">
                        American English
                      </mat-option>
                      <mat-option value="UK">
                        British English
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
        </div>
    </div>