<div class="container h-100 language-pre mat-typography-config p-4" fxLayout.xs="column" fxLayoutAlign="left"
        fxLayoutGap.xs="0" fxLayout="row">
        <button class="closedButton" (click)= closedLanguageModal() title="Close">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 47.971 47.971"
                        style="enable-background:new 0 0 47.971 47.971;" xml:space="preserve">
                        <g>
                                <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                                        c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                                        C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                                        s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z" />
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                </svg>
        </button>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="userDetails.language">
                <mat-radio-button value="US" (click)="languagePrefe('US')"> American English<br>eg. center, not centre
                </mat-radio-button><br>
                <img class="img-fluid" src="../../../assets/images/divider.png"><br>
                <mat-radio-button value="UK" (click)="languagePrefe('UK')"> British English<br>eg. centre, not center
                </mat-radio-button>
        </mat-radio-group>

</div>