import { Component, OnInit } from '@angular/core';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { TechnicalCheck, Abstract, ManuscriptBody, ArticleType } from 'src/app/_interfaces/author-one/technical-check';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { TechnicalComplianceDialogComponent } from './technical-comliance-dialog/technical-comliance-dialog.component';

@Component({
  selector: 'app-technical-compliance',
  templateUrl: './technical-compliance.component.html',
  styleUrls: ['./technical-compliance.component.scss']
})
export class TechnicalComplianceComponent implements OnInit {

  abstractData : Abstract;
  manuScriptData:ManuscriptBody;
  articleTypeData: ArticleType;
  summaryData:any;
  menuscript_present:Boolean= false;
  abstract_present:Boolean = false;
  abstractLength : any;
  wordsCount :number = 0;
  characterCount:number = 0;

  constructor(public dialog: MatDialog,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService) {
    this.getReportData();
     }

  ngOnInit() {
  }

  getReportData(){   
    this.subjectiveBehaviorSharedService.getA1AllCheckReportData.subscribe(reportData=>{
      this.abstractData = reportData['technical_compliance']['abstract'];
      this.manuScriptData = reportData['technical_compliance']['manuscript_body'];
      this.summaryData = reportData['summary'];
      this.abstractLength = reportData.technical_compliance.abstract.length;   
      this.menuscript_present = reportData.technical_compliance.manuscript_body.is_present;
      this.abstract_present = reportData.technical_compliance.abstract.is_present;
      this.articleTypeData = reportData.technical_compliance.article_type;
      this.getWordCount();
      this.summaryData.info.title =this.summaryData.info.title.trim();    
  });
  }
  getWordCount(){
    this.wordsCount = 0
    if(this.manuScriptData.sections_list){
      this.manuScriptData.sections_list.forEach((sections)=>{ this.wordsCount += sections.length })
    }
  }

  viewDetails(title,data){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: title,
      data:data
    };
    this.dialog.open(TechnicalComplianceDialogComponent, dialogConfig);

  }
}
