<div class="content_wrap_section">
    <div class="wrap_header_sectons">
        <div class="top_header_overlay"></div>
        <h2>About Us</h2>
    </div>
    <div class="about_us_wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2>Our vision is to remove language and writing skills as barriers to effective communication of
                        knowledge</h2>
                </div>
            </div>

            <div class="row divider_lines_aboutus">
                <div class="col-md-6">
                    <div class="about_us_right_contents">
                        <h3>Trinka is the only tool for academic & technical writing</h3>
                        <p>Formal writing – academic and technical – is complex and daunting. No writing tools for this
                            genre means that authors have to pour in much time and effort and yet be unsure. This is why
                            we created Trinka – the AI-powered formal writing assistant. Trinka is not just about
                            grammar; it helps you communicate knowledge effectively with language enhancements that help
                            you write for a global audience.</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="about_us_right_contents padding_left_side">
                        <h3>We are proudly Indian and proudly global</h3>
                        <p>India has long been considered the information technology hub of the world. Yet, there are
                            not many globally used products made in India, especially in the field of AI and natural
                            language processing. We wanted to change that. And we did. Trinka is made in India and made
                            for the global user. Trinka exemplifies the perfect combination of our rich, diverse
                            linguistic pedigree and technology prowess. Yes, this is another first!</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="about_us_right_contents">
                        <h3>We honor our inspiration</h3>
                        <p>Trinka is named after the one woman who started it all and keeps us going still – Trinka! Our
                            lead linguist and the one who has made it her mission to remove language as a barrier to
                            effective communication of knowledge, continues to inspire us with her resolve. There
                            couldn’t have been a better name.</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="about_us_right_contents padding_left_side">
                        <h3>We keep pushing the limits</h3>
                        <p>We are a team of 20 passionate individuals who believe in going beyond the state of the art
                            and never say no to a challenge. We have made several advances in natural language
                            processing and AI technology for language enhancement. And we are not slowing down. If you
                            are up for a challenge, <a href="https://www.crimsoni.ai/work-with-us.htm">join us!</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>