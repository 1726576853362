import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'openAccessFilter',
    pure: false
  })
  export class OpenAccessFilterPipe implements PipeTransform {
  
    transform(items: any, filter: string): any {
      let currentfilter = filter["Show open access only"].data;

      if (!items) return [];
      
      if (!currentfilter) return items;

      return items.filter(result => {return (result.open_access == currentfilter || result.open_access == null)});
    }
  }