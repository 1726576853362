<div class="AdvanceSettingsPopupSection">
    <div class="AdvanceSettingsHeader">
        <button mat-button (click)="close()">
            <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>
    <div class="upload_file_checked SelectCategoriesSection">
        <h2>Select categories</h2>
        <mat-checkbox id="grammar" [(ngModel)]="fileDataObject.grammar">Grammar</mat-checkbox>
        <mat-checkbox id="spellings" [(ngModel)]="fileDataObject.spellings">Spellings</mat-checkbox>
        <mat-checkbox id="enhancement" [(ngModel)]="fileDataObject.enhancement">Enhancements</mat-checkbox>
        <mat-checkbox id="writing_advisor" [(ngModel)]="fileDataObject.writing_advisor">Writing Advisor</mat-checkbox>
    </div>

    <div class="upload_file_checked SelectStyleGuideSection">
        <h2>Select a style guide</h2>
        <mat-radio-group aria-label="Select a style guide" [(ngModel)]="fileDataObject.style_guide">
            <mat-radio-button name="style_guide_none" [value]="'NONE'" [checked]="fileDataObject.style_guide == styleGuideDisplay['NONE']">None</mat-radio-button> &nbsp;
             <mat-radio-button name="style_guide_ama" [value]="'AMA'" [checked]="fileDataObject.style_guide == styleGuideDisplay['AMA']">{{styleGuideDisplay['AMA']}}</mat-radio-button> &nbsp;
             <mat-radio-button name="style_guide_agu" [value]="'AGU'" [checked]="fileDataObject.style_guide == styleGuideDisplay['AGU']">{{styleGuideDisplay['AGU']}}</mat-radio-button> &nbsp;
             <mat-radio-button name="style_guide_apa" [value]="'APA'" [checked]="fileDataObject.style_guide == styleGuideDisplay['APA']">{{styleGuideDisplay['APA']}}</mat-radio-button> &nbsp;
             <mat-radio-button name="style_guide_acs" [value]="'ACS'" [checked]="fileDataObject.style_guide == styleGuideDisplay['ACS']">{{styleGuideDisplay['ACS']}}</mat-radio-button> &nbsp;
             <mat-radio-button name="style_guide_ieee" [value]="'IEEE'" [checked]="fileDataObject.style_guide == styleGuideDisplay['IEEE']">{{styleGuideDisplay['IEEE']}}</mat-radio-button> &nbsp;
        </mat-radio-group>
    </div>
    <div class="upload_file_checked advanceFooterSection">
        <mat-checkbox checked id="suggestions_check" [(ngModel)]="fileDataObject.writing_advisor_as_comments">
            Show non-actionable suggestions as comments
        </mat-checkbox>
        <!-- <mat-checkbox checked id="summary_corrections" [(ngModel)]="fileDataObject.statistics_in_document">
            Add a supplementary page with the revision summary <a
                href="../../../../assets/DemoFileBackCoverPage.pdf" target="_blank">View Sample</a>
        </mat-checkbox>  -->
    </div>
    

</div>