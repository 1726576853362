<ngx-spinner></ngx-spinner>
  <header class="header-wrapper">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-6">
          <div class="logo">
            <img src="../../../assets/images/Trinka_Logo.svg" [routerLink]="['/dashboard']" alt="logo" />
            <span class="tags-box" *ngIf="paymentDetails?.user_type==='BASIC'">Basic</span>
            <span class="tags-box-premium" *ngIf="paymentDetails?.user_type !=='BASIC'">{{uppercaseFirstLetter(paymentDetails?.user_type)}}</span>
          </div>
        </div>
        <div class="col-6">
          <div class="media media-credits">
            <div class="media-body">
              <p>
                <img src="../../../assets/images/reference-quality/Vector.svg" alt="vector" />
                Credits: <strong>
                  <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits != 0"
                    (click)="credits()">{{ creditsDetails.credits + creditsDetails.purchased_credits || 0 }}</span>
                  <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0"
                    (click)="credits()">Nil</span>
                  <span *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0
                  " class="autoEditBuyOption" (click)="addCredits()">Buy</span>

                </strong>
              </p>
            </div>
            <div class="media-right">
              <button mat-icon-button [matMenuTriggerFor]="menu1" class="p-0">
                <span class="name-initial">{{profileName}}</span>
              </button>
              <mat-menu #menu1="matMenu" class="profile-menu personalize_top_menus">
                <button mat-menu-item [routerLink]="['/dashboard/profile']">
                  <img class="img-fluid" src="../../../../assets/images/Icon_ManageProfile.svg" alt="logo favicon">
                  <span> Account Settings</span>
                </button>
                <button mat-menu-item (click)="signOut()"><img class="img-fluid"
                    src="../../../../assets/images/Icon_Logout.svg" alt="logo favicon">
                  Logout</button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="sub-header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-9">
          <div class="media align-items-center">
            <div class="media-body d-flex align-items-center">
              <div class="section-title section-flex">
                <h3>Journal Finder Results</h3>
                <p *ngIf="report?.created_on"> <span>Generated on: {{report?.created_on | date: "medium" }} | </span><a href="#seeInputModal" data-toggle="modal" data-target="#seeInputModal">View Abstract</a></p>
              </div>
              <a href="#renewResultsModal" *ngIf="report?.is_report_expire" data-toggle="modal" data-target="#renewResultsModal" class="renew-results">Renew Results</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="search-box">
            <a (click)="backToJournalFinderReport()" [routerLink]="['/journal-finder']">Back to Search</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!----------------- SEE INPUT MODAL  -------------->
  <div class="modal" id="seeInputModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content modal-800">
        
        <!-- Modal body -->
        <div class="modal-body abstract-content">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h3>Abstract</h3>
            <p>{{abstractText}}</p>
        </div>
        
      </div>
    </div>
  </div>
  <!----------------- SEE INPUT MODAL ENDS -------------->

  <!----------------- RENEW RESULTS MODAL  -------------->
  <div class="modal" id="renewResultsModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body renew-content">
          <div class="icon">
            <img src="../../../assets/images/renew-modal-icon.svg" alt="icon" />
          </div>
          <h3>Renew Results</h3>
          <p>Renew your results for this abstract with just <b>2 credits</b>.</p>
          <p class="mb-4">Your results will be valid for another 90 days from the date of renewal.</p>
          <a href="javascript:void(0)" class="useCredits" data-dismiss="modal" (click)="renewJournalReport()">Use 2 Credits</a><br/>
          <a href="javascript:void(0)" data-dismiss="modal" class="cancelModal">Cancel</a>
        </div>
        
      </div>
    </div>
  </div>
  <!----------------- RENEW RESULTS MODAL ENDS -------------->

  <div class="filter-section" *ngIf="showResults">
    
    <div class="container">
      <div class="filter-wrapper" [ngClass]="report?.is_report_expire ? 'disable-tootip' : ''">
        <div class="popover-content">
          <div class="media align-items-center">
            <div class="media-left">
              <img src="../../../assets/images/flat-color-icons_expired.svg" alt="icon" />
            </div>
            <div class="media-body">
              <p>Your report has expired. Click on Renew Results to get updated results with filters and recent articles. </p>
            </div>
          </div>
          <a href="#">Why do my results expire? <i class="fa fa-external-link" aria-hidden="true"></i></a>
        </div>
        <div class="row">
          <div class="col-12">
            <h2>Filters</h2>
          </div>
          <!-- Target Journal -->
          <div class="col-12" style="display: flex;">
            
            <button mat-button [matMenuTriggerFor]="targetfiltermenu" class="target_journal_filter_btn">Target Journal<i class="fa fa-caret-down" aria-hidden="true"></i></button>
            <mat-menu #targetfiltermenu="matMenu" class="target_journal_filter_menus">
              <div class="mt-search">
                <input type="text" [(ngModel)]="searchJournalText" placeholder="search" (click)="$event.stopPropagation()" (keyup)="searchJournalTitle($event)"/>
              </div>
              <div class="check-container" *ngIf="searchJournalText==''">
                <div  *ngFor="let filterelement of filterList.target_journal.detected_by_AI | keyvalue" (click) = "$event.stopPropagation()">
                  <div class="form-group-check">
                    <input type="checkbox" id="{{filterelement.value.id}}" [checked]="filterelement.value.status" (change)="filterChange('target_journal','detected_by_AI',filterelement.value.id,$event)">
                    <label for="{{filterelement.value.id}}" title="{{filterelement.value.id}}">{{filterelement.value.id}}</label>
                  </div>
                </div>
              </div>
                
              <div class="check-container"  style="padding-top: 0px;" *ngIf="searchJournalText==''">
                <div  *ngFor="let filterelement of filterList.target_journal.added_by_you | keyvalue" (click) = "$event.stopPropagation()">
                  <div class="form-group-check" >
                    <input type="checkbox" id="{{filterelement.value.id}}" [checked]="filterelement.value.status" (change)="filterChange('target_journal','added_by_you',filterelement.value.id,$event)">
                    <label for="{{filterelement.value.id}}" title="{{filterelement.value.id}}">{{filterelement.value.id}}</label>
                  </div>
                </div>
              </div>

              <div class="region-container" *ngIf="searchJournalText!=''">
                <div  *ngFor="let filterelement of searchJournallist | keyvalue" (click) = "$event.stopPropagation()">
                  <div class="form-group-check" >
                    <input type="checkbox" id="{{filterelement.key}}"  (change)="addUserFilter($event,'target_journal', filterelement.value)">
                    <label for="{{filterelement.key}}" title="{{filterelement.value}}">{{filterelement.value}}</label>
                  </div>
                </div>
                <div *ngIf="searchJournallist.length==0">
                  <span>Not Found</span> 
                </div>
              </div>
                
              <div class="select-btn-grp">
                <input type="button" class="btn btn-cancel" [disabled] = "!filterDisplayList['Target Journal'].data.length" (click)="resetTargetJournals($event)" value="Reset"/>
              </div>
            </mat-menu>


          <!-- Region -->

            <button mat-button [matMenuTriggerFor]="regionfiltermenu" class="target_journal_filter_btn">Geography<i class="fa fa-caret-down" aria-hidden="true"></i></button>
            <mat-menu #regionfiltermenu="matMenu" class="target_journal_filter_menus">
              <div class="mt-search">
                <input type="text" [(ngModel)]="searchRegionText" placeholder="search" (click)="$event.stopPropagation()" (keyup)="searchRegion($event)"/>
              </div>

              <div class="region-container" *ngIf="searchRegionText==''">
                <div  *ngFor="let filterelement of filterList.geography.detected_by_AI | keyvalue" (click) = "$event.stopPropagation()">
                  <div class="form-group-check">
                    <input type="checkbox" id="{{filterelement.value.id}}" [checked]="filterelement.value.status" (change)="filterChange('geography','detected_by_AI',filterelement.value.id,$event);">
                    <label for="{{filterelement.value.id}}" title="{{filterelement.value.id}}">{{filterelement.value.id}}</label>
                  </div>
                </div>
              </div>

              <div class="region-container" *ngIf="searchRegionText!=''">
                <div  *ngFor="let filterelement of searchRegionList | keyvalue; let i=index" (click) = "$event.stopPropagation()">
                  <div class="form-group-check" >
                    <input type="checkbox" id="{{filterelement.value}}" (change)="filterChange('geography','detected_by_AI',filterelement.value,$event);">
                    <label for="{{filterelement.value}}" title="{{filterelement.value}}">{{filterelement.value}}</label>
                  </div>
                </div>
                <div *ngIf="searchRegionList.length==0">
                  <span>Not Found</span> 
                </div>
              </div>

              <div class="select-btn-grp">
                <input type="button" class="btn btn-cancel" [disabled] = "!filterDisplayList['Geography'].data.length" (click)="resetSelectionFilter($event, 'Geography')" value="Reset"/>
              </div>
            </mat-menu>

          <!-- Subject Area -->
            
            <!-- <button mat-button [matMenuTriggerFor]="subjectfiltermenu" class="target_journal_filter_btn">Subject Area<i class="fa fa-caret-down" aria-hidden="true"></i></button>
            <mat-menu #subjectfiltermenu="matMenu" class="target_journal_filter_menus">
              <div class="mt-search">
                <input type="text" placeholder="search" (click)="$event.stopPropagation()" (keyup)="searchSubjectArea($event)"/>
              </div>
              <div class="check-container" *ngIf="searchSubjectText==''">
                <div  *ngFor="let filterelement of filterList.subject_area_filter.detected_by_AI | keyvalue" (click) = "$event.stopPropagation()">
                  <div class="form-group-check">
                    <input type="checkbox" id="{{filterelement.value.id}}" [checked]="filterelement.value.status" (change)="filterChange('subject_area','detected_by_AI',filterelement.value.id,$event);">
                    <label for="{{filterelement.value.id}}" title="{{filterelement.value.id}}">{{filterelement.value.id}}</label>
                  </div>
                </div>
              </div>

              <div class="check-container" *ngIf="searchSubjectText!=''">
                <div  *ngFor="let filterelement of searchSubjectList | keyvalue; let i=index" (click) = "$event.stopPropagation()">
                  <div class="form-group-check" >
                    <input type="checkbox" id="{{filterelement.value}}" (change)="filterChange('subject_area','detected_by_AI',filterelement.value,$event);">
                    <label for="{{filterelement.value}}" title="{{filterelement.value}}">{{filterelement.value}}</label>
                  </div>
                </div>
                <div *ngIf="searchSubjectList.length==0">
                  <span>Not Found</span> 
                </div>
              </div>
                
              <div class="select-btn-grp">
                <input type="button" class="btn btn-cancel" (click)="resetSelectionFilter($event, 'Subject Area')" value="Reset"/>
                <input type="button" class="btn btn-primary btn-apply" value="Apply"/>
              </div>
            </mat-menu> -->

          <!-- Impact Filter -->
            <button mat-button [matMenuTriggerFor]="impactfiltermenu" class="target_journal_filter_btn">Impact Factor<i class="fa fa-caret-down" aria-hidden="true"></i></button>
            <mat-menu #impactfiltermenu="matMenu" class="target_journal_filter_menus">
              <div class="mt-filter-note">
                <img src="../../../assets/images/Icon_Info.svg" />
                <span>Impact factor is a measure of the frequency with which the “average article” in a journal has been cited in a particular year or period.</span>
              </div>
              <div class="check-container">
                <!-- <div class="rd-btn">
                  <input type="radio" id="autoImpact" [checked]="impactSelectionType" (change)="selectImfactorType(0,false, $event)" name="impact" (click)="$event.stopPropagation()">
                  <label for="autoImpact" (click)="$event.stopPropagation()">Default</label>
                </div> -->
                <!-- <div *ngFor="let filterelement of filterList.impact_factor.detected_by_AI | keyvalue; let i=index">
                  <div class="check-rs" [ngClass]= "impactSelectionType ? '' : 'disable-check'">
                    <div class="form-group-check">
                      <input type="checkbox" id="{{filterelement.value.id}}" (change)="selectImfactorType(0, true, $event)"  [checked]="filterelement.value.status" (click)="$event.stopPropagation()">
                      <label for="{{filterelement.value.id}}" (click)="$event.stopPropagation()">{{filterelement.value.id}}</label>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="rd-btn">
                  <input type="radio" id="manualImpact" name="impact" (change)="selectImfactorType(1, false, $event)" (click)="$event.stopPropagation()">
                  <label for="manualImpact" (click)="$event.stopPropagation()">Custom</label>
                </div> -->
                <div class="custom_title">Select range to filter</div>
                <div class="custom-container">
                  <div class="custom-content"> <!-- [ngClass]= "impactSelectionType ? 'disable-check' : ''"-->
                    <span class="pr-10">Min</span>
                    <input type="number" placeholder="0" oninput="value>=0 ||(value='');" [(ngModel)]="impact_factor_custom.min" (keyup)="selectImfactorType(1, true, $event)" (click)="$event.stopPropagation()"/>
                    <span class="text-center pl-10 pr-10">-</span>
                    <input type="number" placeholder="0" min="0" oninput="value>=0 ||(value='');"  [(ngModel)]="impact_factor_custom.max" (keyup)="selectImfactorType(1, true, $event)"  (click)="$event.stopPropagation()"/>
                    <span class="pl-10">Max</span>
                  </div>
                </div>
                <div class="error" [ngClass]="isNumber(impact_factor_custom.max) =='number' && isNumber(impact_factor_custom.min) =='number'? impact_factor_custom.min> impact_factor_custom.max ? '' : 'shine_loader_hide' : 'shine_loader_hide' "><span>Min value cannot be greater than Max value</span></div>
              </div>
              <div class="select-btn-grp">
                <input type="button" class="btn btn-cancel" (click)="resetRangeFilter($event,'Impact Factor')" value="Reset"/>
              </div>
            </mat-menu>


            <!-- Hindex Filter -->
            <button mat-button [matMenuTriggerFor]="hindexfiltermenu" class="target_journal_filter_btn">H Index<i class="fa fa-caret-down" aria-hidden="true"></i></button>
            <mat-menu #hindexfiltermenu="matMenu" class="target_journal_filter_menus">
              <div class="mt-filter-note">
                <img src="../../../assets/images/Icon_Info.svg" />
                <span>The H index or Hirsch index is the journal's number of articles (h) that have received at least h citations. It quantifies both journal scientific productivity and scientific impact.</span>
              </div>
              <div class="check-container">
                <!-- <div class="rd-btn">
                  <input type="radio" id="autohindex" [checked]="hIndexSelectionType" (change)="selectHIndexType(0,false, $event)" name="hindex" (click)="$event.stopPropagation()">
                  <label for="autohindex" (click)="$event.stopPropagation()">Default</label>
                </div>
                <div *ngFor="let filterelement of filterList.h_index.detected_by_AI | keyvalue; let i=index">
                  <div class="check-rs" [ngClass]= "hIndexSelectionType ? '' : 'disable-check'">
                    <div class="form-group-check">
                      <input type="checkbox" id="{{filterelement.value.id}}" (change)="selectHIndexType(0, true, $event)"  [checked]="filterelement.value.status" (click)="$event.stopPropagation()">
                      <label for="{{filterelement.value.id}}" (click)="$event.stopPropagation()">{{filterelement.value.id}}</label>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="rd-btn">
                  <input type="radio" id="manualHIndex" name="hindex" (change)="selectHIndexType(1, false, $event)" (click)="$event.stopPropagation()">
                  <label for="manualHIndex" (click)="$event.stopPropagation()">Custom</label>
                </div> -->
                <div class="custom_title">Select range to filter</div>
                <div class="custom-container">
                  <div class="custom-content"> <!-- [ngClass]= "hIndexSelectionType ? 'disable-check' : ''"-->
                    <span class="pr-10">Min</span>
                    <input type="number" placeholder="0" oninput="value>=0 ||(value='');" [(ngModel)]="hIndex_custom.min" (keyup)="selectHIndexType(1, true, $event)" (click)="$event.stopPropagation()"/>
                    <span class="text-center pl-10 pr-10">-</span>
                    <input type="number" placeholder="0" oninput="value>=0 ||(value='');" [(ngModel)]="hIndex_custom.max" (keyup)="selectHIndexType(1, true, $event)"  (click)="$event.stopPropagation()"/>
                    <span class="pl-10">Max</span>
                  </div>
                </div>
                <div class="error" [ngClass]="isNumber(hIndex_custom.max) =='number' && isNumber(hIndex_custom.min) =='number'? hIndex_custom.min> hIndex_custom.max ? '' : 'shine_loader_hide' : 'shine_loader_hide' "><span>Min value cannot be greater than Max value</span></div>
              </div>
              <div class="select-btn-grp">
                <input type="button" class="btn btn-cancel" (click)="resetRangeFilter($event, 'H Index')" value="Reset"/>
              </div>
            </mat-menu>



            <!-- Citescore Filter -->
            <button mat-button [matMenuTriggerFor]="citescorefiltermenu" class="target_journal_filter_btn">CiteScore<i class="fa fa-caret-down" aria-hidden="true"></i></button>
            <mat-menu #citescorefiltermenu="matMenu" class="target_journal_filter_menus">
              <div class="mt-filter-note">
                <img src="../../../assets/images/Icon_Info.svg" />
                <span>CiteScore metric is the measure of the number of citations received in a year against the number of articles published by the journal in the previous 3 years.</span>
              </div>
              <div class="check-container">
                <!-- <div class="rd-btn">
                  <input type="radio" id="autoCiteScore" [checked]="citeScoreSelectionType" (change)="selectCiteScoreType(0,false, $event)" name="citeScore" (click)="$event.stopPropagation()">
                  <label for="autoCiteScore" (click)="$event.stopPropagation()">Default</label>
                </div>
                <div *ngFor="let filterelement of filterList.cite_score.detected_by_AI | keyvalue; let i=index">
                  <div class="check-rs" [ngClass]= "citeScoreSelectionType ? '' : 'disable-check'">
                    <div class="form-group-check">
                      <input type="checkbox" id="{{filterelement.value.id}}" (change)="selectCiteScoreType(0, true, $event)"  [checked]="filterelement.value.status" (click)="$event.stopPropagation()">
                      <label for="{{filterelement.value.id}}" (click)="$event.stopPropagation()">{{filterelement.value.id}}</label>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="rd-btn">
                  <input type="radio" id="manualCiteScore" name="citeScore" (change)="selectCiteScoreType(1, false, $event)" (click)="$event.stopPropagation()">
                  <label for="manualCiteScore" (click)="$event.stopPropagation()">Custom</label>
                </div> -->
                <div class="custom_title">Select range to filter</div>
                <div class="custom-container">
                  <div class="custom-content"> <!--  [ngClass]= "citeScoreSelectionType ? 'disable-check' : ''" -->
                    <span class="pr-10">Min</span>
                    <input type="number" placeholder="0" oninput="value>=0 ||(value='');" [(ngModel)]="citeScore_custom.min" (keyup)="selectCiteScoreType(1, true, $event)" (click)="$event.stopPropagation()"/>
                    <span class="text-center pl-10 pr-10">-</span>
                    <input type="number" placeholder="0" oninput="value>=0 ||(value='');" [(ngModel)]="citeScore_custom.max" (keyup)="selectCiteScoreType(1, true, $event)"  (click)="$event.stopPropagation()"/>
                    <span class="pl-10">Max</span>
                  </div>
                </div>
                <div class="error" [ngClass]="isNumber(citeScore_custom.max) =='number' && isNumber(citeScore_custom.min) =='number'? citeScore_custom.min> citeScore_custom.max ? '' : 'shine_loader_hide' : 'shine_loader_hide' "><span>Min value cannot be greater than Max value</span></div>
              </div>
              <div class="select-btn-grp">
                <input type="button" class="btn btn-cancel" (click)="resetRangeFilter($event, 'Cite Score')" value="Reset"/>
              </div>
            </mat-menu>

            <!-- Concept -->
            <!-- <button mat-button [matMenuTriggerFor]="conceptfiltermenu" class="target_journal_filter_btn">Concept<i class="fa fa-caret-down" aria-hidden="true"></i></button>
            <mat-menu #conceptfiltermenu="matMenu" class="target_journal_filter_menus">
              <div class="mt-search">
                <input type="text" placeholder="search" (click)="$event.stopPropagation()" (keyup)="searchConcept($event)"/>
              </div>
              <div class="check-container" *ngIf="searchConceptText==''">
                <div  *ngFor="let filterelement of filterList.concept_filter.detected_by_AI | keyvalue" (click) = "$event.stopPropagation()">
                  <div class="form-group-check">
                    <input type="checkbox" id="{{filterelement.value.id}}" [checked]="filterelement.value.status" (change)="filterChange('concepts','detected_by_AI',filterelement.value.id,$event);">
                    <label for="{{filterelement.value.id}}" title="{{filterelement.value.id}}">{{filterelement.value.id}}</label>
                  </div>
                </div>
              </div>

              <div class="check-container" *ngIf="searchConceptText!=''">
                <div  *ngFor="let filterelement of searchConceptList | keyvalue; let i=index" (click) = "$event.stopPropagation()">
                  <div class="form-group-check" >
                    <input type="checkbox" id="{{filterelement.value}}" (change)="filterChange('concepts','detected_by_AI',filterelement.value,$event);">
                    <label for="{{filterelement.value}}" title="{{filterelement.value}}">{{filterelement.value}}</label>
                  </div>
                </div>
                <div *ngIf="searchConceptList.length==0">
                  <span>Not Found</span> 
                </div>
              </div>

              <div class="select-btn-grp">
                <input type="button" class="btn btn-cancel"(click)="resetSelectionFilter($event, 'Concepts')" value="Reset"/>
                <input type="button" class="btn btn-primary btn-apply" value="Apply"/>
              </div>
            </mat-menu> -->

          <div class="switchContainer">
            <label class="switch switch-dark">
              <input type="checkbox" [checked]="filterList.show_open_access" (change)="changeCheckboxFilter('show_open_access','Show open access only');" >
              <span class="slider round"></span>
              <span class="label right">Show open access only</span>
            </label>
          </div>

            <div class="switchContainer">
              <label class="switch switch-dark">
                <input type="checkbox" [checked]="filterList.hide_predatory_journals" (change)="changeCheckboxFilter('hide_predatory_journals','Hide predatory journals');">
                <span class="slider round"></span>
                <span class="label right">Hide predatory journals</span>
              </label>
            </div>

          </div>
        </div>
      </div>

      <div class="filterLayoutSection">
        <div class="row">
          <div class="col-sm-12">
            <div class="filter-list" id="filterList">
              <div class="single-filter-wrap" *ngFor="let displayFilter of filterDisplayList | keyvalue: unsorted">
                <div class="single-filter single-filter-region" *ngIf="(displayFilter.value.data != '' && displayFilter.value.type != 'boolean') ">
                  <div class="info" *ngIf="displayFilter.value.type == 'array'">
                    <h4 class="name">
                      {{displayFilter.key}}
                    </h4>
                    <span class="filter-divider">:</span>
                    <p class="size" *ngFor="let displayItem of displayFilter.value.data | slice:0:1; let i=index">
                      <span title="{{displayFilter.value.data.join(', ')}}">{{displayItem}}</span>
                    </p>
                    <p class="more" *ngIf="displayFilter.value.data.length > 1">+{{displayFilter.value.data.length - 1}}</p>
                  </div>
                  <div class="info" *ngIf="displayFilter.value.type == 'string'">
                    <h4 class="name">
                      {{displayFilter.key}}
                    </h4>
                    <span class="filter-divider">:</span>
                    <p class="size">
                      {{displayFilter.value.data}}
                    </p>
                  </div>
                  <div class="info" *ngIf="displayFilter.value.type == 'boolean'">
                    <h4 class="name" *ngIf="displayFilter.value.data">
                      {{displayFilter.key}}
                    </h4>
                    <span class="filter-divider" style="display:none;">:</span>
                    <p class="size" style="display:none;">
                      {{displayFilter.value.data}}
                    </p>
                  </div>
                  <div (click)="deleteFilter($event,displayFilter.key)" class="delete" style="cursor: pointer">
                    <span class="cross">&#10060;</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row" *ngIf="filterResult.length!=0">
        <div class="col-12" style="margin-top: 15px;" *ngIf="showResults">
          <h4>Showing the top {{filterResult.length || 0}} journal recommendations based on your search</h4>
          <p>We have sampled {{processed_report?.sample_count || 0}} articles from {{journalData.length || 0}} journals to give this recommendation.</p>
        </div>
      </div>
    </div>
  </div>

  <section class="journal-report-section" *ngIf="showResults">
    <div class="container relative">
      <div class="shine-loader" *ngIf="journalLoader">
        <span class="shine photo shine-lg"></span>
        <span class="shine photo shine-lg"></span>
        <span class="shine photo shine-lg"></span>
        <span class="shine photo shine-lg"></span>
        <span class="shine photo shine-lg"></span>
        <br>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="report-wrapper"
            *ngFor="let journal of journalData| targetjournalFilter: this.filterDisplayList | regionFilter: this.filterDisplayList | subjectareaFilter: this.filterDisplayList | impactFactorFilter: this.filterDisplayList | HIndexFilter: this.filterDisplayList | citeScoreFilter: this.filterDisplayList | conceptFilter: this.filterDisplayList | predatoryFilter: this.filterDisplayList | openAccessFilter: this.filterDisplayList | sortFilter: this.filterDisplayList | slice:0:5; let i=index" #someVar>
            <div class="row">
              <div class="col-12">
                <div class="media">
                  <div class="media-left" *ngIf="journal.open_access || journal.open_access==null">
                    <img src="../../../assets/images/lock.svg" />
                  </div>
                  <div class="media-body">
                    <h1>{{journal.journal_title}}</h1>
                    <p>Publisher: <b>{{journal.publisher || "NA"}}</b> <span *ngIf="journal.predatory_journal=='true'">| <b> Possible Predatory</b></span> <span *ngIf="journal.website_url">|</span> <a *ngIf="journal.website_url" (click) = "logJournalWebsite(journal.journal_title, i+1)" href="{{journal.website_url || ''}}"
                    target="_blank">Journal Website</a></p>
                    <div class="report-left_1">
                      <ul>
                        <li class="popover-item">
                          <span>Impact Factor: <b>{{journal.impact_factor || "NA"}}</b></span>
                          <div class="popover-content">
                            <p>Impact factor is a measure of the frequency with which the "average article" in a journal
                              has been cited in a particular year or period.</p>
                            <em>Source: <a href="#">Clarivate</a></em>
                          </div>
                        </li>
                        <li class="popover-item">
                          <span>H Index: <b> {{journal.h_index || "NA"}}</b></span>
                          <div class="popover-content">
                            <p>The H index or Hirsch index is the journal's number of articles (h) that have received at
                              least h citations. It quantifies both journal scientific productivity and scientific impact.
                              <br><br>Publishing in a high H-index journal maximizes your chances of being cited by other
                              authors and, consequently, may improve your own personal H index score.
                            </p>
                          </div>
                        </li>
                        <li class="popover-item">
                          <span>CiteScore: <b> {{journal.cite_score || "NA"}}</b></span>
                          <div class="popover-content">
                            <p>CiteScore metric is the measure of the number of citations received in a year against the
                              number of articles published by the journal in the previous 3 years.
                              <br><br>Eg. To calculate value for 2019, CiteScore considers citations received for all
                              articles in 2019 to total number or articles published in 2018, 2017 and 2016.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="report-content">
              <div class="row">
                <div class="col-md-5">
                  <div class="report-left">
                    
                    <p class="pc-para">Language: <span>{{journal.language || "NA"}}</span></p>
                    <p class="pc-para">Geography: <span>{{journal.geography || "NA"}}</span></p>
                    <!-- <p class="pc-para">Journal subject areas: <span>{{journal.journal_subject_area || "NA"}}</span></p> -->
                    <a href="javascript:void(0)" (click)="showSimilarArticle(journal.journal_title, journal.similar_article_count, i+1)" [ngClass]="report?.is_report_expire ? 'disable-tootip' : ''">
                      See all similar articles published ({{journal.similar_article_count}})
                      <div class="popover-content">
                        <div class="media align-items-center">
                          <div class="media-left">
                            <img src="../../../assets/images/flat-color-icons_expired.svg" alt="icon" />
                          </div>
                          <div class="media-body">
                            <p>Your report has expired. Click on Renew Results to get updated results with filters and recent articles. </p>
                          </div>
                        </div>
                        <a href="#">Why do my results expire? <i class="fa fa-external-link" aria-hidden="true"></i></a>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-md-7 text-center">
                  <div class="mr-graph-minus">
                    <div class="graphButton">
                      <a href="javascript:void(0)" (click)="changeGraph(0)" class="ml-2" [ngClass]="chartTypeStatus ? 'active' : ''">
                        <img src="../../../assets/images/bar-graph.svg" alt="graph" />
                      </a>
                      <a href="javascript:void(0)" (click)="changeGraph(1)" class="ml-2"  [ngClass]="chartTypeStatus ? '' : 'active'">
                        <img src="../../../assets/images/line-graph.svg" alt="graph" />
                      </a>
                    </div>
                    <div class="graph" *ngIf="chartTypeStatus">
                      <fusioncharts width="100%" height="220" type="Column2d"  placeholder=" "
                        [dataSource]="{chart: {theme: 'fusion', showValues: '1',labelFontSize: '11', yaxisname: 'No. of Articles Published',xaxisname: 'Year',chartBottomMargin: '0', canvasTopPadding: '20', xAxisNameFontSize: '13', yAxisNameFontSize: '13', 'yAxisMaxValue': '5'},data: journal.graphdata.data}">
                      </fusioncharts>
                    </div>
                    <div class="graph" *ngIf="!chartTypeStatus">
                      <fusioncharts width="100%" height="220" type="line"  placeholder=" "
                      [dataSource]="{chart: {theme: 'fusion', showValues: '1',labelFontSize: '11', yaxisname: 'No. of Articles Published',xaxisname: 'Year',chartBottomMargin: '0', xAxisNameFontSize: '13', yAxisNameFontSize: '13', 'yAxisMaxValue': '5'},data: journal.graphdata.data}">
                    </fusioncharts>
                    </div>
                    <div class="popover-item sm-popover">
                      10-year publication trend: <span>{{journal.similar_article_count}}</span> articles with similar
                      concepts published
                      <div class="popover-content">
                        <p>Higher number of articles published in the last 10 years will increase your chances of
                          acceptance. It shows the interest of the Journal to publish articles in similar subject areas.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="rank">Rank #{{journal.journal_rank}}</span>
          </div>

          <div class="no_journals" *ngIf="filterResult.length==0">
            <p class="no_journals_title">No recommendations found for the filters applied</p>
            <p class="no_journals_reset_btn" (click)="resetAllFilter()">Reset all filters</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <a (click)="scrollToTop()" class="scroll-to-top" *ngIf="pageYoffset > 300" ></a>

  <section class="published-section" *ngIf="!showResults"> 
    <div class="result-sort">
      <div class="container ">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="similar-articles">
              <a href="javascript:void(0)" (click)="backToJournalReport()"><img src="../../../assets/images/eva_arrow-back-outline.svg" alt="back" /></a>
              <h3>Similar articles published in</h3>
              <div class="select-dropdown">
                <mat-select class="form-control" disableOptionCentering [(value)]="selectedJournalName" panelClass="testClass" (selectionChange)="getSimilarArticleBasedonSelection($event)">
                  <mat-option *ngFor="let journal of journalData| targetjournalFilter: this.filterDisplayList | regionFilter: this.filterDisplayList | subjectareaFilter: this.filterDisplayList | impactFactorFilter: this.filterDisplayList | HIndexFilter: this.filterDisplayList | citeScoreFilter: this.filterDisplayList  | conceptFilter: this.filterDisplayList | predatoryFilter: this.filterDisplayList | openAccessFilter: this.filterDisplayList | sortFilter: this.filterDisplayList | slice:0:5; let i=index" title="#{{journal.journal_rank}} {{journal.journal_title}} ({{journal.similar_article_count}})" (click)="setValues(journal.journal_rank, journal.similar_article_count )" [value]="journal.journal_title"><b>#{{journal.journal_rank}}</b> {{journal.journal_title}} <span>({{journal.similar_article_count}})</span> </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="sort-by">
              <div class="form-inline"></div>
              <label for="email">Sort by</label>
              <div class="select-dropdown">
                <mat-select [(value)]="selectedSort" disableOptionCentering class="form-control" panelClass="testClass" (selectionChange)="sortArticle($event)" style="font-size: 13px;">
                  <mat-option  value="most">Similarity: Most to Least</mat-option>
                  <mat-option  value="least">Similarity: Least to Most</mat-option>
                </mat-select>
              </div>
            </div>
          </div> -->
          <div class="col-md-4">
            <div class="pagination-result" [ngClass]="similarArticleLoader ? 'disabled' : ''">
              <span>{{simliarArticleData?.article_data?.length == 0 ? 0: lowIndex+1 || 0}}-{{highIndex || 0}} of {{simliarArticleData?.article_data?.length || 0}}</span>
              <button type="button" class="btn-prev" (click)="prevRecord(lowIndex, highIndex)" [ngClass]="lowIndex == 0 ? 'disabled' : ''"><img src="../../../assets/images/checksArrow.png"/></button>
              <button type="button" class="btn-next" (click)="nextRecord(lowIndex, highIndex)" [ngClass]="highIndex == simliarArticleData?.article_data?.length ? 'disabled' : ''"><img src="../../../assets/images/checksArrow.png"/></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container relative">
      <div class="row">
        <div class="col-12 relative">
          <div class="shine-loader" style="display: contents;" *ngIf="similarArticleLoader">
            <span class="shine photo shine-lg"></span>
            <span class="shine photo shine-lg"></span>
            <span class="shine photo shine-lg"></span>
            <span class="shine photo shine-lg"></span>
            <span class="shine photo shine-lg"></span>
            <br>
          </div>
        </div>
        <div class="col-12" *ngFor="let article of simliarArticleData?.article_data | slice:lowIndex:highIndex; let i=index">



          <div class="pd-articles" id="accordion" *ngIf="!similarArticleLoader">
            <span class="similarity">Similarity Score: {{article.score | number : '1.1-1'}}</span>
            <h3>{{article.title || "NA"}}</h3>
            <p class="author-list">Author: <b *ngIf="article.authors[0]!=''">{{article.authors[0]}} et al.</b> <b *ngIf="article.authors[0]==''">NA</b><span>|</span> Published in <b>{{article.date | date: "yyyy" || "NA"}}</b> <span *ngIf="article.googlescholar_title_link">|</span><a  *ngIf="article.googlescholar_title_link" href="{{article.googlescholar_title_link}}"
                target="_blank">View Article</a></p>
            <div class="row">
              <div class="col-lg-1 col-3">
                <h6>Subject Areas:</h6>
              </div>
              <div class="col-lg-11 col-9">
                <div class="subject-content">
                  <span *ngFor="let subject of article.subject_area">{{subject}}</span>
                  <span *ngIf="article.subject_area.length == 0">NA</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-1 col-3">
                <h6>Concepts:</h6>
              </div>
              <div class="col-lg-11 col-9">
                <div class="concept-content">
                  <span class="ellipseContent">{{article.keyphrases.join(', ') || "NA"}}</span>
                </div>
              </div>
            </div>
            
            <div class="author-show collapse" [ngClass]="article?.abstract && !article?.abstractLoader ? 'show' : ''" id="collapseSeven{{i}}" data-target="#accordion">
              <div class="row">
                <div class="col-lg-1 col-3">
                  <h6>Abstract:</h6>
                </div>
                <div class="col-lg-11 col-9">
                  <div class="concept-content">
                    <span class="abstract">{{article?.abstract || ""}}</span>
                    <div class="shine-loader" style="display: contents;" *ngIf="article?.abstractLoader">
                      <span class="shine photo shine-md"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-right" *ngIf="!article?.abstractLoader">
                <a data-toggle="collapse" href="#collapseSeven{{i}}" (click)="openAbstract($event,article.doc_id,i)">{{article?.abstractViewText || "View Abstract & More"}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>