import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByCatergoryExtension'
})
export class FilterByCatergoryExtensionPipe implements PipeTransform {
  catList = [];

  transform(items: any[], searchCategory: any): any[] {
    if (!items) return [];

    return items.filter(it => {
      if (searchCategory.has(it.suggestions[0].type)) {
        it['node'].removeClass('tempTagRemove')
        return true;
      } else {
        it['node'].addClass('tempTagRemove')
      }
    });
  }
}
