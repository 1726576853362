import { ɵNgStyleR2Impl } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';

@Component({
  selector: 'app-save-new-file-details-modal',
  templateUrl: './save-new-file-details-modal.component.html',
  styleUrls: ['./save-new-file-details-modal.component.scss']
})
export class SaveNewFileDetailsModalComponent implements OnInit {

  documentTypeDropdown = [
    { "id": 2, "name": "General" },
    { "id": 3, "name": "Academic" },
    { "id": 4, "name": "Legal" }
  ]

  styleGuideDispay =[
    { "id":'NONE', "name": "None" },
    { "id":"AMA",  "name": "AMA (11th ed)"  },
    { "id":"APA",  "name": "APA (7th ed)"  },
    { "id":"ACS",  "name": "ACS (2nd ed)"  },
    { "id":"AGU",  "name": "AGU (2017 ed)" },
    { "id":"IEEE", "name": "IEEE"  },
    // { "id":"WILEY","name": "Wiley (2020)" }
  ];

  legalStyleGuideList = [
    { id: "NONE", name: "Common Style" },
    { id: "Redbook (3rd ed)-Legal", name: "USA-The Redbook (3rd ed.)" },
    { id: "SCI stereotypical language", name: "India-SCI Gender Handbook" }
  ]

  paper_type = [
    { "id": 0, "name": "Select Paper Type" },
    { "id": 1, "name": "Original Article"  },
    { "id": 2, "name": "Case Report"   },
    { "id": 3, "name": "Review Article"  },
    { "id": 4, "name": "Communication"   },
    { "id": 5, "name": "Other"    },
  ]

  languageCodeOptions = [{
    "code": "en",
    "value": "English",
    "isSelected": false
  },
  // {
  //   "code": "de",
  //   "value": "German",
  //   "isSelected": false
  // },
  {
    "code": "es",
    "value": "Spanish",
    "isSelected": false
  }];

  form: FormGroup;
  userId: string;
  selectedLanguageCode: string[];
  selectedDocumentType: number;
  selectedStyleGuide :string;
  selectedPaperType :string;
  file_id :string;
  selectedFile: any;
  disable_options :boolean = true;
  suggestedSAlist = false;
  subjectAreaListArray = [];
  subjectArea: string;
  subjectAreaList = [];
  subjectAreaData: any;
  subAreaLoaded: boolean = false;
  subjectType = [ ];
  fileName :string ='';
  defualtLang: string;
  defaultLangCode: string[] = [];
  selectedLanguage: string = 'US';
  documentTypeList = ["Select", "General", "Academic", "Legal"];
  loader: boolean = false;

  constructor(private formBuilder: FormBuilder,
    public sharedService: SharedService,
    private networkCalls: DashboardService,
    private spinner: NgxSpinnerService,
    public amplitudeService: AmplitudeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SaveNewFileDetailsModalComponent>,
    private subjectiveBehaviourService: SubjectiveBehaviorSharedService,
    private onlineEditorService: OnlineEditorService) {
        this.file_id = data.file_id;
        this.selectedFile = this.sharedService.renameFile;
        this.fileName = this.selectedFile.file_name;
        this.selectedLanguageCode = data.lang_code;
        this.defaultLangCode = [...data.lang_code];
        this.selectLanguageOptions();
        this.defualtLang = data.lang;
        this.amplitudeService.logAmplitude('File_setting_poupupview',{
          'File ID': this.selectedFile.file_id,
          'Creation type': this.selectedFile.file_name == "Untitled" ? "New File" : "File Upload",
          'editor': 'V1'
        });
        this.getSubjectList();
        this.dialogRef.disableClose = true;
     }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    this.form = this.formBuilder.group({
      file_name: new FormControl(this.fileName, [Validators.minLength(1), Validators.pattern(/^[^/<>:\"\\|?*.]+$/)]),
      uk_lang: new FormControl('British English'),
      us_lang : new FormControl('American English'),
      academic_doc: new FormControl('Academic'),
      general_doc: new FormControl('General'),
      legal_doc: new FormControl('Legal'),
      style_guide: new FormControl(null),
      subject_area: new FormControl({value:"", disabled: true}),
      paper_type: new FormControl(null)
    });
      this.form.get('paper_type').setValue(this.paper_type[0]);
      this.form.get('style_guide').setValue(this.styleGuideDispay[0]);
      this.getSubjectAreas();
  }

  setLanguagePreferenceOption(value: string) {
    this.selectedLanguage = value;
  }

  setLanguageOptions(code: string) {
    const index = this.selectedLanguageCode.findIndex((o) => o === code);
    let languageString = ['']
    if (index !== -1) {
      this.selectedLanguageCode.splice(index, 1);
    } else {
      this.selectedLanguageCode.push(code);
    }
    this.selectLanguageOptions();
    languageString = this.languageCodeOptions.filter((x) => code === x.code).map(obj => obj.value);
    let isSelectedFlagArr = this.languageCodeOptions.filter((x) => code === x.code).map(obj => obj.isSelected);
    if (this.selectedLanguageCode.length > 0) {
      this.amplitudeService.logAmplitude('Popup_Language_Preference', {
        'language': languageString.toString(),
        'isSelected': isSelectedFlagArr.toString()
      });
    }
  }

  setDocumentType(event){
    this.selectedDocumentType = event.target.id == 'academic_doc' ? 3 : event.target.id === 'general_doc' ? 2 : 4;
    this.disable_options = ['academic_doc', 'legal_doc'].includes(event.target.id) ? false : true;
    event.target.id == 'academic_doc' ? this.form.get('subject_area').enable() : this.form.get('subject_area').disable();
    if(this.disable_options){
      this.form.get('paper_type').setValue(this.paper_type[0]);
      this.form.get('subject_area').setValue('');
    }
    this.form.get('style_guide').setValue(this.selectedDocumentType === 4 ? this.legalStyleGuideList[0] : this.styleGuideDispay[0]);
    this.amplitudeService.logAmplitude('PU_Document_Type',{
      'File ID': this.selectedFile.file_id,
      'document_type': this.documentTypeList[this.selectedDocumentType - 1],
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
  }

  selectStyleGuide(event){
    this.selectedStyleGuide = event.value.id;
    this.amplitudeService.logAmplitude('PU_Style_Guide',{
      'File ID': this.selectedFile.file_id,
      'document_type': this.documentTypeList[this.selectedDocumentType - 1],
      'style_guide': event.value.name
    });
  }

  selectPaperType(event){
    this.selectedPaperType = event.value.id == 0 ? "" : event.value.id;
    this.amplitudeService.logAmplitude('PU_Paper_Type',{
      'File ID': this.selectedFile.file_id,
      'Value': event.value.id == 0 ? "" : event.value.name
    });
  }

  setSubjectArea(){
    this.amplitudeService.logAmplitude('PU_Subject_Area',{
      'File ID': this.selectedFile.file_id,
      'Value': this.form.value.subject_area
    });
  }

  submit(val) {
    if(!val){
      this.amplitudeService.logAmplitude('PU_Skip_CTA',{
        'File ID': this.selectedFile.file_id
      });
      this.dialogRef.close(0);
    }

    this.loader = true;
    this.selectedFile.file_name = val ? this.form.value.file_name : this.selectedFile.file_name;
    let curr_lan_code = this.selectedLanguageCode.length > 0 ? this.selectedLanguageCode.toString() : this.defaultLangCode.toString();
    this.selectedFile.lang_code = val ? curr_lan_code : 'en';
    this.selectedFile.language = this.selectedLanguage;
    this.selectedFile.document_type =  val ? this.selectedDocumentType: 1;
    this.selectedFile.style_guide = val ? this.selectedStyleGuide : "NONE";
    if(val){
      this.selectedFile.subject_area_list.push({label :this.form.value.subject_area, isSelected: true, user_added_flag: true});
    }
    this.selectedFile.paper_type = val? this.selectedPaperType: 1 ;
    this.selectedFile.skip_status = false;


    this.networkCalls.putUpdateFile(this.userId, this.selectedFile).subscribe(result => {
      this.spinner.show();
      let eventObj = {};
      this.languageCodeOptions.forEach((x: any) => {
          eventObj[x.value] = x.isSelected;
      });
      this.amplitudeService.logAmplitude('PU_Save_CTA',{
        'File ID': this.selectedFile.file_id,
        ...eventObj,
        'document_type': this.documentTypeList[this.selectedFile.document_type - 1],
        'style_guide': this.selectedFile.style_guide,
        'editor': 'V1'
      });
      result.data['lang_code'] = curr_lan_code;
      this.dialogRef.close(result);
      this.loader = false;
      this.spinner.hide();
    }, error => {
      this.loader = false;
      this.spinner.hide();
      this.sharedService.errorHandller(error);
    })
  }

  searchSubjectArea: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.subjectAreaListArray.filter(v =>v.text.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10).map(item => {
                if (item.levelNo == 3) {
                  return item.text;
                }
              }))
    )


  getSubjectList() {
    this.subAreaLoaded = true;
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.onlineEditorService.subjectAreaValidator(this.userId, this.file_id).subscribe(resp => {
      this.subAreaLoaded = false;
      this.subjectAreaList = resp.data.suggested_subject_area;

      let selObjs = [];
      let filterLabels = [];
      this.subjectType.forEach((subobj) => {
        if (subobj.isSelected || subobj.user_added_flag) {
          selObjs.push(subobj)
          filterLabels.push(subobj.label)
        }
      });
      this.subjectType = []
      selObjs.forEach((selObj) => {
        this.subjectType.push(selObj)
      })
      var found = false;
      if (this.subjectAreaList.length > 0) {
        for (let i = 0; i < this.subjectAreaList.length; i++) {
          if (filterLabels.includes(this.subjectAreaList[i].label)) {
            continue
          }
          this.subjectType.push({ label: this.subjectAreaList[i].label, level_1: this.subjectAreaList[i].level_1, level_2: this.subjectAreaList[i].level_2, isSelected: false, user_added_flag: false })
        }
      }
    }, error => {
      this.sharedService.errorHandller(error);
      this.subAreaLoaded = false;
    });
  }
  getSubjectAreas() {
    this.suggestedSAlist = true;
    this.subjectiveBehaviourService.getSubjectAreaList.subscribe(resp => {
      this.suggestedSAlist = false;
      this.subjectAreaListArray = this.filterSubjectArea(resp);
    }, error => {
      this.suggestedSAlist = false;
      this.sharedService.errorHandller(error);
    });
  }

  filterSubjectArea(SAlist) {
    var temp = [];
    if (SAlist) {
      return SAlist.filter(sa => {
        if (sa.levelNo == 3) {
          if (!temp.includes(sa.text)) {
            temp.push(sa.text);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      })
    } else return false;
  }


  selectLanguageOptions() {
    this.languageCodeOptions.forEach((x) => {
      x.isSelected = this.selectedLanguageCode.indexOf(x.code) > -1;
    });
  }
}

