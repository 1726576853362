
import { NgModule } from "@angular/core";

import { PlagiarismCheckerComponent } from "./plagiarism-checker.component";
import { PlagiarismCheckRoutingModule } from "./plagiarism-check-routing.module";
import { SharedModule } from "../shared/shared.module";
import { CommonModule } from "@angular/common";
import { MaterialModuleList } from "src/app/material-modules";
import { PlagcheckOptionsComponent } from "src/app/components/plagiarism-check/plagcheck-options/plagcheck-options.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlagReportViewerComponent } from "src/app/components/plagiarism-check/plag-report-viewer/plag-report-viewer.component";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PlagCheckReportShareComponent } from "src/app/components/plagiarism-check/plag-check-report-share/plag-check-report-share.component";
import { PlagCheckOnboardingModalComponent } from "src/app/components/plagiarism-check/plag-check-onboarding-modal/plag-check-onboarding-modal.component";
import { PlagCheckSampleReportPopupComponent } from "src/app/components/plagiarism-check/plag-check-sample-report-popup/plag-check-sample-report-popup.component";
import { PushNotificationService } from "src/app/_services/push-notification.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { PlagReportPlansComponent } from "src/app/components/plagiarism-check/plag-report-plans/plag-report-plans.component";
import { PlagBuyCreditsModalComponent } from "src/app/components/plagiarism-check/plag-buy-credits-modal/plag-buy-credits-modal.component";
import { CommonDeleteConfirmationModalComponent } from "src/app/components/common-delete-confirmation-modal/common-delete-confirmation-modal.component";
import { PlagCheckBasicWordLimitModalComponent } from "src/app/components/plagiarism-check/plag-check-basic-word-limit-modal/plag-check-basic-word-limit-modal.component";
@NgModule({
  declarations:
    [
      PlagiarismCheckerComponent,
      PlagcheckOptionsComponent,
      PlagReportViewerComponent,
      PlagCheckReportShareComponent,
      PlagCheckOnboardingModalComponent,
      PlagCheckSampleReportPopupComponent,
      PlagReportPlansComponent,
      PlagBuyCreditsModalComponent,
      CommonDeleteConfirmationModalComponent,
      PlagCheckBasicWordLimitModalComponent
    ],
  imports: [
    PlagiarismCheckRoutingModule,
    CommonModule,
    SharedModule,
    MaterialModuleList,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
  bootstrap: [PlagiarismCheckerComponent],
  providers: [PushNotificationService],
  entryComponents: [
     PlagCheckReportShareComponent,
    PlagCheckOnboardingModalComponent,
    PlagCheckSampleReportPopupComponent,
    PlagBuyCreditsModalComponent,
    CommonDeleteConfirmationModalComponent,
    PlagCheckBasicWordLimitModalComponent
  ]

})
export class PlagiarismCheckModule { }
