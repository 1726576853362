import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable'
import { katanaInput } from 'src/app/_interfaces/katana-input'
import { KatanaResult } from 'src/app/_interfaces/katana-result'
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FileObject } from 'src/app/_interfaces/editor/file';

@Injectable({
  providedIn: 'root'
})
export class OnlineEditorService {

  constructor(public http: HttpClient) { }

  putUpdateFile(userId: any, fileObj: any): Observable<FileObject> {
    return this.http.put<FileObject>(environment.server_address + "/trinka/api/v1/user/" + userId + "/file/" + fileObj.file_id, fileObj)
  }
  putUpdateFileForDocumentType(userId: any, fileId, docType: any): Observable<FileObject> {
    return this.http.put<FileObject>(environment.server_address + "/trinka/api/v1/user/" + userId + "/file/" + fileId, docType)
  }
  getFileById(userId: any, file_id: any): Observable<FileObject> {
    return this.http.get<FileObject>(environment.server_address + "/trinka/api/v1/user/" + userId + "/file/" + file_id)
  }
  createNewFile(userId: any) {
    return this.http.post(environment.server_address + "/trinka/api/v1/user/" + userId + "/file", {})
  }
  uploadFile(userId: any, FileObj: FormData) {
    return this.http.post(environment.server_address + "/trinka/api/v1/user/" + userId + "/file", FileObj)
  }
  enableEditingCall(file_id: string) {
    return this.http.post(environment.server_address + "/ws/v1/trinka/close/" + file_id, {})
  }
  downloadFileById(userId: any, file_id: any) {
    window.location.href = environment.server_address + "/trinka/api/v1/user/" + userId + "/file/" + file_id + "/export";
    // window.open(environment.server_address+"/trinka/api/v1/user/4d387a86-d785-4ab1-94c8-af7e2836b627/file/"+file_id+"/export");
  }
  sendToProofreadingFile(userId: any, file_id: any) {
    return this.http.get(environment.server_address + "/trinka/api/v1/user/" + userId + "/file/" + file_id + "/proofread/plandata");
  }
  subjectAreaRequest(email_id: string) {
    return this.http.get(environment.server_address + "/trinka/api/v1/user/" + email_id + "/proofread/subjectarea");
  }
  sendToProofreadingMakePayment(userId: any, file_id: any, payload) {
    return this.http.post(environment.server_address + "/trinka/api/v1/user/" + userId + "/file/" + file_id + "/proofread/paymentlink", payload);
  }
  pageConfigRequest(email_id: string) {
    return this.http.get(environment.server_address + "/trinka/api/v1/user/" + email_id + "/proofread/pageconfig");
  }
  getSelectedProfileData(email_id: string): Observable<any> {
    return this.http.get<any>(environment.auth_api + "/trinka/api/v1/user/" + email_id + "/profiledetails")
  }
  getLocalCultureData(culture: string): Observable<any> {
    return this.http.get<any>("/assets/language/"+culture+".json")
  }
  submitOrderMin(userId: any, payload) {
    return this.http.post(environment.server_address + "/trinka/api/v1/user/" + userId + "/proofread/submitordermin", payload);
  }
  featurePermission(): Observable<any> {
    return this.http.get<any>(environment.server_address + "/trinka/api/v1/permission")
  }
  subjectAreaValidator(email_id:string, file_id: any):Observable<any> {
    return this.http.get<any>(environment.server_address + "/trinka/api/v1/user/"+ email_id+ "/file/" + file_id + "/subject");
  }

  consistencyCheckerRequest(data: any){
    // return this.http.post<any>(environment.server_address +"/trinka/api/v1/consistency", data);
    return this.http.post<any>(environment.server_address +"/trinka/api/v1/consistency", data);
  }

  submitDocumentForCheck(file_id: any,journalName ?: any){
    return this.http.post<any>(environment.server_address + "/trinka/api/v1/report/submission/"+file_id, journalName);
  }

  requestAllSubmitedReportRevision(file_id: any) {
    return this.http.get<any>(environment.server_address + "/trinka/api/v1/report/submission/"+file_id);
  }

  getGeneratedReportByFileIdSubmissionId(file_id: any, submissionId) {
    return this.http.get<any>(environment.server_address + "/trinka/api/v1/file/"+file_id+"/report/"+submissionId);
  }
  getJournalNameListRequest() {
    return this.http.get<any>("/assets/json/journalNameList.json");
  }

  getDocumentDetailsRequest(email_id:string, file_id: any):Observable<any> {
    return this.http.get<any>(environment.server_address+"/trinka/api/v1/user/"+email_id+"/file/"+file_id+"/details");
  }

  resendVerifyMail(email_id):Observable<any>{
    return this.http.post<any>(environment.auth_api+"/api/v1/user/verifylink/send",{email_id: email_id, source: 'TRINKA'});
  }

  getUserPaymentSubscription(userid){
    return this.http.get(environment.server_address+ "/trinka/api/v1/userplan/"+userid)
  }

  getCreditsDetailsByUser(emailId: string):Observable<any>{
    return this.http.get<any>(environment.credit_service+"/credit/api/v1/user/"+emailId)
  }

  requestAllSubmitedPlgiarismReport(user_id, file_id) {
    return this.http.get<any>(environment.plagiarism_api + "/plagiarism/api/v1/plagiarism/editor/file_list/"+user_id+"/file/"+file_id);
  }

  submitDocumentForPlagiarismCheck(payload: FormData): Observable<any> {
    return this.http.post<any>(environment.plagiarism_api + "/plagiarism/api/v1/editor/upload/plagiarism_file", payload)
  }

  getPlagiarimReportURL(user_id,file_id,submission_id, user_decoded_id): Observable<any>{
    return this.http.get<any>(environment.plagiarism_api + "/plagiarism/api/v1/plagiarism/"+user_id+"/file/"+file_id+"/submission_id/"+submission_id+"/d_user/"+user_decoded_id);
  }

  getEditorUploadFileDetails(user_id,file_id): Observable<any>{
    return this.http.get<any>(environment.server_address + "/trinka/api/v1/userfile/"+user_id+"/file/"+file_id);
  }

  // updateNewFile(file_details) {
  //   return this.http.post(environment.server_address + "/trinka/api/v1/save/file_details", file_details)
  // }
  submitFeedbackCall(payload: any):Observable<any>{
    return this.http.post<any>(environment.feedback_api,payload,{ withCredentials: true });
  }

  sendSentencesToParaphraserRequest(data: any):Observable<any> {
    return this.http.post<any>(`${environment.paraphraser_address}/paraphraser/sentences`, data);
  }

  paraphraseFeedbackRequest(data: any):Observable<any> {
    return this.http.post<any>(`${environment.paraphraser_address}/feedback/sentence`, data);
  }

  paniniFeedbackRequest(user_id: any, file_id: any, data: any):Observable<any> {
    return this.http.post<any>(`${environment.server_address}/trinka-lab/user/`+user_id+"/file/"+file_id, data);
  }
}
