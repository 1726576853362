import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ManageProfileComponent } from '../manage-profile/manage-profile.component';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {

  transactionDetails: any
  isConfirmation: boolean = false
  enagoFlag: boolean = false
  provider : any;
  amplitudeLogData: any = {}
  constructor(public sharedService: SharedService,
    private dialogRef: MatDialogRef<ManageProfileComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    public amplitudeService: AmplitudeService,
    public subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService) { 
        this.enagoFlag = data.is_enago_user;
        this.provider = data.provider;
        this.amplitudeLogData['User ID'] = data.userId;
        this.amplitudeLogData['User source'] = this.enagoFlag ? 'Enago' : "Trinka";
        this.amplitudeLogData['Subscription Plan'] = data.subscriptionPlan;
    }

  ngOnInit() {
    this.logAmplitudeData("Delete_confirmation_popup_view");
    this.subjectiveBehaviorSharedService.setAmplitudeData(this.amplitudeLogData);
  }

  save() {
    this.logAmplitudeData("Yes_Delete_CTA");
    this.dialogRef.close(true);
  }

  close() {
    this.logAmplitudeData("No_keep_the_account_CTA");
    this.dialogRef.close(false);
  }
  
  logAmplitudeData(event){
    this.amplitudeService.logAmplitude(event, this.amplitudeLogData);    
  }

}
