import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TechnicalComplianceComponent } from '../technical-compliance.component';

@Component({
  selector: 'app-technical-comliance-dialog',
  templateUrl: './technical-comliance-dialog.component.html',
  styleUrls: ['./technical-comliance-dialog.component.scss']
})
export class TechnicalComplianceDialogComponent implements OnInit {

  title:any;
  data:any;
  constructor(  private dialogRef: MatDialogRef<TechnicalComplianceComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.title = data.title;
      this.data = data.data;
    }

  ngOnInit() {
  }

}
