export class AuthorOneReport{
  'status':  boolean;
  'message': string;
  'data':   [
    {
      'submission_id':   string,
      'document_status': string,
      'created_at':      Date,
      'updated_at':      Date,
  }
]

constructor() {
   var Datum  : any= [{
    submission_id:   'NA',
    document_status: 'NA',
    created_at:      '',
    updated_at:      '',
  }]
  
    this.status  = false;
    this.message = 'NA';
    this.data = Datum
}
}
