import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirm-access-trinka',
  templateUrl: './confirm-access-trinka.component.html',
  styleUrls: ['./confirm-access-trinka.component.scss']
})
export class ConfirmAccessTrinkaComponent implements OnInit {
  paymentDetails = {
    isPremium: false,
    user_type: 'BASIC',
    is_premium: false
  }
  constructor(private dialogRef: MatDialogRef<ConfirmAccessTrinkaComponent>,
    private amplitudeService: AmplitudeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { 
      console.log(data.data)
      this.paymentDetails = data.data;
    }

  ngOnInit() {
  }

  upgradePlanPopup() {
    let data = {
      "user_id": this.data.userId
    };
    var userData = btoa(encodeURIComponent(JSON.stringify(data)));
    let redirct_url = environment.payment_fe;
    this.amplitudeService.logAmplitude('Upgrade_nudge_clicked', {
      'from': 'Trinka labs',
      'subscription_type': this.paymentDetails.user_type,
      'user_id': this.data.userId
    });
    window.location.href = redirct_url + '/user/' + userData;
    // window.open(redirct_url + '/user/' + userData)
  }

  closeModal(param){
    this.dialogRef.close(param)
  }

}
