import { Component, OnInit } from '@angular/core';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { ReferenceCheck } from 'src/app/_interfaces/author-one/reference-check';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ReferenceCheckDialogComponent } from './reference-check-dialog/reference-check-dialog.component';

@Component({
  selector: 'app-reference-check',
  templateUrl: './reference-check.component.html',
  styleUrls: ['./reference-check.component.scss']
})
export class ReferenceCheckComponent implements OnInit {

  referenceCheck : ReferenceCheck;
  constructor(public dialog: MatDialog,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService) {
    this.getReportData();
     }

  ngOnInit() {
  }

  getReportData(){
    this.subjectiveBehaviorSharedService.getA1AllCheckReportData.subscribe(reportData=>{
      this.referenceCheck = reportData['technical_compliance']['references'];
    });
  }

  viewReferences(individual){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      individualData: individual,
    };
    this.dialog.open(ReferenceCheckDialogComponent, dialogConfig);
  }

}
