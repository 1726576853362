import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortFilter',
    pure: false
  })
  export class SortPipe implements PipeTransform {
    transform(items: any, filter: any): any {
      
      return items.sort(function(a, b) {
        return b.similar_article_count - a.similar_article_count;
      });
    }
  } 