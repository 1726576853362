import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SharedService } from '../_services/shared.service';
import { environment } from 'src/environments/environment';
import { GetMetaService } from '../_services/get-meta.service';

@Injectable()
export class AuthService {
  offsetSeconds = 60
  constructor(
    private sharedService: SharedService,
    public jwtHelper: JwtHelperService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public metaString : GetMetaService
  ) { }
  public isAuthenticated(): boolean {

    if(environment.trialMode){
      return true;
    }

    let token : any;
    let refreshToken : any;
    let isAccessTokenExpired : boolean ;
    if(this.storage.get(this.metaString.getMeta()) != undefined){
      token = this.storage.get(this.metaString.getMeta())['token'];
      refreshToken = this.storage.get(this.metaString.getMeta())['refresh_token'];
      isAccessTokenExpired = this.jwtHelper.isTokenExpired(token,this.offsetSeconds);
      if(!isAccessTokenExpired || (token && refreshToken && this.sharedService.getAccessToken()) ){
        return true;
      }
  }
  return false;
}
}