<div class="fileSettingContents">

  <div class="settingsAppliedMessage" *ngIf="showSettingsAppliedModal;  else settingsModal">
    <div class="fileSettingsAppliedLoader">
      <div class="SVGwrapper">
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </div>
      <p>Settings applied successfully!</p>
    </div>
  </div>
  <ng-template #settingsModal>
    <div class="" *ngIf="afeApplySetting">
      <div class="fileSettingHeader">
        <h2>Apply Settings</h2>
      </div>
      <div class="fileSettingBody">

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <div class="autoEditPreferences" style="padding: 10px 0px 15px 0px; margin-bottom: 24px;">
            <label class="AELabelText">Language Preference</label>
            <div class="categories-container" style="flex-wrap: inherit; width: 100%;">
              <div class="preferenceLanguageSection">
                <div class="selectLanguageSection">
                  <div class="categories-options" (click)="selectlangCode('en')" [ngClass]="modalData.config.langCode == 'en'? 'selected': ''">
                  English
                  </div>
                  <div class="categories-options" (click)="selectlangCode('es')" [ngClass]="modalData.config.langCode == 'es'? 'selected': ''">
                    Spanish <i>New</i>
                    <!-- <div class="spanishToolTipSection">
                      <p>Proofreading for Spanish language is coming soon. To get early access, write to us at support@trinka.ai.</p>
                    </div> -->
                  </div>
                </div>
                <div class="selectLanguageRadioButton" [ngClass]="modalData.config.langCode == 'es'? 'disabled': ''">
                  <mat-radio-group>
                    <mat-radio-button [ngClass]="{ 'selected' : modalData.config.language == 'US' }" (click)="modalData.config.language = 'US'" value="US" [checked]="modalData.config.language == 'US'">US English</mat-radio-button>
                    <mat-radio-button [ngClass]="{ 'selected' : modalData.config.language == 'UK' }" (click)="modalData.config.language = 'UK'" value="UK" [checked]="modalData.config.language == 'UK'">UK English</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

            </div>
          </div>

          <div class="autoEditPreferences AFE_editingMode">
            <label class="AELabelText">Editing Mode<span class="new-badge">New</span>
              <br><span (click)="openPowerModeInfoModal()" class="AFE_learnMoreButton" [ngClass]="modalData.config.langCode == 'es'? 'disabled': ''">Learn More</span>
            </label>
            <div class="categories-container" style="flex-wrap: inherit; width: 100%;">
              <div class="categories-options" [ngClass]="[modalData.config.power_mode ? 'selected' : '' , modalData.config.langCode == 'es'? 'disabled': '' ]"
                (click)="modalData.config.power_mode = true">
                <span>
                  <svg class="powerModeIcon" width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.52416 0.437505C3.56072 0.311163 3.6366 0.200252 3.74043 0.12138C3.84427 0.0425089 3.97046 -7.70039e-05 4.10012 1.04528e-07H8.89981C8.99483 7.33567e-05 9.08847 0.0230647 9.17302 0.0670814C9.25758 0.111098 9.33063 0.174881 9.38616 0.25318C9.4417 0.331479 9.47813 0.422055 9.49246 0.517451C9.50679 0.612847 9.4986 0.710335 9.46858 0.80189L7.33271 7.31207H11.8996C12.012 7.31199 12.1221 7.34395 12.2174 7.40432C12.3128 7.46468 12.3895 7.55103 12.4389 7.65352C12.4883 7.75601 12.5084 7.87053 12.4968 7.98403C12.4853 8.09754 12.4426 8.20547 12.3736 8.29554L3.97413 19.2636C3.88808 19.3765 3.76573 19.4552 3.62843 19.4858C3.49113 19.5164 3.3476 19.4971 3.22288 19.4313C3.09816 19.3654 3.00017 19.2571 2.94602 19.1254C2.89188 18.9936 2.885 18.8467 2.9266 18.7104L5.08766 11.5774H1.10031C1.00704 11.5775 0.915032 11.5555 0.831607 11.5131C0.748181 11.4707 0.675633 11.4092 0.619723 11.3333C0.563813 11.2575 0.526082 11.1695 0.509526 11.0763C0.49297 10.983 0.498046 10.8872 0.52435 10.7963L3.52416 0.437505Z" fill="#FDB933"/>
                  </svg>
                </span>
                Power Mode
                <div class="afe_editingModeToolTip afe_editingPowerMode">
                  <p>This feature is available only for English files.</p>
                </div>
              </div>
              <div class="categories-options liteModeCategoriesOptions" [ngClass]="[!modalData.config.power_mode ? 'selected' : '', modalData.config.langCode == 'es'? 'disabled': '']"
                (click)="modalData.config.power_mode = false">
                <span>
                  <svg class="liteModeIcons" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 13.0001L1.83333 11.6667L3.16667 13.0001L4.5 11.6667L5.83333 13.0001L7.16667 11.6667L8.5 13.0001L10.1667 11.6667L11.5 13.0001" stroke="black" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.5 3.74627L10.8334 3.0796L9.50004 1.7533M9.50004 1.7533L3.50004 7.7836L2.83337 10.4129L5.50004 9.74627L11.8334 3.41294C11.9647 3.28163 12.0689 3.12573 12.14 2.95415C12.2111 2.78257 12.2477 2.59866 12.2477 2.41294C12.2477 2.22721 12.2111 2.04331 12.14 1.87173C12.0689 1.70014 11.9647 1.54425 11.8334 1.41294L11.7454 1.33227C11.4747 1.10403 11.1281 0.985905 10.7744 1.00134C10.4207 1.01678 10.0858 1.16465 9.83604 1.4156L9.50004 1.7533Z" stroke="black" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
                Lite Mode
                <div class="afe_editingModeToolTip afe_editingLiteMode">
                  <p>This feature is available only for English files.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="autoEditPreferences proofreadPreferenceSection">
            <label class="AELabelText">Proofread Preference</label>
            <div class="categories-container">

              <div class="categories-options" [ngClass]="[modalData.config.grammar? 'selected' : !modalData.config.grammar && !modalData.config.spellings && !modalData.config.writing_advisor && !modalData.config.enhancement && modalData.config.style_guide === 'NONE' ? 'error-state' : '', modalData.config.langCode == 'es'? 'isActiveDisabled': '' ]"
                (click)="modalData.config.grammar = !modalData.config.grammar">
                <input class="form-check-input options-checkbox" type="checkbox" [checked]="modalData.config.grammar" />
                <div class="custom-checkbox"></div>
                <label class="form-check-label">
                  Grammar
                </label>
              </div>
              <div class="categories-options" [ngClass]="[modalData.config.spellings? 'selected' : !modalData.config.grammar && !modalData.config.spellings && !modalData.config.writing_advisor && !modalData.config.enhancement && modalData.config.style_guide === 'NONE' ? 'error-state' : '', modalData.config.langCode == 'es'? 'isActiveDisabled': ''] "
                (click)="modalData.config.spellings = !modalData.config.spellings">
                <input class="form-check-input options-checkbox" type="checkbox" [checked]="modalData.config.spellings" />
                <div class="custom-checkbox"></div>
                <label class="form-check-label">
                  Spellings
                </label>
              </div>
              <div class="categories-options" [ngClass]="[modalData.config.writing_advisor? 'selected' : !modalData.config.grammar && !modalData.config.spellings && !modalData.config.writing_advisor && !modalData.config.enhancement && modalData.config.style_guide === 'NONE' ? 'error-state' : '', modalData.config.langCode == 'es'? 'disabled': '' ]"
                (click)="modalData.config.writing_advisor = !modalData.config.writing_advisor">
                <input class="form-check-input options-checkbox" type="checkbox"
                  [checked]="modalData.config.writing_advisor" />
                <div class="custom-checkbox"></div>
                <label class="form-check-label">
                  Writing advisor
                </label>
                <div class="proofreadPreferenceToolTip">
                  <p>This feature is available only for English files.</p>
                </div>
              </div>
              <div class="categories-options" [ngClass]="[modalData.config.enhancement? 'selected' : !modalData.config.grammar && !modalData.config.spellings && !modalData.config.writing_advisor && !modalData.config.enhancement && modalData.config.style_guide === 'NONE' ? 'error-state' : '', modalData.config.langCode == 'es'? 'disabled': '']"
                (click)="modalData.config.enhancement = !modalData.config.enhancement">
                <input class="form-check-input options-checkbox" type="checkbox" [checked]="modalData.config.enhancement" />
                <div class="custom-checkbox"></div>
                <label class="form-check-label">
                  Enhancements
                </label>
                <div class="proofreadPreferenceToolTip">
                  <p>This feature is available only for English files.</p>
                </div>
              </div>

            </div>
          </div>

          <div class="autoFielStyleGuide autoEditPreferences" style="padding-bottom: 20px;">
            <label class="AELabelText">Style Guide</label>
            <div class="style-guide-container" [ngClass]="[!modalData.config.grammar && !modalData.config.spellings && !modalData.config.writing_advisor && !modalData.config.enhancement && modalData.config.style_guide === 'NONE' ? 'error-state' : '' , modalData.config.langCode == 'es'? 'disabled': '' ]">
              <mat-form-field appearance="fill">
                <mat-select [(value)]="modalData.config.style_guide">
                  <mat-option *ngFor="let style of styleGuide" [value]="style.value">
                    {{style.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>

          <div class="supplementaryCheckBox" [ngClass]="modalData.config.langCode == 'es'? 'disabled': ''">
            <div class="other-radio-options" (click)="modalData.config.statistics_in_document = !modalData.config.statistics_in_document">
              <input class="form-check-input options-checkbox" type="checkbox"
                [checked]="modalData.config.statistics_in_document" />
              <div class="custom-checkbox"></div>
              <label class="form-check-label">
                Add a supplementary page with the revision summary
              </label>
            </div>
            <div class="other-radio-options"
              (click)="modalData.config.writing_advisor_as_comments = !modalData.config.writing_advisor_as_comments">
              <input class="form-check-input options-checkbox" type="checkbox"
                [checked]="modalData.config.writing_advisor_as_comments" />
              <div class="custom-checkbox"></div>
              <label class="form-check-label">
                Show non-actionable suggestions as comments
              </label>
            </div>

          </div>
        </form>
      </div>
      <div class="fileSettingFooter">
        <div class="footerCredit">
          <p class="options-not-selected-alert">
            <span class="alert-icon" *ngIf="!modalData.config.grammar && !modalData.config.spellings && !modalData.config.writing_advisor && !modalData.config.enhancement && modalData.config.style_guide === 'NONE'"></span>
            <span class="options-not-selected-alert-txt" *ngIf="!modalData.config.grammar && !modalData.config.spellings && !modalData.config.writing_advisor && !modalData.config.enhancement && modalData.config.style_guide === 'NONE'">
              Select at least one Proofread preference or Style guide
            </span>
          </p>
          <div class="autoEditFooterButton">
            <button (click)="closeDialog(); logCancelEvent()" class="autoEditCancelButton">Cancel</button>
            <button type="submit" [disabled]="!modalData.config.grammar && !modalData.config.spellings && !modalData.config.writing_advisor && !modalData.config.enhancement && modalData.config.style_guide === 'NONE'" (click)="onSubmit()" class="autoEditApplyButton" cdkFocusInitial>Apply</button>
          </div>
        </div>

      </div>
    </div>
    <div class="AEF_editingModePopup" *ngIf="afeEditingModePopup">
      <div class="powerModeHoverMode" >
        <div class="powerModeHoverModeHeader">
          <h4>
            <button class="backButtonApplySetting" (click)="applySettingBackButton()">
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 5.99938C14 6.22215 13.9115 6.43579 13.754 6.59332C13.5965 6.75084 13.3828 6.83934 13.16 6.83934H2.87053L6.47676 10.4449C6.63455 10.6027 6.7232 10.8167 6.7232 11.0398C6.7232 11.263 6.63455 11.477 6.47676 11.6348C6.31896 11.7926 6.10494 11.8813 5.88178 11.8813C5.65863 11.8813 5.44461 11.7926 5.28681 11.6348L0.247051 6.59505C0.168744 6.51701 0.106612 6.42429 0.0642174 6.32219C0.0218229 6.22009 0 6.11063 0 6.00007C0 5.88952 0.0218229 5.78006 0.0642174 5.67796C0.106612 5.57586 0.168744 5.48314 0.247051 5.4051L5.28681 0.365342C5.36494 0.287209 5.4577 0.225231 5.55979 0.182945C5.66187 0.14066 5.77129 0.118896 5.88178 0.118896C5.99228 0.118896 6.1017 0.14066 6.20378 0.182945C6.30587 0.225231 6.39862 0.287209 6.47676 0.365342C6.55489 0.443475 6.61687 0.536232 6.65915 0.638317C6.70144 0.740403 6.7232 0.849817 6.7232 0.960314C6.7232 1.07081 6.70144 1.18022 6.65915 1.28231C6.61687 1.3844 6.55489 1.47715 6.47676 1.55529L2.87053 5.15942H13.16C13.3828 5.15942 13.5965 5.24791 13.754 5.40543C13.9115 5.56296 14 5.7766 14 5.99938Z" fill="#414E62"/>
              </svg>
            </button>
            Back to Settings / <span>Learn More</span>
          </h4>
        </div>
        <div class="knowMoreAboutPowerMode">
          <h5>Learn More About Editing Modes</h5>
          <div class="powerModeHoverModeBody">
            <h3>
              <svg width="9.62" height="15.63" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
              </svg>
              Power Mode
            </h3>
            <p><strong>About:</strong> Power Mode corrects your language as well as enhances it with advanced AI suggestions. It also paraphrases the text lightly in order to bring clarity and fluency to your writing.</p>
            <p><strong>When to use:</strong> Use Power Mode when you need both corrections and enhancements for your writing, especially if you aim to make your writing clearer, more fluent, and impactful.</p>
            <h3 style="margin-top: 24px;">
              <svg width="14" height="14.4" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Lite Mode
            </h3>
            <p><strong>About:</strong> Lite Mode focuses on correcting basic grammar, spelling, and punctuation mistakes. It helps keep your writing accurate.</p>
            <p><strong>When to use:</strong> Use Lite Mode when basic grammar corrections are sufficient for your needs or you just want to proofread the content</p>
          </div>
          <div class="powerModeHoverSliderSectoin">
            <h4>
              <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="11" height="1.375" rx="0.6875" fill="#444444"/>
                <rect y="3.66675" width="11" height="1.375" rx="0.6875" fill="#444444"/>
                <rect y="7.33325" width="11" height="1.375" rx="0.6875" fill="#444444"/>
                <rect y="11" width="5.5" height="1.375" rx="0.6875" fill="#444444"/>
                <rect x="7.3335" y="11" width="1.375" height="1.375" rx="0.6875" fill="#444444"/>
              </svg>
              View Examples
            </h4>
            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carouselExampleControls" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleControls" data-slide-to="1"></li>
                <li data-target="#carouselExampleControls" data-slide-to="2"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <!-- <h4>View Examples</h4> -->
                  <!-- <h5>“In recent years, air pollutions more and more serious with the development of technology and industry.”</h5> -->
                  <div class="powerModeShowReivision">
                    <label for="">Show Revisions</label>
                    <input type="checkbox" checked (click)="showRevivationActive_Slider_two()">
                  </div>
                  <div class="powerModeSlides">
                    <div class="powerModeCard" [ngClass]="{'showRevivisionTwoPowerMode': showRevivsionTwo}">
                      <h2>
                        <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
                        </svg>
                        Power Mode
                      </h2>
                      <p style="margin-bottom: 15px;">
                        In recent years, air
                         <span class="powerModeNormalWord">pollutions</span>
                         <span class="powerModeEditedWord" style="margin-right: 3px;">pollution</span>
                         <span class="powerModeNormalWord">are</span>
                         <span class="powerModeEditedWord" style="margin-right: 3px;">has become</span>
                         <span class="powerModeNormalWord">more and more</span>
                         <span class="powerModeEditedWord">increasingly</span>
                          serious
                          <span class="powerModeNormalWord">with</span>
                         <span class="powerModeEditedWord" style="margin-right: 3px;">because of</span>
                         <span class="powerModeNormalWord">the development</span>
                         <span class="powerModeEditedWord">advances in</span>
                          technology and industry.
                      </p>
                      <p class="powerModeNameOfSentence">Corrections and enhancements</p>
                    </div>
                    <div class="liteModeCard" [ngClass]="{'showRevivisionTwoLiteMode': showRevivsionTwo}">
                      <h2>
                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Lite Mode
                      </h2>
                      <p style="margin-bottom: 53px;">
                        In recent years, air
                         <span class="powerModeNormalWord">pollutions</span>
                         <span class="powerModeEditedWord">pollution</span>
                         <span class="powerModeNormalWord">are</span>
                         <span class="powerModeEditedWord" style="margin-right: 0px;">has become</span> more and more serious with the development of technology and industry.
                      </p>
                      <p class="powerModeNameOfSentence">Only corrections</p>
                    </div>
                  </div>
                  <div class="powerModeEditingMode">
                    <p> * Editing Modes are available only for English language.</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <!-- <h5>“Since 2006, corporate profits broke records, while unemployment rate in America has gone up significantly.”</h5> -->
                  <div class="powerModeShowReivision">
                    <label for="">Show Revisions</label>
                    <input type="checkbox" checked (click)="showRevivationActive_Slider_one()">
                  </div>
                  <div class="powerModeSlides">
                    <div class="powerModeCard" [ngClass]="{'showRevivisionOnePowerMode': showRevivsionOne}">
                      <h2>
                        <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
                        </svg>
                        Power Mode
                      </h2>
                      <p style="margin-bottom: 50px;">Since 2006, corporate profits
                        <span class="powerModeNormalWord">broke</span>
                        <span class="powerModeEditedWord">have broken</span> records,
                        <span class="powerModeNormalWord">while</span>
                        <span class="powerModeEditedWord">and</span>  unemployment
                        <span class="powerModeNormalWord" style="margin-right: 0;">rate</span> in
                        <span class="powerModeNormalWord">America</span>
                        <span class="powerModeEditedWord">the US</span> has
                        <span class="powerModeNormalWord">gone up</span>
                        <span class="powerModeEditedWord">increased</span> significantly.
                      </p>
                      <p class="powerModeNameOfSentence">Corrections and enhancements</p>
                    </div>
                    <div class="liteModeCard" [ngClass]="{'showRevivisionOneLiteMode': showRevivsionOne}">
                      <h2>
                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Lite Mode
                      </h2>
                      <p style="margin-bottom: 49px;">Since 2006, corporate profits
                        <span class="powerModeNormalWord">broke</span>
                        <span class="powerModeEditedWord" style="margin-right: 0;">have broken</span> records, while
                        <span class="powerModeEditedWord" style="margin-right: 0;">the</span> unemployment rate in America has
                        <span class="powerModeNormalWord">gone up</span>
                        <span class="powerModeEditedWord" style="margin-right: 0;">increased</span> significantly.
                      </p>
                      <p class="powerModeNameOfSentence">Only corrections</p>
                    </div>
                  </div>
                  <div class="powerModeEditingMode">
                    <p> * Editing Modes are available only for English language.</p>
                  </div>
                </div>
                <div class="carousel-item">
                    <!-- <h4>View Examples</h4> -->
                    <!-- <h5>“I wants to publish my paper in IEEE.”</h5> -->
                    <div class="powerModeShowReivision">
                      <label for="">Show Revisions</label>
                      <input type="checkbox" checked (click)="showRevivationActive_Slider_three()">
                    </div>
                    <div class="powerModeSlides">
                      <div class="powerModeCard" [ngClass]="{'showRevivisionThreePowerMode': showRevivsionThree}">
                        <h2>
                          <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
                          </svg>
                          Power Mode
                        </h2>
                        <p style="margin-bottom: 52px;">
                          Subadult and juvenile <span class="powerModeEditedWord" style="margin-right: 3px;">elephants</span> <span class="powerModeNormalWord">were found to consume</span> <span class="powerModeEditedWord">consumed</span> more plant species <span class="powerModeNormalWord">then</span> <span class="powerModeEditedWord">than</span> adult elephants.
                        </p>
                        <p class="powerModeNameOfSentence">Corrections and enhancements</p>
                      </div>
                      <div class="liteModeCard" [ngClass]="{'showRevivisionThreeLiteMode': showRevivsionThree}">
                        <h2>
                          <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          Lite Mode
                        </h2>
                        <p style="margin-bottom: 70px;">
                          Subadult and juvenile <span class="powerModeEditedWord" style="margin-right: 0;">elephants</span> were found to consume more plant species  <span class="powerModeNormalWord">then</span> <span style="margin-right: 0;" class="powerModeEditedWord">than</span> adult elephants.
                        </p>
                        <p class="powerModeNameOfSentence">Only corrections</p>
                      </div>
                    </div>
                    <div class="powerModeEditingMode">
                      <p> * Editing Modes are available only for English language.</p>
                    </div>
                  </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
