import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import {EncryptionDecryption} from 'src/app/utilities/encryptionModule';

@Injectable({
  providedIn: 'root'
})
export class LocaldbService {

  private db: any;
  encrypt: EncryptionDecryption;

  constructor() {
    this.initDatabase();
    this.encrypt = new EncryptionDecryption();
   

  }

  async initDatabase() {
    await this.createDatabase()
  }

  async createDatabase() {
    this.db = new Dexie('trinka');
    await this.db.version(2).stores({
      us: "s_i, t,o"
    })
    this.db.open()
  }

  insertRecord(sentence_id, file_id, txt) {
    var s_i = this.encrypt.encryptJSON(file_id)+'*'+this.encrypt.encryptJSON(sentence_id);
    var t = this.encrypt.encryptJSON(txt);
    this.db.us.put({
      s_i: s_i,
      t: t,
      o: 1
    }).then(function (res) { 
    }).catch(function (error) { 
    })
  }


  updateRecord(sentence_id, file_id, txt) {
    var s_i = this.encrypt.encryptJSON(file_id)+'*'+this.encrypt.encryptJSON(sentence_id);
    var t = this.encrypt.encryptJSON(txt);
    this.db.us.update(s_i,
      {
        "t": t
      }).then(function (data) {
      });
  }

  deleteRecord(sentence_id, file_id) {
    var s_i = sentence_id;
    this.db.us.update(s_i,
      {
        "o": 0
      }).then(function (data) {
      });
  }

  async deleteDatabase() {
    this.db.delete().then(function () {
    })
  }

  async getSentenceByText(text) {
    var t = this.encrypt.encryptJSON(text);
    var data = await this.db.us.where({ "t": t }).toArray().then((data) => { return data });
    return data;
  }

  async resetDb() {
    await this.db.us.where({ "o": 1 }).modify({ "o": 0 })
  }

  decodeStringResponse(encodedString) {
    return decodeURIComponent(atob(encodedString))
  }

  encodeStringResponse(encodedString) {
    return btoa(encodeURIComponent(encodedString))
  }
}
