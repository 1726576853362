import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent {
  feedbackText: string = '';
  ratingFeedback: number = 0;
  ratings: number[] = [1, 2, 3, 4, 5];
  emailId = "";
  feedbackForm!: FormGroup;
  feedbackSubmitted: boolean = false;
  submitInProgress: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<FeedbackModalComponent>,
    private _dashboardService: DashboardService,
    private _sharedService: SharedService,
    private _toast: ToastrService,
    private _amplitude : AmplitudeService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.emailId = this._sharedService.getLocalStorageMeta().sub;
    this.feedbackForm = new FormGroup({
      feedbackText: new FormControl(''),
    });
  }

  onCloseClick(): void {
    this._amplitude.logAmplitude('AFE_Feedback _cancel',{});
    this.dialogRef.close();
  }

  onSubmitClick(): void {
    // Add your logic for the Submit button here
    if (this.feedbackForm.valid) {
    this._amplitude.logAmplitude('AFE_Feedback_submit',{"rating": this.ratingFeedback, "remark": this.feedbackForm.get('feedbackText').value});
      this.submitInProgress = true;
      const payload = {
        "feedback_text": this.feedbackForm.get('feedbackText').value,
        "rating": this.ratingFeedback,
        "source": "Trinka - Proofread File",
        "email_id": this.emailId,
        "user_type": this.data.user_type
      }
      this._dashboardService.submitFeedbackCall(payload).subscribe({
        next: response => {
          if (response.success) {
            this.feedbackSubmitted = true;
            // this._toast.showSuccess("Feedback is submitted successfully!");
          } else {
            this.feedbackSubmitted = false;
            this._toast.error("Failed to submit your feedback!");
          }
          // this.dialogRef.close();
        },
        complete: () => {
          // this.dialogRef.close();
          this.submitInProgress = false;
        },
        error: err => {
          this.submitInProgress = false;
          this.feedbackSubmitted = false;
          this._toast.error("Failed to submit your feedback!");
        }
      })
    } else {
      this.validateAllFormFields(this.feedbackForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  checkIfValidComment() {
    let textValue = this.feedbackForm.get('feedbackText').value;
    return textValue.trim().length === 0 && textValue.trim().length !== textValue.length;
  }
}
