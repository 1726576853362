import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../_services/shared.service';

@Pipe({
  name: 'CCFilterByCategory'
})
export class CCFilterByCategoryPipe implements PipeTransform {
  catList = [];
  constructor( private sharedService: SharedService){

  }
  transform(items: any[], searchCategory: any): any[] {
    if (!items) return [];
      return items.filter(it => {return searchCategory.has(it.module)});
  }
}
