import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AutoEditComponent } from '../auto-edit.component';

@Component({
  selector: 'app-advance-settings',
  templateUrl: './advance-settings.component.html',
  styleUrls: ['./advance-settings.component.scss']
})
export class AdvanceSettingsComponent implements OnInit {

  fileDataObject: any = {
    writing_advisor_as_comments: true,
    statistics_in_document: true,
    enhancement: true,
    grammar: true,
    spellings: true,
    writing_advisor: true,
    style_guide: "NONE"

  };

  styleGuideDisplay = {
    "NONE": "NONE",
    "AMA": "AMA (11th ed)",
    "APA": "APA (7th ed)",
    "ACS": "ACS (2nd ed)",
    "AGU": "AGU (2017 ed)",
    "IEEE": "IEEE"
  }
  constructor(public sharedService: SharedService,
    private dialogRef: MatDialogRef<AutoEditComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.fileDataObject = data;
      dialogRef.disableClose = true;
    }
    

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(this.fileDataObject);
  }

}
