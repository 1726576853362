<div class="wordPluginOuterSection">
  <div class="WPTopHeader">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="WP_logo">
            <img src="../../../assets/images/Trinka_Logo.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="WP_bodyContentOuter">
    <div class="WPBodyContents">
      <h2 *ngIf="pathname == '/trinka-native-app'">Signing you in to Trinka Desktop App...</h2>
      <h2 *ngIf="pathname != '/trinka-native-app'">Signing you in to Trinka Word plugin...</h2>
      <p>Click “Open Trinka” on the dialog shown in the browser. If you don’t see a dialog, click “Open Trinka” below.  </p>
      <button (click)="clickForTrinka()">Open Trinka </button>
    </div>
    <div class="WP_logoInText">
      <span>Having trouble? Try writing on <a (click)="loginToTrinka()">Trinka Cloud</a></span>
    </div>
  </div>
</div>
