import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { VerifiedUserService } from 'src/app/_services/networkcalls/verified-user.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  is_confirm : boolean = false;
  invalid_otp : boolean = false;
  enable_mode = false;
  btnDisabled = false;
  emailId: string;
  otp: any;
  showOtpComponent = true;
  profileData: any;
  isLinkSend = false;
  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    inputStyles: {
      'width': '50px',
      'height': '50px'
      }
  };

  invalid_otp_config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: false,
    inputStyles: {
      'width': '50px',
      'height': '50px',
      'border-color' : '#f44336'
    }
  };

  isEmailVerificationModal :boolean = false;

  constructor(private networkCalls: DashboardService,
    private sharedService: SharedService,
    private toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialog,
    private verificationService: VerifiedUserService
    ) {
     }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.emailId = localMeta.sub
    this.profileData = this.data['profileData'];
    this.isEmailVerificationModal = this.profileData.is_otp_verification;
    if(!this.isEmailVerificationModal && this.profileData.trial_expire_on > -1){
      this.resendVerificationMailLink()
    }
  }



  onOtpChange(otp) {
    this.otp = otp;
    if(this.otp.length === 4){
      this.enable_mode = true;
    }
  }

  confirmEmail(){
    this.invalid_otp = false;

    this.networkCalls.userVerifyOtp(this.otp, this.emailId).subscribe(result=>{
      if(result['status']){
        this.is_confirm = true;
        this.verificationService.getSelectedProfileData();
      } else{
        this.invalid_otp = true;
        this.toaster.error("Please enter a valid verification code or click on Resend the verification code.");
      }
    });
  }

  btnText = 'Resend the verification code to my email address';
  resendVerificationCode(){
    this.ngOtpInput.setValue(null);
    let message =  'A new verification code has been sent to ' + '<i>'+this.emailId+'</i>';
    this.invalid_otp = false;
    this.btnDisabled = true;
    this.btnText = message;
    this.networkCalls.resendOtp(this.emailId).subscribe(result=>{
      this.otp = '';
      if(result['status']){
        this.btnText = message;
      }
    });

    setTimeout(() => {
      this.btnDisabled = false;
      this.btnText = 'Resend the verification code to my email address';
      }, 5000);

    }
    
  signOut() {
    this.sharedService.SignOut();
  }

  resendVerificationMailLink(){
    this.networkCalls.resendVerifyMail(this.emailId).subscribe(resp=>{
      this.isLinkSend = true;
      
      setTimeout(() => {
        this.isLinkSend = false
        }, 5000);
    })
  }
}
