import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-document-and-style-modal',
  templateUrl: './document-and-style-modal.component.html',
  styleUrls: ['./document-and-style-modal.component.scss']
})
export class DocumentAndStyleModalComponent implements OnInit {
  selection: any = {
    document_type: null,
    styleGuide: null,
    documentTypeList: [],
    styleGuideList: [],
    styleGuideLegalList: []
  };
  documentTypeList = ["Select", "General", "Academic", "Legal"];
  loader: boolean = false;

  constructor(public dialogRef: MatDialogRef<DocumentAndStyleModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public amplitudeService: AmplitudeService) {
    this.selection.document_type = data.document_type;
    this.selection.styleGuide = data.styleGuide;
    this.selection.documentTypeList = data.documentTypeList;
    this.selection.styleGuideList = data.styleGuideList;
    this.selection.styleGuideLegalList = data.styleGuideLegalList;
  }

  returnZero() {
    return 0;
  }

  ngOnInit() {
    this.amplitudeService.logAmplitude('Document_Style', {});
  }

  saveModal() {
    this.loader = true;
    this.amplitudeService.logAmplitude('Document_Style_Save', {
      'document_type': this.documentTypeList[this.selection.document_type - 1],
      'style_guide': this.selection.styleGuide,
      'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
    });
    setTimeout(() => {
      this.loader = false;
      this.closeModal(this.selection);
    }, 2000);
  }

  closeModal(data: any) {
    this.dialogRef.close(data);
  }
}
