<div class="savedCardsModal" *ngIf="!isConfirmationModal">
    <div class="closedButton" mat-button mat-dialog-close>
		<img src="../../../assets/images/closeIcons.png" alt="">
	</div>
    <div class="savedCardNotPresent" *ngIf="savedCardList == undefined || savedCardList.length == 0">
        No saved card found.
    </div>

    <div class="savedCardOuterContainer" *ngIf="savedCardList != undefined && savedCardList.length > 0">
        <h3>Your Saved Cards</h3>

        <div class="savedCardContent" *ngFor="let savedCard of savedCardList; let i = index;">
            <div class="savedCardLeftside">
                <p *ngIf="isSavedCardExpired(savedCard)">Card ending with {{savedCard.last4}} <span class="savedCardExpired">Expired: {{formatExpiryDate(savedCard)}}</span></p>
                <p *ngIf="!isSavedCardExpired(savedCard)">Card ending with {{savedCard.last4}} <span>Expiry: {{formatExpiryDate(savedCard)}}</span></p>
                <span>
                    {{savedCard.name}}
                </span>
            </div> 
            <div class="savedCardRightside">
                <a (click)="takeConfirmation(savedCard, i)">Delete</a>
            </div> 
        </div>

    </div>

</div>

<div class="savedCardsConfirmationModal" *ngIf="isConfirmationModal">
    <div class="closedButton" mat-button mat-dialog-close>
		<img src="../../../assets/images/closeIcons.png" alt="">
	</div>
    <div class="confirmationText">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><title>2</title><g id="Layer_7" data-name="Layer 7"><path d="M50.5,13.5a26.15,26.15,0,1,0,0,37A26.19,26.19,0,0,0,50.5,13.5ZM47.67,47.67a22.16,22.16,0,1,1,0-31.35A22.19,22.19,0,0,1,47.67,47.67Z"/><path d="M41.06,22.94a2,2,0,0,0-2.83,0L32,29.17l-6.23-6.23a2,2,0,0,0-2.83,2.83L29.17,32l-6.23,6.23a2,2,0,1,0,2.83,2.83L32,34.83l6.23,6.23a2,2,0,0,0,2.83-2.83L34.83,32l6.23-6.23A2,2,0,0,0,41.06,22.94Z"/></g></svg>
        <h3>Are you sure?</h3>
        <p>Do you really want to delete these records?</p>
    </div>

    <div class="popupFooterContent">
        <button class="noButtonConfirm" (click)="confirmDelete(false)">Cancel</button>
        <button class="yesButtonContfirm" (click)="confirmDelete(true)">Delete</button>
    </div>
</div>
