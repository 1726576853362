import { ApplicationRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PaymentDetails } from 'src/app/_interfaces/dashboard/payment-details';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { AutoEdit } from 'src/app/_enums/auto-edit.enum';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { environment } from 'src/environments/environment';
import { PlagCheckSampleReportPopupComponent } from '../plag-check-sample-report-popup/plag-check-sample-report-popup.component';
import { MatDialog } from '@angular/material';
import { PlagBuyCreditsModalComponent } from '../plag-buy-credits-modal/plag-buy-credits-modal.component';
import { PlagCheckBasicWordLimitModalComponent } from 'src/app/components/plagiarism-check/plag-check-basic-word-limit-modal/plag-check-basic-word-limit-modal.component';

@Component({
  selector: 'app-plagcheck-options',
  templateUrl: './plagcheck-options.component.html',
  styleUrls: ['./plagcheck-options.component.scss']
})
export class PlagcheckOptionsComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private networkCalls: DashboardService,
    private sharedService: SharedService,
    private amplitudeService: AmplitudeService,
    public dialog: MatDialog,
    private appRef: ApplicationRef,
  ) { }


  @Input() fileName: string;
  @Input() files_submitted: any;
  @Input() cloudDriveFileDetails: any;
  @Input() isFileFromEditor: any;

  @Output() reportStatus = new EventEmitter<any>();
  @Output() wordLimitReachedAction = new EventEmitter<any>();

  option_selected = false;
  option_title = '';
  win = window['dataLayer'] || {}
  element: HTMLElement;
  userId: any;
  userData: any;
  paymentDetails: PaymentDetails;
  creditsDetails: any;

  selectedPlagCheckName: string;
  plagiarismFilesList: any = [];
  plagiarism_check_list: any = [];
  isFileSelected = null;

  selectedFiles: FileList;
  plagiarismFile = null;
  selected_type: number;
  freeStatus: boolean = false;
  filterModel = "";
  user_decoded_id: any;
  dataSource: Object;
  report_data: any = [];
  free_score_reset_date: any;
  remainig_free_score: number;
  total_free_score: number;
  credit_required: number;
  has_suff_credits: boolean = true;
  plagiarism_free_score: boolean = false;


  optionList: any = [{
    name: "Overall score",
    score: true,
    standard: true,
    advance: true
  },
  {
    name: "Matches from internet sources",
    score: true,
    standard: true,
    advance: true
  },
  {
    name: "Matches from paid publications",
    score: true,
    standard: false,
    advance: true
  },{
    name: "Matches from open access journals",
    score: true,
    standard: true,
    advance: true
  }, {
    name: "List of top matched sources",
    score: false,
    standard: true,
    advance: true
  }, {
    name: "Detailed downloadable report",
    score: false,
    standard: true,
    advance: true
  }];

  CREDIT_CALCULATION_WORD_COUNT_BRACKET: number = 25000;
  STANDARD_CREDIT: number = 12;
  ADVANCE_CREDIT: number = 18;

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    let data = {
      "user_id": this.userId
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.getCreditsDetails(true);
    this.amplitudeService.logAmplitude('Plag_file_uploaded_pageview', {
      'File Type': this.isFileFromEditor ? 'EDITOR' : this.cloudDriveFileDetails.source,
      'document_type': this.fileName.split(".").pop(),
    });
  }


  

  openSampleReportModal(isIthenticate) {
    this.dialog.open(PlagCheckSampleReportPopupComponent, { data: { "isIthenticate": isIthenticate } });
  }

  addCredits() {

    let redirct_url = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url + '/user/credit/' + this.userData, "_self");
  }

  generateReport(selected_type : number) {
    let credits_required = 1;
    if(selected_type == 1){
      credits_required = 1;
    }if(selected_type == 2){
      credits_required = Math.ceil(this.cloudDriveFileDetails.word_count / this.CREDIT_CALCULATION_WORD_COUNT_BRACKET) * this.ADVANCE_CREDIT;
    }else{
      credits_required = Math.ceil(this.cloudDriveFileDetails.word_count / this.CREDIT_CALCULATION_WORD_COUNT_BRACKET) * this.STANDARD_CREDIT;
    }
    
    this.spinner.show();
    this.selected_type = selected_type;
    if (this.fileName == null || this.fileName == "") {
      this.toastr.error('Please upload file with doc, docx, pdf format.', "Error");
      return;
    }

    if (!(this.fileName.endsWith("doc") || this.fileName.endsWith("docx") || this.fileName.endsWith("pdf"))) {
      this.toastr.error('Please upload file with doc, docx, pdf format.', "Error");
      return;
    }

    if (this.selected_type == null) {
      this.toastr.error("Please select plagiarism check");
      return;
    }

    if (this.selected_type === 1 && this.creditsDetails.user_type === "BASIC" && this.cloudDriveFileDetails.word_count > 500) {
      this.spinner.hide();
      let dialogRef = this.dialog.open(PlagCheckBasicWordLimitModalComponent, {
        disableClose: true,
        panelClass: 'deleteModalSectionPlagCheck',
      });
      dialogRef.afterClosed().subscribe(result => {
        this.wordLimitReachedAction.emit(result.data);
        dialogRef = null;
      });
      
      return;
    }

    if (this.selected_type == 1 && this.creditsDetails.user_type != "PREMIUM" && this.creditsDetails["plagiarism_free_score"] <= 0) {
      this.toastr.error("Fair Use of limit exceed");
      return;
    }

    if (this.selected_type == 1 && this.creditsDetails.user_type == "PREMIUM" && this.creditsDetails["plagiarism_free_score"] <= 0) {
      this.toastr.error("Fair Use of limit exceed");
      return;
    }
    if (this.creditsDetails.user_type !== "PREMIUM_PLUS" && this.selected_type != 1 && credits_required > (this.creditsDetails.credits + this.creditsDetails.purchased_credits)) {
      this.spinner.hide();
      this.dialog.open(PlagBuyCreditsModalComponent,{ width: "500px",data: {
        required_credits_purchase :  credits_required - (this.creditsDetails.credits + this.creditsDetails.purchased_credits),
          plag_type : this.selected_type
      }});
      
      return;
    }
    if (this.creditsDetails.user_type === "PREMIUM_PLUS" && this.selected_type === 3 && this.creditsDetails.plagiarism_free_page_count_scan < this.getPageCount(this.cloudDriveFileDetails.word_count) && credits_required > (this.creditsDetails.credits + this.creditsDetails.purchased_credits)) {
      this.spinner.hide();
      this.dialog.open(PlagBuyCreditsModalComponent,{ width: "500px",data: {
        required_credits_purchase :  credits_required - (this.creditsDetails.credits + this.creditsDetails.purchased_credits),
          plag_type : this.selected_type
      }});
      
      return;
    }
    if (this.creditsDetails.user_type === "PREMIUM_PLUS" && this.selected_type === 2 && credits_required > (this.creditsDetails.credits + this.creditsDetails.purchased_credits)) {
      this.spinner.hide();
      this.dialog.open(PlagBuyCreditsModalComponent,{ width: "500px",data: {
        required_credits_purchase :  credits_required - (this.creditsDetails.credits + this.creditsDetails.purchased_credits),
          plag_type : this.selected_type
      }});
      
      return;
    }
    if (this.cloudDriveFileDetails.source == "LOCAL_SYSTEM") {
      this.uploadLocalFileForPlagCheck();
    }
    else {
      this.uploadCloudFileForPlagCheck();
    }
    
    if(this.selected_type == 1){
      this.amplitudeService.logAmplitude('Plag_generated_freescore', {
        'Location 1': 'plagiarism_file_upload_pageview',
      });
    }
    if(this.selected_type == 2){
      this.amplitudeService.logAmplitude('Plag_generated_advancedcheck', {
        'Location 1': 'plagiarism_file_upload_pageview'
      });
    }
    if (this.selected_type == 3) {
      let credits = {};
      let scans = {}
      if (this.creditsDetails.plagiarism_free_page_count_scan > this.getPageCount(this.cloudDriveFileDetails.word_count)) {
        scans = {
          'scans_available': this.creditsDetails.plagiarism_free_page_count_scan,
          'scans_utilised':  this.getPageCount(this.cloudDriveFileDetails.word_count)
        }
      }
      else {
        credits =  {
            'credit_balance_free': this.creditsDetails.credits,
            'credit_balance_purchased':  this.creditsDetails.purchased_credits
        }
      }
      this.amplitudeService.logAmplitude('Plag_generated_standardcheck', {
        'Location 1': 'plagiarism_file_upload_pageview',
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits,
        'scans_available': this.creditsDetails.plagiarism_free_page_count_scan,
        'scans_used':  this.getPageCount(this.cloudDriveFileDetails.word_count)
      });
    }
      
  }

  arrayChunk(list: any[], chunkSize: number) {
    var R = [];
    for (var i = 0; i < list.length; i += chunkSize)
      R.push(list.slice(i, i + chunkSize));
    return R;
  }




  getCreditsDetails(isOnInit: boolean = false) {
    this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
      if (response["status"]) {
        this.creditsDetails = response["data"];
        this.optionList[2].score = this.creditsDetails.user_type !== 'BASIC';
        this.networkCalls.storeUserCreditDetails(this.creditsDetails);
        this.user_decoded_id = this.creditsDetails["user_id"];
        this.plagiarism_free_score = this.creditsDetails["plagiarism_free_score"] == 0 ? true : false;
        this.freeStatus = this.creditsDetails["plagiarism_free_score"] > 0 ? true : false;
        this.free_score_reset_date = this.creditsDetails["next_renewal_date"];
        this.total_free_score = this.creditsDetails["user_type"] == "BASIC" ? 1 : 2;
        this.remainig_free_score = this.creditsDetails["plagiarism_free_score"] ? this.creditsDetails["plagiarism_free_score"] : 0;
        this.appRef.tick();
      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  uploadLocalFileForPlagCheck(initialIndex = 0, flag = false) {
    var file_length = 0;
    try {
      var chunkArray = this.arrayChunk(this.files_submitted, 5);
      let index = 0;
      for (index = 0; index < chunkArray[initialIndex].length; index++) {
        const element = chunkArray[initialIndex][index];
        this.cloudDriveFileDetails.userId = this.userId;
        var fileObject = new FormData();
        file_length = initialIndex;
        // fileObject.append("file", element);
        fileObject.append("plagiarism_type", this.selected_type.toString());
        fileObject.append("user_id", this.userId);
        fileObject.append("d_user_id", this.user_decoded_id);
        fileObject.append('cloudFileDetails', JSON.stringify(this.cloudDriveFileDetails))
        this.spinner.show();
        this.networkCalls.uploadPlagiarismFile(fileObject).subscribe(res => {
          this.spinner.hide();
          if (initialIndex == chunkArray.length - 1) {
            this.toastr.success("Plagiarism check has begun. The report will appear in the Generated Reports section.");

          }
          if (res['status']) {
            //this.plagiarismFileControl.nativeElement.value = null;
            this.plagiarismFile = null;
            this.fileName = null;
            this.reportStatus.emit(res);
            // this.plagiarism_check_list[this.selected_type-1].checked = false;
            //this.getPlagiarismFileList();

          }
        }, error => {
          this.spinner.hide();
          this.plagiarismFile = null;
          this.fileName = null;
          file_length = initialIndex;
          
            this.sharedService.errorHandller(error);
         
        });

      }
      if (index != chunkArray[initialIndex].length - 1) {
        setTimeout(() => {
          this.uploadLocalFileForPlagCheck(initialIndex + 1, true);
          // this.getPlagiarismFileList();
        }, 2000);
      }
    }
    catch (error) {
      // this.isFileSelected = false;
    }
    // } else {
    //   this.toastr.error('Please upload file with doc, docx format.',"Error");
    // }
  }
  uploadCloudFileForPlagCheck() {
    try {
      this.cloudDriveFileDetails.userId = this.userId;
      var fileObject = new FormData();
      fileObject.append("plagiarism_type", this.selected_type.toString());
      fileObject.append("user_id", this.userId);
      fileObject.append("d_user_id", this.user_decoded_id);
      fileObject.append('cloudFileDetails', JSON.stringify(this.cloudDriveFileDetails))

      this.spinner.show();
      this.networkCalls.uploadPlagiarismFile(fileObject).subscribe(res => {
        this.spinner.hide();
        // this.amplitudeService.logAmplitude('tool_check_submit',{
        //   'selected_check': this.plagiarism_check_list[this.selected_type-1].type,
        //   'credits_required': this.plagiarism_check_list[this.selected_type-1].credit
        //   });
        if (res['status']) {
          this.toastr.success("Plagiarism check has begun.It will appear in the Generated Reports section.");
          //this.plagiarismFileControl.nativeElement.value = null;
          this.plagiarismFile = null;
          this.fileName = null;
          this.reportStatus.emit(res);
          // this.plagiarism_check_list[this.selected_type-1].checked = false;
          // this.getPlagiarismFileList();
        }
      }, error => {
        this.spinner.hide();
        //this.plagiarismFileControl.nativeElement.value = null;
        this.plagiarismFile = null;
        this.fileName = null;
        //this.plagiarism_check_list[this.selected_type-1].checked = false;
        this.sharedService.errorHandller(error);
        //this.getPlagiarismFileList();
      });
    }
    catch (error) {
      this.spinner.hide();
      this.isFileSelected = false;
    }
  }

  getPageCount(noOfWords: number) {
    const perPageCount = 250;
    if (noOfWords) return Math.ceil(noOfWords / perPageCount) 
    else return 0;
  }

  getStandardPlagCheckCreditCount(noOfWords: number){
    if (noOfWords){ 
      let credits = Math.ceil(noOfWords / this.CREDIT_CALCULATION_WORD_COUNT_BRACKET) * this.STANDARD_CREDIT;
      return  credits > 1 ? `${credits} Credits` : `${credits} Credit`;
    }
    else {
      return `0 Credit`;
    }
  }

  getAdvanceCreditCount(noOfWords: number){
    if (noOfWords){ 
      let credits = Math.ceil(noOfWords / this.CREDIT_CALCULATION_WORD_COUNT_BRACKET) * this.ADVANCE_CREDIT;
      return  credits > 1 ? `${credits} Credits` : `${credits} Credit`;
    }
    else {
      return `0 Credit`;
    }
  }

  getPageOrPagesTxt(noOfWords: number) {
    const perPageCount = 250;
    if (Math.ceil(noOfWords / perPageCount) > 1) return ' Pages' 
    else return ' Page';
  }
}
