import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Subject } from 'rxjs/Rx';

@Injectable()
export class CKEditorScriptLoader implements CanActivate {
  constructor() {}

  loadScript(scriptUrl: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (scriptUrl) {
        const existingScript = document.querySelector(`script[src="${scriptUrl}"]`);
        if (!existingScript) {
          const script = document.createElement('script');
          script.src = scriptUrl;
          script.async = true;
          script.onload = () => {
            resolve();
          };
          script.onerror = (error) => reject(error);
          document.head.appendChild(script);
        } else {
          resolve();
        }
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let subject = new Subject<boolean>();

    if(state.url.includes('/main/')) {
      this.loadScript('//cdn.ckeditor.com/4.7.1/full/ckeditor.js').then(() => {
        subject.next(true);
        subject.complete();
      });
    } else if(state.url.includes('/main-v1/')) {
      this.loadScript('//cdn.ckeditor.com/ckeditor5/39.0.1/super-build/ckeditor.js').then(() => {
        subject.next(true);
        subject.complete();
      });
    }

    return subject;
  }
}
