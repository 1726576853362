import { Component, OnInit, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import * as $ from 'jquery';
import { MatDialogRef } from '@angular/material';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimeoutError } from 'rxjs';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

declare const a2a: any;
@Component({
  selector: 'app-share-widget',
  templateUrl: './share-widget.component.html',
  styleUrls: ['./share-widget.component.scss']
})
export class ShareWidgetComponent implements OnInit {
  invited_emailid = '';
  error_message = "";
  profile_data :any;
  refer_code = "";
  refer_error = true;
  linkCopied = false;
  btnLinkCopy =true;
  refer_email_id = "";
  localMeta:any;
  loading_link = false;
  sendEmailSuccessfully = false;
  @ViewChild('btnCopyToClipBoard',{static:false}) btnCopyToClipBoard:any
  @ViewChild('errorMessage', {static:false}) errorMessage:any
  @ViewChild('add2Any', {static:false}) add2Any:any
  country:string = "";
  userId:string = "";
  creditsDetails: any=[];

  constructor(
    public dialogRef: MatDialogRef<ShareWidgetComponent>,
    private networkCalls: DashboardService,
    private sharedService: SharedService,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    private spinner: NgxSpinnerService,
    public amplitudeService: AmplitudeService
  ) {
    this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(profileData=>{
      this.profile_data = profileData;

    });
    this.localMeta = this.sharedService.getLocalStorageMeta();
    this.refer_email_id = this.localMeta.sub;
    this.userId = btoa(this.localMeta.sub);
    this.loading_link = true;
    this.country = localStorage.getItem("country-trinka");
    if(this.country != 'IN'){
    this.networkCalls.getReferalCode(btoa(this.refer_email_id)).subscribe(response=>{
        this.refer_code = response.data.referal_code;
        this.loading_link =false;
        this.add2Any.nativeElement.removeAttribute('data-a2a-url');
        this.add2Any.nativeElement.setAttribute('data-a2a-url', environment.refer_link);
        this.add2Any.nativeElement.setAttribute('data-a2a-title', "Hi there , I am excited to share my Trinka Referral Coupon "+this.refer_code+ ".  Use this discount coupon to avail 10% instant discount on purchase of any subscription plan on Trinka. To avail the benefit, use the below link to sign in/ sign up on Trinka :  ");
      });
    }
    this.getCreditsDetails();
   }

  ngOnInit() {
    a2a.init();
  }

  getCreditsDetails() {

    this.networkCalls.getStoreUserCreditDetails().subscribe(resp => {
      if(Object.keys(resp).length == 0)
      {
        this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(
          (response) => {
            if (response["status"]) {
              this.creditsDetails = response.data;
              this.networkCalls.storeUserCreditDetails(this.creditsDetails);
            }
          },
          (error) => {
            this.sharedService.errorHandller(error);
          }
        );
      }
      else{
        this.creditsDetails = resp;
      }
    });
  }

  close(){
    this.dialogRef.close();
  }

  sendReferEmail(){
    if(this.country=='IN'){
      this.error_message = "Trinka Referral Program Coming Soon";
      return;
    }
    this.error_message = ""
    this.refer_error = true
    this.spinner.show()

    var splitted_emailid = this.invited_emailid.split(",")
    var wrong_emailid = []
    var emailId_list = []
    let email_validate_regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
    for(var i =0 ; i < splitted_emailid.length; i++ ){
      let email_id = splitted_emailid[i].toLowerCase().trim();
      var validated = email_validate_regex.test(email_id)
      if(validated == false){
        wrong_emailid.push(email_id)
      } else {
        emailId_list.push(email_id)
      }

    }

    if(wrong_emailid.length >0){
      this.error_message = "Please check the email address(es)."
      this.spinner.hide()
      return
    }

    if(emailId_list.includes(this.localMeta.sub,0)){
      this.error_message = "Please remove your email id."
      this.spinner.hide()
      return
    }


    let referrer_name = this.profile_data.firstname+" "+this.profile_data.lastname;
    let payload = {
       referrer_email_id: this.refer_email_id,
       referrer_first_name: this.profile_data.firstname,
       referrer_last_name: this.profile_data.lastname,
       invited_email_ids: emailId_list
    }
    this.networkCalls.sendReferEmailid(payload).subscribe(result=>{
        // var data = splitted_emailid
        this.error_message="";
        if(result.status){
          this.refer_error = false
          this.invited_emailid = "";
          this.sendEmailSuccessfully = true;
          this.error_message = ""
          this.amplitudeService.logAmplitude('refer_made',{
            'User ID' : this.userId,
            'Subscription Type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
            'platform':'trinka',
            'via': 'email'
          });
        }
        else {
          this.refer_error = true
          this.error_message = result.message
        }
        this.spinner.hide()
      },error=>{
        this.error_message = error.error.message
        this.spinner.hide()
      });
  }
  backToEmail(){
    this.sendEmailSuccessfully = false;
  }

  copyToClipBoard(){
    this.linkCopied = true;
    this.btnLinkCopy = false;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.refer_code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {
      this.linkCopied = false;
      this.btnLinkCopy = true;
    }, 2100);

    this.amplitudeService.logAmplitude('Copy_referral_code',{
      'User ID' : this.userId,
      'Subscription Type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
    });
  }

  openSampleMail(){
    this.amplitudeService.logAmplitude('Show_sample_referral_email',{
      'User ID' : this.userId,
      'Subscription Type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
    });
    window.open("assets/images/refer_mail_sample.png","_blank");

  }

  shareClicked(event: MouseEvent) {
    var social_media = event.target as HTMLElement;
    this.amplitudeService.logAmplitude('refer_made',{
      'User ID' : this.userId,
      'Subscription Type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'platform':'trinka',
      'via': social_media.innerText
    });
  }

}
