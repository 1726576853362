import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class ParaphraserService {
  userSelectionSentencesChange = new BehaviorSubject<any>(null);
  paraphraseFloatingOptionClick = new BehaviorSubject<boolean>(false);
  paraphraseContentAddedInEditor = new BehaviorSubject<boolean>(false);

  constructor() {}

  userSelectionSentencesChangeEvent(userSelection: any, list: any, fromFloatingOption: boolean = false) {
    this.userSelectionSentencesChange.next({ userSelection, list, fromFloatingOption });
  }

  paraphraseFloatingOptionClickEvent() {
    this.paraphraseFloatingOptionClick.next(true);
  }

  paraphraseContentAddedInEditorEvent() {
    this.paraphraseContentAddedInEditor.next(true);
  }
}
