<div class="publicationSubSection">
    <mat-accordion>
        <ng-container *ngFor="let object of ethicalArrayList; let i = index">
            <mat-expansion-panel class="ethical_compliance_section" (opened)="panelOpenState = true"
                (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <div class="ethical_card_header">
                        <p>
                            <span class="no_checks">{{object.checkNo}}</span> {{object.checkName}}
                            <i class="authorship_alternet_text">
                                <b>{{object.tooltip}}</b>
                                <img src="../../../../assets/images/Icon_Info.svg" alt="">
                            </i>
                        </p>

                        <div class="ehtical_compliance_header ehtical_compliance_presnet_text" *ngIf="object.flag == true && object.required_to_show == true">
                            <p> <strong> Present</strong></p>
                        </div>
                        <div class="ehtical_compliance_header ehtical_compliance_not_required" *ngIf="object.required_to_show == false">
                            <p> <strong> Not Required</strong></p>
                        </div>
                        <div class="ehtical_compliance_header ehtical_compliance_Not_present" *ngIf="object.flag == false && object.required_to_show == true" >
                            <p> <strong> Not Present</strong></p>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="clinical_compliance_body" *ngIf="object.flag == true && object.required_to_show == true">
                    <div>{{ethicalArrayList?.statement}}</div>
                    <p style="line-height: 17px;font-size: 13px;margin-top: 10px;color: rgba(77,77,77,.8);" *ngIf="object.id == 1">Your manuscript contains {{object.checkList.length}} statement(s) declaring informed consent.</p>
                    <p style="line-height: 17px;font-size: 13px;margin-top: 10px;color: rgba(77,77,77,.8);" *ngIf="object.id == 2">Your manuscript contains {{object.checkList.length}} statement(s) on ethical standards/approval declaration.</p>
                    <p style="line-height: 17px;font-size: 13px;margin-top: 10px;color: rgba(77,77,77,.8);" *ngIf="object.id == 3">Your manuscript contains {{object.checkList.length}} statement(s) describing financial disclosure.</p>
                    <p style="line-height: 17px;font-size: 13px;margin-top: 10px;color: rgba(77,77,77,.8);" *ngIf="object.id == 4">Your manuscript contains {{object.checkList.length}} statement(s) on conflict of interest declaration.</p>
                    <p style="line-height: 17px;font-size: 13px;margin-top: 10px;color: rgba(77,77,77,.8);" *ngIf="object.id == 5">Your manuscript contains {{object.checkList.length}} statement(s) on data availability.</p>
                    <ul>
                        <li  *ngFor="let checkList of object.checkList;">
                            {{checkList}}
                        </li>
                    </ul>
                </div>
                <div class="clinical_compliance_body" *ngIf="object.flag == false && object.required_to_show != false && object.id ==1">
                    <p style="font-size: 13px;margin-top: 10px;color: rgba(77,77,77,.8);line-height: 17px">Your manuscript does not have the necessary statement(s) on informed consent.</p>
                    <p style="font-size: 13px;color: rgba(77,77,77,.8);line-height: 17px">If your work involves human beings, you should include a statement confirming that informed consent was obtained from all participants or whether the requirement of informed consent was waived by the institutional review board.</p>
                    <p style="font-size: 13px;color: rgba(77,77,77,.8);line-height: 17px">Examples of such statements include the following:</p>
                    <ul>
                        <li>Written informed consent was obtained from all patients before they were entered into the study.</li>
                        <li>Informed consent was waived by the institutional review board.</li>
                    </ul>
                </div>
                <div class="clinical_compliance_body" *ngIf="object.flag == false && object.required_to_show != false && object.id ==2">
                    <p style="font-size: 13px;margin-top: 10px;color: rgba(77,77,77,.8);line-height: 17px">Your manuscript does not have the necessary statement(s) on ethical approval.</p>
                    <p style="font-size: 13px;color: rgba(77,77,77,.8);line-height: 17px">
                        If your work involves animals, you should include a statement that you had followed all applicable international, national, and/or institutional guidelines for the care and use of animals. If your work involves human participants, you should include a statement that all procedures involving human participants were in accordance with the ethical standards of the institutional and/or national research committee. 
                        If your work does not involve animals or humans, you do not have to include such a statement.</p>
                </div>
                <div class="clinical_compliance_body" *ngIf="object.flag == false && object.required_to_show != false && object.id ==3">
                    <p style="font-size: 13px;margin-top: 10px;color: rgba(77,77,77,.8);line-height: 17px">Your manuscript does not have the necessary statement(s) on financial disclosure.</p>
                    <p style="font-size: 13px;color: rgba(77,77,77,.8);line-height: 17px">
                        Please provide all sources of funding. You may also declare that the study did not use any sources of funding.
                    </p>
                    <p style="font-size: 13px;color: rgba(77,77,77,.8);line-height: 17px">Examples of such statements include the following:</p>
                    <ul>
                        <li>This article received no sources of funding.</li>
                        <li>The study was supported by grant XXXXX from XXXX.</li>
                        <li>Author XXX was supported by grant ХХХХ from Ministry ХХХХ.</li>
                    </ul>
                </div>
                <div class="clinical_compliance_body" *ngIf="object.flag == false && object.required_to_show != false && object.id == 4">
                    <p style="font-size: 13px;margin-top: 10px;color: rgba(77,77,77,.8);line-height: 17px;">Your manuscript does not have the necessary statement(s) on conflict of interest.</p>
                    <p style="font-size: 13px;color: rgba(77,77,77,.8);line-height: 17px;">
                        A conflict of interest is any relationship that may directly or indirectly influence or bias the work. You must state all 
                        conflicts of interest or declare that there are no conflicts of interest.
                    </p>
                    <p style="font-size: 13px;color: rgba(77,77,77,.8);line-height: 17px;">Examples of such statements include the following:</p>
                    <ul>
                        <li>The authors declare that they have no conflicts of interest.</li>
                        <li>Conflict of interest: The author had a consultancy agreement with XXX.</li>
                        <li>Conflict of interest: The author is a stockholder in XXX.</li>
                    </ul>
                </div>
                <div class="clinical_compliance_body" *ngIf="object.flag == false && object.required_to_show != false && object.id ==5">
                    <p style="font-size: 13px;margin-top: 10px;color: rgba(77,77,77,.8);line-height: 17px;">Your manuscript does not have the necessary statement(s) on data availability.</p>
                    <p style="font-size: 13px;color: rgba(77,77,77,.8);line-height: 17px;">
                        Many journals now require the authors to include a 'Data availability' section in their manuscript. This section should provide 
                        information on where the readers can obtain the data required to reproduce the reported results.
                    </p>
                    <p style="font-size: 13px;color: rgba(77,77,77,.8);line-height: 17px;">Examples of such statements include the following:</p>
                    <ul>
                        <li>The data required to reproduce these findings are available to download from www.xxx.xxx.</li>
                        <li>The datasets generated during and/or analysed during the current study are available in the XXX repository.</li>
                        <li>All data generated or analysed during this study are included in this published article (and its supplementary information files).</li>
                    </ul>
                </div>

                <div class="clinical_compliance_body" *ngIf="object.required_to_show == false">
                    <div class="not_required_document" *ngIf="object.id !=2 && object.id !=1">
                        {{object.displayName}} is not required for your manuscript.
                    </div>
                    <div class="not_required_document" *ngIf="object.id ==1">
                        Informed consent is only required when the research involves human subjects.
                    </div>
                    <div class="not_required_document" *ngIf="object.id ==2">
                        Ethical approval statements are only required when the research involves human or animal subjects.
                    </div>
                </div>
                
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</div>