<div class="publicationSubSection">
<mat-card class="keywordSummary_sections">
    <ng-container>
        <div class="keyword_summary_checks">
            <p>
                <span>Check 1</span> Keywords
                <i class="keyword_info_section" alt="Shows the keywords detected in the manuscript as well as suggests keywords based on concepts detected in the manuscript">
                    <img src="../../../../assets/images/Icon_Info.svg" alt="">
                </i>
            </p>
        </div>
        <div class="keywordSummary_body">
            <ng-container *ngIf="keywordsCheck?.suggested_keywords.keywords.length>0">
                <p class="SuggestedKeywords_text">Suggested Keywords</p>
                <ul>
                    <li *ngFor="let suggestedkeywordsObject of keywordsCheck?.suggested_keywords.keywords"
                        title="{{suggestedkeywordsObject.keyword}}">
                        <mat-checkbox class="example-margin" [(ngModel)]="suggestedkeywordsObject.is_selected">
                            {{suggestedkeywordsObject.keyword}}</mat-checkbox>
                    </li>
                </ul>
                <button class="selectedKeyword_buttons" mat-raised-button
                    (click)="insertSelectedKeywords(keywordsCheck?.suggested_keywords.keywords)">Copy selected keywords</button>
                    <span class="keyword_info_section" alt="The selected keywords are copied to the clipboard.">
                        <img src="../../../../assets/images/Icon_Info.svg" alt="">
                    </span>
            </ng-container>
            <ng-container class="keywordSummary_body" *ngIf="keywordsCheck?.suggested_keywords.keywords.length == 0">
                <div class="suggestion_keyword_notFound">
                    <p style="font-size: 16px;color: #4d4d4d;">No keyword(s) could be suggested</p>
                </div>
            </ng-container>
            <div class="keywords_is_found" *ngIf="keywordsCheck?.identified_keywords.is_present">
                <p style="font-size: 16px;"><span>{{keywordsCheck?.identified_keywords.count}}</span> keyword(s) found in document</p>
                <ul>
                    <li *ngFor="let identifiedkeywordsObject of keywordsCheck?.identified_keywords.keywords" title="{{identifiedkeywordsObject.keyword}}">
                        {{identifiedkeywordsObject.keyword}}
                    </li>
                </ul>
            </div>
            <div class="keywords_is_found keywordsNot_founds" *ngIf="keywordsCheck?.identified_keywords.is_present ==false">
                <p>No keyword(s) found in the document</p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!keywordsCheck?.is_present">
    </ng-container>
</mat-card>

<mat-card class="keywordSummary_sections">
    <ng-container *ngIf="summaryConceptCheck.is_present == true">
        <div class="keyword_summary_checks with_copy_button">
            <p>
                <span>Check 2</span> Summary
                <i class="keyword_info_section" alt="Shows a summary based on the most important sentences in the manuscript. The concepts are highlighted.">
                    <img src="../../../../assets/images/Icon_Info.svg" alt="">
                </i>
            </p>
        </div>
        <div class="keywordSymmary_descriptions" [class.Contentshow]="Contentshow">
            <p [innerHTML]="summaryConceptCheck.to_display"></p>
            <a (click)="Contentshow = !Contentshow">{{ Contentshow ? 'View Less': 'View More' }}</a>
        </div>
        <button class="keyword_copyButton" (click)="callServiceToCopy()">Copy summary</button>
        <span class="keyword_info_section" alt="The summary is copied to the clipboard.">
            <img src="../../../../assets/images/Icon_Info.svg" alt="">
        </span>
    </ng-container>
    <ng-container *ngIf="summaryConceptCheck.is_present == false">
        <div class="keyword_summary_checks">
            <p><span>Check 2</span> Summary</p>
        </div>
        <div class="summaryNot_fount">
            <p style="margin: 0;">Summary could not be generated</p>
        </div>
    </ng-container>

</mat-card>
</div>