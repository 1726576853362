<mat-list class="row headerBoxShadow ManageProfilemobileHeader">
    <div class="col-md-12 top_new_header">
        <div class="page_top_header">
            <h1 class="mat-display-1 mb-0">Journal Finder</h1>
            <div *ngIf="creditsDetails" class="AutoEditCreditDetails">
                <img src="../../../../assets/images/Credits_icon.svg" alt="">
                Credits:
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits != 0"
                    (click)="credits()">{{creditsDetails.credits + creditsDetails.purchased_credits}}</span>
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0"
                    (click)="credits()">Nil</span>
                <span *ngIf=" creditsDetails.credits + creditsDetails.purchased_credits == 0" class="autoEditBuyOption"
                    (click)="addCredits()">Buy</span>
            </div>
        </div>
    </div>
</mat-list>

<section class="reference-wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-9">
                <div class="journal-finder-left">
                    <h1>Find the Right Journal to Publish Your Paper</h1> 
                    <p>Ensure maximum citations by submitting your paper to the right journal. Compare your paper's concepts against millions of past publications and publication trends.</p>
                    <!-- <a href="#" class="mr-30">View Sample Report</a> -->
                    <a href="https://www.trinka.ai/features/journal-finder" target="_blank">Know More</a>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="button-gp">
                    <a class="upload-file-btn button" (click)="startJournalFinder()">Get Started</a>
                    <!-- <span>10 Credits per Search</span> -->
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h1 class="reference-title mb-10">Why Choose Trinka's Journal Finder</h1>
            </div>
        </div>
        <div class="row match-box">
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <div class="media">
                        <div class="media-left">
                            <img src="../../../../assets/images/server-icon.png" />
                        </div>
                        <div class="media-body">
                            <h3>Large database of scientific publications</h3>
                            <p>Trinka's Journal Finder uses a large database of scientific publications gathered from sources such as <span>Crossref</span> and <span>PubMed</span>.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <div class="media">
                        <div class="media-left">
                            <img src="../../../../assets/images/paper.png" />
                        </div>
                        <div class="media-body">
                            <h3>Works for all subjects</h3>
                            <p>No need to keep looking for Journals that publish in your subject area. Trinka's Journal Finder recommends the right journal <span>for all scientific disciplines</span>.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <div class="media">
                        <div class="media-left">
                            <img src="../../../../assets/images/brain.png" />
                        </div>
                        <div class="media-body">
                            <h3>Al-powered recommendations</h3>
                            <p>Al technology that <span>understands the concepts</span> in your paper and finds published articles with similar concepts to recommend the right journal to you.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 dflex">
                <div class="c-card ref-card">
                    <div class="media">
                        <div class="media-left">
                            <img src="../../../../assets/images/stats-search.svg" />
                        </div>
                        <div class="media-body">
                            <h3>Publication trends to understand citability</h3>
                            <p>See the historical publication trend of your topic and understand how your paper could be cited once published.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="video">
                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/fuWNwHYFPFM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>

    </div>
</section>