<div class="CreditsPopupSection">
    <div class="CreditsHeader">
        <h2>Rewards Earned: <span>${{referralReward}}</span></h2>
        <button mat-button mat-dialog-close>
            <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
    </div>
    <div class="creditBodySection">
        <p>Get the most of your earned rewards when you purchase your next subscription. With unlimited referrals available, you stand a chance to make your next subscription/renewal completely free!</p>
        <p>Refer Trinka now to earn rewards!</p>
    </div>


    <div class="addCreditSection">
        <button class="referNowButton" (click)="referNow()" [ngClass]="referralReward > 0 ? 'refer' : ''"> Refer Now </button>
        <button *ngIf="referralReward > 0" (click)="redeemNow()" class="redeen" style="margin-bottom: 16px;">Redeem Now</button>
    </div>
    <div class="renewal_premium_plus_msg" *ngIf="showPremiumPlusRedeemMsg">
        <p>Since you are on the Premium Plus Plan, Referral Rewards will automatically be applied on your next subscription renewal.</p>
    </div>

</div>