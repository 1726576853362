import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModuleList } from './material-modules';
import { WebsiteModule } from './modules/website/website.module';
import { EditorModule } from './modules/editor/editor.module';
import { DashbaordModule } from './modules/dashbaord/dashbaord.module';
import { FileUploadComponent } from './components/dashboard/file-upload/file-upload.component';
import { ToastrModule } from 'ngx-toastr';
import { Page404Component } from './components/dashboard/page404/page404.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MatDialogModule} from '@angular/material/dialog';
import { DownloadfilepopupComponent } from './components/downloadfilepopup/downloadfilepopup.component';
import { BrowserExtensionModule } from './modules/browser-extension/browser-extension.module';
import { LanguagePreferencesComponent } from './components/language-preferences/language-preferences.component';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material';
import { UserVerifyComponent } from './components/user-verify/user-verify.component';
import { SharedModule } from './modules/shared/shared.module';
import { EditorReferenceQualityReportComponent } from './components/editor/editor-reference-quality-report/editor-reference-quality-report.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DeleteFeedbackModalComponent } from './components/delete-feedback-modal/delete-feedback-modal.component';
import { JournalFinderModule } from './modules/journal-finder/journal-finder.module';
import { ReferenceQualityComponent } from './components/dashboard/reference-quality/reference-quality.component';


import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { FusionChartsModule } from 'angular-fusioncharts';
import { WordPluginSinginComponent } from './Components/word-plugin-singin/word-plugin-singin.component';
import { PlagiarismCheckModule } from './modules/plagiarism-check/plagiarism-check.module';
// import * as Sentry from "@sentry/browser"
import { Router } from '@angular/router';
import { LanguageCodePreferenceModalComponent } from './components/language-code-preference-modal/language-code-preference-modal.component';
import { UpgradeIdPageComponent } from './Components/upgrade-id-page/upgrade-id-page.component';

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);
FusionCharts.options['license']({
  key: 'KqA8ydjB3B3E2A3A15B10A7C6D5D5C1H2C1ixrB1F2gcowc1B6A7A-13mB-9A3H2C2B2C1E7C7E1F5C4A1B3A6B2D3D2ippE1C3C11d1C7A3B4utcH3B5D7moB-9D3C4D2H-7geE3A3B2F4H2D3H2C8B1E4B1zllA33A8a1B9d1dB-11G4F4D3jyA7RA1E3wwi1A1A7A4B6B5F5E4A2H3G3A5B4B3C7E-11==',
  creditLabel: false,
});
@NgModule({
  declarations: [
    AppComponent,
    FileUploadComponent,
    LanguagePreferencesComponent,
    Page404Component,
    DownloadfilepopupComponent,
    UserVerifyComponent,
    EditorReferenceQualityReportComponent,
    DeleteFeedbackModalComponent,
    ReferenceQualityComponent,
    WordPluginSinginComponent,
    LanguageCodePreferenceModalComponent,
    UpgradeIdPageComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModuleList,
    WebsiteModule,
    DashbaordModule,
    EditorModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      resetTimeoutOnDuplicate : true,
      newestOnTop : true,
    }),
    AngularFontAwesomeModule,
    MatDialogModule,
    BrowserExtensionModule,
    FormsModule,
    SharedModule,
    NgxSpinnerModule,
    JournalFinderModule,
    FusionChartsModule,
    PlagiarismCheckModule,
  ],
  entryComponents: [
    DownloadfilepopupComponent,
    LanguagePreferencesComponent,
    LanguageCodePreferenceModalComponent
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
