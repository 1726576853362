import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-power-mode-editing',
  templateUrl: './power-mode-editing.component.html',
  styleUrls: ['./power-mode-editing.component.scss']
})
export class PowerModeEditingComponent implements OnInit {
  showRevivsionOne: boolean = false;
  showRevivsionTwo: boolean = false;
  showRevivsionThree: boolean = false;
  constructor(private dialogRef: MatDialogRef<PowerModeEditingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }
  closeModal(param){
    this.dialogRef.close(param)
  }
  showRevivationActive_Slider_one(){
    this.showRevivsionOne = !this.showRevivsionOne;
  }
  showRevivationActive_Slider_two(){
    this.showRevivsionTwo = !this.showRevivsionTwo;
  }
  showRevivationActive_Slider_three(){
    this.showRevivsionThree = !this.showRevivsionThree;
  }
}
