import { Component, OnInit, Inject } from '@angular/core';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { SharedService } from 'src/app/_services/shared.service';
import { LanguagePreferencesGlobalComponent } from '../../language-preferences-global/language-preferences-global.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectProfileModalComponent } from '../../select-profile-modal/select-profile-modal.component';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { PaymentService } from 'src/app/_services/networkcalls/payment.service';
import { passwordStrength } from 'src/app/_directives/custom-validators.directive';
import { MatSnackBar, MatDialog, MatDialogConfig } from '@angular/material';
import { PaymentTransactionComponent } from '../payment-transaction/payment-transaction.component';
import { environment } from 'src/environments/environment';
import { DeleteAccountComponent } from 'src/app/components/dashboard/delete-account/delete-account.component';
import { DatePipe } from '@angular/common'
import { PaymentDetails } from 'src/app/_interfaces/dashboard/payment-details';
import { noWhiteSpaceValidator } from 'src/app/_directives/customValidators';
// import { ChangePaymentMethodComponent } from '../change-payment-method/change-payment-method.component';
import { SavedCardsComponent } from '../saved-cards/saved-cards.component';
import { LanguageCodePreferenceModalComponent } from 'src/app/components/language-code-preference-modal/language-code-preference-modal.component';

declare function wisepop():any;

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss']
})
export class ManageProfileComponent implements OnInit {
  updatepassword: FormGroup;
  userId: string;
  selectedProfile: any;
  profileList = [];
  subjectAreaListArray = [];
  userDetails: any;
  userSubscriptionDetails: any;

  passwordStrengthScore: number;
  showPassword: boolean = true;


  containAtLeastEightChars: boolean;
  containAtLeastOneLowerCaseLetter: boolean;
  containAtLeastOneUpperCaseLetter: boolean;
  containAtLeastOneDigit: boolean;
  containAtLeastOneSpecialChar: boolean;
  userData: any;
  emailItems = [{selected: true, label: 'Send Auto File Edit updates',type : 'autoedit'}, {selected: true, label: 'Send Publication Checks updates',type : 'publication'}];
  paymentDetails : PaymentDetails;
  provider : any;
  savedCardList: any;
  isUserSubscriptionDetailsLoaded: boolean = false;
  paymentMethodDetails: any;
  isFetchSubscriptionPaymentMethodLoaded: boolean = false;
  stripePortalLink: string = '';

  constructor(
    public networkCalls: DashboardService,
    public networkCallsEditor: OnlineEditorService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public sharedService: SharedService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public fb: FormBuilder,
    public subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    public amplitudeService: AmplitudeService,
    public networkCallsPayments: PaymentService,
    public datepipe: DatePipe
  ) {
    this.updatepassword = fb.group({
      password: new FormControl('', [Validators.required, Validators.minLength(8),noWhiteSpaceValidator.cannotContainSpace]),
    });
  }


  passUpdateMode: boolean = false;
  ngOnInit() {
    wisepop();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    let data:any = {
      "user_id" : btoa(localMeta.sub)
    };
    this.networkCalls.getStoreUserPlanDetails().subscribe(planDetails => {
      if (planDetails && planDetails.user_type) {
        data = {
          "user_id": planDetails.user_id,
          "plan_name": this.sharedService.uppercaseFirstLetter(planDetails.user_type).toLowerCase(),
          "from": "Trinka"
        };
        this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
      }
    },err=>{console.log(err)});
    // this.getUserProfileData()
    this.getSelectedProfileData();
    this.getSubsciptionDetails();
    this.getProfileList();
    this.getNotificationStatus();
    this.getUserPaymentSubscription();
    // this.fetchSavedCards();
    this.setStripePortalLink();
  }


  getSubsciptionDetails() {
    this.spinner.show();
    this.networkCallsPayments.getPaymentSubscriptionDetails(this.userId).subscribe(response => {
      this.userSubscriptionDetails = response.data;
      // this.fetchSubscriptionPaymentMethod();
      this.spinner.hide();
      this.isUserSubscriptionDetailsLoaded = true;
      this.amplitudeService.setUserProperties('subscription_period',this.userSubscriptionDetails['interval']);
    }, error => {
      this.isUserSubscriptionDetailsLoaded = true;
      //  this.sharedService.errorHandller(error);
    }, () => {
      this.isUserSubscriptionDetailsLoaded = true;
    });
  }


  getUserProfileData() {
    this.spinner.show();
    this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(data => {
      this.userDetails = data
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }
  togglePasswordEditMode() {
    if (this.updatepassword.value.password != "") {
      this.updatepassword.reset();
    }
    this.passUpdateMode = !this.passUpdateMode
  }

  changePassword() {
    this.spinner.show();
    this.networkCalls.updateUserPassword(btoa(this.updatepassword.value.password)).subscribe(data => {
      this.spinner.hide();
      // this.password = atob(data.password)
      this.showPassword = !this.showPassword;
      this.togglePasswordEditMode();
      this.toastr.success("Password updated successfully", 'Success');
      this.amplitudeService.logAmplitude('password_change', {
        'userID': this.userId
      });
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  openLanguagePrefGlobal() {
    this.dialog.open(LanguagePreferencesGlobalComponent, { data: { language: "US" } });
  }

  openLanguageCodePreferrencedModal() {
    this.dialog.open(LanguageCodePreferenceModalComponent, { data: { language: "US" } });
  }

  getProfileList() {
    this.spinner.show();
    this.networkCalls.getUserMultipleProfile(this.userId).subscribe(result => {
      if (result["status"]) {
        this.profileList = result['data'];
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }
  openChangeProfile() {
    this.dialog.open(SelectProfileModalComponent, { data: { profileList: this.profileList, selectedProfile: this.selectedProfile, closeButton: true } }).afterClosed().subscribe(() => {
      this.getSelectedProfileData();
    })
    this.amplitudeService.logAmplitude('switch_account', {
      'userID': this.userId,
      'account_code': this.selectedProfile['membershipid']
    });
  }

  getSelectedProfileData() {
    this.subjectiveBehaviorSharedService.getSelectedProfileData.subscribe(data => {
      this.selectedProfile = data;
      if (this.sharedService.featurePermission.data.enago_proofreading || this.sharedService.featurePermission.data.document_style) {
        this.getSubjectAreas();
      }
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  getSubjectAreas() {
    this.subjectAreaListArray = [];
    this.networkCallsEditor.subjectAreaRequest(this.userId).subscribe(result => {
      if (result['status']) {
        this.subjectAreaListArray = this.filterSubjectArea(result['data']['areaDetails']);
        this.subjectiveBehaviorSharedService.setSubjectAreaList(this.subjectAreaListArray)
      } else {
        this.toastr.warning(result['message'])
      }
    }, error => {
      this.spinner.hide();
      // this.sharedService.errorHandller(error);
    });
  }

  filterSubjectArea(SAlist) {
    var temp = [];
    return SAlist.filter(sa => {
      if (sa.levelNo == 3) {
        if (!temp.includes(sa.text)) {
          temp.push(sa.text);
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })
  }

  onStrengthChanged(result: any) {
    this.passwordStrengthScore = result.value;
    this.containAtLeastEightChars = result.at_least_eight_chars;
    this.containAtLeastOneDigit = result.at_least_one_digit_char;
    this.containAtLeastOneLowerCaseLetter = result.at_least_one_lower_case_char;
    this.containAtLeastOneSpecialChar = result.at_least_one_special_char;
    this.containAtLeastOneUpperCaseLetter = result.at_least_one_upper_case_char;


  }

  resetPasswordStrenth(event) {
    this.containAtLeastEightChars =
      this.containAtLeastOneLowerCaseLetter =
      this.containAtLeastOneUpperCaseLetter =
      this.containAtLeastOneDigit =
      this.containAtLeastOneSpecialChar = false;
  }

  getTransactionHistory(){
    this.spinner.show();
    this.networkCallsPayments.getUserTransactionDetails(this.userId).subscribe(result => {
      if (result['status']) {
        var allTransactions = result["data"]
        this.transactions(allTransactions)
        this.spinner.hide();
        this.amplitudeService.logAmplitude('Transaction_History',{
          'User ID': this.userId,
          'Subscription Plan':this.userSubscriptionDetails['plan_name'],
          'Subscription End Date': this.datepipe.transform(new Date(this.userSubscriptionDetails['next_renewal_date']),'MMM dd, yyyy')
        });

      }else {
        this.toastr.warning(result['message'])
      }
    },error => {
      this.toastr.warning(error['message'])
    })
  }

  getTransactionHistoryBasicUser(){
    this.spinner.show();
    this.networkCallsPayments.getUserTransactionDetails(this.userId).subscribe(result => {
      if (result['status']) {
        var allTransactions = result["data"]

        this.transactions(allTransactions)
        this.spinner.hide();
        this.amplitudeService.logAmplitude('Transaction_History',{
          'User ID': this.userId,
          'Subscription Plan':this.userSubscriptionDetails['plan_name'],
          'Subscription End Date': this.datepipe.transform(new Date(this.userSubscriptionDetails['next_renewal_date']),'MMM dd, yyyy')
        });
      }
      else {
        var allTransactions = result["data"]
        this.transactions(allTransactions)
        this.spinner.hide();
      }
    },error => {
      this.toastr.warning(error['message'])
    })
  }

  transactions(allTransactionDetails){
    if(allTransactionDetails != null){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        transactionDetails : allTransactionDetails,
      };
      this.dialog.open(PaymentTransactionComponent, dialogConfig)
    }
  }

  confirmCancel(){
    this.amplitudeService.logAmplitude('Cancel_Subscription',{
      'User ID': this.userId,
      'Subscription Plan':this.userSubscriptionDetails['plan_name'],
      'Subscription End Date': this.datepipe.transform(new Date(this.userSubscriptionDetails['next_renewal_date']),'MMM dd, yyyy')
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      transactionDetails : "CONFIRMATION",
    };
    const dialogRefResp = this.dialog.open(PaymentTransactionComponent, dialogConfig)
    dialogRefResp.afterClosed().subscribe(data =>  {
      if(data == true){
        this.amplitudeService.logAmplitude('Confirm_Cancel_Subs',{
          'User ID': this.userId,
          'Subscription Plan':this.userSubscriptionDetails['plan_name'],
          'Subscription End Date': this.datepipe.transform(new Date(this.userSubscriptionDetails['next_renewal_date']),'MMM dd, yyyy')
        });
        this.cancelSubscription()
      }
      else{
        this.amplitudeService.logAmplitude('Close_confirm_popup',{
          'User ID': this.userId,
          'Subscription Plan':this.userSubscriptionDetails['plan_name'],
          'Subscription End Date': this.datepipe.transform(new Date(this.userSubscriptionDetails['next_renewal_date']),'MMM dd, yyyy')
        });
      }

    })
  }

  cancelSubscription(){
    this.spinner.show();
    this.networkCallsPayments.cancelSubscription(this.userId).subscribe(result => {
      if (result['status']) {
        this.toastr.success("Subscription Cancelled")
        this.getSubsciptionDetails()
        this.spinner.hide();

      }else {
        this.toastr.warning("Subscription cancellation failed")
        this.spinner.hide();

      }
    }, err => {
      this.toastr.error("Subscription cancellation failed")
      this.spinner.hide();

    })
    }

    async upgradeToPremium(){
      let redirct_url  = environment.payment_fe;
      this.amplitudeService.logAmplitude('Upgrade_nudge_clicked', {
        'from': 'Account settings',
        'Current Plan': this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase()
      });
      if (!this.userData) {
        let data = {
          "user_id": this.userId,
          "plan_name": this.sharedService.uppercaseFirstLetter(this.paymentDetails.user_type).toLowerCase(),
          "from": "Trinka"
        };
        this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
      }
      window.open(redirct_url + '/user/' + this.userData, "_blank"); 
    }

    notificationsChange(type, status)
    {
      this.spinner.show();
        var notification_details = new FormData()
        notification_details.append("email_id",this.userId);
        notification_details.append("status",status.checked)
      if(type=="autoedit"){
          this.networkCalls.setEmailStatusAutoEdit(notification_details).subscribe(data =>{
            this.spinner.hide();
            this.toastr.success("Successfully updated autoedit email notification settings");
          }, err => {
            this.toastr.error("Failed to update autoedit email notification settings")
            this.spinner.hide();
          });
      }
      else{
          this.networkCalls.setEmailControlPublication(notification_details).subscribe(data =>{
            this.spinner.hide();
            this.toastr.success("Successfully updated publication email notification settings");
          }, err => {
            this.toastr.error("Failed to update publication email notification settings")
            this.spinner.hide();
          });
      }
    }

    getNotificationStatus(){
      this.networkCalls.getEmailStatusAutoEdit(this.userId).subscribe(data => {
        let status = data;
          this.emailItems[0].selected =  Boolean(status);
      }, err => {
        this.toastr.error("Failed to get autoedit email notification settings")
        this.spinner.hide();
      });

      this.networkCalls.getEmailControlPublication().subscribe(res => {
        let status = res["data"];
        this.emailItems[1].selected =  Boolean(status);
      }, err => {
        this.toastr.error("Failed to get publication email notification settings")
        this.spinner.hide();
      });
    }

    is_enago_user : boolean = false
    DeleteAccountPopUp()
    {
      this.networkCalls.getEnagoUserFlag(this.userId).subscribe(result =>{
        this.is_enago_user = result['data']['is_enago_user'];
        this.provider = result['data']['provider']
        this.amplitudeService.logAmplitude('Delete_account_CTA', {
          'User ID': this.userId,
          'User source': this.is_enago_user ? "Enago" : "Trinka",
          'Subscription Plan': this.paymentDetails['is_premium']?'Premium':'Basic'
        });
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          is_enago_user : this.is_enago_user,
          'userId' : this.userId,
          'subscriptionPlan' : this.paymentDetails['is_premium']?'Premium':'Basic',
          provider : this.provider
        };
        const dialogRefResp = this.dialog.open(DeleteAccountComponent, dialogConfig)
        dialogRefResp.afterClosed().subscribe(data =>  {
        if(data == true){
          this.deleteAccount()
        }

    })
       }, err => {
        this.toastr.error("Something went wrong")
       });
      
    }
    
    deleteAccount(){
      // alert("Account deleted successfully");
      var obj = new FormData()
      obj.append("email_id",this.userId);


      this.spinner.show();
      this.networkCalls.deleteAccount(obj).subscribe(data =>{
        this.spinner.hide();
       this.sharedService.SignOut(true,this.userId);
        this.toastr.success("Account Deleted Successfully");
        
      }, err => {
       // this.sharedService.SignOut(true,this.userId);
       this.toastr.error("Failed to delete account")
        this.spinner.hide();
      });
    }

    getUserPaymentSubscription(){
      this.networkCalls.getUserPaymentSubscription(this.userId).subscribe(response => {
        this.paymentDetails = response['data'];
      });
    }

    
    changePaymentMethod() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        userSubscriptionDetails: this.userSubscriptionDetails
      };
      // const dialogRefResp = this.dialog.open(ChangePaymentMethodComponent, dialogConfig);
      // this.amplitudeService.logAmplitude('Pay_change_paymethod_CTA', {
      //   'Subscription End Date': this.datepipe.transform(new Date(this.userSubscriptionDetails['next_renewal_date']),'MMM dd, yyyy')
      // });
      
      // dialogRefResp.afterClosed().subscribe(data => {
      //   if (data) {
      //     // this.fetchSubscriptionPaymentMethod();
      //   }
      // });
    }


    fetchSavedCards() {
      this.spinner.show();
      this.networkCallsPayments.getSavedCardDetails(this.userId).subscribe(result => {
        this.savedCardList = result['data'];
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
    }


    showSavedCards() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        userSubscriptionDetails: this.userSubscriptionDetails,
        savedCardList: this.savedCardList,
        isConfirmationModal: false,
        userId: this.userId
      };
      this.dialog.open(SavedCardsComponent, dialogConfig);
    }


    formatExpiryDate(card) {
      let expiryDate = card.expiry_month < 10 ? "0" + card.expiry_month : card.expiry_month;
      expiryDate += "/" + card.expiry_year.toString().slice(2);
      return expiryDate;
    }


    fetchSubscriptionPaymentMethod() {
      this.networkCallsPayments.getSubscriptionPaymentMethod(this.userSubscriptionDetails['subscription_id']).subscribe(result => {
        this.paymentMethodDetails = result['data'];
        this.isFetchSubscriptionPaymentMethodLoaded = true;
        this.isUserSubscriptionDetailsLoaded = true;
      }, error => {
        this.isFetchSubscriptionPaymentMethodLoaded = true;
        this.isUserSubscriptionDetailsLoaded = true;
      });
    }
  
    async redirectToSubscriptionPage() {
      this.setStripePortalLink();
      setTimeout(() => {
        if (this.stripePortalLink) window.open(this.stripePortalLink, '_blank');
      }, 600);  
    }
  
    setStripePortalLink(isPremiumUpgrade: boolean = false) {
      this.networkCallsPayments.getCustomerPortalLink(this.userId, isPremiumUpgrade).subscribe(response => {
        this.stripePortalLink = response.data;
      })
    }
  
}
