import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';

@Component({
  selector: 'app-instruction-feedback-modal',
  templateUrl: './instruction-feedback-modal.component.html',
  styleUrls: ['./instruction-feedback-modal.component.scss']
})
export class InstructionFeedbackModalComponent implements OnInit {

  accuracyOption : { 'panini': boolean, 'live': boolean } = { 'panini': false, 'live': false };
  qualityOption : { 'panini': boolean, 'live': boolean } = { 'panini': false, 'live': false };
  speedOption : { 'panini': boolean, 'live': boolean } = { 'panini': false, 'live': false };
  textFeedback: string = '';
  isLiked: boolean = false;
  isSubmitted: boolean = false;
  fileId = "";
  userId = "";
  constructor(private dialogRef: MatDialogRef<InstructionFeedbackModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private networkCalls: OnlineEditorService,
  private amplitudeService: AmplitudeService,
  ) {
    this.isLiked = data.isLiked;
    this.fileId = data.fileId;
    this.userId = data.userId;
  }

  ngOnInit() {
  }

  closeModal(param){
    this.dialogRef.close(param)
  }

  toggleSelectedOption(key, value) {
    for (const property in this[key]) {
      this[key][property] = false;
    }    
    this[key][value] = true;
    // this.validateForm()
  }

  disableSubmit = true;

  // validateForm(){
  //   if(Object.values(this.accuracyOption).includes(true) && Object.values(this.speedOption).includes(true) &&  Object.values(this.qualityOption).includes(true)){
  //     this.disableSubmit = false;
  //     return true;
  //   } else {
  //     this.disableSubmit = true;
  //     return false;
  //   }
  // }
  submitFeedback() {
    // if(this.validateForm()){
      let payload = {
        'iLikePanini': this.isLiked,
        'suggestion': this.textFeedback,
        'accuracy': this.findKeyWithTrueValue(this.accuracyOption),
        'speed': this.findKeyWithTrueValue(this.speedOption),
        'quality': this.findKeyWithTrueValue(this.qualityOption)
      }
      this.amplitudeService.logAmplitude('Trinka_Labs_Submit_feedback',payload);
      this.disableSubmit = false;
      this.isSubmitted = true;
      this.networkCalls.paniniFeedbackRequest(this.userId, this.fileId, payload).subscribe(result =>{
        console.log(result)
      })
    // } else {
    //   this.disableSubmit = false;
    // }
    // this.closeModal(true);
  }

  returnKey(obj){
    return Object.keys(obj).filter(r=> {
      if(obj[r]){
        return r
      }
      return obj[r] === true
    })
  }

  // Function to find the key with the value true
  findKeyWithTrueValue(obj: any): string | null {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === true) {
      return key;
    }
  }
  return "None"; // Return null if no key with true value is found
}
}
