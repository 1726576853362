export class Stack {
  private _rejectedJobs = [];
  private _jobs = [];
  private _jobsProcessingInterval = null;
  private _jobTimeout = 4 * 60 * 1000; // 4 minutes
  private _index = 0;

  isProcessingStarted = false;
  jobsProcessingIntervalTime = 10000;

  constructor() { }

  add(worker, meta) {
    this._index++;
    meta = meta || { enableLogging: false }; // if require can store more data

    if (meta.enableLogging) {
      console.log('STACK: add ===> ', this._index, meta.timeStamp);
    }

    return new Promise((resolve, reject) => {
      this._jobs.push({ index: this._index, worker, meta, resolve, reject });
    });
  }

  startJobProcessing() {
    this._jobsProcessingInterval = setInterval(() => { this._process(); }, this.jobsProcessingIntervalTime);
  }

  restartJobProcessing() {
    clearInterval(this._jobsProcessingInterval);
    this._jobsProcessingInterval = setInterval(() => { this._process(); }, this.jobsProcessingIntervalTime);
  }

  stopJobProcessing() {
    clearInterval(this._jobsProcessingInterval);
    // TODO: need to fix last job execution (status: CRASHED)
    this._process();
    this._index = 0;
    this._rejectedJobs = [];
    this._jobs = [];
    this.isProcessingStarted = false;
  }

  private _process() {
    if (!this._jobs.length) return;

    // let job = this._jobs[this._jobs.length - 1];
    // this._rejectedJobs.push(this._jobs.slice(0, -1));
    let job = this._jobs.pop();

    let timeout = setTimeout(() => { this._timeout(job) }, this._jobTimeout);
    this._rejectedJobs.push(this._jobs.splice(0));

    try {
      job.worker(job.meta.params.callback, job.meta.params.forceUpdate).then((response) => {
        job.resolve(response);
        this._done(timeout, job);
      }, (error) => {
        job.reject(error);
        this._catch(timeout, job);
      });
    } catch (err) {
      job.reject(err);
      this._catch(timeout, job);
    }
  }

  private _timeout(job) {
    this._next('TIMEOUT', job);
  }

  private _done(timeout, job) {
    clearTimeout(timeout);
    this._next('FINISHED', job);
  }

  private _catch(timeout, job) {
    clearTimeout(timeout);
    this._next('CRASHED', job);
  }

  private _next(status, job) {
    // let completedJob = this._jobs.pop();
    if (job.meta.enableLogging) {
      console.log('STACK: status ===> ', status);
      console.log('STACK: completedJob ===> ', job);
      console.log('STACK: rejectedJobs till now ===> ', this._rejectedJobs);
    }
  }
}
