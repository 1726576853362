import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'subjectareaFilter',
    pure: false
  })
  export class SubjectAreaFilterPipe implements PipeTransform {
    transform(items: any, filter: any): any {
      let currentfilter = filter["Subject Area"].data;
      if (!items) return [];
      
      if (currentfilter.length == 0) return items;

      return items.filter(result => {return result.journal_subject_area.includes(currentfilter)});
    }
  }