<div class="contactUsPopup">
  <form [formGroup]="feedbackForm" (submit)="onSubmitClick()" *ngIf="!feedbackSubmitted">
    <div class="contactUsHeader">
      <h2>Rate your experience</h2>
    </div>
    <div class="contactUsBody">
      <ul>
        <li>
          <span *ngFor="let rating of ratings" [ngClass]="{'active': rating <= ratingFeedback}"
            (click)="ratingFeedback = rating">
            <svg class="starWithNonBackground" width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.2003 1.66699L19.3203 10.0137L28.5336 11.3603L21.8669 17.8537L23.4403 27.027L15.2003 22.6937L6.96028 27.027L8.53361 17.8537L1.86694 11.3603L11.0803 10.0137L15.2003 1.66699Z" stroke="#F59E0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg class="starWithBackgound" width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.8001 1.66699L18.9201 10.0137L28.1335 11.3603L21.4668 17.8537L23.0401 27.027L14.8001 22.6937L6.56013 27.027L8.13346 17.8537L1.4668 11.3603L10.6801 10.0137L14.8001 1.66699Z" fill="#F59E0B" stroke="#F59E0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </li>
      </ul>
      <textarea matInput formControlName="feedbackText" placeholder="Share your feedback with us..."
        class="feedback-textarea"></textarea>
      <!-- <div
        *ngIf="feedbackForm.controls?.['feedbackText']?.invalid && (feedbackForm.controls?.['feedbackText']?.dirty || feedbackForm.controls?.['feedbackText']?.touched)">
        Feedback text is required. 
      </div> -->
      <!-- <mat-error *ngIf="feedbackForm.controls.feedbackText.errors?.required && feedbackForm.get('feedbackText').touched">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0ZM7 3C6.87304 2.99989 6.74745 3.02626 6.63126 3.07743C6.51507 3.1286 6.41082 3.20344 6.32519 3.29717C6.23955 3.3909 6.1744 3.50146 6.13391 3.62179C6.09341 3.74211 6.07846 3.86957 6.09 3.996L6.455 8.002C6.46915 8.13668 6.53267 8.26136 6.63331 8.35197C6.73395 8.44259 6.86458 8.49274 7 8.49274C7.13542 8.49274 7.26605 8.44259 7.36669 8.35197C7.46733 8.26136 7.53085 8.13668 7.545 8.002L7.909 3.996C7.92053 3.86965 7.90561 3.74228 7.86517 3.62202C7.82474 3.50176 7.75968 3.39125 7.67415 3.29754C7.58862 3.20382 7.4845 3.12896 7.36843 3.07773C7.25235 3.0265 7.12688 3.00003 7 3ZM7 11C7.21217 11 7.41566 10.9157 7.56569 10.7657C7.71571 10.6157 7.8 10.4122 7.8 10.2C7.8 9.98783 7.71571 9.78434 7.56569 9.63431C7.41566 9.48429 7.21217 9.4 7 9.4C6.78783 9.4 6.58434 9.48429 6.43431 9.63431C6.28429 9.78434 6.2 9.98783 6.2 10.2C6.2 10.4122 6.28429 10.6157 6.43431 10.7657C6.58434 10.9157 6.78783 11 7 11Z"
                  fill="#FF5C5C" />
          </svg>
          Required
      </mat-error> -->
    </div>
    <div class="contactUsFooter">
      <button class="contactusCloseButton" (click)="cancelEvent()" mat-button mat-dialog-close>Cancel</button>
      <button type="submit" class="contactUsSubmitButton" [disabled]="ratingFeedback <=0">Submit</button>
    </div>
  </form>
  <div class="feedbackThankYou" *ngIf="feedbackSubmitted">
    <!-- <div class="thankYouAnimation animate__animated animate__fadeOut animate__delay-3s"></div>
    <div class="thankYouAnimation thankYouAnimationAfter animate__animated animate__fadeIn animate__delay-3s">
      <span></span>
    </div> -->
    <div class="SVGwrapper"> 
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
    </div>
    <h2 class="animate__animated animate__fadeInUp animate__delay-1s">Thank you for sharing your feedback!</h2>
    <button class="contactusCloseButton animate__animated animate__fadeInUp animate__delay-1s" class="contactusCloseButton" mat-button mat-dialog-close>Close</button>
  </div>
</div>
