import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { DictWordList } from 'src/app/_interfaces/dashboard/dict-word-list';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-dictionary-modal',
  templateUrl: './dictionary-modal.component.html',
  styleUrls: ['./dictionary-modal.component.scss']
})
export class DictionaryModalComponent implements OnInit, AfterViewInit {
  dictApiCall = true
  dicWordList: DictWordList[]
  constructor(
    private networkCalls: DashboardService,
    public sharedService: SharedService,
    private router: Router,
    private dialogRef: MatDialogRef<DictionaryModalComponent>
  ) { }



  ngOnInit() {

  }
  
  ngAfterViewInit(){
    if(this.dictApiCall){
      this.dictApiCall = false;
      this.networkCalls.getDictVal().subscribe(words => {
        this.dicWordList = words['data']
        }, error => {
          this.sharedService.errorHandller(error);
        });
        this.dictApiCall = true
    }
  }

  redirectToDict(){

   this.closeDialog()
    this.router.navigate(['/dashboard/mydictionary']);

  }

  closeDialog(){
    this.dialogRef.close();
  }


}
