<div class="outer_login_wrap_section">
    <div class="login_left_side">
        <div class="left_login_overlay"></div>
        <div class="login_content">
            <div class="top_logo">
                <a href="https://trinka.ai" target="_blank">
                    <img src="/assets/images/trinka-logo-login.png" alt="">
                </a>
            </div>
            <div class="login_text_contents">
                <h2>Help us improve</h2>
                <p style="max-width: 450px;">As you are starting your journey with Trinka, please register or login to access a range of unique features.</p>
            </div>
            <div class="login_footer">
                <ul>
                    <li><a href="https://www.trinka.ai/privacypolicy" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://www.trinka.ai/termsofservices" target="_blank">Terms of Services</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="login_right_side browserInstall_page">
        <div class="login_body_section">
            <div class="uninstall_by_mistake">
                <h2>We're sorry to see you go!</h2>
            </div>
            <div class="uninstall_by_mistake">
                <h6>We have deleted your Trinka account</h6>
            </div>
            <div class="feedback_forms">
                <p>Could you please let us know why you deleted your account?</p>
                <ul *ngFor="let item of suggestion | keyvalue: asIsOrder ">
                    <li >
                        <mat-checkbox  [checked]="item.value.selected" [(ngModel)]="item.value.selected"
                        (change)="validateSelectedOptions()">
                            {{item.value.value}}
                        </mat-checkbox>                      
                    </li>
                    <li *ngIf="item.value.selected && item.value.inputBox">
                        <textarea [(ngModel)]="item.value.text" placeholder="How can we make Trinka better?"></textarea>
                    </li>
                </ul>
            </div>
            <div  class="feedback_submit_buttons">
                <button class="feedback-btn" [disabled]="isDisabled" (click)="submitFeedback()">Submit Feedback</button>              
            </div>
        </div>
    </div>
</div>