<ngx-spinner></ngx-spinner>
<div class="pageLoader" *ngIf="jouranlFinderPageLoader">
  <svg class="spinner">
    <circle style="cx: 50%;cy: 50%; r: 50%;">
      <animateTransform attributeName="transform" type="rotate" values="-90;810" keyTimes="0;1"
        dur="2s" repeatCount="indefinite"></animateTransform>
      <animate attributeName="stroke-dashoffset" values="0%;0%;-157.080%" calcMode="spline"
        keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s"
        repeatCount="indefinite"></animate>
      <animate attributeName="stroke-dasharray" values="0% 314.159%;157.080% 157.080%;0% 314.159%"
        calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s"
        repeatCount="indefinite"></animate>
    </circle>
  </svg>
  <span>Opening Journal Finder...</span>
</div>

<header class="header-wrapper">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-8">
        <div class="logo">
          <img src="../../../assets/images/Trinka_Logo.svg" [routerLink]="['/dashboard']" alt="logo" />
          <span class="tags-box" *ngIf="creditsDetails?.user_type === 'BASIC'">Basic</span>
          <span class="tags-box-premium" *ngIf="creditsDetails?.user_type === 'PREMIUM'">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.187 4.35161L9.41105 1.67033C9.34045 1.56408 9.22115 1.5 9.09353 1.5H2.90684C2.77913 1.5 2.65992 1.56409 2.58931 1.67033L0.813334 4.35161C0.718569 4.49408 0.731218 4.68211 0.844103 4.81027L5.71367 10.3701C5.78559 10.4528 5.88998 10.5 5.99995 10.5C6.10992 10.5 6.2143 10.4528 6.2866 10.3698L11.1562 4.8099C11.2687 4.6821 11.2813 4.49408 11.187 4.35161ZM3.84922 4.9405L5.02944 8.43581L1.96799 4.9405H3.84922ZM6.87805 2.25922L7.38839 4.18168H4.61062L5.12097 2.25922H6.87805ZM7.34695 4.9405L5.99977 8.93135L4.65259 4.9405H7.34695ZM8.15037 4.9405H10.0317L6.97023 8.43581L8.15037 4.9405ZM10.1619 4.18127H8.17623L7.66588 2.25881H8.88875L10.1619 4.18127ZM3.11118 2.2592H4.33405L3.8237 4.18165H1.83753L3.11118 2.2592Z" fill="#92400E"/>
            </svg>  
            Premium
          </span>
          <span class="tags-box-premium-plus" *ngIf="creditsDetails?.user_type === 'PREMIUM_PLUS'">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.935 6.0381L10.0987 3.43811C10.0257 3.33509 9.90237 3.27295 9.77042 3.27295H3.37364C3.24159 3.27295 3.11833 3.33509 3.04533 3.43811L1.20904 6.0381C1.11106 6.17625 1.12414 6.35857 1.24085 6.48285L6.27578 11.8741C6.35015 11.9543 6.45808 12.0001 6.57179 12.0001C6.68549 12.0001 6.79342 11.9543 6.86817 11.8738L11.9031 6.48249C12.0194 6.35857 12.0325 6.17625 11.935 6.0381ZM4.34802 6.60914L5.56831 9.99846L2.4029 6.60914H4.34802ZM7.4797 4.00915L8.00738 5.87331H5.13527L5.66295 4.00915H7.4797ZM7.96453 6.60914L6.5716 10.479L5.17867 6.60914H7.96453ZM8.79523 6.60914H10.7404L7.57501 9.99846L8.79523 6.60914ZM10.8751 5.87292H8.82197L8.29429 4.00875H9.55868L10.8751 5.87292ZM3.58492 4.00913H4.84931L4.32164 5.87329H2.26802L3.58492 4.00913Z" fill="#92400E"/>
              <path d="M2.85775 0L3.32074 1.19438L4.57194 1.63633L3.32074 2.07829L2.85775 3.27267L2.39476 2.07829L1.14355 1.63633L2.39476 1.19438L2.85775 0Z" fill="#92400E"/>
              <path d="M1.1428 2.72705L1.45146 3.5233L2.28559 3.81794L1.45146 4.11258L1.1428 4.90883L0.834138 4.11258L0 3.81794L0.834138 3.5233L1.1428 2.72705Z" fill="#92400E"/>
            </svg>
            Premium Plus
          </span>
          <!-- <span class="tags-box" *ngIf="!paymentDetails?.is_premium">Free</span>
          <span class="tags-box-premium" *ngIf="paymentDetails?.is_premium">Premium</span> -->
        </div>
        <div class="plagCheckHeading">
          <h1>Journal Finder</h1>
          <span (click)="credits()" class="credits-details-txt" 
            *ngIf="creditsDetails.credits + creditsDetails.purchased_credits != 0">
            Credits: {{ creditsDetails.credits + creditsDetails.purchased_credits || 0 }}
          </span>
          <span *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0"
          (click)="credits()" >Nil</span>

          <span *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0
                " class="autoEditBuyOption" (click)="addCredits()">Buy</span>
          <span class="referralReferralHeaderText" *ngIf="country!='IN'" (click)="showReferralRewardModal()">Rewards: <i>${{referralReward}}</i></span>
        </div>
      </div>
      <div class="col-4">
        <div class="media media-credits">
          <div class="media-body">
            <button [ngClass]="paymentDetails?.user_type == 'PREMIUM'? 'plag-check-upgrade-btn editorUpgradeButtonPremiumPlus' : paymentDetails?.user_type == 'BASIC' ? 'plag-check-upgrade-btn': '' " *ngIf="paymentDetails?.user_type !== 'PREMIUM_PLUS' && paymentDetails?.user_type !== 'PREMIUM_TRIAL' && paymentDetails?.user_type !== 'INSTITUTIONAL_ACCESS'" (click)="redirectToUpgradeToPremiumPage(true)" >
                    <svg *ngIf="paymentDetails?.user_type == 'BASIC'" width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.187 4.35161L9.41105 1.67033C9.34045 1.56408 9.22115 1.5 9.09353 1.5H2.90684C2.77913 1.5 2.65992 1.56409 2.58931 1.67033L0.813334 4.35161C0.718569 4.49408 0.731218 4.68211 0.844103 4.81027L5.71367 10.3701C5.78559 10.4528 5.88998 10.5 5.99995 10.5C6.10992 10.5 6.2143 10.4528 6.2866 10.3698L11.1562 4.8099C11.2687 4.6821 11.2813 4.49408 11.187 4.35161ZM3.84922 4.9405L5.02944 8.43581L1.96799 4.9405H3.84922ZM6.87805 2.25922L7.38839 4.18168H4.61062L5.12097 2.25922H6.87805ZM7.34695 4.9405L5.99977 8.93135L4.65259 4.9405H7.34695ZM8.15037 4.9405H10.0317L6.97023 8.43581L8.15037 4.9405ZM10.1619 4.18127H8.17623L7.66588 2.25881H8.88875L10.1619 4.18127ZM3.11118 2.2592H4.33405L3.8237 4.18165H1.83753L3.11118 2.2592Z" fill="#92400E"/>
                    </svg>
                    <svg *ngIf="paymentDetails?.user_type == 'PREMIUM'" width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.935 6.0381L10.0987 3.43811C10.0257 3.33509 9.90237 3.27295 9.77042 3.27295H3.37364C3.24159 3.27295 3.11833 3.33509 3.04533 3.43811L1.20904 6.0381C1.11106 6.17625 1.12414 6.35857 1.24085 6.48285L6.27578 11.8741C6.35015 11.9543 6.45808 12.0001 6.57179 12.0001C6.68549 12.0001 6.79342 11.9543 6.86817 11.8738L11.9031 6.48249C12.0194 6.35857 12.0325 6.17625 11.935 6.0381ZM4.34802 6.60914L5.56831 9.99846L2.4029 6.60914H4.34802ZM7.4797 4.00915L8.00738 5.87331H5.13527L5.66295 4.00915H7.4797ZM7.96453 6.60914L6.5716 10.479L5.17867 6.60914H7.96453ZM8.79523 6.60914H10.7404L7.57501 9.99846L8.79523 6.60914ZM10.8751 5.87292H8.82197L8.29429 4.00875H9.55868L10.8751 5.87292ZM3.58492 4.00913H4.84931L4.32164 5.87329H2.26802L3.58492 4.00913Z" fill="#92400E"/>
                        <path d="M2.85775 0L3.32074 1.19438L4.57194 1.63633L3.32074 2.07829L2.85775 3.27267L2.39476 2.07829L1.14355 1.63633L2.39476 1.19438L2.85775 0Z" fill="#92400E"/>
                        <path d="M1.1428 2.72705L1.45146 3.5233L2.28559 3.81794L1.45146 4.11258L1.1428 4.90883L0.834138 4.11258L0 3.81794L0.834138 3.5233L1.1428 2.72705Z" fill="#92400E"/>
                    </svg>

                    <span *ngIf="paymentDetails?.user_type == 'BASIC'">Upgrade</span>
                    <span *ngIf="paymentDetails?.user_type == 'PREMIUM'">Upgrade to Premium Plus</span>
            </button>
            <button class="plag-check-upgrade-btn institutional_btn" *ngIf="paymentDetails?.user_type&&paymentDetails?.user_type === 'INSTITUTIONAL_ACCESS'" >
              <img src="../../../assets/images/institutional.svg" alt="Browser Icon"/>
              <span>Institutional Access</span>
            </button>
            <!-- <p>
              <img src="../../../assets/images/reference-quality/Vector.svg" alt="vector" />
              Credits: <strong>
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits != 0"
                  (click)="credits()">{{ creditsDetails.credits + creditsDetails.purchased_credits || 0 }}</span>
                <span class="CreditsNumber" *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0"
                  (click)="credits()">Nil</span>
                <span *ngIf="creditsDetails.credits + creditsDetails.purchased_credits == 0
                " class="autoEditBuyOption" (click)="addCredits()">Buy</span>

              </strong>
            </p> -->
          </div>
          <div class="media-right">
            <button mat-icon-button [matMenuTriggerFor]="menu1" class="p-0">
              <span class="name-initial">{{profileName}}</span>
            </button>
            <mat-menu #menu1="matMenu" class="profile-menu personalize_top_menus">
              <a mat-menu-item [routerLink]="['/dashboard/profile']">
                <img class="img-fluid" src="../../../../assets/images/Icon_ManageProfile.svg" alt="logo favicon">
                <span> Account Settings</span>
              </a>
              <button mat-menu-item (click)="signOut()"><img class="img-fluid"
                  src="../../../../assets/images/Icon_Logout.svg" alt="logo favicon">
                Logout</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="section-md section-first">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title">
          <!-- <h3 class="x-title">Journal Finder</h3> -->
          <p>3 easy steps to find the best journal to publish</p>
          <div class="rc-line">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row match-box">
      <div class="col-lg-4 col-md-4 col-12 dflex">
        <div class="c-card steps-card">
          <h3>Use abstract</h3>
          <p>Enter the abstract of your paper in the text area. Next, click on "Search Journal".</p>
          <span class="number">01</span>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12 dflex">
        <div class="c-card steps-card">
          <h3>We will do the magic</h3>
          <p>Our Al engine will search through millions of published abstracts and full papers to find the top five journals that publish similar articles.
            <span class="number">02</span>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12 dflex">
        <div class="c-card steps-card">
          <h3>Choose from the top 5 journals</h3>
          <p>View articles most similar to your paper and publication trend. Filter the results by multiple parameters or search for your own journal.</p>
          <span class="number">03</span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-md bg-grey">
  <div class="container">
    <div class="row">
      <!-- <div class="col-12">
        <h3 class="x-title">Submit file</h3>
      </div> -->
      <div class="col-12">
        <div class="mb-20 upload_section">
          <!-- Paste abstarct Section-->
          <div class="abs-pasting" id="absPasting">
            <div class="check-ref-body">
              <textarea placeholder="Paste your abstract in here..." [(ngModel)]="abstractText" (change)="focusAbstractTxt(0);" (paste)="focusAbstractTxt(1);"
              (keyup)="focusAbstractTxt(3);"></textarea>
            </div>
            <div class="check-ref-footer">
              <div class="btn-reference">
                <div class="row align-items-center">
                  <div class="col-6">
                    <!-- <p>10 Credits <span>per Search</span></p> -->
                  </div>
                  <div class="col-6 text-right">
                    <button type="button" (click)="cancelAbsBtn()" class="button-cancel">Cancel</button>
                    <button type="button" data-toggle="modal" [attr.data-target]="totalCredit<creditLimit && abstractText.trim().length? '#insuffecientModal' : ''" (click)="searchJournal()" class="button">Search Journal</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Loader -->
            <div class="drag-loader" [ngClass]="uploadStatus ? '' : 'hide_upload_loader' ">
              <svg class="spinner" viewBox="0 0 50 50">
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
              </svg>
              <p class="uploading">Generating report...</p>
            </div><!-- Loader Ends -->
          </div><!-- Paste abstarct Section Ends-->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-md">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-8">
        <h3 class="x-title">Your Results</h3>
      </div>
      <div class="col-12 col-sm-4">
        <div class="search-input form-group">
          <input type="text" class="form-control" [(ngModel)]="filterModel" placeholder="Search" />
          <span class="search-icon"><i class="fa fa-search"></i></span>
        </div>
      </div>
      <div class="col-12">
        <ng-container>
          <div class="shine-loader" [ngClass]="loaderStatus ? '' : 'shine_loader_hide'">
            <div class="full-shine">
              <span class="shine lines"></span>
            </div>
            <div class="full-shine">
              <span class="shine lines"></span>
            </div>
            <div class="full-shine">
              <span class="shine lines"></span>
            </div>
            <div class="full-shine">
              <span class="shine lines"></span>
            </div>
          </div>
          <ul class="upload-wrapper" *ngFor=" let file of processedAbstractList | filterByFileName: filterModel">
            <li class="upload-files" [ngClass]="file.is_report_expire ? 'buy-credits' : ''">
              <div class="row no-margin w-100 align-items-center">
                <div class="col-7">
                  <div class="media">
                    <div class="media-left">
                      <img src="../../../assets/images/abstract.svg" alt="file" />
                    </div>
                    <div class="media-body">
                      <h3>{{ file.file_name }}</h3>
                      <p *ngIf="file.file_status === 'SUCCESS'">Results generated on: {{ file.created_on | date: "medium" }}
                      <p *ngIf="file.file_status != 'SUCCESS'">Uploaded on: {{ file.created_on | date: "medium" }} </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="file.file_status === 'SUCCESS'" class="col-2 text-center">
                  <!-- <span class="count d-block"><i>Report <span *ngIf="file.is_report_expire">expired</span>
                    <span *ngIf="!file.is_report_expire">expires</span> on</i><br> <span [ngClass]="file.is_report_expire ? 'expire_date' : checkBeforeExpiryStatus(file.expire_on) ? 'yellow': '' ">{{file.expire_on | date: "mediumDate" }} </span></span> -->
                </div>


                <div *ngIf="file.file_status === 'SUCCESS'" class="col-3 text-right">
                  <button class="button button-filled" (click)="logViewResult(file.file_id)" [routerLink]="['/journal-finder/report',file.file_id]">View Results</button>
                </div>


                <!-- IN PROGRESS LOADER-->
                <div class="col-5"
                  *ngIf="(file.file_status !== 'FAILED' && file.file_status !== 'SUCCESS' && file.file_status !== 'OVER_LIMIT' && file.file_status !== 'WAITING_IN_QUEUE' && file.file_status !== 'ON_HOLD')">
                  <div class="inprogress_file">
                    <div class="checkingloader">
                      <div class='loader--text'></div>
                      <div class='loader--dot'></div>
                      <div class='loader--dot'></div>
                      <div class='loader--dot'></div>
                    </div>
                    <p>In progress</p>
                  </div>
                </div>

                <div class="col-5 file_download_fail" *ngIf="file.file_status === 'FAILED'">
                  <button>
                      <img src="../../../../assets/images/Icon_Info_redColor.svg">
                  </button>
                  <span>Processing failed</span>
              </div>

                <div class="col-6" *ngIf="file.file_status === 'WAITING_IN_QUEUE'">
                  <p class="in-queued">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8 4V8L10 10" stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    Queued
                  </p>
                </div>

              </div>
            </li>
          </ul>
        </ng-container>

        <ng-container *ngIf="processedAbstractList.length === 0 && !loaderStatus">
          <ul class="no_file_search_section" style="border:none">
            <li>
              <p style="color: grey;"><i>No reports</i></p>
            </li>
          </ul>
        </ng-container>

        <ng-container
          *ngIf="(processedAbstractList | filterByFileName: filterModel).length <= 0 && processedAbstractList.length > 0">
          <ul class="no_file_search_section">
            <li>
              <p>
                Sorry, we couldn't find any abstract named "{{ filterModel }}". Please try a different search.
              </p>
            </li>
          </ul>
        </ng-container>

      </div>
    </div>
  </div>
</section>


<!-- Insuffecient credits Modal -->
<div class="modal"  id="insuffecientModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <div class="cs-modal-content">
          <div class="icon">
            <span>
              <img src="../../../../assets/images/reference-quality/cancel.svg" width="40" height="40" alt="cancel" />
            </span>
          </div>
          <h3>Insufficient Credits</h3>
          <p>You do not have enough credits. Please add credits to generate this results.</p>
          <a (click)="addCredits()" class="md-btn" data-dismiss="modal">Add Credits</a>
          <div class="mt-3 text-center">
            <a href="javascript:void(0)" (click)="logCancelInsuffeicent()" class="cancel-link"
              data-dismiss="modal">Cancel</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>