import { Component, OnInit  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { SharedBrowserExtensionService } from 'src/app/_services/shared-browser-extension.service';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-browser-extension',
  templateUrl: './browser-extension.component.html',
  styleUrls: ['./browser-extension.component.scss']
})
export class BrowserExtensionComponent implements OnInit {
  userId: any;
  styleGuide: string = 'NONE';

  styleGuideDispay = {
    "NONE": "None",
    "AMA": "AMA (11th ed)",
    "APA": "APA (7th ed)",
    "ACS": "ACS (2nd ed)",
    "AGU": "AGU (2017 ed)"
  }
 
  constructor(
    public subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    public sharedService: SharedBrowserExtensionService,
    public networkCalls: OnlineEditorService,
    public fb: FormBuilder,
    public amplitudeService: AmplitudeService,
  ) {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.amplitudeService.setUserId(this.userId);

    /**activeRoute.firstChild.paramMap.subscribe(
      (params: ParamMap): void => {
        this.transactionPayload = params.get("transactionPayload");
        if (this.transactionPayload != undefined) {
          this.paymentResponceObj = JSON.parse(atob(this.transactionPayload));
          this.transactionStatus = true;
          //this.confirmOrderRequest(this.paymentResponceObj)
          setTimeout(() => {
            this.modalService.open(this.paymentStatus, { size: 'md paymentPopup', backdrop: 'static' });
          }, 4000);
        }
      }
    );*/
  }
  ngAfterViewInit() {
  }


  /**getSelectedDocumentType() {
    this.subjectiveBehaviorSharedService.getDocTypeObject.subscribe(document_type => {
      this.document_type = document_type.selected_document_type;
      this.docTypeObject = {
        selected_document_type: this.document_type,
        selected_paper_type: document_type.selected_paper_type,
        user_added_subject_area: document_type.user_added_subject_area,
        selected_subject_area: document_type.selected_subject_area,
        subject_area_list: document_type.subject_area_list

      }
    });
  }
  getSelectedStyleguide() {
    this.subjectiveBehaviorSharedService.getStyleGuide.subscribe(styleguide => {
      this.styleGuide = styleguide[0] ? styleguide[0] : 'NONE'
    })
  }*/

  /**confirmOrderRequest(paymentResponceObj) {
    let payload = {}
    payload['OrderRefNum'] = paymentResponceObj['orderRefNum'];
    payload['SubdomainId'] = paymentResponceObj['subDomainId'];
    payload['paymentInfo'] = {}
    payload['paymentInfo']['status'] = paymentResponceObj['status'];
    payload['paymentInfo']['transactionId'] = paymentResponceObj['transactionId'];
    payload['paymentInfo']['amount'] = paymentResponceObj['amount'];
    this.networkCalls.submitOrderMin(this.userId, payload).subscribe(res => {
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }*/

  ngOnInit() {
  }

  styleGuideChange() {
    this.subjectiveBehaviorSharedService.changeStyleGuide(this.styleGuide, true);
  }

  changeRoute() {
    document.location.href = document.location.origin + "/extension/main/" + this.sharedService.currentFileId;
  }

  openLangPrefModal() {
    this.sharedService.openLanguagePreferences()
  }
  
}