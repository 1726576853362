import { Component, OnInit } from '@angular/core';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { JournalScopeMatch } from 'src/app/_interfaces/author-one/journal-check';

@Component({
  selector: 'app-journal-scope-match',
  templateUrl: './journal-scope-match.component.html',
  styleUrls: ['./journal-scope-match.component.scss']
})
export class JournalScopeMatchComponent implements OnInit {

  journalScopeMatch: JournalScopeMatch;
  isDocLocked:boolean;
  targetedJournal: boolean

  constructor(private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService) {
    this.getReportData();
  }

  ngOnInit() {
  }

  getReportData(){
    this.subjectiveBehaviorSharedService.getA1AllCheckReportData.subscribe(reportData=>{
        this.journalScopeMatch = reportData.journal_scope_match;
        if (this.journalScopeMatch) {
          if (typeof(this.journalScopeMatch.required_to_show) == "undefined"){
            this.journalScopeMatch.required_to_show = true
          }
          // this.isDocLocked = ((reportData.pricing == "FREE") && this.journalScopeMatch.is_premium); //old one pricing
          this.targetedJournal = this.journalScopeMatch.scope.name != ""
          this.isDocLocked = (this.journalScopeMatch.is_premium);
          if(!this.isDocLocked && !this.journalScopeMatch.mega_support){
            this.journalScopeMatch.suggestions = this.journalScopeMatch.suggestions.filter(
              suggest => {
                return suggest.score >= 40;
              }
            );
          }
        }
    });
  }

  scorePercent(score) {
    if(Math.round(score) < 40){
      return  Math.round(score)+"% <span class='journal_match_colored'>Poor Match</span>"
    }
    if(Math.round(score) >= 40 && Math.round(score) < 80) {
      return  Math.round(score)+"% <span class='journal_match_colored'>Average  Match</span>"
    }
    if(Math.round(score) >= 80){
      return  Math.round(score )+"% <span class='journal_match_colored'>Excellent  Match</span>"
    }
  }
}

