import { ApplicationRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plag-buy-credits-modal',
  templateUrl: './plag-buy-credits-modal.component.html',
  styleUrls: ['./plag-buy-credits-modal.component.scss']
})
export class PlagBuyCreditsModalComponent implements OnInit {
  userId
  userData
  creditsDetails: any;
  freeStatus: boolean = false;
  user_decoded_id: any;
  free_score_reset_date:any;
  remainig_free_score: number;
  total_free_score: number;
  required_credits:number;
  plag_type:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private sharedService: SharedService,
    private networkCalls: DashboardService,
    private amplitudeService: AmplitudeService,
    private dialogRef: MatDialogRef<PlagBuyCreditsModalComponent>,
    private appRef: ApplicationRef,
    ) { 
      this.required_credits = data.required_credits_purchase;
      this.plag_type =data.plag_type;
      this.appRef.tick();
    }
 
  
  
  plagiarism_free_score : boolean = false;
  ngOnInit() {
    this.plag_type = this.plag_type == 1 ? 'Free Plagiairsm check' : (this.plag_type == 3 ? 'Trinka Plagiarism Check':'ithenticate Plagiarism Check')
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    let data = {
      "user_id": this.userId
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.getCreditsDetails(true);
  }


  getCreditsDetails(isOnInit: boolean = false) {
    this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
      if (response["status"]) {
        this.creditsDetails = response["data"];
        this.networkCalls.storeUserCreditDetails(this.creditsDetails);
        this.user_decoded_id = this.creditsDetails["user_id"];
        this.plagiarism_free_score = this.creditsDetails["plagiarism_free_score"] == 0 ? true : false;
        this.freeStatus = this.creditsDetails["plagiarism_free_score"] > 0 ? true : false;
        this.free_score_reset_date = this.creditsDetails["next_renewal_date"];
        this.total_free_score = this.creditsDetails["user_type"] == "BASIC" ? 1 : 2;
        this.remainig_free_score = this.creditsDetails["plagiarism_free_score"] ? this.creditsDetails["plagiarism_free_score"] : 0;
        if (isOnInit) {
          this.amplitudeService.logAmplitude('Plag_toolpage_view', {
            'userID': this.userId,
            'free_credit_balance': this.creditsDetails.credits,
            'premium_credit_balance': this.creditsDetails.purchased_credits
          });
        }
        this.appRef.tick();
      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  addCredits() {

    let redirct_url = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);

    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.amplitudeService.logAmplitude('Plag_Buy_Credit', {
      'Location 1': 'plagiarism_file_upload_pageview',
      'free_credits': this.creditsDetails.credits,
      'Credits_purchased': this.creditsDetails.purchased_credits
    });
    window.open(redirct_url + '/user/credit/' + this.userData, "_self");
    this.close();
  }
  close() {
    this.dialogRef.close();
    this.appRef.tick();
  }
}
