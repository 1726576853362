import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { FilesList } from 'src/app/_interfaces/dashboard/file-list';
import { SharedService } from 'src/app/_services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  fileListMyDrive: FilesList;
  userId: string;
  constructor(
    public networkCalls: DashboardService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    this.getFilesByUserCall();
  }
  getFilesByUserCall() {
    this.spinner.show();
    this.networkCalls.getFilesByUser(this.userId).subscribe(files => {
      this.fileListMyDrive = files;
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  deleteFile(file) {
    this.spinner.show();
    this.networkCalls.deleteFileById( this.userId,file.file_id).subscribe(res => {
      this.getFilesByUserCall();
      this.spinner.hide();
      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }
}
