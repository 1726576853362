<div class="renameFolderPopup quality-modal">
    <h1>Find the Right Journal to Publish Your Paper</h1>
    <mat-dialog-content>
        <div>Ensure maximum citations by submitting your paper to the right journal.</div>
        <div>Compare your paper's concepts against millions of past publications and publication trends.</div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="folderRenameFooter">
            <button class="cancelRenameButton" (click)="submit(0)">Cancel</button>
            <button class="CreateRenameButton" (click)="submit(1)">Go to Journal Finder</button>
        </div>
    </mat-dialog-actions>
</div>