import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { VerifiedUserService } from 'src/app/_services/networkcalls/verified-user.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';
import { CreditDetailsComponent } from '../../dashboard/credit-details/credit-details.component';

declare function wisepop():any;
@Component({
  selector: 'app-journal-finder-tool',
  templateUrl: './journal-finder-tool.component.html',
  styleUrls: ['./journal-finder-tool.component.scss']
})
export class JournalFinderToolComponent implements OnInit {

  userId: any;
  userData: any;
  creditsDetails: any;
  constructor(
    private sharedService: SharedService,
    private networkCalls: DashboardService,
    private verifiedService: VerifiedUserService,
    private amplitudeService: AmplitudeService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    wisepop();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    let data = {
      "user_id" : this.userId
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.getCreditsDetails(true);
    this.networkCalls.setDashboardCloudFileDetails(null);
    this.networkCalls.setDashboardLocalFileDetails(null);
    this.verifiedService.getSelectedProfileData();
  }

  getCreditsDetails(isOnInit:boolean= false){
    this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
      if (response["status"]) {
        this.creditsDetails = response["data"];
        if(isOnInit){
          this.amplitudeService.logAmplitude('JFR_Tool_pageview',{
            'Credit Balance Free': this.creditsDetails.credits,
            'Credit Balance Purchased': this.creditsDetails.purchased_credits
          });
        }
      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  startJournalFinder(){
    this.amplitudeService.logAmplitude('JFR_GetStarted_CTA',{
      'Credit Balance Free': this.creditsDetails.credits,
      'Credit Balance Purchased': this.creditsDetails.purchased_credits
    });
    window.open("/journal-finder" , "_blank");
  }

  credits() {
    if (this.creditsDetails != null) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData: this.creditsDetails,
      };

      this.dialog.open(CreditDetailsComponent, dialogConfig);
    }
  }

  addCredits() {
    // this.amplitudeService.logAmplitude("RFQ_Insuff-Credit_AddCredits", {
    //   'Credit Balance Free': this.creditsDetails.credits,
    //   'Credit Balance Purchased': this.creditsDetails.purchased_credits,
    // });
    let redirct_url = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url + "/user/credit/" + this.userData, "_self");
  }
}
