<div class="errorPanelSpinner">
    <!-- <div class='checkingloader'>
        <div class='loader--text'></div>
        <div class='loader--dot'></div>
        <div class='loader--dot'></div>
        <div class='loader--dot'></div>
    </div> -->
    <div class="stage">
      <span>Scanning</span>
      <div class="dot-flashing"></div>
    </div>
  </div>