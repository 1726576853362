import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class ReferenceCheckService {

  constructor(public http: HttpClient) { }

  reUploadOnholdQualityFile(fileId,data){
    return this.http.put(environment.refcheck_api+"/quality/document/"+fileId,data);
  }

  getRefQualityFileList(emailId: string): Observable<any> {
    const params = new HttpParams().set("email_id",btoa(emailId));
    return this.http.get<any>(environment.refcheck_api + "/quality/v2/document", {params})
  }

  getRefQualityDocument(documentId:string,emailId: string): Observable<any> {
    const params = new HttpParams().set("email_id",btoa(emailId));
    return this.http.get<any>(environment.refcheck_api + "/quality/v2/document/"+documentId, {params})
  }

  getRefQualityReport(documentId:string,emailId: string): Observable<any> {
    const params = new HttpParams().set("email_id",emailId);
    return this.http.get<any>(environment.refcheck_api  + "/quality/document/"+documentId+"/overview", {params})
  }

  getPoorCitationData(documentId:string, emailId: string, doi: string, pageNo: any): Observable<any> {
    const params = new HttpParams().set("email_id",emailId).set("pageNo",pageNo).set("pageSize","10").set("ref_doi",doi);
    return this.http.get<any>(environment.refcheck_api + "/quality/document/"+documentId+"/poorlycitations", {params})
  }

  uploadAutoRefQualityFile(file: FormData): Observable<any> {
    return this.http.post<any>(environment.refcheck_api + "/quality/document", file)
  }
}
