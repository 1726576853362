<div class="container filter-modal mat-typography-config p-0" fxLayout.xs="column" fxLayoutAlign="left"
    fxLayoutGap.xs="0" fxLayout="row">
    <div fxFlex="100%" fxFlexOrder="1">
        <div>
            <mat-list>
 
                <mat-list-item>
                  <mat-checkbox
                  [(ngModel)]="master_checked"
                  (change)="master_change()"
                  ><b>Check/ Uncheck All Categories</b></mat-checkbox>
                </mat-list-item>
                
                <mat-list-item *ngFor="let item of sharedService.filterCheckList">
                    <mat-checkbox
                    [(ngModel)]="item.checked"
                    [disabled]="item.disabled"
                    [labelPosition]="item.labelPosition"
                    (change)="list_change()"
                    >{{ item.name }}</mat-checkbox>
                    <span class="sub-error-count">{{item.errorCount}}</span>
                    <mat-divider></mat-divider>
                </mat-list-item>
         
              </mat-list>
        </div>
        <div class="close-btn">
            <div class="close-btn-inner">
                <mat-dialog-actions>
                    <button [mat-dialog-close]="'asdf()'" cdkFocusInitial class="close-btn-font">&times;</button>
                </mat-dialog-actions>
            </div>
        </div>
    </div>
</div>