<div class="bg-white">
    <div class="container-fluid">
      <div class="row outer_scroll_sections full_page_editor" id="outer_scroll_bar">
        <div class="col-md-8 editor_section fullpage_editor_rightside">
          <div class="filter_toggle_button">
            <button (click)="toggleFlag = !toggleFlag" [ngClass]="toggleFlag? 'cardsOpen': ' '" title="Click to Expand Error Card">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 447.243 447.243" style="enable-background:new 0 0 447.243 447.243;"
                xml:space="preserve">
                <g>
                  <g>
                    <path d="M420.361,192.229c-1.83-0.297-3.682-0.434-5.535-0.41H99.305l6.88-3.2c6.725-3.183,12.843-7.515,18.08-12.8l88.48-88.48
              c11.653-11.124,13.611-29.019,4.64-42.4c-10.441-14.259-30.464-17.355-44.724-6.914c-1.152,0.844-2.247,1.764-3.276,2.754
              l-160,160C-3.119,213.269-3.13,233.53,9.36,246.034c0.008,0.008,0.017,0.017,0.025,0.025l160,160
              c12.514,12.479,32.775,12.451,45.255-0.063c0.982-0.985,1.899-2.033,2.745-3.137c8.971-13.381,7.013-31.276-4.64-42.4
              l-88.32-88.64c-4.695-4.7-10.093-8.641-16-11.68l-9.6-4.32h314.24c16.347,0.607,30.689-10.812,33.76-26.88
              C449.654,211.494,437.806,195.059,420.361,192.229z" />
                  </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
              </svg>
            </button>
          </div>
          <div class="full_page_top_bar">
            <img class="trinka_logo" src="../../../../assets/images/Trinka_Logo.svg" alt="">
          </div>
          <div class="loading loader" #loading>
            <div>
              <ck-editor name="editor1" [(ngModel)]="editorValue" [config]="config" language="en"
                (change)="onChange($event)" (focus)="onFocus($event)" [fullPage]="false" (ready)="onEditorReady()">
              </ck-editor>
            </div>
  
            <div class="statusBar with_new_update_StatusBar">
              <div class="statusBar_content">
                <div class="word_count" style="display: none;">
                  <p class="notes">{{sharedService.whiteListDisplay()?"Trinka editor": "Editor"}} supports basic
                    formatting only.</p>
                  <button data-tooltip alt="Double-column text, equations, images, and tables may not appear correctly.
                Downloaded files may appear differently on word processors.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                MS Word comments are not supported on Trinka. So they will not appear here and in the downloaded file.
                ">
                    <img src="../../../../assets/images/Icon_Info.svg" alt="Alerts">
                  </button>
                </div>
  
                <div class="saving_section" *ngIf="savingSection">
                  <p *ngIf="saving" class="saving_text">Saving...</p>
                  <p *ngIf="!saving"><i *ngIf="!saving" class="fa  fa-check"></i> Saved</p>
                </div>
  
                <div class="select_language" style="visibility: hidden;">
                  <div class="enable_editing">
  
                    <strong class="blue_dots"></strong>
                    <mat-form-field class="custom_dropdown_sections">
                      <mat-select [(ngModel)]="fileHolder.data.language" (ngModelChange)="onLanguageTypeSelect()">
                        <mat-option value="US">
                          American English
                        </mat-option>
                        <mat-option value="UK">
                          British English
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
  
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
  
        <div class="col-md-4 filter_outer_sections fullpage_editor_leftside" [ngClass]="toggleFlag? 'cardsOpen': ' '">
          <div class="full_page_top_bar text-right" style="width: 34%;">
          </div>
          <div class="filter errorPanelToolbar">
            <div class="filer_outer_sections"  style="display: none;">
              <div class="alert_box_sections">
                <span class="all_alerts_section">
                </span>
                <span class="totalCount">
                  <span style="padding: 0 6px 0 0px;font-size: 14px;">Alerts</span>
                  <span style="font-weight: 600;font-size: 14px;">{{displayVisibleErrorCount()}}</span>
                </span>
              </div>
  
              <div class="categories_type_sections">
                <span class="selecteCategories">
                  <button mat-button [matMenuTriggerFor]="menu" class="file_manager_dropdown" title="Filter Categories">
                    <img src="../../../../assets/images/Icon_Filter.svg" alt="Filter Categories">
                  </button>
                  <mat-menu #menu="matMenu" class="categoires_sub_menus">
                    <div class="categories_box">
                      <div>
                        <mat-list>
  
                          <mat-list-item>
                            <mat-checkbox [(ngModel)]="master_checked" [(indeterminate)]="master_indeterminate"
                              (change)="master_change()"><b>Select all categories</b></mat-checkbox>
                          </mat-list-item>
                          <ng-container *ngFor="let item of sharedService.filterCheckList">
                            <mat-list-item *ngIf="item.display">
                              <mat-checkbox [(ngModel)]="item.checked" [disabled]="item.disabled"
                                [labelPosition]="item.labelPosition" (change)="list_change()">{{ item.name }}
                              </mat-checkbox>
                              <span class="sub-error-count">{{item.errorCount}}</span>
                              <mat-divider></mat-divider>
                            </mat-list-item>
                          </ng-container>
  
                        </mat-list>
                      </div>
                    </div>
                  </mat-menu>
  
                </span>
  
              </div>
              <div *ngIf="selectedCategories() === 4 && sharedService.featurePermission.data.accept_all_alerts"
                class="acceptButton">
                <button [disabled]="requestToElementMap.size>0" (click)="acceptAllCards()">
                  {{alertList?.length > 0 ? !onlyNonCTACardsLeft(alertList) ? "Accept all" : "Add comments" : "Accept all"}}</button>
              </div>
            </div>
          </div>
  
          <div class="errorPanel extension_editor_section browserExtensionEditor" id="error_windows">
            <div class="enable_editing_sections" *ngIf="readOnly" ng-clock>
              <img src="../../../../assets/images/Enable_editing_img.png" alt="">
              <h3>File locked for editing</h3>
              <p>This file is open elsewhere and therefore locked for editing here. If you enable editing here, it will be locked for editing anywhere else it is open.</p>
              <button class="enable_editing1" (click)="enableSendingRequest()">Enable editing</button>
            </div>
  
            <div class="No_error_found" *ngIf="displayNoError()">
              <!-- <img src="../../../../assets/images/NoIssuesFound.png" alt=""> -->
              <h3>No language issues found</h3>
              <p>Trinka has checked your text thoroughly and currently has no suggestions to improve the language.</p>
            </div>
  
            <mat-accordion *ngIf="alertList.length">
              <ng-container class="errorContainer"
                *ngFor="let item of alertList| filterByCatergoryExtension: filterCategories(); let j = index" [attr.data-index]="j">
                <mat-expansion-panel class="mat-expansion-panel-spacing mb-2 mt-0 error-type fade_in_left"
                  *ngIf="item.isVisible" [ngClass]="item.alert_class" (opened)="openCard(item)" (closed)="closeCard(item)"
                  [expanded]="(item.isExpanded)">
                  <mat-expansion-panel-header class="cards_headers">
                    <mat-panel-title>  
                      <ng-container *ngIf="!item.isExpanded">
  
                        <div>
                          <strong class="strong_text"> {{item.text}}</strong>
                        </div>
                        <span *ngIf="item.suggestions[0].type == 1" class="addSpace_text">(Grammar)</span>
                        <span *ngIf="item.suggestions[0].type == 2" class="addSpace_text">(Spelling)</span>
                        <span *ngIf="item.suggestions[0].type == 3" class="addSpace_text">(Writing Advisor)</span>
                        <span *ngIf="item.suggestions[0].type == 4" class="addSpace_text">(Enhancement)</span>
                        <span *ngIf="item.suggestions[0].type == 5" class="addSpace_text">({{styleGuideDispay[styleGuide]}} Style)</span>
                      </ng-container>
  
                      <div *ngIf="item.isExpanded">
                        <strong *ngIf="item.suggestions[0].type == 1">Grammar</strong>
                        <strong *ngIf="item.suggestions[0].type == 2">Spelling</strong>
                        <strong *ngIf="item.suggestions[0].type == 3">Writing Advisor</strong>
                        <strong *ngIf="item.suggestions[0].type == 4">Enhancement</strong>
                        <strong *ngIf="item.suggestions[0].type == 5">{{styleGuideDispay[styleGuide]}}
                          Style</strong>
                      </div>
                    </mat-panel-title>
    
                  </mat-expansion-panel-header>
  
                  <div class="error-main cards_body" *ngFor="let suggestion of item.suggestions; let k = index">
                    <div class="margin_bottoms">
                      <div class="error-inner">
                        <div class="dictionary_sections"
                          *ngIf="suggestion.type == 2 && sharedService.featurePermission.data.add_to_dictionary">
                          <button class="error-link add_dictionary_button" [ngClass]="item.alert_class"
                            (click)="muteCard(item,suggestion, k,j)" *ngIf="k == 0"><i>+</i> Add to dictionary </button>
                        </div>
                        <div
                          *ngIf="(suggestion.suggestion == '' && suggestion.type == 3 || suggestion.type == 4) && !suggestion.cta_present">
                          <label> {{item.text}}</label>
                        </div>
                        <div *ngIf="suggestion.suggestion != '' && suggestion.cta_present" class="correction_work">
                          <b><s>{{item.text}}</s></b>
                          <span class="arrow">
                            <img src="assets/images/arrow.png" alt="">
                          </span>
                          <label class="error-link" [ngClass]="item.alert_class"
                            (click)="acceptCard(item,suggestion, k,j)">{{suggestion.suggestion}}
                          </label>
                        </div>
  
                        <div *ngIf="suggestion.suggestion == '' && suggestion.cta_present">
                          <label class="error-link" [ngClass]="item.alert_class"
                            (click)="acceptCard(item,suggestion, k,j)">Delete
                            '<span>{{item.text}}</span>'</label>
                        </div>
  
                      </div>
                      <div class="error-comment">{{suggestion.comment}}</div>
                    </div>

                    <div class="Optional_buttons" *ngIf="k == 0">
                      <mat-panel-description>
                        <span (click)="ignoreCard(item, j)" title="Delete">
                          <img src="../../../../assets/images/delete_icon.svg" alt="">
                        </span>
                      </mat-panel-description>

                      <span class="thumbsDown" (click)="rejectSuggestions(item,j)" title="Incorrect suggestion">
                        <img src="../../../../assets/images/dislike_icon.svg" alt="">
                      </span>
                    </div>
                  </div>
  
                </mat-expansion-panel>
              </ng-container>
              <div class="displayNone_moblie" style="height:200px"></div>
            </mat-accordion>
          </div>
          <div *ngIf="IS_CONNECTED">
            <div class="errorPanelSpinner" *ngIf="requestToElementMap.size>0">
              <div class='checkingloader'>
                <div class='loader--text'></div>
                <div class='loader--dot'></div>
                <div class='loader--dot'></div>
                <div class='loader--dot'></div>
              </div>
            </div>
          </div>
  
          <div class="errorPanelSpinner network_text" *ngIf="!IS_CONNECTED">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p>Internet connection is unavailable.</p>
          </div>
          <div class="errorPanelSpinner network_text" *ngIf="IS_CONNECTED && trinkaOnHold">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p>Experiencing a surge in usage! Please wait for results.</p>
          </div>
        </div>
      </div>
    </div>