import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WebsiteComponent } from './website.component';
import { PricingComponent } from 'src/app/components/pricing/pricing.component';
import { UseCaseComponent } from 'src/app/components/use-case/use-case.component';
import { EnterpriceComponent } from 'src/app/components/enterprice/enterprice.component';
import { FeaturesComponent } from 'src/app/components/features/features.component';
import { MainComponent } from 'src/app/components/main/main.component';
import { CompareWithComponent } from 'src/app/components/compare-with/compare-with.component';
import { MsWordAppComponent } from 'src/app/components/ms-word-app/ms-word-app.component'
import { WebAppComponent } from 'src/app/components/web-app/web-app.component';
import { ExamplesComponent } from 'src/app/components/examples/examples.component';
import { ContactUsComponent } from 'src/app/components/contact-us/contact-us.component';
import { AboutUsComponent } from 'src/app/components/about-us/about-us.component';
import { WorkWithUsComponent } from 'src/app/components/work-with-us/work-with-us.component';
import { HelpCenterComponent } from 'src/app/components/help-center/help-center.component';
import { DataSecurityComponent } from 'src/app/components/data-security/data-security.component';
import { MediaComponent } from 'src/app/components/media/media.component';
import { PrivacyPlicyComponent as PrivacyPolicyComponent } from 'src/app/components/privacy-plicy/privacy-plicy.component';
import { TermofServicesComponent as TermsofServicesComponent } from 'src/app/components/termof-services/termof-services.component';

const routes: Routes = [
  { path: '', component: WebsiteComponent,children:[
    { path: '', redirectTo: 'main', pathMatch:'full'},
    { path: 'main', component: MainComponent },
    { path: 'main/:open', component: MainComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'usecase', component: UseCaseComponent },
    { path: 'enterprise', component: EnterpriceComponent },
    { path: 'features', component: FeaturesComponent },
    { path: 'compare', component: CompareWithComponent },
    { path: 'ms-word-app', component: MsWordAppComponent },
    { path: 'web-app', component: WebAppComponent },
    { path: 'examples', component: ExamplesComponent },
    { path: 'contactUs', component: ContactUsComponent },
    { path: 'aboutUs', component: AboutUsComponent },
    { path: 'workWithUs', component: WorkWithUsComponent },
    { path: 'helpCenter', component: HelpCenterComponent },
    { path: 'dataSecurity', component: DataSecurityComponent },
    { path: 'media', component: MediaComponent },
    { path: 'privacypolicy', component: PrivacyPolicyComponent },
    { path: 'termsOfServices', component: TermsofServicesComponent },
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebsiteRoutingModule { }
