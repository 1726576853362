import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-editor-plagiarism-modal',
  templateUrl: './editor-plagiarism-modal.component.html',
  styleUrls: ['./editor-plagiarism-modal.component.scss']
})
export class EditorPlagiarismModalComponent implements OnInit {

  constructor(private dialogRef : MatDialogRef<EditorPlagiarismModalComponent>,
    @Inject(MAT_DIALOG_DATA) creditDetails) { 

      // console.log(creditDetails);
    }

  ngOnInit() {
  }

  submit(){
    this.dialogRef.close(1);
  }
}
