import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PluginsService {

  constructor() { }

  handleLogin(payload){
    const tokenPayload = {
      type:"login",
      actions:["save.token","update.isLoggedIn"],
      target:"bg",
      data:payload
    }
    this.sendMessageToBrowserExtension(tokenPayload);
  }

  handleSignUp(payload){
    const tokenPayload = {
      type:"signup",
      actions:["save.token","update.isLoggedIn"],
      target:"bg",
      data:payload
    }
    this.sendMessageToBrowserExtension(tokenPayload);
  }


  handleLogout(){
    const tokenPayload = {
      type:"logout",
      actions:["delete.token","update.isLoggedIn"],
      target:"bg",
    }
    this.sendMessageToBrowserExtension(tokenPayload);
  }

  
  handleRefreshToken(payload){

    const tokenPayload = {
      type:"refresh_token",
      actions:["save.token","update.isLoggedIn"],
      target:"bg",
      data:payload
    }
    this.sendMessageToBrowserExtension(tokenPayload);
  }


  sendMessageToBrowserExtension(tokenPayload){
    try {
      chrome.runtime.sendMessage(environment.chrome_extension_id, tokenPayload);
    }catch(error){
      console.log("Error while send message to chrome extension: ",error)
    }

    try {
      window.postMessage(tokenPayload,"*")
    }catch(error){
      console.log("Error while send message to firefox extension:",error)
    }

  }

  async isChromeExtensionInstalled() {
    return new Promise((resolve, reject) => {
      let link = document.createElement('link');
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("type", "text/css");
      link.onload = () => {
        console.log('Installed');
        resolve(false);
      };
      link.onerror = () => {
        console.log('Not Installed');
        reject(true);
      };
      link.setAttribute("href", `chrome-extension://${environment.chrome_extension_id}/src/css/Trinka-popup.styles.css`);
      document.getElementsByTagName("head")[0].appendChild(link);
    });
  }
  
  async isFirefoxExtensionInstalled() {
    // TODO: To be added after discussion for extension installed logic
    /*
    return new Promise((resolve, reject) => {
      let link = document.createElement('link');
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("type", "text/css");
      link.onload = () => {
        console.log('Installed');
        resolve(true);
      };
      link.onerror = () => {
        console.log('Not Installed');
        reject(false);
      };
      console.log('id is: ', environment.firefox_extension_id);
      link.setAttribute("href", `moz-extension://${environment.firefox_extension_id}/src/css/Trinka-popup.styles.css`);
      document.getElementsByTagName("head")[0].appendChild(link);
    });
      */
  }
}

