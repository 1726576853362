import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { DictWordList } from 'src/app/_interfaces/dashboard/dict-word-list';
import { MatDialog } from '@angular/material/dialog';
import { UpdateDictionaryWordComponent } from 'src/app/update-dictionary-word/update-dictionary-word.component';
import { DictEditWord } from 'src/app/_interfaces/dashboard/dictionary-edit-word';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AmplitudeService } from 'src/app/_services/amplitude.service';

@Component({
  selector: 'app-my-dictionary',
  templateUrl: './my-dictionary.component.html',
  styleUrls: ['./my-dictionary.component.scss']
})
export class MyDictionaryComponent implements OnInit {
  win  = window['dataLayer'] || {}
  dicWordList: DictWordList[]
  dicTextVal: '';
  searchByWord: '';
  placeholder: '';
  sortByDate: boolean = true;
  alphabeticalSort = 'asc';
  constructor(
    public sharedService: SharedService,
    private networkCalls: DashboardService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    public amplitudeService: AmplitudeService
  ) { }

  dictionaryData: any
  ngOnInit() {
    this.dicTextVal = '';
    this.getDictionary();
  }

  editWord(dictEditWord: DictEditWord) {
    const dialogRef = this.dialog.open(UpdateDictionaryWordComponent, { data: { selectedword: dictEditWord } });

    dialogRef.afterClosed().subscribe(result => {
      this.getDictionary(true);
      // this.win.dataLayer.push({
      //   'action': 'edit_word',
      //   'category': '<category>',
      //   'english_type': '<english type>',
      //   'case_sensitive': '<case_sensitive>', //yes or no values
      //   'event_name': 'my_dictionary',
      //   'event': 'my_dictionary'
      //    });
    });
  }

  getDictionary(flag = false) {
    if(flag){this.spinner.show();}
    this.networkCalls.getDictVal()
    .subscribe(words => {
      this.dicWordList = words['data']
      if(flag){this.spinner.hide();}
      }, error => {
        this.sharedService.errorHandller(error);
      });
  }

  // updateDictionaryWord(){
  //   this.networkCalls.updateDictWord({
  //     "word": this.dicTextVal,
  //     "category": "CS",
  //     "localization": "BOTH",
  //     "case_sensitive": true
  //   }).subscribe(result => {
  //   }, error => {
  //   this.sharedService.errorHandller(error);
  //   })
  // }



  deleteWord(word) {
    this.spinner.show();
    this.networkCalls.deleteDictVal(encodeURIComponent(word)).subscribe(result => {

      this.amplitudeService.logAmplitude('word_delete',{
        'word': word
         });

      this.getDictionary();
        

      this.spinner.hide();
    }, error => {
      this.sharedService.errorHandller(error);
    })
    this.restoreWord(word)
  }

  restoreWord(word){
    let message = word + " is deleted"
    let snakRef = this._snackBar.open(message, 'UNDO', {
      duration: 10000,
      horizontalPosition:"right",
    });
    snakRef.onAction().subscribe(()=> this.restoreWordApi(word));
  }

  restoreWordApi(word){
    this.networkCalls.restoreWord(word).subscribe(result =>{
      this.getDictionary()
    },error => {
      this.sharedService.errorHandller(error);
    });
  }

  onClickAdd() {
    this.spinner.show();
    // this.getDictionary()
    if (this.dicTextVal != "") {
      this.networkCalls.addToDictionary({
        "word": this.dicTextVal,
        "category": "CS",
        "localization": "BOTH",
        "case_sensitive": true
      }).subscribe(result => {
        
        this.amplitudeService.logAmplitude('word_add',{
          'word': this.dicTextVal
          });
    
        this.dicTextVal = "";
        this.spinner.show();
        this.getDictionary();

        this.spinner.hide();  
      }, error => {
        this.sharedService.errorHandller(error);
      })
    }
  }
  
}
 