<mat-list class="row">
    <mat-list-item class="col-md-12">
        <h1 class="mat-display-1 mb-0">Recent Files - Online Editor</h1>
    </mat-list-item>
</mat-list>
<mat-divider class="seprator"></mat-divider>

<mat-list class="file-thumbnail mt-5">
    <mat-list-item class="d-inline-block" *ngFor="let file of fileListMyDrive?.data| slice:0:4"
        [routerLink]="['/editor/main', file.file_id]">
        <mat-card class="d-block px-4 bg-white rounded pt-0 h-100">
            <mat-card-header class="text-center d-block belt pt-0">
                <img src="../../../assets/images/top-belt.png" class="img-fluid" />
            </mat-card-header>

            <mat-card-title class="text-center d-block mb-2">
                {{file.file_name}}
            </mat-card-title>
            <mat-card-content class="d-block">
                <div class="snippet" *ngIf="file.snippet">{{file.snippet}}</div>
                <div *ngIf="!file.snippet">Blank file</div>
            </mat-card-content>

            <mat-card-footer class="text-right d-block pt-1 pb-3">
                <mat-list class="pt-0"><a href="#"><img src="../../../assets/images/Icon_Download.svg" class="img-fluid"
                            alt="download" title="download" /></a>&nbsp;<span (click)="deleteFile(file)"><img
                            src="../../../assets/images/Icon_Delete.svg" class="img-fluid cursor-pointer" alt="delete"
                            title="delete" /></span></mat-list>
            </mat-card-footer>
        </mat-card>
    </mat-list-item>
</mat-list>