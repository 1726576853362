<div class="renameFolderPopup quality-modal">
    <!-- <div class="p-user">Early access for premium users</div> -->
    <h1>Check quality of your citations</h1>
    <mat-dialog-content>
        <div>Easily identify issues with the quality of cited references. Each citation is assessed for visibility and reproducibility metrics.</div>
        <div>Use this feature to cite strong papers to improve the strength of argumentation in your paper and avoid negative reviewer feedback.</div>

    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="folderRenameFooter">
            <button class="cancelRenameButton" mat-dialog-close cdkFocusInitial>Cancel</button>
            <button class="CreateRenameButton" (click)="submit()">Check Citations</button>
            <!-- <button class="CreateRenameButton" *ngIf="!details['is_premium']" (click)="upgradePlanPopup()">Upgrade to Premium</button> -->
        </div>
    </mat-dialog-actions>
</div>