import { Component, OnInit } from '@angular/core';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { EthicalCheck } from 'src/app/_interfaces/author-one/ethical-check';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-ethical-compliance',
  templateUrl: './ethical-compliance.component.html',
  styleUrls: ['./ethical-compliance.component.scss']
})
export class EthicalComplianceComponent implements OnInit {

  ethicalArrayList : any;
  ethicalCompliance: EthicalCheck;
  panelOpenState = false;

  constructor(private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService) {
    this.getReportData();
     }

  ngOnInit() {
  }

  getReportData(){
      this.subjectiveBehaviorSharedService.getA1AllCheckReportData.subscribe(reportData=>{  
        if(reportData == ''){
          setTimeout(() => {
            this.getReportData();
          }, 2000);
        }
        this.ethicalCompliance = reportData.ethical_compliance;
        this.createEthicalArray(this.ethicalCompliance);
    });
  }

  createEthicalArray(ethicalComplaince){

    this.ethicalArrayList=[
      {
       'id':1, 
       'checkNo':'Check 1',
       'checkName' : 'Informed Consent',
       'displayName' : 'Informed consent',
       'checkList': ethicalComplaince.informed_consent != undefined? ethicalComplaince.informed_consent.matches: [],
       'flag':ethicalComplaince.informed_consent.is_present,
       'statement':ethicalComplaince.informed_consent.statement,
       'required_to_show':ethicalComplaince.informed_consent.required_to_show,
       "present_statement":'Your manuscript contains '+ethicalComplaince.informed_consent.matches.length+' statements declaring informed consent.',
       'tooltip': 'Shows whether an informed consent statement is present. Note that such a statement is not required if there are no human participants.'
      },
      {
        'id':2, 
        'checkNo':'Check 2',
        'checkName' : 'Ethics Statement',
        'displayName' : 'Ethics statement',
        'checkList': ethicalComplaince.ethics_statement.matches != undefined? ethicalComplaince.ethics_statement.matches: [],
        'flag': ethicalComplaince.ethics_statement.is_present,
        'statement':ethicalComplaince.ethics_statement.statement,
        'required_to_show':ethicalComplaince.ethics_statement.required_to_show,
        "present_statement":'Your manuscript contains '+ethicalComplaince.ethics_statement.matches.length+' statements on ethical standards/approval declaration.',
        'tooltip': 'Shows whether an ethics statement is present. Note that such a statement is not required if the experiment does not involve humans or animals.'
      },
      {
        'id':3, 
        'checkNo':'Check 3',
        'checkName' : 'Financial Disclosure',
        'displayName' : 'Financial sisclosure',
        'checkList': ethicalComplaince.financial_disclosure.matches != undefined? ethicalComplaince.financial_disclosure.matches:[],
        'flag': ethicalComplaince.financial_disclosure.is_present,
        'statement':ethicalComplaince.financial_disclosure.statement,
        "present_statement":'Your manuscript contains '+ethicalComplaince.financial_disclosure.matches+' statements describing financial disclosure.',
        'required_to_show':true,
        'tooltip': 'Shows whether an appropriate funding statement is present.'
      },
      {
        'id':4, 
        'checkNo':'Check 4',
        'checkName' : 'Conflict of Interest',
        'displayName' : 'Conflict of interest',
        'checkList': ethicalComplaince.conflict_of_interest.matches != undefined? ethicalComplaince.conflict_of_interest.matches: [],
        'flag': ethicalComplaince.conflict_of_interest.is_present,
        'statement':ethicalComplaince.conflict_of_interest.statement,
        "present_statement":'Your manuscript contains '+ethicalComplaince.conflict_of_interest.matches.length+' statements on conflict of interest declaration.',
        'required_to_show':true,
        'tooltip': 'Shows whether an appropriate conflict of interest statement is present.'
      },
      {
        'id':5, 
        'checkNo':'Check 5',
        'checkName' : 'Data Availability',
        'displayName' : 'Data availability',
        'checkList': ethicalComplaince.data_availability_disclosure.matches != undefined? ethicalComplaince.data_availability_disclosure.matches: [],
        'flag': ethicalComplaince.data_availability_disclosure.is_present,
        'statement':ethicalComplaince.data_availability_disclosure.statement,
        "present_statement":'Your manuscript contains '+ethicalComplaince.data_availability_disclosure.matches.length+' statements on data availability.',
        'required_to_show':true,
        'tooltip': 'Shows whether an appropriate data availability statement is present.'
      },
    ];
  }
}
